/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { cancelIcon } from '@progress/kendo-svg-icons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { htmlRender } from '../../Helpers/ContentFormatting';
import { ViewPalletForm } from '../../Helpers/FormHelpers/ViewPalletForm';
import { Label } from '@progress/kendo-react-labels';
import { Switch } from '@progress/kendo-react-inputs';
import LiftRequestTooltipModal from '../../App/Modals/LiftRequestTooltipModal';
import { ApiSimulationShapleyById } from '../../Helpers/Apis';
import { Link } from 'react-router-dom';
import Loader from '../../Core/Loader';
import Moment from 'react-moment';

const HazmatCell = ({ dataItem, ...props }) => {
	if (!dataItem || !dataItem.hazmat || !dataItem.hazmat.containsHazmat) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					No
				</span>
			</td>
		);
	}

	if (dataItem.hazmat.containsHazmat) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					Yes
				</span>
			</td>
		);
	}
};

const RollingStockCell = ({ dataItem, ...props }) => {
	if (!dataItem || !dataItem.rollingStock) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					No
				</span>
			</td>
		);
	}

	if (dataItem.rollingStock) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					Yes
				</span>
			</td>
		);
	}
};

const OversizeCell = ({ dataItem, ...props }) => {
	if (!dataItem || !dataItem.oversize) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					No
				</span>
			</td>
		);
	}

	if (dataItem.oversize) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					Yes
				</span>
			</td>
		);
	}
};

const PalletFormContainerCommandCell = (props) => {
	return (
		<td>
			<button className="btn btn-primary btn-color-green" onClick={() => props.enterEditForm(props.dataItem)}>
				Cargo Form
			</button>
		</td>
	);
};

export const ViewMissionDetail = (props) => {
	const [detailType, setDetailType] = React.useState('');
	const [openForm, setOpenForm] = React.useState(false);
	const [legLocked, setLegLocked] = React.useState(false);
	const [toggleModal, setToggleModal] = React.useState(false);
	const [modalTitle, setModalTitle] = React.useState('');
	const [modalRef, setModalRef] = React.useState('');
	const [modalContent, setModalContent] = React.useState('');
	const [shaplyData, setShaplyData] = React.useState('');
	const [editItem, setEditItem] = React.useState({
		PalletID: 1
	});

	const item = props.item;
	const editorRef = React.useRef(null);
	const legLockedAnchor = React.useRef(null);
	const shaplyEditorRef = React.useRef(null);
	const shaplyAnchor = React.useRef(null);

	useEffect(() => {
		//call shaply plot here to prevent racing conditions. Only if in sandbox
		if (props.environment === 'sandbox') {
			ApiSimulationShapleyById(item.missionId).then((res) => {
				setShaplyData(res);
			});
		}

		if (props.item.title.props) {
			setDetailType('Leg');
		} else {
			if (props.item.title.includes('to')) {
				setDetailType('Leg');
			} else if (props.item.title.includes('STOP')) {
				setDetailType('Stop');
			} else {
				setDetailType('Mission');
			}
		}
		// Set Values for leg being locked or not
		if (props.item.state !== null && props.item.state === 'LOCKED') {
			setLegLocked(true);
		} else {
			setLegLocked(false);
		}
	}, []);

	const enterEditForm = (item) => {
		setOpenForm(true);
		setEditItem(item);
	};

	const handleCancelEdit = () => {
		setOpenForm(false);
	};

	const PalletFormCommandCell = (props) => <PalletFormContainerCommandCell {...props} enterEditForm={enterEditForm} />;

	const handleModalToggle = () => {
		setToggleModal(!toggleModal);
	};

	const handleModalRef = (val) => {
		setModalRef(val);
	};

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	return (
		<React.Fragment>
			<Dialog title={`${detailType} detail:`} onClose={props.onCancel} width={1200} height={950}>
				{detailType === 'Mission' ? (
					<React.Fragment>
						{props.environment === 'sandbox' ? (
							<Row id={'cargo-transportation'} className={'form-section row'}>
								<div className="col-12 form-section-title cargo-transportation">
									<h3>
										Sandbox Mission Details
										<span className="fontsize-14px" style={{ float: 'right' }}>
											(To view the details of this mission{' '}
											<Link to={`/missionstatus/${item.missionId}`} target={'_blank'}>
												click here)
											</Link>
										</span>
									</h3>
								</div>
							</Row>
						) : (
							<Row id={'cargo-transportation'} className={'form-section row'}>
								<div className="col-12 form-section-title cargo-transportation">
									<h3>
										Mission Details
										<span className="fontsize-14px" style={{ float: 'right' }}>
											(To view the details of this mission{' '}
											<Link to={`/missionstatus/${item.missionId}`} target={'_blank'}>
												click here)
											</Link>
										</span>
									</h3>
								</div>
							</Row>
						)}

						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className={'col-5'}>
								<label>Title</label>
								<div className={'static-field-read-only static-text'}>{item.title ? item.title : ''}</div>
							</div>
						</Row>
						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className={'col-5'}>
								<label>Mission Start Date Time</label>
								<div className={'static-field-read-only static-text'}>
									<Moment format="YYYY-MM-DD HH:mm">{item.start ? item.start : ''}</Moment>
								</div>
							</div>
							<div className={'col-5'}>
								<label>Mission End Date Time</label>
								<div className={'static-field-read-only static-text'}>
									<Moment format="YYYY-MM-DD HH:mm">{item.end ? item.end : ''}</Moment>
								</div>
							</div>
						</Row>

						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className="col-12 form-section-title cargo-transportation">
								<h3>Mission Cargo Details</h3>
							</div>
						</Row>

						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className={'col-5'}>
								<label>Total Cargo Positions in this Mission</label>
								<div className={'static-field-read-only static-text'}>{item.cargoPalletTotal ? item.cargoPalletTotal : ''}</div>
							</div>
							<div className={'col-5'}>
								<label>Total Passengers in this Mission</label>
								<div className={'static-field-read-only static-text'}>{item.cargoPaxTotal ? item.cargoPaxTotal : ''}</div>
							</div>
						</Row>

						{props.environment === 'sandbox' ? (
							<React.Fragment>
								<Row id={'cargo-transportation'} className={'form-section row'}>
									<div className="col-12 form-section-title cargo-transportation">
										<h3>Mission Simulation Results</h3>
									</div>
								</Row>

								<Row id={'cargo-transportation'} className={'form-section row'}>
									<div className={'col-5'}>
										<label>Mission Plausible</label>
										<div className={'static-field-read-only static-text'}>{item.probabilityPlausibility && item.probabilityPlausibility === 'Will fly' ? 'Yes' : 'No'}</div>
									</div>
									<div className={'col-5'}>
										<label>Mission Probability Percentage</label>
										<div className={'static-field-read-only static-text'}>{item.propabilityPercentage ? parseInt(item.propabilityPercentage * 100) + '%' : ''}</div>
									</div>
								</Row>

								<Row id={'cargo-transportation'} className={'form-section row'}>
									<div className="col-12 form-section-title cargo-transportation">
										<h3>Mission Simulation Shapley Plot</h3>
									</div>
								</Row>

								{shaplyData !== '' ? (
									<React.Fragment>
										<Row className={'has-info'}>
											<img style={{ maxWidth: '70%', position: 'absolute', left: '10%' }} src={`data:;base64,${shaplyData}`} editorRef={shaplyEditorRef} alt={'No Shapley data found'} />
										</Row>

										{/* Infotip: Start */}
										<Button
											style={{ float: 'right' }}
											type={'button'}
											variant={'secondary'}
											className={'btn-form-info'}
											id={'legLockingAnchor'}
											ref={shaplyAnchor}
											onClick={() => {
												// ref: modal anchor
												handleModalRef(shaplyAnchor);

												// Info: Modal Title
												handleModalTitle('Shapley Plot Information');

												// Info: Modal Content
												handleModalContent(`
													<p>SHAP values are based on Lloyd Shapley’s work in game theory, and are designed to estimate the relative influence of each factor used on the final prediction.</p>

													<p>The baseline value, E[f(x)], is the average value of the entire dataset (in arbitrary units). </p>
													<p>Each factor’s influence adds or subtracts from the baseline by its given value, in total summing to a value f(x) for each instance.</p>
												`);

												// Show Modal
												handleModalToggle();
											}}>
											<i className={'fa-solid fa-info modal-trigger'}></i>
											<span className={'sr-only'}>Click to view information about Shapley Plot</span>
										</Button>
										{/* Infotip: End */}
									</React.Fragment>
								) : (
									<Loader />
								)}
							</React.Fragment>
						) : (
							''
						)}
					</React.Fragment>
				) : (
					''
				)}

				{detailType === 'Leg' ? (
					<React.Fragment>
						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className="col-12 form-section-title cargo-transportation">
								<h3>
									Leg Details
									<span className="fontsize-14px" style={{ float: 'right' }}>
										(To view mission details this leg is a part of{' '}
										<Link to={`/missionstatus/${item.missionId}`} target={'_blank'}>
											click here)
										</Link>
									</span>
								</h3>
							</div>
						</Row>

						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className={'col-4'}>
								<label>Title</label>
								<div className={'static-field-read-only static-text'}>{item.title ? item.title : ''}</div>
							</div>
							<div className={'col-4'}>
								<label>Asset Tail #</label>
								<div className={'static-field-read-only static-text'}>{item.tailNumber ? item.tailNumber : ''}</div>
							</div>
							{/* <div className={'col-4'}> */}
							<div className={'col-3 cargo-toggles padding-top-28 padding-left-90 has-info'}>
								{/* <div className={'form-group'}> */}
								<Switch ariaLabelledBy={'switchLabel'} ref={editorRef} defaultChecked={false} onLabel={'Yes'} offLabel={'No'} checked={legLocked} disabled={true} size={'large'} trackRounded={'large'} thumbRounded={'large'} />
								<Label className={'padding-left-15'} id={'switchLabel'} editorRef={editorRef} style={{ display: 'inline-block', left: '150px' }}>
									Leg Locked
								</Label>
								{/* Infotip: Start */}
								<Button
									type={'button'}
									variant={'secondary'}
									className={'btn-form-info'}
									id={'legLockingAnchor'}
									ref={legLockedAnchor}
									onClick={() => {
										// ref: modal anchor
										handleModalRef(legLockedAnchor);

										// Info: Modal Title
										handleModalTitle('Leg Locking Information');

										// Info: Modal Content
										handleModalContent(`
												<p>By locking a leg, a user controls what Solver considers when generating a new schedule. <p>

												<p>Any Locked Leg will remain preserved as-is,
												 but other Unlocked Legs in the same mission will be considered when generating new Mission Schedule(s)</p>
											`);

										// Show Modal
										handleModalToggle();
									}}>
									<i className={'fa-solid fa-info modal-trigger'}></i>
									<span className={'sr-only'}>Click to view information about leg locking</span>
								</Button>
								{/* Infotip: End */}
							</div>
						</Row>

						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className="col-12 form-section-title cargo-transportation">
								<h3>Leg Itinerary Details</h3>
							</div>
						</Row>

						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className={'col-6'}>
								<label>Leg Departure Date Time</label>
								<div className={'static-field-read-only static-text'}>
									<Moment format="YYYY-MM-DD HH:mm">{item.start ? item.start : ''}</Moment>
								</div>
							</div>
							<div className={'col-6'}>
								<label>Leg Arrival Date Time</label>
								<div className={'static-field-read-only static-text'}>
									<Moment format="YYYY-MM-DD HH:mm">{item.end ? item.end : ''}</Moment>
								</div>
							</div>
							<div className={'col-6'}>
								<label>Leg Departure Airport</label>
								<div className={'static-field-read-only static-text'}>{item.locationFrom ? item.locationFrom : ''}</div>
							</div>
							<div className={'col-6'}>
								<label>Leg Arrival Airport</label>
								<div className={'static-field-read-only static-text'}>{item.locationTo ? item.locationTo : ''}</div>
							</div>
							<div className={'col-6'}>
								<label>Leg Departure Latitude</label>
								<div className={'static-field-read-only static-text'}>{item.fromLat ? item.fromLat : ''}</div>
							</div>
							<div className={'col-6'}>
								<label>Leg Arrival Latitude</label>
								<div className={'static-field-read-only static-text'}>{item.toLat ? item.toLat : ''}</div>
							</div>
							<div className={'col-6'}>
								<label>Leg Departure Longitude</label>
								<div className={'static-field-read-only static-text'}>{item.fromLon ? item.fromLon : ''}</div>
							</div>
							<div className={'col-6'}>
								<label>Leg Arrival Longitude</label>
								<div className={'static-field-read-only static-text'}>{item.toLon ? item.toLon : ''}</div>
							</div>
						</Row>

						{item.cargoPalletTotal > 0 || item.cargoPaxTotal > 0 ? (
							<React.Fragment>
								<Row id={'cargo-transportation'} className={'form-section row'}>
									<div className="col-12 form-section-title cargo-transportation">
										<h3>Leg Cargo Summary</h3>
									</div>
								</Row>

								<Row id={'cargo-transportation'} className={'form-section row'}>
									<div className={'col-5'}>
										<label>Total Cargo Positions in this Leg</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPalletTotal ? item.cargoPalletTotal : ''}</div>
									</div>
									<div className={'col-5'}>
										<label>Total Passengers in this Leg</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxTotal ? item.cargoPaxTotal : ''}</div>
									</div>
								</Row>
							</React.Fragment>
						) : (
							''
						)}

						{item.cargoPaxInfo.length > 0 ? (
							<React.Fragment>
								<Row id={'senior-traveler'} className={'form-section row'}>
									<div className="col-12 form-section-title senior-traveler">
										<h3>
											Senior Traveler
											<span className="fontsize-14px" style={{ float: 'right' }}>
												(Must be 1 for every 121 PAX &amp; can't be the same as the Travel Authorizing Official)
											</span>
										</h3>
									</div>

									<div className={'col-3'}>
										<label>First Name</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].type === 'PASSENGERS' && item.cargoPaxInfo[0].seniorTravelers !== null && item.cargoPaxInfo[0].seniorTravelers !== undefined && item.cargoPaxInfo[0].seniorTravelers.length && item.cargoPaxInfo[0].seniorTravelers[0].firstName ? item.cargoPaxInfo[0].seniorTravelers[0].firstName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Last Name</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].type === 'PASSENGERS' && item.cargoPaxInfo[0].seniorTravelers !== null && item.cargoPaxInfo[0].seniorTravelers !== undefined && item.cargoPaxInfo[0].seniorTravelers.length && item.cargoPaxInfo[0].seniorTravelers[0].lastName ? item.cargoPaxInfo[0].seniorTravelers[0].lastName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Email Address</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].type === 'PASSENGERS' && item.cargoPaxInfo[0].seniorTravelers !== null && item.cargoPaxInfo[0].seniorTravelers !== undefined && item.cargoPaxInfo[0].seniorTravelers.length && item.cargoPaxInfo[0].seniorTravelers[0].email ? item.cargoPaxInfo[0].seniorTravelers[0].email : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Mobile Phone</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].type === 'PASSENGERS' && item.cargoPaxInfo[0].seniorTravelers !== null && item.cargoPaxInfo[0].seniorTravelers !== undefined && item.cargoPaxInfo[0].seniorTravelers.length && item.cargoPaxInfo[0].seniorTravelers[0].phoneNumber ? item.cargoPaxInfo[0].seniorTravelers[0].phoneNumber : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Department or Agency</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].type === 'PASSENGERS' && item.cargoPaxInfo[0].seniorTravelers !== null && item.cargoPaxInfo[0].seniorTravelers !== undefined && item.cargoPaxInfo[0].seniorTravelers.length && item.cargoPaxInfo[0].seniorTravelers[0].branchOfService ? item.cargoPaxInfo[0].seniorTravelers[0].branchOfService : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Grade</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].type === 'PASSENGERS' && item.cargoPaxInfo[0].seniorTravelers !== null && item.cargoPaxInfo[0].seniorTravelers !== undefined && item.cargoPaxInfo[0].seniorTravelers.length && item.cargoPaxInfo[0].seniorTravelers[0].rank && item.cargoPaxInfo[0].seniorTravelers[0].rank.gradeTitle ? item.cargoPaxInfo[0].seniorTravelers[0].rank.gradeTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Duty Title</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].type === 'PASSENGERS' && item.cargoPaxInfo[0].seniorTravelers !== null && item.cargoPaxInfo[0].seniorTravelers !== undefined && item.cargoPaxInfo[0].seniorTravelers.length && item.cargoPaxInfo[0].seniorTravelers[0].dutyTitle ? item.cargoPaxInfo[0].seniorTravelers[0].dutyTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Unit</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].type === 'PASSENGERS' && item.cargoPaxInfo[0].seniorTravelers !== null && item.cargoPaxInfo[0].seniorTravelers !== undefined && item.cargoPaxInfo[0].seniorTravelers.length && item.cargoPaxInfo[0].seniorTravelers[0].unit && item.cargoPaxInfo[0].seniorTravelers[0].unit.uic ? item.cargoPaxInfo[0].seniorTravelers[0].unit.uic : ''}</div>
									</div>
								</Row>

								<Row id={'travel-authorizing-official'} className={'form-section row'}>
									<div className="col-12 form-section-title travel-authorizing-official">
										<h3>
											Travel Authorizing Official
											<span className="fontsize-14px" style={{ float: 'right' }}>
												(As appointed by Service)
											</span>
										</h3>
									</div>

									<div className={'col-3'}>
										<label>First Name</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].commandingOfficer && item.cargoPaxInfo[0].commandingOfficer.firstName ? item.cargoPaxInfo[0].commandingOfficer.firstName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Last Name</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].commandingOfficer && item.cargoPaxInfo[0].commandingOfficer.lastName ? item.cargoPaxInfo[0].commandingOfficer.lastName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Email Address</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].commandingOfficer && item.cargoPaxInfo[0].commandingOfficer.email ? item.cargoPaxInfo[0].commandingOfficer.email : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Mobile Phone</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].commandingOfficer && item.cargoPaxInfo[0].commandingOfficer.phoneNumber ? item.cargoPaxInfo[0].commandingOfficer.phoneNumber : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Department or Agency</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].commandingOfficer && item.cargoPaxInfo[0].commandingOfficer.branchOfService ? item.cargoPaxInfo[0].commandingOfficer.branchOfService : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Grade</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].commandingOfficer && item.cargoPaxInfo[0].commandingOfficer.rank && item.cargoPaxInfo[0].commandingOfficer.rank.gradeTitle ? item.cargoPaxInfo[0].commandingOfficer.rank.gradeTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Duty Title</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].commandingOfficer && item.cargoPaxInfo[0].commandingOfficer.dutyTitle ? item.cargoPaxInfo[0].commandingOfficer.dutyTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Unit</label>
										<div className={'static-field-read-only static-text'}>{item.cargoPaxInfo[0] && item.cargoPaxInfo[0].commandingOfficer && item.cargoPaxInfo[0].commandingOfficer.unit ? item.cargoPaxInfo[0].commandingOfficer.unit.uic : ''}</div>
									</div>
								</Row>
							</React.Fragment>
						) : (
							''
						)}

						{item.cargoPalletInfo.length > 0 ? (
							<React.Fragment>
								<Grid
									name={'test'}
									style={{
										height: '420px',
										width: '100%'
									}}
									data={item.cargoPalletInfo}>
									<Column field="PalletName" title="Cargo Title" />
									<Column field="count" title="Cargo Positions" />
									<Column
										cells={{
											data: HazmatCell
										}}
										title="Contains Hazmat"
										editable={false}
									/>
									<Column
										cells={{
											data: RollingStockCell
										}}
										title="Rolling Stock"
										editable={false}
									/>
									<Column
										cells={{
											data: OversizeCell
										}}
										title="Oversize"
										editable={false}
									/>
									<Column cell={PalletFormCommandCell} />
								</Grid>
								{openForm && <ViewPalletForm item={editItem} cancelEdit={handleCancelEdit} props={props} />}
							</React.Fragment>
						) : (
							''
						)}

						<Row id={'share-lift-request'} className={'form-section row'}>
							<div className="col-12 form-section-title share-request">
								<h3>Lift Requests satisfied in this Leg</h3>
							</div>
							<div className={'col-12'}>
								<label>Lift Request IDs</label>
								<div className={'static-field-read-only static-textarea'}>
									{item.satisfiesLiftRequests &&
										item.satisfiesLiftRequests.map((rec, i) => {
											const liftRequest = `<a href="/liftrequest/view/${rec}">${rec}</a>`;
											return <span key={i}>{i === 0 ? htmlRender(liftRequest) : htmlRender(liftRequest)}</span>;
										})}
								</div>
							</div>
						</Row>
					</React.Fragment>
				) : (
					''
				)}

				{detailType === 'Stop' ? (
					<React.Fragment>
						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className="col-12 form-section-title cargo-transportation">
								<h3>Stop Detail</h3>
							</div>
						</Row>

						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className={'col-5'}>
								<label>Stop Start / Arrival Date Time</label>
								<div className={'static-field-read-only static-text'}>
									<Moment format="YYYY-MM-DD HH:mm">{item.start ? item.start : ''}</Moment>
								</div>
							</div>
							<div className={'col-5'}>
								<label>Stop End / Departure Date Time</label>
								<div className={'static-field-read-only static-text'}>
									<Moment format="YYYY-MM-DD HH:mm">{item.end ? item.end : ''}</Moment>
								</div>
							</div>
							<div className={'col-5'}>
								<label>Stop Arrival Airport</label>
								<div className={'static-field-read-only static-text'}>{item.stopLocation ? item.stopLocation : ''}</div>
							</div>
						</Row>
						<Row id={'cargo-transportation'} className={'form-section row'}>
							<div className={'col-5'}>
								<label>Stop Airport Location (latitude)</label>
								<div className={'static-field-read-only static-text'}>{item.stopLat ? item.stopLat : ''}</div>
							</div>
							<div className={'col-5'}>
								<label>Stop Airport Location (latitude)</label>
								<div className={'static-field-read-only static-text'}>{item.stopLon ? item.stopLon : ''}</div>
							</div>
						</Row>
					</React.Fragment>
				) : (
					''
				)}

				<DialogActionsBar layout="end">
					<Button className={'btn btn-primary btn-size-medium btn-color-blue'} onClick={props.onCancel} icon="cancel" svgIcon={cancelIcon}>
						Close
					</Button>
				</DialogActionsBar>
			</Dialog>
			<LiftRequestTooltipModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalRef={modalRef} modalContent={modalContent} />
		</React.Fragment>
	);
};
