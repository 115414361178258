/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import { Row } from 'react-bootstrap';
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormSwitch } from '../../../App/KendoFormElements/FormComponents';
import { Button } from '@progress/kendo-react-buttons';

import { SandboxSelectionDialog } from '../SandboxSelectionDialog/SandboxSelectionDialog';

export const SandboxItineraryDetails = (props) => {
	const [openLiftRequestForm, setOpenLiftRequestForm] = React.useState(false);
	const [openAssetForm, setOpenAssetForm] = React.useState(false);
	const [openAirportForm, setOpenAirportForm] = React.useState(false);

	const [lrIdArray, setLrIdArray] = React.useState(props.selectedLiftRequests);
	const [potentialLrIdArray, setPotentialLrIdArray] = React.useState([]);

	const [assetIdArray, setAssetIdArray] = React.useState(props.selectedAssets);
	const [potentialAssetIdArray, setPotentialAssetIdArray] = React.useState([]);

	const [airportIdArray, setAirportIdArray] = React.useState(props.selectedAirports);
	const [potentialAirportIdArray, setPotentialAirportIdArray] = React.useState([]);

	const handleCancelEdit = (param) => {
		setOpenLiftRequestForm(false);
		setOpenAssetForm(false);
		setOpenAirportForm(false);
	};

	const enterEditForm = (param) => {
		if (param === 'liftRequest') {
			setOpenLiftRequestForm(true);
		}
		if (param === 'asset') {
			setOpenAssetForm(true);
		}
		if (param === 'airport') {
			setOpenAirportForm(true);
		}
	};

	const handleSubmit = (event, param) => {
		setOpenLiftRequestForm(false);
		setOpenAssetForm(false);
		setOpenAirportForm(false);

		if (event.target.textContent === 'Add Selected Lift Requests') {
			props.setSelectedLiftRequests(potentialLrIdArray);
			setLrIdArray(potentialLrIdArray);
		}

		if (event.target.textContent === 'Add Selected Assets') {
			props.setSelectedAssets(potentialAssetIdArray);
			setAssetIdArray(potentialAssetIdArray);
		}

		if (event.target.textContent === 'Add Selected Airports') {
			props.setSelectedAirports(potentialAirportIdArray);
			setAirportIdArray(potentialAirportIdArray);
		}
	};

	return (
		<React.Fragment>
			<Row id={'lift-request-introduction'} className={'form-section'}>
				<div className={'col-12 form-section-title lift-request-introduction'}>
					<h3>Itinerary Details</h3>
				</div>
			</Row>

			{/* able to edit the name on this page */}

			<Row className={'form-section'}>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<Field key={'sandboxName'} id={'sandboxName'} name={'sandboxName'} label={'Sandbox Name'} placeholder={'Enter a name for your environment...'} component={FormInput} />
					</div>
				</div>
			</Row>

			<Row className={'form-section'}>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<p style={{ fontSize: '0.9rem', fontWeight: '600', padding: '0 0 8px', cursor: 'pointer', margin: '0' }}>{`${lrIdArray.length} Lift Requests Added to Sandbox`}</p>
						<Button className={`btn btn-primary margin-right-o 'btn-next'}`} type={'button'} themeColor={'primary'} onClick={() => enterEditForm('liftRequest')} title={`Click to View Lift Requests'}`}>
							View Lift Requests
						</Button>
					</div>
				</div>
			</Row>

			{openLiftRequestForm && <SandboxSelectionDialog cancelEdit={handleCancelEdit} onSubmit={handleSubmit} lrIdArray={lrIdArray} setPotentialLrIdArray={setPotentialLrIdArray} buttonString={'Lift Requests'} props={props} />}

			<Row className={'form-section'}>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<p style={{ fontSize: '0.9rem', fontWeight: '600', padding: '0 0 8px', cursor: 'pointer', margin: '0' }}>{`${assetIdArray.length} Assets Added to Sandbox`}</p>
						<Button className={`btn btn-primary margin-right-o 'btn-next'}`} type={'button'} themeColor={'primary'} onClick={() => enterEditForm('asset')} title={`Click to View Assets`}>
							View Assets
						</Button>
					</div>
				</div>
			</Row>

			{openAssetForm && <SandboxSelectionDialog cancelEdit={handleCancelEdit} onSubmit={handleSubmit} assetIdArray={assetIdArray} setPotentialAssetIdArray={setPotentialAssetIdArray} buttonString={'Assets'} props={props} />}

			<Row className={'form-section'}>
				<div>
					<Field key={'useStandardAirports'} id={'useStandardAirports'} name={'useStandardAirports'} onLabel={'Yes'} offLabel={'No'} defaultChecked={true} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Use Standard Airports?'} component={FormSwitch} />
				</div>
			</Row>

			{props.valueGetter('useStandardAirports') === false && (
				<Row className={'form-section'}>
					<div className={'col-4'}>
						<div className={'form-group'}>
							<p style={{ fontSize: '0.9rem', fontWeight: '600', padding: '0 0 8px', cursor: 'pointer', margin: '0' }}>{`${airportIdArray.length} Airports Added to Sandbox`}</p>
							<Button className={`btn btn-primary margin-right-o 'btn-next'}`} type={'button'} themeColor={'primary'} onClick={() => enterEditForm('airport')} title={`Click to View Airports`}>
								View Airports
							</Button>
						</div>
					</div>

					{openAirportForm && <SandboxSelectionDialog cancelEdit={handleCancelEdit} onSubmit={handleSubmit} airportIdArray={airportIdArray} setPotentialAirportIdArray={setPotentialAirportIdArray} buttonString={'Airports'} props={props} />}
				</Row>
			)}
		</React.Fragment>
	);
};
