/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Form, FormElement, Field, FieldArray } from '@progress/kendo-react-form';
import { FormInput, FormTextArea, FormDropDownList, FormComboBox, FormMultiSelect, FormSwitch, FormCheckbox, FormTimePicker, FormDatePicker } from '../../App/KendoFormElements/FormComponents';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Loader from '../../Core/Loader';
import { toast } from 'react-toastify';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { optionsAirportStatuses, optionsAssetTypes } from '../../../Assets/json/formOptions';
import { optionsCountries } from '../../../Assets/json/countries';
import { optionsTimezones } from '../../../Assets/json/timezones';

import { Error } from '@progress/kendo-react-labels';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';

import { ApiAirportById, ApiDeleteAirportRequest, ApiEditAirportRequest, ApiGetAirportTypeRequest } from '../../Helpers/Apis';

import { getDates } from '../../Helpers/DatesListArray';
import { stripTimeZone, forceDateStringToUTCISOFormat } from '../../Helpers/DateTimeConversions';

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { GoogleMap, LoadScript, OverlayView } from '@react-google-maps/api';
import Marker from '../../Maps/Marker';
import { getLatitudeLongitudeDegreesMinutes, getLatitudeLongitudeDecimalDegrees } from '../../Helpers/ContentFormatting';

// Field Array Command Cell: Remove Row
const commandCell = (onRemove) => (props) => {
	const onClick = React.useCallback(
		(e) => {
			e.preventDefault();
			onRemove(props);
		},
		[onRemove]
	);

	return (
		<td className={'summary-actions-cell'}>
			<button className={'button btn-icon-remove btn-color-red margin-right-0 padding-top-12 padding-bottom-12 k-button k-grid-remove-command'} title={'Click to Remove Row'} onClick={onClick}>
				Remove
			</button>
		</td>
	);
};

const AirportEdit = (props) => {
	const [loaded, setLoaded] = React.useState(false);

	// eslint-disable-next-line
	const [airportDetails, setAirportDetails] = React.useState([]);
	const [airportTypeList, setAirportTypeList] = React.useState([]);
	const [formState, setFormState] = React.useState({});

	// Dialog Visibility
	const [visible, setVisible] = React.useState(false);

	const params = useParams();

	const requestId = params.airportId ? params.airportId : '';

	const [geKloadChecked, setGeKloadChecked] = React.useState(false);
	const [geGpuChecked, setGeGpuChecked] = React.useState(false);
	const [geApuChecked, setGeApuChecked] = React.useState(false);

	const [normalSunday, setNormalSunday] = React.useState(false);
	const [normalMonday, setNormalMonday] = React.useState(false);
	const [normalTuesday, setNormalTuesday] = React.useState(false);
	const [normalWednesday, setNormalWednesday] = React.useState(false);
	const [normalThursday, setNormalThursday] = React.useState(false);
	const [normalFriday, setNormalFriday] = React.useState(false);
	const [normalSaturday, setNormalSaturday] = React.useState(false);
	const [datesArr, setDatesArr] = React.useState([]);
	const [submissionInvalid, setSubmissionInvalid] = React.useState(true);
	const [submissionInvalidRequiredIcaoCode, setSubmissionInvalidRequiredIcaoCode] = React.useState(true);
	const [submissionInvalidRequiredLat, setSubmissionInvalidRequiredLat] = React.useState(true);
	const [submissionInvalidRequiredLong, setSubmissionInvalidRequiredLong] = React.useState(true);
	let closedArr = [];

	const handleGeKloadCheck = (e) => {
		setGeKloadChecked(!geKloadChecked);
	};

	const handleGeGpuCheck = (e) => {
		setGeGpuChecked(!geGpuChecked);
	};

	const handleGeApuCheck = (e) => {
		setGeApuChecked(!geApuChecked);
	};

	const handleNormalSunday = (e) => {
		setNormalSunday(!normalSunday);
	};

	const handleNormalMonday = (e) => {
		setNormalMonday(!normalMonday);
	};

	const handleNormalTuesday = (e) => {
		setNormalTuesday(!normalTuesday);
	};

	const handleNormalWednesday = (e) => {
		setNormalWednesday(!normalWednesday);
	};

	const handleNormalThursday = (e) => {
		setNormalThursday(!normalThursday);
	};

	const handleNormalFriday = (e) => {
		setNormalFriday(!normalFriday);
	};

	const handleNormalSaturday = (e) => {
		setNormalSaturday(!normalSaturday);
	};

	const defaultTimes = {
		from: new Date(stripTimeZone('2023-03-04T13:00:00.000Z')),
		to: new Date(stripTimeZone('2023-03-04T01:00:00.000Z'))
	};

	// Form Initial Values
	const initValues = {
		...formState,

		id: params.airportId,
		name: airportDetails.name ? airportDetails.name : '',
		type: airportDetails.type ? airportDetails.type : '',

		status: airportDetails.status ? airportDetails.status : '',
		dafifIdentifier: airportDetails.dafifIdentifier ? airportDetails.dafifIdentifier : '',
		icao: airportDetails.icao ? airportDetails.icao : '',
		iata: airportDetails.iata ? airportDetails.iata : '',
		countryCode: airportDetails.country ? { value: airportDetails.country.code, label: airportDetails.country.name, valueandlabel: airportDetails.country.code + ' - ' + airportDetails.country.name } : {},
		timeZoneIdentifier: airportDetails.timeZoneIdentifier ? airportDetails.timeZoneIdentifier : '',
		allowedAircraft: airportDetails.allowedAircraft ? airportDetails.allowedAircraft : [],

		groundEquipmentKLoader: false,
		groundEquipmentGpu: false,
		groundEquipmentApu: false,

		latitude: airportDetails.latitude && airportDetails.longitude ? getLatitudeLongitudeDegreesMinutes(airportDetails.latitude, airportDetails.longitude, 0) : '',
		longitude: airportDetails.latitude && airportDetails.longitude ? getLatitudeLongitudeDegreesMinutes(airportDetails.latitude, airportDetails.longitude, 1) : '',
		elevation: airportDetails.elevation ? airportDetails.elevation : '',

		/*
		SAMPLE FORMAT FOR AVAILABILITY OVERRIDE INIT
		availabilityExceptionsDateRange = [
			{dateFrom: "", dateTo: "", timeFrom: "", timeTo: "", frequency: "", closed: ""},
			{dateFrom: "", dateTo: "", timeFrom: "", timeTo: "", frequency: "", closed: ""},
			{dateFrom: "", dateTo: "", timeFrom: "", timeTo: "", frequency: "", closed: ""}
		],

		availabilityExceptionDateFromField = [
			{dateFrom: new Date("2023-12-25T05:00:00.000Z")},
			{dateFrom: new Date("2023-12-28T05:00:00.000Z")},
			{dateFrom: new Date("2024-01-01T05:00:00.000Z")}
		],

		availabilityExceptionDateToField = [
			{dateTo: new Date("2023-12-27T05:00:00.000Z")},
			{dateTo: new Date("2023-12-31T05:00:00.000Z")},
			{dateTo: new Date("2024-01-05T05:00:00.000Z")}
		],

		availabilityExceptionTimeFromField = [
			{timeFrom: new Date("2023-12-25T08:00:00.000Z")},
			{timeFrom: new Date("2023-12-28T08:00:00.000Z")},
			{timeFrom: new Date("2024-01-01T08:00:00.000Z")}
		],

		availabilityExceptionTimeToField = [
			{timeTo: new Date("2023-12-27T20:00:00.000Z")},
			{timeTo: new Date("2023-12-31T20:00:00.000Z")},
			{timeTo: new Date("2024-01-05T20:00:00.000Z")}
		],

		availabilityExceptionFrequencyField = ["YEARLY","DAILY","WEEKLY"],
		availabilityExceptionClosedField = [true,true,false],
		*/

		// Availability Exception Fields
		availabilityExceptionsDateRange: [],
		availabilityExceptionDateFromField: [],
		availabilityExceptionDateToField: [],
		availabilityExceptionTimeFromField: [],
		availabilityExceptionTimeToField: [],
		availabilityExceptionFrequencyField: [],
		availabilityExceptionClosedField: [],

		commentField: []
	};

	// Init: Ground Support Equipment
	if (airportDetails.groundSupportEquipment) {
		// eslint-disable-next-line
		airportDetails.groundSupportEquipment.map((rec, i) => {
			if (rec.equipmentType === 'K_LOADER') {
				initValues.groundEquipmentKLoader = rec.quantity === 1 ? true : false;
			}
			if (rec.equipmentType === 'GPU') {
				initValues.groundEquipmentGpu = rec.quantity === 1 ? true : false;
			}
			if (rec.equipmentType === 'APU') {
				initValues.groundEquipmentApu = rec.quantity === 1 ? true : false;
			}
		});
	}

	// Init: Normal Operating Hours, Availability
	if (airportDetails.availability) {
		// eslint-disable-next-line
		airportDetails.availability.map((rec, i) => {
			// Strip the UTC timezone off to make it appear that this is a local date/time
			let startTime = new Date(stripTimeZone(rec.start));
			let endTime = new Date(stripTimeZone(rec.end));

			// Normal Operating Hours
			if (rec.byDay[0] === 'SU') {
				initValues.availabilitySunday = true;
				initValues.availabilitySundayFrom = rec.start !== null && rec.start !== undefined ? startTime : defaultTimes.from;
				initValues.availabilitySundayTo = rec.end !== null && rec.end !== undefined ? endTime : defaultTimes.to;
			} else if (rec.byDay[0] === 'MO') {
				initValues.availabilityMonday = true;
				initValues.availabilityMondayFrom = rec.start !== null && rec.start !== undefined ? startTime : defaultTimes.from;
				initValues.availabilityMondayTo = rec.end !== null && rec.end !== undefined ? endTime : defaultTimes.to;
			} else if (rec.byDay[0] === 'TU') {
				initValues.availabilityTuesday = true;
				initValues.availabilityTuesdayFrom = rec.start !== null && rec.start !== undefined ? startTime : defaultTimes.from;
				initValues.availabilityTuesdayTo = rec.end !== null && rec.end !== undefined ? endTime : defaultTimes.to;
			} else if (rec.byDay[0] === 'WE') {
				initValues.availabilityWednesday = true;
				initValues.availabilityWednesdayFrom = rec.start !== null && rec.start !== undefined ? startTime : defaultTimes.from;
				initValues.availabilityWednesdayTo = rec.end !== null && rec.end !== undefined ? endTime : defaultTimes.to;
			} else if (rec.byDay[0] === 'TH') {
				initValues.availabilityThursday = true;
				initValues.availabilityThursdayFrom = rec.start !== null && rec.start !== undefined ? startTime : defaultTimes.from;
				initValues.availabilityThursdayTo = rec.end !== null && rec.end !== undefined ? endTime : defaultTimes.to;
			} else if (rec.byDay[0] === 'FR') {
				initValues.availabilityFriday = true;
				initValues.availabilityFridayFrom = rec.start !== null && rec.start !== undefined ? startTime : defaultTimes.from;
				initValues.availabilityFridayTo = rec.end !== null && rec.end !== undefined ? endTime : defaultTimes.to;
			} else if (rec.byDay[0] === 'SA') {
				initValues.availabilitySaturday = true;
				initValues.availabilitySaturdayFrom = rec.start !== null && rec.start !== undefined ? startTime : defaultTimes.from;
				initValues.availabilitySaturdayTo = rec.end !== null && rec.end !== undefined ? endTime : defaultTimes.to;
			}
		});
	}

	// Generate Array of Availability Overrides
	let availabilityOverrides = [];
	if (airportDetails.availability && !airportDetails.availability.byDay) {
		// eslint-disable-next-line
		airportDetails.availability.map((rec, i) => {
			let startTime = new Date(stripTimeZone(rec.start));
			let endTime = new Date(stripTimeZone(rec.end));

			// Add Includes to Array First
			if (rec.byDay.length <= 0 && rec.until !== null && rec.until !== undefined && rec.until !== '') {
				availabilityOverrides.push({
					dateFrom: startTime,
					dateTo: endTime,
					timeFrom: startTime,
					timeTo: endTime,
					frequency: rec.freq,
					closed: false
				});
			}

			// Add Excludes to Array Second
			if (rec.byDay.length <= 0 && rec.until === null) {
				availabilityOverrides.push({
					dateFrom: startTime,
					dateTo: endTime,
					timeFrom: startTime,
					timeTo: endTime,
					frequency: rec.freq,
					closed: true
				});
			}
		});
	}
	//Tracking array for closed checkboxes
	closedArr = availabilityOverrides.map((override) => override.closed === true);

	// Availability Overrides Mapped to Corresponding InitValue Fields
	if (availabilityOverrides.length) {
		// eslint-disable-next-line
		availabilityOverrides.map((rec, i) => {
			initValues.availabilityExceptionsDateRange.push({ dateFrom: '', dateTo: '', timeFrom: '', timeTo: '', frequency: '', closed: '' });
			initValues.availabilityExceptionDateFromField.push({ dateFrom: rec.dateFrom });
			initValues.availabilityExceptionTimeFromField.push({ timeFrom: rec.timeFrom });
			initValues.availabilityExceptionTimeToField.push({ timeTo: rec.timeTo });
			initValues.availabilityExceptionClosedField.push(rec.closed);
		});
	}

	// Init: Populate Comments
	if (airportDetails.comments) {
		// eslint-disable-next-line
		airportDetails.comments.map((rec, i) => {
			initValues.commentField.push({ comments: rec.text });
		});
	}

	const airportTitle = initValues.name ? initValues.name : requestId;

	const mapLatitude = airportDetails.latitude !== null && airportDetails.latitude !== '' ? airportDetails.latitude : '';
	const mapLongitude = airportDetails.longitude !== null && airportDetails.longitude !== '' ? airportDetails.longitude : '';

	// Map Options
	const mapOptions = {
		// Map Styling
		style: {
			width: '100%',
			height: '350px'
		},

		center: {
			lat: mapLatitude,
			lng: mapLongitude
		},

		// Default Zoom Level
		zoom: 4,

		disableDefaultUI: true,

		options: {
			maxZoom: 18,
			minZoom: 3
		}
	};

	const closedOnClick = (idx) => {
		closedArr[idx] = !closedArr[idx];
	};

	const dateOnChange = (event, idx) => {
		setDatesArr((prevDatesArr) => {
			const newArr = [...prevDatesArr];
			newArr[idx] = forceDateStringToUTCISOFormat(event.target.value);
			return newArr;
		});
	};

	const dateValidator = () => {
		let datesSet = new Set(datesArr.map((date) => date.split('T')[0]));
		if (datesArr.includes('')) {
			setSubmissionInvalid(true);
			return 'Error: Missing Date';
		} else if (datesSet.size === datesArr.length) {
			setSubmissionInvalid(false);
			return '';
		} else {
			setSubmissionInvalid(true);
			return 'Error: Duplicate Date Found';
		}
	};

	const requiredValidatorIcaoCode = (value) => {
		if (value) {
			setSubmissionInvalidRequiredIcaoCode(false);
			return '';
		} else {
			setSubmissionInvalidRequiredIcaoCode(true);
			return 'Error: This field is required.';
		}
	};

	const requiredValidatorLat = (value) => {
		if (value) {
			setSubmissionInvalidRequiredLat(false);
			return '';
		} else {
			setSubmissionInvalidRequiredLat(true);
			return 'Error: This field is required.';
		}
	};

	const requiredValidatorLong = (value) => {
		if (value) {
			setSubmissionInvalidRequiredLong(false);
			return '';
		} else {
			setSubmissionInvalidRequiredLong(true);
			return 'Error: This field is required.';
		}
	};

	const checkValidation = () => {
		return submissionInvalid || submissionInvalidRequiredIcaoCode || submissionInvalidRequiredLat || submissionInvalidRequiredLong;
	};

	// Field Array Field: Availability Exception - Date From Cell
	const availabilityExceptionDateFromCell = (props) => {
		return (
			<React.Fragment>
				<td className={'availability-cell availability-exception-date'}>
					<Field key={'availabilityExceptionDateFromField'} id={'availabilityExceptionDateFromField'} name={`availabilityExceptionDateFromField[${props.dataIndex}].${props.field}`} label={'Date (from)'} onChange={(selectedDate) => dateOnChange(selectedDate, props.dataIndex)} component={FormDatePicker} placeholder={'YYYY/MM/DD'} format={'yyyy/MM/dd'} />
				</td>
			</React.Fragment>
		);
	};

	// Field Array Field: Availability Exception - Time From Cell
	const availabilityExceptionTimeFromCell = (props) => {
		return (
			<React.Fragment>
				<td className={'availability-cell availability-exception-time'}>
					<Field key={'availabilityExceptionTimeFromField'} defaultValue={new Date(new Date().setHours(9, 0, 0, 0))} disabled={closedArr[props.dataIndex]} id={'availabilityExceptionTimeFromField'} name={`availabilityExceptionTimeFromField[${props.dataIndex}].${props.field}`} label={'Time (from)'} component={FormTimePicker} placeholder={'Hours:Minutes'} format={'HH:mm'} />
				</td>
			</React.Fragment>
		);
	};

	// Field Array Field: Availability Exception - Time To Cell
	const availabilityExceptionTimeToCell = (props) => {
		return (
			<React.Fragment>
				<td className={'availability-cell availability-exception-time'}>
					<Field key={'availabilityExceptionTimeToField'} defaultValue={new Date(new Date().setHours(17, 0, 0, 0))} disabled={closedArr[props.dataIndex]} id={'availabilityExceptionTimeToField'} name={`availabilityExceptionTimeToField[${props.dataIndex}].${props.field}`} label={'Time (to)'} component={FormTimePicker} placeholder={'Hours:Minutes'} format={'HH:mm'} />
				</td>
			</React.Fragment>
		);
	};

	// Field Array Field: Availability Exception - Closed Cell
	const availabilityExceptionClosedCell = (props) => {
		return (
			<React.Fragment>
				<td className={'availability-cell availability-exception-closed'}>
					<Field key={'availabilityExceptionClosedField'} id={'availabilityExceptionClosedField'} name={`availabilityExceptionClosedField[${props.dataIndex}]`} label={'Closed?'} component={FormCheckbox} onChange={() => closedOnClick(props.dataIndex)} />
				</td>
			</React.Fragment>
		);
	};

	// Field Array Field: Comment Cell
	const commentCell = (props) => {
		return (
			<React.Fragment>
				<td className={'availability-cell asset-date-range'}>
					<Field key={'commentField'} id={'commentField'} name={`commentField[${props.dataIndex}].${props.field}`} label={'Comments'} component={FormTextArea} placeholder={'Details about airport...'} />
				</td>
			</React.Fragment>
		);
	};

	// Field Array Grid: Availability Grid
	const ExceptionsGrid = (fieldArrayRenderProps) => {
		const { validationMessage, visited } = fieldArrayRenderProps;
		const onAdd = React.useCallback(
			(e) => {
				e.preventDefault();
				fieldArrayRenderProps.onUnshift({ value: { dateRange: '', frequency: '', days: '' } });
				closedArr.push(false);
				datesArr.push('');
			},
			[fieldArrayRenderProps]
		);
		const onRemove = React.useCallback(
			(cellProps) => {
				fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex });
				closedArr.pop();
				datesArr.pop();
			},
			[fieldArrayRenderProps.onRemove]
		);

		return (
			<div className={'unavailability-array-grid'}>
				{visited && validationMessage && <Error>{validationMessage}</Error>}
				<Grid data={fieldArrayRenderProps.value}>
					<GridToolbar>
						<button title={'Click to Add Item'} className={'k-button k-primary'} onClick={onAdd}>
							Add Item
							<i className={'far fa-plus'}></i>
						</button>
					</GridToolbar>

					<GridColumn field="dateFrom" title="Date" cell={availabilityExceptionDateFromCell} />
					<GridColumn
						field="timeFrom"
						title="Time (from)"
						cell={availabilityExceptionTimeFromCell}
						headerCell={() => (
							<div>
								{' '}
								Time (from) <div> (UTC/Zulu) </div>
							</div>
						)}
					/>
					<GridColumn
						field="timeTo"
						title="Time (to)"
						cell={availabilityExceptionTimeToCell}
						headerCell={() => (
							<div>
								{' '}
								Time (to) <div> (UTC/Zulu) </div>
							</div>
						)}
					/>
					<GridColumn field="closed" title="Closed?" cell={availabilityExceptionClosedCell} />
					<GridColumn cell={commandCell(onRemove)} width="240px" />
				</Grid>
			</div>
		);
	};

	// Field Array Grid: Comment Grid
	const CommentGrid = (fieldArrayRenderProps) => {
		const { validationMessage, visited } = fieldArrayRenderProps;
		const onAdd = React.useCallback(
			(e) => {
				e.preventDefault();
				fieldArrayRenderProps.onUnshift({ value: { comments: '' } });
			},
			[fieldArrayRenderProps]
		);
		const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps.onRemove]);

		return (
			<div className={'assets-array-grid'}>
				{visited && validationMessage && <Error>{validationMessage}</Error>}
				<Grid data={fieldArrayRenderProps.value}>
					<GridToolbar>
						<button title={'Click to Add New Comment'} className={'k-button k-primary'} onClick={onAdd}>
							Add New Comment
							<i className={'far fa-plus'}></i>
						</button>
					</GridToolbar>
					<GridColumn field="comments" title="Comments" cell={commentCell} />
					<GridColumn cell={commandCell(onRemove)} width="240px" />
				</Grid>
			</div>
		);
	};

	const dataLoad = () => {
		// Get Airport Type List
		ApiGetAirportTypeRequest().then((res) => {
			setAirportTypeList(res);
		});
		ApiAirportById(params.airportId).then((res) => {
			setGeKloadChecked(res.history[0] !== null && res.history[0].groundSupportEquipment[0] !== undefined && res.history[0].groundSupportEquipment[0].quantity === 1 ? true : false);
			setGeGpuChecked(res.history[0] !== null && res.history[0].groundSupportEquipment[1] !== undefined && res.history[0].groundSupportEquipment[1].quantity === 1 ? true : false);
			setGeApuChecked(res.history[0] !== null && res.history[0].groundSupportEquipment[2] !== undefined && res.history[0].groundSupportEquipment[2].quantity === 1 ? true : false);

			setAirportDetails(res.history[0]);
			setDatesArr(res.history[0].availability.map((availObj) => (availObj.byDay.length ? undefined : availObj.start.split('T')[0])).filter((date) => date !== undefined));
			setLoaded(true);

			// Set Normal Operating Hours Conditions on Load
			// eslint-disable-next-line
			res.history[0].availability.map((rec) => {
				if (rec.byDay[0] === 'SU') {
					setNormalSunday(true);
				} else if (rec.byDay[0] === 'MO') {
					setNormalMonday(true);
				} else if (rec.byDay[0] === 'TU') {
					setNormalTuesday(true);
				} else if (rec.byDay[0] === 'WE') {
					setNormalWednesday(true);
				} else if (rec.byDay[0] === 'TH') {
					setNormalThursday(true);
				} else if (rec.byDay[0] === 'FR') {
					setNormalFriday(true);
				} else if (rec.byDay[0] === 'SA') {
					setNormalSaturday(true);
				}
			});
		});
	};

	useEffect(() => {
		dataLoad();
	}, [props]);

	const handleAirportDeletion = (id) => {
		ApiDeleteAirportRequest(id);
		toast.success('Airport Deleted Successfully!');
		navigate('/airportslist');
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const openDialog = (id) => {
		toggleDialog();
	};

	const DialogWindow = (id) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleDialog}>
				<div data-id={id}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to delete this airport?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-gray" title={'Click to Cancel'} onClick={toggleDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-gray"
						title={'Click to Confirm'}
						onClick={() => {
							handleAirportDeletion(id);
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<NavLink
							className={'nav-link btn-delete'}
							role={'button'}
							to={`#`}
							title={`Click to Delete Airport`}
							onClick={() => {
								openDialog(params.airportId);
							}}>
							<i className="fa-solid fa-trash"></i>
							Delete Airport
						</NavLink>

						{visible && DialogWindow(params.airportId)}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	const navigate = useNavigate();

	// On Form Submit Event
	const onFormSubmit = React.useCallback((event) => {
		const { values } = event;

		setFormState(values);

		// Main Object
		let submissionData = {
			dafifIdentifier: values.dafifIdentifier ? values.dafifIdentifier : '',
			name: values.name ? values.name : '',
			icao: values.icao ? values.icao : '',
			iata: values.iata ? values.iata : '',
			latitude: values.latitude && values.longitude ? getLatitudeLongitudeDecimalDegrees(values.latitude, values.longitude, 0) : null,
			longitude: values.latitude && values.longitude ? getLatitudeLongitudeDecimalDegrees(values.latitude, values.longitude, 1) : null,
			elevation: values.elevation ? values.elevation : '',
			country: values.countryCode ? { code: values.countryCode.value, name: values.countryCode.label } : {},
			status: values.status ? values.status : '',
			availability: [],
			allowedAircraft: values.allowedAircraft ? values.allowedAircraft : [],
			type: values.type ? values.type : {},
			groundSupportEquipment: [
				{
					equipmentType: 'K_LOADER',
					quantity: values.groundEquipmentKLoader ? 1 : 0
				},
				{
					equipmentType: 'GPU',
					quantity: values.groundEquipmentGpu ? 1 : 0
				},
				{
					equipmentType: 'APU',
					quantity: values.groundEquipmentApu ? 1 : 0
				}
			],
			timeZoneIdentifier: values.timeZoneIdentifier ? values.timeZoneIdentifier : '',
			createdBy: {
				id: props.profile.id,
				firstName: props.profile.firstName,
				lastName: props.profile.lastName,
				email: props.profile.email
			},
			comments: []
		};

		// Populate Comments
		if (values.commentField) {
			// eslint-disable-next-line
			values.commentField.map((rec, i) => {
				submissionData.comments.push({ text: rec.comments });
			});
		}

		// Normal Operating Days Selection
		const availabilityRuleByDay = [];
		if (values.availabilitySunday) {
			availabilityRuleByDay.push('SU');
		}
		if (values.availabilityMonday) {
			availabilityRuleByDay.push('MO');
		}
		if (values.availabilityTuesday) {
			availabilityRuleByDay.push('TU');
		}
		if (values.availabilityWednesday) {
			availabilityRuleByDay.push('WE');
		}
		if (values.availabilityThursday) {
			availabilityRuleByDay.push('TH');
		}
		if (values.availabilityFriday) {
			availabilityRuleByDay.push('FR');
		}
		if (values.availabilitySaturday) {
			availabilityRuleByDay.push('SA');
		}

		// Date(s)/Time(s) to Exclude from Normal Days of Operation
		let availabilityExceptionExclusions = [];
		let availabilityExceptionExclusionsFinal = [];
		if (values.availabilityExceptionsDateRange !== null && values.availabilityExceptionsDateRange !== undefined && values.availabilityExceptionsDateRange !== '') {
			// eslint-disable-next-line
			values.availabilityExceptionsDateRange.map((rec, i) => {
				let dateFrom = new Date(values.availabilityExceptionDateFromField[i].dateFrom);

				// Zeroing Out "From" Time
				dateFrom.setHours(0);
				dateFrom.setMinutes(0);
				dateFrom.setSeconds(0);

				//currently only supports single date ranges
				let dateTo = dateFrom;
				availabilityExceptionExclusions.push(getDates(dateFrom, dateTo));
			});

			availabilityExceptionExclusionsFinal = [].concat(...availabilityExceptionExclusions);
		}

		// If Days selected for Normal Operating Hours
		if (availabilityRuleByDay.length) {
			// eslint-disable-next-line
			availabilityRuleByDay.map((rec, i) => {
				// For availability override for including updated times, "date from" / "time from" and
				// "date to" / "time to" will be appended to availability after all normal + exceptions,
				// and will need to set "until", which will be the same as the end "date/time".
				submissionData.availability.push({
					// Start Time
					start: rec === 'SU' ? forceDateStringToUTCISOFormat(values.availabilitySundayFrom) : rec === 'MO' ? forceDateStringToUTCISOFormat(values.availabilityMondayFrom) : rec === 'TU' ? forceDateStringToUTCISOFormat(values.availabilityTuesdayFrom) : rec === 'WE' ? forceDateStringToUTCISOFormat(values.availabilityWednesdayFrom) : rec === 'TH' ? forceDateStringToUTCISOFormat(values.availabilityThursdayFrom) : rec === 'FR' ? forceDateStringToUTCISOFormat(values.availabilityFridayFrom) : rec === 'SA' ? forceDateStringToUTCISOFormat(values.availabilitySaturdayFrom) : null,

					// End Time
					end: rec === 'SU' ? forceDateStringToUTCISOFormat(values.availabilitySundayTo) : rec === 'MO' ? forceDateStringToUTCISOFormat(values.availabilityMondayTo) : rec === 'TU' ? forceDateStringToUTCISOFormat(values.availabilityTuesdayTo) : rec === 'WE' ? forceDateStringToUTCISOFormat(values.availabilityWednesdayTo) : rec === 'TH' ? forceDateStringToUTCISOFormat(values.availabilityThursdayTo) : rec === 'FR' ? forceDateStringToUTCISOFormat(values.availabilityFridayTo) : rec === 'SA' ? forceDateStringToUTCISOFormat(values.availabilitySaturdayTo) : null,

					// Frequency of Availability Rule
					freq: 'WEEKLY',

					// If "until" and "count" are null it indicates "till the end of time"
					until: null,
					count: null,

					byHour: null,

					// Day of the Week (Array)
					byDay: [rec] !== '' ? [rec] : null,

					byMonthDay: null,
					byYearDay: null,
					byWeekNumber: null,
					byMonth: null,

					interval: null,

					// Exclusion Exception(s)
					// Array of date strings. Will be based on day only, so format to include only
					// day and not time. This is where time is zeroed out. This will exclude
					// days from the availability
					recurrenceException: availabilityExceptionExclusionsFinal
				});
			});
		}

		// Date(s)/Time(s) to Include
		if (values.availabilityExceptionsDateRange !== null && values.availabilityExceptionsDateRange !== undefined && values.availabilityExceptionsDateRange !== '') {
			// eslint-disable-next-line
			values.availabilityExceptionsDateRange.map((rec, i) => {
				// Inclusions
				if (
					!values.availabilityExceptionClosedField[i] && // Is Not Checked
					values.availabilityExceptionDateFromField[i] !== null &&
					values.availabilityExceptionDateFromField[i] !== undefined
				) {
					let dateFrom = new Date(values.availabilityExceptionDateFromField[i].dateFrom);
					//currently only supports single date ranges
					let dateTo = new Date(values.availabilityExceptionDateFromField[i].dateFrom);

					let timeFromHours;
					let timeFromMinutes;
					let timeToHours;
					let timeToMinutes;

					//If user left time from as 0900
					if (values.availabilityExceptionTimeFromField[i] === null || values.availabilityExceptionTimeFromField[i] === undefined) {
						timeFromHours = 9;
						timeFromMinutes = 0;
					} else {
						timeFromHours = new Date(values.availabilityExceptionTimeFromField[i].timeFrom).getHours();
						timeFromMinutes = new Date(values.availabilityExceptionTimeFromField[i].timeFrom).getMinutes();
					}

					//If user left time to as 1700
					if (values.availabilityExceptionTimeFromField[i] === null || values.availabilityExceptionTimeFromField[i] === undefined) {
						timeToHours = 17;
						timeToMinutes = 0;
					} else {
						timeToHours = new Date(values.availabilityExceptionTimeToField[i].timeTo).getHours();
						timeToMinutes = new Date(values.availabilityExceptionTimeToField[i].timeTo).getMinutes();
					}

					dateFrom.setHours(timeFromHours);
					dateFrom.setMinutes(timeFromMinutes);

					dateTo.setHours(timeToHours);
					dateTo.setMinutes(timeToMinutes);

					const dateFromFinal = dateFrom;
					const dateToFinal = dateTo;

					submissionData.availability.push({
						start: forceDateStringToUTCISOFormat(dateFromFinal),

						end: forceDateStringToUTCISOFormat(dateToFinal),

						// Frequency of Availability Rule
						freq: null,

						// If "until" and "count" are null it indicates "till the end of time"
						until: forceDateStringToUTCISOFormat(dateToFinal),
						count: null,

						byHour: null,
						byDay: null,
						byMonthDay: null,
						byYearDay: null,
						byWeekNumber: null,
						byMonth: null,
						interval: null,
						recurrenceException: []
					});
				}

				// Exclusions
				if (
					values.availabilityExceptionClosedField[i] && // Is Checked
					values.availabilityExceptionDateFromField[i] !== null &&
					values.availabilityExceptionDateFromField[i] !== undefined
				) {
					let dateFrom = new Date(values.availabilityExceptionDateFromField[i].dateFrom);
					//currently only supports single date ranges
					let dateTo = new Date(values.availabilityExceptionDateFromField[i].dateFrom);

					dateFrom.setHours(0);
					dateFrom.setMinutes(0);

					dateTo.setHours(0);
					dateTo.setMinutes(0);

					const dateFromFinal = dateFrom;
					const dateToFinal = dateTo;

					submissionData.availability.push({
						start: forceDateStringToUTCISOFormat(dateFromFinal),

						end: forceDateStringToUTCISOFormat(dateToFinal),

						// Frequency of Availability Rule
						freq: null,

						// If "until" and "count" are null it indicates "till the end of time"
						until: null,
						count: null,

						byHour: null,
						byDay: null,
						byMonthDay: null,
						byYearDay: null,
						byWeekNumber: null,
						byMonth: null,
						interval: null,
						recurrenceException: []
					});
				}
			});
		}

		ApiEditAirportRequest(params.airportId, submissionData).then((res) => {
			toast.success('Airport Updated Successfully!');
			navigate('/airportslist');
		});
	}, []);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						{loaded ? (
							<React.Fragment>
								<div className={'page-title'}>
									<h2>Airport: {airportTitle}</h2>

									{PageTitleControls()}
								</div>

								<Form
									onSubmitClick={onFormSubmit}
									key={airportDetails}
									initialValues={initValues}
									render={(formRenderProps) => (
										<div className={'airport-form'}>
											<div className={'airport-form-inner'}>
												<FormElement>
													<Row id={'airport-name-and-status'} className={'form-section'}>
														{/* Section Title - Airport Name and Status: Start */}
														<div className={'col-12 form-section-title airport-name-and-status'}>
															<h3>Name and Status</h3>
														</div>
														{/* Section Title - Airport Name and Status: End */}

														{/* Name: Start */}
														<div className={'col-4'}>
															<Field key={'name'} id={'name'} name={'name'} label={'Airport Name'} placeholder={'E.g. Louis Armstrong New Orleans International Airport'} component={FormInput} />
														</div>
														{/* Name: End */}

														{/* Airport Type: Start */}
														<div className={'col-4'}>
															<Field key={'type'} id={'type'} name={'type'} label={'Airport Type'} defaultItem={'Select Type...'} component={FormDropDownList} data={airportTypeList} textField={'shortDescription'} dataItemKey={'type'} />
														</div>
														{/* Airport Type: End */}

														{/* Airport Status: Start */}
														<div className={'col-4'}>
															<Field key={'status'} id={'status'} name={'status'} label={'Airport Status'} defaultItem={'Select Status...'} component={FormDropDownList} data={optionsAirportStatuses} />
														</div>
														{/* Airport Status: End */}
													</Row>

													<Row id={'airport-codes'} className={'form-section'}>
														{/* Section Title - Airport Codes: Start */}
														<div className={'col-12 form-section-title airport-codes'}>
															<h3>Airport Codes</h3>
														</div>
														{/* Section Title - Airport Codes: End */}

														{/* DAFIF Identifier: Start */}
														<div className={'col-4'}>
															<Field key={'dafifIdentifier'} id={'dafifIdentifier'} name={'dafifIdentifier'} label={'DAFIF Identifier'} placeholder={'E.g. US12345'} component={FormInput} />
														</div>
														{/* DAFIF Identifier: End */}

														{/* ICAO: Start */}
														<div className={'col-4 required-field'}>
															<Field key={'icao'} id={'icao'} name={'icao'} label={'Airport ICAO Code'} placeholder={'E.g. KMSY'} component={FormInput} validator={requiredValidatorIcaoCode} />
														</div>
														{/* ICAO: End */}

														{/* IATA: Start */}
														<div className={'col-4'}>
															<Field key={'iata'} id={'iata'} name={'iata'} label={'Airport IATA Code'} placeholder={'E.g. MSY'} component={FormInput} />
														</div>
														{/* IATA: End */}

														{/* Country Code: Start */}
														<div className={'col-4'}>
															<Field key={'countryCode'} id={'countryCode'} name={'countryCode'} label={'Country Code'} defaultItem={'Select Country...'} component={FormDropDownList} data={optionsCountries} textField={'valueandlabel'} dataItemKey={'value'} />
														</div>
														{/* Country Code: End */}
													</Row>

													<Row id={'airport-details'} className={'form-section'}>
														{/* Section Title - Airport Details: Start */}
														<div className={'col-12 form-section-title airport-details'}>
															<h3>Details</h3>
														</div>
														{/* Section Title - Airport Details: End */}

														{/* Airport Timezone: Start */}
														<div className={'col-4'}>
															<Field key={'timeZoneIdentifier'} id={'timeZoneIdentifier'} name={'timeZoneIdentifier'} label={'Airport Timezone'} defaultItem={'Select Timezone...'} component={FormComboBox} data={optionsTimezones} />
														</div>
														{/* Airport Timezone: End */}

														{/* Allowed Asset Types: Start */}
														<div className={'col-4'}>
															<Field key={'allowedAircraft'} id={'allowedAircraft'} name={'allowedAircraft'} label={'Supported Asset Types'} defaultItem={'Select Supported Asset Types...'} component={FormMultiSelect} data={optionsAssetTypes} hint={'You can make multiple selections.'} />
														</div>
														{/* Allowed Asset Types: End */}
													</Row>

													<Row id={'airport-equipment'} className={'form-section'}>
														{/* Section Title - Airport Equipment: Start */}
														<div className={'col-12 form-section-title airport-equipment'}>
															<h3>Ground Support Equipment</h3>
														</div>
														{/* Section Title - Airport Equipment: End */}

														<div className={'col-12 switch-option ground-equipment-row ge-kloader-row'}>
															<p>K_LOADER</p>
															<Field key={'groundEquipmentKLoader'} name={'groundEquipmentKLoader'} id={'groundEquipmentKLoader'} component={FormSwitch} defaultChecked={false} className={'groundEquipmentKLoader'} size={'medium'} trackRounded={'medium'} thumbRounded={'medium'} onLabel={'Enabled'} offLabel={'Disabled'} onChange={handleGeKloadCheck} />
														</div>

														<div className={'col-12 switch-option ground-equipment-row ge-gpu-row'}>
															<p>GPU</p>
															<Field id={'groundEquipmentGpu'} key={'groundEquipmentGpu'} name={'groundEquipmentGpu'} component={FormSwitch} defaultChecked={false} className={'groundEquipmentGpu'} size={'medium'} trackRounded={'medium'} thumbRounded={'medium'} onLabel={'Enabled'} offLabel={'Disabled'} onChange={handleGeGpuCheck} />
														</div>

														<div className={'col-12 switch-option ground-equipment-row ge-apu-row'}>
															<p>APU</p>
															<Field id={'groundEquipmentApu'} key={'groundEquipmentApu'} name={'groundEquipmentApu'} component={FormSwitch} defaultChecked={false} className={'groundEquipmentApu'} size={'medium'} trackRounded={'medium'} thumbRounded={'medium'} onLabel={'Enabled'} offLabel={'Disabled'} onChange={handleGeApuCheck} />
														</div>
													</Row>

													<Row id={'airport-location-coordinates'} className={'form-section'}>
														{/* Section Title - Airport Codes: Start */}
														<div className={'col-12 form-section-title airport-location-coordinates'}>
															<h3>Airport Coordinates (Latitude/Longitude)</h3>
														</div>
														{/* Section Title - Airport Codes: End */}

														{/* Latitude: Start */}
														<div className={'col-4 required-field'}>
															<Field key={'latitude'} id={'latitude'} name={'latitude'} label={'Latitude Coordinate'} placeholder={"E.g. 29.9922012 or 29° 59.532' N"} component={FormInput} validator={requiredValidatorLat} />
														</div>
														{/* Latitude: End */}

														{/* Longitude: Start */}
														<div className={'col-4 required-field'}>
															<Field key={'longitude'} id={'longitude'} name={'longitude'} label={'Longitude Coordinate'} placeholder={"E.g. -90.2590112 or 90° 15.541' W"} component={FormInput} validator={requiredValidatorLong} />
														</div>
														{/* Longitude: End */}

														{/* Elevation: Start */}
														<div className={'col-4'}>
															<Field key={'elevation'} id={'elevation'} name={'elevation'} label={'Elevation'} placeholder={'E.g. 28'} component={FormInput} />
														</div>
														{/* Elevation: End */}
													</Row>

													<Row id={'normal-airport-availability'} className={'form-section'}>
														{/* Section Title - Airport Hours of Operation: Start */}
														<div className={'col-12 form-section-title airport-availability'} style={{ display: 'inline' }}>
															<h3>
																Normal Operating Hours <span style={{ fontSize: '0.9rem' }}>(UTC/Zulu)</span>{' '}
															</h3>
														</div>
														{/* Section Title - Airport Hours of Operation: End */}

														<ul className={'checkbox-list airport-operating-hours'}>
															<li>
																<Field label={'Sunday'} id={'availabilitySunday'} name={'availabilitySunday'} className={'day-field'} component={FormCheckbox} onChange={handleNormalSunday} />
																{normalSunday === true ? (
																	<React.Fragment>
																		<Field label={'From'} id={'availabilitySundayFrom'} name={'availabilitySundayFrom'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																		<Field label={'To'} id={'availabilitySundayTo'} name={'availabilitySundayTo'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																	</React.Fragment>
																) : (
																	<div className={'k-form-field'}>
																		<label className={'k-label padding-left-20'}>(Closed)</label>
																	</div>
																)}
															</li>
															<li>
																<Field label={'Monday'} id={'availabilityMonday'} name={'availabilityMonday'} className={'day-field'} component={FormCheckbox} onChange={handleNormalMonday} />
																{normalMonday === true ? (
																	<React.Fragment>
																		<Field label={'From'} id={'availabilityMondayFrom'} name={'availabilityMondayFrom'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																		<Field label={'To'} id={'availabilityMondayTo'} name={'availabilityMondayTo'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																	</React.Fragment>
																) : (
																	<div className={'k-form-field'}>
																		<label className={'k-label padding-left-20'}>(Closed)</label>
																	</div>
																)}
															</li>
															<li>
																<Field label={'Tuesday'} id={'availabilityTuesday'} name={'availabilityTuesday'} className={'day-field'} component={FormCheckbox} onChange={handleNormalTuesday} />
																{normalTuesday === true ? (
																	<React.Fragment>
																		<Field label={'From'} id={'availabilityTuesdayFrom'} name={'availabilityTuesdayFrom'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																		<Field label={'To'} id={'availabilityTuesdayTo'} name={'availabilityTuesdayTo'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																	</React.Fragment>
																) : (
																	<div className={'k-form-field'}>
																		<label className={'k-label padding-left-20'}>(Closed)</label>
																	</div>
																)}
															</li>
															<li>
																<Field label={'Wednesday'} id={'availabilityWednesday'} name={'availabilityWednesday'} className={'day-field'} component={FormCheckbox} onChange={handleNormalWednesday} />
																{normalWednesday === true ? (
																	<React.Fragment>
																		<Field label={'From'} id={'availabilityWednesdayFrom'} name={'availabilityWednesdayFrom'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																		<Field label={'To'} id={'availabilityWednesdayTo'} name={'availabilityWednesdayTo'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																	</React.Fragment>
																) : (
																	<div className={'k-form-field'}>
																		<label className={'k-label padding-left-20'}>(Closed)</label>
																	</div>
																)}
															</li>
															<li>
																<Field label={'Thursday'} id={'availabilityThursday'} name={'availabilityThursday'} className={'day-field'} component={FormCheckbox} onChange={handleNormalThursday} />
																{normalThursday === true ? (
																	<React.Fragment>
																		<Field label={'From'} id={'availabilityThursdayFrom'} name={'availabilityThursdayFrom'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																		<Field label={'To'} id={'availabilityThursdayTo'} name={'availabilityThursdayTo'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																	</React.Fragment>
																) : (
																	<div className={'k-form-field'}>
																		<label className={'k-label padding-left-20'}>(Closed)</label>
																	</div>
																)}
															</li>
															<li>
																<Field label={'Friday'} id={'availabilityFriday'} name={'availabilityFriday'} className={'day-field'} component={FormCheckbox} onChange={handleNormalFriday} />
																{normalFriday === true ? (
																	<React.Fragment>
																		<Field label={'From'} id={'availabilityFridayFrom'} name={'availabilityFridayFrom'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																		<Field label={'To'} id={'availabilityFridayTo'} name={'availabilityFridayTo'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																	</React.Fragment>
																) : (
																	<div className={'k-form-field'}>
																		<label className={'k-label padding-left-20'}>(Closed)</label>
																	</div>
																)}
															</li>
															<li>
																<Field label={'Saturday'} id={'availabilitySaturday'} name={'availabilitySaturday'} className={'day-field'} component={FormCheckbox} onChange={handleNormalSaturday} />
																{normalSaturday === true ? (
																	<React.Fragment>
																		<Field label={'From'} id={'availabilitySaturdayFrom'} name={'availabilitySaturdayFrom'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																		<Field label={'To'} id={'availabilitySaturdayTo'} name={'availabilitySaturdayTo'} className={'time-field'} placeholder={'Hours:Minutes'} format={'HH:mm'} component={FormTimePicker} />
																	</React.Fragment>
																) : (
																	<div className={'k-form-field'}>
																		<label className={'k-label padding-left-20'}>(Closed)</label>
																	</div>
																)}
															</li>
														</ul>
													</Row>

													<Row id={'airport-unavailability'} className={'form-section'}>
														{/* Section Title - Airport Periods of Unavailability: Start */}
														<div className={'col-12 form-section-title airport-availability'}>
															<h3>Availability Override</h3>
														</div>
														{/* Section Title - Airport Periods of Unavailability: End */}

														{/* Exceptions: Start */}
														<div className={'col-12'}>
															<FieldArray name="availabilityExceptionsDateRange" component={ExceptionsGrid} validator={dateValidator} />
														</div>
														{/* Exceptions: End */}
													</Row>

													<Row id={'airport-comments'} className={'form-section'}>
														{/* Section Title - Airport Comments: Start */}
														<div className={'col-12 form-section-title airport-comments'}>
															<h3>Comments</h3>
														</div>
														{/* Section Title - Airport Comments: End */}

														{/* Comments: Start */}
														<div className={'col-12'}>
															<FieldArray name="commentField" component={CommentGrid} />
														</div>
														{/* Comments: End */}
													</Row>

													{/* Map View: Start */}
													{airportDetails.latitude !== null && airportDetails.latitude !== undefined && airportDetails.longitude !== null && airportDetails.longitude !== undefined && (
														<Row id={'airport-location-map'}>
															<div className={'col-12 padding-bottom-15'}>
																<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
																	<GoogleMap mapContainerStyle={mapOptions.style} center={mapOptions.center} zoom={mapOptions.zoom} options={mapOptions.options}>
																		<OverlayView
																			position={{
																				lat: mapLatitude,
																				lng: mapLongitude
																			}}
																			mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
																			<Marker
																				position={{
																					lat: mapLatitude,
																					lng: mapLongitude
																				}}
																				type={'dot'}
																				name={props.modalTitle}
																				lat={mapLatitude}
																				lng={mapLongitude}
																			/>
																		</OverlayView>
																	</GoogleMap>
																</LoadScript>
															</div>
														</Row>
													)}
													{/* Map View: Start */}

													{/* Submission: Start */}
													<Row id={'form-submission-buttons'} className={'form-section form-submission-buttons k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'}>
														<div className={'col-12 align-right'}>
															<Button title={'Click to Save Airport Details'} className={'btn btn-size-medium btn-primary btn-color-orange margin-right-0 btn-submit'} disabled={checkValidation()} onClick={formRenderProps.onSubmit}>
																Save Airport Details
																<i className={'far fa-arrow-right'}></i>
															</Button>
														</div>
													</Row>
													{/* Submission: End */}
												</FormElement>
											</div>
										</div>
									)}
								/>
							</React.Fragment>
						) : (
							<Loader />
						)}
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AirportEdit);
