/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Form, FormElement, Field, FieldArray } from '@progress/kendo-react-form';
import { FormInput, FormDateRangePicker, FormDropDownList } from '../../App/KendoFormElements/FormComponents';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import { ApiAssetById, ApiEditAssetRequest, ApiOrgSquadronList } from '../../Helpers/Apis';
import { forceDateStringToUTCISOFormatEOD, forceDateStringToUTCISOFormatSOD, stripTimeZone } from '../../Helpers/DateTimeConversions';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Loader from '../../Core/Loader';
import { optionsAssetStatusCodesC40, optionsAssetStatusCodesC130T, optionsAssetTypes } from '../../../Assets/json/formOptions';
import { Error } from '@progress/kendo-react-labels';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';

import { toast } from 'react-toastify';

const commandCell = (onRemove) => (props) => {
	const onClick = React.useCallback(
		(e) => {
			e.preventDefault();
			onRemove(props);
		},
		[onRemove]
	);

	return (
		<td className={'summary-actions-cell'}>
			<button className={'button btn-icon-remove btn-color-red margin-top-22 margin-right-0 padding-top-12 padding-bottom-12 k-button k-grid-remove-command'} title={'Click to Remove Row'} onClick={onClick}>
				Remove
			</button>
		</td>
	);
};

const AssetEdit = (props) => {
	const [loaded, setLoaded] = React.useState(false);
	const [assetDetails, setAssetDetails] = React.useState([]);
	const [squadronList, setSquadronList] = React.useState([]);
	// eslint-disable-next-line
	const [selectedSquadron, setSelectedSquadron] = React.useState('');
	const [assetType, setAssetType] = React.useState('');
	const [formState, setFormState] = React.useState({});
	const [tailNumber, setTailNumber] = React.useState('');
	const [submissionInvalidAssetType, setsubmissionInvalidAssetType] = React.useState(true);
	const [submissionInvalidHomeIcao, setsubmissionInvalidHomeIcao] = React.useState(true);

	const requiredValidatorAssetType = (value) => {
		if (value && value !== 'Select Asset Type...') {
			setsubmissionInvalidAssetType(false);
			return '';
		} else {
			setsubmissionInvalidAssetType(true);
			return 'Error: This field is required.';
		}
	};

	const requiredValidatorHomeIcao = (value) => {
		if (value) {
			setsubmissionInvalidHomeIcao(false);
			return '';
		} else {
			setsubmissionInvalidHomeIcao(true);
			return 'Error: This field is required.';
		}
	};

	const params = useParams();

	const assignedSquadronChangeHandler = (e) => {
		setSelectedSquadron(e.value.id);
	};

	const assetTypeChangeHandler = (e) => {
		setAssetType(e.value);
	};

	// Status Value Renderer
	const statusValueRender = (element) => {
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(element);
		}

		if (!element.props.value) {
			return element;
		}

		const newElement = { ...element };

		newElement.props = {
			...element.props,
			value: element.props.value
		};

		return <>{newElement}</>;
	};

	// Status Item Renderer
	const statusItemRender = (li, itemProps) => {
		//const index = itemProps.index;

		const itemChildren = <>{itemProps.dataItem.code + ': ' + itemProps.dataItem.label}</>;

		return React.cloneElement(li, li.props, itemChildren);
	};

	// Dates of Unavailability
	const unavailabilityDatesCell = (props) => {
		return (
			<td className={'availability-cell asset-date-range'}>
				<Field key={'assetDateRange'} id={'assetDateRange'} name={`assetDateRange[${props.dataIndex}].${props.field}`} label={'Range of Unavailability'} component={FormDateRangePicker} placeholder={'yyyy/MM/dd'} format={'yyyy/MM/dd'} />
			</td>
		);
	};

	// Status of Unavailability
	const unavailabilityStatusCell = (props) => {
		return (
			<td className={'availability-cell asset-status-code'} style={{ width: '200px' }}>
				{assetType === 'C-40' && <Field key={'assetStatusCode'} id={'assetStatusCode'} className={'asset-status-code c-40'} name={`assetStatusCode[${props.dataIndex}].${props.field}`} label={'Status Code'} defaultItem={'Select Status Code...'} component={FormDropDownList} valueRender={statusValueRender} itemRender={statusItemRender} data={optionsAssetStatusCodesC40} value={optionsAssetStatusCodesC40} textField={'code'} dataItemKey={'label'} />}

				{assetType === 'C-130T' && <Field key={'assetStatusCode'} id={'assetStatusCode'} className={'asset-status-code c-130t'} name={`assetStatusCode[${props.dataIndex}].${props.field}`} label={'Status Code'} defaultItem={'Select Status Code...'} component={FormDropDownList} valueRender={statusValueRender} itemRender={statusItemRender} data={optionsAssetStatusCodesC130T} value={optionsAssetStatusCodesC130T} textField={'code'} dataItemKey={'label'} />}
			</td>
		);
	};

	// Repeater: Unavailability Grid
	const AssetUnavailabilityRangeGrid = (fieldArrayRenderProps) => {
		const { validationMessage, visited } = fieldArrayRenderProps;
		const onAdd = React.useCallback(
			(e) => {
				e.preventDefault();
				fieldArrayRenderProps.onUnshift({ value: { dates: '', status: '' } });
			},
			[fieldArrayRenderProps]
		);
		const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps.onRemove]);

		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(fieldArrayRenderProps);
		}

		return (
			<div className={'assets-array-grid'}>
				{visited && validationMessage && <Error>{validationMessage}</Error>}
				<Grid data={fieldArrayRenderProps.value}>
					<GridToolbar>
						<button title={'Click to Add New'} className={'k-button k-primary'} onClick={onAdd}>
							Add New
							<i className={'far fa-plus'}></i>
						</button>
					</GridToolbar>
					<GridColumn field="dates" title="Range of Unavailability (UTC/Zulu)" cell={unavailabilityDatesCell} />
					<GridColumn field="status" title="Status" cell={unavailabilityStatusCell} />
					<GridColumn cell={commandCell(onRemove)} width="240px" />
				</Grid>
			</div>
		);
	};

	// Form Initial Values
	const initValues = {
		...formState,

		assignedSquadron: {},

		assetId: assetDetails !== null && assetDetails.id !== null ? assetDetails.id : '',

		assetType: assetDetails !== null && assetDetails.assetType !== null && assetDetails.assetType !== undefined && assetDetails.assetType.id !== null ? assetDetails.assetType.id : '',

		assetTailNumber: assetDetails !== null && assetDetails.tailNumber !== null && assetDetails.tailNumber !== undefined ? assetDetails.tailNumber : '',

		assetHomeIcao: assetDetails !== null && assetDetails.homeLocation !== null && assetDetails.homeLocation !== undefined && assetDetails.homeLocation.icao !== null ? assetDetails.homeLocation.icao : '',

		assetUnavailabilityInfo: [],

		assetStatusCode: [],

		assetDateRange: []
	};

	// Populate Squadron Dropdown
	if (assetDetails.squadronId !== null && assetDetails.squadronId !== undefined) {
		// eslint-disable-next-line
		squadronList.map((rec, i) => {
			if (assetDetails.squadronId === rec.id) {
				initValues.assignedSquadron = {
					aircraft: rec.aircraft,
					fixedWingSquadron: rec.fixedWingSquadron,
					id: rec.id,
					insignia: rec.insignia,
					nickname: rec.nickname,
					notes: rec.notes,
					operationalAndAdministrativeCommander: rec.operationalAndAdministrativeCommander,
					squadronDesignation: rec.squadronDesignation,
					squadronLineage: rec.squadronLineage
				};
			}
		});
	}

	// Populate assetDateRange with Asset Date Range and Asset Status Code Object(s) Data
	if (assetDetails.status !== null && assetDetails.status !== undefined) {
		// eslint-disable-next-line
		assetDetails.status.map((rec, i) => {
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log(rec);
			}

			// If C-40
			if (assetDetails.assetType.id === 'C-40') {
				// eslint-disable-next-line
				optionsAssetStatusCodesC40.map((record, index) => {
					if (rec.reason.code === record.code) {
						initValues.assetStatusCode.push({
							status: {
								code: record.code,
								codeAndLabel: record.codeAndLabel,
								description: record.description,
								label: record.label,
								type: record.type
							}
						});
					}
				});
			}

			// If C-130T
			if (assetDetails.assetType.id === 'C-130T') {
				// eslint-disable-next-line
				optionsAssetStatusCodesC130T.map((record, index) => {
					if (rec.reason.code === record.code) {
						initValues.assetStatusCode.push({
							status: {
								code: record.code,
								codeAndLabel: record.codeAndLabel,
								description: record.description,
								label: record.label,
								type: record.type
							}
						});
					}
				});
			}

			// Generate Field Array Rows
			initValues.assetUnavailabilityInfo.push({});

			// Push to assetDateRange
			initValues.assetDateRange.push({
				dates: {
					start: new Date(stripTimeZone(rec.start.planned)),
					end: new Date(stripTimeZone(rec.end.planned))
				}
			});

			return false;
		});

		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(initValues);
		}
	}

	const dataLoad = () => {
		ApiOrgSquadronList().then((res) => {
			setSquadronList(res);
		});

		setTimeout(() => {
			ApiAssetById(params.assetId).then((res) => {
				setAssetDetails(res);
				setTailNumber(res.tailNumber);
				setAssetType(res.assetType.id);
				setSelectedSquadron(res.squadronId);
				setLoaded(true);
			});
		}, 500);
	};

	useEffect(() => {
		dataLoad();
	}, [props]);

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto"></Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	const navigate = useNavigate();

	// On Form Submit Event
	const onFormSubmit = React.useCallback((event) => {
		const { values } = event;

		setFormState(values);

		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(values);
		}

		// Main Object
		let submissionData = {
			assetType: {
				series: null,
				maxMissionDays: null,
				id: values.assetType ? values.assetType : null,
				max_segment_hr: null,
				ground_speed_knots: null,
				turnaround_time: null,
				pax_cargo_combined_weight: null,
				home_reconfiguration: null,
				reconfiguration_length: null,
				configuration_options: null
			},
			homeLocation: {
				id: null,
				icao: values.assetHomeIcao ? values.assetHomeIcao : null,
				iata: null,
				name: null,
				country: { code: null },
				latitude: null,
				longitude: null,
				status: null,
				aircraftTravelTime: null,
				openTime: null,
				closeTime: null
			},
			ownerOrg: 5,
			schedulingOrg: 0,
			tailNumber: values.assetTailNumber ? values.assetTailNumber : null,
			current_configuration_id: null,

			squadronId: values.assignedSquadron !== null && values.assignedSquadron !== undefined ? values.assignedSquadron.id : '',

			status: []
		};

		// Populate Status with Status Code and Start/End Range of Unavailability
		if (values.assetDateRange || values.assetStatusCode) {
			values.assetUnavailabilityInfo.map((rec, i) => {
				return submissionData.status.push({
					reason: {
						code: values.assetStatusCode !== null && values.assetStatusCode[i] !== null && values.assetStatusCode[i] !== undefined && values.assetStatusCode[i].status !== null ? values.assetStatusCode[i].status.code : null,
						description: null
					},

					updatedBy: {
						id: props.keycloak.profile.id,
						firstName: props.profile.firstName,
						lastName: props.profile.lastName,
						email: props.profile.email
					},

					updatedOn: new Date(),
					start: {
						airport: {
							icao: values.assetHomeIcao ? values.assetHomeIcao : null,
							iata: null,
							name: null,
							country: { code: null },
							latitude: null,
							longitude: null,
							status: null,
							aircraftTravelTime: null,
							openTime: null,
							closeTime: null
						},
						earliest: null,
						latest: null,
						planned: values.assetDateRange[i] !== null && values.assetDateRange[i].dates !== null && values.assetDateRange[i].dates.start ? forceDateStringToUTCISOFormatSOD(values.assetDateRange[i].dates.start) : null,
						actual: null,
						alternateAirports: [],
						poc: null
					},
					end: {
						airport: {
							icao: values.assetHomeIcao ? values.assetHomeIcao : null,
							iata: null,
							name: null,
							country: { code: null },
							latitude: null,
							longitude: null,
							status: null,
							aircraftTravelTime: null,
							openTime: null,
							closeTime: null
						},
						earliest: null,
						latest: null,
						planned: values.assetDateRange[i] !== null && values.assetDateRange[i].dates !== null && values.assetDateRange[i].dates.end ? forceDateStringToUTCISOFormatEOD(values.assetDateRange[i].dates.end) : null,
						actual: null,
						alternateAirports: [],
						poc: null
					},
					missionId: null
				});
			});
		}

		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(submissionData);
		}

		ApiEditAssetRequest(submissionData, params.assetId).then((res) => {
			toast.success('Asset Created Successfully!');
			navigate('/assetavailability');
		});
	}, []);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						{loaded ? (
							<React.Fragment>
								<div className={'page-title'}>
									<h2>Asset: {tailNumber}</h2>

									{PageTitleControls()}
								</div>

								<Form
									onSubmitClick={onFormSubmit}
									key={assetDetails}
									initialValues={initValues}
									render={(formRenderProps) => (
										<div className={'airport-form margin-top-10'}>
											<div className={'airport-form-inner'}>
												<FormElement>
													{/* Asset Details: Start */}
													<Row id={'asset-details'} className={'form-section'}>
														{/* Section Title - Asset Details Section: Start */}
														<div className={'col-12 form-section-title'}>
															<h3>Asset Details</h3>
														</div>
														{/* Section Title - Asset Details Section: End */}

														{/* Assigned Squadron: Start */}
														<div className={'col-3'}>
															<Field key={'assignedSquadron'} id={'assignedSquadron'} name={'assignedSquadron'} label={'Assigned Squadron'} defaultItem={'Select Squadron...'} component={FormDropDownList} data={squadronList} onChange={assignedSquadronChangeHandler} textField={'squadronDesignation'} dataItemKey={'id'} />
														</div>
														{/* Assigned Squadron: End */}

														{/* Asset Type: Start */}
														<div className={'col-3 required-field'}>
															<Field key={'assetType'} id={'assetType'} name={'assetType'} label={'Asset Type'} defaultItem={'Select Asset Type...'} itemDisabled={'itemDisabled'} component={FormDropDownList} data={optionsAssetTypes} onChange={assetTypeChangeHandler} validator={requiredValidatorAssetType} />
														</div>
														{/* Asset Type: End */}

														{/* Asset Tail Number: Start */}
														<div className={'col-3'}>
															<Field key={'assetTailNumber'} id={'assetTailNumber'} name={'assetTailNumber'} label={'Tail Number'} placeholder={'E.g. 304'} component={FormInput} />
														</div>
														{/* Asset Tail Number: End */}

														{/* Asset Home ICAO: Start */}
														<div className={'col-3 required-field'}>
															<Field key={'assetHomeIcao'} id={'assetHomeIcao'} name={'assetHomeIcao'} label={'Home ICAO'} placeholder={'E.g. KMSY'} component={FormInput} validator={requiredValidatorHomeIcao} />
														</div>
														{/* Asset Home ICAO: End */}
													</Row>
													{/* Asset Details: End */}

													{/* Asset Unavailability Dates: Start */}
													<Row id={'asset-times'} className={'form-section'}>
														{/* Section Title - Asset Hours: Start */}
														<div className={'col-12 form-section-title asset-operating-times'}>
															<h3>Asset Unavailability</h3>
														</div>
														{/* Section Title - Asset Hours: End */}

														{/* Date Range: Start */}
														<div className={'col-12'}>
															<label>Range of Unavailability</label>
															{/*
															<Field key={'assetDateRange'} id={'assetDateRange'} name={'assetDateRange'} label={'Range of Unavailability'} component={FormDateRangePicker} defaultValue={assetDateRangeDefaults} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} />
															*/}

															<FieldArray name="assetUnavailabilityInfo" component={AssetUnavailabilityRangeGrid} />
														</div>
														{/* Date Range: End */}
													</Row>
													{/* Asset Unavailability Dates: End */}

													{/* Submission: Start */}
													<Row id={'form-submission-buttons'} className={'form-section form-submission-buttons k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'}>
														<div className={'col-12 align-right'}>
															<Button title={'Click to Save Asset Details'} className={'btn btn-size-medium btn-primary btn-color-orange margin-right-0 btn-submit'} disabled={submissionInvalidAssetType || submissionInvalidHomeIcao} onClick={formRenderProps.onSubmit}>
																Save Asset Details
																<i className={'far fa-arrow-right'}></i>
															</Button>
														</div>
													</Row>
													{/* Submission: End */}
												</FormElement>
											</div>
										</div>
									)}
								/>
							</React.Fragment>
						) : (
							<Loader />
						)}
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetEdit);
