/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Grid, GridColumn as Column, GridNoRecords, getSelectedState } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { ApiChangeLiftRequestState, ApiLiftRequestList } from '../../Helpers/Apis';
import { LiftRequestColumns } from '../../Helpers/DataGrids';

import { accessValidator } from '../../Helpers/RolesPermissions';
import { toast } from 'react-toastify';
import { CapitalizeString } from '../../Helpers/ContentFormatting';

const initialDataState = {
	skip: 0,
	take: 25
};

const SELECTED_FIELD = 'selected';

const LiftRequestListGrid = (props) => {
	const [page, setPage] = React.useState(initialDataState);
	const [lifts, setLifts] = React.useState([]);
	const [formKey, setFormKey] = React.useState(new Date());
	const [visible, setVisible] = React.useState(false);
	const [dialogState, setDialogState] = React.useState('');
	const [dialogId, setDialogId] = React.useState('');
	const [initialSelectedLiftRequests] = React.useState(props.lrIdArray);
	const [tabsInitialSelectedLiftRequests] = React.useState(props.tabsPotentialLrIdArray);

	const [sandboxRemovalDialogVisible, setSandboxRemovalDialogVisible] = React.useState(false);
	const [sandboxRemovalDialogId, setSandboxRemovalDialogId] = React.useState('');

	const [isDashboardWidget] = React.useState(props.dashboardWidget && props.dashboardWidget === true ? true : false);

	const [selectedState, setSelectedState] = React.useState({});

	const [userinfo, setUserInfo] = useState({
		languages: [],
		response: []
	});

	const accessAuthorizationButton = ['approver'];
	const accessClaimButton = ['nalo'];
	const accessRegrettedButton = ['nalo'];

	const roleaccess = props.roleaccess;
	const queryparams = props.queryparams;

	const environment = props.environment;
	const paramPage = props.page;
	const sandboxData = props.data;

	const pageChange = (event) => {
		setPage(event.page);
	};

	const handleCheckboxChange = (e) => {
		// Destructuring
		const { value, checked } = e.target;
		const { languages } = userinfo;

		// Case 1 : The user checks the box
		if (checked) {
			setUserInfo({
				languages: [...languages, value],
				response: [...languages, value]
			});

			// Case 2  : The user unchecks the box
		} else {
			setUserInfo({
				languages: languages.filter((e) => e !== value),
				response: languages.filter((e) => e !== value)
			});
		}
	};

	const dataFetch = () => {
		ApiLiftRequestList(queryparams).then((res) => {
			if (environment !== 'selection') {
				setLifts(res);
			} else {
				res.map((dataItem) =>
					Object.assign(
						{
							selected: false
						},
						dataItem
					)
				);

				setLifts(res);
			}
		});

		// set number of lift requests per page based on environment parameters
		if (environment === 'selection') {
			initialDataState.take = 6;
		} else if (paramPage === 'sandboxEdit') {
			initialDataState.take = 6;
		} else if (isDashboardWidget) {
			initialDataState.take = 10;
		} else {
			initialDataState.take = 25;
		}
	};

	const stateUpdate = (id, state) => {
		ApiChangeLiftRequestState(id, state);
		toast.success('Successfully Changed Lift Request Status to ' + CapitalizeString(state.toLowerCase()));

		const newData = lifts.map((item) =>
			item.id === id
				? {
						...item,
						history: item.history.map((child) => ({
							...child,
							state: state
						}))
				  }
				: item
		);
		setLifts(newData);
		setFormKey(id);
		setDialogId('');
		setDialogState('');
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const toggleSandboxRemovalDialog = () => {
		setSandboxRemovalDialogVisible(!sandboxRemovalDialogVisible);
	};

	const openDialog = (id, state) => {
		toggleDialog();
		setDialogId(id);
		setDialogState(state);
	};

	const openRemoveSandboxDialog = (id) => {
		toggleSandboxRemovalDialog();
		setSandboxRemovalDialogId(id);
	};

	const onSelectionChange = React.useCallback(
		(event) => {
			const newSelectedState = getSelectedState({
				event,
				selectedState: selectedState,
				dataItemKey: 'id'
			});
			setSelectedState(newSelectedState);
			//set submission array of lift request ids using the keys of the selected state object (only add if value is true for selection, not false)
			let selectedIdsArray = [];
			// eslint-disable-next-line
			Object.entries(newSelectedState).map(([key, value]) => {
				if (value === true) {
					selectedIdsArray.push(key);
				}
			});
			props.setPotentialLrIdArray(selectedIdsArray);
			props.setTabsPotentialLrIdArray(selectedIdsArray);
		},
		[selectedState]
	);

	const DialogWindow = (id, state) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleDialog}>
				<div data-id={id} data-state-to={state}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to change the lift request status to {CapitalizeString(state.toLowerCase())}?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							stateUpdate(id, state);
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const SandboxRemovalDialogWindow = (id) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleSandboxRemovalDialog}>
				<div data-id={id}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to remove this lift request from the sandbox?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Remove Lift Request from Sandbox'} onClick={toggleSandboxRemovalDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							props.handleRemoveLiftRequest(id);
							toggleSandboxRemovalDialog();
							setSandboxRemovalDialogId('');
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	useEffect(() => {
		if (environment === 'sandbox') {
			setLifts(sandboxData);
			initialDataState.take = 6;
		} else if (environment === 'selection') {
			dataFetch();

			// set initial selected lift requests
			let selectedStateObj = {};

			// eslint-disable-next-line
			initialSelectedLiftRequests.map((lrId) => {
				selectedStateObj[lrId] = true;
			});

			// eslint-disable-next-line
			tabsInitialSelectedLiftRequests.map((lrId) => {
				selectedStateObj[lrId] = true;
			});

			props.setPotentialLrIdArray(tabsInitialSelectedLiftRequests);
			setSelectedState(selectedStateObj);
		} else {
			dataFetch();
		}

		setFormKey(new Date());
	}, []);

	// Lift Request Checkbox
	const CheckboxCell = (p) => {
		const id = p.dataItem.id;
		const state = p.dataItem.history[0].state;

		return (
			<td className={'table-lr-checkbox-selection'}>
				{state === 'VALIDATED' && (
					<div className={'grid-custom-checkbox'}>
						<label className={'custom-checkbox'} htmlFor={`lr-${id}`} title={`Check to Select ID ${id}`}>
							<input type="checkbox" name="languages" value={id} id={`lr-${id}`} className={`lr-${id} lift-request-selection`} onChange={handleCheckboxChange} />
							<span>
								<span className={'sr-only'}>Click to Select Record {id}</span>
							</span>
						</label>
					</div>
				)}
			</td>
		);
	};

	const [selectedCols, setSelectedCols] = useState(['none', 'none', 'none', 'none', 'none', 'none', 'none', 'none']);

	const onHeaderClick = (p) => {
		var queryParam = queryparams.concat('&sortBy=');

		var sort = true;
		// none -> up -> down
		if (selectedCols[p] === 'none') {
			setSelectedCols(selectedCols.map((col, index) => (index === p ? 'ascend' : 'none')));
			queryParam = queryParam.concat('%2B');
		} else if (selectedCols[p] === 'ascend') {
			setSelectedCols(selectedCols.map((col, index) => (index === p ? 'descend' : 'none')));
			queryParam = queryParam.concat('-');
		} else {
			setSelectedCols(selectedCols.map((col, index) => (index === p ? 'none' : 'none')));
			sort = false;
		}

		if (sort) {
			switch (p) {
				case 0:
					queryParam = queryParam.concat('requestName');
					break;
				case 1:
					queryParam = queryParam.concat('state');
					break;
				case 2:
					//queryParam = queryParam.concat('requestingUnit.uic'); //bad
					queryParam = queryparams;
					break;
				case 3:
					queryParam = queryParam.concat('departure.airport.icao');
					break;
				case 4:
					queryParam = queryParam.concat('arrival.airport.icao');
					break;
				case 5:
					//queryParam = queryParam.concat('cargoes.passengers.count()'); //bad
					queryParam = queryparams;
					break;
				case 6:
					//queryParam = queryParam.concat('cargoes.count'); //bad
					queryParam = queryparams;
					break;
				case 7:
					queryParam = queryParam.concat('cargoes.hazmat.containsHazmat');
					break;
				default:
					break;
			}
		} else {
			queryParam = queryparams;
		}

		ApiLiftRequestList(queryParam).then((res) => {
			setLifts(res);
			setFormKey(new Date());
		});
	};

	const getCaret = (idx) => {
		return selectedCols[idx] === 'descend' ? '\u25B2' : selectedCols[idx] === 'ascend' ? '\u25BC' : '';
	};

	// Actions Cell
	const ActionsCell = (p) => {
		const history = p.dataItem.history[0];
		const id = p.dataItem.id;
		const sandboxId = p.dataItem.sandboxId;

		return (
			<td className={'table-actions'}>
				<div className={'actions'}>
					<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'left'}>
						<ul className={'actions-list'}>
							{/* Access: Approver - Button to Authorize Lift Requests */}
							{accessValidator(roleaccess, accessAuthorizationButton) && history.state === 'SUBMITTED' && (
								<li className={'action-item authorize'}>
									<NavLink
										className={'nav-link'}
										role={'button'}
										to={`#`}
										title={'Authorize Lift Request'}
										onClick={() => {
											openDialog(id, 'AUTHORIZED');
										}}>
										<i className="fa-solid fa-file-exclamation"></i>
										<span className={'sr-only'}>Click to Authorize Lift Request</span>
									</NavLink>

									{visible && dialogId === id && dialogState === 'AUTHORIZED' && DialogWindow(id, 'AUTHORIZED')}
								</li>
							)}

							{/* Access: Nalo - Button to Claim Authorized Lift Requests */}
							{accessValidator(roleaccess, accessClaimButton) && history.state === 'AUTHORIZED' && (
								<li className={'action-item approve'}>
									<NavLink
										className={'nav-link'}
										role={'button'}
										to={`#`}
										title={'Claim Lift Request'}
										onClick={() => {
											openDialog(id, 'CLAIMED');
										}}>
										<i className="fa-solid fa-file-check"></i>
										<span className={'sr-only'}>Click to Claim Lift Request</span>
									</NavLink>

									{visible && dialogId === id && dialogState === 'CLAIMED' && DialogWindow(id, 'CLAIMED')}
								</li>
							)}

							{/* Access: Nalo - Button to Regret Lift Requests */}
							{accessValidator(roleaccess, accessRegrettedButton) && history.state !== 'IN_TRANSIT' && history.state !== 'SATISFIED' && history.state !== 'REGRETTED' && history.state !== 'CANCELLED' && (
								<li className={'action-item regret'}>
									<NavLink
										className={'nav-link'}
										role={'button'}
										to={`#`}
										title={'Regret Lift Request'}
										onClick={() => {
											openDialog(id, 'REGRETTED');
										}}>
										<i className="fa-solid fa-ban"></i>
										<span className={'sr-only'}>Click to Regret Lift Request</span>
									</NavLink>

									{visible && dialogId === id && dialogState === 'REGRETTED' && DialogWindow(id, 'REGRETTED')}
								</li>
							)}

							{/* Access: Nalo - Button to Edit Lift Requests */}
							{!accessValidator(roleaccess, ['approver', 'squadron', 'requester']) && history.state !== 'SUBMITTED' && history.state !== 'AUTHORIZED' && history.state !== 'IN_TRANSIT' && history.state !== 'SATISFIED' && history.state !== 'REGRETTED' && history.state !== 'CANCELLED' && history.state !== 'CLAIMED' && paramPage !== 'view' && paramPage !== 'sandboxEdit' && (
								<li className={'action-item edit'}>
									<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/edit/${id}`} title={'Edit Lift Request'} target={environment === 'sandbox' ? '_blank' : ''}>
										<i className="fa-solid fa-pen-to-square"></i>
										<span className={'sr-only'}>Click to Edit {id}</span>
									</NavLink>
								</li>
							)}

							{/* Access: Nalo (including requester base role) - Button to edit Lift Requests */}
							{accessValidator(roleaccess, ['nalo']) && history.state !== 'SUBMITTED' && history.state !== 'AUTHORIZED' && history.state !== 'AUTHORIZED' && history.state !== 'IN_TRANSIT' && history.state !== 'SATISFIED' && history.state !== 'REGRETTED' && history.state !== 'CANCELLED' && paramPage !== 'view' && (
								<li className={'action-item validate'}>
									<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/edit/${id}`} title={'Edit Lift Request'} target={environment === 'sandbox' ? '_blank' : ''}>
										<i className="fa-solid fa-pen-to-square"></i>
										<span className={'sr-only'}>Click to Edit ${id}</span>
									</NavLink>
								</li>
							)}

							{/* Access: Requester (excluding nalo with base requester role) - Button to edit Lift Requests */}
							{accessValidator(roleaccess, ['requester']) && !accessValidator(roleaccess, ['nalo']) && history.state !== 'VALIDATED' && history.state !== 'UNSATISFIED' && history.state !== 'SCHEDULED' && history.state !== 'IN_TRANSIT' && history.state !== 'SATISFIED' && history.state !== 'REGRETTED' && history.state !== 'CANCELLED' && paramPage !== 'view' && paramPage !== 'sandboxEdit' && (
								<li className={'action-item edit'}>
									<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/edit/${id}`} title={'Edit Lift Request'} target={environment === 'sandbox' ? '_blank' : ''}>
										<i className="fa-solid fa-pen-to-square"></i>
										<span className={'sr-only'}>Click to Edit ${id}</span>
									</NavLink>
								</li>
							)}

							{/* Access: Nalo - Button to Validate Lift Requests */}
							{accessValidator(roleaccess, ['nalo']) && history.state === 'CLAIMED' && environment !== 'sandbox' && (
								<li className={'action-item validate'}>
									<NavLink className={'nav-link'} role={'button'} to={`/validation/${id}`} title={'Validate Lift Request'}>
										<i className="fa-regular fa-ballot-check"></i>
										<span className={'sr-only'}>Click to Validate ${id}</span>
									</NavLink>
								</li>
							)}

							{/* Access: Nalo - Button to View Lift Requests */}
							<li className={'action-item view'}>
								<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/view/${id}`} title={'View Lift Request'} target={environment === 'sandbox' || environment === 'selection' ? '_blank' : ''}>
									<i className="fa-solid fa-eye"></i>
									<span className={'sr-only'}>Click to View {id}</span>
								</NavLink>
							</li>

							{/* Access: Requester (excluding nalo with base requester role) - Button to edit Lift Requests */}
							{paramPage === 'sandboxEdit' && sandboxId !== null && (
								<li className={'action-item edit'}>
									<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/edit/${id}`} title={'4 Edit Lift Request'} target={environment === 'sandbox' ? '_blank' : ''}>
										<i className="fa-solid fa-pen-to-square"></i>
										<span className={'sr-only'}>Click to Edit ${id}</span>
									</NavLink>
								</li>
							)}

							{paramPage === 'sandboxEdit' && (
								<li className={'action-item regret'}>
									<NavLink
										className={'nav-link'}
										role={'button'}
										to={`#`}
										title={'Remove Lift Request from Sandbox'}
										onClick={() => {
											openRemoveSandboxDialog(id);
										}}>
										<i className="fa-solid fa-trash"></i>
										<span className={'sr-only'}>Click to Remove Lift Request from This Sandbox</span>
									</NavLink>

									{sandboxRemovalDialogVisible && sandboxRemovalDialogId === id && SandboxRemovalDialogWindow(id)}
								</li>
							)}
						</ul>
					</Tooltip>
				</div>
			</td>
		);
	};

	return (
		<form>
			{/*
      <textarea
        className="form-control text"
        name="response"
        value={userinfo.response}
        placeholder="The checkbox values will be displayed here "
        id="floatingTextarea2"
        style={{ height: "150px" }}
        onChange={handleCheckboxChange}
      ></textarea>
      */}
			<Grid
				key={formKey}
				data={lifts.slice(page.skip, page.take + page.skip).map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[item.id] }))}
				skip={page.skip}
				take={page.take}
				total={lifts.length}
				pageable={{ buttonCount: 5, pageSizes: [10, 25, 50, 150] }}
				filterable={false}
				onPageChange={pageChange}
				selectedField={SELECTED_FIELD}
				onSelectionChange={onSelectionChange}
				selectable={{
					enabled: true,
					drag: false,
					cell: false,
					mode: 'multiple'
				}}>
				<GridNoRecords>No Records Available</GridNoRecords>

				{process.env.REACT_APP_SHOW_IN_DEV === 'true' && environment !== 'sandbox' && environment !== 'selection' && <Column field="history" title=" " sortable={false} filterable={false} width="30px" cell={CheckboxCell} />}

				{/*
        <Column
          field="history"
          filter="text"
          title=" "
          width="30px"
          cell={LiftRequestColumns.LiftRequestCheckboxCell}
        />
        */}

				{environment === 'selection' && <Column field={SELECTED_FIELD} headerSelectionValue={lifts.findIndex((item) => !selectedState[item.id]) === -1} />}

				{environment === 'sandbox' && <Column field="history" filter="text" title="Request Name" className="sandboxList" width="200px" cell={LiftRequestColumns.RequestNameCell} />}

				{environment === 'selection' && <Column field="history" filter="text" title="Request Name" className="sandboxList" width="145px" cell={LiftRequestColumns.RequestNameCell} />}

				{environment !== 'selection' && environment !== 'sandbox' && (
					<Column
						field="history"
						filter="text"
						title="Request Name"
						cell={LiftRequestColumns.RequestNameCell}
						headerCell={(props) => (
							<div onClick={() => onHeaderClick(0)}>
								<i className={'fa-solid fa-sort'}></i>
								{props.title.concat(getCaret(0))}
							</div>
						)}
					/>
				)}

				{/* <Column
					field="history"
					filter="text"
					title="Request Name"
					cell={LiftRequestColumns.RequestNameCell}
					headerCell={(props) => (
						<div onClick={() => onHeaderClick(0)}>
							<i class="fa-solid fa-sort"></i>
							{props.title.concat(getCaret(0))}
						</div>
					)}
				/> */}
				{paramPage !== 'results' && environment !== 'sandbox' && (
					<Column
						field="history"
						filter="text"
						title="Status"
						cell={LiftRequestColumns.StateCell}
						headerCell={(props) => (
							<div onClick={() => onHeaderClick(1)}>
								<i className={'fa-solid fa-sort'}></i>
								{props.title.concat(getCaret(1))}
							</div>
						)}
					/>
				)}

				<Column field="history" filter="text" title="Unit Name" cell={LiftRequestColumns.UicUnitNameCell} />
				<Column
					field="history"
					filter="text"
					title="Dep. ICAO"
					cell={LiftRequestColumns.DepartureICAOCell}
					headerCell={(props) =>
						environment !== 'selection' && environment !== 'sandbox' ? (
							<div onClick={() => onHeaderClick(3)}>
								<i className={'fa-solid fa-sort'}></i>
								{props.title.concat(getCaret(3))}
							</div>
						) : (
							<div>{props.title}</div>
						)
					}
				/>
				<Column
					field="history"
					filter="date"
					title="Dep. DATE"
					cell={LiftRequestColumns.DepartureDateRangeCell}
					headerCell={() => (
						<div>
							{' '}
							Dep. DATE <div> (UTC/Zulu) </div>
						</div>
					)}
				/>
				<Column
					field="history"
					filter="text"
					title="Arr. ICAO"
					cell={LiftRequestColumns.ArrivalICAOCell}
					headerCell={(props) =>
						environment !== 'selection' && environment !== 'sandbox' ? (
							<div onClick={() => onHeaderClick(4)}>
								<i className={'fa-solid fa-sort'}></i>
								{props.title.concat(getCaret(4))}
							</div>
						) : (
							<div>{props.title}</div>
						)
					}
				/>
				<Column
					field="history"
					filter="date"
					title="Arr. DATE"
					cell={LiftRequestColumns.ArrivalDateRangeCell}
					headerCell={() => (
						<div>
							{' '}
							Arr. DATE <div> (UTC/Zulu) </div>
						</div>
					)}
				/>
				<Column field="history" filter="text" title="Passengers #" cell={LiftRequestColumns.PassengersCountCell} />
				<Column field="history" filter="text" title="Pallets #/lbs" cell={LiftRequestColumns.PalletsCountCell} />
				<Column
					field="history"
					filter="date"
					title="Has Hazmat"
					cell={LiftRequestColumns.HasHazmatCell}
					headerCell={(props) =>
						environment !== 'selection' && environment !== 'sandbox' ? (
							<div onClick={() => onHeaderClick(7)}>
								<i className={'fa-solid fa-sort'}></i>
								{props.title.concat(getCaret(7))}
							</div>
						) : (
							<div>{props.title}</div>
						)
					}
				/>
				{environment !== 'selection' && paramPage !== 'results' && paramPage !== 'summary' && <Column field="state" title=" " sortable={false} filterable={false} width="145px" cell={ActionsCell} />}

				{paramPage === 'results' && <Column field="satisfied" title="Satisfied" sortable={false} filterable={false} width="145px" cell={LiftRequestColumns.SatisfiedCell} />}
			</Grid>
		</form>
	);
};

export default LiftRequestListGrid;
