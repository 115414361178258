/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

/**
 * Global: Yes/No
 * @type {[{label: string, value: string},{label: string, value: string}]}
 */
export const optionsYesNo = [
	{ value: 'no', label: 'No' },
	{ value: 'yes', label: 'Yes' }
];

/**
 * Global: Days of the Week 2 Digit
 * @type {string[]}
 */
export const optionsDaysOfTheWeek2Digit = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

/**
 * Global: Asset Types
 * @type {string[]}
 */
export const optionsAssetTypes = ['C-40', 'C-130T'];

/**
 * Global: Airport Statuses
 * @type {string[]}
 */
export const optionsAirportStatuses = ['AVAILABLE', 'PREFERRED', 'NOT_PREFERRED', 'NOT_ALLOWED'];

/**
 * Global: Airport Equipment Type
 * @type {string[]}
 */
export const optionsAirportEquipmentType = ['K_LOADER', 'GPU', 'APU'];

/**
 * Global: Airport Schedule Frequency
 * @type {string[]}
 * All possible options based on iCal spec: ['HOURLY', 'DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY']
 */
export const optionsAirportScheduleFrequency = ['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY'];

/**
 * Global: Asset Status Codes for C-40
 * @type {string[]}
 */
export const optionsAssetStatusCodesC40 = [
	{ type: 'C-40', code: 'APUI', label: 'APU INOP', codeAndLabel: 'APUI: APU INOP', description: 'Limited to CONUS missions, airfields have to have ground support.' },
	{ type: 'C-40', code: 'HRI', label: 'HF Radios INOP', codeAndLabel: 'HRI: HF Radios INOP', description: 'Cannot schedule overwater missions.' },
	{ type: 'C-40', code: 'MX', label: 'Scheduled Maintenance', codeAndLabel: 'MX: Scheduled Maintenance', description: 'Scheduled maintenance for this asset.' },
	{ type: 'C-40', code: 'SPO', label: 'Single Pack Operation', codeAndLabel: 'SPO: Single Pack Operation', description: "Limited to 25,000'. Cannot schedule overwater missions." }
	// {type: 'C-40', code: 'WHI', label: 'Window Heat INOP', codeAndLabel: 'WHI: Window Heat INOP', description: 'Cannot fly in icing conditions.'},
];

/**
 * Global: Asset Status Codes for C-130T
 * @type {string[]}
 */
export const optionsAssetStatusCodesC130T = [
	{ type: 'C-130', code: 'API', label: 'Autopilot INOP', codeAndLabel: 'API: Autopilot INOP', description: 'Limited to 12.0 hour CREW DAY.' },
	{ type: 'C-130', code: 'HRI', label: 'HF Radios INOP', codeAndLabel: 'HRI: HF Radio INOP', description: 'Cannot schedule overwater missions.' },
	{ type: 'C-130', code: 'MX', label: 'Scheduled Maintenance', codeAndLabel: 'MX: Scheduled Maintenance', description: 'Scheduled maintenance for this asset.' },
	{ type: 'C-130', code: 'WRI', label: 'Weather Radar INOP', codeAndLabel: 'WRI: Weather Radar INOP', description: 'Cannot schedule overwater missions.' }
];

/**
 * Global: Disruption Types
 * @type {string[]}
 */
export const optionsDisruptions = ['Asset unavailable', 'Cancelled lift request', 'Delayed asset'];

/**
 * Lift Request Form: Explosive Compatibility Groups
 * @type {string[]}
 */
export const optionsExplosiveCompatibilityGroups = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'N', 'S'];

/**
 * Lift Request Form: Passenger Eligibility Code
 * @type {string[]}
 */
export const optionsPassengerEligibilityCode = ['P1', 'P2', 'P3', 'P4', 'P5'];

/**
 * Disruption Resolution Types
 * @type {string[]}
 */
export const optionsDisruptionResolutionTypes = ['Send to Solver'];
