/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

/**
 * Compare entered departure date to determine if 24 hour difference exists between earliest and latest times.
 * Sets time window warning to true if not.
 * @param setHr24LaterDateLatestDep
 * @param setHr24LaterDateEarliestDep
 * @param hr24LaterDateEarliestDep
 * @param hr24LaterDateLatestDep
 * @param setTimeWindowWarningDep
 * @param e
 */
export const compareDateChangeDep = (setHr24LaterDateLatestDep, setHr24LaterDateEarliestDep, hr24LaterDateEarliestDep, hr24LaterDateLatestDep, setTimeWindowWarningDep, e) => {
	if (e.target.props.id.includes('earliest')) {
		//create new date that is 24 hours after earliest date (used to compare with latest date)
		setHr24LaterDateEarliestDep(new Date(e.value.getTime() + 60 * 60 * 24 * 1000));
		if (hr24LaterDateLatestDep !== '' && (e.value >= hr24LaterDateLatestDep || e.value === new Date(hr24LaterDateLatestDep.getTime() + 60 * 60 * 24 * 1000))) {
			setTimeWindowWarningDep(true);
		} else {
			setTimeWindowWarningDep(false);
		}
	}

	if (e.target.props.id.includes('latest')) {
		//create new date that is 24 hours before latest date (used to compare with earliest date)
		setHr24LaterDateLatestDep(new Date(e.value.getTime() - 60 * 60 * 24 * 1000));
		if (hr24LaterDateEarliestDep !== '' && (e.value <= hr24LaterDateEarliestDep || e.value === new Date(hr24LaterDateEarliestDep.getTime() - 60 * 60 * 24 * 1000))) {
			setTimeWindowWarningDep(true);
		} else {
			setTimeWindowWarningDep(false);
		}
	}
};

/**
 * Compare entered arrival date to determine if 24 hour difference exists between earliest and latest times.
 * Sets time window warning to true if not.
 * @param setHr24LaterDateLatestArr
 * @param setHr24LaterDateEarliestArr
 * @param hr24LaterDateEarliestArr
 * @param hr24LaterDateLatestArr
 * @param setTimeWindowWarningArr
 * @param e
 */
export const compareDateChangeArr = (setHr24LaterDateLatestArr, setHr24LaterDateEarliestArr, hr24LaterDateEarliestArr, hr24LaterDateLatestArr, setTimeWindowWarningArr, e) => {
	if (e.target.props.id.includes('earliest')) {
		setHr24LaterDateEarliestArr(new Date(e.value.getTime() + 60 * 60 * 24 * 1000));
		if (hr24LaterDateLatestArr !== '' && (e.value >= hr24LaterDateLatestArr || e.value === new Date(hr24LaterDateLatestArr.getTime() + 60 * 60 * 24 * 1000))) {
			setTimeWindowWarningArr(true);
		} else {
			setTimeWindowWarningArr(false);
		}
	}

	if (e.target.props.id.includes('latest')) {
		setHr24LaterDateLatestArr(new Date(e.value.getTime() - 60 * 60 * 24 * 1000));
		if (hr24LaterDateEarliestArr !== '' && (e.value <= hr24LaterDateEarliestArr || e.value === new Date(hr24LaterDateEarliestArr.getTime() - 60 * 60 * 24 * 1000))) {
			setTimeWindowWarningArr(true);
		} else {
			setTimeWindowWarningArr(false);
		}
	}
};

/**
 * Compare already entered departure date to determine if 24 hour difference exists between earliest and latest times.
 * Sets time window warning to true if not.
 * @param setTimeWindowWarningDep
 * @param setTimeWindowWarningArr
 * @param e
 */
export const initialTimeWindowWarning = (setTimeWindowWarningDep, setTimeWindowWarningArr, res) => {
	if (res.history[0].departure !== null) {
		let departureEarliest = new Date(res.history[0].departure.earliest);
		let departureLatest = new Date(res.history[0].departure.latest);

		// Get departure time difference in hours
		let departureTimeDifference = (departureLatest.getTime() - departureEarliest.getTime()) / 1000 / (60 * 60);

		if (departureTimeDifference <= 24) {
			setTimeWindowWarningDep(true);
		}
	}

	if (res.history[0].arrival !== null) {
		let arrivalEarliest = new Date(res.history[0].arrival.earliest);
		let arrivalLatest = new Date(res.history[0].arrival.latest);

		// Get arrival time difference in hours
		let arrivalTimeDifference = (arrivalLatest.getTime() - arrivalEarliest.getTime()) / 1000 / (60 * 60);

		if (arrivalTimeDifference <= 24) {
			setTimeWindowWarningArr(true);
		}
	}
};
