/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { Field } from '@progress/kendo-react-form';
import { FormNumericTextBox, FormSwitch } from '../KendoFormElements/FormComponents';
import { commercialCostValidator, requiredValidator } from '../KendoFormElements/Validators';
import Button from 'react-bootstrap/Button';
import LiftRequestInfoModal from '../Modals/LiftRequestInfoModal';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { insertItem, getItems, updateItem, deleteItem } from '../../Helpers/TableHelpers/TableServices';
import { PalletCommandButtonsCell } from '../../Helpers/TableHelpers/CommandCell';
import { EditPalletForm } from '../../Helpers/FormHelpers/EditPalletForm';
import { CargoColumns } from '../../Helpers/DataGrids';

const editField = 'inEdit';

const PalletFormContainerCommandCell = (props) => {
	return (
		<td>
			<button className="btn btn-primary btn-color-green padding-left-30 padding-right-30" onClick={() => props.enterEditForm(props.dataItem)}>
				Edit <i className="far fa-pencil"></i>
			</button>
		</td>
	);
};

const HazmatCell = ({ dataItem, ...props }) => {
	if (!dataItem || !dataItem.hazmat || !dataItem.hazmat.containsHazmat) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					No
				</span>
			</td>
		);
	}

	if (dataItem.hazmat.containsHazmat) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					Yes
				</span>
				{/* keeping in case Customer wants hazmat data in column */}
				{/* <span style={{
		display: 'inlineBlock',
		paddingLeft: '10px',
		verticalAlign: 'middle',
		lineHeight: '32px'
	  	}} className="hazmat-cell">
		  {dataItem.hazmat.hazardClassAndDivision}
		</span> */}
			</td>
		);
	}
};

export const PassengersCargoDetails = (props, setPalletData) => {
	const [toggleModal, setToggleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalContent, setModalContent] = useState('');
	const [data, setData] = useState(props.cargoData);
	const [openForm, setOpenForm] = React.useState(false);
	const [adding, setAdding] = useState(false);
	const [editItem, setEditItem] = React.useState({
		EntryID: 1
	});

	const handleSubmit = (event) => {
		setOpenForm(false);
		// eslint-disable-next-line
		let newData = props.cargoData.map((item) => {
			if (event.EntryID === item.EntryID) {
				item = {
					...event,
					oversize: event.oversize,
					rollingStock: event.rollingStock,
					description: event.description,
					bellyLoadable: event.bellyLoadable,
					hazmat: {
						...item.hazmat,
						containsHazmat: event.hazmat ? event.hazmat.containsHazmat : false
					}
				};
			}
			update(item);
			return item;
		});
		setData(newData);
		props.setCargoData(newData);
		setOpenForm(false);
	};

	const handleCancelEdit = () => {
		setOpenForm(false);
	};

	const discardNew = () => {
		const newData = [...deleteItem(editItem, props.cargoData)];
		setData(newData);
		props.setCargoData(newData);
		setAdding(false);
		setOpenForm(false);
	};

	const enterEditForm = (item) => {
		setOpenForm(true);
		setEditItem(item);
	};

	const handleModalToggle = () => setToggleModal(!toggleModal);

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	const PalletFormCommandCell = (props) => <PalletFormContainerCommandCell {...props} enterEditForm={enterEditForm} />;

	const CargoCommandCell = (props) => <PalletCommandButtonsCell {...props} edit={enterEdit} remove={handleRemove} add={add} discard={discard} update={update} cancel={cancel} editField={editField} />;

	// modify the data in the store, db etc
	const handleRemove = (dataItem) => {
		setOpenForm(false);
		if (dataItem !== undefined) {
			const newData = [...deleteItem(dataItem, props.cargoData)];
			setData(newData);
			props.setCargoData(newData);
			setAdding(false);
		}
	};

	const add = (dataItem) => {
		dataItem.inEdit = true;
		const newData = insertItem(dataItem, props.cargoData);
		setData(newData);
		props.setCargoData(newData);
		setAdding(false);
	};

	const update = (dataItem) => {
		dataItem.inEdit = false;
		dataItem.newItem = false;
		const newData = updateItem(dataItem, props.cargoData);
		setData(newData);
		props.setCargoData(newData);
		props.setCargoData(data);
	};

	// Local state operations
	const discard = () => {
		const newData = [...data];
		newData.splice(0, 1);
		setData(newData);
		props.setCargoData(newData);
		setAdding(false);
	};

	const cancel = (dataItem) => {
		const originalItem = getItems(props.cargoData).find((p) => p.EntryID === dataItem.EntryID);
		const newData = data.map((item) => (item.EntryID === originalItem.EntryID ? originalItem : item));
		setData(newData);
		props.setCargoData(newData);
	};

	const enterEdit = (dataItem) => {
		const newData = data.map((item) =>
			item.EntryID === dataItem.EntryID
				? {
						...item,
						inEdit: true
				  }
				: item
		);
		setData(newData);
		props.setCargoData(newData);
	};

	const itemChange = (event) => {
		const newData = data.map((item) =>
			item.EntryID === event.dataItem.EntryID
				? {
						...item,
						[event.field || '']: event.value
				  }
				: item
		);
		setData(newData);
		props.setCargoData(newData);
	};

	const addNew = () => {
		const newDataItem = {
			inEdit: true,
			newItem: true,
			type: 'PALLETS',
			hazmat: {
				containsHazmat: false,
				hazmatCargoes: []
			}
		};
		setAdding(true);

		setData([newDataItem, ...data]);
		props.setCargoData([newDataItem, ...data]);
		add(newDataItem);
		setOpenForm(true);
		setEditItem(newDataItem);
	};

	const setPalletHazmatCargoesData = (dataItem, hazmatCargoesArray) => {
		let newData = data.map((item) => {
			if (dataItem.EntryID === item.EntryID) {
				item = {
					...item,
					hazmat: {
						...item.hazmat,

						hazmatCargoes: dataItem.hazmat.hazmatCargoes
					}
				};
			}
			return item;
		});
		props.setCargoData(newData);
		setData(newData);
	};

	return (
		<React.Fragment>
			<div id={'form-wizard-passenger-cargo-option'}>
				<h4 className={'align-center'}>
					<strong>Please select whether your lift request includes passengers or cargo:</strong>
					<br />
					<span className={'fontsize-14px'} style={{ fontWeight: 'normal' }}>
						(For each passenger, we will account for 40lbs of bags)
					</span>
				</h4>

				<div className={'cargo-toggles align-center'}>
					<div>
						<Field key={'hasPassengers'} id={'hasPassengers'} name={'cargo.hasPassengers'} onLabel={'Yes'} offLabel={'No'} defaultChecked={false} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Request Contains Passengers?'} component={FormSwitch} />
					</div>
					<div>
						<Field key={'hasPallets'} id={'hasPallets'} name={'cargo.hasPallets'} onLabel={'Yes'} offLabel={'No'} defaultChecked={false} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Request Contains Cargo?'} component={FormSwitch} />
					</div>
				</div>
			</div>

			{props.valueGetter('cargo.hasPassengers') && (
				<div id={'form-wizard-passengers'} className={`${props.valueGetter('cargo.hasPassengers') ? 'show' : 'hide'}`}>
					{/* Total Number of PAX Row: Start */}
					<Row id={'total-pax'} className={'form-section'}>
						{/* Section Title - PAX Count: Start */}
						<div className={'col-12 form-section-title pax-count'}>
							<h3>PAX Count</h3>
						</div>
						{/* Section Title - PAX Count: End */}

						{/* Total Number of PAX: Start */}
						<div className={'col-4 has-info'}>
							<div className={'form-group'}>
								<Field key={'totalNumberOfPax'} id={'totalNumberOfPax'} name={'cargo.paxCount'} label={'Total Number of PAX'} placeholder={''} format={'n0'} min={1} component={FormNumericTextBox} />
								<Button
									variant={'secondary'}
									className={'btn-form-info'}
									onClick={() => {
										// Info: Modal Title
										handleModalTitle('PAX Information');

										// Info: Modal Content
										handleModalContent(`
                                    <p>Total number of passengers.</p>
                                `);

										// Show Modal
										handleModalToggle();
									}}>
									<i className={'fa-solid fa-info modal-trigger'}></i>
									<span className={'sr-only'}>Click to view information about XXXXX</span>
								</Button>
							</div>
						</div>
						{/* Total Number of PAX Row: End */}
					</Row>
					{/* Total Number of PAX: End */}

					{/* Commercial Cost for PAX Row: Start */}
					<Row id={'commercial-cost'} className={'form-section'}>
						{/* Section Title - PAX Count: Start */}
						<div className={'col-12 form-section-title pax-count'}>
							<h3>
								Determine Commercial Cost
								<span className={'fontsize-14px'} style={{ float: 'right' }}>
									(Calculate Cost Using the{' '}
									<a href={'https://www.gsa.gov/travel/plan-book/transportation-airfare-pov-etc/city-pair-program-cpp'} title={'Click to Use CPP Search Tool'} target={'_blank'} rel={'noreferrer'}>
										CPP Search Tool
									</a>
									)
								</span>
							</h3>
						</div>
						{/* Section Title - PAX Count: End */}

						{/* Total Commercial Cost for PAX: Start */}
						<div className={'col-4 has-info'}>
							<div className={'form-group'}>
								<Field key={'totalCommercialCost'} id={'totalCommercialCost'} name={'commercialCost'} label={'Total Commercial Cost for PAX'} placeholder={''} hint={'Amount in USD'} format={'n2'} min={0.01} validator={commercialCostValidator} component={FormNumericTextBox} />
								<Button
									variant={'secondary'}
									className={'btn-form-info'}
									onClick={() => {
										// Info: Modal Title
										handleModalTitle('Commercial Cost Information');

										// Info: Modal Content
										handleModalContent(`
                                    <p>Cost of commercial travel.</p>
                                    <p>(Transportation, additional per diem, lost time, etc.)</p>
                                `);

										// Show Modal
										handleModalToggle();
									}}>
									<i className={'fa-solid fa-info modal-trigger'}></i>
									<span className={'sr-only'}>Click to view information about XXXXX</span>
								</Button>
							</div>
						</div>
						{/* Total Commercial Cost for PAX Row: End */}
					</Row>
					{/* Commercial Cost for PAX Row: End */}
				</div>
			)}

			{props.valueGetter('cargo.hasPallets') && (
				<div id={'form-wizard-cargo'} className={`${props.valueGetter('cargo.hasPallets') ? 'show' : 'hide'}`}>
					{/* Pallet Positions: Start */}
					<Row id={'cargo-pallets'} className={'form-section'}>
						{/* Section Title - Cargo Pallets: Start */}
						<div className={'col-12 form-section-title cargo-pallets'}>
							<h3>Cargo</h3>
						</div>
						{/* Section Title - Cargo Pallets: End */}
					</Row>

					{/* Pallet Table: Start */}

					<Grid
						name={'cargo.pallets'}
						style={{
							height: '420px',
							width: '100%'
						}}
						data={data}
						onItemChange={itemChange}
						navigatable={true}
						editField={editField}>
						<GridToolbar>
							<Button type={'button'} disabled={adding} title="Add new" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={addNew}>
								Add New Cargo
							</Button>
						</GridToolbar>

						<Column field="EntryID" title="Id" width="0px" editable={false} />

						<Column field="PalletName" title="Cargo Title" editable={true} />

						<Column field="weight" title="Total Weight" editable={true} cell={CargoColumns.WeightCellWizard} />

						<Column field="count" title="Cargo Positions" editor="numeric" validator={requiredValidator} />

						<Column
							cells={{
								data: HazmatCell
							}}
							title="Contains Hazmat"
							editable={false}
						/>
						<Column cell={PalletFormCommandCell} />
						<Column cell={CargoCommandCell} width="250px" />
					</Grid>

					{openForm && <EditPalletForm cancelEdit={handleCancelEdit} discardNew={discardNew} setPalletHazmatCargoesData={setPalletHazmatCargoesData} onSubmit={handleSubmit} item={editItem} props={props} />}

					{/* Pallet Table: End */}

					{/* Pallet Positions: End */}
				</div>
			)}

			<LiftRequestInfoModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalContent={modalContent} />
		</React.Fragment>
	);
};
