/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import Button from 'react-bootstrap/Button';
import { Popover, PopoverActionsBar } from '@progress/kendo-react-tooltip';

const LiftRequestTooltipModal = (props) => {
	// https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
	function modalContent() {
		return { __html: props.modalContent };
	}

	function modalContentOutput() {
		return <div dangerouslySetInnerHTML={modalContent()} />;
	}

	return (
		<Popover
			show={props.modalShow}
			collision={{
				vertical: 'flip',
				horizontal: 'flip'
			}}
			onHide={props.setModalShow}
			anchor={props.modalRef.current && props.modalRef.current.element}
			keyboard={false}
			title={props.modalTitle}>
			{modalContentOutput()}

			<PopoverActionsBar>
				<Button type={'button'} className={'btn-transparent'} onClick={props.setModalShow}>
					Close
				</Button>
			</PopoverActionsBar>
		</Popover>
	);
};

export default LiftRequestTooltipModal;
