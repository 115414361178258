/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { cancelIcon } from '@progress/kendo-svg-icons';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';

const DetailComponent = (props) => {
	const dataItem = props.dataItem;

	return (
		<React.Fragment>
			<div className={'col-12'}>
				<label>UN Number (Joint Hazardous Material Identification Number)</label>
				<div className={'static-field-read-only static-text'}>{dataItem.unNumber}</div>
			</div>
			<div className={'col-12'}>
				<label>Proper Shipping Name</label>
				<div className={'static-field-read-only static-text'}>{dataItem.shippingName}</div>
			</div>
			<div className={'col-12'}>
				<label>Proper Shipping Description</label>
				<div className={'static-field-read-only static-textarea'}>{dataItem.shippingDescription}</div>
			</div>
			<div className={'col-12'}>
				<label>Hazard Class and Division</label>
				<div className={'static-field-read-only static-text'}>{dataItem.hazardClassAndDivision}</div>
			</div>
			<div className={'col-12'}>
				<label>Explosive Compatibility Group</label>
				<div className={'static-field-read-only static-text'}>{dataItem.explosiveCompatibilityGroup}</div>
			</div>
			<div className={'col-12'}>
				<label>Passenger Eligibility Code ('P' Codes)</label>
				<div className={'static-field-read-only static-text'}>{dataItem.passengerEligibilityCode}</div>
			</div>
			<div className={'col-12'}>
				<label>NET Explosive Weight (as req)</label>
				<div className={'static-field-read-only static-text'}>{dataItem.netExplosiveWeight + ' lbs'}</div>
			</div>
			<div className={'col-3'}>
				<label>HAZMAT Dimensions (Length)</label>
				<div className={'static-field-read-only static-text'}>{dataItem.cargo ? dataItem.cargo.length + ' inches' : ''}</div>
			</div>
			<div className={'col-3'}>
				<label>HAZMAT Dimensions (Width)</label>
				<div className={'static-field-read-only static-text'}>{dataItem.cargo ? dataItem.cargo.width + ' inches' : ''}</div>
			</div>
			<div className={'col-3'}>
				<label>HAZMAT Dimensions (Height)</label>
				<div className={'static-field-read-only static-text'}>{dataItem.cargo ? dataItem.cargo.height + ' inches' : ''}</div>
			</div>
			<div className={'col-3'}>
				<label>HAZMAT Dimensions (Weight)</label>
				<div className={'static-field-read-only static-text'}>{dataItem.cargo ? dataItem.cargo.weight + ' lbs' : ''}</div>
			</div>
		</React.Fragment>
	);
};

export const ViewPalletForm = (props) => {
	const [hazmatCargoes, setHazmatCargoes] = useState(props.item !== undefined && props.item !== null && props.item.hazmat !== undefined && props.item.hazmat !== null && props.item.hazmat.hazmatCargoes !== undefined ? props.item.hazmat.hazmatCargoes : null);
	// eslint-disable-next-line
	const { cancelEdit, item } = props;
	const expandChange = (event) => {
		let newData = hazmatCargoes.map((item) => {
			if (item.EntryID === event.dataItem.EntryID) {
				item.expanded = event.value;
			}
			return item;
		});
		setHazmatCargoes(newData);
	};

	const HazmatDetailComponent = (props) => <DetailComponent {...props} hazmatCargoes={hazmatCargoes} setHazmatCargoes={setHazmatCargoes} />;

	return (
		<React.Fragment>
			<Dialog title={`Cargo: ${item.PalletName}`} onClose={cancelEdit} width={1200} height={950}>
				{/* Pallet Position: Start */}
				<Row id={'cargo-pallets'} className={'form-section row'}>
					{/* Section Title - Cargo Pallets: Start */}
					<div className="col-12 form-section-title cargo-pallets">
						<h3>Cargo Positions</h3>
					</div>
					{/* Section Title - Cargo Pallets: End */}
					<div className={'col-4'}>
						<label>Total Cargo Positions Required</label>
						<div className={'static-field-read-only static-text'}>{item.count}</div>
					</div>
				</Row>

				<Row id={'cargo-transportation'} className={'form-section row'}>
					<div className="col-12 form-section-title cargo-transportation">
						<h3>
							Cargo Transportation
							<span className="fontsize-14px" style={{ float: 'right' }}>
								(Cargo acceptors and handlers are required at destination airfield)
							</span>
						</h3>
					</div>

					<div className={'col-3'}>
						<label>Largest Item Dimensions (Length)</label>
						<div className={'static-field-read-only static-text'}>{item.largestItem && item.largestItem.length ? item.largestItem.length + ' inches' : ''}</div>
					</div>

					<div className={'col-3'}>
						<label>Largest Item Dimensions (Width)</label>
						<div className={'static-field-read-only static-text'}>{item.largestItem && item.largestItem.width ? item.largestItem.width + ' inches' : ''}</div>
					</div>

					<div className={'col-3'}>
						<label>Largest Item Dimensions (Height)</label>
						<div className={'static-field-read-only static-text'}>{item.largestItem && item.largestItem.height ? item.largestItem.height + ' inches' : ''}</div>
					</div>

					<div className={'col-3'}>
						<label>
							Largest Item (
							<b>
								<em>Weight In Pounds</em>
							</b>
							)
						</label>
						<div className={'static-field-read-only static-text'}>{item.largestItem && item.largestItem.weight ? item.largestItem.weight + ' lbs' : ''}</div>
					</div>

					<div className={'col-3'}>
						<label>Heaviest Item (Length)</label>
						<div className={'static-field-read-only static-text'}>{item.heaviestItem && item.heaviestItem.length ? item.heaviestItem.length + ' inches' : ''}</div>
					</div>

					<div className={'col-3'}>
						<label>Heaviest Item (Width)</label>
						<div className={'static-field-read-only static-text'}>{item.heaviestItem && item.heaviestItem.width ? item.heaviestItem.width + ' inches' : ''}</div>
					</div>

					<div className={'col-3'}>
						<label>Heaviest Item (Height)</label>
						<div className={'static-field-read-only static-text'}>{item.heaviestItem && item.heaviestItem.height ? item.heaviestItem.height + ' inches' : ''}</div>
					</div>

					<div className={'col-3'}>
						<label>
							Heaviest Item (
							<b>
								<em>Weight In Pounds</em>
							</b>
							)
						</label>
						<div className={'static-field-read-only static-text'}>{item.heaviestItem && item.heaviestItem.weight ? item.heaviestItem.weight + ' lbs' : ''}</div>
					</div>

					<div className={'col-3'}>
						<label>
							Total Weight (
							<b>
								<em>In Pounds</em>
							</b>
							)
						</label>
						<div className={'static-field-read-only static-text'}>{item.weight ? item.weight + ' lbs' : ''}</div>
					</div>

					<div className={'col-3'}>
						<label>Total Cubic Feet</label>
						<div className={'static-field-read-only static-text'}>{item.totalCubicFeet ? item.totalCubicFeet + ' cubic feet' : ''}</div>
					</div>

					<div className={'col-12'}>
						<label>
							Cargo Description (
							<b>
								<em>All Weights In Pounds</em>
							</b>
							)
						</label>
						<div className={'static-field-read-only static-textarea'}>{item.description}</div>
					</div>

					<div className={'col-12'}>
						<label>Special Handling Requirements</label>
						<div className={'static-field-read-only static-textarea'}>{item.specialHandlingRequirements}</div>
					</div>

					{/* Oversized Cargo: Start */}
					<div className={'col-12 has-oversize-cargo'}>
						<label>Contains Oversized Cargo?</label>
						<div className={'static-field-read-only static-text'}>{item.oversize ? 'Yes' : 'No'}</div>
					</div>
					{/* Oversized Cargo: End */}

					{/* Rolling Stock: Start */}
					<div className={'col-12 has-rolling-stock'}>
						<label>Contains Rolling Stock?</label>
						<div className={'static-field-read-only static-text'}>{item.rollingStock ? 'Yes' : 'No'}</div>
					</div>
					{/* Rolling Stock: End */}

					{/* Belly Loadable: Start */}
					<div className={'col-12 has-rolling-stock'}>
						<label>Contains Belly Loadable Cargo?</label>
						<div className={'static-field-read-only static-text'}>{item.bellyLoadable ? 'Yes' : 'No'}</div>
					</div>
					{/* Belly Loadable: End */}

					<div className="col-12 form-section-title cargo-has-hazmat">
						<h3>Hazmat</h3>
					</div>

					<div className={'col-12'}>
						<label>Does cargo contain HAZMAT?</label>
						<div className={'static-field-read-only static-text'}>{item.hazmat !== undefined && item.hazmat !== null && item.hazmat.containsHazmat !== undefined && item.hazmat.containsHazmat === true ? 'Yes' : 'No'}</div>
					</div>
					{item.hazmat !== undefined && item.hazmat !== null && item.hazmat.containsHazmat !== undefined && item.hazmat.containsHazmat !== null && item.hazmat.containsHazmat === true ? (
						<React.Fragment>
							<Grid
								name={'hazmat.hazmatCargoes'}
								style={{
									height: '850px',
									width: '96%',
									paddingLeft: 10
								}}
								data={hazmatCargoes}
								navigatable={true}
								detail={HazmatDetailComponent}
								expandField="expanded"
								onExpandChange={expandChange}>
								<GridToolbar></GridToolbar>
								<Column field="hazmatName" title="Hazmat Cargo Name" editable={false} />
								<Column field="hazardClassAndDivision" title="Hazard Type" editable={false} />
								<Column field="cargo.weight" title="Weight" editable={false} />
							</Grid>
						</React.Fragment>
					) : (
						''
					)}
				</Row>
				{/* Pallet Position: End */}
				<DialogActionsBar layout="end">
					<Button className={'btn btn-primary btn-size-medium btn-color-blue'} onClick={cancelEdit} icon="cancel" svgIcon={cancelIcon}>
						Close
					</Button>
				</DialogActionsBar>
			</Dialog>
		</React.Fragment>
	);
};
