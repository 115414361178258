/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import { Grid, GridColumn as Column, GridNoRecords, getSelectedState } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { AirportColumns } from '../../Helpers/DataGrids';

import { ApiAirportsList, ApiDeleteAirportRequest } from '../../Helpers/Apis';

import AirportMapModal from '../Modals/AirportMapModal';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { toast } from 'react-toastify';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

const initialDataState = {
	skip: 0,
	take: 10
};

const SELECTED_FIELD = 'selected';

const AirportsListGrid = (props) => {
	// Used to Refresh on Delete
	const { state } = useLocation();

	// Used to Refresh on Delete
	const [rerender, setRerender] = useState(false);

	// Dialog Visibility
	const [currentId, setCurrentId] = React.useState('');
	const [visible, setVisible] = React.useState(false);

	const [page, setPage] = React.useState(initialDataState);
	const [airports, setAirports] = React.useState([]);

	const [toggleModal, setToggleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalContent, setModalContent] = useState('');
	const [selectedState, setSelectedState] = React.useState({});
	const [initialSelectedAirports] = React.useState(props.airportIdArray);

	const [sandboxRemovalDialogVisible, setSandboxRemovalDialogVisible] = React.useState(false);
	const [sandboxRemovalDialogId, setSandboxRemovalDialogId] = React.useState('');

	const environment = props.environment;
	const sandboxData = props.data;
	const paramPage = props.page;

	const navigate = useNavigate();

	const pageChange = (event) => {
		setPage(event.page);
	};

	const handleModalToggle = () => setToggleModal(!toggleModal);

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	const dataFetch = () => {
		ApiAirportsList().then((res) => {
			setAirports(res);

			if (environment === 'selection') {
				// set initial selected airports
				let selectedStateObj = {};
				// eslint-disable-next-line
				initialSelectedAirports.map((airportId) => {
					selectedStateObj[airportId] = true;
				});
				setSelectedState(selectedStateObj);

				setAirports(res);
			}
		});
	};

	// rerender Used to Refresh on Delete
	useEffect(() => {
		if (environment === 'sandbox') {
			setAirports(sandboxData);
		} else {
			dataFetch();
		}

		// set number of airports per page based on environment parameters
		if (environment === 'selection') {
			initialDataState.take = 9;
		} else {
			initialDataState.take = 10;
		}
	}, [rerender]);

	// Used to Refresh on Delete
	useEffect(() => {
		if (environment === 'sandbox') {
			setAirports(sandboxData);
		} else {
			dataFetch();
		}
	}, [state]);

	const handleAirportDeletion = (id) => {
		ApiDeleteAirportRequest(id).then((res) => {
			toast.success('Airport Deleted Successfully!');
			navigate('/airportslist');

			// Used to Refresh on Delete
			setRerender(!rerender);
		});
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const openDialog = () => {
		toggleDialog();
	};

	const DialogWindow = (id) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleDialog}>
				<div data-id={id}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to delete this airport?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							handleAirportDeletion(id);
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const SandboxRemovalDialogWindow = (id) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleSandboxRemovalDialog}>
				<div data-id={id}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to remove this airport from the sandbox?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Remove Airport from Sandbox'} onClick={toggleSandboxRemovalDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							props.handleRemoveAirports(id);
							toggleSandboxRemovalDialog();
							setSandboxRemovalDialogId('');
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const toggleSandboxRemovalDialog = () => {
		setSandboxRemovalDialogVisible(!sandboxRemovalDialogVisible);
	};

	const openRemoveSandboxDialog = (id) => {
		toggleSandboxRemovalDialog();
		setSandboxRemovalDialogId(id);
	};

	const PageTitleControls = () => {
		return (
			<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'bottom'}>
				<Navbar expand="lg">
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							<NavLink className={'nav-link'} role={'button'} to={`/airport/new`} title={`Click to Create a New Airport`}>
								<i className="fa-solid fa-circle-plus"></i>
								New Airport
							</NavLink>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</Tooltip>
		);
	};

	const onSelectionChange = React.useCallback(
		(event) => {
			const newSelectedState = getSelectedState({
				event,
				selectedState: selectedState,
				dataItemKey: 'id'
			});
			setSelectedState(newSelectedState);
			let selectedIdsArray = [];
			// eslint-disable-next-line
			Object.entries(newSelectedState).map(([key, value]) => {
				if (value === true) {
					selectedIdsArray.push(key);
				}
			});
			props.setPotentialAirportIdArray(selectedIdsArray);
		},
		[selectedState]
	);

	const ActionsCell = (p) => {
		const id = p.dataItem.id;
		const history = p.dataItem.history[0];
		const name = history.name;
		const latitude = history.latitude;
		const longitude = history.longitude;
		const sandboxId = p.dataItem.sandboxId;

		return (
			<td className={'table-actions'}>
				<div className={'actions'}>
					<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'left'}>
						<ul className={'actions-list'}>
							<li className={'action-item globe'}>
								<button
									className={'nav-link'}
									title={`Click to View ${name} on Map`}
									style={{ borderWidth: 0 }}
									onClick={() => {
										// Info: Modal Title
										handleModalTitle(`${name} Location`);

										// Info: Modal Content
										handleModalContent([{ name: name, lat: latitude, lng: longitude }]);

										// Show Modal
										handleModalToggle();
									}}>
									<i className="fa-solid fa-globe"></i>
									<span className={'sr-only'}>View {name} on Map</span>
								</button>
							</li>
							{paramPage !== 'view' && paramPage !== 'sandboxEdit' && (
								<li className={'action-item delete'}>
									<button
										className={'nav-link'}
										title={`Click to Delete ${name}`}
										style={{ borderWidth: 0 }}
										onClick={() => {
											setCurrentId(id);
											openDialog(id);
										}}>
										<i className="fa-solid fa-trash"></i>
										<span className={'sr-only'}>Delete {name}</span>
									</button>

									{visible && currentId === id && DialogWindow(id)}
								</li>
							)}

							{paramPage !== 'sandboxEdit' && (
								<li className={'action-item edit'}>
									<NavLink className={'nav-link'} role={'button'} to={`/airport/edit/${id}`} title={`Click to View ${name} Details`}>
										<i className="fa-solid fa-edit"></i>
										<span className={'sr-only'}>View {name} Details</span>
									</NavLink>
								</li>
							)}

							{paramPage === 'sandboxEdit' && sandboxId !== null && (
								<li className={'action-item edit'}>
									<NavLink className={'nav-link'} role={'button'} to={`/airport/edit/${id}`} title={`Click to View ${name} Details`} target={'_blank'}>
										<i className="fa-solid fa-edit"></i>
										<span className={'sr-only'}>View {name} Details</span>
									</NavLink>
								</li>
							)}

							{paramPage === 'sandboxEdit' && (
								<li className={'action-item regret'}>
									<NavLink
										className={'nav-link'}
										role={'button'}
										to={`#`}
										title={'Remove Airport from Sandbox'}
										onClick={() => {
											openRemoveSandboxDialog(id);
										}}>
										<i className="fa-solid fa-trash"></i>
										<span className={'sr-only'}>Click to Remove Airport from This Sandbox</span>
									</NavLink>

									{sandboxRemovalDialogVisible && sandboxRemovalDialogId === id && SandboxRemovalDialogWindow(id)}
								</li>
							)}
						</ul>
					</Tooltip>
				</div>
			</td>
		);
	};

	return (
		<React.Fragment>
			{environment !== 'sandbox' && environment !== 'selection' && (
				<div className={'page-title padding-bottom-10'}>
					<h2>Airports List</h2>

					{PageTitleControls()}
				</div>
			)}

			<Grid
				data={airports.slice(page.skip, page.take + page.skip).map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[item.id] }))}
				skip={page.skip}
				take={page.take}
				total={airports.length}
				pageable={{ buttonCount: 5, pageSizes: [10, 25, 50, 150] }}
				filterable={false}
				onPageChange={pageChange}
				selectedField={SELECTED_FIELD}
				onSelectionChange={onSelectionChange}
				selectable={{
					enabled: true,
					drag: false,
					cell: false,
					mode: 'multiple'
				}}>
				<GridNoRecords>No Records Available</GridNoRecords>

				{environment === 'selection' && <Column field={SELECTED_FIELD} headerSelectionValue={airports.findIndex((item) => !selectedState[item.id]) === -1} />}

				{environment === 'sandbox' || environment === 'selection' ? <Column field="name" filter="text" title="Airport Name" className="sandboxList" cell={AirportColumns.NameCell} /> : <Column field="name" filter="text" title="Airport Name" cell={AirportColumns.NameCell} />}

				<Column field="country_code" filter="text" title="Country Code" cell={AirportColumns.CountryCodeCell} />
				<Column field="icao" filter="text" title="ICAO" cell={AirportColumns.IcaoCell} />
				<Column field="iata" filter="text" title="IATA" cell={AirportColumns.IataCell} />
				<Column field="latitude" filter="text" title="Latitude" cell={AirportColumns.LatitudeCell} />
				<Column field="longitude" filter="text" title="Longitude" cell={AirportColumns.LongitudeCell} />
				{/*<Column field="status" filter="text" title="Status" cell={AirportColumns.StatusCell} />*/}
				{environment !== 'selection' && paramPage !== 'summary' && <Column field="id" title=" " sortable={false} filterable={false} cell={ActionsCell} />}
			</Grid>

			<AirportMapModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalContent={modalContent} />
		</React.Fragment>
	);
};

export default AirportsListGrid;
