/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { connect, useSelector } from 'react-redux';
import * as Actions from './store/actions';
import actions from './store/actions';

import AirportEdit from './Components/Pages/AirportEdit/AirportEdit';
import AirportNew from './Components/Pages/AirportNew/AirportNew';
import AirportsList from './Components/Pages/AirportsList/AirportsList';
import Analysis from './Components/Pages/Analysis/Analysis';
import AssetAvailability from './Components/Pages/AssetAvailability/AssetAvailability';
import AssetEdit from './Components/Pages/AssetEdit/AssetEdit';
import AssetNew from './Components/Pages/AssetNew/AssetNew';
import Dashboard from './Components/Pages/Dashboard/Dashboard';
import DisruptionManagement from './Components/Pages/DisruptionManagement/DisruptionManagement';
import DisruptionInformation from './Components/Pages/DisruptionInformation/DisruptionInformation';
import Unauthorized from './Components/Pages/Unauthorized/Unauthorized';
import LiftRequestEdit from './Components/Pages/LiftRequestEdit/LiftRequestEdit';
import LiftRequestHistory from './Components/Pages/LiftRequestHistory/LiftRequestHistory';
import LiftRequestView from './Components/Pages/LiftRequestView/LiftRequestView';
import LiftRequestsList from './Components/Pages/LiftRequestsList/LiftRequestsList';
import Validation from './Components/Pages/Validation/Validation';
import MissionEdit from './Components/Pages/MissionEdit/MissionEdit';
import MissionNew from './Components/Pages/MissionNew/MissionNew';
import MissionStatus from './Components/Pages/MissionStatus/MissionStatus';
import MissionsList from './Components/Pages/MissionsList/MissionsList';
import SolverStatus from './Components/Pages/SolverStatus/SolverStatus';
import MultiMission from './Components/Pages/MultiMission/MultiMission';
import NewLiftRequest from './Components/Pages/NewLiftRequest/NewLiftRequest';
import NewLiftRequestWizard from './Components/Pages/NewLiftRequestWizard/NewLiftRequestWizard';
import Profile from './Components/Pages/Profile/Profile';
import Reports from './Components/Pages/Reports/Reports';
import ScheduleLegs from './Components/Pages/ScheduleLegs/ScheduleLegs';
import ScheduleMap from './Components/Pages/ScheduleMap/ScheduleMap';
import RadarBoxMap from './Components/Pages/RadarBoxMap/RadarBoxMap';
import Sidebar from './Components/App/Sidebar/Sidebar';
import SquadronView from './Components/Pages/SquadronView/SquadronView';
import SquadronsList from './Components/Pages/SquadronsList/SquadronsList';
import SandboxDashboard from './Components/Pages/Sandbox/SandboxDashboard/SandboxDashboard';
import NewSandboxWizard from './Components/Pages/Sandbox/NewSandboxWizard/NewSandboxWizard';
import SandboxView from './Components/Pages/Sandbox/SandboxView/SandboxView';
import SandboxEdit from './Components/Pages/Sandbox/SandboxEdit/SandboxEdit';
import SandboxResults from './Components/Pages/Sandbox/SandboxResults/SandboxResults';
import Tests from './Components/Pages/Tests/Tests';

import Header from './Components/Core/Header';
import Layout from './Components/Core/Layout';
import Main from './Components/Core/Main';

import AuthLoader from './Components/Auth/AuthLoader';
import { ToastContainer } from 'react-toastify';

import pkg from '../package.json';
import { accessValidator } from './Components/Helpers/RolesPermissions';
const appVersion = pkg.version;
const appName = process.env.REACT_APP_NAME;

// App Roles Used in Access Permissions
// const appRoles = process.env.REACT_APP_ROLES;

const App = (props) => {
	const accessAirportEdit = ['nalo'];
	const accessAirportNew = ['nalo'];
	const accessAirportsList = ['nalo'];
	const accessAnalysis = ['nalo'];

	const accessAssetManagement = ['nalo', 'squadron'];
	const accessAssetEdit = ['nalo'];
	const accessAssetNew = ['nalo'];

	const accessDisruptionInformation = ['nalo'];
	const accessDisruptionManagement = ['nalo'];
	const accessLiftRequestsList = ['approver', 'nalo', 'requester'];
	const accessNewLiftRequestFull = ['nalo'];
	const accessNewLiftRequestWizard = ['requester'];
	const accessMissionsList = ['nalo'];

	const accessMissionEdit = ['nalo'];
	const accessMissionNew = ['nalo'];
	const accessMissionStatus = ['nalo', 'squadron'];
	const accessMissionStatusById = ['nalo', 'squadron'];

	const accessReports = ['nalo'];

	const accessSandbox = ['nalo'];

	const accessSchedule = ['nalo'];
	const accessScheduleLegs = ['nalo'];
	const accessScheduleLegsById = ['nalo'];
	const accessScheduleMap = ['nalo'];
	const accessScheduleMapById = ['nalo'];

	const accessSolverStatus = ['nalo'];

	const accessSquadronView = ['nalo'];
	const accessSquadronsList = ['nalo'];

	const accessDeveloperTests = ['approver', 'nalo', 'requester', 'squadron'];
	var theme = 'theme-default';
	// const navigate = useNavigate();

	useSelector((state) => {
		if (state.keycloak.profile.attributes.userThemeValue !== undefined) {
			theme = state.keycloak.profile.attributes.userThemeValue[0];
		}
	});

	useEffect(() => {
		props.onStart();

		function refreshTokens() {
			if (actions.isLoggedIn()) {
				actions.updateToken();
			}
		}
		const minute = 1000 * 60;

		refreshTokens();

		setInterval(refreshTokens, minute * 2.5);
	}, []);

	const location = useLocation();

	// Authenticated
	if (props.authenticated) {
		return (
			<Layout userrole={'isauthed'} apptheme={theme}>
				<ToastContainer />

				{location.pathname.includes('sandbox') ? <Sidebar appname={appName} sandboxView={true} /> : <Sidebar appname={appName} />}

				<Main>
					<Header appname={appName} appversion={appVersion} />

					<Routes>
						<Route exact path="/" element={<Navigate to="/dashboard" />} />
						<Route exact path="/dashboard" element={<Dashboard />} />

						<Route exact path="/airportslist" element={accessValidator(props.roleaccess, accessAirportsList) ? <AirportsList /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/airport/edit/:airportId" element={accessValidator(props.roleaccess, accessAirportEdit) ? <AirportEdit /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/airport/new" element={accessValidator(props.roleaccess, accessAirportNew) ? <AirportNew /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/analysis" element={accessValidator(props.roleaccess, accessAnalysis) ? <Analysis /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/assetavailability" element={accessValidator(props.roleaccess, accessAssetManagement) ? <AssetAvailability /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/asset/edit/:assetId" element={accessValidator(props.roleaccess, accessAssetEdit) ? <AssetEdit /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/asset/new" element={accessValidator(props.roleaccess, accessAssetNew) ? <AssetNew /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/disruptions" element={accessValidator(props.roleaccess, accessDisruptionManagement) ? <DisruptionManagement /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/disruption/:disruptionId" element={accessValidator(props.roleaccess, accessDisruptionInformation) ? <DisruptionInformation /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/liftrequest/view/:liftId" element={accessValidator(props.roleaccess, accessLiftRequestsList) && !accessValidator(props.roleaccess, ['squadron']) ? <LiftRequestView /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/liftrequest/edit/:liftId" element={accessValidator(props.roleaccess, accessLiftRequestsList) && !accessValidator(props.roleaccess, ['squadron']) ? <LiftRequestEdit /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/liftrequest/history/:liftId" element={accessValidator(props.roleaccess, accessLiftRequestsList) && !accessValidator(props.roleaccess, ['squadron']) ? <LiftRequestHistory /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/liftrequestslist" element={accessValidator(props.roleaccess, accessLiftRequestsList) && !accessValidator(props.roleaccess, ['squadron']) ? <LiftRequestsList /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/validation/:liftId" element={accessValidator(props.roleaccess, accessLiftRequestsList) && !accessValidator(props.roleaccess, ['squadron']) ? <Validation /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/mission/edit/:missionId" element={accessValidator(props.roleaccess, accessMissionEdit) ? <MissionEdit /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/mission/new" element={accessValidator(props.roleaccess, accessMissionNew) ? <MissionNew /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/missionstatus" element={accessValidator(props.roleaccess, accessMissionStatus) ? <MissionStatus /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/missionslist/" element={accessValidator(props.roleaccess, accessMissionsList) ? <MissionsList /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/solverstatus" element={accessValidator(props.roleaccess, accessSolverStatus) ? <SolverStatus /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/missionstatus/:missionId" element={accessValidator(props.roleaccess, accessMissionStatusById) ? <MissionStatus /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/newairlift/" element={accessValidator(props.roleaccess, accessNewLiftRequestFull) ? <NewLiftRequest /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/newairliftwizard/" element={accessValidator(props.roleaccess, accessNewLiftRequestWizard) && !accessValidator(props.roleaccess, ['approver']) && !accessValidator(props.roleaccess, ['nalo']) && !accessValidator(props.roleaccess, ['squadron']) ? <NewLiftRequestWizard /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/profile/:userId" element={<Profile />} />

						<Route exact path="/reports" element={accessValidator(props.roleaccess, accessReports) ? <Reports /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/schedule" element={accessValidator(props.roleaccess, accessSchedule) ? <MultiMission /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/schedule/legs" element={accessValidator(props.roleaccess, accessScheduleLegs) ? <ScheduleLegs /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/schedule/legs/:missionId" element={accessValidator(props.roleaccess, accessScheduleLegsById) ? <ScheduleLegs /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/schedule/map" element={accessValidator(props.roleaccess, accessScheduleMap) ? <ScheduleMap /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/schedule/map/:missionId" element={accessValidator(props.roleaccess, accessScheduleMapById) ? <ScheduleMap /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/radarbox/map" element={accessValidator(props.roleaccess, accessScheduleMap) ? <RadarBoxMap /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/radarbox/map/:missionId" element={accessValidator(props.roleaccess, accessScheduleMapById) ? <RadarBoxMap /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/squadron/view/:squadronId" element={accessValidator(props.roleaccess, accessSquadronView) ? <SquadronView /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/squadronslist" element={accessValidator(props.roleaccess, accessSquadronsList) ? <SquadronsList /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/sandbox/dashboard" element={accessValidator(props.roleaccess, accessSandbox) ? <SandboxDashboard /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/sandbox/newsandbox/" element={accessValidator(props.roleaccess, accessSandbox) ? <NewSandboxWizard /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/sandbox/view/:sandboxId" element={accessValidator(props.roleaccess, accessSandbox) ? <SandboxView /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/sandbox/edit/:sandboxId" element={accessValidator(props.roleaccess, accessSandbox) ? <SandboxEdit /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/sandbox/results/:sandboxId" element={accessValidator(props.roleaccess, accessSandbox) ? <SandboxResults /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/tests/:userId" element={accessValidator(props.roleaccess, accessDeveloperTests) && process.env.REACT_APP_DEBUG === 'true' ? <Tests /> : <Navigate to="/unauthorized" />} />

						<Route exact path="/unauthorized" element={<Unauthorized />} />
					</Routes>
				</Main>
			</Layout>
		);

		// Unauthenticated
	} else {
		return <AuthLoader />;
	}
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
