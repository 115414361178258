/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import MissionsListGrid from './MissionsListGrid';
import { NavLink } from 'react-router-dom';
import { accessValidator } from '../../Helpers/RolesPermissions';

const MissionsListTabs = (props) => {
	const [selected, setSelected] = React.useState(0);

	const accessMissionCreate = ['nalo'];

	const handleSelect = (e) => {
		setSelected(e.selected);
	};

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg" style={{ display: 'block' }}>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						{accessValidator(props.roleaccess, accessMissionCreate) && (
							<div className={'nav-link-group'}>
								<NavLink to={`/mission/new`} className={'nav-link btn-edit-mission icon-only'} title={'Click to Create a New Mission'}>
									<i className={'fa-solid fa-circle-plus padding-right-8'}></i>
									<span>New Mission</span>
								</NavLink>
							</div>
						)}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	return (
		<React.Fragment>
			<div className={'page-title'}>
				<h2>Missions List</h2>

				{PageTitleControls()}
			</div>

			<TabStrip selected={selected} onSelect={handleSelect} id="lift-request-list-tabs" className="lift-request-list-tabs">
				<TabStripTab eventKey="all" title="All Missions">
					<MissionsListGrid queryparams={'?state=GENERATED&state=DEPARTED&state=COMPLETED'} token={props.token} />
				</TabStripTab>

				<TabStripTab eventKey="generated" title="Generated">
					<MissionsListGrid queryparams={'?state=GENERATED'} token={props.token} />
				</TabStripTab>

				<TabStripTab eventKey="departed" title="Departed">
					<MissionsListGrid queryparams={'?state=DEPARTED'} token={props.token} />
				</TabStripTab>

				<TabStripTab eventKey="completed" title="Completed">
					<MissionsListGrid queryparams={'?state=COMPLETED'} token={props.token} />
				</TabStripTab>

				<TabStripTab eventKey="cancelled" title="Cancelled">
					<MissionsListGrid queryparams={'?state=CANCELLED'} token={props.token} />
				</TabStripTab>
			</TabStrip>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname,
		theme: state.theme
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionsListTabs);
