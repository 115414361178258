/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import moment from 'moment';

// Return a Date/Time string without the time zone identifier
export const stripTimeZone = (date) => {
	if (date !== null) {
		// Handle UTC time
		if (date.lastIndexOf('Z') !== -1) {
			return date.substring(0, date.lastIndexOf('Z'));
		}

		// Handle Positive UTC offset
		else if (date.lastIndexOf('+') !== -1) {
			return date.substring(0, date.lastIndexOf('+'));
		}

		// Handle negative UTC offset
		else if (date.lastIndexOf('-') !== -1) {
			return date.substring(0, date.lastIndexOf('-'));
		}

		// Provided Date/Time string must be in stripped format already
		else {
			return date;
		}
	} else {
		return date;
	}
};

// Return an ISO standard Date string from a JavaScript Time Object in UTC
export const forceDateStringToUTCISOFormat = (date) => {
	return moment.parseZone(date).utc(true).format();
};

export const forceDateStringToUTCISOFormatEOD = (date) => {
	return moment.parseZone(date).utc(true).endOf('day').format();
};

export const forceDateStringToUTCISOFormatSOD = (date) => {
	return moment.parseZone(date).utc(true).add(1, 'second').format();
};
