/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

/**
 * Global: Countries List
 * @type {[{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string},null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]}
 */
export const optionsCountries = [
	{ value: 'AF', label: 'Afghanistan', valueandlabel: 'AF - Afghanistan' },
	{ value: 'AX', label: 'Aland Islands', valueandlabel: 'AX - Aland Islands' },
	{ value: 'AL', label: 'Albania', valueandlabel: 'AL - Albania' },
	{ value: 'DZ', label: 'Algeria', valueandlabel: 'DZ - Algeria' },
	{ value: 'AS', label: 'American Samoa', valueandlabel: 'AS - American Samoa' },
	{ value: 'AD', label: 'Andorra', valueandlabel: 'AD - Andorra' },
	{ value: 'AO', label: 'Angola', valueandlabel: 'AO - Angola' },
	{ value: 'AI', label: 'Anguilla', valueandlabel: 'AI - Anguilla' },
	{ value: 'AQ', label: 'Antarctica', valueandlabel: 'AQ - Antarctica' },
	{ value: 'AG', label: 'Antigua and Barbuda', valueandlabel: 'AG - Antigua and Barbuda' },
	{ value: 'AR', label: 'Argentina', valueandlabel: 'AR - Argentina' },
	{ value: 'AM', label: 'Armenia', valueandlabel: 'AM - Armenia' },
	{ value: 'AW', label: 'Aruba', valueandlabel: 'AW - Aruba' },
	{ value: 'AU', label: 'Australia', valueandlabel: 'AU - Australia' },
	{ value: 'AT', label: 'Austria', valueandlabel: 'AT - Austria' },
	{ value: 'AZ', label: 'Azerbaijan', valueandlabel: 'AZ - Azerbaijan' },
	{ value: 'BS', label: 'Bahamas', valueandlabel: 'BS - Bahamas' },
	{ value: 'BH', label: 'Bahrain', valueandlabel: 'BH - Bahrain' },
	{ value: 'BD', label: 'Bangladesh', valueandlabel: 'BD - Bangladesh' },
	{ value: 'BB', label: 'Barbados', valueandlabel: 'BB - Barbados' },
	{ value: 'BY', label: 'Belarus', valueandlabel: 'BY - Belarus' },
	{ value: 'BE', label: 'Belgium', valueandlabel: 'BE - Belgium' },
	{ value: 'BZ', label: 'Belize', valueandlabel: 'BZ - Belize' },
	{ value: 'BJ', label: 'Benin', valueandlabel: 'BJ - Benin' },
	{ value: 'BM', label: 'Bermuda', valueandlabel: 'BM - Bermuda' },
	{ value: 'BT', label: 'Bhutan', valueandlabel: 'BT - Bhutan' },
	{ value: 'BO', label: 'Bolivia', valueandlabel: 'BO - Bolivia' },
	{ value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba', valueandlabel: 'BQ - Bonaire, Sint Eustatius and Saba' },
	{ value: 'BA', label: 'Bosnia and Herzegovina', valueandlabel: 'BA - Bosnia and Herzegovina' },
	{ value: 'BW', label: 'Botswana', valueandlabel: 'BW - Botswana' },
	{ value: 'BV', label: 'Bouvet Island', valueandlabel: 'BV - Bouvet Island' },
	{ value: 'BR', label: 'Brazil', valueandlabel: 'BR - Brazil' },
	{ value: 'IO', label: 'British Indian Ocean Territory', valueandlabel: 'IO - British Indian Ocean Territory' },
	{ value: 'BN', label: 'Brunei Darussalam', valueandlabel: 'BN - Brunei Darussalam' },
	{ value: 'BG', label: 'Bulgaria', valueandlabel: 'BG - Bulgaria' },
	{ value: 'BF', label: 'Burkina Faso', valueandlabel: 'BF - Burkina Faso' },
	{ value: 'BI', label: 'Burundi', valueandlabel: 'BI - Burundi' },
	{ value: 'KH', label: 'Cambodia', valueandlabel: 'KH - Cambodia' },
	{ value: 'CM', label: 'Cameroon', valueandlabel: 'CM - Cameroon' },
	{ value: 'CA', label: 'Canada', valueandlabel: 'CA - Canada' },
	{ value: 'CV', label: 'Cape Verde', valueandlabel: 'CV - Cape Verde' },
	{ value: 'KY', label: 'Cayman Islands', valueandlabel: 'KY - Cayman Islands' },
	{ value: 'CF', label: 'Central African Republic', valueandlabel: 'CF - Central African Republic' },
	{ value: 'TD', label: 'Chad', valueandlabel: 'TD - Chad' },
	{ value: 'CL', label: 'Chile', valueandlabel: 'CL - Chile' },
	{ value: 'CN', label: 'China', valueandlabel: 'CN - China' },
	{ value: 'CX', label: 'Christmas Island', valueandlabel: 'CX - Christmas Island' },
	{ value: 'CC', label: 'Cocos (Keeling) Islands', valueandlabel: 'CC - Cocos (Keeling) Islands' },
	{ value: 'CO', label: 'Colombia', valueandlabel: 'CO - Colombia' },
	{ value: 'KM', label: 'Comoros', valueandlabel: 'KM - Comoros' },
	{ value: 'CG', label: 'Congo', valueandlabel: 'CG - Congo' },
	{ value: 'CD', label: 'Congo, Democratic Republic of the Congo', valueandlabel: 'CD - Congo, Democratic Republic of the Congo' },
	{ value: 'CK', label: 'Cook Islands', valueandlabel: 'CK - Cook Islands' },
	{ value: 'CR', label: 'Costa Rica', valueandlabel: 'CR - Costa Rica' },
	{ value: 'CI', label: "Cote D'Ivoire", valueandlabel: "CI - Cote D'Ivoire" },
	{ value: 'HR', label: 'Croatia', valueandlabel: 'HR - Croatia' },
	{ value: 'CU', label: 'Cuba', valueandlabel: 'CU - Cuba' },
	{ value: 'CW', label: 'Curacao', valueandlabel: 'CW - Curacao' },
	{ value: 'CY', label: 'Cyprus', valueandlabel: 'CY - Cyprus' },
	{ value: 'CZ', label: 'Czech Republic', valueandlabel: 'CZ - Czech Republic' },
	{ value: 'DK', label: 'Denmark', valueandlabel: 'DK - Denmark' },
	{ value: 'DJ', label: 'Djibouti', valueandlabel: 'DJ - Djibouti' },
	{ value: 'DM', label: 'Dominica', valueandlabel: 'DM - Dominica' },
	{ value: 'DO', label: 'Dominican Republic', valueandlabel: 'DO - Dominican Republic' },
	{ value: 'EC', label: 'Ecuador', valueandlabel: 'EC - Ecuador' },
	{ value: 'EG', label: 'Egypt', valueandlabel: 'EG - Egypt' },
	{ value: 'SV', label: 'El Salvador', valueandlabel: 'SV - El Salvador' },
	{ value: 'GQ', label: 'Equatorial Guinea', valueandlabel: 'GQ - Equatorial Guinea' },
	{ value: 'ER', label: 'Eritrea', valueandlabel: 'ER - Eritrea' },
	{ value: 'EE', label: 'Estonia', valueandlabel: 'EE - Estonia' },
	{ value: 'ET', label: 'Ethiopia', valueandlabel: 'ET - Ethiopia' },
	{ value: 'FK', label: 'Falkland Islands (Malvinas)', valueandlabel: 'FK - Falkland Islands (Malvinas)' },
	{ value: 'FO', label: 'Faroe Islands', valueandlabel: 'FO - Faroe Islands' },
	{ value: 'FJ', label: 'Fiji', valueandlabel: 'FJ - Fiji' },
	{ value: 'FI', label: 'Finland', valueandlabel: 'FI - Finland' },
	{ value: 'FR', label: 'France', valueandlabel: 'FR - France' },
	{ value: 'GF', label: 'French Guiana', valueandlabel: 'GF - French Guiana' },
	{ value: 'PF', label: 'French Polynesia', valueandlabel: 'PF - French Polynesia' },
	{ value: 'TF', label: 'French Southern Territories', valueandlabel: 'TF - French Southern Territories' },
	{ value: 'GA', label: 'Gabon', valueandlabel: 'GA - Gabon' },
	{ value: 'GM', label: 'Gambia', valueandlabel: 'GM - Gambia' },
	{ value: 'GE', label: 'Georgia', valueandlabel: 'GE - Georgia' },
	{ value: 'DE', label: 'Germany', valueandlabel: 'DE - Germany' },
	{ value: 'GH', label: 'Ghana', valueandlabel: 'GH - Ghana' },
	{ value: 'GI', label: 'Gibraltar', valueandlabel: 'GI - Gibraltar' },
	{ value: 'GR', label: 'Greece', valueandlabel: 'GR - Greece' },
	{ value: 'GL', label: 'Greenland', valueandlabel: 'GL - Greenland' },
	{ value: 'GD', label: 'Grenada', valueandlabel: 'GD - Grenada' },
	{ value: 'GP', label: 'Guadeloupe', valueandlabel: 'GP - Guadeloupe' },
	{ value: 'GU', label: 'Guam', valueandlabel: 'GU - Guam' },
	{ value: 'GT', label: 'Guatemala', valueandlabel: 'GT - Guatemala' },
	{ value: 'GG', label: 'Guernsey', valueandlabel: 'GG - Guernsey' },
	{ value: 'GN', label: 'Guinea', valueandlabel: 'GN - Guinea' },
	{ value: 'GW', label: 'Guinea-Bissau', valueandlabel: 'GW - Guinea-Bissau' },
	{ value: 'GY', label: 'Guyana', valueandlabel: 'GY - Guyana' },
	{ value: 'HT', label: 'Haiti', valueandlabel: 'HT - Haiti' },
	{ value: 'HM', label: 'Heard Island and Mcdonald Islands', valueandlabel: 'HM - Heard Island and Mcdonald Islands' },
	{ value: 'VA', label: 'Holy See (Vatican City State)', valueandlabel: 'VA - Holy See (Vatican City State)' },
	{ value: 'HN', label: 'Honduras', valueandlabel: 'HN - Honduras' },
	{ value: 'HK', label: 'Hong Kong', valueandlabel: 'HK - Hong Kong' },
	{ value: 'HU', label: 'Hungary', valueandlabel: 'HU - Hungary' },
	{ value: 'IS', label: 'Iceland', valueandlabel: 'IS - Iceland' },
	{ value: 'IN', label: 'India', valueandlabel: 'IN - India' },
	{ value: 'ID', label: 'Indonesia', valueandlabel: 'ID - Indonesia' },
	{ value: 'IR', label: 'Iran, Islamic Republic of', valueandlabel: 'IR - Iran, Islamic Republic of' },
	{ value: 'IQ', label: 'Iraq', valueandlabel: 'IQ - Iraq' },
	{ value: 'IE', label: 'Ireland', valueandlabel: 'IE - Ireland' },
	{ value: 'IM', label: 'Isle of Man', valueandlabel: 'IM - Isle of Man' },
	{ value: 'IL', label: 'Israel', valueandlabel: 'IL - Israel' },
	{ value: 'IT', label: 'Italy', valueandlabel: 'IT - Italy' },
	{ value: 'JM', label: 'Jamaica', valueandlabel: 'JM - Jamaica' },
	{ value: 'JP', label: 'Japan', valueandlabel: 'JP - Japan' },
	{ value: 'JE', label: 'Jersey', valueandlabel: 'JE - Jersey' },
	{ value: 'JO', label: 'Jordan', valueandlabel: 'JO - Jordan' },
	{ value: 'KZ', label: 'Kazakhstan', valueandlabel: 'KZ - Kazakhstan' },
	{ value: 'KE', label: 'Kenya', valueandlabel: 'KE - Kenya' },
	{ value: 'KI', label: 'Kiribati', valueandlabel: 'KI - Kiribati' },
	{ value: 'KP', label: "Korea, Democratic People's Republic of", valueandlabel: "KP - Korea, Democratic People's Republic of" },
	{ value: 'KR', label: 'Korea, Republic of', valueandlabel: 'KR - Korea, Republic of' },
	{ value: 'XK', label: 'Kosovo', valueandlabel: 'XK - Kosovo' },
	{ value: 'KW', label: 'Kuwait', valueandlabel: 'KW - Kuwait' },
	{ value: 'KG', label: 'Kyrgyzstan', valueandlabel: 'KG - Kyrgyzstan' },
	{ value: 'LA', label: "Lao People's Democratic Republic", valueandlabel: "LA - Lao People's Democratic Republic" },
	{ value: 'LV', label: 'Latvia', valueandlabel: 'LV - Latvia' },
	{ value: 'LB', label: 'Lebanon', valueandlabel: 'LB - Lebanon' },
	{ value: 'LS', label: 'Lesotho', valueandlabel: 'LS - Lesotho' },
	{ value: 'LR', label: 'Liberia', valueandlabel: 'LR - Liberia' },
	{ value: 'LY', label: 'Libyan Arab Jamahiriya', valueandlabel: 'LY - Libyan Arab Jamahiriya' },
	{ value: 'LI', label: 'Liechtenstein', valueandlabel: 'LI - Liechtenstein' },
	{ value: 'LT', label: 'Lithuania', valueandlabel: 'LT - Lithuania' },
	{ value: 'LU', label: 'Luxembourg', valueandlabel: 'LU - Luxembourg' },
	{ value: 'MO', label: 'Macao', valueandlabel: 'MO - Macao' },
	{ value: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', valueandlabel: 'MK - Macedonia, the Former Yugoslav Republic of' },
	{ value: 'MG', label: 'Madagascar', valueandlabel: 'MG - Madagascar' },
	{ value: 'MW', label: 'Malawi', valueandlabel: 'MW - Malawi' },
	{ value: 'MY', label: 'Malaysia', valueandlabel: 'MY - Malaysia' },
	{ value: 'MV', label: 'Maldives', valueandlabel: 'MV - Maldives' },
	{ value: 'ML', label: 'Mali', valueandlabel: 'ML - Mali' },
	{ value: 'MT', label: 'Malta', valueandlabel: 'MT - Malta' },
	{ value: 'MH', label: 'Marshall Islands', valueandlabel: 'MH - Marshall Islands' },
	{ value: 'MQ', label: 'Martinique', valueandlabel: 'MQ - Martinique' },
	{ value: 'MR', label: 'Mauritania', valueandlabel: 'MR - Mauritania' },
	{ value: 'MU', label: 'Mauritius', valueandlabel: 'MU - Mauritius' },
	{ value: 'YT', label: 'Mayotte', valueandlabel: 'YT - Mayotte' },
	{ value: 'MX', label: 'Mexico', valueandlabel: 'MX - Mexico' },
	{ value: 'FM', label: 'Micronesia, Federated States of', valueandlabel: 'FM - Micronesia, Federated States of' },
	{ value: 'MD', label: 'Moldova, Republic of', valueandlabel: 'MD - Moldova, Republic of' },
	{ value: 'MC', label: 'Monaco', valueandlabel: 'MC - Monaco' },
	{ value: 'MN', label: 'Mongolia', valueandlabel: 'MN - Mongolia' },
	{ value: 'ME', label: 'Montenegro', valueandlabel: 'ME - Montenegro' },
	{ value: 'MS', label: 'Montserrat', valueandlabel: 'MS - Montserrat' },
	{ value: 'MA', label: 'Morocco', valueandlabel: 'MA - Morocco' },
	{ value: 'MZ', label: 'Mozambique', valueandlabel: 'MZ - Mozambique' },
	{ value: 'MM', label: 'Myanmar', valueandlabel: 'MM - Myanmar' },
	{ value: 'NA', label: 'Namibia', valueandlabel: 'NA - Namibia' },
	{ value: 'NR', label: 'Nauru', valueandlabel: 'NR - Nauru' },
	{ value: 'NP', label: 'Nepal', valueandlabel: 'NP - Nepal' },
	{ value: 'NL', label: 'Netherlands', valueandlabel: 'NL - Netherlands' },
	{ value: 'AN', label: 'Netherlands Antilles', valueandlabel: 'AN - Netherlands Antilles' },
	{ value: 'NC', label: 'New Caledonia', valueandlabel: 'NC - New Caledonia' },
	{ value: 'NZ', label: 'New Zealand', valueandlabel: 'NZ - New Zealand' },
	{ value: 'NI', label: 'Nicaragua', valueandlabel: 'NI - Nicaragua' },
	{ value: 'NE', label: 'Niger', valueandlabel: 'NE - Niger' },
	{ value: 'NG', label: 'Nigeria', valueandlabel: 'NG - Nigeria' },
	{ value: 'NU', label: 'Niue', valueandlabel: 'NU - Niue' },
	{ value: 'NF', label: 'Norfolk Island', valueandlabel: 'NF - Norfolk Island' },
	{ value: 'MP', label: 'Northern Mariana Islands', valueandlabel: 'MP - Northern Mariana Islands' },
	{ value: 'NO', label: 'Norway', valueandlabel: 'NO - Norway' },
	{ value: 'OM', label: 'Oman', valueandlabel: 'OM - Oman' },
	{ value: 'PK', label: 'Pakistan', valueandlabel: 'PK - Pakistan' },
	{ value: 'PW', label: 'Palau', valueandlabel: 'PW - Palau' },
	{ value: 'PS', label: 'Palestinian Territory, Occupied', valueandlabel: 'PS - Palestinian Territory, Occupied' },
	{ value: 'PA', label: 'Panama', valueandlabel: 'PA - Panama' },
	{ value: 'PG', label: 'Papua New Guinea', valueandlabel: 'PG - Papua New Guinea' },
	{ value: 'PY', label: 'Paraguay', valueandlabel: 'PY - Paraguay' },
	{ value: 'PE', label: 'Peru', valueandlabel: 'PE - Peru' },
	{ value: 'PH', label: 'Philippines', valueandlabel: 'PH - Philippines' },
	{ value: 'PN', label: 'Pitcairn', valueandlabel: 'PN - Pitcairn' },
	{ value: 'PL', label: 'Poland', valueandlabel: 'PL - Poland' },
	{ value: 'PT', label: 'Portugal', valueandlabel: 'PT - Portugal' },
	{ value: 'PR', label: 'Puerto Rico', valueandlabel: 'PR - Puerto Rico' },
	{ value: 'QA', label: 'Qatar', valueandlabel: 'QA - Qatar' },
	{ value: 'RE', label: 'Reunion', valueandlabel: 'RE - Reunion' },
	{ value: 'RO', label: 'Romania', valueandlabel: 'RO - Romania' },
	{ value: 'RU', label: 'Russian Federation', valueandlabel: 'RU - Russian Federation' },
	{ value: 'RW', label: 'Rwanda', valueandlabel: 'RW - Rwanda' },
	{ value: 'BL', label: 'Saint Barthelemy', valueandlabel: 'BL - Saint Barthelemy' },
	{ value: 'SH', label: 'Saint Helena', valueandlabel: 'SH - Saint Helena' },
	{ value: 'KN', label: 'Saint Kitts and Nevis', valueandlabel: 'KN - Saint Kitts and Nevis' },
	{ value: 'LC', label: 'Saint Lucia', valueandlabel: 'LC - Saint Lucia' },
	{ value: 'MF', label: 'Saint Martin', valueandlabel: 'MF - Saint Martin' },
	{ value: 'PM', label: 'Saint Pierre and Miquelon', valueandlabel: 'PM - Saint Pierre and Miquelon' },
	{ value: 'VC', label: 'Saint Vincent and the Grenadines', valueandlabel: 'VC - Saint Vincent and the Grenadines' },
	{ value: 'WS', label: 'Samoa', valueandlabel: 'WS - Samoa' },
	{ value: 'SM', label: 'San Marino', valueandlabel: 'SM - San Marino' },
	{ value: 'ST', label: 'Sao Tome and Principe', valueandlabel: 'ST - Sao Tome and Principe' },
	{ value: 'SA', label: 'Saudi Arabia', valueandlabel: 'SA - Saudi Arabia' },
	{ value: 'SN', label: 'Senegal', valueandlabel: 'SN - Senegal' },
	{ value: 'RS', label: 'Serbia', valueandlabel: 'RS - Serbia' },
	{ value: 'CS', label: 'Serbia and Montenegro', valueandlabel: 'CS - Serbia and Montenegro' },
	{ value: 'SC', label: 'Seychelles', valueandlabel: 'SC - Seychelles' },
	{ value: 'SL', label: 'Sierra Leone', valueandlabel: 'SL - Sierra Leone' },
	{ value: 'SG', label: 'Singapore', valueandlabel: 'SG - Singapore' },
	{ value: 'SX', label: 'Sint Maarten', valueandlabel: 'SX - Sint Maarten' },
	{ value: 'SK', label: 'Slovakia', valueandlabel: 'SK - Slovakia' },
	{ value: 'SI', label: 'Slovenia', valueandlabel: 'SI - Slovenia' },
	{ value: 'SB', label: 'Solomon Islands', valueandlabel: 'SB - Solomon Islands' },
	{ value: 'SO', label: 'Somalia', valueandlabel: 'SO - Somalia' },
	{ value: 'ZA', label: 'South Africa', valueandlabel: 'ZA - South Africa' },
	{ value: 'GS', label: 'South Georgia and the South Sandwich Islands', valueandlabel: 'GS - South Georgia and the South Sandwich Islands' },
	{ value: 'SS', label: 'South Sudan', valueandlabel: 'SS - South Sudan' },
	{ value: 'ES', label: 'Spain', valueandlabel: 'ES - Spain' },
	{ value: 'LK', label: 'Sri Lanka', valueandlabel: 'LK - Sri Lanka' },
	{ value: 'SD', label: 'Sudan', valueandlabel: 'SD - Sudan' },
	{ value: 'SR', label: 'Suriname', valueandlabel: 'SR - Suriname' },
	{ value: 'SJ', label: 'Svalbard and Jan Mayen', valueandlabel: 'SJ - Svalbard and Jan Mayen' },
	{ value: 'SZ', label: 'Swaziland', valueandlabel: 'SZ - Swaziland' },
	{ value: 'SE', label: 'Sweden', valueandlabel: 'SE - Sweden' },
	{ value: 'CH', label: 'Switzerland', valueandlabel: 'CH - Switzerland' },
	{ value: 'SY', label: 'Syrian Arab Republic', valueandlabel: 'SY - Syrian Arab Republic' },
	{ value: 'TW', label: 'Taiwan, Province of China', valueandlabel: 'TW - Taiwan, Province of China' },
	{ value: 'TJ', label: 'Tajikistan', valueandlabel: 'TJ - Tajikistan' },
	{ value: 'TZ', label: 'Tanzania, United Republic of', valueandlabel: 'TZ - Tanzania, United Republic of' },
	{ value: 'TH', label: 'Thailand', valueandlabel: 'TH - Thailand' },
	{ value: 'TL', label: 'Timor-Leste', valueandlabel: 'TL - Timor-Leste' },
	{ value: 'TG', label: 'Togo', valueandlabel: 'TG - Togo' },
	{ value: 'TK', label: 'Tokelau', valueandlabel: 'TK - Tokelau' },
	{ value: 'TO', label: 'Tonga', valueandlabel: 'TO - Tonga' },
	{ value: 'TT', label: 'Trinidad and Tobago', valueandlabel: 'TT - Trinidad and Tobago' },
	{ value: 'TN', label: 'Tunisia', valueandlabel: 'TN - Tunisia' },
	{ value: 'TR', label: 'Turkey', valueandlabel: 'TR - Turkey' },
	{ value: 'TM', label: 'Turkmenistan', valueandlabel: 'TM - Turkmenistan' },
	{ value: 'TC', label: 'Turks and Caicos Islands', valueandlabel: 'TC - Turks and Caicos Islands' },
	{ value: 'TV', label: 'Tuvalu', valueandlabel: 'TV - Tuvalu' },
	{ value: 'UG', label: 'Uganda', valueandlabel: 'UG - Uganda' },
	{ value: 'UA', label: 'Ukraine', valueandlabel: 'UA - Ukraine' },
	{ value: 'AE', label: 'United Arab Emirates', valueandlabel: 'AE - United Arab Emirates' },
	{ value: 'GB', label: 'United Kingdom', valueandlabel: 'GB - United Kingdom' },
	{ value: 'US', label: 'United States', valueandlabel: 'US - United States' },
	{ value: 'UM', label: 'United States Minor Outlying Islands', valueandlabel: 'UM - United States Minor Outlying Islands' },
	{ value: 'UY', label: 'Uruguay', valueandlabel: 'UY - Uruguay' },
	{ value: 'UZ', label: 'Uzbekistan', valueandlabel: 'UZ - Uzbekistan' },
	{ value: 'VU', label: 'Vanuatu', valueandlabel: 'VU - Vanuatu' },
	{ value: 'VE', label: 'Venezuela', valueandlabel: 'VE - Venezuela' },
	{ value: 'VN', label: 'Viet Nam', valueandlabel: 'VN - Viet Nam' },
	{ value: 'VG', label: 'Virgin Islands, British', valueandlabel: 'VG - Virgin Islands, British' },
	{ value: 'VI', label: 'Virgin Islands, U.S.', valueandlabel: 'VI - Virgin Islands, U.s.' },
	{ value: 'WF', label: 'Wallis and Futuna', valueandlabel: 'WF - Wallis and Futuna' },
	{ value: 'EH', label: 'Western Sahara', valueandlabel: 'EH - Western Sahara' },
	{ value: 'YE', label: 'Yemen', valueandlabel: 'YE - Yemen' },
	{ value: 'ZM', label: 'Zambia', valueandlabel: 'ZM - Zambia' },
	{ value: 'ZW', label: 'Zimbabwe', valueandlabel: 'ZW - Zimbabwe' }
];
