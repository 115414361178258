/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

// https://stackoverflow.com/questions/51039639/kendo-react-grid-add-a-hyperlink-column

import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { toast } from 'react-toastify';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { MissionsColumns } from '../../Helpers/DataGrids';
import { EditMissionForm } from '../../Helpers/FormHelpers/EditMissionForm';

import { ApiMissionListByState, ApiUpdateMission, ApiUpdateLegState, ApiChangeMissionState, ApiLegsById, ApiChangeLiftRequestState } from '../../Helpers/Apis';
import { accessValidator } from '../../Helpers/RolesPermissions';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

const initialDataState = {
	skip: 0,
	take: 10
};

const MissionsListGrid = (props) => {
	const [page, setPage] = React.useState(initialDataState);
	const [missions, setMissions] = React.useState([]);
	const [openForm, setOpenForm] = React.useState(false);
	const [currentId, setCurrentId] = React.useState('');
	const [visible, setVisible] = React.useState(false);
	const [editItem, setEditItem] = React.useState({
		EntryID: 1
	});

	const queryparams = props.queryparams;

	const accessMissionEdit = ['nalo'];

	const pageChange = (event) => {
		setPage(event.page);
	};

	const dataFetch = () => {
		ApiMissionListByState(queryparams).then((res) => {
			setMissions(res);
		});
	};

	const handleSubmit = (event) => {
		if (event && event.history && event.history[0]) {
			// eslint-disable-next-line
			missions.map((mission) => {
				if (mission.id === event.id) {
					mission.missionName = event.missionName;
					setMissions(missions);
				}
			});
			let submissionData = event.history[0];
			submissionData.missionName = event.missionName;

			ApiUpdateMission(event.id, submissionData).then((res) => {
				toast.success('Mission Name Changed Successfully!');
			});
		}
		setOpenForm(false);
	};

	const handleCancelEdit = () => {
		setOpenForm(false);
	};

	const enterEditForm = (item) => {
		setOpenForm(true);
		setEditItem(item);
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const openDialog = () => {
		toggleDialog();
	};

	const DialogWindow = (id) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleDialog}>
				<div data-id={id}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to cancel this mission?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					{/* get list of legs, call update leg state to CANCELLED. THEN set mission state to CANCELLED */}
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							//call apis to cancel this mission
							ApiLegsById(id).then((res) => {
								if (res.length > 0) {
									// eslint-disable-next-line
									res.map((leg) => {
										ApiUpdateLegState(leg.id, 'CANCELLED');
										//set all lift requests within this mission back to VALIDATED state
										if (leg.history[0].cargoes !== null && leg.history[0].cargoes !== undefined && leg.history[0].cargoes.length > 0) {
											// eslint-disable-next-line
											leg.history[0].cargoes.map((cargo) => {
												if (cargo.liftrequest_id) {
													ApiChangeLiftRequestState(cargo.liftrequest_id, 'VALIDATED');
												}
											});
										}
									});
								}
							});
							ApiChangeMissionState(id, 'CANCELLED').then((res) => {
								toast.success('Mission Cancelled Successfully!');
							});

							//handle visual missions data update
							// eslint-disable-next-line
							missions.map((mission) => {
								if (mission.id === id) {
									mission.history[0].state = 'CANCELLED';
									setMissions(missions);
								}
							});

							toggleDialog();
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	useEffect(() => {
		dataFetch();
	}, []);

	const ActionsCell = (p) => {
		const id = p.dataItem.id;

		return (
			<td className={'table-actions'}>
				<div className={'actions'}>
					<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'left'}>
						<ul className={'actions-list'}>
							<li>
								<NavLink onClick={() => enterEditForm(p.dataItem)} title={'Click to Change Mission Name'}>
									<i className="fa-regular fa-input-text"></i>
									<span className={'sr-only'}>Click to Change Mission Name</span>
								</NavLink>
							</li>

							{accessValidator(props.roleaccess, accessMissionEdit) && (
								<li className={'action-item edit'}>
									<NavLink to={`/mission/edit/${id}`} title={'Click to Edit Mission'}>
										<i className="fa-solid fa-edit"></i>
										<span className={'sr-only'}>Click to Edit Mission {id}</span>
									</NavLink>
								</li>
							)}

							<li className={'action-item view'}>
								<NavLink to={`/missionstatus/${id}`} title={'Click to View Mission Details'}>
									<i className="fa-solid fa-eye"></i>
									<span className={'sr-only'}>Click to View {id}</span>
								</NavLink>
							</li>
							<li className={'action-item delete'}>
								<button
									className={'nav-link'}
									title={`Click to Cancel Mission ${id}`}
									style={{ borderWidth: 0 }}
									onClick={() => {
										setCurrentId(id);
										openDialog(id);
									}}>
									<i className="fa-solid fa-trash"></i>
									<span className={'sr-only'}>Delete {id}</span>
								</button>

								{visible && currentId === id && DialogWindow(id)}
							</li>
						</ul>
					</Tooltip>
				</div>
			</td>
		);
	};

	return (
		<div>
			<Grid data={missions.slice(page.skip, page.take + page.skip)} skip={page.skip} take={page.take} total={missions.length} pageable={{ buttonCount: 5, pageSizes: [10, 25, 50, 150] }} filterable={false} resizable={true} onPageChange={pageChange}>
				<GridNoRecords>No Records Available</GridNoRecords>

				<Column field="history" filter="text" title="Mission Name" cell={MissionsColumns.MissionNameCell} />
				<Column field="history" filter="text" title="Status" cell={MissionsColumns.StatusCell} />
				<Column field="history" filter="text" title="# Legs" cell={MissionsColumns.LegsCount} />
				<Column field="latestVersion" filter="text" title="Latest Version" cell={MissionsColumns.LatestVersion} />
				<Column field="history" filter="text" title="Created On" cell={MissionsColumns.CreatedOn} />
				<Column field="history" title=" " sortable={false} filterable={false} cell={ActionsCell} />

				{/*
                <Column field="missionid" title="Mission ID" sortable={false} cell={MissionsColumns.MissionIdCell} />
                <Column field="status" title="Status" cell={MissionsColumns.StatusCell} />
                <Column field="callsign" title="Call Sign" cell={MissionsColumns.CallSignCell} />
                <Column field="flyingunit" title="Flying Unit"sortable={true} cell={MissionsColumns.FlyingUnitCell} />
                <Column field="actype" title="A/C Type" cell={MissionsColumns.AcTypeCell} />
                <Column field="depicao" title="Dep. ICAO" cell={MissionsColumns.DepIcaoCell} />
                <Column field="arricao" title="Arr. ICAO" cell={MissionsColumns.ArrIcaoCell} />
                <Column field="deptimedatez" title="Dep. Date/Time (Z)" cell={MissionsColumns.DepDateTimeCell} />
                <Column field="arrtimedatez" title="Arr. Date/Time (Z)" cell={MissionsColumns.ArrDateTimeCell} />
                <Column field="scheduledcaro" title="Scheduled Cargo" cell={MissionsColumns.ScheduledCargoCell} />
                <Column field="availablecargo" title="Available Cargo" cell={MissionsColumns.AvailableCargoCell} />
                */}

				{/*
                <Column
                    field="missionid"
                    title=" "
                    sortable={false}
                    filterable={false}
                    cell={(props) =>
                        <td className={"table-actions"}>
                            <div className={"actions"}>
                                <ul className={"actions-list"}>
                                    <li className={"action-item edit"}>
                                        <NavLink to={`/schedule/${props.dataItem[props.field]}`} title={`Click to View ${props.dataItem[props.field]}`}>
                                            <i className="fa-solid fa-pen-to-square"></i>
                                            <span className={"sr-only"}>View Mission</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    }
                />
                */}
			</Grid>
			{openForm && <EditMissionForm cancelEdit={handleCancelEdit} onSubmit={handleSubmit} item={editItem} props={props} />}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionsListGrid);
