/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { Field } from '@progress/kendo-react-form';

import { FormComboBox, FormInput } from '../KendoFormElements/FormComponents';
import { phoneValidator } from '../KendoFormElements/Validators';
import { optionsDepartmentOrAgency } from '../../../Assets/json/departmentsAndAgencies';
import { optionsGradesListNavy } from '../../../Assets/json/grades';
import { ApiOrgUnitListPaged, ApiOrgUnitListCount, ApiOrgUnitList } from '../../Helpers/Apis';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import LiftRequestInfoModal from '../Modals/LiftRequestInfoModal';
import { filterChange } from '../../Helpers/FilterData';

export const IntroDetails = (props) => {
	const [nameCount, setNameCount] = React.useState('');

	const [toggleModal, setToggleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalContent, setModalContent] = useState('');
	const [units, setUnits] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [unitCount, setUnitCount] = React.useState(0);
	const [requestingUnit, setRequestingUnit] = React.useState([]);
	const [departmentOrAgencyData, setDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [gradesData, setGradesData] = React.useState(optionsGradesListNavy);
	const [depOrAgencyloading, setDepOrAgencyloading] = React.useState(false);
	const [gradesLoading, setGradesLoading] = React.useState(false);
	const [unitsLoading, setUnitsLoading] = React.useState(false);
	const [fullUnits, setFullUnits] = React.useState(false);

	const timeout = React.useRef();

	const filterUnitsChange = (event) => {
		filterChange(event, setUnits, setUnitsLoading, fullUnits, timeout);
	};

	const filterRequestingUnitsChange = (event) => {
		filterChange(event, setRequestingUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterDepartmentOrAgencyChange = (event) => {
		filterChange(event, setDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterGradesChange = (event) => {
		filterChange(event, setGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const handleModalToggle = () => setToggleModal(!toggleModal);

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	const uicPageSize = 10;

	const nameMax = 50;

	const handleNameCount = (e) => {
		setNameCount(e.value);
	};

	//get initial page and unit count
	useEffect(() => {
		ApiOrgUnitList().then((res) => {
			setFullUnits(res);
		});
		ApiOrgUnitListPaged(0, uicPageSize).then((res) => {
			setUnits(res);
			setRequestingUnit(res);
		});
		ApiOrgUnitListCount().then((res) => {
			setUnitCount(res);
		});
	}, []);

	const pageChange = (event) => {
		const currPage = event.page.skip;
		//stop before entries in uic/page length
		if (currPage < unitCount / event.page.take) {
			ApiOrgUnitListPaged(currPage, event.page.take).then((res) => {
				setUnits(res);
				setRequestingUnit(res);
			});
			setPage(currPage);
		}
	};

	return (
		<React.Fragment>
			<Row id={'lift-request-introduction'} className={'form-section'}>
				{/* Section Title - Senior Traveler: Start */}
				<div className={'col-12 form-section-title lift-request-introduction'}>
					<h3>Introduction</h3>
				</div>
				{/* Section Title - Senior Traveler: End */}

				<div className={'col-12'}>
					<p>This submission form wizard allows for you to streamline the process for a lift request. Please review the information below before proceeding with entering your lift request information. Your lift request will be submitted with the information you see. If you need to do so, you may update your phone number, grade, duty title, and unit.</p>
				</div>
			</Row>

			<Row style={{ maxWidth: '1200px' }}>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<Field key={'requesterFirstName'} id={'requesterFirstName'} name={'requesterFirstName'} label={'Your First Name (Requester)'} placeholder={' '} component={FormInput} hint={'Read Only'} readOnly={'readonly'} />
					</div>
				</div>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<Field key={'requesterLastName'} id={'requesterLastName'} name={'requesterLastName'} label={'Your Last Name (Requester)'} placeholder={''} component={FormInput} hint={'Read Only'} readOnly={'readonly'} />
					</div>
				</div>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<Field key={'requesterEmail'} id={'requesterEmail'} name={'requesterEmail'} label={'Your Email'} placeholder={''} component={FormInput} hint={'Read Only'} readOnly={'readonly'} />
					</div>
				</div>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<Field key={'requesterPhone'} id={'requesterPhone'} name={'requester.phoneNumber'} label={'Phone Number'} placeholder={'Please include country code.'} component={FormInput} validator={phoneValidator} />
					</div>
				</div>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<Field key={'branchOfService'} id={'branchOfService'} name={'requester.branchOfService'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={departmentOrAgencyData} filterable={true} onFilterChange={filterDepartmentOrAgencyChange} />
					</div>
				</div>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<Field key={'requesterGrade'} id={'requesterGrade'} name={'requester.rank'} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={gradesData} loading={gradesLoading} filterable={true} onFilterChange={filterGradesChange} />
					</div>
				</div>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<Field key={'requesterDutyTitle'} id={'requesterDutyTitle'} name={'requester.dutyTitle'} label={'Duty Title'} placeholder={''} component={FormInput} />
					</div>
				</div>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<Field
							key={'requesterUnit'}
							id={'requesterUnit'}
							name={'requester.unit'}
							label={'Unit'}
							textField={'uicnra'}
							dataItemKey={'uic'}
							placeholder={'Unit...'}
							component={FormComboBox}
							allowCustom={true}
							data={units}
							value={units}
							virtual={{
								total: unitCount,
								pageSize: uicPageSize,
								skip: page
							}}
							onPageChange={pageChange}
							loading={unitsLoading}
							filterable={true}
							onFilterChange={filterUnitsChange}
						/>
					</div>
				</div>
			</Row>

			<Row className={'form-section'}>
				<div className={'col-12 form-section-title lift-request-introduction'}>
					<h3>Requesting Unit</h3>
				</div>

				<div className={'col-12'}>
					<p>If requesting unit is different from the requester's, then please enter requesting unit and PLADS information below.</p>
				</div>
			</Row>

			<Row style={{ maxWidth: '1200px' }}>
				<div className={'col-4'}>
					<div className={'form-group'}>
						<Field
							key={'requestingUnit'}
							id={'requestingUnit'}
							name={'requestingUnit.unit'}
							label={'Unit'}
							textField={'uicnra'}
							dataItemKey={'uic'}
							placeholder={'Unit...'}
							component={FormComboBox}
							allowCustom={true}
							data={requestingUnit}
							value={units}
							virtual={{
								total: unitCount,
								pageSize: uicPageSize,
								skip: page
							}}
							onPageChange={pageChange}
							loading={unitsLoading}
							filterable={true}
							onFilterChange={filterRequestingUnitsChange}
						/>
					</div>
				</div>

				<div className={'col-12'}>
					<div className={'form-group'}>
						<Field key={'plads'} id={'plads'} name={'requestingUnit.plads'} label={'PLADS'} placeholder={'plain language address...'} component={FormInput} />
					</div>
				</div>
			</Row>

			<Row id={'lift-request-introduction'} className={'form-section'}>
				{/* Section Title - Senior Traveler: Start */}
				<div className={'col-12 form-section-title lift-request-introduction'}>
					<h3>Name Your Form</h3>
				</div>
				{/* Section Title - Senior Traveler: End */}

				<div className={'col-12'}>
					<p>Enter a name for your form below. This field is not required.</p>
				</div>

				<div className={'col-4 has-info'}>
					<div className={'form-group'}>
						<Field key={'requesterFormName'} id={'requesterFormName'} name={'requestName'} label={'Form Name'} placeholder={'E.g. Security Package for Delivery'} component={FormInput} onChange={handleNameCount} maxLength={nameMax} value={nameCount} hint={`Name can be no longer than ${nameMax} characters. ${nameCount.length} of ${nameMax}`} />
						<Button
							variant={'secondary'}
							className={'btn-form-info'}
							onClick={() => {
								// Info: Modal Title
								handleModalTitle('Form Name Information');

								// Info: Modal Content
								handleModalContent(`
                                    <p>Specific form name for this Lift Request.</p>
                                `);

								// Show Modal
								handleModalToggle();
							}}>
							<i className={'fa-solid fa-info modal-trigger'}></i>
							<span className={'sr-only'}>Click to view information about Request Names</span>
						</Button>
					</div>
				</div>
			</Row>

			<LiftRequestInfoModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalContent={modalContent} />
		</React.Fragment>
	);
};
