/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

// Form Initial Values
export const InitialValues = (formState, liftDetails) => {
	const initValues = {
		...formState,

		/**
		 * State
		 * ************************************************************************************ */

		// State
		state: liftDetails.history && liftDetails.history[0] && liftDetails.history[0].state !== null && liftDetails.history[0].state !== undefined ? liftDetails.history[0].state : '',

		/**
		 * Requester
		 * ************************************************************************************ */

		// Requester: First Name
		requesterFirstName: liftDetails.history && liftDetails.history[0].requester && liftDetails.history[0].requester.firstName !== null && liftDetails.history[0].requester.firstName !== undefined ? liftDetails.history[0].requester.firstName : '',

		// Requester: Last Name
		requesterLastName: liftDetails.history && liftDetails.history[0].requester && liftDetails.history[0].requester.lastName !== null && liftDetails.history[0].requester.lastName !== undefined ? liftDetails.history[0].requester.lastName : '',

		// Requester: Email
		requesterEmail: liftDetails.history && liftDetails.history[0].requester && liftDetails.history[0].requester.email !== null && liftDetails.history[0].requester.email !== undefined ? liftDetails.history[0].requester.email : '',

		// Requester: Phone
		requesterPhone: liftDetails.history && liftDetails.history[0].requester && liftDetails.history[0].requester.phoneNumber !== null && liftDetails.history[0].requester.phoneNumber !== undefined ? liftDetails.history[0].requester.phoneNumber : '',

		// Requester: Rank
		requesterBranchOfService: liftDetails.history && liftDetails.history[0].requester && liftDetails.history[0].requester.branchOfService !== null && liftDetails.history[0].requester.branchOfService !== undefined ? liftDetails.history[0].requester.branchOfService : '',

		// Requester: Rank
		requesterGrade:
			liftDetails.history && liftDetails.history[0].requester && liftDetails.history[0].requester.rank !== null
				? {
						grade: liftDetails.history[0].requester.rank.grade,
						insignia: liftDetails.history[0].requester.rank.insignia,
						title: liftDetails.history[0].requester.rank.title,
						abbr: liftDetails.history[0].requester.rank.abbreviation,
						class: liftDetails.history[0].requester.rank.rank,
						gradetitle: liftDetails.history[0].requester.rank.gradeTitle
				  }
				: '',

		// Requester: Duty Title
		requesterDutyTitle: liftDetails.history && liftDetails.history[0].requester && liftDetails.history[0].requester.dutyTitle !== null && liftDetails.history[0].requester.dutyTitle !== undefined ? liftDetails.history[0].requester.dutyTitle : '',

		// Requester: Unit
		requesterUnit:
			liftDetails.history && liftDetails.history[0].requester && liftDetails.history[0].requester.unit !== null && liftDetails.history[0].requester.unit !== undefined
				? {
						id: liftDetails.history[0].requester.unit.id,
						nra: liftDetails.history[0].requester.unit.nra,
						uic: liftDetails.history[0].requester.unit.uic,
						uicnra: liftDetails.history[0].requester.unit.uic !== null && liftDetails.history[0].requester.unit.nra !== null ? liftDetails.history[0].requester.unit.uic + ': ' + liftDetails.history[0].requester.unit.nra : null
				  }
				: '',

		requestingUnit:
			liftDetails.history && liftDetails.history[0].requestingUnit && liftDetails.history[0].requestingUnit.unit !== null && liftDetails.history[0].requestingUnit.unit !== undefined
				? {
						id: liftDetails.history[0].requestingUnit.unit.id,
						nra: liftDetails.history[0].requestingUnit.unit.nra,
						uic: liftDetails.history[0].requestingUnit.unit.uic,
						uicnra: liftDetails.history[0].requestingUnit.unit.uic !== null && liftDetails.history[0].requestingUnit.unit.nra !== null ? liftDetails.history[0].requestingUnit.unit.uic + ': ' + liftDetails.history[0].requestingUnit.unit.nra : null
				  }
				: '',

		requestingUnitPlads: liftDetails.history && liftDetails.history[0].requestingUnit && liftDetails.history[0].requestingUnit.plads !== null && liftDetails.history[0].requestingUnit.plads !== undefined ? liftDetails.history[0].requestingUnit.plads : '',

		/**
		 * Priority and Urgency
		 * ************************************************************************************ */

		// Priority
		priority:
			liftDetails.history && liftDetails.history[0].priority !== null && liftDetails.history[0].priority !== undefined
				? {
						label: liftDetails.history[0].priority2.label,
						value: liftDetails.history[0].priority2.value,
						state: liftDetails.history[0].priority2.label,
						notes: liftDetails.history[0].priority2.notes
				  }
				: '',

		// Urgency
		urgency:
			liftDetails.history && liftDetails.history[0].urgency !== null && liftDetails.history[0].urgency !== undefined
				? {
						label: liftDetails.history[0].urgency2.label,
						value: liftDetails.history[0].urgency2.value,
						state: liftDetails.history[0].urgency2.label,
						notes: liftDetails.history[0].urgency2.notes
				  }
				: '',

		/**
		 * Departure: POC Primary
		 * ************************************************************************************ */

		// Departure: POC Primary - First Name
		pocDeparturePassengerFNamePri: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.primary && liftDetails.history[0].departure.poc.primary.firstName !== null && liftDetails.history[0].departure.poc.primary.firstName !== undefined ? liftDetails.history[0].departure.poc.primary.firstName : '',

		// Departure: POC Primary - Last Name
		pocDeparturePassengerLNamePri: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.primary && liftDetails.history[0].departure.poc.primary.lastName !== null && liftDetails.history[0].departure.poc.primary.lastName !== undefined ? liftDetails.history[0].departure.poc.primary.lastName : '',

		// Departure: POC Primary - Rank
		pocDeparturePassengerGradePri:
			liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.primary && liftDetails.history[0].departure.poc.primary.rank !== null && liftDetails.history[0].departure.poc.primary.rank !== undefined
				? {
						grade: liftDetails.history[0].departure.poc.primary.rank.grade,
						insignia: liftDetails.history[0].departure.poc.primary.rank.insignia,
						title: liftDetails.history[0].departure.poc.primary.rank.title,
						abbr: liftDetails.history[0].departure.poc.primary.rank.abbreviation,
						class: liftDetails.history[0].departure.poc.primary.rank.rank,
						gradetitle: liftDetails.history[0].departure.poc.primary.rank.gradeTitle
				  }
				: '',

		// Departure: POC Primary - Duty Title
		pocDeparturePassengerDutyTitlePri: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.primary && liftDetails.history[0].departure.poc.primary.dutyTitle !== null && liftDetails.history[0].departure.poc.primary.dutyTitle !== undefined ? liftDetails.history[0].departure.poc.primary.dutyTitle : '',

		// Departure: POC Primary - Branch of Service
		pocDepartureBranchOfServicePri: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.primary && liftDetails.history[0].departure.poc.primary.branchOfService !== null && liftDetails.history[0].departure.poc.primary.branchOfService !== undefined ? liftDetails.history[0].departure.poc.primary.branchOfService : '',

		// Departure: POC Primary - Unit
		pocDeparturePassengerUnitPri:
			liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.primary && liftDetails.history[0].departure.poc.primary.unit !== null && liftDetails.history[0].departure.poc.primary.unit !== undefined
				? {
						id: liftDetails.history[0].departure.poc.primary.unit.id,
						nra: liftDetails.history[0].departure.poc.primary.unit.nra,
						uic: liftDetails.history[0].departure.poc.primary.unit.uic,
						uicnra: liftDetails.history[0].departure.poc.primary.unit.uic !== null && liftDetails.history[0].departure.poc.primary.unit.nra !== null ? liftDetails.history[0].departure.poc.primary.unit.uic + ': ' + liftDetails.history[0].departure.poc.primary.unit.nra : null
				  }
				: '',

		// Departure: POC Primary - Email
		pocDeparturePassengerEmailPri: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.primary && liftDetails.history[0].departure.poc.primary.email !== null && liftDetails.history[0].departure.poc.primary.email !== undefined ? liftDetails.history[0].departure.poc.primary.email : '',

		// Departure: POC Primary - Phone
		pocDeparturePassengerMobilePri: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.primary && liftDetails.history[0].departure.poc.primary.phoneNumber !== null && liftDetails.history[0].departure.poc.primary.phoneNumber !== undefined ? liftDetails.history[0].departure.poc.primary.phoneNumber : '',

		/**
		 * Departure: POC Alternate
		 * ************************************************************************************ */

		// Departure: POC Alternate - First Name
		pocDeparturePassengerFNameAlt: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.alternate && liftDetails.history[0].departure.poc.alternate.firstName !== null && liftDetails.history[0].departure.poc.alternate.firstName !== undefined ? liftDetails.history[0].departure.poc.alternate.firstName : '',

		// Departure: POC Alternate - Last Name
		pocDeparturePassengerLNameAlt: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.alternate && liftDetails.history[0].departure.poc.alternate.lastName !== null && liftDetails.history[0].departure.poc.alternate.lastName !== undefined ? liftDetails.history[0].departure.poc.alternate.lastName : '',

		// Departure: POC Alternate - Rank
		pocDeparturePassengerGradeAlt:
			liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.alternate && liftDetails.history[0].departure.poc.alternate.rank !== null && liftDetails.history[0].departure.poc.alternate.rank !== undefined
				? {
						grade: liftDetails.history[0].departure.poc.alternate.rank.grade,
						insignia: liftDetails.history[0].departure.poc.alternate.rank.insignia,
						title: liftDetails.history[0].departure.poc.alternate.rank.title,
						abbr: liftDetails.history[0].departure.poc.alternate.rank.abbreviation,
						class: liftDetails.history[0].departure.poc.alternate.rank.rank,
						gradetitle: liftDetails.history[0].departure.poc.alternate.rank.gradeTitle
				  }
				: '',

		// Departure: POC Alternate - Duty Title
		pocDeparturePassengerDutyTitleAlt: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.alternate && liftDetails.history[0].departure.poc.alternate.dutyTitle !== null && liftDetails.history[0].departure.poc.alternate.dutyTitle !== undefined ? liftDetails.history[0].departure.poc.alternate.dutyTitle : '',

		// Departure: POC Alternate - Branch of Service
		pocDepartureBranchOfServiceAlt: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.alternate && liftDetails.history[0].departure.poc.alternate.branchOfService !== null && liftDetails.history[0].departure.poc.alternate.branchOfService !== undefined ? liftDetails.history[0].departure.poc.alternate.branchOfService : '',

		// Departure: POC Alternate - Unit
		pocDeparturePassengerUnitAlt:
			liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.alternate && liftDetails.history[0].departure.poc.alternate.unit !== null && liftDetails.history[0].departure.poc.alternate.unit !== undefined
				? {
						id: liftDetails.history[0].departure.poc.alternate.unit.id,
						nra: liftDetails.history[0].departure.poc.alternate.unit.nra,
						uic: liftDetails.history[0].departure.poc.alternate.unit.uic,
						uicnra: liftDetails.history[0].departure.poc.alternate.unit.uic !== null && liftDetails.history[0].departure.poc.alternate.unit.nra !== null ? liftDetails.history[0].departure.poc.alternate.unit.uic + ': ' + liftDetails.history[0].departure.poc.alternate.unit.nra : null
				  }
				: '',

		// Departure: POC Alternate - Phone
		pocDeparturePassengerMobileAlt: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.alternate && liftDetails.history[0].departure.poc.alternate.phoneNumber !== null && liftDetails.history[0].departure.poc.alternate.phoneNumber !== undefined ? liftDetails.history[0].departure.poc.alternate.phoneNumber : '',

		// Departure: POC Alternate - Email
		pocDeparturePassengerEmailAlt: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.poc && liftDetails.history[0].departure.poc.alternate && liftDetails.history[0].departure.poc.alternate.email !== null && liftDetails.history[0].departure.poc.alternate.email !== undefined ? liftDetails.history[0].departure.poc.alternate.email : '',

		/**
		 * Arrival: POC Primary
		 * ************************************************************************************ */

		// Arrival: POC Primary - First Name
		pocArrivalPassengerFNamePri: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.primary && liftDetails.history[0].arrival.poc.primary.firstName !== null && liftDetails.history[0].arrival.poc.primary.firstName !== undefined ? liftDetails.history[0].arrival.poc.primary.firstName : '',

		// Arrival: POC Primary - Last Name
		pocArrivalPassengerLNamePri: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.primary && liftDetails.history[0].arrival.poc.primary.lastName !== null && liftDetails.history[0].arrival.poc.primary.lastName !== undefined ? liftDetails.history[0].arrival.poc.primary.lastName : '',

		// Arrival: POC Primary - Rank
		pocArrivalPassengerGradePri:
			liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.primary && liftDetails.history[0].arrival.poc.primary.rank !== null && liftDetails.history[0].arrival.poc.primary.rank !== undefined
				? {
						grade: liftDetails.history[0].arrival.poc.primary.rank.grade,
						insignia: liftDetails.history[0].arrival.poc.primary.rank.insignia,
						title: liftDetails.history[0].arrival.poc.primary.rank.title,
						abbr: liftDetails.history[0].arrival.poc.primary.rank.abbreviation,
						class: liftDetails.history[0].arrival.poc.primary.rank.rank,
						gradetitle: liftDetails.history[0].arrival.poc.primary.rank.gradeTitle
				  }
				: '',

		// Arrival: POC Primary - XXX
		pocArrivalPassengerDutyTitlePri: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.primary && liftDetails.history[0].arrival.poc.primary.dutyTitle !== null && liftDetails.history[0].arrival.poc.primary.dutyTitle !== undefined ? liftDetails.history[0].arrival.poc.primary.dutyTitle : '',

		// Arrival: POC Primary - Branch of Service
		pocArrivalBranchOfServicePri: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.primary && liftDetails.history[0].arrival.poc.primary.branchOfService !== null && liftDetails.history[0].arrival.poc.primary.branchOfService !== undefined ? liftDetails.history[0].arrival.poc.primary.branchOfService : '',

		// Arrival: POC Primary - Unit
		pocArrivalPassengerUnitPri:
			liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.primary && liftDetails.history[0].arrival.poc.primary.unit !== null && liftDetails.history[0].arrival.poc.primary.unit !== undefined
				? {
						id: liftDetails.history[0].arrival.poc.primary.unit.id,
						nra: liftDetails.history[0].arrival.poc.primary.unit.nra,
						uic: liftDetails.history[0].arrival.poc.primary.unit.uic,
						uicnra: liftDetails.history[0].arrival.poc.primary.unit.uic !== null && liftDetails.history[0].arrival.poc.primary.unit.nra !== null ? liftDetails.history[0].arrival.poc.primary.unit.uic + ': ' + liftDetails.history[0].arrival.poc.primary.unit.nra : null
				  }
				: '',

		// Arrival: POC Primary - Phone
		pocArrivalPassengerMobilePri: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.primary && liftDetails.history[0].arrival.poc.primary.phoneNumber !== null && liftDetails.history[0].arrival.poc.primary.phoneNumber !== undefined ? liftDetails.history[0].arrival.poc.primary.phoneNumber : '',

		// Arrival: POC Primary - Email
		pocArrivalPassengerEmailPri: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.primary && liftDetails.history[0].arrival.poc.primary.email !== null && liftDetails.history[0].arrival.poc.primary.email !== undefined ? liftDetails.history[0].arrival.poc.primary.email : '',

		/**
		 * Arrival: POC Alternate
		 * ************************************************************************************ */

		// Arrival: POC Alternate - First Name
		pocArrivalPassengerFNameAlt: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.alternate && liftDetails.history[0].arrival.poc.alternate.firstName !== null && liftDetails.history[0].arrival.poc.alternate.firstName !== undefined ? liftDetails.history[0].arrival.poc.alternate.firstName : '',

		// Arrival: POC Alternate - Last Name
		pocArrivalPassengerLNameAlt: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.alternate && liftDetails.history[0].arrival.poc.alternate.lastName !== null && liftDetails.history[0].arrival.poc.alternate.lastName !== undefined ? liftDetails.history[0].arrival.poc.alternate.lastName : '',

		// Arrival: POC Alternate - Rank
		pocArrivalPassengerGradeAlt:
			liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.alternate && liftDetails.history[0].arrival.poc.alternate.rank !== null && liftDetails.history[0].arrival.poc.alternate.rank !== undefined
				? {
						grade: liftDetails.history[0].arrival.poc.alternate.rank.grade,
						insignia: liftDetails.history[0].arrival.poc.alternate.rank.insignia,
						title: liftDetails.history[0].arrival.poc.alternate.rank.title,
						abbr: liftDetails.history[0].arrival.poc.alternate.rank.abbreviation,
						class: liftDetails.history[0].arrival.poc.alternate.rank.rank,
						gradetitle: liftDetails.history[0].arrival.poc.alternate.rank.gradeTitle
				  }
				: '',

		// Arrival: POC Alternate - Duty Title
		pocArrivalPassengerDutyTitleAlt: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.alternate && liftDetails.history[0].arrival.poc.alternate.dutyTitle !== null && liftDetails.history[0].arrival.poc.alternate.dutyTitle !== undefined ? liftDetails.history[0].arrival.poc.alternate.dutyTitle : '',

		// Arrival: POC Alternate - Branch of Service
		pocArrivalBranchOfServiceAlt: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.alternate && liftDetails.history[0].arrival.poc.alternate.branchOfService !== null && liftDetails.history[0].arrival.poc.alternate.branchOfService !== undefined ? liftDetails.history[0].arrival.poc.alternate.branchOfService : '',

		// Arrival: POC Alternate - Unit
		pocArrivalPassengerUnitAlt:
			liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.alternate && liftDetails.history[0].arrival.poc.alternate.unit !== null && liftDetails.history[0].arrival.poc.alternate.unit !== undefined
				? {
						id: liftDetails.history[0].arrival.poc.alternate.unit.id,
						nra: liftDetails.history[0].arrival.poc.alternate.unit.nra,
						uic: liftDetails.history[0].arrival.poc.alternate.unit.uic,
						uicnra: liftDetails.history[0].arrival.poc.alternate.unit.uic !== null && liftDetails.history[0].arrival.poc.alternate.unit.nra !== null ? liftDetails.history[0].arrival.poc.alternate.unit.uic + ': ' + liftDetails.history[0].arrival.poc.alternate.unit.nra : null
				  }
				: '',

		// Arrival: POC Alternate - Phone
		pocArrivalPassengerMobileAlt: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.alternate && liftDetails.history[0].arrival.poc.alternate.phoneNumber !== null && liftDetails.history[0].arrival.poc.alternate.phoneNumber !== undefined ? liftDetails.history[0].arrival.poc.alternate.phoneNumber : '',

		// Arrival: POC Alternate - Email
		pocArrivalPassengerEmailAlt: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.poc && liftDetails.history[0].arrival.poc.alternate && liftDetails.history[0].arrival.poc.alternate.email !== null && liftDetails.history[0].arrival.poc.alternate.email !== undefined ? liftDetails.history[0].arrival.poc.alternate.email : '',

		/**
		 * Purpose of Travel
		 * ************************************************************************************ */

		// Mission Description
		completeMissionDescription: liftDetails.history && liftDetails.history[0].missionDescription !== null && liftDetails.history[0].missionDescription !== undefined ? liftDetails.history[0].missionDescription : '',

		// Mission Justification
		justification: liftDetails.history && liftDetails.history[0].justification !== null && liftDetails.history[0].justification !== undefined ? liftDetails.history[0].justification : '',

		/**
		 * Itinerary Information: Departure
		 * ************************************************************************************ */

		// Departure: ICAO
		desiredDepartureIcao:
			liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.airport !== null && liftDetails.history[0].departure.airport !== undefined
				? {
						history: [
							{
								country: {
									code: liftDetails.history[0].departure.airport.country !== null && liftDetails.history[0].departure.airport.country !== undefined && liftDetails.history[0].departure.airport.country.code !== null ? liftDetails.history[0].departure.airport.country.code : null,
									name: liftDetails.history[0].departure.airport.country !== null && liftDetails.history[0].departure.airport.country !== undefined && liftDetails.history[0].departure.airport.country.name !== null ? liftDetails.history[0].departure.airport.country.name : null
								},
								iata: liftDetails.history[0].departure.airport.iata,
								icao: liftDetails.history[0].departure.airport.icao,
								id: liftDetails.history[0].departure.airport.id,
								latitute: liftDetails.history[0].departure.airport.latitude,
								longitude: liftDetails.history[0].departure.airport.longitude,
								name: liftDetails.history[0].departure.airport.name,
								status: liftDetails.history[0].departure.airport.status,
								icaoName: liftDetails.history[0].departure.airport.icao + ': ' + liftDetails.history[0].departure.airport.name,
								openTime: liftDetails.history[0].departure.airport.openTime,
								closeTime: liftDetails.history[0].departure.airport.closeTime,
								aircraftTravelTime: liftDetails.history[0].departure.airport.aircraftTravelTime,
								availability: liftDetails.history[0].departure.airport.availability,
								groundSupportEquipment: liftDetails.history[0].departure.airport.groundSupportEquipment,
								dafifIdentifier: liftDetails.history[0].departure.airport.dafifIdentifier,
								elevation: liftDetails.history[0].departure.airport.elevation,
								timeZoneIdentifier: liftDetails.history[0].departure.airport.timeZoneIdentifier,
								type: liftDetails.history[0].departure.airport.type,
								comments: liftDetails.history[0].departure.airport.comments,
								allowedAircraft: liftDetails.history[0].departure.airport.allowedAircraft,
								createdAt: liftDetails.history[0].departure.airport.createdAt,
								createdBy: liftDetails.history[0].departure.airport.createdBy,
								cycleDate: liftDetails.history[0].departure.airport.cycleDate
							}
						]
				  }
				: null,

		// Departure: Earliest Date/Time
		earliestDepartureDateTime: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.earliest !== null && liftDetails.history[0].departure.earliest !== undefined ? new Date(liftDetails.history[0].departure.earliest) : null,

		// Departure: Latest Date/Time
		latestDepartureDateTime: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.latest !== null && liftDetails.history[0].departure.latest !== undefined ? new Date(liftDetails.history[0].departure.latest) : null,

		// Departure: Alternate Airports
		alternateDeparture: liftDetails.history && liftDetails.history[0].departure && liftDetails.history[0].departure.alternateAirports !== null && liftDetails.history[0].departure.alternateAirports !== undefined ? liftDetails.history[0].departure.alternateAirports.map((altAirportDepObj) => ({ altAirportDep: altAirportDepObj })) : [''],

		/**
		 * Itinerary Information: Arrival
		 * ************************************************************************************ */

		// Arrival: ICAO
		desiredArrivalIcao:
			liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.airport !== null && liftDetails.history[0].arrival.airport !== undefined
				? {
						history: [
							{
								country: {
									code: liftDetails.history[0].arrival.airport.country !== null && liftDetails.history[0].arrival.airport.country !== undefined && liftDetails.history[0].arrival.airport.country.code !== null && liftDetails.history[0].arrival.airport.country.code !== undefined ? liftDetails.history[0].arrival.airport.country.code : null,
									name: liftDetails.history[0].arrival.airport.country !== null && liftDetails.history[0].arrival.airport.country !== undefined && liftDetails.history[0].arrival.airport.country.name !== null && liftDetails.history[0].arrival.airport.country.name !== undefined ? liftDetails.history[0].arrival.airport.country.name : null
								},
								iata: liftDetails.history[0].arrival.airport.iata,
								icao: liftDetails.history[0].arrival.airport.icao,
								id: liftDetails.history[0].arrival.airport.id,
								latitute: liftDetails.history[0].arrival.airport.latitude,
								longitude: liftDetails.history[0].arrival.airport.longitude,
								name: liftDetails.history[0].arrival.airport.name,
								status: liftDetails.history[0].arrival.airport.status,
								icaoName: liftDetails.history[0].arrival.airport.icao + ': ' + liftDetails.history[0].arrival.airport.name,
								openTime: liftDetails.history[0].arrival.airport.openTime,
								closeTime: liftDetails.history[0].arrival.airport.closeTime,
								aircraftTravelTime: liftDetails.history[0].arrival.airport.aircraftTravelTime,
								availability: liftDetails.history[0].arrival.airport.availability,
								groundSupportEquipment: liftDetails.history[0].arrival.airport.groundSupportEquipment,
								dafifIdentifier: liftDetails.history[0].arrival.airport.dafifIdentifier,
								elevation: liftDetails.history[0].arrival.airport.elevation,
								timeZoneIdentifier: liftDetails.history[0].arrival.airport.timeZoneIdentifier,
								type: liftDetails.history[0].arrival.airport.type,
								comments: liftDetails.history[0].arrival.airport.comments,
								allowedAircraft: liftDetails.history[0].arrival.airport.allowedAircraft,
								createdAt: liftDetails.history[0].arrival.airport.createdAt,
								createdBy: liftDetails.history[0].arrival.airport.createdBy,
								cycleDate: liftDetails.history[0].arrival.airport.cycleDate
							}
						]
				  }
				: null,

		// Arrival: Earliest Date/Time
		earliestArrivalDateTime: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.earliest !== null && liftDetails.history[0].arrival.earliest !== undefined ? new Date(liftDetails.history[0].arrival.earliest) : null,

		// Arrival: Latest Date/Time
		latestArrivalDateTime: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.latest !== null && liftDetails.history[0].arrival.latest !== undefined ? new Date(liftDetails.history[0].arrival.latest) : null,

		// Arrival: Alternate Airports
		alternateArrival: liftDetails.history && liftDetails.history[0].arrival && liftDetails.history[0].arrival.alternateAirports !== null && liftDetails.history[0].arrival.alternateAirports !== undefined ? liftDetails.history[0].arrival.alternateAirports.map((altAirportArrObj) => ({ altAirportArr: altAirportArrObj })) : [''],

		/**
		 * Passengers or Cargo Selection
		 * ************************************************************************************ */

		// Has Passengers Toggle
		hasPassengers: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' ? true : false,

		// Has Pallets Toggle
		hasPallets: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? true : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? true : false,

		/**
		 * Cargo: Passengers
		 * ************************************************************************************ */

		// PAX Count
		totalNumberOfPax: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' && liftDetails.history[0].cargoes[0].count !== null && liftDetails.history[0].cargoes[0].count !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' ? liftDetails.history[0].cargoes[0].count : '',

		// Determine Commercial Cost
		commercialCost: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' && liftDetails.history[0].commercialCost !== null && liftDetails.history[0].commercialCost !== undefined ? liftDetails.history[0].commercialCost : '',

		// Senior Traveler: First Name
		srTravelerFName: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' && liftDetails.history[0].cargoes[0].seniorTravelers && liftDetails.history[0].cargoes[0].seniorTravelers[0] && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== undefined && liftDetails.history[0].cargoes[0].seniorTravelers[0].firstName !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0].firstName !== undefined ? liftDetails.history[0].cargoes[0].seniorTravelers[0].firstName : '',

		// Senior Traveler: Last Name
		srTravelerLName: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' && liftDetails.history[0].cargoes[0].seniorTravelers && liftDetails.history[0].cargoes[0].seniorTravelers[0] && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== undefined && liftDetails.history[0].cargoes[0].seniorTravelers[0].lastName !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0].lastName !== undefined ? liftDetails.history[0].cargoes[0].seniorTravelers[0].lastName : '',

		// Senior Traveler: Email Address
		srTravelerEmail: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' && liftDetails.history[0].cargoes[0].seniorTravelers && liftDetails.history[0].cargoes[0].seniorTravelers[0] && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== undefined && liftDetails.history[0].cargoes[0].seniorTravelers[0].email !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0].email !== undefined ? liftDetails.history[0].cargoes[0].seniorTravelers[0].email : '',

		// Senior Traveler: Mobile Phone
		srTravelerMobile: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' && liftDetails.history[0].cargoes[0].seniorTravelers && liftDetails.history[0].cargoes[0].seniorTravelers[0] && liftDetails.history[0].cargoes[0].seniorTravelers[0].phoneNumber !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0].phoneNumber !== undefined ? liftDetails.history[0].cargoes[0].seniorTravelers[0].phoneNumber : '',

		// Senior Traveler: Branch Of Service/Department or Agency
		srTravelerBranchOfService: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' && liftDetails.history[0].cargoes[0].seniorTravelers && liftDetails.history[0].cargoes[0].seniorTravelers[0] && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== undefined && liftDetails.history[0].cargoes[0].seniorTravelers[0].branchOfService !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0].branchOfService !== undefined ? liftDetails.history[0].cargoes[0].seniorTravelers[0].branchOfService : '',

		// Senior Traveler: Grade/Rank
		srTravelerGrade:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' && liftDetails.history[0].cargoes[0].seniorTravelers && liftDetails.history[0].cargoes[0].seniorTravelers[0] && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== undefined && liftDetails.history[0].cargoes[0].seniorTravelers[0].rank !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0].rank !== undefined
				? {
						grade: liftDetails.history[0].cargoes[0].seniorTravelers[0].rank.grade,
						insignia: liftDetails.history[0].cargoes[0].seniorTravelers[0].rank.insignia,
						title: liftDetails.history[0].cargoes[0].seniorTravelers[0].rank.title,
						abbr: liftDetails.history[0].cargoes[0].seniorTravelers[0].rank.abbreviation,
						class: liftDetails.history[0].cargoes[0].seniorTravelers[0].rank.rank,
						gradetitle: liftDetails.history[0].cargoes[0].seniorTravelers[0].rank.gradeTitle
				  }
				: '',

		// Senior Traveler: Duty Title
		srTravelerDutyTitle: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' && liftDetails.history[0].cargoes[0].seniorTravelers && liftDetails.history[0].cargoes[0].seniorTravelers[0] && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== undefined && liftDetails.history[0].cargoes[0].seniorTravelers[0].dutyTitle !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0].dutyTitle !== undefined ? liftDetails.history[0].cargoes[0].seniorTravelers[0].dutyTitle : '',

		// Senior Traveler: Unit/UIC
		srTravelerUnit:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].type === 'PASSENGERS' && liftDetails.history[0].cargoes[0].seniorTravelers && liftDetails.history[0].cargoes[0].seniorTravelers[0] && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0] !== undefined && liftDetails.history[0].cargoes[0].seniorTravelers[0].unit !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0].unit !== undefined
				? {
						id: liftDetails.history[0].cargoes[0].seniorTravelers[0].unit.id,
						nra: liftDetails.history[0].cargoes[0].seniorTravelers[0].unit.nra,
						uic: liftDetails.history[0].cargoes[0].seniorTravelers[0].unit.uic,
						uicnra: liftDetails.history[0].cargoes[0].seniorTravelers[0].unit.uic !== null && liftDetails.history[0].cargoes[0].seniorTravelers[0].unit.nra !== null ? liftDetails.history[0].cargoes[0].seniorTravelers[0].unit.uic + ': ' + liftDetails.history[0].cargoes[0].seniorTravelers[0].unit.nra : null
				  }
				: '',

		// Travel Authorizing Official: First Name
		trAuthOffFName: liftDetails.history && liftDetails.history[0] && liftDetails.history[0] !== null && liftDetails.history[0] !== undefined && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer !== null && liftDetails.history[0].commandingOfficer !== undefined && liftDetails.history[0].commandingOfficer.firstName !== null && liftDetails.history[0].commandingOfficer.firstName !== undefined ? liftDetails.history[0].commandingOfficer.firstName : '',

		// Travel Authorizing Official: Last Name
		trAuthOffLName: liftDetails.history && liftDetails.history[0] && liftDetails.history[0] !== null && liftDetails.history[0] !== undefined && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer !== null && liftDetails.history[0].commandingOfficer !== undefined && liftDetails.history[0].commandingOfficer.lastName !== null && liftDetails.history[0].commandingOfficer.lastName !== undefined ? liftDetails.history[0].commandingOfficer.lastName : '',

		// Travel Authorizing Official: Commanding Officer Email
		trAuthOffEmail: liftDetails.history && liftDetails.history[0].commandingOfficer !== null && liftDetails.history[0].commandingOfficer.email !== null && liftDetails.history[0].commandingOfficer.email !== undefined ? liftDetails.history[0].commandingOfficer.email : '',

		// Travel Authorizing Official: Mobile Phone
		trAuthOffMobile: liftDetails.history && liftDetails.history[0] && liftDetails.history[0] !== null && liftDetails.history[0] !== undefined && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer.phoneNumber !== null && liftDetails.history[0].commandingOfficer.phoneNumber !== undefined ? liftDetails.history[0].commandingOfficer.phoneNumber : '',

		// Travel Authorizing Official: Branch Of Service/Department or Agency
		trAuthOffBranchOfService: liftDetails.history && liftDetails.history[0] && liftDetails.history[0] !== null && liftDetails.history[0] !== undefined && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer !== null && liftDetails.history[0].commandingOfficer !== undefined && liftDetails.history[0].commandingOfficer.branchOfService !== null && liftDetails.history[0].commandingOfficer.branchOfService !== undefined ? liftDetails.history[0].commandingOfficer.branchOfService : '',

		// Travel Authorizing Official: Grade/Rank
		trAuthOffGrade:
			liftDetails.history && liftDetails.history[0] && liftDetails.history[0] !== null && liftDetails.history[0] !== undefined && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer !== null && liftDetails.history[0].commandingOfficer !== undefined && liftDetails.history[0].commandingOfficer.rank !== null && liftDetails.history[0].commandingOfficer.rank !== undefined
				? {
						grade: liftDetails.history[0].commandingOfficer.rank.grade,
						insignia: liftDetails.history[0].commandingOfficer.rank.insignia,
						title: liftDetails.history[0].commandingOfficer.rank.title,
						abbr: liftDetails.history[0].commandingOfficer.rank.abbreviation,
						class: liftDetails.history[0].commandingOfficer.rank.rank,
						gradetitle: liftDetails.history[0].commandingOfficer.rank.gradeTitle
				  }
				: '',

		// Travel Authorizing Official: Duty Title
		trAuthOffDutyTitle: liftDetails.history && liftDetails.history[0] && liftDetails.history[0] !== null && liftDetails.history[0] !== undefined && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer !== null && liftDetails.history[0].commandingOfficer !== undefined && liftDetails.history[0].commandingOfficer.dutyTitle !== null && liftDetails.history[0].commandingOfficer.dutyTitle !== undefined ? liftDetails.history[0].commandingOfficer.dutyTitle : '',

		// Travel Authorizing Official: Unit/UIC
		trAuthOffUnit:
			liftDetails.history && liftDetails.history[0] && liftDetails.history[0] !== null && liftDetails.history[0] !== undefined && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer && liftDetails.history[0].commandingOfficer !== null && liftDetails.history[0].commandingOfficer !== undefined && liftDetails.history[0].commandingOfficer.unit !== null && liftDetails.history[0].commandingOfficer.unit !== undefined
				? {
						id: liftDetails.history[0].commandingOfficer.unit.id,
						nra: liftDetails.history[0].commandingOfficer.unit.nra,
						uic: liftDetails.history[0].commandingOfficer.unit.uic,
						uicnra: liftDetails.history[0].commandingOfficer.unit.uic !== null && liftDetails.history[0].commandingOfficer.unit.nra !== null ? liftDetails.history[0].commandingOfficer.unit.uic + ': ' + liftDetails.history[0].commandingOfficer.unit.nra : null
				  }
				: '',

		/**
		 * Cargo: Pallets
		 * ************************************************************************************ */

		cargoArray: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined ? liftDetails.history[0].cargoes : [],
		// Cargo Pallet Positions: Pallet Positions Count
		cargoNumberOfPalletPositions: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].count !== null && liftDetails.history[0].cargoes[0].count !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].count : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].count !== null && liftDetails.history[0].cargoes[1].count !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].count : '',

		// Cargo Transportation: Largest Item Dimensions - Length
		cargoLargestItemDimensionsLength:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].largestItem !== null && liftDetails.history[0].cargoes[0].largestItem !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].largestItem.length : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].largestItem !== null && liftDetails.history[0].cargoes[1].largestItem !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].largestItem.length : '',

		// Cargo Transportation: Largest Item Dimensions - Width
		cargoLargestItemDimensionsWidth:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].largestItem !== null && liftDetails.history[0].cargoes[0].largestItem !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].largestItem.width : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].largestItem !== null && liftDetails.history[0].cargoes[1].largestItem !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].largestItem.width : '',

		// Cargo Transportation: Largest Item Dimensions - Height
		cargoLargestItemDimensionsHeight:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].largestItem !== null && liftDetails.history[0].cargoes[0].largestItem !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].largestItem.height : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].largestItem !== null && liftDetails.history[0].cargoes[1].largestItem !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].largestItem.height : '',

		// Cargo Transportation: Largest Item Dimensions - Weight
		cargoLargestItemDimensionsWeight:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].largestItem !== null && liftDetails.history[0].cargoes[0].largestItem !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].largestItem.weight : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].largestItem !== null && liftDetails.history[0].cargoes[1].largestItem !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].largestItem.weight : '',

		// Cargo Transportation: Heaviest Item Dimensions - Length
		cargoHeaviestItemLength:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].heaviestItem !== null && liftDetails.history[0].cargoes[0].heaviestItem !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].heaviestItem.length : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].heaviestItem !== null && liftDetails.history[0].cargoes[1].heaviestItem !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].heaviestItem.length : '',

		// Cargo Transportation: Heaviest Item Dimensions - Width
		cargoHeaviestItemWidth:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].heaviestItem !== null && liftDetails.history[0].cargoes[0].heaviestItem !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].heaviestItem.width : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].heaviestItem !== null && liftDetails.history[0].cargoes[1].heaviestItem !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].heaviestItem.width : '',

		// Cargo Transportation: Heaviest Item Dimensions - Height
		cargoHeaviestItemHeight:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].heaviestItem !== null && liftDetails.history[0].cargoes[0].heaviestItem !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].heaviestItem.height : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].heaviestItem !== null && liftDetails.history[0].cargoes[1].heaviestItem !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].heaviestItem.height : '',

		// Cargo Transportation: Heaviest Item Dimensions - Weight
		cargoHeaviestItemWeight:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].heaviestItem !== null && liftDetails.history[0].cargoes[0].heaviestItem !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].heaviestItem.weight : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].heaviestItem !== null && liftDetails.history[0].cargoes[1].heaviestItem !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].heaviestItem.weight : '',

		// Cargo Transportation: Total Weight
		cargoTotalWeight: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].weight !== null && liftDetails.history[0].cargoes[0].weight !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].weight : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].weight !== null && liftDetails.history[0].cargoes[1].weight !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].weight : '',

		// Cargo Transportation: Total Cubic Feet
		cargoTotalCubicFeet:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].totalCubicFeet !== null && liftDetails.history[0].cargoes[0].totalCubicFeet !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].totalCubicFeet : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].totalCubicFeet !== null && liftDetails.history[0].cargoes[1].totalCubicFeet !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].totalCubicFeet : '',

		// Cargo Transportation: Cargo Description
		cargoDescription: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].description !== null && liftDetails.history[0].cargoes[0].description !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].description : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].description !== null && liftDetails.history[0].cargoes[1].description !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].description : '',

		// Cargo Transportation: Special Handling Requirements
		specialHandlingRequirements:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].specialHandlingRequirements !== null && liftDetails.history[0].cargoes[0].specialHandlingRequirements !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS'
				? liftDetails.history[0].cargoes[0].specialHandlingRequirements
				: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].specialHandlingRequirements !== null && liftDetails.history[0].cargoes[1].specialHandlingRequirements !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS'
				? liftDetails.history[0].cargoes[1].specialHandlingRequirements
				: '',

		// Cargo Transportation: Oversize
		hasOversizedCargo: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].oversize !== null && liftDetails.history[0].cargoes[0].oversize !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].oversize : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].oversize !== null && liftDetails.history[0].cargoes[1].oversize !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].oversize : '',

		// Cargo Transportation: Rolling Stock
		hasRollingStockCargo: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].rollingStock !== null && liftDetails.history[0].cargoes[0].rollingStock !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].rollingStock : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].rollingStock !== null && liftDetails.history[0].cargoes[1].rollingStock !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].rollingStock : '',

		/**
		 * Hazmat
		 * ************************************************************************************ */

		// Has Hazmat
		cargoHasHazmat: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].hazmat.containsHazmat : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].hazmat.containsHazmat : '',

		// Hazmat: UN Number
		hazmatUnNumber: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].hazmat.unNumber : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].hazmat.unNumber : '',

		// Hazmat: Proper Shipping Name
		hazmatProperShippingName: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].hazmat.shippingName : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].hazmat.shippingName : '',

		// Hazmat: Proper Shipping Description
		hazmatProperShippingDescription:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].hazmat.shippingDescription : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].hazmat.shippingDescription : '',

		// Hazmat: Hazard Class and Division
		hazmatHazardClass:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].hazmat.hazardClassAndDivision : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].hazmat.hazardClassAndDivision : '',

		// Hazmat: Special Provision Code
		passengerEligibilityCode:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].hazmat.passengerEligibilityCode : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].hazmat.passengerEligibilityCode : '',

		// Hazmat: explosive compatibility group
		explosiveCompatibilityGroup:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].hazmat.explosiveCompatibilityGroup : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].hazmat.explosiveCompatibilityGroup : '',

		// Hazmat: NET Explosive Weight
		hazmatNetExplosiveWeight:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS' ? liftDetails.history[0].cargoes[0].hazmat.netExplosiveWeight : liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS' ? liftDetails.history[0].cargoes[1].hazmat.netExplosiveWeight : '',

		// Hazmat: Dimensions - Length
		hazmatDimensionsLength:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].hazmat.cargo !== null && liftDetails.history[0].cargoes[0].hazmat.cargo !== undefined && liftDetails.history[0].cargoes[0].hazmat.cargo.length !== null && liftDetails.history[0].cargoes[0].hazmat.cargo.length !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS'
				? liftDetails.history[0].cargoes[0].hazmat.cargo.length
				: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].hazmat.cargo !== null && liftDetails.history[0].cargoes[1].hazmat.cargo !== undefined && liftDetails.history[0].cargoes[1].hazmat.cargo.length !== null && liftDetails.history[0].cargoes[1].hazmat.cargo.length !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS'
				? liftDetails.history[0].cargoes[1].hazmat.cargo.length
				: '',

		// Hazmat: Dimensions - Width
		hazmatDimensionsWidth:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].hazmat.cargo !== null && liftDetails.history[0].cargoes[0].hazmat.cargo !== undefined && liftDetails.history[0].cargoes[0].hazmat.cargo.width !== null && liftDetails.history[0].cargoes[0].hazmat.cargo.width !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS'
				? liftDetails.history[0].cargoes[0].hazmat.cargo.width
				: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].hazmat.cargo !== null && liftDetails.history[0].cargoes[1].hazmat.cargo !== undefined && liftDetails.history[0].cargoes[1].hazmat.cargo.width !== null && liftDetails.history[0].cargoes[1].hazmat.cargo.width !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS'
				? liftDetails.history[0].cargoes[1].hazmat.cargo.width
				: '',

		// Hazmat: Dimensions - Height
		hazmatDimensionsHeight:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].hazmat.cargo !== null && liftDetails.history[0].cargoes[0].hazmat.cargo !== undefined && liftDetails.history[0].cargoes[0].hazmat.cargo.height !== null && liftDetails.history[0].cargoes[0].hazmat.cargo.height !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS'
				? liftDetails.history[0].cargoes[0].hazmat.cargo.height
				: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].hazmat.cargo !== null && liftDetails.history[0].cargoes[1].hazmat.cargo !== undefined && liftDetails.history[0].cargoes[1].hazmat.cargo.height !== null && liftDetails.history[0].cargoes[1].hazmat.cargo.height !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS'
				? liftDetails.history[0].cargoes[1].hazmat.cargo.height
				: '',

		// Hazmat: Dimensions - Weight
		hazmatDimensionsWeight:
			liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[0] !== null && liftDetails.history[0].cargoes[0] !== undefined && liftDetails.history[0].cargoes[0].hazmat !== null && liftDetails.history[0].cargoes[0].hazmat !== undefined && liftDetails.history[0].cargoes[0].hazmat.cargo !== null && liftDetails.history[0].cargoes[0].hazmat.cargo !== undefined && liftDetails.history[0].cargoes[0].hazmat.cargo.weight !== null && liftDetails.history[0].cargoes[0].hazmat.cargo.weight !== undefined && liftDetails.history[0].cargoes[0].type === 'PALLETS'
				? liftDetails.history[0].cargoes[0].hazmat.cargo.weight
				: liftDetails.history && liftDetails.history[0].cargoes && liftDetails.history[0].cargoes[1] !== null && liftDetails.history[0].cargoes[1] !== undefined && liftDetails.history[0].cargoes[1].hazmat !== null && liftDetails.history[0].cargoes[1].hazmat !== undefined && liftDetails.history[0].cargoes[1].hazmat.cargo !== null && liftDetails.history[0].cargoes[1].hazmat.cargo !== undefined && liftDetails.history[0].cargoes[1].hazmat.cargo.weight !== null && liftDetails.history[0].cargoes[1].hazmat.cargo.weight !== undefined && liftDetails.history[0].cargoes[1].type === 'PALLETS'
				? liftDetails.history[0].cargoes[1].hazmat.cargo.weight
				: '',

		/**
		 * Share Email Addresses
		 * ************************************************************************************ */

		// Share Emails
		shareEmails: liftDetails.history && liftDetails.history[0] && liftDetails.history[0].shareEmailsObjects !== null && liftDetails.history[0].shareEmailsObjects !== undefined ? liftDetails.history[0].shareEmailsObjects : [],

		/**
		 * Form Name
		 * ************************************************************************************ */

		// Form Name
		requestName: liftDetails.history && liftDetails.history[0] && liftDetails.history[0].requestName !== null && liftDetails.history[0].requestName !== undefined ? liftDetails.history[0].requestName : '',

		/**
		 * Remarks/Additional Comments
		 * ************************************************************************************ */

		// Remarks/Additional Comments
		remarksAdditionalComments: liftDetails.history && liftDetails.history[0] && liftDetails.history[0].comments !== undefined && liftDetails.history[0].comments[0] !== undefined && liftDetails.history[0].comments[0].text !== null && liftDetails.history[0].comments[0].text !== undefined ? liftDetails.history[0].comments[0].text : '',

		validationComments: liftDetails.history && liftDetails.history[0] && liftDetails.history[0].validationHistory && liftDetails.history[0].validationHistory[0] !== undefined && liftDetails.history[0].validationHistory[0].comments !== undefined && liftDetails.history[0].validationHistory[0].comments[0] !== null && liftDetails.history[0].validationHistory[0].comments[0] !== undefined ? liftDetails.history[0].validationHistory[0].comments : ''
	};

	return initValues;
};
