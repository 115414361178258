/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect, useState } from 'react';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Form, FormElement, Field, FieldArray } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { FormInput, FormDateTimePicker, FormTextArea, FormNumericTextBox, FormComboBox, FormSwitch } from '../../App/KendoFormElements/FormComponents';
import { priorityOfTravel, naloPriorityOfTravel, urgencyOfTravel, naloUrgencyOfTravel } from '../../../Assets/json/optionsNewLiftRequest';
import { commercialTravelUnacceptableDescriptionValidator, completeMissionDescriptionValidator, emailValidator, nameValidator, phoneValidator, priorityValidator, requiredValidator, urgencyValidator } from '../../App/KendoFormElements/Validators';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { optionsDepartmentOrAgency } from '../../../Assets/json/departmentsAndAgencies';
import { optionsGradesListNavy } from '../../../Assets/json/grades';
import { filterChange } from '../../Helpers/FilterData';
import { insertItem, getItems, updateItem, deleteItem } from '../../Helpers/TableHelpers/TableServices';
import { PalletCommandButtonsCell } from '../../Helpers/TableHelpers/CommandCell';
import { EditPalletLongForm } from '../../Helpers/FormHelpers/EditPalletLongForm';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { forceDateStringToUTCISOFormat } from '../../Helpers/DateTimeConversions';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import LiftRequestInfoModal from '../../App/Modals/LiftRequestInfoModal';

import { ApiAirportsList, ApiNewLiftRequestForm, ApiOrgUnitListCount, ApiOrgUnitListPaged, ApiOrgUnitList } from '../../Helpers/Apis';
import { compareDateChangeDep, compareDateChangeArr } from '../../Helpers/DateTimeCheck';
import { CargoColumns } from '../../Helpers/DataGrids';

let globalAirports = [];

const editField = 'inEdit';

const PalletFormContainerCommandCell = (props) => {
	return (
		<td>
			<button className="btn btn-primary btn-color-green padding-left-30 padding-right-30" onClick={() => props.enterEditForm(props.dataItem)} title={'Click to Create Cargo Form'}>
				Edit <i className="far fa-pencil"></i>
			</button>
		</td>
	);
};

const HazmatCell = ({ dataItem, ...props }) => {
	if (!dataItem || !dataItem.hazmat || !dataItem.hazmat.containsHazmat) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					No
				</span>
			</td>
		);
	}

	if (dataItem.hazmat && dataItem.hazmat.containsHazmat) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					Yes
				</span>
				{/* keeping in case Customer wants hazmat data in column */}
				{/* <span style={{
		display: 'inlineBlock',
		paddingLeft: '10px',
		verticalAlign: 'middle',
		lineHeight: '32px'
	  	}} className="hazmat-cell">
		  {dataItem.hazmat.hazardClassAndDivision}
		</span> */}
			</td>
		);
	}
};

const commandCell = (onRemove) => (props) => {
	const onClick = React.useCallback(
		(e) => {
			e.preventDefault();
			onRemove(props);
		},
		[onRemove]
	);
	return (
		<td className={'summary-actions-cell'}>
			<button className={'button btn-icon-remove btn-color-red margin-right-0 padding-top-12 padding-bottom-12 k-button k-grid-remove-command'} title={'Click to Remove Row'} onClick={onClick}>
				Remove
			</button>
		</td>
	);
};

const icaoAltDepartureCell = (props) => {
	return (
		<td className={'summary-icao-cell no-label'}>
			<Field name={`alternateDeparture[${props.dataIndex}].${props.field}`} textField={'history.0.icaoName'} dataItemKey={'history.0.icao'} placeholder={'Select the Desired Departure ICAO...'} component={FormComboBox} data={globalAirports} allowCustom={true} />
		</td>
	);
};

const IcaoAltArrivalCell = (props) => {
	return (
		<td className={'summary-icao-cell no-label'}>
			<Field name={`alternateArrival[${props.dataIndex}].${props.field}`} textField={'history.0.icaoName'} dataItemKey={'history.0.icao'} placeholder={'Select the Desired Departure ICAO...'} component={FormComboBox} data={globalAirports} allowCustom={true} />
		</td>
	);
};

const emailCell = (props) => {
	return (
		<td className={'summary-email-cell no-label'}>
			<Field name={`shareEmails[${props.dataIndex}].${props.field}`} placeholder={'E.g. name@domain.com'} component={FormInput} optional={false} validator={emailValidator} />
		</td>
	);
};

const AltDepartureAirportsGrid = (fieldArrayRenderProps) => {
	const { validationMessage, visited } = fieldArrayRenderProps;
	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			fieldArrayRenderProps.onUnshift({ value: { airportCode: '' } });
		},
		[fieldArrayRenderProps.onUnshift]
	);
	const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps.onRemove]);

	return (
		<div className={'lift-request-array-grid'}>
			{visited && validationMessage && <Error>{validationMessage}</Error>}
			<Grid data={fieldArrayRenderProps.value}>
				<GridToolbar>
					<button title={'Click to Add Alternate ICAO'} className={'k-button k-primary'} onClick={onAdd}>
						Add New Alternate ICAO
						<i className={'far fa-plus'}></i>
					</button>
				</GridToolbar>
				<Column field="airportCode" title="ICAO Code" cell={icaoAltDepartureCell} />
				<Column cell={commandCell(onRemove)} width="240px" />
			</Grid>
		</div>
	);
};

const ShareFormGrid = (fieldArrayRenderProps) => {
	const { validationMessage, visited } = fieldArrayRenderProps;
	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			fieldArrayRenderProps.onUnshift({ value: { shareEmail: '' } });
		},
		[fieldArrayRenderProps.onUnshift]
	);
	const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps.onRemove]);

	return (
		<div className={'lift-request-array-grid'}>
			{visited && validationMessage && <Error>{validationMessage}</Error>}
			<Grid data={fieldArrayRenderProps.value}>
				<GridToolbar>
					<button title={'Click to Add New Email'} className={'k-button k-primary'} onClick={onAdd}>
						Add New Email
						<i className={'far fa-plus'}></i>
					</button>
				</GridToolbar>
				<Column field="shareEmail" title="Email" cell={emailCell} />
				<Column cell={commandCell(onRemove)} width="240px" />
			</Grid>
		</div>
	);
};

const AltArrivalAirportsGrid = (fieldArrayRenderProps) => {
	const { validationMessage, visited } = fieldArrayRenderProps;
	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			fieldArrayRenderProps.onUnshift({ value: { airportCode: '' } });
		},
		[fieldArrayRenderProps.onUnshift]
	);
	const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps.onRemove]);

	return (
		<div className={'lift-request-array-grid'}>
			{visited && validationMessage && <Error>{validationMessage}</Error>}
			<Grid data={fieldArrayRenderProps.value}>
				<GridToolbar>
					<button title={'Click to Add Alternate ICAO'} className={'k-button k-primary'} onClick={onAdd}>
						Add New Alternate ICAO
						<i className={'far fa-plus'}></i>
					</button>
				</GridToolbar>
				<Column field="airportCode" title="ICAO Code" cell={IcaoAltArrivalCell} />
				<Column cell={commandCell(onRemove)} width="240px" />
			</Grid>
		</div>
	);
};

const NewLiftRequest = (props) => {
	const [formState, setFormState] = React.useState({});

	const [hasPallet, setHasPallet] = React.useState(false);
	const [hasPassenger, setHasPassenger] = React.useState(false);

	const [toggleModal, setToggleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalContent, setModalContent] = useState('');

	const [missionDescCount, setMissionDescCount] = React.useState('');
	const [airliftReasonCount, setAirliftReasonCount] = React.useState('');

	const [units, setUnits] = React.useState([]);
	const [airports, setAirports] = React.useState([]);

	const [timeWindowWarningDep, setTimeWindowWarningDep] = React.useState(false);
	const [timeWindowWarningArr, setTimeWindowWarningArr] = React.useState(false);
	const [hr24LaterDateEarliestDep, setHr24LaterDateEarliestDep] = React.useState('');
	const [hr24LaterDateLatestDep, setHr24LaterDateLatestDep] = React.useState('');
	const [hr24LaterDateEarliestArr, setHr24LaterDateEarliestArr] = React.useState('');
	const [hr24LaterDateLatestArr, setHr24LaterDateLatestArr] = React.useState('');

	const [unitCount, setUnitCount] = React.useState(0);
	const [page, setPage] = React.useState(0);

	const [depPriUnit, setDepPriUnit] = React.useState([]);
	const [depAltUnit, setDepAltUnit] = React.useState([]);
	const [arrPriUnit, setArrPriUnit] = React.useState([]);
	const [arrAltUnit, setArrAltUnit] = React.useState([]);
	const [srTravUnit, setSrTravUnit] = React.useState([]);
	const [requestingUnit, setRequestingUnit] = React.useState([]);
	const [trAuthOffUnit, setTrAuthOffUnit] = React.useState([]);
	const [departmentOrAgencyData, setDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [depAltDepartmentOrAgencyData, setDepAltDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [depPriDepartmentOrAgencyData, setDepPriDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [arrAltDepartmentOrAgencyData, setArrAltDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [arrPriDepartmentOrAgencyData, setArrPriDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [srTravDepartmentOrAgencyData, setSrTravDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [trAuthOffDepartmentOrAgencyData, setTrAuthOffDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [gradesData, setGradesData] = React.useState(optionsGradesListNavy);
	const [depAltGradesData, setDepAltGradesData] = React.useState(optionsGradesListNavy);
	const [depPriGradesData, setDepPriGradesData] = React.useState(optionsGradesListNavy);
	const [arrAltGradesData, setArrAltGradesData] = React.useState(optionsGradesListNavy);
	const [arrPriGradesData, setArrPriGradesData] = React.useState(optionsGradesListNavy);
	const [srTravGradesData, setSrTravGradesData] = React.useState(optionsGradesListNavy);
	const [trAuthOffGradesData, setTrAuthOffGradesData] = React.useState(optionsGradesListNavy);
	const [depOrAgencyloading, setDepOrAgencyloading] = React.useState(false);
	const [gradesLoading, setGradesLoading] = React.useState(false);
	const [unitsLoading, setUnitsLoading] = React.useState(false);
	const [fullUnits, setFullUnits] = React.useState(false);
	const [departureAirportsLoading, setDepartureAirportsLoading] = React.useState(false);
	const [departureAirports, setDepartureAirports] = React.useState([]);
	const [arrivalAirportsLoading, setArrivalAirportsLoading] = React.useState(false);
	const [arrivalAirports, setArrivalAirports] = React.useState([]);
	const [data, setData] = useState([]);
	const [adding, setAdding] = useState(false);
	const [hazmatSubmitData, setHazmatSubmitData] = useState([]);
	const [openForm, setOpenForm] = React.useState(false);
	const [editItem, setEditItem] = React.useState({
		EntryID: 1
	});

	const stateRef = React.useRef();
	stateRef.current = hazmatSubmitData;

	const PalletFormCommandCell = (props) => <PalletFormContainerCommandCell {...props} enterEditForm={enterEditForm} />;

	const CargoCommandCell = (props) => <PalletCommandButtonsCell {...props} edit={enterEdit} remove={remove} add={add} discard={discard} update={update} cancel={cancel} editField={editField} />;

	const handleSubmit = (event) => {
		let newData = data.map((item) => {
			if (event.EntryID === item.EntryID) {
				item = {
					...event,
					oversize: event.oversize,
					rollingStock: event.rollingStock,
					bellyLoadable: event.bellyLoadable,
					description: event.description,
					hazmat: {
						...item.hazmat,
						containsHazmat: event.hazmat.containsHazmat
					}
				};
			}
			update(item);
			return item;
		});
		setData(newData);
		setHazmatSubmitData(newData);
		setOpenForm(false);
	};

	const handleCancelEdit = () => {
		setOpenForm(false);
	};

	const enterEditForm = (item) => {
		setOpenForm(true);
		setEditItem(item);
	};

	// modify the data in the store, db etc
	const remove = (dataItem) => {
		const newData = [...deleteItem(dataItem, hazmatSubmitData)];
		setData(newData);
	};

	const discardNew = () => {
		const newData = [...deleteItem(editItem, hazmatSubmitData)];
		setData(newData);
		setAdding(false);
		setOpenForm(false);
	};

	const add = (dataItem) => {
		dataItem.inEdit = true;
		const newData = insertItem(dataItem, hazmatSubmitData);
		setData(newData);
		setAdding(false);
	};

	const update = (dataItem) => {
		dataItem.inEdit = false;
		dataItem.newItem = false;
		const newData = updateItem(dataItem, hazmatSubmitData);
		setData(newData);
	};

	// Local state operations
	const discard = () => {
		const newData = [...data];
		newData.splice(0, 1);
		setData(newData);
		setAdding(false);
	};

	const cancel = (dataItem) => {
		const originalItem = getItems(hazmatSubmitData).find((p) => p.EntryID === dataItem.EntryID);
		const newData = data.map((item) => (item.EntryID === originalItem.EntryID ? originalItem : item));
		setData(newData);
	};

	const enterEdit = (dataItem) => {
		const newData = data.map((item) =>
			item.EntryID === dataItem.EntryID
				? {
						...item,
						inEdit: true
				  }
				: item
		);
		setData(newData);
		setHazmatSubmitData(newData);
	};

	const itemChange = (event) => {
		const newData = data.map((item) =>
			item.EntryID === event.dataItem.EntryID
				? {
						...item,
						[event.field || '']: event.value
				  }
				: item
		);
		setData(newData);
	};

	const addNew = () => {
		const newDataItem = {
			inEdit: true,
			newItem: true,
			type: 'PALLETS',
			hazmat: {
				containsHazmat: false,
				hazmatCargoes: []
			}
		};
		setAdding(true);
		setData([newDataItem, ...data]);
		add(newDataItem);
		setOpenForm(true);
		setEditItem(newDataItem);
	};

	const setPalletHazmatCargoesData = (dataItem, hazmatCargoesArray) => {
		let newData = data.map((item) => {
			if (dataItem.EntryID === item.EntryID) {
				item = {
					...item,
					hazmat: {
						...item.hazmat,

						hazmatCargoes: dataItem.hazmat.hazmatCargoes
					}
				};
			}
			return item;
		});

		setData(newData);
	};

	const timeout = React.useRef();

	// dropdown query functions. call the onFilterChange helper function to activate filter action on data that is sent.
	const filterUnitsChange = (event) => {
		filterChange(event, setUnits, setUnitsLoading, fullUnits, timeout);
	};

	const filterDepPriUnitsChange = (event) => {
		filterChange(event, setDepPriUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterDepAltUnitsChange = (event) => {
		filterChange(event, setDepAltUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterArrPriUnitsChange = (event) => {
		filterChange(event, setArrPriUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterArrAltUnitsChange = (event) => {
		filterChange(event, setArrAltUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterSrTravUnitsChange = (event) => {
		filterChange(event, setSrTravUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterTrAuthOffUnitsChange = (event) => {
		filterChange(event, setTrAuthOffUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterRequestingUnitsChange = (event) => {
		filterChange(event, setRequestingUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterDepartmentOrAgencyChange = (event) => {
		filterChange(event, setDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterDepAltDepartmentOrAgencyChange = (event) => {
		filterChange(event, setDepAltDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterDepPriDepartmentOrAgencyChange = (event) => {
		filterChange(event, setDepPriDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterArrAltDepartmentOrAgencyChange = (event) => {
		filterChange(event, setArrAltDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterArrPriDepartmentOrAgencyChange = (event) => {
		filterChange(event, setArrPriDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterSrTravDepartmentOrAgencyChange = (event) => {
		filterChange(event, setSrTravDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterTrAuthOffDepartmentOrAgencyChange = (event) => {
		filterChange(event, setTrAuthOffDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterGradesChange = (event) => {
		filterChange(event, setGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterDepPriGradesChange = (event) => {
		filterChange(event, setDepPriGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterDepAltGradesChange = (event) => {
		filterChange(event, setDepAltGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterArrPriGradesChange = (event) => {
		filterChange(event, setArrPriGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterArrAltGradesChange = (event) => {
		filterChange(event, setArrAltGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterSrTravGradesChange = (event) => {
		filterChange(event, setSrTravGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterTrAuthOffGradesChange = (event) => {
		filterChange(event, setTrAuthOffGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterDepartureAirportChange = (event) => {
		filterChange(event, setDepartureAirports, setDepartureAirportsLoading, airports, timeout);
	};

	const filterArrivalAirportChange = (event) => {
		filterChange(event, setArrivalAirports, setArrivalAirportsLoading, airports, timeout);
	};

	// end of dropdown query functions

	useEffect(() => {
		ApiOrgUnitList().then((res) => {
			setFullUnits(res);
		});
		ApiOrgUnitListPaged(0, uicPageSize).then((res) => {
			setUnits(res);
			setDepPriUnit(res);
			setDepAltUnit(res);
			setArrPriUnit(res);
			setArrAltUnit(res);
			setSrTravUnit(res);
			setTrAuthOffUnit(res);
			setRequestingUnit(res);
		});
		ApiOrgUnitListCount().then((res) => {
			setUnitCount(res);
		});
		ApiAirportsList().then((res) => {
			setAirports(res);
			setDepartureAirports(res);
			setArrivalAirports(res);
			globalAirports = res;
		});
	}, []);

	const uicPageSize = 10;

	const missionMin = 100;
	const missionMax = 3000;
	const airliftMin = 100;
	const airliftMax = 3000;

	const pageChange = (event) => {
		const currPage = event.page.skip;

		//stop before entries in uic/page length
		if (currPage < unitCount / event.page.take) {
			ApiOrgUnitListPaged(currPage, event.page.take).then((res) => {
				setUnits(res);
				setDepPriUnit(res);
				setDepAltUnit(res);
				setArrPriUnit(res);
				setArrAltUnit(res);
				setSrTravUnit(res);
				setTrAuthOffUnit(res);
				setRequestingUnit(res);
			});
			setPage(currPage);
		}
	};

	const handleMissionDescCount = (e) => {
		setMissionDescCount(e.value);
		handleOnChange(e);
	};

	const handleAirliftReasonCount = (e) => {
		setAirliftReasonCount(e.value);
		handleOnChange(e);
	};

	const handleHasPallets = (e) => {
		setHasPallet(e.value);
	};

	const handleHasPassengers = (e) => {
		setHasPassenger(e.value);
	};

	const handleOnChange = (e) => {
		const { value, name } = e.target;
		setFormState({
			...formState,
			[name]: value
		});
	};

	const handleDateChange = (e) => {
		if (e.target.props.id.includes('Arrival')) {
			compareDateChangeArr(setHr24LaterDateLatestArr, setHr24LaterDateEarliestArr, hr24LaterDateEarliestArr, hr24LaterDateLatestArr, setTimeWindowWarningArr, e);
		}
		if (e.target.props.id.includes('Departure')) {
			compareDateChangeDep(setHr24LaterDateLatestDep, setHr24LaterDateEarliestDep, hr24LaterDateEarliestDep, hr24LaterDateLatestDep, setTimeWindowWarningDep, e);
		}

		handleOnChange(e);
	};

	const handleModalToggle = () => setToggleModal(!toggleModal);

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	const navigate = useNavigate();

	// On Form Submit Event
	const onFormSubmit = React.useCallback((event) => {
		if (event.isValid) {
			const palletValues = stateRef.current;

			const { values } = event;

			setFormState(values);

			// Requester Details
			let formRequester = {
				/**
				 * Set Requester ID
				 * Temporary Solution: If "Requester" and not "NALO, Approver, or Squadron"
				 * Future solution is to use original requester's ID, as well as allow other requester's (shared LR) to update and save information. Additionally, NALO will eventually be able to select who is the owner of the LR. Currently, no solution to allow for multiple requester ID's for ownership. Only one owner available at this time.
				 */
				id: props.roleaccess.includes('requester') && !props.roleaccess.includes('approver') && !props.roleaccess.includes('nalo') ? props.userid : null,

				firstName: values.requesterFirstName ? values.requesterFirstName : null,
				lastName: values.requesterLastName ? values.requesterLastName : null,
				email: values.requesterEmail ? values.requesterEmail : null,
				phoneNumber: values.requesterPhone ? values.requesterPhone : null,
				branchOfService: values.requesterBranchOfService ? values.requesterBranchOfService : null,
				rank: {
					grade: values.requesterGrade && values.requesterGrade.grade ? values.requesterGrade.grade : null,
					insignia: values.requesterGrade && values.requesterGrade.insignia ? values.requesterGrade.insignia : null,
					title: values.requesterGrade && values.requesterGrade.title ? values.requesterGrade.title : null,
					abbreviation: values.requesterGrade && values.requesterGrade.abbr ? values.requesterGrade.abbr : null,
					rank: values.requesterGrade && values.requesterGrade.class ? values.requesterGrade.class : null,
					gradeTitle: values.requesterGrade && values.requesterGrade.gradetitle ? values.requesterGrade.gradetitle : null
				},
				dutyTitle: values.requesterDutyTitle ? values.requesterDutyTitle : null,
				unit: {
					id: values.requesterUnit && values.requesterUnit.id ? values.requesterUnit.id : null,
					nra: values.requesterUnit && values.requesterUnit.nra ? values.requesterUnit.nra : null,
					uic: values.requesterUnit && values.requesterUnit.uic ? values.requesterUnit.uic : null
				}
			};

			let formRequestingUnit = {
				unit: {
					id: values.requestingUnit && values.requestingUnit.id ? values.requestingUnit.id : null,
					nra: values.requestingUnit && values.requestingUnit.nra ? values.requestingUnit.nra : null,
					uic: values.requestingUnit && values.requestingUnit.uic ? values.requestingUnit.uic : null
				},
				plads: values.requestingUnitPlads ? values.requestingUnitPlads : null
			};

			let commentObj = {};
			commentObj.commenter = {};
			commentObj.text = values.remarksAdditionalComments;
			commentObj.commenter.firstName = props.keycloak.profile.firstName;
			commentObj.commenter.lastName = props.keycloak.profile.lastName;

			// Main Object
			let submissionData = {
				arrival: {
					airport: values.desiredArrivalIcao ? values.desiredArrivalIcao.history[0] : {},
					alternateAirports: [],
					earliest: values.earliestArrivalDateTime ? forceDateStringToUTCISOFormat(values.earliestArrivalDateTime) : null,
					latest: values.latestArrivalDateTime ? forceDateStringToUTCISOFormat(values.latestArrivalDateTime) : null,
					//
					poc: {
						alternate: {
							branchOfService: values.pocArrivalBranchOfServiceAlt ? values.pocArrivalBranchOfServiceAlt : null,
							dutyTitle: values.pocArrivalPassengerDutyTitleAlt ? values.pocArrivalPassengerDutyTitleAlt : null,
							email: values.pocArrivalPassengerEmailAlt ? values.pocArrivalPassengerEmailAlt : null,
							firstName: values.pocArrivalPassengerFNameAlt ? values.pocArrivalPassengerFNameAlt : null,
							lastName: values.pocArrivalPassengerLNameAlt ? values.pocArrivalPassengerLNameAlt : null,
							phoneNumber: values.pocArrivalPassengerMobileAlt ? values.pocArrivalPassengerMobileAlt : null,
							rank: {
								grade: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.grade ? values.pocArrivalPassengerGradeAlt.grade : null,
								insignia: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.insignia ? values.pocArrivalPassengerGradeAlt.insignia : null,
								title: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.title ? values.pocArrivalPassengerGradeAlt.title : null,
								abbreviation: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.abbr ? values.pocArrivalPassengerGradeAlt.abbr : null,
								rank: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.class ? values.pocArrivalPassengerGradeAlt.class : null,
								gradeTitle: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.gradetitle ? values.pocArrivalPassengerGradeAlt.gradetitle : null
							},
							unit: {
								id: values.pocArrivalPassengerUnitAlt && values.pocArrivalPassengerUnitAlt.id ? values.pocArrivalPassengerUnitAlt.id : null,
								nra: values.pocArrivalPassengerUnitAlt && values.pocArrivalPassengerUnitAlt.nra ? values.pocArrivalPassengerUnitAlt.nra : null,
								uic: values.pocArrivalPassengerUnitAlt && values.pocArrivalPassengerUnitAlt.uic ? values.pocArrivalPassengerUnitAlt.uic : null
							}
						},
						primary: {
							branchOfService: values.pocArrivalBranchOfServicePri ? values.pocArrivalBranchOfServicePri : null,
							dutyTitle: values.pocArrivalPassengerDutyTitlePri ? values.pocArrivalPassengerDutyTitlePri : null,
							email: values.pocArrivalPassengerEmailPri ? values.pocArrivalPassengerEmailPri : null,
							firstName: values.pocArrivalPassengerFNamePri ? values.pocArrivalPassengerFNamePri : null,
							lastName: values.pocArrivalPassengerLNamePri ? values.pocArrivalPassengerLNamePri : null,
							phoneNumber: values.pocArrivalPassengerMobilePri ? values.pocArrivalPassengerMobilePri : null,
							rank: {
								grade: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.grade ? values.pocArrivalPassengerGradePri.grade : null,
								insignia: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.insignia ? values.pocArrivalPassengerGradePri.insignia : null,
								title: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.title ? values.pocArrivalPassengerGradePri.title : null,
								abbreviation: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.abbr ? values.pocArrivalPassengerGradePri.abbr : null,
								rank: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.class ? values.pocArrivalPassengerGradePri.class : null,
								gradeTitle: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.gradetitle ? values.pocArrivalPassengerGradePri.gradetitle : null
							},
							unit: {
								id: values.pocArrivalPassengerUnitPri && values.pocArrivalPassengerUnitPri.id ? values.pocArrivalPassengerUnitPri.id : null,
								nra: values.pocArrivalPassengerUnitPri && values.pocArrivalPassengerUnitPri.nra ? values.pocArrivalPassengerUnitPri.nra : null,
								uic: values.pocArrivalPassengerUnitPri && values.pocArrivalPassengerUnitPri.uic ? values.pocArrivalPassengerUnitPri.uic : null
							}
						}
					}
				},
				cargoes: [],
				commandingOfficer: {
					email: values.trAuthOffEmail ? values.trAuthOffEmail : null,
					firstName: values.trAuthOffFName ? values.trAuthOffFName : null,
					lastName: values.trAuthOffLName ? values.trAuthOffLName : null,
					phoneNumber: values.trAuthOffMobile ? values.trAuthOffMobile : null,
					dutyTitle: values.trAuthOffDutyTitle ? values.trAuthOffDutyTitle : null,
					branchOfService: values.trAuthOffBranchOfService ? values.trAuthOffBranchOfService : null,
					rank: {
						grade: values.trAuthOffGrade && values.trAuthOffGrade.grade ? values.trAuthOffGrade.grade : null,
						insignia: values.trAuthOffGrade && values.trAuthOffGrade.insignia ? values.trAuthOffGrade.insignia : null,
						title: values.trAuthOffGrade && values.trAuthOffGrade.title ? values.trAuthOffGrade.title : null,
						abbreviation: values.trAuthOffGrade && values.trAuthOffGrade.abbr ? values.trAuthOffGrade.abbr : null,
						rank: values.trAuthOffGrade && values.trAuthOffGrade.class ? values.trAuthOffGrade.class : null,
						gradeTitle: values.trAuthOffGrade && values.trAuthOffGrade.gradetitle ? values.trAuthOffGrade.gradetitle : null
					},
					unit: {
						id: values.trAuthOffUnit && values.trAuthOffUnit.id ? values.trAuthOffUnit.id : null,
						nra: values.trAuthOffUnit && values.trAuthOffUnit.nra ? values.trAuthOffUnit.nra : null,
						uic: values.trAuthOffUnit && values.trAuthOffUnit.uic ? values.trAuthOffUnit.uic : null
					}
				},
				comments: commentObj ? [commentObj] : null,
				commercialCost: values.commercialCost ? values.commercialCost : null,
				departure: {
					airport: values.desiredDepartureIcao ? values.desiredDepartureIcao.history[0] : {},
					alternateAirports: [],
					earliest: values.earliestDepartureDateTime ? forceDateStringToUTCISOFormat(values.earliestDepartureDateTime) : null,
					latest: values.latestDepartureDateTime ? forceDateStringToUTCISOFormat(values.latestDepartureDateTime) : null,
					poc: {
						alternate: {
							branchOfService: values.pocDepartureBranchOfServiceAlt ? values.pocDepartureBranchOfServiceAlt : null,
							dutyTitle: values.pocDeparturePassengerDutyTitleAlt ? values.pocDeparturePassengerDutyTitleAlt : null,
							email: values.pocDeparturePassengerEmailAlt ? values.pocDeparturePassengerEmailAlt : null,
							firstName: values.pocDeparturePassengerFNameAlt ? values.pocDeparturePassengerFNameAlt : null,
							lastName: values.pocDeparturePassengerLNameAlt ? values.pocDeparturePassengerLNameAlt : null,
							phoneNumber: values.pocDeparturePassengerMobileAlt ? values.pocDeparturePassengerMobileAlt : null,
							rank: {
								grade: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.grade ? values.pocDeparturePassengerGradeAlt.grade : null,
								insignia: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.insignia ? values.pocDeparturePassengerGradeAlt.insignia : null,
								title: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.title ? values.pocDeparturePassengerGradeAlt.title : null,
								abbreviation: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.abbr ? values.pocDeparturePassengerGradeAlt.abbr : null,
								rank: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.class ? values.pocDeparturePassengerGradeAlt.class : null,
								gradeTitle: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.gradetitle ? values.pocDeparturePassengerGradeAlt.gradetitle : null
							},
							unit: {
								id: values.pocDeparturePassengerUnitAlt && values.pocDeparturePassengerUnitAlt.id ? values.pocDeparturePassengerUnitAlt.id : null,
								nra: values.pocDeparturePassengerUnitAlt && values.pocDeparturePassengerUnitAlt.nra ? values.pocDeparturePassengerUnitAlt.nra : null,
								uic: values.pocDeparturePassengerUnitAlt && values.pocDeparturePassengerUnitAlt.uic ? values.pocDeparturePassengerUnitAlt.uic : null
							}
						},
						primary: {
							branchOfService: values.pocDepartureBranchOfServicePri ? values.pocDepartureBranchOfServicePri : null,
							dutyTitle: values.pocDeparturePassengerDutyTitlePri ? values.pocDeparturePassengerDutyTitlePri : null,
							email: values.pocDeparturePassengerEmailPri ? values.pocDeparturePassengerEmailPri : null,
							firstName: values.pocDeparturePassengerFNamePri ? values.pocDeparturePassengerFNamePri : null,
							lastName: values.pocDeparturePassengerLNamePri ? values.pocDeparturePassengerLNamePri : null,
							phoneNumber: values.pocDeparturePassengerMobilePri ? values.pocDeparturePassengerMobilePri : null,
							rank: {
								grade: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.grade ? values.pocDeparturePassengerGradePri.grade : null,
								insignia: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.insignia ? values.pocDeparturePassengerGradePri.insignia : null,
								title: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.title ? values.pocDeparturePassengerGradePri.title : null,
								abbreviation: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.abbr ? values.pocDeparturePassengerGradePri.abbr : null,
								rank: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.class ? values.pocDeparturePassengerGradePri.class : null,
								gradeTitle: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.gradetitle ? values.pocDeparturePassengerGradePri.gradetitle : null
							},
							unit: {
								id: values.pocDeparturePassengerUnitPri && values.pocDeparturePassengerUnitPri.id ? values.pocDeparturePassengerUnitPri.id : null,
								nra: values.pocDeparturePassengerUnitPri && values.pocDeparturePassengerUnitPri.nra ? values.pocDeparturePassengerUnitPri.nra : null,
								uic: values.pocDeparturePassengerUnitPri && values.pocDeparturePassengerUnitPri.uic ? values.pocDeparturePassengerUnitPri.uic : null
							}
						}
					}
				},
				justification: values.justification ? values.justification : null,
				missionDescription: values.completeMissionDescription ? values.completeMissionDescription : null,
				priority: values.priority ? values.priority.value : null,
				requester: formRequester,
				requestingUnit: formRequestingUnit,
				requestName: values.requestName ? values.requestName : null,
				shareEmails: [],
				state: null,
				urgency: values.urgency ? values.urgency.value : null
			};

			// PASSENGERS Object: Pushed to Form Cargo Array Before Submission
			if (values.cargo !== null && values.cargo !== undefined && values.cargo.hasPassengers) {
				let formCargoPassengers = {
					type: 'PASSENGERS',
					count: values.totalNumberOfPax ? values.totalNumberOfPax : null,
					seniorTravelers: [
						{
							firstName: values.srTravelerFName ? values.srTravelerFName : null,
							lastName: values.srTravelerLName ? values.srTravelerLName : null,
							email: values.srTravelerEmail ? values.srTravelerEmail : null,
							phoneNumber: values.srTravelerMobile ? values.srTravelerMobile : null,
							branchOfService: values.srTravelerBranchOfService ? values.srTravelerBranchOfService : null,
							rank: {
								grade: values.srTravelerGrade && values.srTravelerGrade.grade ? values.srTravelerGrade.grade : null,
								insignia: values.srTravelerGrade && values.srTravelerGrade.insignia ? values.srTravelerGrade.insignia : null,
								title: values.srTravelerGrade && values.srTravelerGrade.title ? values.srTravelerGrade.title : null,
								abbreviation: values.srTravelerGrade && values.srTravelerGrade.abbr ? values.srTravelerGrade.abbr : null,
								rank: values.srTravelerGrade && values.srTravelerGrade.class ? values.srTravelerGrade.class : null,
								gradeTitle: values.srTravelerGrade && values.srTravelerGrade.gradetitle ? values.srTravelerGrade.gradetitle : null
							},
							dutyTitle: values.srTravelerDutyTitle ? values.srTravelerDutyTitle : null,
							unit: {
								id: values.srTravelerUnit && values.srTravelerUnit.id ? values.srTravelerUnit.id : null,
								nra: values.srTravelerUnit && values.srTravelerUnit.nra ? values.srTravelerUnit.nra : null,
								uic: values.srTravelerUnit && values.srTravelerUnit.uic ? values.srTravelerUnit.uic : null
							}
						}
					]
				};
				submissionData.cargoes.push(formCargoPassengers);
			}

			if (values.cargo !== null && values.cargo !== undefined && values.cargo.hasPallets) {
				// eslint-disable-next-line
				palletValues.map((pallet) => {
					let palletObj = {};
					palletObj = {
						type: 'PALLETS',
						cargoName: pallet.PalletName ? pallet.PalletName : null,
						count: pallet.count ? pallet.count : null,
						weight: pallet.weight ? pallet.weight : null,
						totalCubicFeet: pallet.cargoTotalCubicFeet ? pallet.cargoTotalCubicFeet : null,
						largestItem: {
							length: pallet.cargoLargestItemDimensionsLength ? pallet.cargoLargestItemDimensionsLength : null,
							width: pallet.cargoLargestItemDimensionsWidth ? pallet.cargoLargestItemDimensionsWidth : null,
							height: pallet.cargoLargestItemDimensionsHeight ? pallet.cargoLargestItemDimensionsHeight : null,
							weight: pallet.cargoLargestItemDimensionsWeight ? pallet.cargoLargestItemDimensionsWeight : null
						},
						heaviestItem: {
							length: pallet.cargoHeaviestItemLength ? pallet.cargoHeaviestItemLength : null,
							width: pallet.cargoHeaviestItemWidth ? pallet.cargoHeaviestItemWidth : null,
							height: pallet.cargoHeaviestItemHeight ? pallet.cargoHeaviestItemHeight : null,
							weight: pallet.cargoHeaviestItemWeight ? pallet.cargoHeaviestItemWeight : null
						},
						description: pallet.description ? pallet.description : null,
						oversize: pallet.oversize ? pallet.oversize : false,
						rollingStock: pallet.rollingStock ? pallet.rollingStock : false,
						bellyLoadable: pallet.bellyLoadable ? pallet.bellyLoadable : false,
						hazmat: {
							containsHazmat: pallet.hazmat && pallet.hazmat.containsHazmat ? pallet.hazmat.containsHazmat : false,
							hazmatCargoes: []
						},
						specialHandlingRequirements: pallet.specialHandlingRequirements ? pallet.specialHandlingRequirements : null
					};

					if (pallet.hazmat !== null && pallet.hazmat.containsHazmat && pallet.hazmat.hazmatCargoes.length > 0) {
						// create hazmatArray and push it to hazmatCargoes
						// eslint-disable-next-line
						pallet.hazmat.hazmatCargoes.map((hazmat) => {
							let hazmatObj = {};
							hazmatObj = {
								hazmatName: hazmat.PalletName ? hazmat.PalletName : null,
								unNumber: hazmat.unNumber ? hazmat.unNumber : null,
								shippingName: hazmat.shippingName ? hazmat.shippingName : null,
								shippingDescription: hazmat.shippingDescription ? hazmat.shippingDescription : null,
								hazardClassAndDivision: hazmat.hazardClassAndDivision ? hazmat.hazardClassAndDivision : null,
								passengerEligibilityCode: hazmat.passengerEligibilityCode ? hazmat.passengerEligibilityCode : null,
								explosiveCompatibilityGroup: hazmat.explosiveCompatibilityGroup ? hazmat.explosiveCompatibilityGroup : null,
								cargo: {
									length: hazmat.cargoLength ? hazmat.cargoLength : null,
									width: hazmat.cargoWidth ? hazmat.cargoWidth : null,
									height: hazmat.cargoHeight ? hazmat.cargoHeight : null,
									weight: hazmat.cargoWeight ? hazmat.cargoWeight : null
								},
								netExplosiveWeight: hazmat.netExplosiveWeight ? hazmat.netExplosiveWeight : null
							};
							palletObj.hazmat.hazmatCargoes.push(hazmatObj);
						});
					}
					submissionData.cargoes.push(palletObj);
				});
			}

			// Push alternateDeparture to Array
			if (values.alternateDeparture) {
				values.alternateDeparture.map((rec) => {
					if (rec.airportCode.history) {
						rec.airportCode.history.map((airportDetail) => submissionData.departure.alternateAirports.push(airportDetail));
					}
					return submissionData.departure.alternateAirports;
				});
			}

			// Push alternateArrival to Array
			if (values.alternateArrival) {
				values.alternateArrival.map((rec) => {
					if (rec.airportCode.history) {
						rec.airportCode.history.map((airportDetail) => submissionData.arrival.alternateAirports.push(airportDetail));
					}
					return submissionData.arrival.alternateAirports;
				});
			}

			// Push shareEmails to Array
			if (values.shareEmails) {
				values.shareEmails.map((rec) => {
					return submissionData.shareEmails.push(rec.shareEmail);
				});
			}

			// Click Event: Save Draft
			if (event.event.target.innerText === 'SAVE DRAFT') {
				// Set State: OPENED
				submissionData.state = 'OPENED';

				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log(submissionData);
				}
				ApiNewLiftRequestForm(submissionData).then((res) => {
					toast.success('Lift Request Draft Saved Successfully!');
					navigate('/dashboard');
				});
			}

			// Click Event: Submitted
			if (event.event.target.innerText === 'SUBMIT AIRLIFT REQUEST') {
				// Set State: SUBMITTED
				submissionData.state = 'SUBMITTED';

				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log(submissionData);
				}

				ApiNewLiftRequestForm(submissionData).then((res) => {
					toast.success('Lift Request Submitted Successfully!');
					navigate('/dashboard');
				});
			}
		}
	}, []);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title padding-bottom-30'}>
							<h2>New Military Air Passenger/Cargo Request</h2>
						</div>

						<Form
							initialValues={formState}
							onSubmitClick={onFormSubmit}
							render={(formRenderProps) => (
								<div className={'lift-request-form long-form'}>
									<div className={'lift-request-form-inner'}>
										<FormElement>
											{/* Requester Information: Start */}
											<Row id={'requester-information'} className={'form-section'}>
												{/* Section Title - Priority and Urgency: Start */}
												<div className={'col-12 form-section-title priority-and-urgency'}>
													<h3>Requester Information</h3>
												</div>
												{/* Section Title - Priority and Urgency: End */}

												<div className={'col-3 required-field'}>
													<Field key={'requesterFirstName'} id={'requesterFirstName'} name={'requesterFirstName'} label={'First Name'} placeholder={'First Name'} onChange={handleOnChange} component={FormInput} validator={nameValidator} />
												</div>

												<div className={'col-3 required-field'}>
													<Field key={'requesterLastName'} id={'requesterLastName'} name={'requesterLastName'} label={'Last Name'} placeholder={'Last Name'} onChange={handleOnChange} component={FormInput} validator={nameValidator} />
												</div>

												<div className={'col-3'}>
													<div className={'form-group'}>
														<Field key={'requesterEmail'} id={'requesterEmail'} name={'requesterEmail'} label={'Your Email'} placeholder={'email@email.com'} onChange={handleOnChange} component={FormInput} />
													</div>
												</div>

												<div className={'col-3'}>
													<div className={'form-group required-field'}>
														<Field key={'requesterPhone'} id={'requesterPhone'} name={'requesterPhone'} label={'Phone Number'} placeholder={'Please include country code.'} onChange={handleOnChange} component={FormInput} validator={phoneValidator} />
													</div>
												</div>

												<div className={'col-3'}>
													<div className={'form-group'}>
														<Field key={'requesterBranchOfService'} id={'requesterBranchOfService'} name={'requesterBranchOfService'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={departmentOrAgencyData} filterable={true} onFilterChange={filterDepartmentOrAgencyChange} />
													</div>
												</div>

												<div className={'col-3'}>
													<div className={'form-group'}>
														<Field key={'requesterGrade'} id={'requesterGrade'} name={'requesterGrade'} label={'Grade'} textField={'gradetitle'} dataItemKey={'gradetitle'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={gradesData} loading={gradesLoading} filterable={true} onFilterChange={filterGradesChange} />
													</div>
												</div>

												<div className={'col-3'}>
													<div className={'form-group'}>
														<Field key={'requesterDutyTitle'} id={'requesterDutyTitle'} name={'requesterDutyTitle'} label={'Duty Title'} placeholder={''} component={FormInput} />
													</div>
												</div>

												<div className={'col-3'}>
													<div className={'form-group'}>
														<Field
															key={'requesterUnit'}
															id={'requesterUnit'}
															name={'requesterUnit'}
															label={'Unit'}
															textField={'uicnra'}
															dataItemKey={'uic'}
															placeholder={'Unit...'}
															component={FormComboBox}
															allowCustom={true}
															data={units}
															value={units}
															virtual={{
																total: unitCount,
																pageSize: uicPageSize,
																skip: page
															}}
															onPageChange={pageChange}
															loading={unitsLoading}
															filterable={true}
															onFilterChange={filterUnitsChange}
														/>
													</div>
												</div>

												<div className={'col-12 form-section-title priority-and-urgency'}>
													<h3>Requesting Unit</h3>
												</div>

												<div className={'col-12'}>
													<p className={'padding-bottom-0 padding-top-0'}>If requesting unit is different from the requester's, then please enter requesting unit and PLADS information below.</p>
												</div>

												<div className={'col-3'}>
													<div className={'form-group'}>
														<Field
															key={'requestingUnit'}
															id={'requestingUnit'}
															name={'requestingUnit'}
															label={'Unit'}
															textField={'uicnra'}
															dataItemKey={'uic'}
															placeholder={'Unit...'}
															component={FormComboBox}
															allowCustom={true}
															data={requestingUnit}
															value={units}
															virtual={{
																total: unitCount,
																pageSize: uicPageSize,
																skip: page
															}}
															onPageChange={pageChange}
															loading={unitsLoading}
															filterable={true}
															onFilterChange={filterRequestingUnitsChange}
														/>
													</div>
												</div>

												<div className={'col-9'}>
													<div className={'form-group'}>
														<Field key={'plads'} id={'plads'} name={'requestingUnitPlads'} label={'PLADS'} placeholder={'plain language address...'} component={FormInput} />
													</div>
												</div>
											</Row>
											{/* Requester Information: End */}

											{/* Priority and Urgency: Start */}
											<Row id={'travel-priority-and-urgency'} className={'form-section'}>
												{/* Section Title - Priority and Urgency: Start */}
												<div className={'col-12 form-section-title priority-and-urgency'}>
													<h3>Priority and Urgency of Lift</h3>
												</div>
												{/* Section Title - Priority and Urgency: End */}

												{/* Travel Priority: Start */}
												<div className={'col-6 priority has-info required-field'}>
													<Field key={'priority'} id={'priority'} name={'priority'} label={'Lift Priority'} textField={'label'} dataItemKey={'value'} placeholder={'Select the priority of your request...'} onChange={handleOnChange} component={FormComboBox} data={props.roleaccess.includes('nalo') ? naloPriorityOfTravel : priorityOfTravel} validator={priorityValidator} />
													<Button
														variant={'secondary'}
														className={'btn-form-info'}
														onClick={() => {
															// Info: Modal Title
															handleModalTitle('Travel Priority Information');

															// Info: Modal Content
															handleModalContent(`
                                                            <p><span class="fontsize-18px">Priority 1:</span><br/><b>Direct support of operational forces</b> engaged in
                                                            combat <b><u>or</u></b> contingency peace-keeping operations directed NCA, <b><u>or</u></b> for emergency
                                                            lifesaving purposes.</p>
                                                            <p><span class="fontsize-18px">Priority 2:</span><br/>"<b>Required use</b>" travel <b><u>or</u></b> compelling
                                                            operational considerations making commercial transportation unacceptable (within 24 hours). Mission cannot be
                                                            satisfied by any other mode of travel. Requester should provide a 2-hour window for departure and arrival
                                                            times to allow consolidation of missions per DoD Directive 4500.43.</p>
                                                            <p><span class="fontsize-18px">Priority 3:</span><br/><b>Official business travel</b> which when consolidated
                                                            by JOSAC with other travelers, is more cost effective than commercial air travel or official business travel
                                                            on previously scheduled missions. Requester <b>must</b> provide at least a 2-hour window for departure and
                                                            arrival times to allow consolidation of missions per DoD Directive 4500.43.</p>
                                                        `);

															// Show Modal
															handleModalToggle();
														}}>
														<i className={'fa-solid fa-info modal-trigger'}></i>
														<span className={'sr-only'}>Click to view information about Travel Priority</span>
													</Button>
												</div>
												{/* Travel Priority: End */}

												{/* Travel Urgency: Start */}
												<div className={'col-6 urgency has-info required-field'}>
													<Field key={'urgency'} id={'urgency'} name={'urgency'} label={'Lift Urgency'} textField={'label'} dataItemKey={'value'} placeholder={'Select the urgency of your request...'} onChange={handleOnChange} component={FormComboBox} data={props.roleaccess.includes('nalo') ? naloUrgencyOfTravel : urgencyOfTravel} validator={urgencyValidator} />
													<Button
														variant={'secondary'}
														className={'btn-form-info'}
														onClick={() => {
															// Info: Modal Title
															handleModalTitle('PUJC Code Information');

															// Info: Modal Content
															handleModalContent(`
                                                            <p><span class="fontsize-18px">Urgency 1:</span><br/>(<b>Combat</b>) Airlift of personnel or materiel in direct
															support of forces alerted for or engaged in combat or contingency operations.</p>
                                                            <p><span class="fontsize-18px">Urgency 2:</span><br/>(<b>Lifesaving or Operational</b>) Airlift of personnel or
															materiel in direct support of lifesaving operations or operational forces deployed or preparing for mobilization.</p>
                                                            <p><span class="fontsize-18px">Urgency 3:</span><br/>(<b>Humanitarian</b>) Airlift of personnel or materiel in direct
															support of authorized and urgent humanitarian operations.</p>
															<p><span class="fontsize-18px">Urgency 4:</span><br/>(<b>Critical</b>) Airlift of personnel or materiel that, while
															not fulfilling a higher urgency, would critically impact the outcome of unit requirements if not immediately supported
															exactly as requested.</p>
                                                            <p><span class="fontsize-18px">Urgency 5:</span><br/>(<b>Priority</b>) Airlift of personnel or materiel that, while
															not fulfilling a higher urgency, would have a serious impact on the outcome of unit requirements if not fulfilled.
															Changes or consolidation of these requests with other requests would not adversely affect unit requirements. </p>
                                                            <p><span class="fontsize-18px">Urgency 6:</span><br/>(<b>Routine</b>) Airlift of personnel or materiel scheduled as
															part of an organization's daily or weekly routine, or travel that is qualified on a cost- effective basis but does
															not meet the requirements of a higher urgency code. Changes or consolidation of these requests with other requests
															would not affect unit requirements.</p>
                                                        `);

															// Show Modal
															handleModalToggle();
														}}>
														<i className={'fa-solid fa-info modal-trigger'}></i>
														<span className={'sr-only'}>Click to view information about PUJC Code</span>
													</Button>
												</div>
												{/* Travel Urgency: End */}
											</Row>
											{/* Priority and Urgency: End */}

											{/* Point of Contact (Departure): Start */}
											<Row id={'point-of-contact-departure'} className={'form-section'}>
												{/* Section Title - Point of Contact: Start */}
												<div className={'col-12 form-section-title point-of-contact'}>
													<h3>
														Point of Contact (Departure)
														<span className={'fontsize-14px'} style={{ float: 'right' }}>
															(Cannot be Arrival POC or Travel Authorizing Official)
														</span>
													</h3>
												</div>
												{/* Section Title - Point of Contact: End */}

												<div className={'col-12'}>
													<h5 className={'form-subsection'}>Primary Contact</h5>
												</div>

												{/* POC Departure Passenger Name (Pri): Start */}
												<div className={'col-3 required-field'}>
													<Field key={'pocDeparturePassengerFNamePri'} id={'pocDeparturePassengerFNamePri'} name={'pocDeparturePassengerFNamePri'} label={'First Name'} placeholder={'First Name'} onChange={handleOnChange} component={FormInput} validator={nameValidator} />
												</div>

												{/* POC Departure Passenger Name (Pri): Start */}
												<div className={'col-3 required-field'}>
													<Field key={'pocDeparturePassengerLNamePri'} id={'pocDeparturePassengerLNamePri'} name={'pocDeparturePassengerLNamePri'} label={'Last Name'} placeholder={'Last Name'} onChange={handleOnChange} component={FormInput} validator={nameValidator} />
												</div>
												{/* POC Departure Passenger Name (Pri): End */}

												{/* POC Departure Passenger Email (Pri): Start */}
												<div className={'col-3'}>
													<Field key={'pocDeparturePassengerEmailPri'} id={'pocDeparturePassengerEmailPri'} name={'pocDeparturePassengerEmailPri'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
												</div>
												{/* POC Departure Passenger Email (Pri): End */}

												{/* POC Departure Passenger Mobile Phone (Pri): Start */}
												<div className={'col-3 required-field'}>
													<Field key={'pocDeparturePassengerMobilePri'} id={'pocDeparturePassengerMobilePri'} name={'pocDeparturePassengerMobilePri'} onChange={handleOnChange} label={'Mobile Phone'} placeholder={'Please include country code.'} component={FormInput} validator={phoneValidator} />
												</div>
												{/* POC Departure Passenger Mobile Phone (Pri): End */}

												{/* POC Departure Passenger Branch of Service (Pri): Start */}
												<div className={'col-3'}>
													<Field key={'pocDepartureBranchOfServicePri'} id={'pocDepartureBranchOfServicePri'} name={'pocDepartureBranchOfServicePri'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={depPriDepartmentOrAgencyData} filterable={true} onFilterChange={filterDepPriDepartmentOrAgencyChange} />
												</div>
												{/* POC Departure Passenger Branch of Service (Pri): End */}

												{/* POC Departure Passenger Grade/DV Code (Pri): Start */}
												<div className={'col-3'}>
													<Field key={'pocDeparturePassengerGradePri'} id={'pocDeparturePassengerGradePri'} name={'pocDeparturePassengerGradePri'} onChange={handleOnChange} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={depPriGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterDepPriGradesChange} />
												</div>
												{/* POC Departure Passenger Grade/DV Code (Pri): End */}

												{/* POC Departure Passenger Duty Title (Pri): Start */}
												<div className={'col-3'}>
													<Field key={'pocDeparturePassengerDutyTitlePri'} id={'pocDeparturePassengerDutyTitlePri'} name={'pocDeparturePassengerDutyTitlePri'} onChange={handleOnChange} label={'Duty Title'} placeholder={''} component={FormInput} />
												</div>
												{/* POC Departure Passenger Duty Title (Pri): End */}

												{/* POC Departure Passenger Unit (Pri): Start */}
												<div className={'col-3'}>
													<Field
														key={'pocDeparturePassengerUnitPri'}
														id={'pocDeparturePassengerUnitPri'}
														name={'pocDeparturePassengerUnitPri'}
														label={'Unit'}
														textField={'uicnra'}
														dataItemKey={'uic'}
														placeholder={'Unit...'}
														component={FormComboBox}
														allowCustom={true}
														data={depPriUnit}
														value={units}
														virtual={{
															total: unitCount,
															pageSize: uicPageSize,
															skip: page
														}}
														onPageChange={pageChange}
														loading={unitsLoading}
														filterable={true}
														onFilterChange={filterDepPriUnitsChange}
													/>
												</div>
												{/* POC Departure Passenger Unit (Pri): End */}

												<div className={'col-12'}>
													<h5 className={'form-subsection'}>Alternate Contact</h5>
												</div>

												{/* POC Departure Passenger Name (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocDeparturePassengerFNameAlt'} id={'pocDeparturePassengerFNameAlt'} name={'pocDeparturePassengerFNameAlt'} onChange={handleOnChange} label={'First Name'} placeholder={'First Name'} component={FormInput} />
												</div>

												{/* POC Departure Passenger Name (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocDeparturePassengerLNameAlt'} id={'pocDeparturePassengerLNameAlt'} name={'pocDeparturePassengerLNameAlt'} onChange={handleOnChange} label={'Last Name'} placeholder={'Last Name'} component={FormInput} />
												</div>
												{/* POC Departure Passenger Name (Alt): End */}

												{/* POC Departure Passenger Email (Alt) */}
												<div className={'col-3'}>
													<Field key={'pocDeparturePassengerEmailAlt'} id={'pocDeparturePassengerEmailAlt'} name={'pocDeparturePassengerEmailAlt'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
												</div>
												{/* POC Departure Passenger Email (alt) */}

												{/* POC Departure Passenger Mobile Phone (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocDeparturePassengerMobileAlt'} id={'pocDeparturePassengerMobileAlt'} name={'pocDeparturePassengerMobileAlt'} onChange={handleOnChange} label={'Mobile Phone'} placeholder={'Please include country code.'} component={FormInput} />
												</div>
												{/* POC Departure Passenger Mobile Phone (Alt): End */}

												{/* POC Departure Passenger Branch of Service (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocDepartureBranchOfServiceAlt'} id={'pocDepartureBranchOfServiceAlt'} name={'pocDepartureBranchOfServiceAlt'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={depAltDepartmentOrAgencyData} filterable={true} onFilterChange={filterDepAltDepartmentOrAgencyChange} />
												</div>
												{/* POC Departure Passenger Branch of Service (Alt): End */}

												{/* POC Departure Passenger Grade/DV Code (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocDeparturePassengerGradeAlt'} id={'pocDeparturePassengerGradeAlt'} name={'pocDeparturePassengerGradeAlt'} onChange={handleOnChange} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={depAltGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterDepAltGradesChange} />
												</div>
												{/* POC Departure Passenger Grade/DV Code (Alt): End */}

												{/* POC Departure Passenger Duty Title (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocDeparturePassengerDutyTitleAlt'} id={'pocDeparturePassengerDutyTitleAlt'} name={'pocDeparturePassengerDutyTitleAlt'} onChange={handleOnChange} label={'Duty Title'} placeholder={''} component={FormInput} />
												</div>
												{/* POC Departure Passenger Duty Title (Alt): End */}

												{/* POC Departure Passenger Unit (Alt): Start */}
												<div className={'col-3'}>
													<Field
														key={'pocDeparturePassengerUnitAlt'}
														id={'pocDeparturePassengerUnitAlt'}
														name={'pocDeparturePassengerUnitAlt'}
														label={'Unit'}
														textField={'uicnra'}
														dataItemKey={'uic'}
														placeholder={'Unit...'}
														component={FormComboBox}
														allowCustom={true}
														data={depAltUnit}
														value={units}
														virtual={{
															total: unitCount,
															pageSize: uicPageSize,
															skip: page
														}}
														onPageChange={pageChange}
														loading={unitsLoading}
														filterable={true}
														onFilterChange={filterDepAltUnitsChange}
													/>
												</div>
												{/* POC Departure Passenger Unit (Alt): End */}
											</Row>
											{/* Point of Contact (Departure): End */}

											{/* Point of Contact (Arrival): Start */}
											<Row id={'point-of-contact-arrival'} className={'form-section'}>
												{/* Section Title - Point of Contact: Start */}
												<div className={'col-12 form-section-title point-of-contact'}>
													<h3>
														Point of Contact (Arrival)
														<span className={'fontsize-14px'} style={{ float: 'right' }}>
															(Cannot be Departure Coordinator)
														</span>
													</h3>
												</div>
												{/* Section Title - Point of Contact: End */}

												<div className={'col-12'}>
													<h5 className={'form-subsection'}>Primary Contact</h5>
												</div>

												{/* POC Arrival Passenger Name (Pri): Start */}
												<div className={'col-3 required-field'}>
													<Field key={'pocArrivalPassengerFNamePri'} id={'pocArrivalPassengerFNamePri'} name={'pocArrivalPassengerFNamePri'} onChange={handleOnChange} label={'First Name'} placeholder={'First Name'} component={FormInput} validator={nameValidator} />
												</div>

												<div className={'col-3 required-field'}>
													<Field key={'pocArrivalPassengerLNamePri'} id={'pocArrivalPassengerLNamePri'} name={'pocArrivalPassengerLNamePri'} onChange={handleOnChange} label={'Last Name'} placeholder={'Last Name'} component={FormInput} validator={nameValidator} />
												</div>
												{/* POC Arrival Passenger Name (Pri): End */}

												{/* POC Arrival Passenger Email (Pri): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalPassengerEmailPri'} id={'pocArrivalPassengerEmailPri'} name={'pocArrivalPassengerEmailPri'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
												</div>
												{/* POC Arrival Passenger Email (Pri): End */}

												{/* POC Arrival Passenger Mobile Phone (Pri): Start */}
												<div className={'col-3 required-field'}>
													<Field key={'pocArrivalPassengerMobilePri'} id={'pocArrivalPassengerMobilePri'} name={'pocArrivalPassengerMobilePri'} label={'Mobile Phone'} placeholder={'Please include country code.'} onChange={handleOnChange} component={FormInput} validator={phoneValidator} />
												</div>
												{/* POC Arrival Passenger Mobile Phone (Pri): End */}

												{/* POC Arrival Passenger Branch of Service (Pri): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalBranchOfServicePri'} id={'pocArrivalBranchOfServicePri'} name={'pocArrivalBranchOfServicePri'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={arrPriDepartmentOrAgencyData} filterable={true} onFilterChange={filterArrPriDepartmentOrAgencyChange} />
												</div>
												{/* POC Arrival Passenger Branch of Service (Pri): End */}

												{/* POC Arrival Passenger Grade/DV Code (Pri): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalPassengerGradePri'} id={'pocArrivalPassengerGradePri'} name={'pocArrivalPassengerGradePri'} onChange={handleOnChange} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={arrPriGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterArrPriGradesChange} />
												</div>
												{/* POC Arrival Passenger Grade/DV Code (Pri): End */}

												{/* POC Arrival Passenger Duty Title (Pri): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalPassengerDutyTitlePri'} id={'pocArrivalPassengerDutyTitlePri'} name={'pocArrivalPassengerDutyTitlePri'} onChange={handleOnChange} label={'Duty Title'} placeholder={''} component={FormInput} />
												</div>
												{/* POC Arrival Passenger Duty Title (Pri): End */}

												{/* POC Arrival Passenger Unit (Pri): Start */}
												<div className={'col-3'}>
													<Field
														key={'pocArrivalPassengerUnitPri'}
														id={'pocArrivalPassengerUnitPri'}
														name={'pocArrivalPassengerUnitPri'}
														label={'Unit'}
														textField={'uicnra'}
														dataItemKey={'uic'}
														placeholder={'Unit...'}
														component={FormComboBox}
														allowCustom={true}
														data={arrPriUnit}
														value={units}
														virtual={{
															total: unitCount,
															pageSize: uicPageSize,
															skip: page
														}}
														onPageChange={pageChange}
														loading={unitsLoading}
														filterable={true}
														onFilterChange={filterArrPriUnitsChange}
													/>
												</div>
												{/* POC Arrival Passenger Unit (Pri): End */}

												<div className={'col-12'}>
													<h5 className={'form-subsection'}>Alternate Contact</h5>
												</div>

												{/* POC Arrival Passenger Name (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalPassengerFNameAlt'} id={'pocArrivalPassengerFNameAlt'} name={'pocArrivalPassengerFNameAlt'} onChange={handleOnChange} label={'First Name'} placeholder={'First Name'} component={FormInput} />
												</div>

												{/* POC Arrival Passenger Name (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalPassengerLNameAlt'} id={'pocArrivalPassengerLNameAlt'} name={'pocArrivalPassengerLNameAlt'} onChange={handleOnChange} label={'Last Name'} placeholder={'Last Name'} component={FormInput} />
												</div>
												{/* POC Arrival Passenger Name (Alt): End */}

												{/* POC Arrival Passenger Email (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalPassengerEmailAlt'} id={'pocArrivalPassengerEmailAlt'} name={'pocArrivalPassengerEmailAlt'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
												</div>
												{/* POC Arrival Passenger Email (Alt): End */}

												{/* POC Arrival Passenger Mobile Phone (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalPassengerMobileAlt'} id={'pocArrivalPassengerMobileAlt'} name={'pocArrivalPassengerMobileAlt'} label={'Mobile Phone'} placeholder={'Please include country code.'} onChange={handleOnChange} component={FormInput} />
												</div>
												{/* POC Arrival Passenger Mobile Phone (Alt): End */}

												{/* POC Arrival Passenger Branch of Service (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalBranchOfServiceAlt'} id={'pocArrivalBranchOfServiceAlt'} name={'pocArrivalBranchOfServiceAlt'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={arrAltDepartmentOrAgencyData} filterable={true} onFilterChange={filterArrAltDepartmentOrAgencyChange} />
												</div>
												{/* POC Arrival Passenger Branch of Service (Alt): End */}

												{/* POC Arrival Passenger Grade/DV Code (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalPassengerGradeAlt'} id={'pocArrivalPassengerGradeAlt'} name={'pocArrivalPassengerGradeAlt'} onChange={handleOnChange} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={arrAltGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterArrAltGradesChange} />
												</div>
												{/* POC Arrival Passenger Grade/DV Code (Alt): End */}

												{/* POC Arrival Passenger Duty Title (Alt): Start */}
												<div className={'col-3'}>
													<Field key={'pocArrivalPassengerDutyTitleAlt'} id={'pocArrivalPassengerDutyTitleAlt'} name={'pocArrivalPassengerDutyTitleAlt'} onChange={handleOnChange} label={'Duty Title'} placeholder={''} component={FormInput} />
												</div>
												{/* POC Arrival Passenger Duty Title (Alt): End */}

												{/* POC Arrival Passenger Unit (Alt): Start */}
												<div className={'col-3'}>
													<Field
														key={'pocArrivalPassengerUnitAlt'}
														id={'pocArrivalPassengerUnitAlt'}
														name={'pocArrivalPassengerUnitAlt'}
														label={'Unit'}
														textField={'uicnra'}
														dataItemKey={'uic'}
														placeholder={'Unit...'}
														component={FormComboBox}
														allowCustom={true}
														data={arrAltUnit}
														value={units}
														virtual={{
															total: unitCount,
															pageSize: uicPageSize,
															skip: page
														}}
														onPageChange={pageChange}
														loading={unitsLoading}
														filterable={true}
														onFilterChange={filterArrAltUnitsChange}
													/>
												</div>
												{/* POC Arrival Passenger Unit (Alt): End */}
											</Row>
											{/* Point of Contact (Arrival): End */}

											{/* Purpose of Travel: Start */}
											<Row id={'purpose-of-travel'} className={'form-section'}>
												{/* Section Title - Purpose of Travel: Start */}
												<div className={'col-12 form-section-title purpose-of-travel'}>
													<h3>Purpose of Travel</h3>
												</div>
												{/* Section Title - Purpose of Travel: End */}

												{/* Complete Mission Description: Start */}
												<div className={'col-12 complete-mission-description has-info required-field'}>
													<Field key={'completeMissionDescription'} id={'completeMissionDescription'} name={'completeMissionDescription'} label={'Complete Mission Description'} optional={false} component={FormTextArea} onChange={handleMissionDescCount} maxLength={missionMax} value={missionDescCount} hint={`Minimum ${missionMin} characters/Maximum ${missionMax} characters. ${missionDescCount.length} of ${missionMax}`} validator={completeMissionDescriptionValidator} />
													<Button
														variant={'secondary'}
														className={'btn-form-info'}
														onClick={() => {
															// Info: Modal Title
															handleModalTitle('Complete Mission Description Information');

															// Info: Modal Content
															handleModalContent(`
                                                            <p>Describe what the request is for. What exercise, deployment, operation, training,
                                                            etc this request will fulfill by being supported by CFAF. This description along
                                                            with compelling considerations and reason for commercial air unacceptable will be
                                                            used to determine the proper PUJC for this lift. We require as much detail as possible
                                                            to properly validate and schedule.</p>
                                                        `);

															// Show Modal
															handleModalToggle();
														}}>
														<i className={'fa-solid fa-info modal-trigger'}></i>
														<span className={'sr-only'}>Click to view information about Complete Mission Description</span>
													</Button>
												</div>
												{/* Complete Mission Description: End */}

												{/* Reason for Military Airlift or Reason Commercial Travel Unacceptable: Start */}
												<div className={'col-12 priority-2-compelling-considerations has-info required-field'}>
													<Field key={'justification'} id={'justification'} name={'justification'} label={'Reason for Military Airlift or Reason Commercial Travel Unacceptable'} optional={false} component={FormTextArea} onChange={handleAirliftReasonCount} maxLength={airliftMax} value={airliftReasonCount} hint={`Minimum ${airliftMin} characters/Maximum ${airliftMax} characters. ${airliftReasonCount.length} of ${airliftMax}`} validator={commercialTravelUnacceptableDescriptionValidator} />
													<Button
														variant={'secondary'}
														className={'btn-form-info'}
														onClick={() => {
															// Info: Modal Title
															handleModalTitle('Priority 2 Considerations Information');

															// Info: Modal Content
															handleModalContent(`
                                                            <p>If this lift cannot be satisfied, what impact will that have on the unit requesting? What is the reason
                                                            behind not taking commercial air instead of CFAF?</p>
                                                            <p>(Content of cargo, unit cohesion, airfield restrictions, strict time line)</p>
                                                            <p>**High cost is not an acceptable reason</p>
                                                        `);

															// Show Modal
															handleModalToggle();
														}}>
														<i className={'fa-solid fa-info modal-trigger'}></i>
														<span className={'sr-only'}>Click to view information about Priority 2 Considerations</span>
													</Button>
												</div>
												{/* Reason for Military Airlift or Reason Commercial Travel Unacceptable: End */}
											</Row>
											{/* Purpose of Travel: End */}

											{/* Departure Flight Itinerary: Start */}
											<Row id={'itinerary-departure-information'} className={'form-section'}>
												{/* Section Title - Desired Flight Itinerary: Start */}
												<div className={'col-12 form-section-title itinerary-departure-information'}>
													<h3>
														Itinerary Departure Information
														<span className={'fontsize-14px'} style={{ float: 'right' }}>
															(Earliest and latest dates must be separated by a minimum of 72 hours)
														</span>
													</h3>
												</div>
												{/* Section Title - Desired Flight Itinerary: End */}

												{/* Departure Flight Itinerary Block: Start */}
												<div id={'itinerary-departure-information-action-block'} className={'col-12 has-info'}>
													<Row>
														{/* Desired Departure ICAO: Start */}
														<div className={'col-4 required-field'}>
															<Field key={'desiredDepartureIcao'} id={'desiredDepartureIcao'} name={'desiredDepartureIcao'} label={'Desired Departure ICAO'} textField={'history.0.icaoName'} dataItemKey={'history.0.icao'} placeholder={'Select the Desired Departure ICAO...'} onChange={handleOnChange} component={FormComboBox} validator={requiredValidator} data={departureAirports} loading={departureAirportsLoading} allowCustom={true} filterable={true} onFilterChange={filterDepartureAirportChange} />
														</div>
														{/* Desired Departure ICAO: End */}

														{/* Earliest Departure Date/Time: Start */}
														<div className={'col-4 required-field'}>
															<Field key={'earliestDepartureDateTime'} id={'earliestDepartureDateTime'} name={'earliestDepartureDateTime'} label={'Earliest Departure Date/Time (UTC/Zulu)'} onChange={handleDateChange} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} validator={requiredValidator} hint={timeWindowWarningDep ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ''} />
														</div>
														{/* Earliest Departure Date/Time: End */}

														{/* Latest Departure Date/Time: Start */}
														<div className={'col-4 required-field'}>
															<Field key={'latestDepartureDateTime'} id={'latestDepartureDateTime'} name={'latestDepartureDateTime'} label={'Latest Departure Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} onChange={handleDateChange} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} validator={requiredValidator} hint={timeWindowWarningDep ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ''} />
															<Button
																variant={'secondary'}
																className={'btn-form-info'}
																onClick={() => {
																	// Info: Modal Title
																	handleModalTitle('Pickup / Drop-off Window Information');
																	// Info: Modal Content
																	handleModalContent(`
                                                                <p>Choose the largest time window possible.</p>
                                                                <p>Requests with larger time windows are more likely to get scheduled. </p>
                                                                `);
																	// Show Modal
																	handleModalToggle();
																}}>
																<i className={'fa-solid fa-info modal-trigger'}></i>
																<span className={'sr-only'}>Click to view information about Remarks/Additional Comments</span>
															</Button>
														</div>
														{/* Latest Departure Date/Time: End */}

														{/* Departure Alternative Airports: Start */}
														<div className={'col-12'}>
															<label>List of Alternative Departure Airports</label>
															<FieldArray name="alternateDeparture" component={AltDepartureAirportsGrid} />
														</div>
														{/* Departure Alternative Airports: End */}
													</Row>
												</div>
												{/* Departure Flight Itinerary Block: End */}
											</Row>
											{/* Departure Flight Itinerary: End */}

											{/* Itinerary Arrival Information: Start */}
											<Row id={'itinerary-arrival-information'} className={'form-section'}>
												{/* Section Title - Alternative Flight Itinerary: Start */}
												<div className={'col-12 form-section-title itinerary-arrival-information'}>
													<h3>Itinerary Arrival Information</h3>
												</div>
												{/* Section Title - Alternative Flight Itinerary: End */}

												{/* Itinerary Arrival Information Block: Start */}
												<div id={'itinerary-arrival-information-action-block'} className={'col-12 has-info'}>
													<Row>
														{/* Desired Arrival ICAO: Start */}
														<div className={'col-4 required-field'}>
															<Field key={'desiredArrivalIcao'} id={'desiredArrivalIcao'} name={'desiredArrivalIcao'} label={'Desired Arrival ICAO'} onChange={handleOnChange} textField={'history.0.icaoName'} dataItemKey={'history.0.icaoName'} placeholder={'Select the Desired Arrival ICAO...'} component={FormComboBox} validator={requiredValidator} data={arrivalAirports} loading={arrivalAirportsLoading} allowCustom={true} filterable={true} onFilterChange={filterArrivalAirportChange} />
														</div>
														{/* Desired Arrival ICAO: End */}

														{/* Earliest Arrival Date/Time: Start */}
														<div className={'col-4 required-field'}>
															<Field key={'earliestArrivalDateTime'} id={'earliestArrivalDateTime'} name={'earliestArrivalDateTime'} label={'Earliest Arrival Date/Time (UTC/Zulu)'} onChange={handleDateChange} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} validator={requiredValidator} hint={timeWindowWarningArr ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ''} />
														</div>
														{/* Earliest Arrival Date/Time: End */}

														{/* Latest Arrival Date/Time: Start */}
														<div className={'col-4 required-field'}>
															<Field key={'latestArrivalDateTime'} id={'latestArrivalDateTime'} name={'latestArrivalDateTime'} label={'Latest Arrival Date/Time (UTC/Zulu)'} onChange={handleDateChange} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} validator={requiredValidator} hint={timeWindowWarningArr ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ''} />
															<Button
																variant={'secondary'}
																className={'btn-form-info'}
																onClick={() => {
																	// Info: Modal Title
																	handleModalTitle('Pickup / Drop-off Window Information');
																	// Info: Modal Content
																	handleModalContent(`
                                                                <p>Choose the largest time window possible.</p>
                                                                <p>Requests with larger time windows are more likely to get scheduled. </p>
                                                                `);
																	// Show Modal
																	handleModalToggle();
																}}>
																<i className={'fa-solid fa-info modal-trigger'}></i>
																<span className={'sr-only'}>Click to view information about Remarks/Additional Comments</span>
															</Button>
														</div>
														{/* Latest Arrival Date/Time: End */}

														{/* Arrival Alternative Airports: Start */}
														<div className={'col-12'}>
															<label>List of Alternative Arrival Airports</label>
															<FieldArray name={'alternateArrival'} component={AltArrivalAirportsGrid} />
														</div>
														{/* Arrival Alternative Airports: End */}
													</Row>
												</div>
												{/* Itinerary Arrival Information Block: End */}
											</Row>
											{/* Itinerary Arrival Information: End */}

											{/* Passengers or Cargo: Start */}
											<Row id={'form-passenger-cargo-option'} className={'form-section'}>
												{/* Section Title - Passengers or Cargo: Start */}
												<div className={'col-12 form-section-title passengers-or-cargo'}>
													<h3>
														Is your request for Passengers or Cargo?
														<span className={'fontsize-14px'} style={{ float: 'right' }}>
															(For each passenger, we will account for 40lbs of bags)
														</span>
													</h3>
												</div>
												{/* Section Title - Passengers or Cargo: End */}

												<div className={'col-12 cargo-toggles padding-left-10'}>
													<div>
														<Field key={'hasPassengers'} id={'hasPassengers'} name={'cargo.hasPassengers'} onLabel={'Yes'} offLabel={'No'} defaultChecked={false} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Request Contains Passengers?'} component={FormSwitch} onChange={handleHasPassengers} />
													</div>
													<div>
														<Field key={'hasPallets'} id={'hasPallets'} name={'cargo.hasPallets'} onLabel={'Yes'} offLabel={'No'} defaultChecked={false} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Request Contains Cargo?'} component={FormSwitch} onChange={handleHasPallets} />
													</div>
												</div>
											</Row>
											{/* Passengers or Cargo: Start */}

											{hasPassenger && (
												<div id={'form-passengers'}>
													{/* Total Number of PAX Row: Start */}
													<Row id={'total-pax'} className={'form-section'}>
														{/* Section Title - PAX Count: Start */}
														<div className={'col-12 form-section-title pax-count'}>
															<h3>PAX Count</h3>
														</div>
														{/* Section Title - PAX Count: End */}

														{/* Total Number of PAX: Start */}
														<div className={'col-4'}>
															<Field key={'totalNumberOfPax'} id={'totalNumberOfPax'} name={'totalNumberOfPax'} label={'Total Number of Pax'} onChange={handleOnChange} placeholder={''} format={'n0'} min={1} component={FormNumericTextBox} />
														</div>
														{/* Total Number of PAX: End */}
													</Row>
													{/* Total Number of PAX Row: End */}

													{/* Commercial Cost for PAX Row: Start */}
													<Row id={'commercial-cost'} className={'form-section'}>
														{/* Section Title - PAX Count: Start */}
														<div className={'col-12 form-section-title pax-count'}>
															<h3>
																Determine Commercial Cost
																<span className={'fontsize-14px'} style={{ float: 'right' }}>
																	(Calculate cost using the{' '}
																	<a href={'https://www.gsa.gov/travel/plan-book/transportation-airfare-pov-etc/city-pair-program-cpp'} title={'Click to Use CPP Search Tool'} target={'_blank'} rel={'noreferrer'}>
																		CPP Search Tool
																	</a>
																	)
																</span>
															</h3>
														</div>
														{/* Section Title - PAX Count: End */}

														{/* Total Commercial Cost for PAX: Start */}
														<div className={'col-4'}>
															<div className={'form-group'}>
																<Field key={'commercialCost'} id={'commercialCost'} name={'commercialCost'} label={'Total Commercial Cost for PAX'} onChange={handleOnChange} placeholder={''} hint={'Amount in USD'} format={'n2'} component={FormNumericTextBox} />

																{/*
                                                            <Button variant={"secondary"} className={"btn-form-info"} onClick={() => {

                                                                // Info: Modal Title
                                                                handleModalTitle("Total Commercial Cost for PAX");

                                                                // Info: Modal Content
                                                                handleModalContent(`
                                                                    <p></p>
                                                                `);

                                                                // Show Modal
                                                                handleModalToggle();
                                                                }}
                                                                >
                                                                <i className={"fa-solid fa-info modal-trigger"}></i>
                                                                <span className={"sr-only"}>Click to view information about Commercial Cost for PAX</span>
                                                            </Button>
                                                            */}
															</div>
														</div>
														{/* Total Commercial Cost for PAX Row: End */}
													</Row>
													{/* Commercial Cost for PAX Row: End */}

													{/* Senior Traveler: Start */}
													<Row id={'senior-traveler'} className={'form-section'}>
														{/* Section Title - Senior Traveler: Start */}
														<div className={'col-12 form-section-title senior-traveler'}>
															<h3>
																Senior Traveler
																<span className={'fontsize-14px'} style={{ float: 'right' }}>
																	(Must be 1 for every 121 PAX &amp; can't be the same as the Travel Authorizing Official)
																</span>
															</h3>
														</div>
														{/* Section Title - Senior Traveler: End */}

														{/* Senior Traveler Name: Start */}
														<div className={'col-3'}>
															<Field key={'srTravelerFName'} id={'srTravelerFName'} name={'srTravelerFName'} onChange={handleOnChange} label={'First Name'} placeholder={'First Name'} component={FormInput} />
														</div>

														<div className={'col-3'}>
															<Field key={'srTravelerLName'} id={'srTravelerLName'} name={'srTravelerLName'} onChange={handleOnChange} label={'Last Name'} placeholder={'Last Name'} component={FormInput} />
														</div>
														{/* Senior Traveler Name: End */}

														{/* Senior Traveler Email: Start */}
														<div className={'col-3'}>
															<Field key={'srTravelerEmail'} id={'srTravelerEmail'} name={'srTravelerEmail'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
														</div>
														{/* Senior Traveler Email: End */}

														{/* Senior Traveler Mobile Phone: Start */}
														<div className={'col-3'}>
															<Field key={'srTravelerMobile'} id={'srTravelerMobile'} name={'srTravelerMobile'} label={'Mobile Phone'} placeholder={'Please include country code.'} onChange={handleOnChange} component={FormInput} validator={phoneValidator} />
														</div>
														{/* Senior Traveler Mobile Phone: End */}

														{/* Senior Traveler Branch of Service: Start */}
														<div className={'col-3'}>
															<Field key={'srTravelerBranchOfService'} id={'srTravelerBranchOfService'} name={'srTravelerBranchOfService'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={srTravDepartmentOrAgencyData} filterable={true} onFilterChange={filterSrTravDepartmentOrAgencyChange} />
														</div>
														{/* Senior Traveler Branch of Service: End */}

														{/* Senior Traveler Grade/DV Code: Start */}
														<div className={'col-3'}>
															<Field key={'srTravelerGrade'} id={'srTravelerGrade'} name={'srTravelerGrade'} onChange={handleOnChange} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={srTravGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterSrTravGradesChange} />
														</div>
														{/* Senior Traveler Grade/DV Code: End */}

														{/* Senior Traveler Duty Title: Start */}
														<div className={'col-3'}>
															<Field key={'srTravelerDutyTitle'} id={'srTravelerDutyTitle'} name={'srTravelerDutyTitle'} onChange={handleOnChange} label={'Duty Title'} placeholder={''} component={FormInput} />
														</div>
														{/* Senior Traveler Duty Title: End */}

														{/* Senior Traveler Unit: Start */}
														<div className={'col-3'}>
															<Field
																key={'srTravelerUnit'}
																id={'srTravelerUnit'}
																name={'srTravelerUnit'}
																label={'Unit'}
																textField={'uicnra'}
																dataItemKey={'uic'}
																placeholder={'Unit...'}
																component={FormComboBox}
																allowCustom={true}
																data={srTravUnit}
																value={units}
																virtual={{
																	total: unitCount,
																	pageSize: uicPageSize,
																	skip: page
																}}
																onPageChange={pageChange}
																loading={unitsLoading}
																filterable={true}
																onFilterChange={filterSrTravUnitsChange}
															/>
														</div>
														{/* Senior Traveler Unit: End */}
													</Row>
													{/* Senior Traveler: End */}

													{/* Travel Authorizing Official: Start */}

													<Row id={'travel-authorizing-official'} className={'form-section'}>
														{/* Section Title - Travel Authorizing Official: Start */}
														<div className={'col-12 form-section-title travel-authorizing-official'}>
															<h3>
																Travel Authorizing Official
																<span className={'fontsize-14px'} style={{ float: 'right' }}>
																	(As appointed by Service)
																</span>
															</h3>
														</div>
														{/* Section Title - Travel Authorizing Official: End */}

														{/* Travel Authorizing Official Name: Start */}
														<div className={'col-3'}>
															<Field key={'trAuthOffFName'} id={'trAuthOffFName'} name={'trAuthOffFName'} onChange={handleOnChange} label={'First Name'} placeholder={'First Name'} component={FormInput} />
														</div>

														<div className={'col-3'}>
															<Field key={'trAuthOffLName'} id={'trAuthOffLName'} name={'trAuthOffLName'} onChange={handleOnChange} label={'Last Name'} placeholder={'Last Name'} component={FormInput} />
														</div>
														{/* Travel Authorizing Official Name: End */}

														{/* Travel Authorizing Official Email: Start */}
														<div className={'col-3'}>
															<Field key={'trAuthOffEmail'} id={'trAuthOffEmail'} name={'trAuthOffEmail'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
														</div>
														{/* Travel Authorizing Official Email: End */}

														{/* Travel Authorizing Official Mobile Phone: Start */}
														<div className={'col-3'}>
															<Field key={'trAuthOffMobile'} id={'trAuthOffMobile'} name={'trAuthOffMobile'} label={'Mobile Phone'} placeholder={'Please include country code.'} onChange={handleOnChange} component={FormInput} validator={phoneValidator} />
														</div>
														{/* Travel Authorizing Official Mobile Phone: End */}

														{/* Travel Authorizing Official Branch of Service: Start */}
														<div className={'col-3'}>
															<Field key={'trAuthOffBranchOfService'} id={'trAuthOffBranchOfService'} name={'trAuthOffBranchOfService'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={trAuthOffDepartmentOrAgencyData} filterable={true} onFilterChange={filterTrAuthOffDepartmentOrAgencyChange} />
														</div>
														{/* Travel Authorizing Official  Branch of Service: End */}

														{/* Travel Authorizing Official Grade/DV Code: Start */}
														<div className={'col-3'}>
															<Field key={'trAuthOffGrade'} id={'trAuthOffGrade'} name={'trAuthOffGrade'} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={trAuthOffGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterTrAuthOffGradesChange} />
														</div>
														{/* Travel Authorizing Official Grade/DV Code: End */}

														{/* Travel Authorizing Official Duty Title: Start */}
														<div className={'col-3'}>
															<Field key={'trAuthOffDutyTitle'} id={'trAuthOffDutyTitle'} name={'trAuthOffDutyTitle'} onChange={handleOnChange} label={'Duty Title'} placeholder={''} component={FormInput} />
														</div>
														{/* Travel Authorizing Official Duty Title: End */}

														{/* Travel Authorizing Official Unit: Start */}
														<div className={'col-3'}>
															<Field
																key={'trAuthOffUnit'}
																id={'trAuthOffUnit'}
																name={'trAuthOffUnit'}
																label={'Unit'}
																textField={'uicnra'}
																dataItemKey={'uic'}
																placeholder={'Unit...'}
																component={FormComboBox}
																allowCustom={true}
																data={trAuthOffUnit}
																value={units}
																virtual={{
																	total: unitCount,
																	pageSize: uicPageSize,
																	skip: page
																}}
																onPageChange={pageChange}
																loading={unitsLoading}
																filterable={true}
																onFilterChange={filterTrAuthOffUnitsChange}
															/>
														</div>
														{/* Travel Authorizing Official Unit: End */}
													</Row>
													{/* Travel Authorizing Official: End */}
												</div>
											)}

											{hasPallet && (
												<div id={'form-cargo'}>
													{/* Pallet Positions: Start */}
													<Row id={'cargo-pallets'} className={'form-section'}>
														{/* Section Title - Cargo Pallets: Start */}
														<div className={'col-12 form-section-title cargo-pallets'}>
															<h3>Cargo</h3>
														</div>
														{/* Section Title - Cargo Pallets: End */}
													</Row>

													{/* Pallet Table: Start */}
													<Grid
														id={'cargoPallets'}
														name={'cargoPallets'}
														style={{
															height: '420px',
															width: '100%'
														}}
														data={data}
														onItemChange={itemChange}
														navigatable={true}
														editField={editField}>
														<GridToolbar>
															<Button type={'button'} title="Add new" disabled={adding} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={addNew}>
																Add New Cargo <i className="far fa-plus"></i>
															</Button>
														</GridToolbar>

														<Column field="EntryID" title="Id" width="0px" editable={false} />

														<Column field="PalletName" title="Cargo Title" editable={true} />

														<Column field="weight" title="Total Weight" editable={true} cell={CargoColumns.WeightCellLongForm} />

														<Column field="count" title="Cargo Positions" editor="numeric" validator={requiredValidator} />

														<Column
															cells={{
																data: HazmatCell
															}}
															title="Contains Hazmat"
															editable={false}
														/>
														<Column cell={PalletFormCommandCell} />
														<Column cell={CargoCommandCell} width="250px" />
													</Grid>

													{openForm && <EditPalletLongForm cancelEdit={handleCancelEdit} discardNew={discardNew} setPalletHazmatCargoesData={setPalletHazmatCargoesData} onSubmit={handleSubmit} item={editItem} props={props} />}

													{/*  Pallet Table: End */}

													{/* Pallet Positions: End */}
												</div>
											)}

											{/* Share Lift Request: Start */}
											<Row id={'share-lift-request'} className={'form-section'}>
												{/* Section Title - Share Request: Start */}
												<div className={'col-12 form-section-title share-request'}>
													<h3>Share Lift Request with Other Personnel</h3>
												</div>
												{/* Section Title - Share Request: End */}

												{/* Share Request: Start */}
												<div className={'col-12'}>
													<FieldArray name="shareEmails" placeholder={'E.g. name@domain.com'} component={ShareFormGrid} />
												</div>
												{/* Share Request: End */}
											</Row>
											{/* Share Lift Request: End */}

											{/* Name Your Form: Start */}
											<Row id={'lift-request-introduction'} className={'form-section'}>
												<div className={'col-12 form-section-title lift-request-introduction'}>
													<h3>Name Your Form</h3>
												</div>
												{/* Section Title - Senior Traveler: End */}

												<div className={'col-12'}>
													<p>Enter a name for your form below. This field is not required.</p>
												</div>

												<div className={'col-4'}>
													<div className={'form-group'}>
														<Field key={'requesterFormName'} id={'requesterFormName'} name={'requestName'} onChange={handleOnChange} label={'Form Name'} placeholder={'E.g. Security Package for Delivery'} component={FormInput} />
													</div>
												</div>
											</Row>
											{/* Name Your Form: End */}

											{/* Remarks/Additional Comments: Start */}
											<Row id={'remarks-additional-comments'} className={'form-section'}>
												{/* Section Title - Remarks/Additional Comments: Start */}
												<div className={'col-12 form-section-title remarks-additional-comments'}>
													<h3>Remarks/Additional Comments</h3>
												</div>
												{/* Section Title - Remarks/Additional Comments: End */}

												{/* Remarks/Additional Comments: Start */}
												<div className={'col-12 remarks-additional-comments has-info'}>
													<Field key={'remarksAdditionalComments'} id={'remarksAdditionalComments'} name={'remarksAdditionalComments'} label={'Remarks/Additional Comments'} onChange={handleOnChange} optional={true} component={FormTextArea} />
													<Button
														variant={'secondary'}
														className={'btn-form-info'}
														onClick={() => {
															// Info: Modal Title
															handleModalTitle('Remarks/Additional Comments Information');

															// Info: Modal Content
															handleModalContent(`
                                                            <p></p>
                                                        `);

															// Show Modal
															handleModalToggle();
														}}>
														<i className={'fa-solid fa-info modal-trigger'}></i>
														<span className={'sr-only'}>Click to view information about XXXXX</span>
													</Button>
												</div>
												{/* Remarks/Additional Comments: End */}
											</Row>
											{/* Remarks/Additional Comments: End */}

											{/* Submission: Start */}
											<Row id={'form-submission-buttons'} className={'form-section form-submission-buttons k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'}>
												<div className={'col-12 align-right'}>
													<Button title={'Click to Save Draft'} className={'btn btn-size-medium btn-primary btn-color-blue btn-submit'} type={'submit'} onClick={formRenderProps.onSubmit}>
														Save Draft
														<i className={'fa-regular fa-floppy-disk'}></i>
													</Button>
													<Button title={'Click to Submit Airlift Request'} className={'btn btn-size-medium btn-primary btn-color-green margin-right-0 btn-submit'} disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}>
														Submit Airlift Request
														<i className={'far fa-arrow-right'}></i>
													</Button>
												</div>
											</Row>
											{/* Submission: End */}
										</FormElement>
									</div>
								</div>
							)}
						/>
					</Panel>

					<LiftRequestInfoModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalContent={modalContent} />

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLiftRequest);
