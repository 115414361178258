/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import moment from 'moment-timezone';

import { Col, Container, Row, Button } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';
import { ApiMissionListByState } from '../../Helpers/Apis';

const RadarBoxMap = (props) => {
	const params = useParams();
	const missionId = params.missionId ? params.missionId : '';

	const [windowView, setWindowView] = React.useState('minimize'); // maximize, minimize toggle
	const [missionList, setMissionList] = React.useState(true);
	const [missionsList, setMissionsList] = React.useState([]);

	const missionsListDataFetch = () => {
		const queryparams = '?state=GENERATED&state=DEPARTED';
		ApiMissionListByState(queryparams).then((res) => {
			setMissionsList(res);
		});
	};

	useEffect(() => {
		missionsListDataFetch();
	}, []);

	// Mission Sidebar Selection List
	const missionsNavigation = () => {
		return (
			<div className={'mission-schedules-list'}>
				<div className={'mission-schedules-toggle'}>
					<Navbar bg="light" expand="lg">
						<Button
							className={`mission-schedules-list-button btn-list-missions-view ${missionList ? 'show-list' : 'hide-list'}`}
							title={`Click to ${missionList ? 'Hide' : 'Show'} Mission List`}
							onClick={() => {
								setMissionList(!missionList);
							}}>
							<i className={'fa fa-bars'}></i>
							<span>{`${missionList ? 'Hide' : 'Show'}`} Mission List</span>
						</Button>
					</Navbar>
				</div>

				<div className={'mission-schedules-list-inner'}>
					<ul>
						{missionsList.map((rec, i) => {
							const missionName = rec.missionName === null ? rec.id : rec.missionName;
							const mId = rec.id;
							const createdAt = moment(rec.history[0].createdAt).tz('America/New_York').format('YYYY-MM-DD');
							const timeframe = {
								__html: '<strong>' + missionName + '</strong><span class="daterange">Created on ' + createdAt + '</span>'
							};

							return (
								<li key={i}>
									<NavLink to={`/schedule/map/${mId}`} title={`View Mission Map for ${mId}`}>
										<span dangerouslySetInnerHTML={timeframe} />
									</NavLink>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};

	// Header Filters
	const headerFilterList = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						{/* Print and export button placeholders to be implemented in ALO-549 */}
						{/* <div className={"nav-link-group"}>
                            <Nav.Link href="#print" className={"icon-only"}>
                                <i className={"fa fa-print"}></i>
                                <span className={"Print"}></span>
                            </Nav.Link>
                            <Nav.Link href="#export" className={"icon-only"}>
                                <i className={"fa fa-file-export"}></i>
                                <span className={"Export"}></span>
                            </Nav.Link>
                        </div> */}

						<div className={'nav-link-group'}>
							<NavLink to={`${missionId ? '/schedule/map/' + missionId : '/schedule/map'}`} className={'nav-link btn-map-view icon-only'} title={'View Map of Selected Mission'}>
								<i className={'fa fa-globe'}></i>
								<span className={'Click for Map View'}></span>
							</NavLink>
							<NavLink to={`${missionId ? '/missionstatus/' + missionId : '/missionstatus'}`} className={'nav-link btn-missionstatus-view icon-only'} title={'View Mission Status for Selected Mission'}>
								<i className={'fa fa-plane-departure'}></i>
								<span className={'Click for Mission Status'}></span>
							</NavLink>
							<NavLink to={`${missionId ? '/schedule/legs/' + missionId : '/schedule/legs'}`} className={'nav-link btn-legs-view icon-only'} title={'View Legs of Selected Mission'}>
								<i className={'fa fa-table-rows'}></i>
								<span className={'Click for Mission Legs View'}></span>
							</NavLink>
							<NavLink to={`${missionId ? '/radarbox/map/' + missionId : '/radarbox/map'}`} className={'nav-link btn-map-view icon-only'} title={'View RadarBox AirNav Map'}>
								<i className={'fa-solid fa-radar'}></i>
								<span className={'sr-only'}>Click for RadarBox AirNav Map</span>
							</NavLink>
						</div>

						<div className={'nav-link-group'}>
							{windowView === 'minimize' ? (
								<Nav.Link
									href="#maximize"
									className={'btn-maximize-view icon-only'}
									onClick={() => {
										setWindowView('maximize');
									}}>
									<i className={'fa fa-arrows-maximize'}></i>
									<span className={'Full Screen'}></span>
								</Nav.Link>
							) : (
								<Nav.Link
									href="#minimize"
									className={'btn-minimize-view icon-only'}
									onClick={() => {
										setWindowView('minimize');
									}}>
									<i className={'fa fa-arrows-minimize'}></i>
									<span className={'Exit Full Screen'}></span>
								</Nav.Link>
							)}
						</div>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	return (
		<Container fluid className={`app-content ${windowView}`}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2>RadarBox AirNav Map View</h2>

							{headerFilterList()}
						</div>

						<div className={`mission-schedules ${missionList ? 'sidebar-expanded' : 'sidebar-collapsed'}`}>
							<div className={'mission-schedules-inner'}>
								{missionsNavigation()}

								{missionId.length ? (
									<React.Fragment>
										<div id={'schedule-map-view'} className={'mission-schedule-output map-view mission-selected'}>
											<div className={'mission-schedule-inner'}>
												<div className={'schedule-section-header'}>
													<h4 className={'block-section-header'}>RadarBox AirNav Map View</h4>
												</div>
												<div className={'schedule-list schedule-map'}>
													<div className={'schedule-list-inner'}>
														<div className={'schedule-map-visual'}>
															<iframe title={'RadarBox AirNav Map'} draggable={false} frameBorder={0} scrolling={'no'} marginHeight="0" marginWidth="0" width={'100%'} height={800} src="https://www.radarbox.com/?widget=1&z=5&airport=KATL"></iframe>
														</div>
													</div>
												</div>
											</div>
										</div>
									</React.Fragment>
								) : (
									<div id={'schedule-no-view'} className={'mission-schedule-output map-view no-mission no-mission-selected'}>
										<div className={'mission-schedule-inner'}>
											<div className={'schedule-section-header'}>
												<h4 className={'block-section-header'}>RadarBox AirNav Map View</h4>
											</div>
											<div className={'schedule-list schedule-map'}>
												<div className={'schedule-list-inner'}>
													<div className={'schedule-map-visual'}>
														{/* <GoogleMap mapContainerStyle={mapOptions.style} center={mapOptions.center} zoom={mapOptions.zoom} options={mapOptions.options}></GoogleMap> */}
														<iframe title={'RadarBox AirNav Map'} sandbox="allow-scripts allow-same-origin" draggable={'false'} frameBorder="0" scrolling={'no'} marginHeight="0" marginWidth="0" width={'100%'} height={800} src="https://www.radarbox.com/?widget=1&z=5&airport=KATL"></iframe>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};
export default RadarBoxMap;
