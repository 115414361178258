/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { GoogleMap, OverlayView, LoadScript } from '@react-google-maps/api';
import Marker from '../../Maps/Marker';

const AirportMapModal = (props) => {
	const mapLatitude = props !== null && props.modalContent !== '' ? props.modalContent[0].lat : '';
	const mapLongitude = props !== null && props.modalContent !== '' ? props.modalContent[0].lng : '';

	// Map Options
	const mapOptions = {
		// Map Styling
		style: {
			width: '100%',
			height: '350px'
		},

		center: {
			lat: mapLatitude,
			lng: mapLongitude
		},

		// Default Zoom Level
		zoom: 4,

		disableDefaultUI: true,

		options: {
			maxZoom: 18,
			minZoom: 3
		}
	};

	return (
		<Modal className={'airport-list-map-modal'} show={props.modalShow} onHide={props.setModalShow} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>{props.modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
					<GoogleMap mapContainerStyle={mapOptions.style} center={mapOptions.center} zoom={mapOptions.zoom} options={mapOptions.options}>
						<OverlayView position={{ lat: mapLatitude, lng: mapLongitude }} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
							<Marker position={{ lat: mapLatitude, lng: mapLongitude }} type={'dot'} name={props.modalTitle} lat={mapLatitude} lng={mapLongitude} />
						</OverlayView>
					</GoogleMap>
				</LoadScript>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={props.setModalShow}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AirportMapModal;
