/*
 * Copyright 2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const SubmitDialog = (props) => {
	// Set the latest solver date to the current date + 2 weeks
	// # Days (14) * Seconds in Minute (60) * Minutes in Hour (60) * Hours in Day (24) * Milliseconds (1000)
	// Or, 12096e5
	const solverDateRangeDefaults = {
		start: new Date(),
		end: new Date(Date.now() + 14 * (60 * 60 * 24 * 1000))
	};

	const environment = props.environment;

	const solverSpeedOption = ['Fast', 'Standard', 'Best'];

	return (
		<Dialog id={'schedule-submit-dialog'} title={'Please Confirm'} onClose={props.toggleDialog}>
			{environment === 'sandbox' ? <p style={{ textAlign: 'center' }}>Are you sure you want to submit this Sandbox's selected Lift Requests, Assets, and Airports to the Air Logistics Solver for scheduling?</p> : <p style={{ textAlign: 'center' }}>Are you sure you want to submit selected Lift Requests, Assets, and Airports to the Air Logistics Solver for scheduling?</p>}

			<div>
				<p className={'padding-top-25 padding-bottom-5 fontsize-14px'}>
					<strong>Enter the Date Range for the Solver</strong>:
				</p>

				<DateRangePicker defaultValue={solverDateRangeDefaults} onChange={props.handleSolverDateChange} />
			</div>

			<div className={'padding-bottom-15'}>
				<p className={'padding-top-25 padding-bottom-5 fontsize-14px'}>
					<strong>Enter Solver Configuration</strong>:
				</p>
				<DropDownList data={solverSpeedOption} defaultValue={'Fast'} onChange={props.handleSolverSpeedChange} />
			</div>

			<DialogActionsBar>
				<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={props.toggleDialog}>
					<i className={'fa-regular fa-xmark padding-right-10'}></i>
					No
				</button>
				<button className="btn btn-color-blue" title={'Click to Confirm'} onClick={props.submitForScheduling}>
					<i className={'fa-regular fa-check padding-right-10'}></i>
					Yes
				</button>
			</DialogActionsBar>
		</Dialog>
	);
};

export { SubmitDialog };
