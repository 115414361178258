/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

// https://stackblitz.com/edit/react-5xte1s?file=app%2Fmain.jsx
// https://stackblitz.com/edit/kendoreact-r2-2019-date-time-picker-format?file=app%2Fmain.jsx

import * as React from 'react';
import { Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { PDFExport } from '@progress/kendo-react-pdf';
import { FormInput, FormTextArea } from '../KendoFormElements/FormComponents';
import { emailValidator } from '../KendoFormElements/Validators';
import { Field, FieldArray } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';

const commandCell = (onRemove) => (props) => {
	const onClick = React.useCallback(
		(e) => {
			e.preventDefault();
			onRemove(props);
		},
		[onRemove]
	);
	return (
		<td className={'summary-actions-cell'}>
			<button className={'button btn-icon-remove btn-color-red margin-right-0 padding-top-12 padding-bottom-12 k-button k-grid-remove-command'} title={'Click to Remove Row'} onClick={onClick}>
				Remove
			</button>
		</td>
	);
};

const emailCell = (props) => {
	return (
		<td className={'summary-email-cell'}>
			<Field name={`shareEmails[${props.dataIndex}].${props.field}`} placeholder={'E.g. name@domain.com'} component={FormInput} optional={false} validator={emailValidator} />
		</td>
	);
};

// Repeater: Share Grid
const ShareFormGrid = (fieldArrayRenderProps) => {
	const { validationMessage, visited } = fieldArrayRenderProps;
	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			fieldArrayRenderProps.onUnshift({ value: { shareEmail: '' } });
		},
		[fieldArrayRenderProps.onUnshift]
	);
	const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps.onRemove]);

	return (
		<div className={'lift-request-array-grid'}>
			{visited && validationMessage && <Error>{validationMessage}</Error>}
			<Grid data={fieldArrayRenderProps.value}>
				<GridToolbar>
					<button title={'Click to Add New Email'} className={'k-button k-primary'} onClick={onAdd}>
						Add New Email
						<i className={'far fa-plus'}></i>
					</button>
				</GridToolbar>
				<GridColumn field="shareEmail" title="Email" cell={emailCell} />
				<GridColumn cell={commandCell(onRemove)} width="240px" />
			</Grid>
		</div>
	);
};

// USD Currency Formatter
let formatCurrency = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'USD'
});

export const SummaryDetails = (props) => {
	const pdfExportComponent = React.useRef(null);

	const summaryData = () => {
		const requesterFirstName = props.valueGetter('requesterFirstName');
		const requesterLastName = props.valueGetter('requesterLastName');
		const requesterEmail = props.valueGetter('requesterEmail');
		const requesterPhone = props.valueGetter('requester.phoneNumber');
		const requesterDepartmentOrAgency = props.valueGetter('requester.branchOfService');
		const requesterDutyTitle = props.valueGetter('requester.dutyTitle');
		const requesterGrade = props.valueGetter('requester.rank.title');
		const requesterUnit = props.valueGetter('requester.unit') ? props.valueGetter('requester.unit') : '';

		const requesterFormName = props.valueGetter('requestName');

		const priority = props.valueGetter('priority');
		const urgency = props.valueGetter('urgency');

		const missionDescription = props.valueGetter('missionDescription');
		const reasonCommercialUnacceptable = props.valueGetter('justification');

		const departure = props.valueGetter('departure.airport.history[0],icao') !== null && props.valueGetter('departure.airport.history[0],icao') !== undefined ? '(' + props.valueGetter('departure.airport.history[0].icao') + ') ' + props.valueGetter('departure.airport.history[0].name') : '';
		const earliestDepartureDateTime = props.valueGetter('departure.earliest');
		const latestDepartureDateTime = props.valueGetter('departure.latest');

		const arrival = props.valueGetter('arrival.airport.history[0],icao') !== null && props.valueGetter('arrival.airport.history[0],icao') !== undefined ? '(' + props.valueGetter('arrival.airport.history[0].icao') + ') ' + props.valueGetter('arrival.airport.history[0].name') : '';
		const earliestArrivalDateTime = props.valueGetter('arrival.earliest');
		const latestArrivalDateTime = props.valueGetter('arrival.latest');

		const hasPassengers = props.valueGetter('cargo.hasPassengers');
		const hasPallets = props.valueGetter('cargo.hasPallets');

		// Cargo: hasPassengers === true
		const totalNumberOfPax = props.valueGetter('cargo.paxCount');
		const totalCommercialCost = props.valueGetter('commercialCost') !== null ? formatCurrency.format(props.valueGetter('commercialCost')) : '';

		let totalNumberOfPallets = 0;

		// eslint-disable-next-line
		props.cargoData.map((pallet) => {
			if (pallet.count) {
				totalNumberOfPallets += pallet.count;
			}
		});

		return (
			<div className={'form-summary'}>
				<div className={'form-summary-inner bg-white'}>
					<ul className={'form-summary-list'}>
						<li>
							<span className={'sec'}>Your Information (Requester)</span>
							<ul>
								<li>
									<span className={'lbl'}>Name</span>
									<span className={'val'}>
										{requesterFirstName} {requesterLastName}
									</span>
								</li>
								<li>
									<span className={'lbl'}>Email Address</span>
									<span className={'val'}>
										<a href={`mailto:${requesterEmail}`} title={`Send an email to ${requesterEmail}`}>
											{requesterEmail}
										</a>
									</span>
								</li>
								<li>
									<span className={'lbl'}>Phone Number</span>
									<span className={'val'}>{requesterPhone}</span>
								</li>
								<li>
									<span className={'lbl'}>Department or Agency</span>
									<span className={'val'}>{requesterDepartmentOrAgency}</span>
								</li>
								<li>
									<span className={'lbl'}>Duty Title</span>
									<span className={'val'}>{requesterDutyTitle}</span>
								</li>
								<li>
									<span className={'lbl'}>Grade</span>
									<span className={'val'}>{requesterGrade}</span>
								</li>
								<li>
									<span className={'lbl'}>Unit</span>
									<span className={'val'}>
										{requesterUnit.nra} {requesterUnit.uic}
									</span>
								</li>
							</ul>
						</li>
						<li>
							<span className={'sec'}>Form Information</span>
							<ul>
								<li>
									<span className={'lbl'}>Requester Form Name</span>
									<span className={'val'}>{requesterFormName}</span>
								</li>
							</ul>
						</li>
						<li>
							<span className={'sec'}>Priority and Urgency Information</span>
							<ul>
								{priority && (
									<li>
										<span className={'lbl'}>Priority</span>
										<span className={'val'}>{priority.label}</span>
									</li>
								)}

								{urgency && (
									<li>
										<span className={'lbl'}>Urgency</span>
										<span className={'val'}>{urgency.label}</span>
									</li>
								)}
							</ul>
						</li>
						<li>
							<span className={'sec'}>Itinerary Information</span>
							<ul>
								<li>
									<span className={'lbl'}>Complete Mission Description</span>
									<span className={'val'}>{missionDescription}</span>
								</li>
								<li>
									<span className={'lbl'}>Reason for Military Airlift or Reason Commercial Travel Unacceptable</span>
									<span className={'val'}>{reasonCommercialUnacceptable}</span>
								</li>

								{/* Desired Flight Itinerary: Start */}
								<li>
									<span className={'subsec'}>Flight Itinerary: Departure</span>
									<ul>
										<li>
											<span className={'lbl'}>Desired Departure ICAO</span>
											<span className={'val'}>{departure}</span>
										</li>

										<li>
											<span className={'lbl'}>Earliest Departure Date/Time (UTC/Zulu)</span>
											<span className={'val'}>
												<Moment format={'YYYY-MM-DD HH:mm'}>{earliestDepartureDateTime}</Moment>
											</span>
										</li>
										<li>
											<span className={'lbl'}>Latest Departure Date/Time (UTC/Zulu)</span>
											<span className={'val'}>
												<Moment format={'YYYY-MM-DD HH:mm'}>{latestDepartureDateTime}</Moment>
											</span>
										</li>

										{/*
                                        {departureAlternativeAirports &&
                                        <li>
                                            <span className={"lbl"}>Alternate Airports (Airport Code)</span>
                                            <span className={"val"}>
                                                {departureAlternativeAirports.map((rec,i) => (
                                                    <React.Fragment key={i}>
                                                        {i === 0 ? rec.airportCode : `, ${rec.airportCode}`}
                                                    </React.Fragment>
                                                ))}
                                            </span>
                                        </li>
                                        }
                                        */}
									</ul>
								</li>
								{/* Desired Flight Itinerary: End */}

								{/* Arrival Flight Itinerary: Start */}
								<li>
									<span className={'subsec'}>Flight Itinerary: Arrival</span>
									<ul>
										<li>
											<span className={'lbl'}>Desired Arrival ICAO</span>
											<span className={'val'}>{arrival}</span>
										</li>

										<li>
											<span className={'lbl'}>Earliest Arrival Date/Time (UTC/Zulu)</span>
											<span className={'val'}>
												<Moment format={'YYYY-MM-DD HH:mm'}>{earliestArrivalDateTime}</Moment>
											</span>
										</li>
										<li>
											<span className={'lbl'}>Latest Arrival Date/Time (UTC/Zulu)</span>
											<span className={'val'}>
												<Moment format={'YYYY-MM-DD HH:mm'}>{latestArrivalDateTime}</Moment>
											</span>
										</li>

										{/*
                                        {arrivalAlternativeAirports &&
                                        <li>
                                            <span className={"lbl"}>Alternate Airports (Airport Code)</span>
                                            <span className={"val"}>
                                            {arrivalAlternativeAirports.map((rec,i) => (
                                                <React.Fragment key={i}>
                                                    {i === 0 ? rec.airportCode : `, ${rec.airportCode}`}
                                                </React.Fragment>
                                            ))}
                                            </span>
                                        </li>
                                        }
                                        */}
									</ul>
								</li>
								{/* Arrival Flight Itinerary: End */}
							</ul>
						</li>

						{hasPassengers && (
							<li>
								<span className={'sec'}>Cargo: Passenger Information</span>
								<ul>
									<li>
										<span className={'lbl'}>Total Number of PAX</span>
										<span className={'val'}>{totalNumberOfPax}</span>
									</li>
									<li>
										<span className={'lbl'}>Total Commercial Cost for PAX</span>
										<span className={'val'}>{totalCommercialCost}</span>
									</li>
								</ul>
							</li>
						)}

						{hasPallets && props.cargoData && (
							<li>
								<span className={'sec'}>Cargo Information</span>
								<li>
									<span className={'lbl'}>Total number of Cargo Positions</span>
									<span className={'val'}>{totalNumberOfPallets}</span>
								</li>
								{props.cargoData.map((pallet, i) => {
									return (
										<ul>
											<b>
												<span className={'subsec'}>Cargo: {pallet.PalletName}</span>
											</b>
											<li>
												<span className={'lbl'}>Number of Cargo Positions</span>
												<span className={'val'}>{pallet.count}</span>
											</li>
											<li>
												<span className={'lbl'}>
													Cargo Description (
													<b>
														<em>All Weights In Pounds</em>
													</b>
													)
												</span>
												<span className={'val'}>{pallet.description !== undefined ? pallet.description : ''}</span>
											</li>
											<li>
												<span className={'lbl'}>Special Handling Instructions</span>
												<span className={'val'}>{pallet.specialHandlingRequirements !== undefined ? pallet.specialHandlingRequirements : ''}</span>
											</li>
											<li>
												<span className={'lbl'}>Contains Oversized Cargo?</span>
												<span className={'val'}>{pallet.oversize !== undefined ? (pallet.oversize === true ? 'Yes' : 'No') : 'No'}</span>
											</li>
											<li>
												<span className={'lbl'}>Contains Rolling Stock?</span>
												<span className={'val'}>{pallet.rollingStock !== undefined ? (pallet.rollingStock === true ? 'Yes' : 'No') : 'No'}</span>
											</li>
											<li>
												<span className={'lbl'}>Belly Loadable?</span>
												<span className={'val'}>{pallet.bellyLoadable !== undefined ? (pallet.bellyLoadable === true ? 'Yes' : 'No') : 'No'}</span>
											</li>
											<li>
												<span className={'lbl'}>Does cargo contain HAZMAT?</span>
												<span className={'val'} style={{ textTransform: 'capitalize' }}>
													{pallet.hazmat.containsHazmat !== undefined ? (pallet.hazmat.containsHazmat === true ? 'Yes' : 'No') : 'No'}
												</span>
											</li>

											{pallet.hazmat.containsHazmat && (
												<React.Fragment>
													{pallet.hazmat.hazmatCargoes.map((hazmat, i) => {
														return (
															<React.Fragment>
																<span className={'subsec'}>Hazmat Cargo {hazmat.PalletName}</span>
																<li>
																	<span className={'lbl'}>UN Number (Joint Hazardous Material Identification Number)</span>
																	<span className={'val'} style={{ textTransform: 'capitalize' }}>
																		{hazmat.unNumber}
																	</span>
																</li>
																<li>
																	<span className={'lbl'}>Proper Shipping Name</span>
																	<span className={'val'} style={{ textTransform: 'capitalize' }}>
																		{hazmat.shippingName}
																	</span>
																</li>
																<li>
																	<span className={'lbl'}>Proper Shipping Description</span>
																	<span className={'val'} style={{ textTransform: 'capitalize' }}>
																		{hazmat.shippingDescription}
																	</span>
																</li>
																<li>
																	<span className={'lbl'}>Hazard Class and Division</span>
																	<span className={'val'} style={{ textTransform: 'capitalize' }}>
																		{hazmat.hazardClassAndDivision}
																	</span>
																</li>
																<li>
																	<span className={'lbl'}>Explosive Compatibility Group</span>
																	<span className={'val'}>{hazmat.explosiveCompatibilityGroup}</span>
																</li>
																<li>
																	<span className={'lbl'}>Passenger Eligibility Code ('P' Code)</span>
																	<span className={'val'}>{hazmat.passengerEligibilityCode}</span>
																</li>

																<li>
																	<span className={'lbl'}>NET Explosive Weight (as req)</span>
																	<span className={'val'}>{hazmat.netExplosiveWeight} lbs.</span>
																</li>
															</React.Fragment>
														);
													})}
												</React.Fragment>
											)}
										</ul>
									);
								})}
							</li>
						)}
					</ul>
				</div>
			</div>
		);
	};

	return (
		<React.Fragment>
			{/* Summary Results: Start */}
			<Row id={'form-summary'} className={'form-section'}>
				{/* Section Title - Summary: Start */}
				<div className={'col-12 form-section-title lift-request-summary-header'}>
					<h3>Summary</h3>

					<div className="summary-controls">
						<button
							className="btn button btn-summary-pdf"
							onClick={() => {
								if (pdfExportComponent.current) {
									pdfExportComponent.current.save();
								}
							}}>
							Export Summary
							<i className={'fa-solid fa-file-pdf'}></i>
						</button>
					</div>
				</div>
				{/* Section Title - Summary: End */}

				<div className={'col-12'}>
					<PDFExport paperSize="A4" margin="1cm" ref={pdfExportComponent}>
						{summaryData()}
					</PDFExport>
				</div>
			</Row>
			{/* Summary Results: End */}

			{/* Share Lift Request: Start */}
			<Row id={'share-lift-request'} className={'form-section'}>
				{/* Section Title - Share Request: Start */}
				<div className={'col-12 form-section-title share-request'}>
					<h3>Share Lift Request with Other Personnel</h3>
				</div>
				{/* Section Title - Share Request: End */}

				{/* Share Request: Start */}
				<div className={'col-12'}>
					<FieldArray name="shareEmails" component={ShareFormGrid} />
				</div>
				{/* Share Request: End */}
			</Row>
			{/* Share Lift Request: End */}

			{/* Remarks/Additional Comments: Start */}
			<Row id={'remarks-additional-comments'} className={'form-section'}>
				{/* Section Title - Remarks/Additional Comments: Start */}
				<div className={'col-12 form-section-title remarks-additional-comments'}>
					<h3>Remarks/Additional Comments</h3>
				</div>
				{/* Section Title - Remarks/Additional Comments: End */}

				{/* Remarks/Additional Comments: Start */}
				<div className={'col-12'}>
					<Field key={'remarks-additional-comments'} id={'remarks-additional-comments'} name={'comments.text'} label={'Remarks/Additional Comments'} optional={true} component={FormTextArea} />

					{/*
                    <Button variant={"secondary"} className={"btn-form-info"} onClick={() => {

                        // Info: Modal Title
                        handleModalTitle("Remarks/Additional Comments Information");

                        // Info: Modal Content
                        handleModalContent(`
                            <p></p>
                        `);

                        // Show Modal
                        handleModalToggle();
                        }}
                        >
                        <i className={"fa-solid fa-info modal-trigger"}></i>
                        <span className={"sr-only"}>Click to view information about Remarks/Additional Comments</span>
                    </Button>
                    */}
				</div>
				{/* Remarks/Additional Comments: End */}
			</Row>
			{/* Remarks/Additional Comments: End */}
		</React.Fragment>
	);
};
