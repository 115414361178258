/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
const Logout = (props) => {
	const logout = () => {
		props.keycloak.logout();
	};

	return (
		<button className={'btn btn-logout'} title={'Click to Log Out'} onClick={() => logout()}>
			Logout
		</button>
	);
};

export default Logout;
