/*
 * Copyright 2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { useEffect } from 'react';
import { Scheduler, SchedulerItem, TimelineView, MonthView } from '@progress/kendo-react-scheduler';
import { Day } from '@progress/kendo-date-math';
import { ApiAssetsList } from '../../Helpers/Apis';

import { Accordion, AccordionContent, AccordionHeading } from '../../Helpers/Accordion';

import { optionsAssetStatusCodeColors } from '../../../Assets/json/assetStatusColors';
import { ApiAssetBySquadronId, ApiOrgSquadronList } from '../../Helpers/Apis';
import '@progress/kendo-date-math/tz/Etc/UTC';

const AssetSchedule = (props) => {
	const [squadronId, setSquadronId] = React.useState();
	const [squadrons, setSquadrons] = React.useState([]);
	const [assetData, setAssetData] = React.useState([]);
	const [assetTailNumbers, setAssetTailNumbers] = React.useState([]);
	const [assetSchedule, setAssetSchedule] = React.useState([]);

	const itemStatus = (props) => (
		<SchedulerItem
			{...props}
			style={{
				...props.style,
				backgroundColor: `${props.dataItem.color}`,
				color: `${props.dataItem.color === '#f8f0e5' ? '#000000' : '#ffffff'}`
			}}
			className={props.dataItem.status}
		/>
	);

	const assetScheduleGroup = {
		resources: ['Aircraft'],
		orientation: 'vertical'
	};

	/**
	 * Tail Number of Asset for Schedule Grid
	 * @param val - Squadron ID
	 */
	const handleAssetTailNumbers = () => {
		//component expects one object with relevant data in array in object.data
		const tailNumbers = [];
		tailNumbers.push({
			name: 'Aircraft',
			data: [],
			field: 'tailNumber',
			valueField: 'value',
			textField: 'text',
			colorField: 'color'
		});

		// eslint-disable-next-line
		assetData.map((rec, i) => {
			tailNumbers[0].data.push({
				value: rec.tailNumber, //this is linked to tailNumber
				text: `Tail #${rec.tailNumber}`,
				color: '#68d128'
			});
			return tailNumbers;
		});

		setAssetTailNumbers(tailNumbers);
	};

	/*Parse assetData into usable data for timeline */
	const handleAssetSchedule = () => {
		const assetScheduleData = [];

		// Availability and Unavailability
		// eslint-disable-next-line
		assetData.map((rec, i) => {
			// Availability
			// eslint-disable-next-line
			rec.availability.map((y, z) => {
				let randomId = Math.floor(Math.random() * 999999) + 1;

				if (y.start.planned !== null && y.end.planned !== null) {
					assetScheduleData.push({
						id: randomId,
						ownerId: rec.ownerOrg,
						squadronId: rec.squadronId,
						start: new Date(y.start.planned),
						end: new Date(y.end.planned),
						recurrenceRule: null,
						recurrenceID: null,
						recurrenceException: null,
						isAllDay: false,
						title: `${rec.assetType.id} | Available`,
						description: '-',
						color: optionsAssetStatusCodeColors.available,
						status: 'Available',
						tailNumber: rec.tailNumber
					});
				}
			});

			// Unavailability
			// eslint-disable-next-line
			rec.status.map((y, z) => {
				let randomId = Math.floor(Math.random() * 999999) + 1;

				const reasonCode = y.reason.code !== null ? y.reason.code.toLowerCase() : '';
				let statusColor = '';

				if (reasonCode === 'api') {
					statusColor = optionsAssetStatusCodeColors.api;
				} else if (reasonCode === 'apui') {
					statusColor = optionsAssetStatusCodeColors.apui;
				} else if (reasonCode === 'hri') {
					statusColor = optionsAssetStatusCodeColors.hri;
				} else if (reasonCode === 'mx') {
					statusColor = optionsAssetStatusCodeColors.mx;
				} else if (reasonCode === 'spo') {
					statusColor = optionsAssetStatusCodeColors.spo;
				} else if (reasonCode === 'wri') {
					statusColor = optionsAssetStatusCodeColors.wri;
				}

				if (y.start.planned !== null && y.end.planned !== null) {
					assetScheduleData.push({
						id: randomId,
						ownerId: rec.ownerOrg,
						squadronId: rec.squadronId,
						start: new Date(y.start.planned),
						end: new Date(y.end.planned),
						recurrenceRule: null,
						recurrenceID: null,
						recurrenceException: null,
						isAllDay: false,
						title: `${rec.assetType.id} | ${y.reason.code}`,
						description: '-',
						color: statusColor,
						status: reasonCode.toUpperCase(),
						tailNumber: rec.tailNumber
					});
				}
			});
			return assetScheduleData;
		});

		setAssetSchedule(assetScheduleData);
	};

	/* Load all squadrons, takes a long time */
	const selectAllSquadrons = () => {
		let assetDataArr = [];
		ApiAssetsList().then((res) => {
			if (Array.isArray(res)) {
				assetDataArr.push(...res);
			} else {
				assetDataArr.push(res);
			}
			setAssetData(assetDataArr);
			setSquadronId(-1);
		});
	};

	/**
	 * Fetch Squadron List Data
	 */
	const fetchSquadronListData = () => {
		ApiOrgSquadronList().then((res) => {
			setSquadrons(res);
		});
	};

	/**
	 * Fetch Squadron Assets By ID
	 * @param id
	 */
	const fetchSquadronAssetsByIdData = (id) => {
		ApiAssetBySquadronId(id).then((res) => {
			setAssetData(res);
			setSquadronId(id);
		});
	};

	//Initial render timeline
	useEffect(() => {
		fetchSquadronListData();
		setSquadronId(1);
		fetchSquadronAssetsByIdData(1);
	}, []);

	useEffect(() => {
		handleAssetSchedule();
		handleAssetTailNumbers();
	}, [assetData]);

	return (
		<div className={'asset-scheduler-wrapper'}>
			<div className={'squadron-selector'}>
				<div className={'squadron-selector-inner'}>
					<ul className={'squadron-selector-list'}>
						<li className={'squadron-selector-item-header'}>Squadrons</li>

						<li id={`squadron-selector-item-all`} className={'squadron-selector-item'}>
							<Accordion>
								<AccordionHeading>
									<div className={`heading-box ${squadronId === -1 ? 'selected' : ''}`}>
										All Squadrons (SLOW)
										<button
											className={`btn-view-squadron-schedule${squadronId === -1 ? '-selected' : ''}`}
											title={'Click to View the Schedule for all Squadrons'}
											onClick={() => {
												selectAllSquadrons();
											}}>
											<span className={'sr-only'}>View Squadron Assets</span>
											<i className="fa-solid fa-calendar-days"></i>
										</button>
									</div>
								</AccordionHeading>
							</Accordion>
						</li>

						{squadrons.map((rec, i) => {
							return (
								<li key={rec.id} id={`squadron-selector-item-${rec.id}`} className={'squadron-selector-item'}>
									<button
										className={`btn-view-squadron-schedule${rec.id === squadronId ? '-selected' : ''}`}
										type={'button'}
										title={`Click to View the Schedule for VR-${rec.squadronDesignation}`}
										onClick={() => {
											fetchSquadronAssetsByIdData(rec.id);
										}}>
										<span className={'sr-only'}>View Squadron Assets</span>
										<i className="fa-solid fa-calendar-days"></i>
									</button>
									<Accordion>
										<AccordionHeading>
											<div className={`heading-box ${rec.id === squadronId ? 'selected' : ''}`}>
												<h4 className="heading">
													{rec.squadronDesignation} {rec.id === squadronId ? 'selected' : ''}
												</h4>
											</div>
										</AccordionHeading>
										<AccordionContent>
											<p>
												<strong>Fixed Wing Squadron</strong>:<br />
												{rec.fixedWingSquadron}
											</p>
											<p>
												<strong>Squadron Designation</strong>:<br />
												{rec.squadronDesignation}
											</p>
											<p>
												<strong>Nickname</strong>:<br />
												{rec.nickname}
											</p>
											<p>
												<strong>Aircraft</strong>:<br />
												{rec.aircraft}
											</p>
											<p>
												<strong>Operational &amp; Administrative Commander</strong>:<br />
												{rec.operationalAndAdministrativeCommander}
											</p>
											<p>
												<strong>SquadronLineage</strong>:<br />
												{rec.squadronLineage}
											</p>
											<p>
												<strong>Notes</strong>:<br />
												{rec.notes}
											</p>
										</AccordionContent>
									</Accordion>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
			<Scheduler
				editable={{
					edit: false
				}}
				data={assetSchedule}
				resources={assetTailNumbers}
				timezone="Etc/UTC"
				group={assetScheduleGroup}>
				<TimelineView
					style={{ ...props.style, height: 'auto' }}
					timeHeaderCell={() => {
						'';
					}}
					title="Days"
					item={itemStatus}
					numberOfDays={30}
					columnWidth={200}
					slotDuration={1440}
					slotDivisions={1}
					startTime={'00:00'}
					endTime={'23:59'}
					workDayStart={'00:00'}
					workDayEnd={'23:59'}
					workWeekStart={Day.Sunday}
					workWeekEnd={Day.Monday}
					showWorkHours={false}
				/>
				<MonthView />
				{/*<AgendaView />*/}
			</Scheduler>
		</div>
	);
};

export default AssetSchedule;
