/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import moment from 'moment-timezone';

export const getDates = (startDate, stopDate) => {
	var dateArray = [];
	var currentDate = moment(startDate);

	// eslint-disable-next-line
	var stopDate = moment(stopDate);
	while (currentDate <= stopDate) {
		dateArray.push(moment(currentDate).utc().toISOString());
		currentDate = moment(currentDate).add(1, 'days');
	}
	return dateArray;
};

export const getAllDatesBetween = (startDate, endDate, steps = 1) => {
	const dateArray = [];
	let currentDate = new Date(startDate);

	while (currentDate <= new Date(endDate)) {
		dateArray.push(new Date(currentDate).toISOString());
		currentDate.setUTCDate(currentDate.getUTCDate() + steps);
	}

	return dateArray;
};
