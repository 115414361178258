/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import { filterBy } from '@progress/kendo-data-query';

export const filterData = (filter, fullData) => {
	const data = fullData.slice();
	return filterBy(data, filter);
};

export const filterChange = (event, setData, setDataLoading, fullData, timeout) => {
	const delay = 500;
	clearTimeout(timeout.current);
	timeout.current = setTimeout(() => {
		setData(filterData(event.filter, fullData));
		setDataLoading(false);
	}, delay);
	setDataLoading(true);
};
