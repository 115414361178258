/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

export const scheduleData = [
	// Mission ID 7581
	{
		id: 7581, // Last 5 digits on mission id
		title: 'Mission 7581', // "Mission" + Last 5 digits on mission id
		squadron: 'vr-51',
		tailnumber: '830',
		orderId: 0, // Order, which can be set as loop counter
		start: new Date('2023-05-10T00:00:00.000Z'), // Start date and time of first leg
		end: new Date('2023-05-12T05:30:00.000Z'), // End date of last leg in mission
		percentComplete: 1.0, // keep as 1.0. Not presently used, however, for this top bar.
		isExpanded: false, // keep as false. If you wish to have all missions expanded, add id in MultiMissionGanttChart.js, "const [expandedState, setExpandedState]"
		children: [
			// Leg ID 13
			{
				id: 13, // Should be id of leg
				title: 'KJFK to KDAL', // Used as title of leg bar in gantt chart
				orderId: 1, // Should be set using counter of loop for loading legs and data for the gantt chart
				squadron: 'vr-51',
				tailnumber: '830',
				start: new Date('2023-05-10T00:00:00.000Z'), // Start date time of this leg
				end: new Date('2023-05-10T10:00:00.000Z'), // End date time of this leg
				percentComplete: 0.0, // All legs should be set to 0.0. Value of 0.0 sets color of leg bar.
				isExpanded: false, // Not presently used. Will be used if children of this item set
				isLocked: true, // Locking is currently in discussion. Not presently used
				locationFrom: 'KJFK', // ICAO of location from. Not presently used
				locationTo: 'KDAL', // ICAO of location to. Not presently used
				fromLat: 40.6413, // Latitude of location from. Not presently used
				fromLon: -73.7781, // Longitude of location from. Not presently used
				toLat: 32.8481, // Latitude of location to. Not presently used
				toLon: -96.8512, // Longitude of location to. Not presently used
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			},

			// Stop. Must have a unique ID
			{
				id: 8695, // Perhaps generate a unique ID? Whatever you want to do to make this unique. We don't currently track stops.
				title: 'STOP', // Always use this string for stops
				orderId: 2, // Should be set using counter of loop for loading legs and data for the gantt chart
				start: new Date('2023-05-10T10:00:00.000Z'), // Set as end date time of leg before this stop
				end: new Date('2023-05-11T00:00:00.000Z'), // Set as start date time of leg after this stop
				percentComplete: 1.0, // All "stops" should be set to 1.0. Value of 1.0 sets color of stop bar.
				isExpanded: false, // No reason for children of a stop. However, not presently used
				isLocked: true, // No locking/unclocking will ever be done for stops I would think. This would always be true. Not presently used
				stopLocation: 'KDAL', // Location where stop taking place
				stopLat: 32.8481, // Latitude of current location. Not presently used
				stopLon: -96.8512, // Longitude of current location. Not presently used
				details: 'Stopped.' // For now, use this string for stops
			},

			// Leg ID 14
			{
				id: 14,
				title: 'KDAL to KABI',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 3,
				start: new Date('2023-05-11T00:00:00.000Z'),
				end: new Date('2023-05-11T08:00:00.000Z'),
				percentComplete: 0.0,
				isExpanded: true,
				isLocked: false,
				locationFrom: 'KDAL',
				locationTo: 'KABI',
				fromLat: 32.8481,
				fromLon: -96.8512,
				toLat: 32.4119,
				toLon: -99.68,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			},

			// Stop. Must have a unique ID
			{
				id: 6281,
				title: 'STOP',
				orderId: 4,
				start: new Date('2023-05-11T08:00:00.000Z'),
				end: new Date('2023-05-11T20:00:00.000Z'),
				percentComplete: 1.0,
				isExpanded: false,
				isLocked: true,
				stopLocation: 'KABI',
				stopLat: 32.8481,
				stopLon: -96.8512,
				details: 'Stopped.'
			},

			// Leg ID 91
			{
				id: 91,
				title: 'KABI to KMSY',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 5,
				start: new Date('2023-05-11T20:00:00.000Z'),
				end: new Date('2023-05-12T05:30:00.000Z'),
				percentComplete: 0.0,
				isExpanded: true,
				isLocked: false,
				locationFrom: 'KABI',
				locationTo: 'KMSY',
				fromLat: 32.8481,
				fromLon: -96.8512,
				toLat: 32.4119,
				toLon: -99.68,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			}
		]
	},

	// Mission ID 5467
	{
		id: 5467,
		title: 'Mission 5467',
		squadron: 'vr-51',
		tailnumber: '830',
		orderId: 1,
		start: new Date('2023-05-11T00:00:00.000Z'),
		end: new Date('2023-05-15T05:00:00.000Z'),
		percentComplete: 1.0,
		isExpanded: false,
		children: [
			// Leg ID 15
			{
				id: 15,
				title: 'KABI to KBHM',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 1,
				start: new Date('2023-05-11T00:00:00.000Z'),
				end: new Date('2023-05-11T18:00:00.000Z'),
				percentComplete: 0.0,
				isExpanded: true,
				isLocked: false,
				locationFrom: 'KABI',
				locationTo: 'KBHM',
				fromLat: 32.4119,
				fromLon: -99.68,
				toLat: 33.5624,
				toLon: -86.7541,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			},

			// Stop. Must have a unique ID
			{
				id: 6893,
				title: 'STOP',
				orderId: 2,
				start: new Date('2023-05-11T18:00:00.000Z'),
				end: new Date('2023-05-12T05:00:00.000Z'),
				percentComplete: 1.0,
				isExpanded: false,
				isLocked: true,
				stopLocation: 'KBHM',
				stopLat: 32.8481,
				stopLon: -96.8512,
				details: 'Stopped.'
			},

			// Leg ID 16
			{
				id: 16,
				title: 'KBHM to KLAX',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 3,
				start: new Date('2023-05-12T05:00:00.000Z'),
				end: new Date('2023-05-13T01:00:00.000Z'),
				percentComplete: 0.0,
				isExpanded: true,
				isLocked: false,
				locationFrom: 'KBHM',
				locationTo: 'KLAX',
				fromLat: 33.5624,
				fromLon: -86.7541,
				toLat: 33.9416,
				toLon: -118.4085,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			},

			// Stop. Must have a unique ID
			{
				id: 2569,
				title: 'STOP',
				orderId: 4,
				start: new Date('2023-05-13T01:00:00.000Z'),
				end: new Date('2023-05-13T16:00:00.000Z'),
				percentComplete: 1.0,
				isExpanded: false,
				isLocked: true,
				stopLocation: 'KLAX',
				stopLat: 32.8481,
				stopLon: -96.8512,
				details: 'Stopped.'
			},

			// Leg ID 718
			{
				id: 718,
				title: 'KBHM to KLAX',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 5,
				start: new Date('2023-05-13T16:00:00.000Z'),
				end: new Date('2023-05-13T20:00:00.000Z'),
				percentComplete: 0.0,
				isExpanded: true,
				isLocked: false,
				locationFrom: 'KBHM',
				locationTo: 'KLAX',
				fromLat: 33.5624,
				fromLon: -86.7541,
				toLat: 33.9416,
				toLon: -118.4085,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			},

			// Stop. Must have a unique ID
			{
				id: 3645,
				title: 'STOP',
				orderId: 6,
				start: new Date('2023-05-13T20:00:00.000Z'),
				end: new Date('2023-05-14T09:00:00.000Z'),
				percentComplete: 1.0,
				isExpanded: false,
				isLocked: true,
				stopLocation: 'KLAX',
				stopLat: 32.8481,
				stopLon: -96.8512,
				details: 'Stopped.'
			},

			// Leg ID 7289
			{
				id: 7289,
				title: 'KBHM to KLAX',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 7,
				start: new Date('2023-05-14T09:00:00.000Z'),
				end: new Date('2023-05-15T05:00:00.000Z'),
				percentComplete: 0.0,
				isExpanded: true,
				isLocked: false,
				locationFrom: 'KBHM',
				locationTo: 'KLAX',
				fromLat: 33.5624,
				fromLon: -86.7541,
				toLat: 33.9416,
				toLon: -118.4085,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			}
		]
	},

	// Mission ID 3567
	{
		id: 3567,
		title: 'Mission 3567',
		squadron: 'vr-51',
		tailnumber: '830',
		orderId: 2,
		start: new Date('2023-05-16T00:00:00.000Z'),
		end: new Date('2023-05-17T09:30:00.000Z'),
		percentComplete: 1.0,
		isExpanded: false,
		children: [
			// Leg ID 11
			{
				id: 11,
				title: 'KIAD to KMIA',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 1,
				start: new Date('2023-05-16T00:00:00.000Z'),
				end: new Date('2023-05-16T14:00:00.000Z'),
				percentComplete: 0.0,
				isExpanded: false,
				isLocked: false,
				locationFrom: 'KIAD',
				locationTo: 'KMIA',
				fromLat: 38.9531,
				fromLon: -77.4565,
				toLat: 25.7959,
				toLon: -80.2871,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			},

			// Stop. Must have a unique ID
			{
				id: 365,
				title: 'STOP',
				orderId: 2,
				start: new Date('2023-05-16T14:00:00.000Z'),
				end: new Date('2023-05-16T22:00:00.000Z'),
				percentComplete: 1.0,
				isExpanded: false,
				isLocked: true,
				stopLocation: 'KMIA',
				stopLat: 32.8481,
				stopLon: -96.8512,
				details: 'Stopped.'
			},

			// Leg ID 12
			{
				id: 12,
				title: 'KMIA to KJFK',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 3,
				start: new Date('2023-05-16T22:00:00.000Z'),
				end: new Date('2023-05-17T09:30:00.000Z'),
				percentComplete: 0.0,
				isExpanded: false,
				isLocked: false,
				locationFrom: 'KMIA',
				locationTo: 'KJFK',
				fromLat: 25.7959,
				fromLon: -80.2871,
				toLat: 40.6413,
				toLon: -73.7781,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			}
		]
	},

	// Mission ID 9680
	{
		id: 9680,
		title: 'Mission 9680',
		squadron: 'vr-51',
		tailnumber: '830',
		orderId: 3,
		start: new Date('2023-05-16T00:00:00.000Z'),
		end: new Date('2023-05-18T01:00:00.000Z'),
		percentComplete: 1.0,
		isExpanded: false,
		children: [
			// Leg ID 17
			{
				id: 17,
				title: 'KLAX to KMSY',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 1,
				start: new Date('2023-05-16T00:00:00.000Z'),
				end: new Date('2023-05-17T00:00:00.000Z'),
				percentComplete: 0.0,
				isExpanded: true,
				isLocked: false,
				locationFrom: 'KLAX',
				locationTo: 'KMSY',
				fromLat: 33.9416,
				fromLon: -118.4085,
				toLat: 29.9911,
				toLon: -90.2592,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			},

			// Stop. Must have a unique ID
			{
				id: 6894,
				title: 'STOP',
				orderId: 2,
				start: new Date('2023-05-17T00:00:00.000Z'),
				end: new Date('2023-05-17T15:00:00.000Z'),
				percentComplete: 1.0,
				isExpanded: false,
				isLocked: true,
				stopLocation: 'KMSY',
				stopLat: 32.8481,
				stopLon: -96.8512,
				details: 'Stopped.'
			},

			// Leg ID 18
			{
				id: 18,
				title: 'KMSY to KGNV',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 3,
				start: new Date('2023-05-17T15:00:00.000Z'),
				end: new Date('2023-05-18T01:00:00.000Z'),
				percentComplete: 0.0,
				isExpanded: true,
				isLocked: true,
				locationFrom: 'KMSY',
				locationTo: 'KGNV',
				fromLat: 29.9911,
				fromLon: -90.2592,
				toLat: 29.6865,
				toLon: -82.2767,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			}
		]
	},

	// Mission ID 2456
	{
		id: 2456,
		title: 'Mission 2456',
		squadron: 'vr-51',
		tailnumber: '830',
		orderId: 4,
		start: new Date('2023-05-18T00:00:00.000Z'),
		end: new Date('2023-05-18T11:00:00.000Z'),
		percentComplete: 1.0,
		isExpanded: false,
		children: [
			// Leg ID 19
			{
				id: 19,
				title: 'KGNV to KHNL',
				squadron: 'vr-51',
				tailnumber: '830',
				orderId: 8,
				start: new Date('2023-05-18T00:00:00.000Z'),
				end: new Date('2023-05-18T11:00:00.000Z'),
				percentComplete: 0,
				isExpanded: true,
				isLocked: true,
				locationFrom: 'KGNV',
				locationTo: 'KHNL',
				fromLat: 29.6865,
				fromLon: -82.2767,
				toLat: 21.3186,
				toLon: -157.9253,
				details: 'Praesent ut volutpat nibh. Integer sem leo, egestas in imperdiet quis, malesuada eu nunc.'
			}
		]
	}
];

export const scheduleDependencyData = [];
