/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

const generateId = (data) => data.reduce((acc, current) => Math.max(acc, current.EntryID), 0) + 1;

export const insertItem = (item, data) => {
	item.EntryID = generateId(data);
	item.inEdit = false;
	data.unshift(item);
	return data;
};

export const getItems = (data) => {
	return data;
};

export const updateItem = (item, data) => {
	let index = data.findIndex((record) => record.EntryID === item.EntryID);
	data[index] = item;
	return data;
};

export const deleteItem = (item, data) => {
	let index = data.findIndex((record) => record.EntryID === item.EntryID);
	data.splice(index, 1);
	return data;
};
