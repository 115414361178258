/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

// Toggle: Yes/No
export const yesNo = [
	{
		label: 'No',
		value: 'no'
	},
	{
		label: 'Yes',
		value: 'yes'
	}
];

// Lift Request Priorities
export const priorities = ['Priority 1', 'Priority 2', 'Priority 3'];

// Lift Request Priority of Travel
export const priorityOfTravel = [
	{
		label: 'Airlift in direct support of operational forces engaged in combat, contingency, or peacekeeping operations directed by the national command authorities or for emergency lifesaving purposes.',
		value: 1,
		state: 'Emergency',
		notes: 'Airlift, once NALO validated, is prioritized for immediate scheduling and lock at expense of efficiency. Less-than-truckload (LTL) authorized after NALO validation.'
	},
	{
		label: 'Required use airlift or airlift requirements with compelling operations considerations making commercial transportation unacceptable. Mission cannot be satisfied by any other mode of travel.',
		value: 2,
		state: 'Required',
		notes: 'NALO specifies LTL or Full Truckload (FTL).'
	},
	{
		label: 'Official business airlift which is validated to be more cost effective than commercial air travel when supported by military aircraft.',
		value: 3,
		state: 'Cost Effective/Space Available',
		notes: 'FTL is default (combine lift request to optimize).'
	}
];

export const naloPriorityOfTravel = [
	{
		label: 'P1 - Airlift in direct support of operational forces engaged in combat, contingency, or peacekeeping operations directed by the national command authorities or for emergency lifesaving purposes.',
		value: 1,
		state: 'Emergency',
		notes: 'Airlift, once NALO validated, is prioritized for immediate scheduling and lock at expense of efficiency. Less-than-truckload (LTL) authorized after NALO validation.'
	},
	{
		label: 'P2 - Required use airlift or airlift requirements with compelling operations considerations making commercial transportation unacceptable. Mission cannot be satisfied by any other mode of travel.',
		value: 2,
		state: 'Required',
		notes: 'NALO specifies LTL or Full Truckload (FTL).'
	},
	{
		label: 'P3 - Official business airlift which is validated to be more cost effective than commercial air travel when supported by military aircraft.',
		value: 3,
		state: 'Cost Effective/Space Available',
		notes: 'FTL is default (combine lift request to optimize).'
	}
];

// Lift Request Urgency of Travel
export const urgencyOfTravel = [
	{
		label: 'Direct support of, or alerted for support of operational forces engaged in general war or national contingency operations.',
		value: 1,
		state: 'Combat',
		notes: 'Airlift, once NALO validated, is prioritized for immediate scheduling and lock at expense of efficiency. Less-than-truckload (LTL) authorized after NALO validation.'
	},
	{
		label: 'Direct support of lifesaving operations or operational forces deployed or preparing for mobilization.',
		value: 2,
		state: 'Liftsaving or Operational',
		notes: 'Airlift, once NALO validated, is prioritized for immediate scheduling and lock at expense of efficiency. Less-than-truckload (LTL) authorized after NALO validation.'
	},
	{
		label: 'Direct support of authorized and urgent humanitarian operations.',
		value: 3,
		state: 'Humanitarian',
		notes: 'Airlift, once NALO validated, is prioritized for immediate scheduling and lock at expense of efficiency. Less-than-truckload (LTL) authorized after NALO validation.'
	},
	{
		label: 'Not fulfilling a higher Urgency (1,2,3). If Airlift is not supported exactly as requested, the resulting impact would critically affect the outcome of unit requirements.',
		value: 4,
		state: 'Critical',
		notes: 'NALO specifies LTL or Full Truckload (FTL).'
	},
	{
		label: 'Not fulfilling a higher Urgency (1,2,3,4). If Airlift is not fulfilled, the resulting impact would critically affect the outcome of unit requirements. Changes or consolidation with other requests would not affect unit requirements.',
		value: 5,
		state: 'Priority',
		notes: 'FTL is default (combine lift request to optimize).'
	},
	{
		label: 'Not fulfilling a higher Urgency (1,2,3,4,5). Scheduled as part of an organization’s normal routine, or travel that qualifies for NALO transport on a cost effective basis. Changes or consolidation with other requests would not affect unit requirements.',
		value: 6,
		state: 'Routine',
		notes: 'FTL is default (combine lift request to optimize).'
	}
];

export const naloUrgencyOfTravel = [
	{
		label: '1 - Direct support of, or alerted for support of operational forces engaged in general war or national contingency operations.',
		value: 1,
		state: 'Combat',
		notes: 'Airlift, once NALO validated, is prioritized for immediate scheduling and lock at expense of efficiency. Less-than-truckload (LTL) authorized after NALO validation.'
	},
	{
		label: '2 - Direct support of lifesaving operations or operational forces deployed or preparing for mobilization.',
		value: 2,
		state: 'Liftsaving or Operational',
		notes: 'Airlift, once NALO validated, is prioritized for immediate scheduling and lock at expense of efficiency. Less-than-truckload (LTL) authorized after NALO validation.'
	},
	{
		label: '3 - Direct support of authorized and urgent humanitarian operations.',
		value: 3,
		state: 'Humanitarian',
		notes: 'Airlift, once NALO validated, is prioritized for immediate scheduling and lock at expense of efficiency. Less-than-truckload (LTL) authorized after NALO validation.'
	},
	{
		label: '4 - Not fulfilling a higher Urgency (1,2,3). If Airlift is not supported exactly as requested, the resulting impact would critically affect the outcome of unit requirements.',
		value: 4,
		state: 'Critical',
		notes: 'NALO specifies LTL or Full Truckload (FTL).'
	},
	{
		label: '5 - Not fulfilling a higher Urgency (1,2,3,4). If Airlift is not fulfilled, the resulting impact would critically affect the outcome of unit requirements. Changes or consolidation with other requests would not affect unit requirements.',
		value: 5,
		state: 'Priority',
		notes: 'FTL is default (combine lift request to optimize).'
	},
	{
		label: '6 - Not fulfilling a higher Urgency (1,2,3,4,5). Scheduled as part of an organization’s normal routine, or travel that qualifies for NALO transport on a cost effective basis. Changes or consolidation with other requests would not affect unit requirements.',
		value: 6,
		state: 'Routine',
		notes: 'FTL is default (combine lift request to optimize).'
	}
];

// Cargo/Passenger Toggle Selection
export const cargoPassengersSelection = [
	{
		label: 'Passengers',
		value: 'PASSENGERS'
	},
	{
		label: 'Pallets',
		value: 'PALLETS'
	}
];
