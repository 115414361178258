/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useState } from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { descriptionValidator, requiredValidator, fourDigitNumericInputValidator } from '../../App/KendoFormElements/Validators';
import { FormComboBox, FormInput, FormTextArea } from '../../App/KendoFormElements/FormComponents';
import { optionsExplosiveCompatibilityGroups, optionsPassengerEligibilityCode } from '../../../Assets/json/formOptions';
import { Button } from '@progress/kendo-react-buttons';
import { Row } from 'react-bootstrap';
import { htmlRender } from '../../Helpers/ContentFormatting';
import { PalletCommandButtonsCell } from '../TableHelpers/CommandCell';
import LiftRequestTooltipModal from '../../App/Modals/LiftRequestTooltipModal';
const editField = 'inEdit';

export const DetailComponent = (props) => {
	const dataItem = props.dataItem;
	const update = props.update;
	const edit = props.edit;
	const remove = props.remove;
	const add = props.add;
	const discard = props.discard;
	const cancel = props.cancel;
	const allowedToSubmit = props.allowedToSubmit;
	const [hazmatCargoDescCount, setHazmatCargoDescCount] = React.useState('');
	const [toggleModal, setToggleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalRef, setModalRef] = useState('');
	const [modalContent, setModalContent] = useState('');
	const hazmatShippingNameAnchor = React.useRef(null);
	const unNumberAnchor = React.useRef(null);
	const passengerEligibilityCodeAnchor = React.useRef(null);
	const hazmatHazardClassAnchor = React.useRef(null);
	const hazmatProperShippingDescriptionAnchor = React.useRef(null);

	const hazmatCargoDescMin = 50;

	const handleCargoDescCount = (e) => {
		props.editDetailItem(dataItem, e.value, 'shippingDescription');
		setHazmatCargoDescCount(e.value);
	};

	const handleModalToggle = () => {
		setToggleModal(!toggleModal);
	};

	const handleModalRef = (val) => {
		setModalRef(val);
	};

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	const CommandCell = (props) => <PalletCommandButtonsCell {...props} allowedToSubmit={allowedToSubmit} edit={edit} remove={remove} add={add} discard={discard} update={update} cancel={cancel} editField={editField} dataItem={dataItem} />;

	return (
		<React.Fragment>
			<Form
				initialValues={dataItem}
				className={'dialog-form'}
				render={(renderProps) => (
					<FormElement>
						<Row>
							<div className={'col-6 has-info'}>
								<div className={'form-group'}>
									<Field name={'unNumber'} label={'UN Number (Joint Hazardous Material Identification Number)'} placeholder={'United Nations Number...'} component={FormInput} onChange={(e) => props.editDetailItem(dataItem, e.value, 'unNumber')} readOnly={!props.dataItem.inEdit} validator={fourDigitNumericInputValidator} />

									{/* Infotip: Start */}
									<Button
										type={'button'}
										variant={'secondary'}
										className={'btn-form-info'}
										id={'unNumberInfo'}
										name={'unNumberInfo'}
										ref={unNumberAnchor}
										onClick={() => {
											// ref: modal anchor
											handleModalRef(unNumberAnchor);

											// Info: Modal Title
											handleModalTitle('HAZMAT UN Number Information');

											// Info: Modal Content
											handleModalContent(`
											<p>A UN number (<a href="https://en.wikipedia.org/wiki/United_Nations" target="_blank" rel="noreferrer">United Nations</a> number) is a four-digit number<br/> that identifies <a href="https://en.wikipedia.org/wiki/Hazardous_materials" target="_blank" rel="noreferrer">hazardous materials</a>, and articles (such as<br/>
											<a href="https://en.wikipedia.org/wiki/Explosive" target="_blank" rel="noreferrer">explosives</a>, <a href="https://en.wikipedia.org/wiki/Flammable_liquid" target="_blank" rel="noreferrer">flammable liquids</a>, <a href="https://en.wikipedia.org/wiki/Oxidizer" target="_blank" rel="noreferrer">oxidizers</a>,
											<a href="https://en.wikipedia.org/wiki/Toxic" target="_blank" rel="noreferrer">toxic</a> liquids, etc.).<br/> Some <a href="https://en.wikipedia.org/wiki/Hazardous_substances" target="_blank" rel="noreferrer">hazardous substances</a> have their own UN numbers<br/>
											(e.g. <a href="https://en.wikipedia.org/wiki/Acrylamide" target="_blank" rel="noreferrer">acrylamide</a> has UN 2074), while sometimes groups of<br/> <a href="https://en.wikipedia.org/wiki/Chemical_substance" target="_blank" rel="noreferrer">chemicals</a> or products with similar properties receive a common<br/> UN number
											(e.g. flammable liquids, not otherwise specified,<br/> have UN 1993).</p>
									`);

											// Show Modal
											handleModalToggle();
										}}>
										<i className={'fa-solid fa-info modal-trigger'}></i>
										<span className={'sr-only'}>Click to view information about XXXXX</span>
									</Button>
									{/* Infotip: End */}
								</div>
							</div>
							<div className={'col-6 has-info'}>
								<div className={'form-group'}>
									<Field name={'shippingName'} value={dataItem.shippingName} label={'Proper Shipping Name'} placeholder={''} component={FormInput} validator={requiredValidator} onChange={(e) => props.editDetailItem(dataItem, e.value, 'shippingName')} readOnly={!props.dataItem.inEdit} />

									{/* Infotip: Start */}
									<Button
										type={'button'}
										variant={'secondary'}
										className={'btn-form-info'}
										id={'unNumberInfo'}
										name={'unNumberInfo'}
										ref={hazmatShippingNameAnchor}
										onClick={() => {
											// ref: modal anchor
											handleModalRef(hazmatShippingNameAnchor);

											// Info: Modal Title
											handleModalTitle('HAZMAT Shipping Name Information');

											// Info: Modal Content
											handleModalContent(`
											<p>For each listed material, the <a href="https://www.ecfr.gov/current/title-49/subtitle-B/chapter-I/subchapter-C/part-172/subpart-B/section-172.101" target="_blank" rel="noreferrer">Hazardous Materials Table</a>
											identifies<br/> the hazard class, and gives the proper shipping name or directs the<br/> user to the preferred proper shipping name.</p>
										`);

											// Show Modal
											handleModalToggle();
										}}>
										<i className={'fa-solid fa-info modal-trigger'}></i>
										<span className={'sr-only'}>Click to view information about XXXXX</span>
									</Button>
									{/* Infotip: End */}
								</div>
							</div>
						</Row>

						<div className={'col-12 has-info'}>
							<div className={'form-group'}>
								<Field key={'hazmatProperShippingDescription'} id={'hazmatProperShippingDescription'} name={'shippingDescription'} label={'Proper Shipping Description'} placeholder={''} component={FormTextArea} hint={`Minimum ${hazmatCargoDescMin} characters. current: ${hazmatCargoDescCount.length}`} validator={descriptionValidator} onChange={handleCargoDescCount} readOnly={!props.dataItem.inEdit} />

								{/* Infotip: Start */}
								<Button
									type={'button'}
									variant={'secondary'}
									className={'btn-form-info'}
									id={'unNumberInfo'}
									name={'unNumberInfo'}
									ref={hazmatProperShippingDescriptionAnchor}
									onClick={() => {
										// ref: modal anchor
										handleModalRef(hazmatProperShippingDescriptionAnchor);

										// Info: Modal Title
										handleModalTitle('HAZMAT Shipping Description Information');

										// Info: Modal Content
										handleModalContent(`
										<p>A complete description or copy of the materials used to train, including<br/> the title and date of the materials.
										Reference AFMAN 24-604:<br/> <a href="https://www.ustranscom.mil/dtr/part-ii/dtr_part_ii_204.pdf" target="_blank" rel="noreferrer">Hazardous Material, Part II, Chapter 204 (ustranscom.mil)</a></p>
									`);

										// Show Modal
										handleModalToggle();
									}}>
									<i className={'fa-solid fa-info modal-trigger'}></i>
									<span className={'sr-only'}>Click to view information about XXXXX</span>
								</Button>
								{/* Infotip: End */}
							</div>
						</div>

						<Row>
							<div className={'col-4 has-info'}>
								<div className={'form-group'}>
									<Field key={'hazmatHazardClass'} min={0} id={'hazmatHazardClass'} name={'hazardClassAndDivision'} label={'Hazard Class and Division'} placeholder={''} component={FormInput} type={'number'} validator={requiredValidator} onChange={(e) => props.editDetailItem(dataItem, e.value, 'hazardClassAndDivision')} readOnly={!props.dataItem.inEdit} />

									{/* Infotip: Start */}
									<Button
										type={'button'}
										variant={'secondary'}
										className={'btn-form-info'}
										id={'unNumberInfo'}
										name={'unNumberInfo'}
										ref={hazmatHazardClassAnchor}
										onClick={() => {
											// ref: modal anchor
											handleModalRef(hazmatHazardClassAnchor);

											// Info: Modal Title
											handleModalTitle('HAZMAT Hazard Class and Division Information');

											// Info: Modal Content
											handleModalContent(`
											<p>Reference: AFMAN 24-604 paragraph A4.2: <a href="https://static.e-publishing.af.mil/production/1/af_a4/publication/afman24-604/afman24-604.pdf" target="_blank" rel="noreferrer">Classifying Hazardous Materials</a></p>
									`);

											// Show Modal
											handleModalToggle();
										}}>
										<i className={'fa-solid fa-info modal-trigger'}></i>
										<span className={'sr-only'}>Click to view information about XXXXX</span>
									</Button>
									{/* Infotip: End */}
								</div>
							</div>
							<div className={'col-4'}>
								<div className={'form-group'}>
									<Field key={'explosiveCompatibilityGroup'} id={'explosiveCompatibilityGroup'} name={'explosiveCompatibilityGroup'} label={'Explosive Compatibility Group'} placeholder={'Explosive Compatibility Groups...'} component={FormComboBox} data={optionsExplosiveCompatibilityGroups} onChange={(e) => props.editDetailItem(dataItem, e.value, 'explosiveCompatibilityGroup')} disabled={!props.dataItem.inEdit} />
								</div>
							</div>
							<div className={'col-4 has-info'}>
								<div className={'form-group'}>
									<Field key={'passengerEligibilityCode'} id={'passengerEligibilityCode'} name={'passengerEligibilityCode'} label={"Special Provision Code ('P' Codes)"} placeholder={'Passenger Eligibility Code...'} component={FormComboBox} data={optionsPassengerEligibilityCode} validator={requiredValidator} onChange={(e) => props.editDetailItem(dataItem, e.value, 'passengerEligibilityCode')} disabled={!props.dataItem.inEdit} />

									{/* Infotip: Start */}
									<Button
										type={'button'}
										variant={'secondary'}
										className={'btn-form-info'}
										id={'unNumberInfo'}
										name={'unNumberInfo'}
										ref={passengerEligibilityCodeAnchor}
										onClick={() => {
											// ref: modal anchor
											handleModalRef(passengerEligibilityCodeAnchor);

											// Info: Modal Title
											handleModalTitle('HAZMAT Provision Code Information');

											// Info: Modal Content
											handleModalContent(`
											<p>Requirements of the special provision codes are identified in <a href="https://static.e-publishing.af.mil/production/1/af_a4/publication/afman24-604/afman24-604.pdf" target="_blank" rel="noreferrer">AFMAN 24-602 Table A4.5.7</a>.<br/>
											The codes reflect four categories: numeric codes, codes beginning with "A", codes beginning with "N",<br/> and codes beginning with a “P”. Use codes beginning with a “P” to determine passenger eligibility for<br/> transport with
											hazardous materials. Use all other codes to determine packaging provisions,<br/> restrictions, and exceptions from requirements for particular quantities or forms of materials.</p>
											`);

											// Show Modal
											handleModalToggle();
										}}>
										<i className={'fa-solid fa-info modal-trigger'}></i>
										<span className={'sr-only'}>Click to view information about XXXXX</span>
									</Button>
									{/* Infotip: End */}
								</div>
							</div>
						</Row>

						<div className={'col-12'}>
							<div className={'form-group'}>
								<Field key={'hazmatNetExplosiveWeight'} id={'hazmatNetExplosiveWeight'} name={'netExplosiveWeight'} label={htmlRender('NET Explosive Weight (<b><em>as req in Pounds</em></b>)')} placeholder={''} component={FormInput} hint={'Total weight of all hazardous materials. Include NET EXPLOSIVE WEIGHT if applicable.'} validator={requiredValidator} onChange={(e) => props.editDetailItem(dataItem, e.value, 'netExplosiveWeight')} readOnly={!props.dataItem.inEdit} />
							</div>
						</div>
						<div style={{ float: 'right' }}>
							<CommandCell />
						</div>
					</FormElement>
				)}
			/>
			<LiftRequestTooltipModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalRef={modalRef} modalContent={modalContent} />
		</React.Fragment>
	);
};
