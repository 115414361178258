/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { accessValidator } from '../../Helpers/RolesPermissions';
import { ApiDisruptionsList } from '../../Helpers/Apis';

const HeaderNotifications = (props) => {
	const [disruptions, setDisruptions] = React.useState([]);

	const accessNotifications = ['nalo'];

	// Get List for State(s): CREATED, CLAIMED, PROCESSING,. AWAITING_APPROVAL
	// Exclude State(s): APPROVED
	const queryparams = '?states=CREATED&states=CLAIMED&states=PROCESSING&states=AWAITING_APPROVAL';

	const dataFetch = () => {
		ApiDisruptionsList(queryparams).then((res) => {
			setDisruptions(res);
		});
	};

	useEffect(() => {
		// Permission to make API call to DM service?
		if (accessValidator(props.roleaccess, accessNotifications)) {
			dataFetch();

			// Fetch Data Every 30 Seconds
			const interval = setInterval(dataFetch, 30000);

			return () => clearInterval(interval);
		}
	}, []);

	// Show Bell Notifications for Specific User(s)
	if (accessValidator(props.roleaccess, accessNotifications)) {
		// Show Bell Notifications if Data Exists
		if (disruptions !== undefined && disruptions.length) {
			return (
				<ul className={`app-header-nav app-header-nav-notifications-wrapper ${props.className ? props.className : ''}`}>
					<li className={'app-header-nav-item'}>
						<NavLink className={'app-header-nav-notifications-view'} title={'Click to View Disruptions'} to={`/disruptions`} onClick={() => {}}>
							<i className="fa-solid fa-bell"></i>
							<span className={'sr-only'}>View Disruptions</span>
						</NavLink>
					</li>
				</ul>
			);
		}
	}
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname,
		theme: state.theme
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNotifications);
