/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { SubmitDialog } from '../../Helpers/FormHelpers/dialogHelpers/solverSubmissionDialog';
import { Tooltip } from '@progress/kendo-react-tooltip';

import LiftRequestListGrid from './LiftRequestListGrid';

import { accessValidator } from '../../Helpers/RolesPermissions';
import { ApiMissionGenRequest } from '../../Helpers/Apis';
import { toast } from 'react-toastify';
import LiftRequestImportWindow from '../LiftRequestImportWindow/LiftRequestImportWindow';

const dateNow = new Date(Date.now());
//set the latest solver date to the current date + 2 weeks
const dateLater = new Date(Date.now() + 12096e5);

const ListRequestListTabs = (props) => {
	const [selected, setSelected] = React.useState(0);
	const [visible, setVisible] = React.useState(false);
	const [solverStartDateValue, setSolverStartDateValue] = React.useState(dateNow);
	const [solverEndDateValue, setSolverEndDateValue] = React.useState(dateLater);
	const [solverSpeed, setSolverSpeed] = React.useState('Fast');
	const [isDashboardWidget] = React.useState(props.dashboardWidget ? true : false);
	const [tabsPotentialLrIdArray, setTabsPotentialLrIdArray] = React.useState([]);

	const accessSubmitForScheduling = ['nalo'];

	// eslint-disable-next-line
	const accessSubmitForcedScheduling = ['nalo'];

	const environment = props.environment;

	const accessBulkImport = ['nalo'];

	const solverScheduleObj = {
		optimization_timestamp: {
			start_time: solverStartDateValue,
			end_time: solverEndDateValue
		},
		solver_configuration: {
			solver_speed: solverSpeed.toUpperCase()
		},
		createdBy: {
			id: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.id ? props.keycloak.profile.id : null,
			firstName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.firstName ? props.keycloak.profile.firstName : null,
			lastName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.lastName ? props.keycloak.profile.lastName : null,
			email: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.email ? props.keycloak.profile.email : null,
			phoneNumber: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.phoneNumber ? props.keycloak.profile.phoneNumber : null,
			rank: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.rank ? props.keycloak.profile.rank : null,
			dutyTitle: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.dutyTitle ? props.keycloak.profile.dutyTitle : null,
			unit: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.unit ? props.keycloak.profile.unit : null,
			branchOfService: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.branchOfService ? props.keycloak.profile.branchOfService : null
		}
	};

	const handleSolverDateChange = (event) => {
		// Date Range
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(event.value);
		}

		setSolverStartDateValue(event.value.start);
		setSolverEndDateValue(event.value.end);
	};

	const handleSolverSpeedChange = (event) => {
		setSolverSpeed(event.value);
	};

	const handleSelect = (e) => {
		setSelected(e.selected);
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const submitForScheduling = () => {
		toggleDialog();
		ApiMissionGenRequest(solverScheduleObj);
		toast.success('Successfully submitted lift requests to the Air Logistics Solver for scheduling');
	};

	const PageTitleControls = () => {
		return (
			<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'bottom'}>
				<Navbar expand="lg">
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							{accessValidator(props.roleaccess, accessSubmitForScheduling) && (
								<button
									className={'nav-link'}
									title={'Click to Submit for Scheduling'}
									onClick={() => {
										toggleDialog();
									}}>
									<i className={'fa fa-send-back'}></i>
									Submit for Scheduling
								</button>
							)}

							{accessValidator(props.roleaccess, accessBulkImport) && <LiftRequestImportWindow showIcon={true} classes={'nav-link'} />}

							{visible && <SubmitDialog text={'Are you sure you want to submit lift requests to the Air Logistics Solver for scheduling?'} visible={visible} toggleDialog={toggleDialog} handleSolverDateChange={handleSolverDateChange} handleSolverSpeedChange={handleSolverSpeedChange} submitForScheduling={submitForScheduling} />}

							{/* Print and export button placeholders to be implemented in ALO-549 */}
							{/*
                            <Nav.Link href="#print" title={"Click to Print List"}>
                                <i className={"fa fa-print"}></i>
                                Print
                            </Nav.Link>
                            <Nav.Link href="#export" title={"Click to Export List"}>
                                <i className={"fa fa-file-export"}></i>
                                Export
                            </Nav.Link>
                            */}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</Tooltip>
		);
	};

	let accessStates = '';

	if (props.roleaccess.includes('requester') && !props.roleaccess.includes('approver') && !props.roleaccess.includes('nalo')) {
		// Fetch List by User ID of Current User
		accessStates = '?latestOwner=' + props.userid + '&states=OPENED&states=SUBMITTED&states=AUTHORIZED&states=CLAIMED&states=VALIDATED&states=UNSATISFIED&states=SCHEDULED&states=IN_TRANSIT&states=SATISFIED&states=REGRETTED&states=CANCELLED';
		//accessStates = "?latestOwner=" + props.userid;
	}

	if (accessValidator(props.roleaccess, ['nalo'])) {
		accessStates = '?states=SUBMITTED&states=AUTHORIZED&states=CLAIMED&states=VALIDATED&states=SCHEDULED&states=IN_TRANSIT&states=SATISFIED';
	}

	return (
		<React.Fragment>
			{isDashboardWidget && environment !== 'selection' ? (
				<div className={'dashboard-widget-block-inner'}>
					<div className={'page-title'}>
						<h3>Lift Requests List</h3>
					</div>
				</div>
			) : (
				''
			)}
			{!isDashboardWidget && environment !== 'selection' ? (
				<div className={'page-title'}>
					<h2>Lift Requests List</h2>
					{PageTitleControls()}
				</div>
			) : (
				''
			)}

			<TabStrip selected={selected} onSelect={handleSelect} id="lift-request-list-tabs" className={isDashboardWidget ? 'lift-request-dashboard-list-tabs' : 'lift-request-list-tabs'}>
				{/* All Request: NALO View */}
				{props.roleaccess.includes('nalo', 'requester') && (
					<TabStripTab eventKey="all" title="All Requests">
						<LiftRequestListGrid queryparams={accessStates} roleaccess={props.roleaccess} token={props.token} environment={environment} dashboardWidget={isDashboardWidget} lrIdArray={props.lrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} tabsPotentialLrIdArray={tabsPotentialLrIdArray} setTabsPotentialLrIdArray={setTabsPotentialLrIdArray} />
					</TabStripTab>
				)}

				{/* All Request: Requester View */}
				{props.roleaccess.includes('requester') && !props.roleaccess.includes('approver') && !props.roleaccess.includes('nalo') && (
					<TabStripTab eventKey="all" title="All Requests">
						<LiftRequestListGrid queryparams={accessStates} roleaccess={props.roleaccess} token={props.token} environment={environment} props={props} dashboardWidget={isDashboardWidget} selectedLiftRequests={props.selectedLiftRequests} setSelectedLiftRequests={props.setSelectedLiftRequests} lrIdArray={props.lrIdArray} setLrIdArray={props.setLrIdArray} tabsPotentialLrIdArray={tabsPotentialLrIdArray} setTabsPotentialLrIdArray={setTabsPotentialLrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} />
					</TabStripTab>
				)}

				{accessValidator(props.roleaccess, ['approver', 'nalo', 'requester']) && (
					<TabStripTab eventKey="authorized" title="Submitted">
						<LiftRequestListGrid queryparams={'?states=SUBMITTED'} roleaccess={props.roleaccess} token={props.token} environment={environment} dashboardWidget={isDashboardWidget} selectedLiftRequests={props.selectedLiftRequests} setSelectedLiftRequests={props.setSelectedLiftRequests} lrIdArray={props.lrIdArray} tabsPotentialLrIdArray={tabsPotentialLrIdArray} setTabsPotentialLrIdArray={setTabsPotentialLrIdArray} setLrIdArray={props.setLrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} />
					</TabStripTab>
				)}

				{accessValidator(props.roleaccess, ['nalo', 'requester']) && (
					<TabStripTab eventKey="authorized" title="Authorized">
						<LiftRequestListGrid queryparams={'?states=AUTHORIZED'} roleaccess={props.roleaccess} token={props.token} environment={environment} dashboardWidget={isDashboardWidget} selectedLiftRequests={props.selectedLiftRequests} setSelectedLiftRequests={props.setSelectedLiftRequests} lrIdArray={props.lrIdArray} tabsPotentialLrIdArray={tabsPotentialLrIdArray} setTabsPotentialLrIdArray={setTabsPotentialLrIdArray} setLrIdArray={props.setLrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} />
					</TabStripTab>
				)}

				{accessValidator(props.roleaccess, ['nalo', 'requester']) && (
					<TabStripTab eventKey="claimed" title="Claimed">
						<LiftRequestListGrid queryparams={'?states=CLAIMED'} roleaccess={props.roleaccess} token={props.token} environment={environment} dashboardWidget={isDashboardWidget} selectedLiftRequests={props.selectedLiftRequests} setSelectedLiftRequests={props.setSelectedLiftRequests} lrIdArray={props.lrIdArray} tabsPotentialLrIdArray={tabsPotentialLrIdArray} setTabsPotentialLrIdArray={setTabsPotentialLrIdArray} setLrIdArray={props.setLrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} />
					</TabStripTab>
				)}

				{accessValidator(props.roleaccess, ['nalo', 'requester']) && (
					<TabStripTab eventKey="validated" title="Validated">
						<LiftRequestListGrid queryparams={'?states=VALIDATED'} roleaccess={props.roleaccess} token={props.token} environment={environment} dashboardWidget={isDashboardWidget} selectedLiftRequests={props.selectedLiftRequests} setSelectedLiftRequests={props.setSelectedLiftRequests} lrIdArray={props.lrIdArray} tabsPotentialLrIdArray={tabsPotentialLrIdArray} setTabsPotentialLrIdArray={setTabsPotentialLrIdArray} setLrIdArray={props.setLrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} />
					</TabStripTab>
				)}

				{accessValidator(props.roleaccess, ['nalo', 'requester']) && (
					<TabStripTab eventKey="validated" title="Scheduled">
						<LiftRequestListGrid queryparams={'?states=SCHEDULED'} roleaccess={props.roleaccess} token={props.token} environment={environment} dashboardWidget={isDashboardWidget} selectedLiftRequests={props.selectedLiftRequests} setSelectedLiftRequests={props.setSelectedLiftRequests} lrIdArray={props.lrIdArray} tabsPotentialLrIdArray={tabsPotentialLrIdArray} setTabsPotentialLrIdArray={setTabsPotentialLrIdArray} setLrIdArray={props.setLrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} />
					</TabStripTab>
				)}

				{accessValidator(props.roleaccess, ['nalo', 'requester']) && (
					<TabStripTab eventKey="validated" title="In-Transit">
						<LiftRequestListGrid queryparams={'?states=IN_TRANSIT'} roleaccess={props.roleaccess} token={props.token} environment={environment} dashboardWidget={isDashboardWidget} selectedLiftRequests={props.selectedLiftRequests} setSelectedLiftRequests={props.setSelectedLiftRequests} lrIdArray={props.lrIdArray} tabsPotentialLrIdArray={tabsPotentialLrIdArray} setTabsPotentialLrIdArray={setTabsPotentialLrIdArray} setLrIdArray={props.setLrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} />
					</TabStripTab>
				)}

				{accessValidator(props.roleaccess, ['nalo', 'requester']) && (
					<TabStripTab eventKey="validated" title="Satisfied">
						<LiftRequestListGrid queryparams={'?states=SATISFIED'} roleaccess={props.roleaccess} token={props.token} environment={environment} dashboardWidget={isDashboardWidget} selectedLiftRequests={props.selectedLiftRequests} setSelectedLiftRequests={props.setSelectedLiftRequests} lrIdArray={props.lrIdArray} tabsPotentialLrIdArray={tabsPotentialLrIdArray} setTabsPotentialLrIdArray={setTabsPotentialLrIdArray} setLrIdArray={props.setLrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} />
					</TabStripTab>
				)}

				{accessValidator(props.roleaccess, ['nalo', 'requester']) && (
					<TabStripTab eventKey="validated" title="Regretted">
						<LiftRequestListGrid queryparams={'?states=REGRETTED'} roleaccess={props.roleaccess} token={props.token} environment={environment} dashboardWidget={isDashboardWidget} selectedLiftRequests={props.selectedLiftRequests} setSelectedLiftRequests={props.setSelectedLiftRequests} lrIdArray={props.lrIdArray} tabsPotentialLrIdArray={tabsPotentialLrIdArray} setTabsPotentialLrIdArray={setTabsPotentialLrIdArray} setLrIdArray={props.setLrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} />
					</TabStripTab>
				)}
			</TabStrip>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListRequestListTabs);
