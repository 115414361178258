/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

// Grades List: Air Force
export const optionsGradesListAirForce = [
	{
		grade: 'E-1',
		insignia: '',
		title: 'Airman Basic',
		abbr: 'AB',
		class: 'Enlisted Airman',
		gradetitle: '(E-1) Airman Basic'
	},
	{
		grade: 'E-2',
		insignia: '/Assets/images/ranks/air-force/airman.png',
		title: 'Airman',
		abbr: 'Amn',
		class: 'Enlisted Airman',
		gradetitle: '(E-2) Airman'
	},
	{
		grade: 'E-3',
		insignia: '/Assets/images/ranks/air-force/airman-first-class.png',
		title: 'Airman First Class',
		abbr: 'A1C',
		class: 'Enlisted Airman',
		gradetitle: '(E-3) Airman First Class'
	},
	{
		grade: 'E-4',
		insignia: '/Assets/images/ranks/air-force/senior-airman.png',
		title: 'Senior Airman',
		abbr: 'SrA',
		class: 'Enlisted Airman',
		gradetitle: '(E-4) Senior Airman'
	},
	{
		grade: 'E-5',
		insignia: '/Assets/images/ranks/air-force/staff-sergeant.png',
		title: 'Staff Sergeant',
		abbr: 'SSgt',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-5) Staff Sergeant'
	},
	{
		grade: 'E-6',
		insignia: '/Assets/images/ranks/air-force/technical-sergeant.png',
		title: 'Technical Sergeant',
		abbr: 'TSgt',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-6) Technical Sergeant'
	},
	{
		grade: 'E-7',
		insignia: '/Assets/images/ranks/air-force/master-sergeant.png',
		title: 'Master Sergeant',
		abbr: 'MSgt',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-7) Master Sergeant'
	},
	{
		grade: 'E-8',
		insignia: '/Assets/images/ranks/air-force/senior-master-sergeant.png',
		title: 'Senior Master Sergeant',
		abbr: 'SMSgt',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-8) Senior Master Sergeant'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/air-force/chief-master-sergeant.png',
		title: 'Chief Master Sergeant',
		abbr: 'CMSgt',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-9) Chief Master Sergeant'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/air-force/command-chief-master-sergeant.png',
		title: 'Command Chief Master Sergeant',
		abbr: 'CCM',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-9) Command Chief Master Sergeant'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/air-force/chief-master-sergeant-of-the-air-force.png',
		title: 'Chief Master Sergeant Of The Air Force',
		abbr: 'CMSAF',
		class: 'Noncommissioned Officer (Special)',
		gradetitle: '(E-9) Chief Master Sergeant Of The Air Force'
	},
	{
		grade: 'O-1',
		insignia: '/Assets/images/ranks/air-force/second-lieutenant.png',
		title: 'Second Lieutenant',
		abbr: '2d Lt',
		class: 'Commissioned Officer',
		gradetitle: '(O-1) Second Lieutenant'
	},
	{
		grade: 'O-2',
		insignia: '/Assets/images/ranks/air-force/first-lieutenant.png',
		title: 'First Lieutenant',
		abbr: '1st L',
		class: 'Commissioned Officer',
		gradetitle: '(O-2) First Lieutenant'
	},
	{
		grade: 'O-3',
		insignia: '/Assets/images/ranks/air-force/captain.png',
		title: 'Captain',
		abbr: 'Capt',
		class: 'Commissioned Officer',
		gradetitle: '(O-3) Captain'
	},
	{
		grade: 'O-4',
		insignia: '/Assets/images/ranks/air-force/major.png',
		title: 'Major',
		abbr: 'Maj',
		class: 'Field Officer',
		gradetitle: '(O-4) Major'
	},
	{
		grade: 'O-5',
		insignia: '/Assets/images/ranks/air-force/lieutenant-colonel.png',
		title: 'Lieutenant Colonel',
		abbr: 'Lt Co',
		class: 'Field Officer',
		gradetitle: '(O-5) Lieutenant Colonel'
	},
	{
		grade: 'O-6',
		insignia: '/Assets/images/ranks/air-force/colonel.png',
		title: 'Colonel',
		abbr: 'Col',
		class: 'Field Officer',
		gradetitle: '(O-6) Colonel'
	},
	{
		grade: 'O-7',
		insignia: '/Assets/images/ranks/air-force/brigadier-general.png',
		title: 'Brigadier General',
		abbr: 'Brig',
		class: 'General Officer',
		gradetitle: '(O-7) Brigadier General'
	},
	{
		grade: 'O-8',
		insignia: '/Assets/images/ranks/air-force/major-general.png',
		title: 'Major General',
		abbr: 'Maj G',
		class: 'General Officer',
		gradetitle: '(O-8) Major General'
	},
	{
		grade: 'O-9',
		insignia: '/Assets/images/ranks/air-force/lieutenant-general.png',
		title: 'Lieutenant General',
		abbr: 'Lt Ge',
		class: 'General Officer',
		gradetitle: '(O-9) Lieutenant General'
	},
	{
		grade: 'O-10',
		insignia: '/Assets/images/ranks/air-force/general.png',
		title: 'General',
		abbr: 'Gen',
		class: 'General Officer',
		gradetitle: '(O-10) General'
	},
	{
		grade: 'O-10',
		insignia: '/Assets/images/ranks/air-force/general-of-the-air-force.png',
		title: 'General of the Air Force',
		abbr: 'GAF',
		class: 'General Officer',
		gradetitle: '(O-10) General of the Air Force'
	}
];

// Grades List: Army
export const optionsGradesListArmy = [
	{
		grade: 'E-1',
		insignia: '',
		title: 'Private',
		abbr: 'PVT',
		class: 'Enlisted Soldier',
		gradetitle: '(E-1) Private'
	},
	{
		grade: 'E-2',
		insignia: '/Assets/images/ranks/army/private-second-class.png',
		title: 'Private Second Class',
		abbr: 'PV2',
		class: 'Enlisted Soldier',
		gradetitle: '(E-2) Private Second Class'
	},
	{
		grade: 'E-3',
		insignia: '/Assets/images/ranks/army/private-first-class.png',
		title: 'Private First Class',
		abbr: 'PFC',
		class: 'Enlisted Soldier',
		gradetitle: '(E-3) Private First Class'
	},
	{
		grade: 'E-4',
		insignia: '/Assets/images/ranks/army/specialist.png',
		title: 'Specialist',
		abbr: 'SPC',
		class: 'Enlisted Soldier',
		gradetitle: '(E-4) Specialist'
	},
	{
		grade: 'E-4',
		insignia: '/Assets/images/ranks/army/corporal.png',
		title: 'Corporal',
		abbr: 'CPL',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-4) Corporal'
	},
	{
		grade: 'E-5',
		insignia: '/Assets/images/ranks/army/sergeant.png',
		title: 'Sergeant',
		abbr: 'SGT',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-5) Sergeant'
	},
	{
		grade: 'E-6',
		insignia: '/Assets/images/ranks/army/staff-sergeant.png',
		title: 'Staff Sergeant',
		abbr: 'SSG',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-6) Staff Sergeant'
	},
	{
		grade: 'E-7',
		insignia: '/Assets/images/ranks/army/sergeant-first-class.png',
		title: 'Sergeant First Class',
		abbr: 'SFC',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-7) Sergeant First Class'
	},
	{
		grade: 'E-8 ',
		insignia: '/Assets/images/ranks/army/master-sergeant.png',
		title: 'Master Sergeant',
		abbr: 'MSG',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-8) Master Sergeant'
	},
	{
		grade: 'E-8',
		insignia: '/Assets/images/ranks/army/first-sergeant.png',
		title: 'First Sergeant',
		abbr: '1SG',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-8) First Sergeant'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/army/sergeant-major.png',
		title: 'Sergeant Major',
		abbr: 'SGM',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-9) Sergeant Major'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/army/command-sergeant-major.png',
		title: 'Command Sergeant Major',
		abbr: 'CSM',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-9) Command Sergeant Major'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/army/sergeant-major-of-the-army.png',
		title: 'Sergeant Major of the Army',
		abbr: 'SMA',
		class: 'Noncommissioned Officer (Special)',
		gradetitle: '(E-9) Sergeant Major of the Army'
	},
	{
		grade: 'W-1',
		insignia: '/Assets/images/ranks/army/warrant-officer-1.png',
		title: 'Warrant Officer 1',
		abbr: 'WO1',
		class: 'Warrant Officer',
		gradetitle: '(W-1) Warrant Officer 1'
	},
	{
		grade: 'W-2',
		insignia: '/Assets/images/ranks/army/chief-warrant-officer-2.png',
		title: 'Chief Warrant Officer 2',
		abbr: 'CW2',
		class: 'Warrant Officer',
		gradetitle: '(W-2) Chief Warrant Officer 2'
	},
	{
		grade: 'W-3',
		insignia: '/Assets/images/ranks/army/chief-warrant-officer-3.png',
		title: 'Chief Warrant Officer 3',
		abbr: 'CW3',
		class: 'Warrant Officer',
		gradetitle: '(W-3) Chief Warrant Officer 3'
	},
	{
		grade: 'W-4',
		insignia: '/Assets/images/ranks/army/chief-warrant-officer-4.png',
		title: 'Chief Warrant Officer 4',
		abbr: 'CW4',
		class: 'Warrant Officer',
		gradetitle: '(W-4) Chief Warrant Officer 4'
	},
	{
		grade: 'W-5',
		insignia: '/Assets/images/ranks/army/chief-warrant-officer-5.png',
		title: 'Chief Warrant Officer 5',
		abbr: 'CW5',
		class: 'Warrant Officer',
		gradetitle: '(W-5) Chief Warrant Officer 5'
	},
	{
		grade: 'O-1',
		insignia: '/Assets/images/ranks/army/second-lieutenant.png',
		title: 'Second Lieutenant',
		abbr: '2LT',
		class: 'Commissioned Officer',
		gradetitle: '(O-1) Second Lieutenant'
	},
	{
		grade: 'O-2',
		insignia: '/Assets/images/ranks/army/first-lieutenant.png',
		title: 'First Lieutenant',
		abbr: '1LT',
		class: 'Commissioned Officer',
		gradetitle: '(O-2) First Lieutenant'
	},
	{
		grade: 'O-3',
		insignia: '/Assets/images/ranks/army/captain.png',
		title: 'Captain',
		abbr: 'CPT',
		class: 'Commissioned Officer',
		gradetitle: '(O-3) Captain'
	},
	{
		grade: 'O-4',
		insignia: '/Assets/images/ranks/army/major.png',
		title: 'Major',
		abbr: 'MAJ',
		class: 'Field Officer',
		gradetitle: '(O-4) Major'
	},
	{
		grade: 'O-5',
		insignia: '/Assets/images/ranks/army/lieutenant-colonel.png',
		title: 'Lieutenant Colonel',
		abbr: 'LTC',
		class: 'Field Officer',
		gradetitle: '(O-5) Lieutenant Colonel'
	},
	{
		grade: 'O-6',
		insignia: '/Assets/images/ranks/army/colonel.png',
		title: 'Colonel',
		abbr: 'COL',
		class: 'Field Officer',
		gradetitle: '(O-6) Colonel'
	},
	{
		grade: 'O-7',
		insignia: '/Assets/images/ranks/army/brigadier-general.png',
		title: 'Brigadier General',
		abbr: 'BG',
		class: 'General Officer',
		gradetitle: '(O-7) Brigadier General'
	},
	{
		grade: 'O-8',
		insignia: '/Assets/images/ranks/army/major-general.png',
		title: 'Major General',
		abbr: 'MG',
		class: 'General Officer',
		gradetitle: '(O-8) Major General'
	},
	{
		grade: 'O-9',
		insignia: '/Assets/images/ranks/army/lieutenant-general.png',
		title: 'Lieutenant General',
		abbr: 'LTG',
		class: 'General Officer',
		gradetitle: '(O-9) Lieutenant General'
	},
	{
		grade: 'O-10',
		insignia: '/Assets/images/ranks/army/general.png',
		title: 'General',
		abbr: 'GEN',
		class: 'General Officer',
		gradetitle: '(O-10) General'
	},
	{
		grade: 'O-10',
		insignia: '/Assets/images/ranks/army/general-of-the-army.png',
		title: 'General of the Army',
		abbr: 'GA',
		class: 'General Officer',
		gradetitle: '(O-10) General of the Army'
	}
];

// Grades List: Coast Guard
export const optionsGradesListCoastGuard = [
	{
		grade: 'E-1',
		insignia: '/Assets/images/ranks/coast-guard/no-insignia.png',
		title: 'Seaman Recruit',
		abbr: 'SR',
		class: 'Seaman',
		gradetitle: '(E-1) Seaman Recruit'
	},
	{
		grade: 'E-2',
		insignia: '/Assets/images/ranks/coast-guard/seaman-apprentice.png',
		title: 'Seaman Apprentice',
		abbr: 'SA',
		class: 'Seaman',
		gradetitle: '(E-2) Seaman Apprentice'
	},
	{
		grade: 'E-3',
		insignia: '/Assets/images/ranks/coast-guard/seaman.png',
		title: 'Seaman',
		abbr: 'SN',
		class: 'Seaman',
		gradetitle: '(E-3) Seaman'
	},
	{
		grade: 'E-4',
		insignia: '/Assets/images/ranks/coast-guard/petty-officer-third-class.png',
		title: 'Petty Officer Third Class',
		abbr: 'PO3',
		class: 'Petty Officer',
		gradetitle: '(E-4) Petty Officer Third Class'
	},
	{
		grade: 'E-5',
		insignia: '/Assets/images/ranks/coast-guard/petty-officer-second-class.png',
		title: 'Petty Officer Second Class',
		abbr: 'PO2',
		class: 'Petty Officer',
		gradetitle: '(E-5) Petty Officer Second Class'
	},
	{
		grade: 'E-6',
		insignia: '/Assets/images/ranks/coast-guard/petty-officer-first-class.png',
		title: 'Petty Officer First Class',
		abbr: 'PO1',
		class: 'Petty Officer',
		gradetitle: '(E-6) Petty Officer First Class'
	},
	{
		grade: 'E-7',
		insignia: '/Assets/images/ranks/coast-guard/chief-petty-officer.png',
		title: 'Chief Petty Officer',
		abbr: 'CPO',
		class: 'Chief Petty Officer',
		gradetitle: '(E-7) Chief Petty Officer'
	},
	{
		grade: 'E-8',
		insignia: '/Assets/images/ranks/coast-guard/senior-chief-petty-officer.png',
		title: 'Senior Chief Petty Officer',
		abbr: 'SCPO',
		class: 'Chief Petty Officer',
		gradetitle: '(E-8) Senior Chief Petty Officer'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/coast-guard/master-chief-petty-officer.png',
		title: 'Master Chief Petty Officer',
		abbr: 'MCPO',
		class: 'Chief Petty Officer',
		gradetitle: '(E-9) Master Chief Petty Officer'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/coast-guard/command-master-chief-petty-officer.png',
		title: 'Command Master Chief Petty Officer',
		abbr: 'CMC',
		class: 'Chief Petty Officer',
		gradetitle: '(E-9) Command Master Chief Petty Officer'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/coast-guard/master-chief-petty-officer-of-the-coast-guard.png',
		title: 'Master Chief Petty Officer Of The Coast Guard',
		abbr: 'MCPOC',
		class: 'Chief Petty Officer (Special)',
		gradetitle: '(E-9) Master Chief Petty Officer Of The Coast Guard'
	},
	{
		grade: 'W-2',
		insignia: '/Assets/images/ranks/coast-guard/chief-warrant-officer-2.png',
		title: 'Chief Warrant Officer 2',
		abbr: 'CWO-2',
		class: 'Chief Warrant Officer',
		gradetitle: '(W-2) Chief Warrant Officer 2'
	},
	{
		grade: 'W-3',
		insignia: '/Assets/images/ranks/coast-guard/chief-warrant-officer-3.png',
		title: 'Chief Warrant Officer 3',
		abbr: 'CWO-3',
		class: 'Chief Warrant Officer',
		gradetitle: '(W-3) Chief Warrant Officer 3'
	},
	{
		grade: 'W-4',
		insignia: '/Assets/images/ranks/coast-guard/chief-warrant-officer-4.png',
		title: 'Chief Warrant Officer 4',
		abbr: 'CWO-4',
		class: 'Chief Warrant Officer',
		gradetitle: '(W-4) Chief Warrant Officer 4'
	},
	{
		grade: 'O-1',
		insignia: '/Assets/images/ranks/coast-guard/ensign.png',
		title: 'Ensign',
		abbr: 'ENS',
		class: 'Junior Officer',
		gradetitle: '(O-1) Ensign'
	},
	{
		grade: 'O-2',
		insignia: '/Assets/images/ranks/coast-guard/lieutenant-junior-grade.png',
		title: 'Lieutenant Junior Grade',
		abbr: 'LTJG',
		class: 'Junior Officer',
		gradetitle: '(O-2) Lieutenant Junior Grade'
	},
	{
		grade: 'O-3',
		insignia: '/Assets/images/ranks/coast-guard/lieutenant.png',
		title: 'Lieutenant',
		abbr: 'LT',
		class: 'Junior Officer',
		gradetitle: '(O-3) Lieutenant'
	},
	{
		grade: 'O-4',
		insignia: '/Assets/images/ranks/coast-guard/lieutenant-commander.png',
		title: 'Lieutenant Commander',
		abbr: 'LCDR',
		class: 'Junior Officer',
		gradetitle: '(O-4) Lieutenant Commander'
	},
	{
		grade: 'O-5',
		insignia: '/Assets/images/ranks/coast-guard/commander.png',
		title: 'Commander',
		abbr: 'CDR',
		class: 'Senior Officer',
		gradetitle: '(O-5) Commander'
	},
	{
		grade: 'O-6',
		insignia: '/Assets/images/ranks/coast-guard/captain.png',
		title: 'Captain',
		abbr: 'CAPT',
		class: 'Senior Officer',
		gradetitle: '(O-6) Captain'
	},
	{
		grade: 'O-7',
		insignia: '/Assets/images/ranks/coast-guard/rear-admiral-lower-half.png',
		title: 'Rear Admiral Lower Half',
		abbr: 'DRML',
		class: 'Flag Officer',
		gradetitle: '(O-7) Rear Admiral Lower Half'
	},
	{
		grade: 'O-8',
		insignia: '/Assets/images/ranks/coast-guard/rear-admiral.png',
		title: 'Rear Admiral',
		abbr: 'RADM',
		class: 'Flag Officer',
		gradetitle: '(O-8) Rear Admiral'
	},
	{
		grade: 'O-9',
		insignia: '/Assets/images/ranks/coast-guard/vice-admiral.png',
		title: 'Vice Admiral',
		abbr: 'VADM',
		class: 'Flag Officer',
		gradetitle: '(O-9) Vice Admiral'
	},
	{
		grade: 'O-10',
		insignia: '/Assets/images/ranks/coast-guard/admiral.png',
		title: 'Admiral',
		abbr: 'ADM',
		class: 'Flag Officer',
		gradetitle: '(O-10) Admiral'
	}
];

// Grades List: Marines Corp
export const optionsGradesListMarineCorps = [
	{
		grade: 'E-1',
		insignia: '',
		title: 'Private',
		abbr: 'Pvt',
		class: 'Junior Enlisted',
		gradetitle: '(E-1) Private'
	},
	{
		grade: 'E-2',
		insignia: '/Assets/images/ranks/marine-corps/private-first-class.png',
		title: 'Private First Class',
		abbr: 'PFC',
		class: 'Junior Enlisted',
		gradetitle: '(E-2) Private First Class'
	},
	{
		grade: 'E-3',
		insignia: '/Assets/images/ranks/marine-corps/lance-corporal.png',
		title: 'Lance Corporal',
		abbr: 'LCpl',
		class: 'Junior Enlisted',
		gradetitle: '(E-3) Lance Corporal'
	},
	{
		grade: 'E-4',
		insignia: '/Assets/images/ranks/marine-corps/corporal.png',
		title: 'Corporal',
		abbr: 'Cpl',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-4) Corporal'
	},
	{
		grade: 'E-5',
		insignia: '/Assets/images/ranks/marine-corps/sergeant.png',
		title: 'Sergeant',
		abbr: 'Sgt',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-5) Sergeant'
	},
	{
		grade: 'E-6',
		insignia: '/Assets/images/ranks/marine-corps/staff-sergeant.png',
		title: 'Staff Sergeant',
		abbr: 'SSgt',
		class: 'Staff Noncommissioned Officer',
		gradetitle: '(E-6) Staff Sergeant'
	},
	{
		grade: 'E-7',
		insignia: '/Assets/images/ranks/marine-corps/gunnery-sergeant.png',
		title: 'Gunnery Sergeant',
		abbr: 'GySgt',
		class: 'Staff Noncommissioned Officer',
		gradetitle: '(E-7) Gunnery Sergeant'
	},
	{
		grade: 'E-8',
		insignia: '/Assets/images/ranks/marine-corps/master-sergeant.png',
		title: 'Master Sergeant',
		abbr: 'MSgt',
		class: 'Staff Noncommissioned Officer',
		gradetitle: '(E-8) Master Sergeant'
	},
	{
		grade: 'E-8',
		insignia: '/Assets/images/ranks/marine-corps/first-sergeant.png',
		title: 'First Sergeant',
		abbr: '1stSg',
		class: 'Senior Enlisted Advisor',
		gradetitle: '(E-8) First Sergeant'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/marine-corps/master-gunnery-sergeant.png',
		title: 'Master Gunnery Sergeant',
		abbr: 'MGySg',
		class: 'Staff Noncommissioned Officer',
		gradetitle: '(E-9) Master Gunnery Sergeant'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/marine-corps/sergeant-major.png',
		title: 'Sergeant Major',
		abbr: 'SgtMa',
		class: 'Senior Enlisted Advisor',
		gradetitle: '(E-9) Sergeant Major'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/marine-corps/sergeant-major-of-the-marine-corps.png',
		title: 'Sergeant Major Of The Marine Corps',
		abbr: 'sgtMa',
		class: 'Senior Enlisted Advisor',
		gradetitle: '(E-9) Sergeant Major Of The Marine Corps'
	},
	{
		grade: 'W-1',
		insignia: '/Assets/images/ranks/marine-corps/warrant-officer-1.png',
		title: 'Warrant Officer 1',
		abbr: 'WO1',
		class: 'Warrant Officer',
		gradetitle: '(W-1) Warrant Officer 1'
	},
	{
		grade: 'W-2',
		insignia: '/Assets/images/ranks/marine-corps/chief-warrant-officer-2.png',
		title: 'Chief Warrant Officer 2',
		abbr: 'CW2',
		class: 'Warrant Officer',
		gradetitle: '(W-2) Chief Warrant Officer 2'
	},
	{
		grade: 'W-3',
		insignia: '/Assets/images/ranks/marine-corps/chief-warrant-officer-3.png',
		title: 'Chief Warrant Officer 3',
		abbr: 'CW3',
		class: 'Warrant Officer',
		gradetitle: '(W-3) Chief Warrant Officer 3'
	},
	{
		grade: 'W-4',
		insignia: '/Assets/images/ranks/marine-corps/chief-warrant-officer-4.png',
		title: 'Chief Warrant Officer 4',
		abbr: 'CW4',
		class: 'Warrant Officer',
		gradetitle: '(W-4) Chief Warrant Officer 4'
	},
	{
		grade: 'W-5',
		insignia: '/Assets/images/ranks/marine-corps/chief-warrant-officer-5.png',
		title: 'Chief Warrant Officer 5',
		abbr: 'CW5',
		class: 'Warrant Officer',
		gradetitle: '(W-5) Chief Warrant Officer 5'
	},
	{
		grade: 'O-1',
		insignia: '/Assets/images/ranks/marine-corps/second-lieutenant.png',
		title: 'Second Lieutenant',
		abbr: '2ndLt',
		class: 'Commissioned Officer',
		gradetitle: '(O-1) Second Lieutenant'
	},
	{
		grade: 'O-2',
		insignia: '/Assets/images/ranks/marine-corps/first-lieutenant.png',
		title: 'First Lieutenant',
		abbr: '1stLt',
		class: 'Commissioned Officer',
		gradetitle: '(O-2) First Lieutenant'
	},
	{
		grade: 'O-3',
		insignia: '/Assets/images/ranks/marine-corps/captain.png',
		title: 'Captain',
		abbr: 'Capt',
		class: 'Commissioned Officer',
		gradetitle: '(O-3) Captain'
	},
	{
		grade: 'O-4',
		insignia: '/Assets/images/ranks/marine-corps/major.png',
		title: 'Major',
		abbr: 'Maj',
		class: 'Field Officer',
		gradetitle: '(O-4) Major'
	},
	{
		grade: 'O-5',
		insignia: '/Assets/images/ranks/marine-corps/lieutenant-colonel.png',
		title: 'Lieutenant Colonel',
		abbr: 'LtCol',
		class: 'Field Officer',
		gradetitle: '(O-5) Lieutenant Colonel'
	},
	{
		grade: 'O-6',
		insignia: '/Assets/images/ranks/marine-corps/colonel.png',
		title: 'Colonel',
		abbr: 'Col',
		class: 'Field Officer',
		gradetitle: '(O-6) Colonel'
	},
	{
		grade: 'O-7',
		insignia: '/Assets/images/ranks/marine-corps/brigadier-general.png',
		title: 'Brigadier General',
		abbr: 'BGen',
		class: 'General Officer',
		gradetitle: '(O-7) Brigadier General'
	},
	{
		grade: 'O-8',
		insignia: '/Assets/images/ranks/marine-corps/major-general.png',
		title: 'Major General',
		abbr: 'MajGen',
		class: 'General Officer',
		gradetitle: '(O-8) Major General'
	},
	{
		grade: 'O-9',
		insignia: '/Assets/images/ranks/marine-corps/lieutenant-general.png',
		title: 'Lieutenant General',
		abbr: 'LtGen',
		class: 'General Officer',
		gradetitle: '(O-9) Lieutenant General'
	},
	{
		grade: 'O-10',
		insignia: '/Assets/images/ranks/marine-corps/general.png',
		title: 'General',
		abbr: 'Gen',
		class: 'General Officer',
		gradetitle: '(O-10) General'
	}
];

// Grades List: Space Force
export const optionsGradesListSpaceForce = [
	{
		grade: 'E-1',
		insignia: '',
		title: 'Specialist 1',
		abbr: 'Spc1',
		class: '',
		gradetitle: '(E-1) Specialist 1'
	},
	{
		grade: 'E-2',
		insignia: '/Assets/images/ranks/space-force/specialist-2.png',
		title: 'Specialist 2',
		abbr: 'Spc2',
		class: '',
		gradetitle: '(E-2) Specialist 2'
	},
	{
		grade: 'E-3',
		insignia: '/Assets/images/ranks/space-force/specialist-3.png',
		title: 'Specialist 3',
		abbr: 'Spc3',
		class: '',
		gradetitle: '(E-3) Specialist 3'
	},
	{
		grade: 'E-4',
		insignia: '/Assets/images/ranks/space-force/specialist-4.png',
		title: 'Specialist 4',
		abbr: 'Spc4',
		class: '',
		gradetitle: '(E-4) Specialist 4'
	},
	{
		grade: 'E-5',
		insignia: '/Assets/images/ranks/space-force/staff-sergeant.png',
		title: 'Sergeant',
		abbr: 'Sgt',
		class: '',
		gradetitle: '(E-5) Staff Sergeant'
	},
	{
		grade: 'E-6',
		insignia: '/Assets/images/ranks/space-force/technical-sergeant.png',
		title: 'Technical Sergeant',
		abbr: 'TSgt',
		class: '',
		gradetitle: '(E-6) Technical Sergeant'
	},
	{
		grade: 'E-7',
		insignia: '/Assets/images/ranks/space-force/master-sergeant.png',
		title: 'Master Sergeant',
		abbr: 'MSgt',
		class: '',
		gradetitle: '(E-7) Master Sergeant'
	},
	{
		grade: 'E-8',
		insignia: '/Assets/images/ranks/space-force/senior-master-sergeant.png',
		title: 'Senior Master Sergeant',
		abbr: 'SMSgt',
		class: '',
		gradetitle: '(E-8) Senior Master Sergeant'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/air-force/chief-master-sergeant.png',
		title: 'Chief Master Sergeant',
		abbr: 'CMSgt',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-9) Chief Master Sergeant'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/space-force/chief-master-sergeant-of-space-force.png',
		title: 'Chief Master Sergeant Of The Space Force',
		abbr: 'CMSSF',
		class: 'Noncommissioned Officer (Special)',
		gradetitle: '(E-9) Chief Master Sergeant Of The Air Force'
	},

	{
		grade: 'O-1',
		insignia: '/Assets/images/ranks/space-force/second-lieutenant.png',
		title: 'Second Lieutenant',
		abbr: '2d Lt',
		class: '',
		gradetitle: '(O-1) Second Lieutenant'
	},
	{
		grade: 'O-2',
		insignia: '/Assets/images/ranks/space-force/first-lieutenant.png',
		title: 'First Lieutenant',
		abbr: '1st L',
		class: 'Commissioned Officer',
		gradetitle: '(O-2) First Lieutenant'
	},
	{
		grade: 'O-3',
		insignia: '/Assets/images/ranks/space-force/captain.png',
		title: 'Captain',
		abbr: 'Capt',
		class: 'Commissioned Officer',
		gradetitle: '(O-3) Captain'
	},
	{
		grade: 'O-4',
		insignia: '/Assets/images/ranks/space-force/major.png',
		title: 'Major',
		abbr: 'Maj',
		class: 'Field Officer',
		gradetitle: '(O-4) Major'
	},
	{
		grade: 'O-5',
		insignia: '/Assets/images/ranks/space-force/lieutenant-colonel.png',
		title: 'Lieutenant Colonel',
		abbr: 'Lt Co',
		class: 'Field Officer',
		gradetitle: '(O-5) Lieutenant Colonel'
	},
	{
		grade: 'O-6',
		insignia: '/Assets/images/ranks/space-force/colonel.png',
		title: 'Colonel',
		abbr: 'Col',
		class: 'Field Officer',
		gradetitle: '(O-6) Colonel'
	},
	{
		grade: 'O-7',
		insignia: '/Assets/images/ranks/space-force/brigadier-general.png',
		title: 'Brigadier General',
		abbr: 'Brig',
		class: 'General Officer',
		gradetitle: '(O-7) Brigadier General'
	},
	{
		grade: 'O-8',
		insignia: '/Assets/images/ranks/space-force/major-general.png',
		title: 'Major General',
		abbr: 'Maj G',
		class: 'General Officer',
		gradetitle: '(O-8) Major General'
	},
	{
		grade: 'O-9',
		insignia: '/Assets/images/ranks/space-force/lieutenant-general.png',
		title: 'Lieutenant General',
		abbr: 'Lt Ge',
		class: 'General Officer',
		gradetitle: '(O-9) Lieutenant General'
	},
	{
		grade: 'O-10',
		insignia: '/Assets/images/ranks/space-force/general.png',
		title: 'General',
		abbr: 'Gen',
		class: 'General Officer',
		gradetitle: '(O-10) General'
	}
];

// Grades List: Navy
export const optionsGradesListNavy = [
	{
		grade: 'E-1',
		insignia: '/Assets/images/ranks/navy/seaman-recruit.png',
		title: 'Seaman Recruit',
		abbr: 'SR',
		class: 'Junior Enlisted',
		gradetitle: '(E-1) Seaman Recruit'
	},
	{
		grade: 'E-2',
		insignia: '/Assets/images/ranks/navy/seaman-apprentice.png',
		title: 'Seaman Apprentice',
		abbr: 'SA',
		class: 'Junior Enlisted',
		gradetitle: '(E-2) Seaman Apprentice'
	},
	{
		grade: 'E-3',
		insignia: '/Assets/images/ranks/navy/seaman.png',
		title: 'Seaman',
		abbr: 'SN',
		class: 'Junior Enlisted',
		gradetitle: '(E-3) Seaman'
	},
	{
		grade: 'E-4',
		insignia: '/Assets/images/ranks/navy/petty-officer-third-class.png',
		title: 'Petty Officer Third Class',
		abbr: 'PO3',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-4) Petty Officer Third Class'
	},
	{
		grade: 'E-5',
		insignia: '/Assets/images/ranks/navy/petty-officer-second-class.png',
		title: 'Petty Officer Second Class',
		abbr: 'PO2',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-5) Petty Officer Second Class'
	},
	{
		grade: 'E-6',
		insignia: '/Assets/images/ranks/navy/petty-officer-first-class.png',
		title: 'Petty Officer First Class',
		abbr: 'PO1',
		class: 'Noncommissioned Officer',
		gradetitle: '(E-6) Petty Officer First Class'
	},
	{
		grade: 'E-7',
		insignia: '/Assets/images/ranks/navy/chief-petty-officer.png',
		title: 'Chief Petty Officer',
		abbr: 'CPO',
		class: 'Senior Noncommissioned Officer',
		gradetitle: '(E-7) Chief Petty Officer'
	},
	{
		grade: 'E-8',
		insignia: '/Assets/images/ranks/navy/senior-chief-petty-officer.png',
		title: 'Senior Chief Petty Officer',
		abbr: 'SCPO',
		class: 'Senior Noncommissioned Officer',
		gradetitle: '(E-8) Senior Chief Petty Officer'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/navy/master-chief-petty-officer.png',
		title: 'Master Chief Petty Officer',
		abbr: 'MCPO',
		class: 'Senior Noncommissioned Officer',
		gradetitle: '(E-9) Master Chief Petty Officer'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/navy/command-master-chief-petty-officer.png',
		title: 'Command Master Chief Petty Officer',
		abbr: 'CMDCM',
		class: 'Senior Enlisted Advisor',
		gradetitle: '(E-9) Command Master Chief Petty Officer'
	},
	{
		grade: 'E-9',
		insignia: '/Assets/images/ranks/navy/master-chief-petty-officer-of-the-navy.png',
		title: 'Master Chief Petty Officer of The Navy',
		abbr: 'MCPON',
		class: 'Senior Enlisted Advisor',
		gradetitle: '(E-9) Master Chief Officer of the Navy'
	},
	{
		grade: 'W-1',
		insignia: '/Assets/images/ranks/navy/warrant-officer-1.png',
		title: 'Warrant Officer 1',
		abbr: 'CW1',
		class: 'Warrant Officer',
		gradetitle: '(W-1) Warrant Officer 1'
	},
	{
		grade: 'W-2',
		insignia: '/Assets/images/ranks/navy/chief-warrant-officer-2.png',
		title: 'Chief Warrant Officer 2',
		abbr: 'CW2',
		class: 'Warrant Officer',
		gradetitle: '(W-2) Chief Warrant Officer 2'
	},
	{
		grade: 'W-3',
		insignia: '/Assets/images/ranks/navy/chief-warrant-officer-3.png',
		title: 'Chief Warrant Officer 3',
		abbr: 'CW3',
		class: 'Warrant Officer',
		gradetitle: '(W-3) Chief Warrant Officer 3'
	},
	{
		grade: 'W-4',
		insignia: '/Assets/images/ranks/navy/chief-warrant-officer-4.png',
		title: 'Chief Warrant Officer 4',
		abbr: 'CW4',
		class: 'Warrant Officer',
		gradetitle: '(W-4) Chief Warrant Officer 4'
	},
	{
		grade: 'W-5',
		insignia: '/Assets/images/ranks/navy/chief-warrant-officer-5.png',
		title: 'Chief Warrant Officer 5',
		abbr: 'CW5',
		class: 'Warrant Officer',
		gradetitle: '(W-5) Chief Warrant Officer 5'
	},
	{
		grade: 'O-1',
		insignia: '/Assets/images/ranks/navy/ensign.png',
		title: 'Ensign',
		abbr: 'ENS',
		class: 'Junior Officer',
		gradetitle: '(O-1) Ensign'
	},
	{
		grade: 'O-2',
		insignia: '/Assets/images/ranks/navy/lieutenant-junior-grade.png',
		title: 'Lieutenant Junior Grade',
		abbr: 'LTJG',
		class: 'Junior Officer',
		gradetitle: '(O-2) Lieutenant Junior Grade'
	},
	{
		grade: 'O-3',
		insignia: '/Assets/images/ranks/navy/lieutenant.png',
		title: 'Lieutenant',
		abbr: 'LT',
		class: 'Junior Officer',
		gradetitle: '(O-3) Lieutenant'
	},
	{
		grade: 'O-4',
		insignia: '/Assets/images/ranks/navy/lieutenant-commander.png',
		title: 'Lieutenant Commander',
		abbr: 'LCDR',
		class: 'Junior Officer',
		gradetitle: '(O-4) Lieutenant Commander'
	},
	{
		grade: 'O-5',
		insignia: '/Assets/images/ranks/navy/commander.png',
		title: 'Commander',
		abbr: 'CDR',
		class: 'Senior Officer',
		gradetitle: '(O-5) Commander'
	},
	{
		grade: 'O-6',
		insignia: '/Assets/images/ranks/navy/captain.png',
		title: 'Captain',
		abbr: 'CAPT',
		class: 'Senior Officer',
		gradetitle: '(O-6) Captain'
	},
	{
		grade: 'O-7',
		insignia: '/Assets/images/ranks/navy/rear-admiral-lower-half.png',
		title: 'Rear Admiral Lower Half',
		abbr: 'RDML',
		class: 'Flag Officer',
		gradetitle: '(O-7) Rear Admiral Lower Half'
	},
	{
		grade: 'O-8',
		insignia: '/Assets/images/ranks/navy/rear-admiral.png',
		title: 'Rear Admiral',
		abbr: 'RADM',
		class: 'Flag Officer',
		gradetitle: '(O-8) Rear Admiral'
	},
	{
		grade: 'O-9',
		insignia: '/Assets/images/ranks/navy/vice-admiral.png',
		title: 'Vice Admiral',
		abbr: 'VADM',
		class: 'Flag Officer',
		gradetitle: '(O-9) Vice Admiral'
	},
	{
		grade: 'O-10',
		insignia: '/Assets/images/ranks/navy/admiral.png',
		title: 'Admiral',
		abbr: 'ADM',
		class: 'Flag Officer',
		gradetitle: '(O-10) Admiral'
	},
	{
		grade: 'O-11',
		insignia: '/Assets/images/ranks/navy/fleet-admiral.png',
		title: 'Fleet Admiral',
		abbr: 'FADM',
		class: 'Flag Officer',
		gradetitle: '(O-11) Fleet Admiral'
	}
];
