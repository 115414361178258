/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import imgsm from '../../Assets/images/logos/logo_grace_no_text_white.svg';
import imglg from '../../Assets/images/logos/logo_grace_w_text_white.svg';

const imgalttxt = process.env.REACT_APP_NAME + ' ' + process.env.REACT_APP_TITLE;

const Logo = (props) => {
	return (
		<div className={`app-logo ${props.className ? props.className : ''}`} style={props.style} title={'Global Resource Allocation and Coordination Environment'}>
			{props.className === 'small' ? <img src={imgsm} alt={imgalttxt} /> : <img src={imglg} alt={imgalttxt} />}
		</div>
	);
};

export default Logo;
