/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */
import React, { useEffect } from 'react';
import { ApiMissionRequestList, ApiMissionListByState, ApiLegsById, ApiMissionRequestErrorByRequestId } from '../../Helpers/Apis';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Row } from 'react-bootstrap';
import { stripTimeZone, forceDateStringToUTCISOFormat } from '../../Helpers/DateTimeConversions';
import { Accordion, AccordionHeading, AccordionContent } from '../../Helpers/Accordion';
import { NavLink } from 'react-router-dom';
import { GoogleMap, OverlayView, Polyline, useJsApiLoader } from '@react-google-maps/api';
import moment from 'moment';
import Loader from '../../Core/Loader';
import Marker from '../../Maps/Marker';

let missionDataLength = 0;

const SolverStatusView = (props) => {
	const [solverStatusData, setSolverStatusData] = React.useState([]);
	const [sortedSolverStatusData, setSortedSolverStatusData] = React.useState([]);
	const [googleMapLoader, setGoogleMapLoader] = React.useState({});
	const [loaded, setLoaded] = React.useState(false);

	const latitudeAvg = 39.3272;
	const longitudeAvg = -100.4708;

	const [libraries] = React.useState(['geometry', 'drawing']);

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
		libraries
	});

	// Colors Used for Legs List and Map Polylines
	let legColorsArray = ['#5cd14c', '#3f0545', '#9826ca', '#b8055d', '#edb8c0', '#733a9e', '#09c6f9', '#3d2aa6', '#a5b0f0', '#fb5e6c', '#08c526', '#9b7f31', '#e20eb4', '#45e275', '#4d62f7', '#c53079', '#38718d', '#d222c7', '#4a9b65', '#866823', '#0097a1', '#858d8b', '#e36d90', '#3d1993', '#758adf', '#8d2af6', '#b0518b', '#3f68e5', '#cfac10', '#14e4c3'];

	// Map Options
	const mapOptions = {
		// Map Styling
		style: {
			width: '100%',
			height: '100%'
		},

		center: {
			lat: latitudeAvg,
			lng: longitudeAvg
		},

		// Default Zoom Level
		zoom: 2,

		options: {
			maxZoom: 18,
			minZoom: 3,
			//disabling scroll functionality in map to help navigate solve status accordions
			scrollwheel: false
		}
	};

	const MissionMapComponent = (visualData) => {
		if (googleMapLoader) {
			return (
				<div id={visualData.name} className={'solverstatus-map'}>
					<GoogleMap mapContainerStyle={mapOptions.style} center={mapOptions.center} zoom={mapOptions.zoom} options={mapOptions.options}>
						{visualData.map((rec, i) => (
							<React.Fragment key={i}>
								<OverlayView position={{ lat: rec.lat, lng: rec.lng }} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
									<Marker
										position={{
											lat: rec.lat,
											lng: rec.lng
										}}
										type={'dot'}
										name={rec.name}
										color={rec.color}
										lat={rec.lat}
										lng={rec.lng}
									/>
								</OverlayView>

								<Polyline
									path={[
										{
											lat: rec.departureLat,
											lng: rec.departureLng
										},
										{
											lat: rec.arrivalLat,
											lng: rec.arrivalLng
										}
									]}
									options={{
										clickable: rec.clickable,
										draggable: rec.draggable,
										editable: rec.editable,
										fillColor: rec.fillColor,
										fillOpacity: rec.fillOpacity,
										geodesic: rec.geodesic,
										radius: rec.geodesic,
										strokeColor: rec.strokeColor,
										strokeOpacity: rec.strokeOpacity,
										strokeWeight: rec.strokeWeight,
										visible: rec.visible,
										zIndex: rec.zIndex
									}}
								/>
							</React.Fragment>
						))}
					</GoogleMap>
				</div>
			);
		}
	};

	useEffect(() => {
		setLoaded(false);
		//get list of mission requests
		ApiMissionRequestList().then((res) => {
			missionDataLength = res.length;

			// eslint-disable-next-line
			res.map((missionReq, i) => {
				if (missionReq.sandboxId === null) {
					let noMission = false;
					let noError = false;

					let missionResponseArr = [];
					let fatalError = false;

					let solverRequestObj = {};
					solverRequestObj.solver_configuration = {
						time_limit: missionReq.solver_configuration.time_limit
					};
					solverRequestObj.createdAt = missionReq.createdAt;
					solverRequestObj.optimization_timestamp = {
						start_time: missionReq.optimization_timestamp.start_time,
						end_time: missionReq.optimization_timestamp.end_time
					};
					solverRequestObj.createdBy = {
						firstName: missionReq.createdBy !== null && missionReq.createdBy.firstName !== null ? missionReq.createdBy.firstName : null,
						lastName: missionReq.createdBy !== null && missionReq.createdBy.lastName !== null ? missionReq.createdBy.lastName : null
					};
					solverRequestObj.id = missionReq.missions_request_id;
					solverRequestObj.requests = missionReq.requests;

					//check if mission(s) come back when calling mission list GET with missionRequestId
					let queryParam = `?missionsRequestId=${missionReq.missions_request_id}`;
					//check if we get a mission response with the requestID
					ApiMissionListByState(queryParam).then((solverResponse) => {
						if (solverResponse.length > 0) {
							solverRequestObj.solverReturnStatus = 'COMPLETED';

							//  eslint-disable-next-line
							solverResponse.map((mission) => {
								//if any missions are returned with no legs, then status is completed with errors
								if (mission.history[0].legs.length === 0) {
									solverRequestObj.solverReturnStatus = 'COMPLETED_WITH_ERROR';
								}

								let satisfiedLiftRequestsArr = [];
								let visualDataArr = [];
								let missionObj = {};
								missionObj.createdAt = mission.history[0].createdAt;
								missionObj.legs = mission.history[0].legs;
								missionObj.asset = mission.history[0].asset;
								missionObj.squadronId = mission.history[0].squadronId;
								missionObj.state = mission.history[0].state;
								missionObj.id = mission.id;
								missionObj.missionName = mission.missionName;

								//call legs api for mission, and build an array of satisfied lift requests by looping through every leg and build an array of all lift requests being satisfied
								ApiLegsById(mission.id).then((legResponse) => {
									if (legResponse.length > 0) {
										// loop through legs for each mission and build visualData
										//  eslint-disable-next-line
										legResponse.map((leg, i) => {
											//if total number of legs across all missions is zero, then status = compiled with errors

											//for each leg, build a visualData obj and push it to the visual data array. then try to set mapTest to the value of MissionMapComponent with the
											//param of the visual data array
											let visualObj = {
												name: leg.history[0].departure.airport.icao,
												lat: leg.history[0].departure.airport.latitude,
												lng: leg.history[0].departure.airport.longitude,
												color: legColorsArray[i],
												departureLat: leg.history[0].departure.airport.latitude,
												departureLng: leg.history[0].departure.airport.longitude,
												arrivalLat: leg.history[0].arrival.airport.latitude,
												arrivalLng: leg.history[0].arrival.airport.longitude,
												geodesic: true,
												strokeColor: legColorsArray[i],
												strokeOpacity: 0.7,
												strokeWeight: 3,
												fillColor: legColorsArray[i],
												fillOpacity: 0.35,
												clickable: true,
												draggable: false,
												editable: false,
												visible: true,
												zIndex: 3
											};
											visualDataArr.push(visualObj);

											if (leg.history[0].satisfiesLiftRequests.length > 0) {
												//  eslint-disable-next-line
												leg.history[0].satisfiesLiftRequests.map((liftRequestId) => {
													//check if satisfiedLRarry already includes id
													if (!satisfiedLiftRequestsArr.includes(liftRequestId)) {
														satisfiedLiftRequestsArr.push(liftRequestId);
													}
												});
											}
										});
									}
									//if leg response length is 0 (no legs for this mission) then set legs array of mission to 0
									else {
										missionObj.legs = [];
										solverRequestObj.solverReturnStatus = 'COMPLETED_WITH_ERROR';
									}
								});
								missionObj = {
									...missionObj,
									satisfiedLiftRequests: satisfiedLiftRequestsArr,
									mapTest: MissionMapComponent(visualDataArr),
									visualDataArray: visualDataArr
								};
								missionResponseArr.push(missionObj);
							});

							ApiMissionRequestErrorByRequestId(missionReq.missions_request_id).then((errorResponse) => {
								if (errorResponse.length > 0) {
									let errorArray = [];
									let liftRequestErrorArray = [];

									if (errorResponse[0].state === 'FATAL') {
										//if outermost state is fatal (highest state of all errors), then set state to failed. if not FATAL, set to completed with Errors
										solverRequestObj.solverReturnStatus = 'FAILED_TO_PROCESS';
										fatalError = true;
									}

									// eslint-disable-next-line
									errorResponse.map((error) => {
										if (error.aircraftItineraries && error.aircraftItineraries.length > 0) {
											if (!fatalError) {
												solverRequestObj.solverReturnStatus = 'COMPLETED_WITH_ERROR';
											}

											// eslint-disable-next-line
											error.aircraftItineraries.map((aircraftItinerariesErrors) => {
												//anything above info, we'll consider an error
												if (aircraftItinerariesErrors.state !== 'INFO') {
													noError = false;
												}
												errorArray.push(aircraftItinerariesErrors);
											});
										}

										if (error.liftRequests && error.liftRequests.length > 0) {
											// eslint-disable-next-line
											error.liftRequests.map((liftRequestErrors) => {
												if (liftRequestErrors.state !== 'INFO') {
													noError = false;
												}
												liftRequestErrorArray.push(liftRequestErrors);
											});
										}

										if (error.otherErrors && error.otherErrors.length > 0) {
											// eslint-disable-next-line
											error.otherErrors.map((otherError) => {
												if (otherError.state !== 'INFO') {
													noError = false;
												}
												errorArray.push(otherError);
											});
										}

										if (error.liftRequests === null && error.otherErrors === null && error.aircraftItineraries === null) {
											noError = true;
										} else {
											noError = false;
										}
									});

									solverRequestObj.errorArray = errorArray;
									solverRequestObj.liftRequestErrors = liftRequestErrorArray;
								} else {
									noError = true;
								}

								if (fatalError) {
									solverRequestObj.solverReturnStatus = 'FAILED_TO_PROCESS';
								}

								if (!noMission && !noError) {
									solverRequestObj.solverReturnStatus = 'COMPLETED_WITH_ERROR';
								}

								if (!noMission && noError) {
									solverRequestObj.solverReturnStatus = 'COMPLETED';
								}

								if (noMission && !fatalError) {
									let todayDateTime = new Date(stripTimeZone(forceDateStringToUTCISOFormat(new Date())));
									let createdAtNoTimezone = new Date(stripTimeZone(missionReq.createdAt));
									var diff = (todayDateTime.getTime() - createdAtNoTimezone.getTime()) / 1000 / (60 * 60);
									//if 4 hours have passed since submission, then set to no response. Otherwise, it is still awaiting a response
									if (Math.floor(diff) >= 4) {
										solverRequestObj.solverReturnStatus = 'NO_RESPONSE';
									} else {
										solverRequestObj.solverReturnStatus = 'AWAITING_RESPONSE';
									}
								}

								solverRequestObj = {
									...solverRequestObj,
									missionResponseArray: missionResponseArr
								};

								setSolverStatusData((solverStatusData) => [...solverStatusData, solverRequestObj]);
							});
							// else if no solver response
						} else {
							noMission = true;

							ApiMissionRequestErrorByRequestId(missionReq.missions_request_id).then((errorResponse) => {
								if (errorResponse.length > 0) {
									let errorArray = [];
									let liftRequestErrorArray = [];

									if (errorResponse[0].state === 'FATAL') {
										//if outermost state is fatal (highest state of all errors), then set state to failed. if not FATAL, set to completed with Errors
										solverRequestObj.solverReturnStatus = 'FAILED_TO_PROCESS';
										fatalError = true;
									}

									// eslint-disable-next-line
									errorResponse.map((error) => {
										if (error.aircraftItineraries && error.aircraftItineraries.length > 0) {
											// eslint-disable-next-line
											error.aircraftItineraries.map((aircraftItinerariesErrors) => {
												//anything above info we will count as "error"
												if (aircraftItinerariesErrors.state !== 'INFO') {
													noError = false;
												}
												errorArray.push(aircraftItinerariesErrors);
											});
										}

										if (error.otherErrors && error.otherErrors.length > 0) {
											// eslint-disable-next-line
											error.otherErrors.map((otherError) => {
												if (otherError.state !== 'INFO') {
													noError = false;
												}
												errorArray.push(otherError);
											});
										}

										if (error.liftRequests && error.liftRequests.length > 0) {
											// eslint-disable-next-line
											error.liftRequests.map((liftRequestErrors) => {
												if (liftRequestErrors.state !== 'INFO') {
													noError = false;
												}
												liftRequestErrorArray.push(liftRequestErrors);
											});
										}

										if (error.liftRequests === null && error.otherErrors === null && error.aircraftItineraries === null) {
											noError = true;
										} else {
											noError = false;
										}
									});

									solverRequestObj.errorArray = errorArray;
									solverRequestObj.liftRequestErrors = liftRequestErrorArray;
								} else {
									noError = true;
								}

								if (fatalError) {
									solverRequestObj.solverReturnStatus = 'FAILED_TO_PROCESS';
								}

								if (!noMission && !noError) {
									solverRequestObj.solverReturnStatus = 'COMPLETED_WITH_ERROR';
								}

								if (!noMission && noError) {
									solverRequestObj.solverReturnStatus = 'COMPLETED';
								}

								if (noMission && !fatalError) {
									let todayDateTime = new Date(stripTimeZone(forceDateStringToUTCISOFormat(new Date())));
									let createdAtNoTimezone = new Date(stripTimeZone(missionReq.createdAt));
									var diff = (todayDateTime.getTime() - createdAtNoTimezone.getTime()) / 1000 / (60 * 60);
									//if 4 hours have passed since submission, then set to no response. Otherwise, it is still awaiting a response
									if (Math.floor(diff) >= 4) {
										solverRequestObj.solverReturnStatus = 'NO_RESPONSE';
									} else {
										solverRequestObj.solverReturnStatus = 'AWAITING_RESPONSE';
									}
								}

								solverRequestObj = {
									...solverRequestObj,
									missionResponseArray: missionResponseArr
								};
								setSolverStatusData((solverStatusData) => [...solverStatusData, solverRequestObj]);
							});
						}
					});
				} else {
					missionDataLength -= 1;
				}
			});
		});

		// Used to Refresh on State Change
		setTimeout(function () {
			setLoaded(true);
		}, 1500);
	}, []);

	useEffect(() => {
		setGoogleMapLoader(isLoaded);
	}, [isLoaded]);

	useEffect(() => {
		setLoaded(false);
		setTimeout(function () {
			setLoaded(true);
		}, 1500);
	}, [solverStatusData]);

	useEffect(() => {
		if (solverStatusData.length === missionDataLength) {
			var sortedSolverStatusArr = solverStatusData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
			setSortedSolverStatusData(sortedSolverStatusArr);
		}
	}, [solverStatusData]);

	return (
		<React.Fragment>
			{loaded ? (
				<React.Fragment>
					<div className={'page-title padding-bottom-10'}>
						<h2>Solver Status</h2>
					</div>

					<div className={'solver-status-requests'} key={sortedSolverStatusData}>
						<PanelBar key={sortedSolverStatusData} keepItemsMounted={true} expandMode={'single'}>
							{sortedSolverStatusData.map((missionRequest, i) => {
								let createdAtNoTimezone = new Date(stripTimeZone(missionRequest && missionRequest.createdAt ? missionRequest.createdAt : null));
								let errorArray = missionRequest && missionRequest.errorArray && missionRequest.errorArray.length > 0 ? missionRequest.errorArray : [];
								let liftRequestErrorArray = missionRequest && missionRequest.liftRequestErrors && missionRequest.liftRequestErrors.length > 0 ? missionRequest.liftRequestErrors : [];
								let solverConfigTimeLimit = missionRequest && missionRequest.solver_configuration && missionRequest.solver_configuration.time_limit ? missionRequest.solver_configuration.time_limit : null;
								let solverConfigTimeLimitInMinutes = Math.floor(solverConfigTimeLimit / 60) * 10;

								let responseArray = missionRequest.missionResponseArray;

								let expectedResponseTime = new Date(createdAtNoTimezone);
								//add the solver configuration time limit (given in seconds) to the createdAt time
								expectedResponseTime.setSeconds(createdAtNoTimezone.getSeconds() + solverConfigTimeLimit * 10);

								let optimizationStart = missionRequest && missionRequest.optimization_timestamp && missionRequest.optimization_timestamp.start_time ? missionRequest.optimization_timestamp.start_time : null;
								let optimizationEnd = missionRequest && missionRequest.optimization_timestamp && missionRequest.optimization_timestamp.end_time ? missionRequest.optimization_timestamp.end_time : null;

								let solverTimeRangeStartFormatted = moment(optimizationStart).format('YYYY-MM-DD');
								let solverTimeRangeEndFormatted = moment(optimizationEnd).format('YYYY-MM-DD');

								let expectedResponseTimeFormatted = moment(expectedResponseTime).format('YYYY-MM-DD HH:mm');
								let submissionTimeFormatted = moment(createdAtNoTimezone).format('YYYY-MM-DD HH:mm');
								let submittedBy = `${missionRequest && missionRequest.createdBy && missionRequest.createdBy.firstName ? missionRequest.createdBy.firstName : ''} ${missionRequest && missionRequest.createdBy && missionRequest.createdBy.lastName ? missionRequest.createdBy.lastName : ''}`;
								let solverReturnStatus = missionRequest && missionRequest.solverReturnStatus;

								/* Solver Status Title */
								const missionRequestTitle = (
									<span key={i} className={'solver-info-header'}>
										<span className={'status-header-item status-date-range'}>
											<b>Time of Submission: </b> {`${submissionTimeFormatted} (UTC/Zulu)`}
											<b className={'padding-left-60'}>Expected Response Time: </b> {`${expectedResponseTimeFormatted} (UTC/Zulu)`}
										</span>

										<span className={'solver-status'}>
											{solverReturnStatus === 'COMPLETED' ? (
												<React.Fragment>
													<span className={'solver-status-lbl'}>Completed</span>
													<i className={'fas fa-circle-check'}></i>
												</React.Fragment>
											) : (
												''
											)}
											{solverReturnStatus === 'AWAITING_RESPONSE' ? (
												<React.Fragment>
													<span className={'solver-status-lbl'}>Awaiting Response</span>
													<i className={'fa-solid fa-stopwatch'}></i>
												</React.Fragment>
											) : (
												''
											)}
											{solverReturnStatus === 'COMPLETED_WITH_ERROR' ? (
												<React.Fragment>
													<span className={'solver-status-lbl'}>Completed with Errors</span>
													<i className={'fa-solid fa-triangle-exclamation'}></i>
												</React.Fragment>
											) : (
												''
											)}
											{solverReturnStatus === 'FAILED_TO_PROCESS' ? (
												<React.Fragment>
													<span className={'solver-status-lbl'}>Failed to Process</span>
													<i className={'fa-solid fa-circle-exclamation'}></i>
												</React.Fragment>
											) : (
												''
											)}
											{solverReturnStatus === 'NO_RESPONSE' ? (
												<React.Fragment>
													<span className={'solver-status-lbl'}>No Response</span>
													<i className={'fa-solid fa-hourglass-clock'}></i>
												</React.Fragment>
											) : (
												''
											)}
										</span>
									</span>
								);

								return (
									<PanelBarItem key={i} title={missionRequestTitle} customProp={missionRequest}>
										<div className={'solver-status-details'}>
											<div className={'status-details'}>
												<Row>
													<span className="grouping">
														<span className={'lbl'}>
															<i className="fa-solid fa-file-circle-exclamation"></i>Details Submited to Solver
														</span>
													</span>
												</Row>
												<Row>
													<span className="grouping">
														<span className={'lbl'}>
															<b>Time of Submission: </b>{' '}
														</span>
														<span className={'val'}>{`${submissionTimeFormatted} (UTC/Zulu)`}</span>
													</span>
												</Row>
												<Row>
													<span className="grouping">
														<span className={'lbl'}>
															<b>Expected Response: </b>
														</span>
														<span className={'val'}>{`${expectedResponseTimeFormatted} (UTC/Zulu)`}</span>
													</span>
												</Row>
												<Row>
													<span className="grouping">
														<span className={'lbl'}>
															<b>Submitted By: </b>
														</span>
														<span className={'val'}>{submittedBy}</span>
													</span>
												</Row>
												<Row>
													<span className="grouping">
														<span className={'lbl'}>
															<b>Solver Date Range: </b>
														</span>
														<span className={'val'}>{`${solverTimeRangeStartFormatted} to ${solverTimeRangeEndFormatted}`}</span>
													</span>
												</Row>
												<Row>
													<span className="grouping">
														<span className={'lbl'}>
															<b>Requested Solve Time: </b>
														</span>
														<span className={'val'}>{`${solverConfigTimeLimitInMinutes} minutes`}</span>
													</span>
												</Row>
												<Row>
													<span className="grouping last">
														<span className={'lbl'}>
															<b>Lift Requests Sent: </b>
														</span>

														{missionRequest.requests !== null && missionRequest.requests.length > 0 ? (
															<span className={'lift-request-val'}>
																{missionRequest.requests.map((request, i) => {
																	return (
																		<NavLink key={i} className={'request-link'} role={'button'} to={`/liftrequest/view/${request.id}`} title={`Click to View Lift Request ${request.id}`} target={'_blank'}>
																			<i className={'fas fa-up-right-from-square'}></i> LR#{request.id}
																		</NavLink>
																	);
																})}
															</span>
														) : (
															<span className={'lift-request-val'}>No Requests Sent</span>
														)}
													</span>
												</Row>
											</div>

											{errorArray.length > 0 && (
												<div key={i} className={'status-details'}>
													<Row>
														<span className="grouping">
															<span className={'lbl'}>
																<i className="fa-solid fa-circle-exclamation error-info"></i>
																Fatal Error Details
															</span>
														</span>
													</Row>

													{errorArray.map((error, i) => {
														return (
															<Row key={error}>
																<span className="grouping">
																	<span className={'lbl'}>
																		<b>Error Message: </b>
																	</span>
																	<span className={'val'}>
																		{error.error.split('\n').map((string, s) => (
																			<p key={s}>{string}</p>
																		))}
																	</span>
																</span>
															</Row>
														);
													})}
												</div>
											)}

											{liftRequestErrorArray.length > 0 && (
												<div className={'padding-bottom-20'}>
													<Accordion>
														<AccordionHeading>
															<div className="heading-box ss-group-header">
																<i className={'fa-solid fa-plane-departure'}></i>
																<span>Lift Request Error Details</span>
															</div>
														</AccordionHeading>
														<AccordionContent>
															{/* eslint-disable-next-line */}
															{liftRequestErrorArray.map((error, i) => {
																return (
																	<Row className={'padding-bottom-20'} key={i}>
																		<p>
																			<b>Error ID: </b> {error.id}
																		</p>
																		<p>
																			<b>Error Level: </b> {error.state}
																		</p>
																		<p>
																			<b>Error Message: </b> {error.error}
																		</p>
																	</Row>
																);
															})}
														</AccordionContent>
													</Accordion>
												</div>
											)}

											{errorArray.length > 0 && solverReturnStatus !== 'FAILED_TO_PROCESS' && (
												<div className={'padding-bottom-20'}>
													<Accordion>
														<AccordionHeading>
															<div className="heading-box ss-group-header">
																<i className={'fa-solid fa-plane-departure'}></i>
																<span>Raw Error Details</span>
															</div>
														</AccordionHeading>
														<AccordionContent>
															{/* eslint-disable-next-line */}
															{errorArray.map((error, i) => {
																return (
																	<Row key={i}>
																		<span className="grouping">
																			<span className={'lbl'}>
																				<b>{`${error.state} Error Message:`}</b>
																			</span>
																			<span className={'val'}>
																				{error.error.split('\n').map((string, s) => (
																					<p key={s}>{string}</p>
																				))}
																			</span>
																		</span>
																	</Row>
																);
															})}
														</AccordionContent>
													</Accordion>
												</div>
											)}

											{responseArray.length > 0 && (
												<div className={'status-details'}>
													<Row>
														<span className="grouping">
															<span className={'lbl'}>
																<i className="fa-solid fa-file-check"></i>Solver Response Details
															</span>
														</span>
													</Row>
													<Row>
														<span className="grouping">
															<span className={'lbl'}>
																<b>Generated Missions: </b>
															</span>
														</span>
													</Row>
													<div className={'mission-response-details'}>
														{
															// eslint-disable-next-line
															responseArray.map((mission, j) => {
																let missionCreatedAt = mission.createdAt ? mission.createdAt : null;
																let missionCreatedAtFormatted = moment(missionCreatedAt).format('YYYY-MM-DD HH:mm');
																let numMissionLegs = mission.legs ? mission.legs.length : '';
																let missionAssetTailNum = mission.asset.tailNumber ? mission.asset.tailNumber : '';
																let assetId = mission.asset.id ? mission.asset.id : '';
																let missionSquadronId = mission.squadronId ? mission.squadronId : null;
																let missionStatus = mission.state ? mission.state : '';
																let satisfiedLiftRequests = mission.satisfiedLiftRequests ? mission.satisfiedLiftRequests : [];

																return (
																	<React.Fragment key={j}>
																		<Accordion>
																			<AccordionHeading>
																				<div className="heading-box ss-group-header">
																					<i className={'fa-solid fa-plane-departure'}></i>
																					<span>{`Mission: ${mission.missionName}`}</span>
																				</div>
																			</AccordionHeading>
																			<AccordionContent>
																				<div className={'ss-group-content'}>
																					{/* content: Created On, Mission Name, Mission Status, # Legs, Squadron, Asset Tail # */}
																					<ul key={i} className={'mission-response-info-list'}>
																						<li className={'mission-info-item first'}>
																							<p>
																								<strong>Created On</strong>
																							</p>
																							<h4>{missionCreatedAtFormatted}</h4>
																						</li>
																						<li className={'mission-info-item'}>
																							<p>
																								<strong>Mission Name</strong>
																							</p>
																							<NavLink className={'request-link'} role={'button'} to={`/missionstatus/${mission.id}`} title={`Click to View Mission Status For ${mission.missionName}`} target={'_blank'}>
																								<h4>
																									<i className={'fas fa-up-right-from-square'}></i>
																									{` ${mission.missionName}`}
																								</h4>
																							</NavLink>
																						</li>
																						<li className={'mission-info-item status'}>
																							<p>
																								<strong>Mission Status</strong>
																							</p>
																							<span className={'status-pill'}>
																								<span className={`pill mission-status-${missionStatus.toLowerCase()}`}>{missionStatus}</span>
																							</span>
																						</li>
																						<li className={'mission-info-item'}>
																							<p>
																								<strong># legs</strong>
																							</p>
																							<h4>{numMissionLegs}</h4>
																						</li>
																						<li className={'mission-info-item'}>
																							<p>
																								<strong>Squadron</strong>
																							</p>
																							<NavLink className={'request-link'} role={'button'} to={`/missionstatus/${mission.id}`} title={`Click to View Squadron Details For ${missionSquadronId}`} target={'_blank'}>
																								{missionSquadronId !== null ? (
																									<h4>
																										<i className={'fas fa-up-right-from-square'}></i>
																										{` ${missionSquadronId}`}
																									</h4>
																								) : (
																									''
																								)}
																							</NavLink>
																						</li>
																						<li className={'mission-info-item'}>
																							<p>
																								<strong>Asset Tail #</strong>
																							</p>
																							<NavLink className={'request-link'} role={'button'} to={`/asset/edit/${assetId}`} title={`Click to View Asset Details For ${missionAssetTailNum}`} target={'_blank'}>
																								<h4>
																									<i className={'fas fa-up-right-from-square'}></i>
																									{` ${missionAssetTailNum}`}
																								</h4>
																							</NavLink>
																						</li>
																					</ul>
																				</div>
																				<Row>
																					<span className="grouping last">
																						<span className={'lbl'}>
																							<b>Lift Requests Satisfied: </b>
																						</span>
																						<span className={'lift-request-val'}>
																							{satisfiedLiftRequests.map((request, i) => {
																								return (
																									<NavLink key={i} className={'request-link'} role={'button'} to={`/liftrequest/view/${request}`} title={`Click to View Lift Request ${request}`} target={'_blank'}>
																										<i className={'fas fa-up-right-from-square'}></i> LR#{request}
																									</NavLink>
																								);
																							})}
																						</span>
																					</span>
																				</Row>
																				<Row>{MissionMapComponent(mission.visualDataArray)}</Row>
																			</AccordionContent>
																		</Accordion>
																	</React.Fragment>
																);
															})
														}
													</div>
												</div>
											)}
										</div>
									</PanelBarItem>
								);
							})}
						</PanelBar>
					</div>
				</React.Fragment>
			) : (
				<Loader />
			)}
		</React.Fragment>
	);
};

export default SolverStatusView;
