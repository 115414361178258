/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Form, FormElement, Field, FieldArray } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { optionsDepartmentOrAgency } from '../../../Assets/json/departmentsAndAgencies';
import { optionsGradesListNavy } from '../../../Assets/json/grades';
import { InitialValues } from '../../Helpers/LiftRequestValues';
import { filterChange } from '../../Helpers/FilterData';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { insertItem, getItems, updateItem, deleteItem } from '../../Helpers/TableHelpers/TableServices';
import { PalletCommandButtonsCell } from '../../Helpers/TableHelpers/CommandCell';
import { EditPalletLongForm } from '../../Helpers/FormHelpers/EditPalletLongForm';
import { stripTimeZone, forceDateStringToUTCISOFormat } from '../../Helpers/DateTimeConversions';

import { FormInput, FormDateTimePicker, FormTextArea, FormNumericTextBox, FormComboBox, FormSwitch } from '../../App/KendoFormElements/FormComponents';

import { priorityOfTravel, naloPriorityOfTravel, urgencyOfTravel, naloUrgencyOfTravel } from '../../../Assets/json/optionsNewLiftRequest';

import { emailValidator, phoneValidator, requiredValidator } from '../../App/KendoFormElements/Validators';
import { commercialTravelUnacceptableDescriptionValidator, completeMissionDescriptionValidator } from '../../App/KendoFormElements/Validators';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';
import LiftRequestInfoModal from '../../App/Modals/LiftRequestInfoModal';
import { ApiAirportsList, ApiUpdateLiftRequestByIdForm, ApiChangeLiftRequestState, ApiLiftRequestById, ApiOrgUnitListCount, ApiOrgUnitListPaged, ApiOrgUnitList } from '../../Helpers/Apis';
import { compareDateChangeDep, compareDateChangeArr, initialTimeWindowWarning } from '../../Helpers/DateTimeCheck';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { accessValidator } from '../../Helpers/RolesPermissions';
import Loader from '../../Core/Loader';
import { CargoColumns } from '../../Helpers/DataGrids';

let globalAirports = [];

const editField = 'inEdit';

const HazmatCell = ({ dataItem, ...props }) => {
	if (!dataItem || !dataItem.hazmat || !dataItem.hazmat.containsHazmat) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					No
				</span>
			</td>
		);
	}

	if (dataItem.hazmat && dataItem.hazmat.containsHazmat) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					Yes
				</span>
				{/* keeping in case Customer wants hazmat data in column */}
				{/* <span style={{
		display: 'inlineBlock',
		paddingLeft: '10px',
		verticalAlign: 'middle',
		lineHeight: '32px'
	  	}} className="hazmat-cell">
		  {dataItem.hazmat.hazardClassAndDivision}
		</span> */}
			</td>
		);
	}
};

const PalletFormContainerCommandCell = (props) => {
	return (
		<td>
			<button className="btn btn-primary btn-color-green padding-left-30 padding-right-30" onClick={() => props.enterEditForm(props.dataItem)}>
				Edit <i className="far fa-pencil"></i>
			</button>
		</td>
	);
};

const commandCell = (onRemove) => (props) => {
	const onClick = React.useCallback(
		(e) => {
			e.preventDefault();
			onRemove(props);
		},
		[onRemove]
	);

	return (
		<td className={'edit-actions-cell'}>
			<button className={'button btn-icon-remove btn-color-red margin-right-0 padding-top-12 padding-bottom-12 k-button k-grid-remove-command'} title={'Click to Remove Row'} onClick={onClick}>
				Remove
			</button>
		</td>
	);
};

const commentsCell = (props) => {
	return (
		<td className={'comments-cell'}>
			<Field name={`comments[${props.dataIndex}].${props.field}`} placeholder={'Comments'} component={FormTextArea} optional={false} />
		</td>
	);
};

const CommentsGrid = (fieldArrayRenderProps) => {
	const { validationMessage, visited } = fieldArrayRenderProps;

	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			fieldArrayRenderProps.onUnshift({ value: { comments: '' } });
		},
		[fieldArrayRenderProps]
	);

	const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps]);

	return (
		<div className={'lift-request-array-grid'}>
			{visited && validationMessage && <Error>{validationMessage}</Error>}

			<Grid data={fieldArrayRenderProps.value}>
				<GridToolbar>
					<button title={'Click to Add A New Comment'} className={'k-button k-primary'} onClick={onAdd}>
						Add New Comment
						<i className={'far fa-plus'}></i>
					</button>
				</GridToolbar>
				<Column field="comment" title="comment" cell={commentsCell} />
				<Column cell={commandCell(onRemove)} width="240px" />
			</Grid>
		</div>
	);
};

const icaoAltDepartureCell = (props) => {
	return (
		<td className={'summary-icao-cell no-label'}>
			<Field name={`alternateDeparture[${props.dataIndex}].${props.field}`} textField={'icaoName'} dataItemKey={'icao'} placeholder={'Select the Desired Departure ICAO...'} component={FormComboBox} data={globalAirports} allowCustom={true} />
		</td>
	);
};

const icaoAltArrivalCell = (props) => {
	return (
		<td className={'summary-icao-cell no-label'}>
			<Field name={`alternateArrival[${props.dataIndex}].${props.field}`} textField={'icaoName'} dataItemKey={'icao'} placeholder={'Select the Desired Arrival ICAO...'} component={FormComboBox} data={globalAirports} allowCustom={true} />
		</td>
	);
};

const emailCell = (props) => {
	return (
		<td className={'edit-email-cell'}>
			<Field name={`shareEmails[${props.dataIndex}].${props.field}`} placeholder={'E.g. name@domain.com'} component={FormInput} optional={false} validator={emailValidator} />
		</td>
	);
};

const AltDepartureAirportsGrid = (fieldArrayRenderProps) => {
	const { validationMessage, visited } = fieldArrayRenderProps;

	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			fieldArrayRenderProps.onUnshift({ value: '' });
		},
		[fieldArrayRenderProps.onUnshift]
	);

	const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps.onRemove]);

	return (
		<div className={'lift-request-array-grid'}>
			{visited && validationMessage && <Error>{validationMessage}</Error>}
			<Grid data={fieldArrayRenderProps.value}>
				<GridToolbar>
					<button title={'Click to Add Alternate ICAO'} className={'k-button k-primary'} onClick={onAdd}>
						Add New Alternate ICAO
						<i className={'far fa-plus'}></i>
					</button>
				</GridToolbar>
				<Column field="altAirportDep" title="Icao" cell={icaoAltDepartureCell} />
				<Column cell={commandCell(onRemove)} width="240px" />
			</Grid>
		</div>
	);
};

const ShareFormGrid = (fieldArrayRenderProps) => {
	const { validationMessage, visited } = fieldArrayRenderProps;

	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			fieldArrayRenderProps.onUnshift({ value: { shareEmail: '' } });
		},
		[fieldArrayRenderProps]
	);

	const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps]);

	return (
		<div className={'lift-request-array-grid'}>
			{visited && validationMessage && <Error>{validationMessage}</Error>}
			<Grid data={fieldArrayRenderProps.value}>
				<GridToolbar>
					<button title={'Click to Add New Email'} className={'k-button k-primary'} onClick={onAdd}>
						Add New Email
						<i className={'far fa-plus'}></i>
					</button>
				</GridToolbar>
				<Column field="email" title="Email" cell={emailCell} />
				<Column cell={commandCell(onRemove)} width="240px" />
			</Grid>
		</div>
	);
};

const AltArrivalAirportsGrid = (fieldArrayRenderProps) => {
	const { validationMessage, visited } = fieldArrayRenderProps;

	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			fieldArrayRenderProps.onUnshift({ value: '' });
		},
		[fieldArrayRenderProps.onUnshift]
	);

	const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps.onRemove]);

	return (
		<div className={'lift-request-array-grid'}>
			{visited && validationMessage && <Error>{validationMessage}</Error>}
			<Grid data={fieldArrayRenderProps.value}>
				<GridToolbar>
					<button title={'Click to Add Alternate ICAO'} className={'k-button k-primary'} onClick={onAdd}>
						Add New Alternate ICAO
						<i className={'far fa-plus'}></i>
					</button>
				</GridToolbar>
				<Column field="altAirportArr" title="ICAO Code" cell={icaoAltArrivalCell} />
				<Column cell={commandCell(onRemove)} width="240px" />
			</Grid>
		</div>
	);
};

const LiftRequestEdit = (props) => {
	const [loaded, setLoaded] = React.useState(false);
	const [liftDetails, setLiftDetails] = React.useState([]);
	const [formState, setFormState] = React.useState({});

	const [hasPallet, setHasPallet] = React.useState(false);
	const [hasPassenger, setHasPassenger] = React.useState(false);

	// eslint-disable-next-line
	const [hasHazmat, setHasHazmat] = React.useState(false);
	const [toggleModal, setToggleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalContent, setModalContent] = useState('');

	const [missionDescCount, setMissionDescCount] = React.useState('');
	const [airliftReasonCount, setAirliftReasonCount] = React.useState('');

	const [timeWindowWarningDep, setTimeWindowWarningDep] = React.useState(false);
	const [timeWindowWarningArr, setTimeWindowWarningArr] = React.useState(false);
	const [hr24LaterDateEarliestDep, setHr24LaterDateEarliestDep] = React.useState('');
	const [hr24LaterDateLatestDep, setHr24LaterDateLatestDep] = React.useState('');
	const [hr24LaterDateEarliestArr, setHr24LaterDateEarliestArr] = React.useState('');
	const [hr24LaterDateLatestArr, setHr24LaterDateLatestArr] = React.useState('');

	const [airports, setAirports] = React.useState([]);
	const [units, setUnits] = React.useState([]);
	const [unitCount, setUnitCount] = React.useState(0);
	const [page, setPage] = React.useState(0);

	const [depPriUnit, setDepPriUnit] = React.useState([]);
	const [depAltUnit, setDepAltUnit] = React.useState([]);
	const [arrPriUnit, setArrPriUnit] = React.useState([]);
	const [arrAltUnit, setArrAltUnit] = React.useState([]);
	const [srTravUnit, setSrTravUnit] = React.useState([]);
	const [requestingUnit, setRequestingUnit] = React.useState([]);
	const [trAuthOffUnit, setTrAuthOffUnit] = React.useState([]);
	const [departmentOrAgencyData, setDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [depAltDepartmentOrAgencyData, setDepAltDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [depPriDepartmentOrAgencyData, setDepPriDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [arrAltDepartmentOrAgencyData, setArrAltDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [arrPriDepartmentOrAgencyData, setArrPriDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [srTravDepartmentOrAgencyData, setSrTravDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [trAuthOffDepartmentOrAgencyData, setTrAuthOffDepartmentOrAgencyData] = React.useState(optionsDepartmentOrAgency);
	const [gradesData, setGradesData] = React.useState(optionsGradesListNavy);
	const [depAltGradesData, setDepAltGradesData] = React.useState(optionsGradesListNavy);
	const [depPriGradesData, setDepPriGradesData] = React.useState(optionsGradesListNavy);
	const [arrAltGradesData, setArrAltGradesData] = React.useState(optionsGradesListNavy);
	const [arrPriGradesData, setArrPriGradesData] = React.useState(optionsGradesListNavy);
	const [srTravGradesData, setSrTravGradesData] = React.useState(optionsGradesListNavy);
	const [trAuthOffGradesData, setTrAuthOffGradesData] = React.useState(optionsGradesListNavy);
	const [depOrAgencyloading, setDepOrAgencyloading] = React.useState(false);
	const [gradesLoading, setGradesLoading] = React.useState(false);
	const [unitsLoading, setUnitsLoading] = React.useState(false);
	const [fullUnits, setFullUnits] = React.useState(false);
	const [departureAirportsLoading, setDepartureAirportsLoading] = React.useState(false);
	const [departureAirports, setDepartureAirports] = React.useState([]);
	const [arrivalAirportsLoading, setArrivalAirportsLoading] = React.useState(false);
	const [arrivalAirports, setArrivalAirports] = React.useState([]);
	const [palletData, setPalletData] = useState([]);
	const [adding, setAdding] = useState(false);
	const [hazmatSubmitData, setHazmatSubmitData] = useState([]);
	const [openForm, setOpenForm] = React.useState(false);
	const [editItem, setEditItem] = React.useState({
		EntryID: 1
	});
	const [formChanged, setFormChanged] = React.useState(false);

	const stateRef = React.useRef();
	stateRef.current = hazmatSubmitData;

	const PalletFormCommandCell = (props) => <PalletFormContainerCommandCell {...props} enterEditForm={enterEditForm} />;

	const CargoCommandCell = (props) => <PalletCommandButtonsCell {...props} edit={enterEdit} remove={remove} add={add} discard={discard} update={update} cancel={cancel} editField={editField} />;

	const handleSubmit = (event) => {
		let newData = palletData.map((item) => {
			if (event.EntryID === item.EntryID) {
				item = {
					...event,
					description: event.description,
					oversize: event.oversize,
					rollingStock: event.rollingStock,
					bellyLoadable: event.bellyLoadable,
					hazmat: {
						...item.hazmat,
						containsHazmat: event.hazmat.containsHazmat
					}
				};
			}
			update(item);
			return item;
		});
		setPalletData(newData);
		setHazmatSubmitData(newData);
		setOpenForm(false);
	};

	const handleCancelEdit = () => {
		setOpenForm(false);
	};

	const enterEditForm = (item) => {
		setOpenForm(true);
		setEditItem(item);
	};

	// modify the data in the store, db etc
	const remove = (dataItem) => {
		const newData = [...deleteItem(dataItem, hazmatSubmitData)];
		setPalletData(newData);
		handleFormChanged();
	};

	const discardNew = () => {
		const newData = [...deleteItem(editItem, hazmatSubmitData)];
		setPalletData(newData);
		setAdding(false);
		handleFormChanged();
		setOpenForm(false);
	};

	const add = (dataItem) => {
		dataItem.inEdit = true;
		const newData = insertItem(dataItem, hazmatSubmitData);
		setPalletData(newData);
		setAdding(false);
	};

	const update = (dataItem) => {
		dataItem.inEdit = false;
		dataItem.newItem = false;
		const newData = updateItem(dataItem, hazmatSubmitData);
		setPalletData(newData);
		handleFormChanged();
	};

	// Local state operations
	const discard = () => {
		const newData = [...palletData];
		newData.splice(0, 1);
		setPalletData(newData);
		setAdding(false);
	};

	const cancel = (dataItem) => {
		const originalItem = getItems(hazmatSubmitData).find((p) => p.EntryID === dataItem.EntryID);
		const newData = palletData.map((item) => (item.EntryID === originalItem.EntryID ? originalItem : item));
		setPalletData(newData);
	};

	const enterEdit = (dataItem) => {
		const newData = palletData.map((item) =>
			item.EntryID === dataItem.EntryID
				? {
						...item,
						inEdit: true
				  }
				: item
		);
		setPalletData(newData);
		setHazmatSubmitData(newData);
		handleFormChanged();
	};

	const itemChange = (event) => {
		const newData = palletData.map((item) =>
			item.EntryID === event.dataItem.EntryID
				? {
						...item,
						[event.field || '']: event.value
				  }
				: item
		);
		setPalletData(newData);
		handleFormChanged();
	};

	const addNew = () => {
		const newDataItem = {
			inEdit: true,
			newItem: true,
			type: 'PALLETS',
			hazmat: {
				containsHazmat: false,
				hazmatCargoes: []
			}
		};
		setAdding(true);
		handleFormChanged();
		setPalletData([newDataItem, ...palletData]);
		add(newDataItem);
		setOpenForm(true);
		setEditItem(newDataItem);
	};

	const setPalletHazmatCargoesData = (dataItem, hazmatCargoesArray) => {
		let newData = palletData.map((item) => {
			if (dataItem.EntryID === item.EntryID) {
				item = {
					...item,
					hazmat: {
						...item.hazmat,

						hazmatCargoes: dataItem.hazmat.hazmatCargoes
					}
				};
			}
			return item;
		});
		setPalletData(newData);
	};

	const uicPageSize = 10;
	const params = useParams();

	const accessAuthorizationButton = ['approver'];
	const accessClaimButton = ['nalo'];
	const accessValidateButton = ['nalo'];
	const accessRegrettedButton = ['nalo'];

	const requestId = params.liftId ? params.liftId : '';

	const timeout = React.useRef();

	// dropdown query functions. call the onFilterChange helper function to activate filter action on data that is sent.
	const filterUnitsChange = (event) => {
		filterChange(event, setUnits, setUnitsLoading, fullUnits, timeout);
	};

	const filterDepPriUnitsChange = (event) => {
		filterChange(event, setDepPriUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterDepAltUnitsChange = (event) => {
		filterChange(event, setDepAltUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterArrPriUnitsChange = (event) => {
		filterChange(event, setArrPriUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterArrAltUnitsChange = (event) => {
		filterChange(event, setArrAltUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterSrTravUnitsChange = (event) => {
		filterChange(event, setSrTravUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterTrAuthOffUnitsChange = (event) => {
		filterChange(event, setTrAuthOffUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterRequestingUnitsChange = (event) => {
		filterChange(event, setRequestingUnit, setUnitsLoading, fullUnits, timeout);
	};

	const filterDepartmentOrAgencyChange = (event) => {
		filterChange(event, setDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterDepAltDepartmentOrAgencyChange = (event) => {
		filterChange(event, setDepAltDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterDepPriDepartmentOrAgencyChange = (event) => {
		filterChange(event, setDepPriDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterArrAltDepartmentOrAgencyChange = (event) => {
		filterChange(event, setArrAltDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterArrPriDepartmentOrAgencyChange = (event) => {
		filterChange(event, setArrPriDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterSrTravDepartmentOrAgencyChange = (event) => {
		filterChange(event, setSrTravDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterTrAuthOffDepartmentOrAgencyChange = (event) => {
		filterChange(event, setTrAuthOffDepartmentOrAgencyData, setDepOrAgencyloading, optionsDepartmentOrAgency, timeout);
	};

	const filterGradesChange = (event) => {
		filterChange(event, setGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterDepPriGradesChange = (event) => {
		filterChange(event, setDepPriGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterDepAltGradesChange = (event) => {
		filterChange(event, setDepAltGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterArrPriGradesChange = (event) => {
		filterChange(event, setArrPriGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterArrAltGradesChange = (event) => {
		filterChange(event, setArrAltGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterSrTravGradesChange = (event) => {
		filterChange(event, setSrTravGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterTrAuthOffGradesChange = (event) => {
		filterChange(event, setTrAuthOffGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterDepartureAirportChange = (event) => {
		filterChange(event, setDepartureAirports, setDepartureAirportsLoading, airports, timeout);
	};

	const filterArrivalAirportChange = (event) => {
		filterChange(event, setArrivalAirports, setArrivalAirportsLoading, airports, timeout);
	};
	// end of dropdown query functions

	const pageChange = (event) => {
		const currPage = event.page.skip;

		//stop before entries in uic/page length
		if (currPage < unitCount / event.page.take) {
			ApiOrgUnitListPaged(currPage, event.page.take).then((res) => {
				setUnits(res);
				setDepPriUnit(res);
				setDepAltUnit(res);
				setArrPriUnit(res);
				setArrAltUnit(res);
				setSrTravUnit(res);
				setTrAuthOffUnit(res);
				setRequestingUnit(res);
			});
			setPage(currPage);
		}
	};

	const data = {
		id: liftDetails.id ? liftDetails.id : '',
		latestVersion: liftDetails.latestVersion ? liftDetails.latestVersion : '',
		latestOwner: {
			id: liftDetails.latestOwner ? liftDetails.latestOwner.id : null,
			firstName: liftDetails.latestOwner ? liftDetails.latestOwner.firstName : null,
			lastName: liftDetails.latestOwner ? liftDetails.latestOwner.lastName : null,
			email: liftDetails.latestOwner ? liftDetails.latestOwner.email : null,
			phoneNumber: liftDetails.latestOwner ? liftDetails.latestOwner.phoneNumber : null,
			branchOfService: liftDetails.branchOfService ? liftDetails.latestOwner.branchOfService : null,
			rank: liftDetails.latestOwner ? liftDetails.latestOwner.rank : null,
			dutyTitle: liftDetails.latestOwner ? liftDetails.latestOwner.dutyTitle : null,
			unit: {
				id: liftDetails.latestOwner ? liftDetails.latestOwner.id : null,
				nra: liftDetails.latestOwner ? liftDetails.latestOwner.nra : null,
				uic: liftDetails.latestOwner ? liftDetails.latestOwner.uic : null
			}
		},
		history: liftDetails.history ? liftDetails.history : []
	};

	const lrTitle = data.history[0] ? data.history[0].requestName : requestId;

	const missionMin = 100;
	const missionMax = 3000;
	const airliftMin = 100;
	const airliftMax = 3000;

	const handleMissionDescCount = (e) => {
		setMissionDescCount(e.value);
		handleFormChanged();
	};

	const handleAirliftReasonCount = (e) => {
		setAirliftReasonCount(e.value);
		handleFormChanged();
	};

	const handleHasPallets = (e) => {
		setHasPallet(!hasPallet);
		handleFormChanged();
	};

	const handleHasPassengers = (e) => {
		setHasPassenger(!hasPassenger);
		handleFormChanged();
	};

	const handleModalToggle = () => setToggleModal(!toggleModal);

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	const handleDateChange = (e) => {
		if (e.target.props.id.includes('Arrival')) {
			compareDateChangeArr(setHr24LaterDateLatestArr, setHr24LaterDateEarliestArr, hr24LaterDateEarliestArr, hr24LaterDateLatestArr, setTimeWindowWarningArr, e);
		}
		if (e.target.props.id.includes('Departure')) {
			compareDateChangeDep(setHr24LaterDateLatestDep, setHr24LaterDateEarliestDep, hr24LaterDateEarliestDep, hr24LaterDateLatestDep, setTimeWindowWarningDep, e);
		}
		handleFormChanged();
	};

	const navigate = useNavigate();

	// On Form Submit
	const onFormSubmit = React.useCallback((event) => {
		const palletValues = stateRef.current;
		const { values } = event;

		setFormState(values);

		// Requester Details
		let formRequester = {
			/**
			 * Set Requester ID
			 * Temporary Solution: If "Requester" and not "NALO, Approver, or Squadron"
			 * Future solution is to use original requester's ID, as well as allow other requester's (shared LR) to update and save information. Additionally, NALO will eventually be able to select who is the owner of the LR. Currently, no solution to allow for multiple requester ID's for ownership. Only one owner available at this time.
			 */
			id: props.roleaccess.includes('requester') && !props.roleaccess.includes('approver') && !props.roleaccess.includes('nalo') ? props.userid : null,

			firstName: values.requesterFirstName ? values.requesterFirstName : null,
			lastName: values.requesterLastName ? values.requesterLastName : null,
			email: values.requesterEmail ? values.requesterEmail : null,
			phoneNumber: values.requesterPhone ? values.requesterPhone : null,
			branchOfService: values.requesterBranchOfService ? values.requesterBranchOfService : null,
			rank: {
				grade: values.requesterGrade && values.requesterGrade.grade ? values.requesterGrade.grade : null,
				insignia: values.requesterGrade && values.requesterGrade.insignia ? values.requesterGrade.insignia : null,
				title: values.requesterGrade && values.requesterGrade.title ? values.requesterGrade.title : null,
				abbreviation: values.requesterGrade && values.requesterGrade.abbr ? values.requesterGrade.abbr : null,
				rank: values.requesterGrade && values.requesterGrade.class ? values.requesterGrade.class : null,
				gradeTitle: values.requesterGrade && values.requesterGrade.gradetitle ? values.requesterGrade.gradetitle : null
			},
			dutyTitle: values.requesterDutyTitle ? values.requesterDutyTitle : null,
			unit: {
				id: values.requesterUnit && values.requesterUnit.id ? values.requesterUnit.id : null,
				nra: values.requesterUnit && values.requesterUnit.nra ? values.requesterUnit.nra : null,
				uic: values.requesterUnit && values.requesterUnit.uic ? values.requesterUnit.uic : null
			}
		};

		let formRequestingUnit = {
			unit: {
				id: values.requestingUnit && values.requestingUnit.id ? values.requestingUnit.id : null,
				nra: values.requestingUnit && values.requestingUnit.nra ? values.requestingUnit.nra : null,
				uic: values.requestingUnit && values.requestingUnit.uic ? values.requestingUnit.uic : null
			},
			plads: values.requestingUnitPlads ? values.requestingUnitPlads : null
		};

		let formCargoes = [];

		// PASSENGERS Object: Pushed to Form Cargo Array Before Submission
		if (values.hasPassengers) {
			let formCargoPassengers = {
				type: 'PASSENGERS',
				count: values.totalNumberOfPax ? values.totalNumberOfPax : null,
				seniorTravelers: [
					{
						firstName: values.srTravelerFName ? values.srTravelerFName : null,
						lastName: values.srTravelerLName ? values.srTravelerLName : null,
						email: values.srTravelerEmail ? values.srTravelerEmail : null,
						phoneNumber: values.srTravelerMobile ? values.srTravelerMobile : null,
						branchOfService: values.srTravelerBranchOfService ? values.srTravelerBranchOfService : null,
						rank: {
							grade: values.srTravelerGrade && values.srTravelerGrade.grade ? values.srTravelerGrade.grade : null,
							insignia: values.srTravelerGrade && values.srTravelerGrade.insignia ? values.srTravelerGrade.insignia : null,
							title: values.srTravelerGrade && values.srTravelerGrade.title ? values.srTravelerGrade.title : null,
							abbreviation: values.srTravelerGrade && values.srTravelerGrade.abbr ? values.srTravelerGrade.abbr : null,
							rank: values.srTravelerGrade && values.srTravelerGrade.class ? values.srTravelerGrade.class : null,
							gradeTitle: values.srTravelerGrade && values.srTravelerGrade.gradetitle ? values.srTravelerGrade.gradetitle : null
						},
						dutyTitle: values.srTravelerDutyTitle ? values.srTravelerDutyTitle : null,
						unit: {
							id: values.srTravelerUnit && values.srTravelerUnit.id ? values.srTravelerUnit.id : null,
							nra: values.srTravelerUnit && values.srTravelerUnit.nra ? values.srTravelerUnit.nra : null,
							uic: values.srTravelerUnit && values.srTravelerUnit.uic ? values.srTravelerUnit.uic : null
						}
					}
				]
			};
			formCargoes.push(formCargoPassengers);
		}

		// PALLETS Object: Pushed to Form Cargo Array Before Submission
		if (values.hasPallets) {
			// eslint-disable-next-line
			palletValues.map((pallet) => {
				let palletObj = {};
				palletObj = {
					type: 'PALLETS',
					cargoName: pallet.PalletName ? pallet.PalletName : null,
					count: pallet.count ? pallet.count : null,
					weight: pallet.weight ? pallet.weight : null,
					totalCubicFeet: pallet.cargoTotalCubicFeet ? pallet.cargoTotalCubicFeet : null,
					largestItem: {
						length: pallet.cargoLargestItemDimensionsLength ? pallet.cargoLargestItemDimensionsLength : null,
						width: pallet.cargoLargestItemDimensionsWidth ? pallet.cargoLargestItemDimensionsWidth : null,
						height: pallet.cargoLargestItemDimensionsHeight ? pallet.cargoLargestItemDimensionsHeight : null,
						weight: pallet.cargoLargestItemDimensionsWeight ? pallet.cargoLargestItemDimensionsWeight : null
					},
					heaviestItem: {
						length: pallet.cargoHeaviestItemLength ? pallet.cargoHeaviestItemLength : null,
						width: pallet.cargoHeaviestItemWidth ? pallet.cargoHeaviestItemWidth : null,
						height: pallet.cargoHeaviestItemHeight ? pallet.cargoHeaviestItemHeight : null,
						weight: pallet.cargoHeaviestItemWeight ? pallet.cargoHeaviestItemWeight : null
					},
					description: pallet.description ? pallet.description : null,
					oversize: pallet.oversize ? pallet.oversize : false,
					rollingStock: pallet.rollingStock ? pallet.rollingStock : false,
					bellyLoadable: pallet.bellyLoadable ? pallet.bellyLoadable : false,
					hazmat: {
						containsHazmat: pallet.hazmat && pallet.hazmat.containsHazmat ? pallet.hazmat.containsHazmat : false,
						hazmatCargoes: []
					},
					specialHandlingRequirements: pallet.specialHandlingRequirements ? pallet.specialHandlingRequirements : null
				};

				if (pallet.hazmat !== null && pallet.hazmat.containsHazmat && pallet.hazmat.hazmatCargoes.length > 0) {
					// create hazmatArray and push it to hazmatCargoes
					// eslint-disable-next-line
					pallet.hazmat.hazmatCargoes.map((hazmat) => {
						let hazmatObj = {};
						hazmatObj = {
							hazmatName: hazmat.PalletName ? hazmat.PalletName : null,
							unNumber: hazmat.unNumber ? hazmat.unNumber : null,
							shippingName: hazmat.shippingName ? hazmat.shippingName : null,
							shippingDescription: hazmat.shippingDescription ? hazmat.shippingDescription : null,
							hazardClassAndDivision: hazmat.hazardClassAndDivision ? hazmat.hazardClassAndDivision : null,
							passengerEligibilityCode: hazmat.passengerEligibilityCode ? hazmat.passengerEligibilityCode : null,
							explosiveCompatibilityGroup: hazmat.explosiveCompatibilityGroup ? hazmat.explosiveCompatibilityGroup : null,
							cargo: {
								length: hazmat.cargoLength ? hazmat.cargoLength : null,
								width: hazmat.cargoWidth ? hazmat.cargoWidth : null,
								height: hazmat.cargoHeight ? hazmat.cargoHeight : null,
								weight: hazmat.cargoWeight ? hazmat.cargoWeight : null
							},
							netExplosiveWeight: hazmat.netExplosiveWeight ? hazmat.netExplosiveWeight : null
						};
						palletObj.hazmat.hazmatCargoes.push(hazmatObj);
					});
				}
				formCargoes.push(palletObj);
			});
		}

		//comment logic, copy comment from front end, get first and last from keycloak
		let totalComments = [];
		if (liftDetails.history[0] && liftDetails.history[0].comments) {
			totalComments = liftDetails.history[0].comments;
		}
		if (values.comments) {
			values.comments.map((rec) => {
				let commentObj = {};
				commentObj.commenter = {};
				commentObj.text = rec.comment;
				commentObj.commenter.firstName = props.keycloak.profile.firstName;
				commentObj.commenter.lastName = props.keycloak.profile.lastName;
				totalComments.push(commentObj);
				return totalComments;
			});
		}

		// Main Object
		let submissionData = {
			arrival: {
				airport: values.desiredArrivalIcao ? values.desiredArrivalIcao.history[0] : {},
				alternateAirports: [],
				earliest: values.earliestArrivalDateTime ? forceDateStringToUTCISOFormat(values.earliestArrivalDateTime) : null,
				latest: values.latestArrivalDateTime ? forceDateStringToUTCISOFormat(values.latestArrivalDateTime) : null,
				poc: {
					alternate: {
						branchOfService: values.pocArrivalBranchOfServiceAlt ? values.pocArrivalBranchOfServiceAlt : null,
						dutyTitle: values.pocArrivalPassengerDutyTitleAlt ? values.pocArrivalPassengerDutyTitleAlt : null,
						email: values.pocArrivalPassengerEmailAlt ? values.pocArrivalPassengerEmailAlt : null,
						firstName: values.pocArrivalPassengerFNameAlt ? values.pocArrivalPassengerFNameAlt : null,
						lastName: values.pocArrivalPassengerLNameAlt ? values.pocArrivalPassengerLNameAlt : null,
						phoneNumber: values.pocArrivalPassengerMobileAlt ? values.pocArrivalPassengerMobileAlt : null,
						rank: {
							grade: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.grade ? values.pocArrivalPassengerGradeAlt.grade : null,
							insignia: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.insignia ? values.pocArrivalPassengerGradeAlt.insignia : null,
							title: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.title ? values.pocArrivalPassengerGradeAlt.title : null,
							abbreviation: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.abbr ? values.pocArrivalPassengerGradeAlt.abbr : null,
							rank: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.class ? values.pocArrivalPassengerGradeAlt.class : null,
							gradeTitle: values.pocArrivalPassengerGradeAlt && values.pocArrivalPassengerGradeAlt.gradetitle ? values.pocArrivalPassengerGradeAlt.gradetitle : null
						},
						unit: {
							id: values.pocArrivalPassengerUnitAlt && values.pocArrivalPassengerUnitAlt.id ? values.pocArrivalPassengerUnitAlt.id : null,
							nra: values.pocArrivalPassengerUnitAlt && values.pocArrivalPassengerUnitAlt.nra ? values.pocArrivalPassengerUnitAlt.nra : null,
							uic: values.pocArrivalPassengerUnitAlt && values.pocArrivalPassengerUnitAlt.uic ? values.pocArrivalPassengerUnitAlt.uic : null
						}
					},
					primary: {
						branchOfService: values.pocArrivalBranchOfServicePri ? values.pocArrivalBranchOfServicePri : null,
						dutyTitle: values.pocArrivalPassengerDutyTitlePri ? values.pocArrivalPassengerDutyTitlePri : null,
						email: values.pocArrivalPassengerEmailPri ? values.pocArrivalPassengerEmailPri : null,
						firstName: values.pocArrivalPassengerFNamePri ? values.pocArrivalPassengerFNamePri : null,
						lastName: values.pocArrivalPassengerLNamePri ? values.pocArrivalPassengerLNamePri : null,
						phoneNumber: values.pocArrivalPassengerMobilePri ? values.pocArrivalPassengerMobilePri : null,
						rank: {
							grade: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.grade ? values.pocArrivalPassengerGradePri.grade : null,
							insignia: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.insignia ? values.pocArrivalPassengerGradePri.insignia : null,
							title: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.title ? values.pocArrivalPassengerGradePri.title : null,
							abbreviation: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.abbr ? values.pocArrivalPassengerGradePri.abbr : null,
							rank: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.class ? values.pocArrivalPassengerGradePri.class : null,
							gradeTitle: values.pocArrivalPassengerGradePri && values.pocArrivalPassengerGradePri.gradetitle ? values.pocArrivalPassengerGradePri.gradetitle : null
						},
						unit: {
							id: values.pocArrivalPassengerUnitPri && values.pocArrivalPassengerUnitPri.id ? values.pocArrivalPassengerUnitPri.id : null,
							nra: values.pocArrivalPassengerUnitPri && values.pocArrivalPassengerUnitPri.nra ? values.pocArrivalPassengerUnitPri.nra : null,
							uic: values.pocArrivalPassengerUnitPri && values.pocArrivalPassengerUnitPri.uic ? values.pocArrivalPassengerUnitPri.uic : null
						}
					}
				}
			},
			cargoes: [],
			commandingOfficer: {
				email: values.trAuthOffEmail ? values.trAuthOffEmail : null,
				firstName: values.trAuthOffFName ? values.trAuthOffFName : null,
				lastName: values.trAuthOffLName ? values.trAuthOffLName : null,
				phoneNumber: values.trAuthOffMobile ? values.trAuthOffMobile : null,
				dutyTitle: values.trAuthOffDutyTitle ? values.trAuthOffDutyTitle : null,
				branchOfService: values.trAuthOffBranchOfService ? values.trAuthOffBranchOfService : null,
				rank: {
					grade: values.trAuthOffGrade && values.trAuthOffGrade.grade ? values.trAuthOffGrade.grade : null,
					insignia: values.trAuthOffGrade && values.trAuthOffGrade.insignia ? values.trAuthOffGrade.insignia : null,
					title: values.trAuthOffGrade && values.trAuthOffGrade.title ? values.trAuthOffGrade.title : null,
					abbreviation: values.trAuthOffGrade && values.trAuthOffGrade.abbr ? values.trAuthOffGrade.abbr : null,
					rank: values.trAuthOffGrade && values.trAuthOffGrade.class ? values.trAuthOffGrade.class : null,
					gradeTitle: values.trAuthOffGrade && values.trAuthOffGrade.gradetitle ? values.trAuthOffGrade.gradetitle : null
				},
				unit: {
					id: values.trAuthOffUnit && values.trAuthOffUnit.id ? values.trAuthOffUnit.id : null,
					nra: values.trAuthOffUnit && values.trAuthOffUnit.nra ? values.trAuthOffUnit.nra : null,
					uic: values.trAuthOffUnit && values.trAuthOffUnit.uic ? values.trAuthOffUnit.uic : null
				}
			},
			comments: totalComments,
			commercialCost: values.commercialCost ? values.commercialCost : null,
			departure: {
				airport: values.desiredDepartureIcao ? values.desiredDepartureIcao.history[0] : {},
				alternateAirports: [],
				earliest: values.earliestDepartureDateTime ? forceDateStringToUTCISOFormat(values.earliestDepartureDateTime) : null,
				latest: values.latestDepartureDateTime ? forceDateStringToUTCISOFormat(values.latestDepartureDateTime) : null,
				poc: {
					alternate: {
						branchOfService: values.pocDepartureBranchOfServiceAlt ? values.pocDepartureBranchOfServiceAlt : null,
						dutyTitle: values.pocDeparturePassengerDutyTitleAlt ? values.pocDeparturePassengerDutyTitleAlt : null,
						email: values.pocDeparturePassengerEmailAlt ? values.pocDeparturePassengerEmailAlt : null,
						firstName: values.pocDeparturePassengerFNameAlt ? values.pocDeparturePassengerFNameAlt : null,
						lastName: values.pocDeparturePassengerLNameAlt ? values.pocDeparturePassengerLNameAlt : null,
						phoneNumber: values.pocDeparturePassengerMobileAlt ? values.pocDeparturePassengerMobileAlt : null,
						rank: {
							grade: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.grade ? values.pocDeparturePassengerGradeAlt.grade : null,
							insignia: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.insignia ? values.pocDeparturePassengerGradeAlt.insignia : null,
							title: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.title ? values.pocDeparturePassengerGradeAlt.title : null,
							abbreviation: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.abbr ? values.pocDeparturePassengerGradeAlt.abbr : null,
							rank: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.class ? values.pocDeparturePassengerGradeAlt.class : null,
							gradeTitle: values.pocDeparturePassengerGradeAlt && values.pocDeparturePassengerGradeAlt.gradetitle ? values.pocDeparturePassengerGradeAlt.gradetitle : null
						},
						unit: {
							id: values.pocDeparturePassengerUnitAlt && values.pocDeparturePassengerUnitAlt.id ? values.pocDeparturePassengerUnitAlt.id : null,
							nra: values.pocDeparturePassengerUnitAlt && values.pocDeparturePassengerUnitAlt.nra ? values.pocDeparturePassengerUnitAlt.nra : null,
							uic: values.pocDeparturePassengerUnitAlt && values.pocDeparturePassengerUnitAlt.uic ? values.pocDeparturePassengerUnitAlt.uic : null
						}
					},
					primary: {
						branchOfService: values.pocDepartureBranchOfServicePri ? values.pocDepartureBranchOfServicePri : null,
						dutyTitle: values.pocDeparturePassengerDutyTitlePri ? values.pocDeparturePassengerDutyTitlePri : null,
						email: values.pocDeparturePassengerEmailPri ? values.pocDeparturePassengerEmailPri : null,
						firstName: values.pocDeparturePassengerFNamePri ? values.pocDeparturePassengerFNamePri : null,
						lastName: values.pocDeparturePassengerLNamePri ? values.pocDeparturePassengerLNamePri : null,
						phoneNumber: values.pocDeparturePassengerMobilePri ? values.pocDeparturePassengerMobilePri : null,
						rank: {
							grade: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.grade ? values.pocDeparturePassengerGradePri.grade : null,
							insignia: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.insignia ? values.pocDeparturePassengerGradePri.insignia : null,
							title: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.title ? values.pocDeparturePassengerGradePri.title : null,
							abbreviation: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.abbr ? values.pocDeparturePassengerGradePri.abbr : null,
							rank: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.class ? values.pocDeparturePassengerGradePri.class : null,
							gradeTitle: values.pocDeparturePassengerGradePri && values.pocDeparturePassengerGradePri.gradetitle ? values.pocDeparturePassengerGradePri.gradetitle : null
						},
						unit: {
							id: values.pocDeparturePassengerUnitPri && values.pocDeparturePassengerUnitPri.id ? values.pocDeparturePassengerUnitPri.id : null,
							nra: values.pocDeparturePassengerUnitPri && values.pocDeparturePassengerUnitPri.nra ? values.pocDeparturePassengerUnitPri.nra : null,
							uic: values.pocDeparturePassengerUnitPri && values.pocDeparturePassengerUnitPri.uic ? values.pocDeparturePassengerUnitPri.uic : null
						}
					}
				}
			},
			justification: values.justification ? values.justification : null,
			missionDescription: values.completeMissionDescription ? values.completeMissionDescription : null,
			priority: values.priority ? values.priority.value : null,
			requester: formRequester,
			requestingUnit: formRequestingUnit,
			requestName: values.requestName ? values.requestName : null,
			shareEmails: [],
			state: values.state ? values.state : 'SUBMITTED',
			urgency: values.urgency ? values.urgency.value : null
		};

		// Push alternateDeparture to Array
		if (values.alternateDeparture) {
			values.alternateDeparture.map((rec) => {
				submissionData.departure.alternateAirports.push(rec.altAirportDep);
				return submissionData.departure.alternateAirports;
			});
		}

		// Push alternateArrival to Array
		if (values.alternateArrival) {
			values.alternateArrival.map((rec) => {
				submissionData.arrival.alternateAirports.push(rec.altAirportArr);
				return submissionData.arrival.alternateAirports;
			});
		}

		submissionData.cargoes = formCargoes;

		// Click Event: Save Draft
		if (event.event.target.innerText === 'SAVE DRAFT' && values.state === 'OPENED') {
			// Set State: OPENED - Redundant, but just in case
			submissionData.state = 'OPENED';

			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log(submissionData);
			}

			ApiUpdateLiftRequestByIdForm(params.liftId, submissionData).then((res) => {
				toast.success('Lift Request Draft Saved Successfully!');
				navigate('/dashboard');
			});
		}

		// Click Event: Submitted
		if (event.event.target.innerText === 'SUBMIT AIRLIFT REQUEST') {
			// If user is NALO and ONLY the LR name has been updated (nothing else) then leave state as claimed/validated. OTHERWISE update state to SUBMITTED (catch to submitted if state is null)
			if (props.roleaccess.includes('nalo') && !formChanged) {
				submissionData.state = liftDetails.history && liftDetails.history[0].state ? liftDetails.history[0].state : 'SUBMITTED';
			} else {
				submissionData.state = 'SUBMITTED';
			}

			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log(submissionData);
			}

			ApiUpdateLiftRequestByIdForm(params.liftId, submissionData).then((res) => {
				toast.success('Lift Request Submitted Successfully!');
				navigate('/dashboard');
			});
		}
	});

	const handleFormChanged = () => {
		setFormChanged(true);
	};

	// Load Data to Form on Load
	const dataLoad = () => {
		ApiLiftRequestById(requestId).then((res) => {
			if (res.history[0].arrival !== null && res.history[0].departure !== null) {
				initialTimeWindowWarning(setTimeWindowWarningDep, setTimeWindowWarningArr, res);
			}
			if (res.history[0]?.arrival?.earliest) {
				res.history[0].arrival.earliest = stripTimeZone(res.history[0].arrival.earliest);
			}
			if (res.history[0]?.arrival?.latest) {
				res.history[0].arrival.latest = stripTimeZone(res.history[0].arrival.latest);
			}
			if (res.history[0]?.departure?.earliest) {
				res.history[0].departure.earliest = stripTimeZone(res.history[0].departure.earliest);
			}
			if (res.history[0]?.departure?.latest) {
				res.history[0].departure.latest = stripTimeZone(res.history[0].departure.latest);
			}

			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log('lift request by id: ', res);
			}

			// Requester: Grade/Rank - Generate Object
			if (res.history[0]?.requester?.rank) {
				optionsGradesListNavy.forEach((ele) => {
					if (ele.title === res.history[0].requester.rank) {
						res.history[0].requester.rank = {
							grade: ele.grade,
							insignia: ele.insignia,
							title: ele.title,
							abbr: ele.abbr,
							class: ele.class,
							gradetitle: ele.gradetitle
						};
					}
				});
			}

			// Priority: Generate Object
			if (res.history[0]?.priority) {
				if (props.roleaccess.includes('nalo')) {
					naloPriorityOfTravel.forEach((ele) => {
						if (ele.value === res.history[0].priority) {
							res.history[0].priority2 = {
								label: ele.label,
								value: ele.value,
								state: ele.state,
								notes: ele.notes
							};
						}
					});
				} else {
					priorityOfTravel.forEach((ele) => {
						if (ele.value === res.history[0].priority) {
							res.history[0].priority2 = {
								label: ele.label,
								value: ele.value,
								state: ele.state,
								notes: ele.notes
							};
						}
					});
				}
			}

			// Urgency: Generate Object
			if (res.history[0]?.urgency) {
				if (props.roleaccess.includes('nalo')) {
					naloUrgencyOfTravel.forEach((ele) => {
						if (ele.value === res.history[0].urgency) {
							res.history[0].urgency2 = {
								label: ele.label,
								value: ele.value,
								state: ele.state,
								notes: ele.notes
							};
						}
					});
				} else {
					urgencyOfTravel.forEach((ele) => {
						if (ele.value === res.history[0].urgency) {
							res.history[0].urgency2 = {
								label: ele.label,
								value: ele.value,
								state: ele.state,
								notes: ele.notes
							};
						}
					});
				}
			}

			// Departure POC Primary: Grade/Rank - Generate Object
			if (res.history[0]?.departure?.poc?.primary?.rank) {
				optionsGradesListNavy.forEach((ele) => {
					if (ele.title === res.history[0]?.departure?.poc?.primary?.rank) {
						res.history[0].departure.poc.primary.rank = {
							grade: ele.grade,
							insignia: ele.insignia,
							title: ele.title,
							abbr: ele.abbr,
							class: ele.class,
							gradetitle: ele.gradetitle
						};
					}
				});
			}

			// Departure POC Alternate: Grade/Rank - Generate Object
			if (res.history[0]?.departure?.poc?.alternate?.rank) {
				optionsGradesListNavy.forEach((ele) => {
					if (ele.title === res.history[0]?.departure?.poc?.alternate?.rank) {
						res.history[0].departure.poc.alternate.rank = {
							grade: ele.grade,
							insignia: ele.insignia,
							title: ele.title,
							abbr: ele.abbr,
							class: ele.class,
							gradetitle: ele.gradetitle
						};
					}
				});
			}

			// Arrival POC Primary: Grade/Rank - Generate Object
			if (res.history[0]?.arrival?.poc?.primary?.rank) {
				optionsGradesListNavy.forEach((ele) => {
					if (ele.title === res.history[0]?.arrival?.poc?.primary?.rank) {
						res.history[0].arrival.poc.primary.rank = {
							grade: ele.grade,
							insignia: ele.insignia,
							title: ele.title,
							abbr: ele.abbr,
							class: ele.class,
							gradetitle: ele.gradetitle
						};
					}
				});
			}

			// Arrival POC Alternate: Grade/Rank - Generate Object
			if (res.history[0]?.arrival?.poc?.alternate?.rank) {
				optionsGradesListNavy.forEach((ele) => {
					if (ele.title === res.history[0]?.arrival?.poc?.alternate?.rank) {
						res.history[0].arrival.poc.alternate.rank = {
							grade: ele.grade,
							insignia: ele.insignia,
							title: ele.title,
							abbr: ele.abbr,
							class: ele.class,
							gradetitle: ele.gradetitle
						};
					}
				});
			}

			// Share Emails: Generate Object and Array
			if (res.history[0]?.shareEmails) {
				const emailArray = [];
				res.history[0]?.shareEmails.forEach((email) => {
					const emailObject = {};
					emailObject.email = email;
					emailArray.push(emailObject);
				});
				res.history[0].shareEmailsObjects = emailArray;
			}

			// Departure: Alternate Airports
			if (res.history[0].departure !== null && res.history[0].departure.alternateAirports[0] !== null) {
				const airportArray = [];
				res.history[0].departure.alternateAirports.forEach((airport) => {
					const airportObject = {};
					airportObject.airport = airport;
					airportArray.push(airportObject);
				});
				res.history[0].departure.alternateDepartureObjects = airportArray;
			}

			// Arrival: Alternate Airports
			if (res.history[0].arrival !== null && res.history[0].arrival.alternateAirports[0] !== null) {
				const airportArray = [];
				res.history[0].arrival.alternateAirports.forEach((airport) => {
					const airportObject = {};
					airportObject.airport = airport;
					airportArray.push(airportObject);
				});
				res.history[0].arrival.alternateArrivalObjects = airportArray;
			}

			// Set Values for Has Pallets and Pax Toggle
			if (res.history[0] !== undefined && res.history[0].cargoes !== undefined) {
				// eslint-disable-next-line
				res.history[0].cargoes.map((cargo) => {
					if (cargo.type === 'PASSENGERS') {
						setHasPassenger(true);
					}
					if (cargo.type === 'PALLETS') {
						setHasPallet(true);
					}
				});
			}

			// Set Value for Has Hazmat Toggle
			if (res.history && res.history[0].cargoes && res.history[0].cargoes[0] !== null && res.history[0].cargoes[0] !== undefined && res.history[0].cargoes[0].hazmat && res.history[0].cargoes[0].hazmat !== null && res.history[0].cargoes[0].hazmat !== undefined && res.history[0].cargoes[0].hazmat.containsHazmat && res.history[0].cargoes[0].hazmat.containsHazmat !== null && res.history[0].cargoes[0].hazmat.containsHazmat !== undefined && res.history[0].cargoes[0].type === 'PALLETS') {
				setHasHazmat(true);
			} else if (res.history && res.history[0].cargoes && res.history[0].cargoes[1] !== null && res.history[0].cargoes[1] !== undefined && res.history[0].cargoes[1].hazmat && res.history[0].cargoes[1].hazmat !== null && res.history[0].cargoes[1].hazmat !== undefined && res.history[0].cargoes[1].hazmat.containsHazmat && res.history[0].cargoes[1].hazmat.containsHazmat !== null && res.history[0].cargoes[1].hazmat.containsHazmat !== undefined && res.history[0].cargoes[1].type === 'PALLETS') {
				setHasHazmat(true);
			}

			// Senior Traveler: Grade/Rank - Generate Object
			if (res.history[0] !== null && res.history[0].cargoes[0] !== null && res.history[0].cargoes[0] !== undefined && res.history[0].cargoes[0].seniorTravelers !== null && res.history[0].cargoes[0].seniorTravelers !== undefined && res.history[0].cargoes[0].seniorTravelers[0].rank !== null) {
				optionsGradesListNavy.forEach((ele) => {
					if (ele.title === res.history[0]?.cargoes[0]?.seniorTravelers[0]?.rank) {
						res.history[0].cargoes[0].seniorTravelers[0].rank = {
							grade: ele.grade,
							insignia: ele.insignia,
							title: ele.title,
							abbr: ele.abbr,
							class: ele.class,
							gradetitle: ele.gradetitle
						};
					}
				});
			}

			//create array of pallets
			let palletArray = [];

			if (res.history && res.history[0].cargoes && res.history[0].cargoes !== null && res.history[0].cargoes !== undefined) {
				// eslint-disable-next-line
				res.history[0].cargoes.map((cargo, i) => {
					if (cargo.type === 'PALLETS') {
						palletArray.push(cargo);
					}
				});
			}

			let palletGridArray = [];

			// eslint-disable-next-line
			palletArray.map((pallet, i) => {
				pallet['EntryID'] = i + 1;
				pallet['PalletName'] = pallet.cargoName ? pallet.cargoName : i + 1;
				pallet['cargoLargestItemDimensionsLength'] = pallet.largestItem && pallet.largestItem.length ? pallet.largestItem.length : null;
				pallet['cargoLargestItemDimensionsWidth'] = pallet.largestItem && pallet.largestItem.width ? pallet.largestItem.width : null;
				pallet['cargoLargestItemDimensionsHeight'] = pallet.largestItem && pallet.largestItem.height ? pallet.largestItem.height : null;
				pallet['cargoLargestItemDimensionsWeight'] = pallet.largestItem && pallet.largestItem.weight ? pallet.largestItem.weight : null;
				pallet['cargoHeaviestItemLength'] = pallet.heaviestItem && pallet.heaviestItem.length ? pallet.heaviestItem.length : null;
				pallet['cargoHeaviestItemWidth'] = pallet.heaviestItem && pallet.heaviestItem.width ? pallet.heaviestItem.width : null;
				pallet['cargoHeaviestItemHeight'] = pallet.heaviestItem && pallet.heaviestItem.height ? pallet.heaviestItem.height : null;
				pallet['cargoHeaviestItemWeight'] = pallet.heaviestItem && pallet.heaviestItem.weight ? pallet.heaviestItem.weight : null;
				pallet['cargoTotalCubicFeet'] = pallet.heaviestItem && pallet.heaviestItem.weight ? pallet.heaviestItem.weight : null;
				if (pallet.hazmat && pallet.hazmat.containsHazmat && pallet.hazmat.hazmatCargoes) {
					let hazmatArray = [];
					// eslint-disable-next-line
					pallet.hazmat.hazmatCargoes.map((hazmat, n) => {
						hazmat['EntryID'] = n + 1;
						hazmat['PalletName'] = hazmat.hazmatName ? hazmat.hazmatName : n + 1;
						hazmat['cargoWeight'] = hazmat.cargo && hazmat.cargo.weight ? hazmat.cargo.weight : null;
						hazmat['cargoHeight'] = hazmat.cargo && hazmat.cargo.height ? hazmat.cargo.height : null;
						hazmat['cargoLength'] = hazmat.cargo && hazmat.cargo.length ? hazmat.cargo.length : null;
						hazmat['cargoWidth'] = hazmat.cargo && hazmat.cargo.width ? hazmat.cargo.width : null;
						hazmatArray.push(hazmat);
					});
					pallet.hazmatCargoes = hazmatArray;
				}
				palletGridArray.push(pallet);
			});

			setPalletData(palletGridArray);
			setHazmatSubmitData(palletGridArray);

			setLiftDetails(res);

			setLoaded(true);
		});

		ApiOrgUnitList().then((res) => {
			setFullUnits(res);
		});

		ApiAirportsList().then((res) => {
			setAirports(res);
			setDepartureAirports(res);
			setArrivalAirports(res);
			globalAirports = res.map((airportObj) => airportObj.history[0]);
		});
		ApiOrgUnitListPaged(0, uicPageSize).then((res) => {
			setUnits(res);
			setDepPriUnit(res);
			setDepAltUnit(res);
			setArrPriUnit(res);
			setArrAltUnit(res);
			setSrTravUnit(res);
			setTrAuthOffUnit(res);
			setRequestingUnit(res);
		});
		ApiOrgUnitListCount().then((res) => {
			setUnitCount(res);
		});
	};

	useEffect(() => {
		dataLoad();
	}, [props]);

	useEffect(() => {
		setLiftDetails(liftDetails);
	}, [liftDetails]);

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						{accessValidator(props.roleaccess, accessAuthorizationButton) && data.history.length && data.history[0] !== undefined && data.history[0].state !== undefined && data.history[0].state === 'SUBMITTED' && (
							<NavLink
								className={'nav-link'}
								role={'button'}
								to={`#`}
								title={`Click to Authorize Lift Request`}
								onClick={() => {
									ApiChangeLiftRequestState(params.liftId, 'AUTHORIZED', props.token).then((res) => {
										toast.success('Successfully Authorized Lift Request!');
										navigate('/liftrequestslist');
									});
								}}>
								<i className={'fa fa-file-exclamation'}></i>
								Authorize Request
							</NavLink>
						)}

						{/* Access: Nalo - Button to Claim Authorized Lift Requests */}
						{accessValidator(props.roleaccess, accessClaimButton) && data.history.length && data.history[0] !== undefined && data.history[0].state !== undefined && data.history[0].state === 'AUTHORIZED' && (
							<NavLink
								className={'nav-link'}
								role={'button'}
								to={`#`}
								title={`Click to Claim Lift Request`}
								onClick={() => {
									ApiChangeLiftRequestState(params.liftId, 'CLAIMED', props.token);
								}}>
								<i className="fa-solid fa-file-check"></i>
								Claim Lift Request
							</NavLink>
						)}

						{/* Access: Nalo - Button to Regret Lift Requests */}
						{accessValidator(props.roleaccess, accessRegrettedButton) && data.history.length && data.history[0] !== undefined && data.history[0].state !== undefined && data.history[0].state !== 'IN_TRANSIT' && data.history[0].state !== 'SATISFIED' && data.history[0].state !== 'REGRETTED' && data.history[0].state !== 'CANCELLED' && (
							<NavLink
								className={'nav-link'}
								role={'button'}
								to={`#`}
								title={`Click to Regret Lift Request`}
								onClick={() => {
									ApiChangeLiftRequestState(params.liftId, 'REGRETTED', props.token);
								}}>
								<i className="fa-solid fa-ban"></i>
								Regret Lift Request
							</NavLink>
						)}

						{/* Access: Nalo - Button to Validate Lift Requests */}
						{accessValidator(props.roleaccess, accessValidateButton) && data.history.length && data.history[0] !== undefined && data.history[0].state !== undefined && data.history[0].state === 'CLAIMED' && (
							<NavLink className={'nav-link'} role={'button'} to={`/validation/${params.liftId}`} title={'Validate Lift Request'}>
								<i className="fa-regular fa-ballot-check"></i>
								<span className={'sr-only'}>Click to Validate ${params.liftId}</span>
								Validate Lift Request
							</NavLink>
						)}

						<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/view/${requestId}`}>
							<i className={'fa fa-eye'}></i>
							Read Only View
						</NavLink>

						<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/history/${params.liftId}`}>
							<i className={'fa fa-rectangle-history'}></i>
							View Request History
						</NavLink>

						{/* Print and export button placeholders to be implemented in ALO-549 */}
						{/* <Nav.Link to={"#print"}>
                            <i className={"fa fa-print"}></i>
                            Print
                        </Nav.Link>
                        <Nav.Link to={"#export"}>
                            <i className={"fa fa-file-export"}></i>
                            Export
                        </Nav.Link> */}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						{loaded ? (
							<React.Fragment>
								<div className={'page-title'}>
									<h2>Lift Request: {lrTitle}</h2>

									{PageTitleControls()}
								</div>

								<div className={'lift-request-meta-data'}>
									<ul>
										<li>Status: {data.history[0] && <span className={`pill lift-status-${data.history[0].state.toLowerCase()}`}>{data.history[0].state}</span>}</li>
										<li>Priority: {data.history[0] && data.history[0].priority}</li>
										<li>Version of Request: {data.history && String(data.history.length)}</li>
									</ul>
								</div>

								<Form
									onSubmitClick={onFormSubmit}
									key={liftDetails}
									initialValues={InitialValues(formState, liftDetails)}
									render={(formRenderProps) => (
										<div className={'lift-request-form long-form'}>
											<div className={'lift-request-form-inner'}>
												<FormElement>
													{/* Requester Information: Start */}
													<Row id={'requester-information'} className={'form-section'}>
														<div className={'col-12 form-section-title priority-and-urgency'}>
															<h3>Requester Information</h3>
														</div>

														<div className={'col-3'}>
															<Field key={'requesterFirstName'} onChange={handleFormChanged} id={'requesterFirstName'} name={'requesterFirstName'} label={'First Name'} placeholder={'First Name'} component={FormInput} />
														</div>

														<div className={'col-3'}>
															<Field key={'requesterLastName'} onChange={handleFormChanged} id={'requesterLastName'} name={'requesterLastName'} label={'Last Name'} placeholder={'Last Name'} component={FormInput} />
														</div>

														<div className={'col-3'}>
															<div className={'form-group'}>
																<Field key={'requesterEmail'} onChange={handleFormChanged} id={'requesterEmail'} name={'requesterEmail'} label={'Your Email'} placeholder={''} component={FormInput} />
															</div>
														</div>

														<div className={'col-3'}>
															<div className={'form-group'}>
																<Field key={'requesterPhone'} onChange={handleFormChanged} id={'requesterPhone'} name={'requesterPhone'} label={'Phone Number'} placeholder={'Please include country code.'} component={FormInput} validator={phoneValidator} />
															</div>
														</div>

														<div className={'col-3'}>
															<div className={'form-group'}>
																<Field key={'requesterBranchOfService'} onChange={handleFormChanged} id={'requesterBranchOfService'} name={'requesterBranchOfService'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={departmentOrAgencyData} filterable={true} onFilterChange={filterDepartmentOrAgencyChange} />
															</div>
														</div>

														<div className={'col-3'}>
															<div className={'form-group'}>
																<Field key={'requesterGrade'} onChange={handleFormChanged} id={'requesterGrade'} name={'requesterGrade'} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={gradesData} loading={gradesLoading} filterable={true} onFilterChange={filterGradesChange} />
															</div>
														</div>

														<div className={'col-3'}>
															<div className={'form-group'}>
																<Field key={'requesterDutyTitle'} onChange={handleFormChanged} id={'requesterDutyTitle'} name={'requesterDutyTitle'} label={'Duty Title'} placeholder={''} component={FormInput} />
															</div>
														</div>

														<div className={'col-3'}>
															<div className={'form-group'}>
																<Field
																	key={'requesterUnit'}
																	onChange={handleFormChanged}
																	id={'requesterUnit'}
																	name={'requesterUnit'}
																	label={'Unit'}
																	textField={'uicnra'}
																	dataItemKey={'uic'}
																	placeholder={'Unit...'}
																	component={FormComboBox}
																	allowCustom={true}
																	data={units}
																	value={units}
																	virtual={{
																		total: unitCount,
																		pageSize: uicPageSize,
																		skip: page
																	}}
																	onPageChange={pageChange}
																	loading={unitsLoading}
																	filterable={true}
																	onFilterChange={filterUnitsChange}
																/>
															</div>
														</div>

														<div className={'col-12 form-section-title priority-and-urgency'}>
															<h3>Requesting Unit</h3>
														</div>

														<div className={'col-12'}>
															<p className={'padding-bottom-0 padding-top-0'}>If requesting unit is different from the requester's, then please enter requesting unit and PLADS information below.</p>
														</div>

														<div className={'col-3'}>
															<div className={'form-group'}>
																<Field
																	key={'requestingUnit'}
																	onChange={handleFormChanged}
																	id={'requestingUnit'}
																	name={'requestingUnit'}
																	label={'Unit'}
																	textField={'uicnra'}
																	dataItemKey={'uic'}
																	placeholder={'Unit...'}
																	component={FormComboBox}
																	allowCustom={true}
																	data={requestingUnit}
																	value={units}
																	virtual={{
																		total: unitCount,
																		pageSize: uicPageSize,
																		skip: page
																	}}
																	onPageChange={pageChange}
																	loading={unitsLoading}
																	filterable={true}
																	onFilterChange={filterRequestingUnitsChange}
																/>
															</div>
														</div>

														<div className={'col-9'}>
															<div className={'form-group'}>
																<Field key={'plads'} id={'plads'} name={'requestingUnitPlads'} label={'PLADS'} placeholder={'plain language address...'} component={FormInput} />
															</div>
														</div>
													</Row>
													{/* Requester Information: End */}

													{/* Priority and Urgency: Start */}
													<Row id={'travel-priority-and-urgency'} className={'form-section'}>
														{/* Section Title - Priority and Urgency: Start */}
														<div className={'col-12 form-section-title priority-and-urgency'}>
															<h3>Priority and Urgency of Lift</h3>
														</div>
														{/* Section Title - Priority and Urgency: End */}

														{/* Travel Priority: Start */}
														<div className={'col-6 priority has-info'}>
															<Field key={'priority'} onChange={handleFormChanged} id={'priority'} name={'priority'} label={'Lift Priority'} textField={'label'} dataItemKey={'value'} placeholder={'Select the priority of your request...'} component={FormComboBox} data={props.roleaccess.includes('nalo') ? naloPriorityOfTravel : priorityOfTravel} allowCustom={true} />
															<Button
																variant={'secondary'}
																className={'btn-form-info'}
																onClick={() => {
																	// Info: Modal Title
																	handleModalTitle('Travel Priority Information');

																	// Info: Modal Content
																	handleModalContent(`
                                									      <p><span class="fontsize-18px">Priority 1:</span><br/><b>Direct support of operational forces</b> engaged in
                                									      combat <b><u>or</u></b> contingency peace-keeping operations directed NCA, <b><u>or</u></b> for emergency
                                									      lifesaving purposes.</p>
                                									      <p><span class="fontsize-18px">Priority 2:</span><br/>"<b>Required use</b>" travel <b><u>or</u></b> compelling
                                									      operational considerations making commercial transportation unacceptable (within 24 hours). Mission cannot be
                                									      satisfied by any other mode of travel. Requester should provide a 2-hour window for departure and arrival
                                									      times to allow consolidation of missions per DoD Directive 4500.43.</p>
                                									      <p><span class="fontsize-18px">Priority 3:</span><br/><b>Official business travel</b> which when consolidated
                                									      by JOSAC with other travelers, is more cost effective than commercial air travel or official business travel
                                									      on previously scheduled missions. Requester <b>must</b> provide at least a 2-hour window for departure and
                                									      arrival times to allow consolidation of missions per DoD Directive 4500.43.</p>
                                									  `);

																	// Show Modal
																	handleModalToggle();
																}}>
																<i className={'fa-solid fa-info modal-trigger'}></i>
																<span className={'sr-only'}>Click to view information about Travel Priority</span>
															</Button>
														</div>
														{/* Travel Priority: End */}

														{/* Travel Urgency: Start */}
														<div className={'col-6 urgency has-info'}>
															<Field defaultValue={liftDetails.history !== undefined && liftDetails.history[0] && liftDetails.history[0].urgency ? liftDetails.history[0].urgency : ''} onChange={handleFormChanged} key={'urgency'} id={'urgency'} name={'urgency'} label={'Lift Urgency'} textField={'label'} dataItemKey={'value'} placeholder={'Select the urgency of your request...'} component={FormComboBox} data={props.roleaccess.includes('nalo') ? naloUrgencyOfTravel : urgencyOfTravel} />
															<Button
																variant={'secondary'}
																className={'btn-form-info'}
																onClick={() => {
																	// Info: Modal Title
																	handleModalTitle('PUJC Code Information');

																	// Info: Modal Content
																	handleModalContent(`
                                                                	<p><b>Priority/Urgency/Justification/Category (PUJC) Codes.</b><br/>Requesters and authorizing officials shall assign a PUJC
																	code to each lift when requesting airlift support. The PUJC codes shall be validated by the validating and scheduling
																	activity (NALO, CTF-53, CTF-63, CFWP).</p>
                                                            `);

																	// Show Modal
																	handleModalToggle();
																}}>
																<i className={'fa-solid fa-info modal-trigger'}></i>
																<span className={'sr-only'}>Click to view information about PUJC Code</span>
															</Button>
														</div>
														{/* Travel Urgency: End */}
													</Row>
													{/* Priority and Urgency: End */}

													{/* Point of Contact (Departure): Start */}
													<Row id={'point-of-contact-departure'} className={'form-section'}>
														{/* Section Title - Point of Contact: Start */}
														<div className={'col-12 form-section-title point-of-contact'}>
															<h3>
																Point of Contact (Departure)
																<span className={'fontsize-14px'} style={{ float: 'right' }}>
																	(Cannot be Arrival POC or Travel Authorizing Official)
																</span>
															</h3>
														</div>
														{/* Section Title - Point of Contact: End */}

														<div className={'col-12'}>
															<h5 className={'form-subsection'}>Primary Contact</h5>
														</div>

														{/* POC Departure Passenger Name (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerFNamePri'} onChange={handleFormChanged} id={'pocDeparturePassengerFNamePri'} name={'pocDeparturePassengerFNamePri'} label={'First Name'} placeholder={'First Name'} component={FormInput} />
														</div>
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerLNamePri'} onChange={handleFormChanged} id={'pocDeparturePassengerLNamePri'} name={'pocDeparturePassengerLNamePri'} label={'Last Name'} placeholder={'Last Name'} component={FormInput} />
														</div>
														{/* POC Departure Passenger Name (Pri): End */}

														{/* POC Departure Passenger Email (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerEmailPri'} onChange={handleFormChanged} id={'pocDeparturePassengerEmailPri'} name={'pocDeparturePassengerEmailPri'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
														</div>
														{/* POC Departure Passenger Email (Pri): End */}

														{/* POC Departure Passenger Mobile Phone (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerMobilePri'} onChange={handleFormChanged} id={'pocDeparturePassengerMobilePri'} name={'pocDeparturePassengerMobilePri'} label={'Mobile Phone'} placeholder={'Please include country code.'} component={FormInput} />
														</div>
														{/* POC Departure Passenger Mobile Phone (Pri): End */}

														{/* POC Departure Passenger Department or Agency (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocDepartureBranchOfServicePri'} onChange={handleFormChanged} id={'pocDepartureBranchOfServicePri'} name={'pocDepartureBranchOfServicePri'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={depPriDepartmentOrAgencyData} filterable={true} onFilterChange={filterDepPriDepartmentOrAgencyChange} />
														</div>
														{/* POC Departure Passenger Department or Agency (Pri): End */}

														{/* POC Departure Passenger Grade/DV Code (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerGradePri'} onChange={handleFormChanged} id={'pocDeparturePassengerGradePri'} name={'pocDeparturePassengerGradePri'} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={depPriGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterDepPriGradesChange} />
														</div>
														{/* POC Departure Passenger Grade/DV Code (Pri): End */}

														{/* POC Departure Passenger Duty Title (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerDutyTitlePri'} onChange={handleFormChanged} id={'pocDeparturePassengerDutyTitlePri'} name={'pocDeparturePassengerDutyTitlePri'} label={'Duty Title'} placeholder={''} component={FormInput} />
														</div>
														{/* POC Departure Passenger Duty Title (Pri): End */}

														{/* POC Departure Passenger Unit (Pri): Start */}
														<div className={'col-3'}>
															<Field
																key={'pocDeparturePassengerUnitPri'}
																onChange={handleFormChanged}
																id={'pocDeparturePassengerUnitPri'}
																name={'pocDeparturePassengerUnitPri'}
																label={'Unit'}
																textField={'uicnra'}
																dataItemKey={'uic'}
																placeholder={'Unit...'}
																component={FormComboBox}
																allowCustom={true}
																data={depPriUnit}
																value={units}
																virtual={{
																	total: unitCount,
																	pageSize: uicPageSize,
																	skip: page
																}}
																onPageChange={pageChange}
																loading={unitsLoading}
																filterable={true}
																onFilterChange={filterDepPriUnitsChange}
															/>
														</div>
														{/* POC Departure Passenger Unit (Pri): End */}

														<div className={'col-12'}>
															<h5 className={'form-subsection'}>Alternate Contact</h5>
														</div>

														{/* POC Departure Passenger First Name (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerFNameAlt'} onChange={handleFormChanged} id={'pocDeparturePassengerFNameAlt'} name={'pocDeparturePassengerFNameAlt'} label={'Name'} placeholder={'First Name'} component={FormInput} />
														</div>
														{/* POC Departure Passenger First Name (Alt): End */}

														{/* POC Departure Passenger Last Name (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerLNameAlt'} onChange={handleFormChanged} id={'pocDeparturePassengerLNameAlt'} name={'pocDeparturePassengerLNameAlt'} label={'Name'} placeholder={'Last Name'} component={FormInput} />
														</div>
														{/* POC Departure Passenger Last Name (Alt): End */}

														{/* POC Departure Passenger Email (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerEmailAlt'} onChange={handleFormChanged} id={'pocDeparturePassengerEmailAlt'} name={'pocDeparturePassengerEmailAlt'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
														</div>
														{/* POC Departure Passenger Email (Alt): End */}

														{/* POC Departure Passenger Mobile Phone (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerMobileAlt'} onChange={handleFormChanged} id={'pocDeparturePassengerMobileAlt'} name={'pocDeparturePassengerMobileAlt'} label={'Mobile Phone'} placeholder={'Please include country code.'} component={FormInput} />
														</div>
														{/* POC Departure Passenger Mobile Phone (Alt): End */}

														{/* POC Departure Passenger Department or Agency (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocDepartureBranchOfServiceAlt'} onChange={handleFormChanged} id={'pocDepartureBranchOfServiceAlt'} name={'pocDepartureBranchOfServiceAlt'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={depAltDepartmentOrAgencyData} filterable={true} onFilterChange={filterDepAltDepartmentOrAgencyChange} />
														</div>
														{/* POC Departure Passenger Department or Agency (Alt): End */}

														{/* POC Departure Passenger Grade/DV Code (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerGradeAlt'} onChange={handleFormChanged} id={'pocDeparturePassengerGradeAlt'} name={'pocDeparturePassengerGradeAlt'} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={depAltGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterDepAltGradesChange} />
														</div>
														{/* POC Departure Passenger Grade/DV Code (Alt): End */}

														{/* POC Departure Passenger Duty Title (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocDeparturePassengerDutyTitleAlt'} onChange={handleFormChanged} id={'pocDeparturePassengerDutyTitleAlt'} name={'pocDeparturePassengerDutyTitleAlt'} label={'Duty Title'} placeholder={''} component={FormInput} />
														</div>
														{/* POC Departure Passenger Duty Title (Alt): End */}

														{/* POC Departure Passenger Unit (Alt): Start */}
														<div className={'col-3'}>
															<Field
																key={'pocDeparturePassengerUnitAlt'}
																onChange={handleFormChanged}
																id={'pocDeparturePassengerUnitAlt'}
																name={'pocDeparturePassengerUnitAlt'}
																label={'Unit'}
																textField={'uicnra'}
																dataItemKey={'uic'}
																placeholder={'Unit...'}
																component={FormComboBox}
																allowCustom={true}
																data={depAltUnit}
																value={units}
																virtual={{
																	total: unitCount,
																	pageSize: uicPageSize,
																	skip: page
																}}
																onPageChange={pageChange}
																loading={unitsLoading}
																filterable={true}
																onFilterChange={filterDepAltUnitsChange}
															/>
														</div>
														{/* POC Departure Passenger Unit (Alt): End */}
													</Row>
													{/* Point of Contact (Departure): End */}

													{/* Point of Contact (Arrival): Start */}
													<Row id={'point-of-contact-arrival'} className={'form-section'}>
														{/* Section Title - Point of Contact: Start */}
														<div className={'col-12 form-section-title point-of-contact'}>
															<h3>
																Point of Contact (Arrival)
																<span className={'fontsize-14px'} style={{ float: 'right' }}>
																	(Cannot be Departure Coordinator)
																</span>
															</h3>
														</div>
														{/* Section Title - Point of Contact: End */}

														<div className={'col-12'}>
															<h5 className={'form-subsection'}>Primary Contact</h5>
														</div>

														{/* POC Arrival Passenger Name (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerFNamePri'} onChange={handleFormChanged} id={'pocArrivalPassengerFNamePri'} name={'pocArrivalPassengerFNamePri'} label={'First Name'} placeholder={'First Name'} component={FormInput} />
														</div>
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerLNamePri'} onChange={handleFormChanged} id={'pocArrivalPassengerLNamePri'} name={'pocArrivalPassengerLNamePri'} label={'Last Name'} placeholder={'Last Name'} component={FormInput} />
														</div>
														{/* POC Arrival Passenger Name (Pri): End */}

														{/* POC Arrival Passenger Email (Pri): End */}
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerEmailPri'} onChange={handleFormChanged} id={'pocArrivalPassengerEmailPri'} name={'pocArrivalPassengerEmailPri'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
														</div>
														{/* POC Arrival Passenger Email (Pri): End */}

														{/* POC Arrival Passenger Mobile Phone (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerMobilePri'} onChange={handleFormChanged} id={'pocArrivalPassengerMobilePri'} name={'pocArrivalPassengerMobilePri'} label={'Mobile Phone'} placeholder={'Please include country code.'} component={FormInput} />
														</div>
														{/* POC Arrival Passenger Mobile Phone (Pri): End */}

														{/* POC Arrival Passenger Department or Agency (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalBranchOfServicePri'} onChange={handleFormChanged} id={'pocArrivalBranchOfServicePri'} name={'pocArrivalBranchOfServicePri'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={arrPriDepartmentOrAgencyData} filterable={true} onFilterChange={filterArrPriDepartmentOrAgencyChange} />
														</div>
														{/* POC Arrival Passenger Department or Agency (Pri): End */}

														{/* POC Arrival Passenger Grade/DV Code (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerGradePri'} onChange={handleFormChanged} id={'pocArrivalPassengerGradePri'} name={'pocArrivalPassengerGradePri'} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={arrPriGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterArrPriGradesChange} />
														</div>
														{/* POC Arrival Passenger Grade/DV Code (Pri): End */}

														{/* POC Arrival Passenger Duty Title (Pri): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerDutyTitlePri'} onChange={handleFormChanged} id={'pocArrivalPassengerDutyTitlePri'} name={'pocArrivalPassengerDutyTitlePri'} label={'Duty Title'} placeholder={''} component={FormInput} />
														</div>
														{/* POC Arrival Passenger Duty Title (Pri): End */}

														{/* POC Arrival Passenger Unit (Pri): Start */}
														<div className={'col-3'}>
															<Field
																key={'pocArrivalPassengerUnitPri'}
																onChange={handleFormChanged}
																id={'pocArrivalPassengerUnitPri'}
																name={'pocArrivalPassengerUnitPri'}
																label={'Unit'}
																textField={'uicnra'}
																dataItemKey={'uic'}
																placeholder={'Unit...'}
																component={FormComboBox}
																allowCustom={true}
																data={arrPriUnit}
																value={units}
																virtual={{
																	total: unitCount,
																	pageSize: uicPageSize,
																	skip: page
																}}
																onPageChange={pageChange}
																loading={unitsLoading}
																filterable={true}
																onFilterChange={filterArrPriUnitsChange}
															/>
														</div>
														{/* POC Arrival Passenger Unit (Pri): End */}

														<div className={'col-12'}>
															<h5 className={'form-subsection'}>Alternate Contact</h5>
														</div>

														{/* POC Arrival Passenger Name (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerFNameAlt'} onChange={handleFormChanged} id={'pocArrivalPassengerFNameAlt'} name={'pocArrivalPassengerFNameAlt'} label={'First Name'} placeholder={'First Name'} component={FormInput} />
														</div>

														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerLNameAlt'} onChange={handleFormChanged} id={'pocArrivalPassengerLNameAlt'} name={'pocArrivalPassengerLNameAlt'} label={'Last Name'} placeholder={'Last Name'} component={FormInput} />
														</div>
														{/* POC Arrival Passenger Name (Alt): End */}

														{/* POC Arrival Passenger Email (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerEmailAlt'} onChange={handleFormChanged} id={'pocArrivalPassengerEmailAlt'} name={'pocArrivalPassengerEmailAlt'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
														</div>
														{/* POC Arrival Passenger Email (Alt): End */}

														{/* POC Arrival Passenger Mobile Phone (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerMobileAlt'} onChange={handleFormChanged} id={'pocArrivalPassengerMobileAlt'} name={'pocArrivalPassengerMobileAlt'} label={'Mobile Phone'} placeholder={'Please include country code.'} component={FormInput} />
														</div>
														{/* POC Arrival Passenger Mobile Phone (Alt): End */}

														{/* POC Arrival Passenger Department or Agency (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalBranchOfServiceAlt'} onChange={handleFormChanged} id={'pocArrivalBranchOfServiceAlt'} name={'pocArrivalBranchOfServiceAlt'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={arrAltDepartmentOrAgencyData} filterable={true} onFilterChange={filterArrAltDepartmentOrAgencyChange} />
														</div>
														{/* POC Arrival Passenger Department or Agency (Alt): End */}

														{/* POC Arrival Passenger Grade/DV Code (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerGradeAlt'} onChange={handleFormChanged} id={'pocArrivalPassengerGradeAlt'} name={'pocArrivalPassengerGradeAlt'} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={arrAltGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterArrAltGradesChange} />
														</div>
														{/* POC Arrival Passenger Grade/DV Code (Alt): End */}

														{/* POC Arrival Passenger Duty Title (Alt): Start */}
														<div className={'col-3'}>
															<Field key={'pocArrivalPassengerDutyTitleAlt'} onChange={handleFormChanged} id={'pocArrivalPassengerDutyTitleAlt'} name={'pocArrivalPassengerDutyTitleAlt'} label={'Duty Title'} placeholder={''} component={FormInput} />
														</div>
														{/* POC Arrival Passenger Duty Title (Alt): End */}

														{/* POC Arrival Passenger Unit (Alt): Start */}
														<div className={'col-3'}>
															<Field
																key={'pocArrivalPassengerUnitAlt'}
																onChange={handleFormChanged}
																id={'pocArrivalPassengerUnitAlt'}
																name={'pocArrivalPassengerUnitAlt'}
																label={'Unit'}
																textField={'uicnra'}
																dataItemKey={'uic'}
																placeholder={'Unit...'}
																component={FormComboBox}
																allowCustom={true}
																data={arrAltUnit}
																value={units}
																virtual={{
																	total: unitCount,
																	pageSize: uicPageSize,
																	skip: page
																}}
																onPageChange={pageChange}
																loading={unitsLoading}
																filterable={true}
																onFilterChange={filterArrAltUnitsChange}
															/>
														</div>
														{/* POC Arrival Passenger Unit (Alt): End */}
													</Row>
													{/* Point of Contact (Arrival): End */}

													{/* Purpose of Travel: Start */}
													<Row id={'purpose-of-travel'} className={'form-section'}>
														{/* Section Title - Purpose of Travel: Start */}
														<div className={'col-12 form-section-title purpose-of-travel'}>
															<h3>Purpose of Travel</h3>
														</div>
														{/* Section Title - Purpose of Travel: End */}

														{/* Complete Mission Description: Start */}
														<div className={'col-12 complete-mission-description has-info'}>
															<Field key={'completeMissionDescription'} id={'completeMissionDescription'} name={'completeMissionDescription'} label={'Complete Mission Description'} optional={false} component={FormTextArea} onChange={handleMissionDescCount} maxLength={missionMax} value={missionDescCount} hint={`Minimum ${missionMin} characters/Maximum ${missionMax} characters. ${missionDescCount.length} of ${missionMax}`} validator={completeMissionDescriptionValidator} />
															<Button
																variant={'secondary'}
																className={'btn-form-info'}
																onClick={() => {
																	// Info: Modal Title
																	handleModalTitle('Mission Description Information');

																	// Info: Modal Content
																	handleModalContent(`
                                      <p>Describe what the request is for. What exercise, deployment, operation, training,
                                      etc this request will fulfill by being supported by NALO. This description along
                                      with compelling considerations and reason for commercial air unacceptable will be
                                      used to determine the proper PUJC for this lift. We require as much detail as possible
                                      to properly validate and schedule.</p>
                                  `);

																	// Show Modal
																	handleModalToggle();
																}}>
																<i className={'fa-solid fa-info modal-trigger'}></i>
																<span className={'sr-only'}>Click to view information about Complete Mission Description</span>
															</Button>
														</div>
														{/* Complete Mission Description: End */}

														{/* Reason for Military Airlift or Reason Commercial Travel Unacceptable: Start */}
														<div className={'col-12 priority-2-compelling-considerations has-info'}>
															<Field key={'justification'} id={'justification'} name={'justification'} label={'Reason for Military Airlift or Reason Commercial Travel Unacceptable'} optional={false} component={FormTextArea} onChange={handleAirliftReasonCount} maxLength={airliftMax} value={airliftReasonCount} hint={`Minimum ${airliftMin} characters/Maximum ${airliftMax} characters. ${airliftReasonCount.length} of ${airliftMax}`} validator={commercialTravelUnacceptableDescriptionValidator} />
															<Button
																variant={'secondary'}
																className={'btn-form-info'}
																onClick={() => {
																	// Info: Modal Title
																	handleModalTitle('Justification Information');

																	// Info: Modal Content
																	handleModalContent(`
                                      <p>If this lift cannot be satisfied, what impact will that have on the unit requesting? What is the reason
                                      behind not taking commercial air instead of NALO?</p>
                                      <p>(Content of cargo, unit cohesion, airfield restrictions, strict time line)</p>
                                      <p><b>** "Cost too high" is NOT ACCEPTABLE **</b></p>
                                  `);

																	// Show Modal
																	handleModalToggle();
																}}>
																<i className={'fa-solid fa-info modal-trigger'}></i>
																<span className={'sr-only'}>Click to view information about Priority 2 Considerations</span>
															</Button>
														</div>
														{/* Reason for Military Airlift or Reason Commercial Travel Unacceptable: End */}
													</Row>
													{/* Purpose of Travel: End */}

													{/* Departure Flight Itinerary: Start */}
													<Row id={'itinerary-departure-information'} className={'form-section'}>
														{/* Section Title - Desired Flight Itinerary: Start */}
														<div className={'col-12 form-section-title itinerary-departure-information'}>
															<h3>
																Itinerary Departure Information
																<span className={'fontsize-14px'} style={{ float: 'right' }}>
																	(Earliest and latest dates must be separated by a minimum of 72 hours)
																</span>
															</h3>
														</div>
														{/* Section Title - Desired Flight Itinerary: End */}

														{/* Departure Flight Itinerary Block: Start */}
														<div id={'itinerary-departure-information-action-block'} className={'col-12 has-info'}>
															<Row>
																{/* Desired Departure ICAO: Start */}
																<div className={'col-4'}>
																	<Field key={'desiredDepartureIcao'} onChange={handleFormChanged} id={'desiredDepartureIcao'} name={'desiredDepartureIcao'} label={'Desired Departure ICAO'} textField={'history.0.icaoName'} dataItemKey={'history.0.icao'} placeholder={'Select the Desired Departure ICAO...'} component={FormComboBox} allowCustom={true} data={departureAirports} loading={departureAirportsLoading} filterable={true} onFilterChange={filterDepartureAirportChange} />
																</div>
																{/* Desired Departure ICAO: End */}

																{/* Earliest Departure Date/Time: Start */}
																<div className={'col-4'}>
																	<Field key={'earliestDepartureDateTime'} id={'earliestDepartureDateTime'} name={'earliestDepartureDateTime'} label={'Earliest Departure Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} onChange={handleDateChange} hint={timeWindowWarningDep ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ''} />
																</div>
																{/* Earliest Departure Date/Time: End */}

																{/* Latest Departure Date/Time: Start */}
																<div className={'col-4'}>
																	<Field key={'latestDepartureDateTime'} id={'latestDepartureDateTime'} name={'latestDepartureDateTime'} label={'Latest Departure Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} onChange={handleDateChange} hint={timeWindowWarningDep ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ''} />
																	<Button
																		variant={'secondary'}
																		className={'btn-form-info'}
																		onClick={() => {
																			// Info: Modal Title
																			handleModalTitle('Pickup / Drop-off Window Information');

																			// Info: Modal Content
																			handleModalContent(`
                                        <p>Choose the largest time window possible.</p>
                                        <p>Requests with larger time windows are more likely to get scheduled. </p>
                                        `);

																			// Show Modal
																			handleModalToggle();
																		}}>
																		<i className={'fa-solid fa-info modal-trigger'}></i>
																		<span className={'sr-only'}>Click to view information about Remarks/Additional Comments</span>
																	</Button>
																</div>

																{/* Latest Departure Date/Time: End */}

																{/* Departure Alternative Airports: Start */}
																<div className={'col-12'}>
																	<label>List of Alternative Departure Airports</label>
																	<FieldArray name="alternateDeparture" component={AltDepartureAirportsGrid} />
																</div>
																{/* Departure Alternative Airports: End */}
															</Row>
														</div>
														{/* Departure Flight Itinerary Block: End */}
													</Row>
													{/* Departure Flight Itinerary: End */}

													{/* Itinerary Arrival Information: Start */}
													<Row id={'itinerary-arrival-information'} className={'form-section'}>
														{/* Section Title - Alternative Flight Itinerary: Start */}
														<div className={'col-12 form-section-title itinerary-arrival-information'}>
															<h3>Itinerary Arrival Information</h3>
														</div>
														{/* Section Title - Alternative Flight Itinerary: End */}

														{/* Itinerary Arrival Information Block: Start */}
														<div id={'itinerary-arrival-information-action-block'} className={'col-12 has-info'}>
															<Row>
																{/* Desired Arrival ICAO: Start */}
																<div className={'col-4'}>
																	<Field key={'desiredArrivalIcao'} onChange={handleFormChanged} id={'desiredArrivalIcao'} name={'desiredArrivalIcao'} label={'Desired Arrival ICAO'} textField={'history.0.icaoName'} dataItemKey={'history.0.icao'} placeholder={'Select the Desired Arrival ICAO...'} component={FormComboBox} data={arrivalAirports} loading={arrivalAirportsLoading} allowCustom={true} filterable={true} onFilterChange={filterArrivalAirportChange} />
																</div>
																{/* Desired Arrival ICAO: End */}

																{/* Earliest Arrival Date/Time: Start */}
																<div className={'col-4'}>
																	<Field key={'earliestArrivalDateTime'} id={'earliestArrivalDateTime'} name={'earliestArrivalDateTime'} label={'Earliest Arrival Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} onChange={handleDateChange} hint={timeWindowWarningArr ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ' '} />
																</div>
																{/* Earliest Arrival Date/Time: End */}

																{/* Latest Arrival Date/Time: Start */}
																<div className={'col-4'}>
																	<Field key={'latestArrivalDateTime'} id={'latestArrivalDateTime'} name={'latestArrivalDateTime'} label={'Latest Arrival Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} onChange={handleDateChange} hint={timeWindowWarningArr ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ' '} />
																	<Button
																		variant={'secondary'}
																		className={'btn-form-info'}
																		onClick={() => {
																			// Info: Modal Title
																			handleModalTitle('Pickup / Drop-off Window Information');

																			// Info: Modal Content
																			handleModalContent(`
                                        <p>Choose the largest time window possible.</p>
                                        <p>Requests with larger time windows are more likely to get scheduled. </p>
                                        `);

																			// Show Modal
																			handleModalToggle();
																		}}>
																		<i className={'fa-solid fa-info modal-trigger'}></i>
																		<span className={'sr-only'}>Click to view information about Remarks/Additional Comments</span>
																	</Button>
																</div>
																{/* Latest Arrival Date/Time: End */}

																{/* Arrival Alternative Airports: Start */}
																<div className={'col-12'}>
																	<label>List of Alternative Arrival Airports</label>
																	<FieldArray name="alternateArrival" component={AltArrivalAirportsGrid} />
																</div>
																{/* Arrival Alternative Airports: End */}
															</Row>
														</div>
														{/* Itinerary Arrival Information Block: End */}
													</Row>
													{/* Itinerary Arrival Information: End */}

													{/* Passengers or Cargo: Start */}
													<Row id={'form-passenger-cargo-option'} className={'form-section'}>
														{/* Section Title - Passengers or Cargo: Start */}
														<div className={'col-12 form-section-title passengers-or-cargo'}>
															<h3>
																Is your request for Passengers or Cargo?
																<span className={'fontsize-14px'} style={{ float: 'right' }}>
																	(For each passenger, we will account for 40lbs of bags)
																</span>
															</h3>
														</div>
														{/* Section Title - Passengers or Cargo: End */}

														<div className={'col-12 cargo-toggles padding-left-10'}>
															<div>
																<Field key={'hasPassengers'} id={'hasPassengers'} name={'hasPassengers'} onLabel={'Yes'} offLabel={'No'} defaultChecked={false} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Request Contains Passengers?'} component={FormSwitch} onChange={handleHasPassengers} />
															</div>
															<div>
																<Field key={'hasPallets'} id={'hasPallets'} name={'hasPallets'} onLabel={'Yes'} offLabel={'No'} defaultChecked={false} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Request Contains Cargo?'} component={FormSwitch} onChange={handleHasPallets} />
															</div>
														</div>
													</Row>
													{/* Passengers or Cargo: Start */}

													{hasPassenger && (
														<div id={'form-passengers'}>
															{/* Total Number of PAX Row: Start */}
															<Row id={'total-pax'} className={'form-section'}>
																{/* Section Title - PAX Count: Start */}
																<div className={'col-12 form-section-title pax-count'}>
																	<h3>PAX Count</h3>
																</div>
																{/* Section Title - PAX Count: End */}

																{/* Total Number of PAX: Start */}
																<div className={'col-4'}>
																	<Field key={'totalNumberOfPax'} onChange={handleFormChanged} id={'totalNumberOfPax'} name={'totalNumberOfPax'} label={'Total Number of Pax'} format={'n0'} min={1} component={FormNumericTextBox} />
																</div>
																{/* Total Number of PAX: End */}
															</Row>
															{/* Total Number of PAX Row: End */}

															{/* Commercial Cost for PAX Row: Start */}
															<Row id={'commercial-cost'} className={'form-section'}>
																{/* Section Title - PAX Count: Start */}
																<div className={'col-12 form-section-title pax-count'}>
																	<h3>
																		Determine Commercial Cost
																		<span className={'fontsize-14px'} style={{ float: 'right' }}>
																			(Calculate cost using the{' '}
																			<a href={'https://www.gsa.gov/travel/plan-book/transportation-airfare-pov-etc/city-pair-program-cpp'} title={'Click to Use CPP Search Tool'} target={'_blank'} rel={'noreferrer'}>
																				CPP Search Tool
																			</a>
																			)
																		</span>
																	</h3>
																</div>
																{/* Section Title - PAX Count: End */}

																{/* Total Commercial Cost for PAX: Start */}
																<div className={'col-4'}>
																	<div className={'form-group'}>
																		<Field
																			defaultValue={liftDetails.history[0]?.commercialCost || 0}
																			key={'commercialCost'}
																			onChange={handleFormChanged}
																			id={'commercialCost'}
																			name={'commercialCost'}
																			label={'Total Commercial Cost for PAX'}
																			// placeholder={""}
																			hint={'Amount in USD'}
																			format={'n2'}
																			component={FormNumericTextBox}
																		/>

																		{/*
                                    									  <Button variant={"secondary"} className={"btn-form-info"} onClick={() => {

                                    									    // Info: Modal Title
                                    									    handleModalTitle("Total Commercial Cost for PAX");

                                    									    // Info: Modal Content
                                    									    handleModalContent(`
                                    									        <p></p>
                                    									    `);

                                    									    // Show Modal
                                    									    handleModalToggle();
                                    									    }}
                                    									    >
                                    									    <i className={"fa-solid fa-info modal-trigger"}></i>
                                    									    <span className={"sr-only"}>Click to view information about Commercial Cost for PAX</span>
                                    									  </Button>
                                    									*/}
																	</div>
																</div>
																{/* Total Commercial Cost for PAX Row: End */}
															</Row>
															{/* Commercial Cost for PAX Row: End */}

															{/* Senior Traveler: Start */}
															<Row id={'senior-traveler'} className={'form-section'}>
																{/* Section Title - Senior Traveler: Start */}
																<div className={'col-12 form-section-title senior-traveler'}>
																	<h3>
																		Senior Traveler
																		<span className={'fontsize-14px'} style={{ float: 'right' }}>
																			(Must be 1 for every 121 PAX &amp; can't be the same as the Travel Authorizing Official)
																		</span>
																	</h3>
																</div>
																{/* Section Title - Senior Traveler: End */}

																{/* Senior Traveler Name: Start */}
																<div className={'col-3'}>
																	<Field key={'srTravelerFName'} onChange={handleFormChanged} id={'srTravelerFName'} name={'srTravelerFName'} label={'First Name'} placeholder={'First Name'} component={FormInput} />
																</div>

																<div className={'col-3'}>
																	<Field key={'srTravelerLName'} onChange={handleFormChanged} id={'srTravelerLName'} name={'srTravelerLName'} label={'Last Name'} placeholder={'Last Name'} component={FormInput} />
																</div>
																{/* Senior Traveler Name: End */}

																{/* Senior Traveler Email: Start */}
																<div className={'col-3'}>
																	<Field key={'srTravelerEmail'} onChange={handleFormChanged} id={'srTravelerEmail'} name={'srTravelerEmail'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
																</div>
																{/* Senior Traveler Email: End */}

																{/* Senior Traveler Mobile Phone: Start */}
																<div className={'col-3'}>
																	<Field key={'srTravelerMobile'} onChange={handleFormChanged} id={'srTravelerMobile'} name={'srTravelerMobile'} label={'Mobile Phone'} placeholder={'Please include country code.'} component={FormInput} validator={phoneValidator} />
																</div>
																{/* Senior Traveler Mobile Phone: End */}

																{/* Senior Traveler Department or Agency: Start */}
																<div className={'col-3'}>
																	<Field key={'srTravelerBranchOfService'} onChange={handleFormChanged} id={'srTravelerBranchOfService'} name={'srTravelerBranchOfService'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={srTravDepartmentOrAgencyData} filterable={true} onFilterChange={filterSrTravDepartmentOrAgencyChange} />
																</div>
																{/* Senior Traveler Department or Agency: End */}

																{/* Senior Traveler Grade/DV Code: Start */}
																<div className={'col-3'}>
																	<Field key={'srTravelerGrade'} onChange={handleFormChanged} id={'srTravelerGrade'} name={'srTravelerGrade'} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={srTravGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterSrTravGradesChange} />
																</div>
																{/* Senior Traveler Grade/DV Code: End */}

																{/* Senior Traveler Duty Title: Start */}
																<div className={'col-3'}>
																	<Field key={'srTravelerDutyTitle'} onChange={handleFormChanged} id={'srTravelerDutyTitle'} name={'srTravelerDutyTitle'} label={'Duty Title'} placeholder={''} component={FormInput} />
																</div>
																{/* Senior Traveler Duty Title: End */}

																{/* Senior Traveler Unit: Start */}
																<div className={'col-3'}>
																	<Field
																		key={'srTravelerUnit'}
																		onChange={handleFormChanged}
																		id={'srTravelerUnit'}
																		name={'srTravelerUnit'}
																		label={'Unit'}
																		textField={'uicnra'}
																		dataItemKey={'uic'}
																		placeholder={'Unit...'}
																		component={FormComboBox}
																		allowCustom={true}
																		data={srTravUnit}
																		value={units}
																		virtual={{
																			total: unitCount,
																			pageSize: uicPageSize,
																			skip: page
																		}}
																		onPageChange={pageChange}
																		loading={unitsLoading}
																		filterable={true}
																		onFilterChange={filterSrTravUnitsChange}
																	/>
																</div>
																{/* Senior Traveler Unit: End */}
															</Row>
															{/* Senior Traveler: End */}

															{/* Travel Authorizing Official: Start */}
															<Row id={'travel-authorizing-official'} className={'form-section'}>
																{/* Section Title - Travel Authorizing Official: Start */}
																<div className={'col-12 form-section-title travel-authorizing-official'}>
																	<h3>
																		Travel Authorizing Official
																		<span className={'fontsize-14px'} style={{ float: 'right' }}>
																			(As appointed by Service)
																		</span>
																	</h3>
																</div>
																{/* Section Title - Travel Authorizing Official: End */}

																{/* Travel Authorizing Official Name: Start */}
																<div className={'col-3'}>
																	<Field key={'trAuthOffFName'} onChange={handleFormChanged} id={'trAuthOffFName'} name={'trAuthOffFName'} label={'First Name'} placeholder={'First Name'} component={FormInput} />
																</div>

																<div className={'col-3'}>
																	<Field key={'trAuthOffLName'} onChange={handleFormChanged} id={'trAuthOffLName'} name={'trAuthOffLName'} label={'Last Name'} placeholder={'Last Name'} component={FormInput} />
																</div>
																{/* Travel Authorizing Official Name: End */}

																{/* Travel Authorizing Official Email: Start */}
																<div className={'col-3'}>
																	<Field key={'trAuthOffEmail'} onChange={handleFormChanged} id={'trAuthOffEmail'} name={'trAuthOffEmail'} label={'Email Address'} placeholder={'E.g. name@domain.com'} component={FormInput} />
																</div>
																{/* Travel Authorizing Official Email: End */}

																{/* Travel Authorizing Official Mobile Phone: Start */}
																<div className={'col-3'}>
																	<Field key={'trAuthOffMobile'} onChange={handleFormChanged} id={'trAuthOffMobile'} name={'trAuthOffMobile'} label={'Mobile Phone'} placeholder={'Please include country code.'} component={FormInput} validator={phoneValidator} />
																</div>
																{/* Travel Authorizing Official Mobile Phone: End */}

																{/* Travel Authorizing Official Branch of Service: Start */}
																<div className={'col-3'}>
																	<Field key={'trAuthOffBranchOfService'} onChange={handleFormChanged} id={'trAuthOffBranchOfService'} name={'trAuthOffBranchOfService'} label={'Department or Agency'} placeholder={'Department or Agency...'} component={FormComboBox} loading={depOrAgencyloading} allowCustom={true} data={trAuthOffDepartmentOrAgencyData} filterable={true} onFilterChange={filterTrAuthOffDepartmentOrAgencyChange} />
																</div>
																{/* Travel Authorizing Official  Branch of Service: End */}

																{/* Travel Authorizing Official Grade/DV Code: Start */}
																<div className={'col-3'}>
																	<Field key={'trAuthOffGrade'} onChange={handleFormChanged} id={'trAuthOffGrade'} name={'trAuthOffGrade'} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={trAuthOffGradesData} loading={gradesLoading} filterable={true} onFilterChange={filterTrAuthOffGradesChange} />
																</div>
																{/* Travel Authorizing Official Grade/DV Code: End */}

																{/* Travel Authorizing Official Duty Title: Start */}
																<div className={'col-3'}>
																	<Field key={'trAuthOffDutyTitle'} onChange={handleFormChanged} id={'trAuthOffDutyTitle'} name={'trAuthOffDutyTitle'} label={'Duty Title'} placeholder={''} component={FormInput} />
																</div>
																{/* Travel Authorizing Official Duty Title: End */}

																{/* Travel Authorizing Official Unit: Start */}
																<div className={'col-3'}>
																	<Field
																		key={'trAuthOffUnit'}
																		id={'trAuthOffUnit'}
																		onChange={handleFormChanged}
																		name={'trAuthOffUnit'}
																		label={'Unit'}
																		textField={'uicnra'}
																		dataItemKey={'uic'}
																		placeholder={'Unit...'}
																		component={FormComboBox}
																		allowCustom={true}
																		data={trAuthOffUnit}
																		value={units}
																		virtual={{
																			total: unitCount,
																			pageSize: uicPageSize,
																			skip: page
																		}}
																		onPageChange={pageChange}
																		loading={unitsLoading}
																		filterable={true}
																		onFilterChange={filterTrAuthOffUnitsChange}
																	/>
																</div>
																{/* Travel Authorizing Official Name: End */}
															</Row>
															{/* Travel Authorizing Official: End */}
														</div>
													)}

													{hasPallet && (
														<div id={'form-cargo'}>
															{/* Pallets Positions: Start */}
															<Row id={'cargo-pallets'} className={'form-section'}>
																{/* Section Title - Cargo Pallets: Start */}
																<div className={'col-12 form-section-title cargo-pallets'}>
																	<h3>Cargo</h3>
																</div>
																{/* Section Title - Cargo Pallets: End */}
															</Row>

															{/* Pallet Table: Start */}
															<Grid
																id={'cargoPallets'}
																name={'cargoPallets'}
																style={{
																	height: '420px',
																	width: '100%'
																}}
																data={palletData}
																onItemChange={itemChange}
																navigatable={true}
																editField={editField}>
																<GridToolbar>
																	<Button type={'button'} title="Add new" disabled={adding} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={addNew}>
																		Add New Cargo
																	</Button>
																</GridToolbar>

																<Column field="EntryID" title="Id" width="0px" editable={false} />

																<Column field="PalletName" title="Cargo Title" editable={true} />

																<Column field="weight" title="Total Weight" editable={true} cell={CargoColumns.WeightCellLongForm} />

																<Column field="count" title="Cargo Positions" editor="numeric" validator={requiredValidator} />

																<Column
																	cells={{
																		data: HazmatCell
																	}}
																	title="Contains Hazmat"
																	editable={false}
																/>
																<Column cell={PalletFormCommandCell} />
																<Column cell={CargoCommandCell} width="250px" />
															</Grid>

															{openForm && <EditPalletLongForm cancelEdit={handleCancelEdit} discardNew={discardNew} setPalletHazmatCargoesData={setPalletHazmatCargoesData} onSubmit={handleSubmit} item={editItem} props={props} />}

															{/* Pallet Table: End */}

															{/* Pallets Positions: End */}
														</div>
													)}

													{/* Share Lift Request: Start */}
													<Row id={'share-lift-request'} className={'form-section'}>
														{/* Section Title - Share Request: Start */}
														<div className={'col-12 form-section-title share-request'}>
															<h3>Share Lift Request with Other Personnel</h3>
														</div>
														{/* Section Title - Share Request: End */}

														{/* Share Request: Start */}
														<div className={'col-12'}>
															<FieldArray name="shareEmails" component={ShareFormGrid} />
														</div>
														{/* Share Request: End */}
													</Row>
													{/* Share Lift Request: End */}

													{/* Name Your Form: Start */}
													<Row id={'lift-request-introduction'} className={'form-section'}>
														<div className={'col-12 form-section-title lift-request-introduction'}>
															<h3>Name Your Form</h3>
														</div>
														{/* Section Title - Senior Traveler: End */}

														<div className={'col-12'}>
															<p>Enter a name for your form below. This field is not required.</p>
														</div>

														<div className={'col-4'}>
															<div className={'form-group'}>
																<Field key={'requesterFormName'} id={'requesterFormName'} name={'requestName'} label={'Form Name'} placeholder={'E.g. Security Package for Delivery'} component={FormInput} />
															</div>
														</div>
													</Row>
													{/* Name Your Form: End */}

													{/* Remarks/Additional Comments: Start */}
													<Row id={'remarks-additional-comments'} className={'form-section'}>
														{/* Section Title - Remarks/Additional Comments: Start */}
														<div className={'col-12 form-section-title remarks-additional-comments'}>
															<h3>Remarks/Additional Comments</h3>
														</div>
														{/* Section Title - Remarks/Additional Comments: End */}

														{/* Remarks/Additional Comments: Start */}
														<div className={'col-12 remarks-additional-comments has-info'}>
															<label>Previous Comments</label>
															{data.history[0] &&
																data.history[0].comments.length > 0 &&
																data.history[0].comments.map((comment, i) => {
																	return (
																		<div className={'col-12'}>
																			{comment.commenter && comment.commenter.firstName ? comment.commenter.firstName + ' ' + comment.commenter.lastName : 'Unknown User'}

																			<div className={'static-field-read-only static-textarea'}>{comment.text != null ? comment.text : ''}</div>
																			<div className={'author-text'}>
																				{comment.createdOn != null
																					? new Intl.DateTimeFormat('en-US', {
																							year: 'numeric',
																							month: '2-digit',
																							day: '2-digit',
																							hour: '2-digit',
																							minute: '2-digit',
																							second: '2-digit'
																					  }).format(new Date(stripTimeZone(comment.createdOn)))
																					: ''}
																			</div>
																		</div>
																	);
																})}

															<div className={'col-12'}>
																<FieldArray name="comments" component={CommentsGrid} />
															</div>
															<Button
																variant={'secondary'}
																className={'btn-form-info'}
																onClick={() => {
																	// Info: Modal Title
																	handleModalTitle('Remarks/Additional Comments Information');

																	// Info: Modal Content
																	handleModalContent(`
                                                                <p>This block is used to provide:</p>

                                                                <ol class="margin-top-0 padding-top-0">
                                                                    <li>Navy UIC (Unit Identification Code), Marine Corps units will use your ATCO's/ASCO's UIC</li>
                                                                    <li>ROM Status of passengers</li>
                                                                    <li>State exemption of travel IAW 22May20 SECDEF Memo</li>
                                                                    <li>Relay mission information crucial to scheduling</li>
                                                                    <li>Email addresses for Requester, Departure, and Arrival coordinators (.mil, .gov, or .edu only)</li>
                                                                    <li>Requester 24 Hour contact number AND office phone number</li>
                                                                    <li>Requested scheduling route (For VR detachment requests only)</li>
                                                                    <li>List other points of contact to be added for the request (if applicable)</li>
                                                                    <li>Additional VIP names</li>
                                                                </ol>

                                                                <p><b>** Travel Authorizing Official SHALL be a CO, XO, or OIC and at least one rank or echelon higher than the senior traveling passenger. **</b></p>

                                                                <p><b>***NOTE*** NALO does not require passenger manifest of any kind. Only the total number of passengers requesting to be transported and any/all VIP passengers. Additionally, all passengers must be on funded orders.</b></p>
                                                            `);

																	// Show Modal
																	handleModalToggle();
																}}>
																<i className={'fa-solid fa-info modal-trigger'}></i>
																<span className={'sr-only'}>Click to view information about Remarks/Additional Comments</span>
															</Button>
														</div>
														{/* Remarks/Additional Comments: End */}
													</Row>
													{/* Remarks/Additional Comments: End */}

													{/* Submission: Start */}
													<Row id={'form-submission-buttons'} className={'form-section form-submission-buttons k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'}>
														<div className={'col-12 align-right'}>
															{liftDetails.history[0].state !== 'SUBMITTED' && liftDetails.history[0].state !== 'AUTHORIZED' && liftDetails.history[0].state !== 'CLAIMED' && liftDetails.history[0].state !== 'VALIDATED' && liftDetails.history[0].state !== 'UNSATISFIED' && liftDetails.history[0].state !== 'SCHEDULED' && liftDetails.history[0].state !== 'IN_TRANSIT' && liftDetails.history[0].state !== 'SATISFIED' && liftDetails.history[0].state !== 'REGRETTED' && liftDetails.history[0].state !== 'CANCELLED' && (
																<Button className={'btn btn-size-medium btn-primary btn-save-draft'} onClick={formRenderProps.onSubmit} title={'Click to Save as Draft'}>
																	Save Draft
																	<i className={'fa-regular fa-floppy-disk'}></i>
																</Button>
															)}
															{accessValidator(props.roleaccess, accessAuthorizationButton) && data.history.length && data.history[0] !== undefined && data.history[0].state !== undefined && data.history[0].state === 'SUBMITTED' && (
																<Button
																	title={'Click to Authorize Airlift Request'}
																	className={'btn btn-size-medium btn-primary btn-color-green margin-right-0 btn-submit'}
																	onClick={() => {
																		ApiChangeLiftRequestState(params.liftId, 'AUTHORIZED', props.token).then((res) => {
																			toast.success('Successfully Authorized Lift Request!');
																			navigate('/liftrequestslist');
																		});
																	}}>
																	Authorize Airlift Request
																	<i className={'fa fa-file-exclamation'}></i>
																</Button>
															)}

															{!accessValidator(props.roleaccess, accessAuthorizationButton) && (
																<Button title={'Click to Submit Airlift Request'} className={'btn btn-size-medium btn-primary btn-color-green margin-right-0 btn-submit'} onClick={formRenderProps.onSubmit}>
																	Submit Airlift Request
																	<i className={'far fa-arrow-right'}></i>
																</Button>
															)}
														</div>
													</Row>
													{/* Submission: Start */}
												</FormElement>
											</div>
										</div>
									)}
								/>
							</React.Fragment>
						) : (
							<Loader />
						)}
					</Panel>

					<LiftRequestInfoModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalContent={modalContent} />

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LiftRequestEdit);
