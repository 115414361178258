/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';

import { FormInput, FormDateTimePicker, FormTextArea, FormComboBox } from '../KendoFormElements/FormComponents';
import { commercialTravelUnacceptableDescriptionValidator, completeMissionDescriptionValidator } from '../KendoFormElements/Validators';
import { requiredValidator } from '../KendoFormElements/Validators';
import Button from 'react-bootstrap/Button';
import { Field, FieldArray } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { filterChange } from '../../Helpers/FilterData';

import LiftRequestInfoModal from '../../App/Modals/LiftRequestInfoModal';

import { ApiAirportsList } from '../../Helpers/Apis';
import { compareDateChangeDep, compareDateChangeArr } from '../../Helpers/DateTimeCheck';

const commandCell = (onRemove) => (props) => {
	const onClick = React.useCallback(
		(e) => {
			e.preventDefault();
			onRemove(props);
		},
		[onRemove]
	);
	return (
		<td className={'summary-actions-cell'}>
			<button className={'button btn-icon-remove btn-color-red margin-right-0 padding-top-12 padding-bottom-12 k-button k-grid-remove-command'} title={'Click to Remove Row'} onClick={onClick}>
				Remove
			</button>
		</td>
	);
};

const icaoAltDepartureCell = (props) => {
	return (
		<td className={'summary-email-cell summary-icao-cell'}>
			<Field name={`departureAlternativeAirports[${props.dataIndex}].${props.field}`} placeholder={'Add using 4-digit ICAO airport code, e.g. KMSY'} component={FormInput} optional={false} />
		</td>
	);
};

const AltDepartureAirportsGrid = (fieldArrayRenderProps) => {
	const { validationMessage, visited } = fieldArrayRenderProps;
	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			fieldArrayRenderProps.onUnshift({ value: { icao: '' } });
		},
		[fieldArrayRenderProps.onUnshift]
	);

	const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps.onRemove]);

	return (
		<div className={'lift-request-array-grid'}>
			{visited && validationMessage && <Error>{validationMessage}</Error>}
			<Grid data={fieldArrayRenderProps.value}>
				<GridToolbar>
					<button title={'Click to Add Alternate ICAO'} className={'k-button k-primary'} onClick={onAdd}>
						Add New Alternate ICAO
						<i className={'far fa-plus'}></i>
					</button>
				</GridToolbar>
				<GridColumn field="icao" title="ICAO Code" cell={icaoAltDepartureCell} />
				<GridColumn cell={commandCell(onRemove)} width="240px" />
			</Grid>
		</div>
	);
};

const icaoAltArrivalCell = (props) => {
	return (
		<td className={'summary-email-cell summary-icao-cell'}>
			<Field name={`arrivalAlternativeAirports[${props.dataIndex}].${props.field}`} placeholder={'Add using 4-digit ICAO airport code, e.g. KMSY'} component={FormInput} optional={false} />
		</td>
	);
};

const AltArrivalAirportsGrid = (fieldArrayRenderProps) => {
	const { validationMessage, visited } = fieldArrayRenderProps;
	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			fieldArrayRenderProps.onUnshift({ value: { icao: '' } });
		},
		[fieldArrayRenderProps.onUnshift]
	);
	const onRemove = React.useCallback((cellProps) => fieldArrayRenderProps.onRemove({ index: cellProps.dataIndex }), [fieldArrayRenderProps.onRemove]);

	return (
		<div className={'lift-request-array-grid'}>
			{visited && validationMessage && <Error>{validationMessage}</Error>}
			<Grid data={fieldArrayRenderProps.value}>
				<GridToolbar>
					<button title={'Click to Add Alternate ICAO'} className={'k-button k-primary'} onClick={onAdd}>
						Add New Alternate ICAO
						<i className={'far fa-plus'}></i>
					</button>
				</GridToolbar>
				<GridColumn field="icao" title="ICAO Code" cell={icaoAltArrivalCell} />
				<GridColumn cell={commandCell(onRemove)} width="240px" />
			</Grid>
		</div>
	);
};

export const ItineraryDetails = (props) => {
	const [missionDescCount, setMissionDescCount] = React.useState('');
	const [airliftReasonCount, setAirliftReasonCount] = React.useState('');

	const [toggleModal, setToggleModal] = React.useState(false);
	const [modalTitle, setModalTitle] = React.useState('');
	const [modalContent, setModalContent] = React.useState('');

	const [airports, setAirports] = React.useState([]);

	const handleModalToggle = () => setToggleModal(!toggleModal);

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	const [timeWindowWarningDep, setTimeWindowWarningDep] = React.useState(false);
	const [timeWindowWarningArr, setTimeWindowWarningArr] = React.useState(false);
	const [hr24LaterDateEarliestDep, setHr24LaterDateEarliestDep] = React.useState('');
	const [hr24LaterDateLatestDep, setHr24LaterDateLatestDep] = React.useState('');
	const [hr24LaterDateEarliestArr, setHr24LaterDateEarliestArr] = React.useState('');
	const [hr24LaterDateLatestArr, setHr24LaterDateLatestArr] = React.useState('');

	const [departureAirportsLoading, setDepartureAirportsLoading] = React.useState(false);
	const [departureAirports, setDepartureAirports] = React.useState([]);
	const [arrivalAirportsLoading, setArrivalAirportsLoading] = React.useState(false);
	const [arrivalAirports, setArrivalAirports] = React.useState([]);
	const timeout = React.useRef();

	const filterDepartureAirportChange = (event) => {
		filterChange(event, setDepartureAirports, setDepartureAirportsLoading, airports, timeout);
	};

	const filterArrivalAirportChange = (event) => {
		filterChange(event, setArrivalAirports, setArrivalAirportsLoading, airports, timeout);
	};

	useEffect(() => {
		ApiAirportsList().then((res) => {
			setAirports(res);
			setDepartureAirports(res);
			setArrivalAirports(res);
		});
	}, []);

	const missionMin = 100;
	const missionMax = 3000;
	const airliftMin = 100;
	const airliftMax = 3000;

	const handleMissionDescCount = (e) => {
		setMissionDescCount(e.value);
	};

	const handleAirliftReasonCount = (e) => {
		setAirliftReasonCount(e.value);
	};

	const handleDateChange = (e) => {
		if (e.target.props.id.includes('Arrival')) {
			compareDateChangeArr(setHr24LaterDateLatestArr, setHr24LaterDateEarliestArr, hr24LaterDateEarliestArr, hr24LaterDateLatestArr, setTimeWindowWarningArr, e);
		}
		if (e.target.props.id.includes('Departure')) {
			compareDateChangeDep(setHr24LaterDateLatestDep, setHr24LaterDateEarliestDep, hr24LaterDateEarliestDep, hr24LaterDateLatestDep, setTimeWindowWarningDep, e);
		}
	};

	return (
		<React.Fragment>
			{/* Purpose of Travel: Start */}
			<Row id={'purpose-of-travel'} className={'form-section'}>
				{/* Section Title - Purpose of Travel: Start */}
				<div className={'col-12 form-section-title purpose-of-travel'}>
					<h3>Purpose of Travel</h3>
				</div>
				{/* Section Title - Purpose of Travel: End */}

				{/* Complete Mission Description: Start */}
				<div className={'col-12 has-info'}>
					<div className={'form-group'}>
						<Field key={'missionDescription'} id={'missionDescription'} name={'missionDescription'} label={'Complete Mission Description'} optional={false} component={FormTextArea} onChange={handleMissionDescCount} maxLength={missionMax} value={missionDescCount} hint={`Minimum ${missionMin} characters/Maximum ${missionMax} characters. ${missionDescCount.length} of ${missionMax}`} validator={completeMissionDescriptionValidator} />
						<Button
							variant={'secondary'}
							className={'btn-form-info'}
							onClick={() => {
								// Info: Modal Title
								handleModalTitle('Mission Description Information');

								// Info: Modal Content
								handleModalContent(`
                                <p>Describe what the request is for. What exercise, deployment, operation, training,
                                etc this request will fulfill by being supported by NALO. This description along
                                with compelling considerations and reason for commercial air unacceptable will be
                                used to determine the proper PUJC for this lift. We require as much detail as possible
                                to properly validate and schedule.</p>
                            `);

								// Show Modal
								handleModalToggle();
							}}>
							<i className={'fa-solid fa-info modal-trigger'}></i>
							<span className={'sr-only'}>Click to view information about XXXXX</span>
						</Button>
					</div>
				</div>
				{/* Complete Mission Description: End */}

				{/* Reason for Military Airlift or Reason Commercial Travel Unacceptable: Start */}
				<div className={'col-12 has-info'}>
					<div className={'form-group'}>
						<Field key={'reasonCommercialUnacceptable'} id={'reasonCommercialUnacceptable'} name={'justification'} label={'Reason for Military Airlift or Reason Commercial Travel Unacceptable'} optional={false} component={FormTextArea} onChange={handleAirliftReasonCount} maxLength={airliftMax} value={airliftReasonCount} hint={`Minimum ${airliftMin} characters/Maximum ${airliftMax} characters. ${airliftReasonCount.length} of ${airliftMax}`} validator={commercialTravelUnacceptableDescriptionValidator} />
						<Button
							variant={'secondary'}
							className={'btn-form-info'}
							onClick={() => {
								// Info: Modal Title
								handleModalTitle('Justification Information');

								// Info: Modal Content
								handleModalContent(`
                                <p>If this lift cannot be satisfied, what impact will that have on the unit requesting? What is the reason
                                behind not taking commercial air instead of NALO?</p>
                                <p>(Content of cargo, unit cohesion, airfield restrictions, strict time line)</p>
                                <p><b>** "Cost too high" is NOT ACCEPTABLE **</b></p>
                            `);

								// Show Modal
								handleModalToggle();
							}}>
							<i className={'fa-solid fa-info modal-trigger'}></i>
							<span className={'sr-only'}>Click to view information about XXXXX</span>
						</Button>
					</div>
				</div>
				{/* Reason for Military Airlift or Reason Commercial Travel Unacceptable: End */}
			</Row>
			{/* Purpose of Travel: End */}

			{/* Departure Flight Itinerary: Start */}
			<Row id={'itinerary-departure-information'} className={'form-section'}>
				{/* Section Title - Desired Flight Itinerary: Start */}
				<div className={'col-12 form-section-title itinerary-departure-information'}>
					<h3>Itinerary Departure Information</h3>
				</div>
				{/* Section Title - Desired Flight Itinerary: End */}

				{/* Departure Flight Itinerary Block: Start */}
				<div id={'itinerary-departure-information-action-block'} className={'col-12 has-info'}>
					<Row>
						{/* Desired Departure ICAO: Start */}
						<div className={'col-4'}>
							<div className={'form-group'}>
								<Field key={'departure'} id={'departure'} name={'departure.airport'} label={'Desired Departure ICAO'} textField={'history.0.icaoName'} dataItemKey={'history.0.icao'} placeholder={'Select the Desired Departure ICAO...'} component={FormComboBox} validator={requiredValidator} data={departureAirports} loading={departureAirportsLoading} allowCustom={true} filterable={true} onFilterChange={filterDepartureAirportChange} />
							</div>
						</div>
						{/* Desired Departure ICAO: End */}

						{/* Alternate Departure ICAO: Start * /}
                        <div className={"col-6"}>
                            <div className={"form-group"}>
                                <Field
                                    key={"altDepartureIcao"}
                                    id={"altDepartureIcao"}
                                    name={"altDepartureIcao"}
                                    label={"Alternate Departure ICAO"}
                                    placeholder={""}
                                    component={FormInput}
                                />
                            </div>
                        </div>
                        {/ * Alternate Departure ICAO: End */}

						{/* Earliest Departure Date/Time: Start */}
						<div className={'col-4'}>
							<div className={'form-group'}>
								<Field key={'earliestDepartureDateTime'} id={'earliestDepartureDateTime'} name={'departure.earliest'} label={'Earliest Departure Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} validator={requiredValidator} onChange={handleDateChange} hint={timeWindowWarningDep ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ''} />
							</div>
						</div>
						{/* Earliest Departure Date/Time: End */}

						{/* Latest Departure Date/Time: Start */}
						<div className={'col-4'}>
							<div className={'form-group'}>
								<Field key={'latestDepartureDateTime'} id={'latestDepartureDateTime'} name={'departure.latest'} label={'Latest Departure Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} validator={requiredValidator} onChange={handleDateChange} hint={timeWindowWarningDep ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ''} />
								<Button
									variant={'secondary'}
									className={'btn-form-info'}
									onClick={() => {
										// Info: Modal Title
										handleModalTitle('Latest Departure Information');

										// Info: Modal Content
										handleModalContent(`
                                        <p>Choose the largest time window possible.</p>
                                        <p>Requests with larger time windows are more likely to get scheduled. </p>
                                    `);

										// Show Modal
										handleModalToggle();
									}}>
									<i className={'fa-solid fa-info modal-trigger'}></i>
									<span className={'sr-only'}>Click to view information about XXXXX</span>
								</Button>
							</div>
						</div>
						{/* Latest Departure Date/Time: End */}

						{/* Departure Alternative Airports: Start */}
						<div className={'col-12'}>
							<label>List of Alternative Departure Airports</label>
							<FieldArray name="departureAlternativeAirports" component={AltDepartureAirportsGrid} />
						</div>
						{/* Departure Alternative Airports: End */}
					</Row>
				</div>
				{/* Departure Flight Itinerary Block: End */}
			</Row>
			{/* Departure Flight Itinerary: End */}

			{/* Itinerary Arrival Information: Start */}
			<Row id={'itinerary-arrival-information'} className={'form-section'}>
				{/* Section Title - Alternative Flight Itinerary: Start */}
				<div className={'col-12 form-section-title itinerary-arrival-information'}>
					<h3>Itinerary Arrival Information</h3>
				</div>
				{/* Section Title - Alternative Flight Itinerary: End */}

				{/* Itinerary Arrival Information Block: Start */}
				<div id={'itinerary-arrival-information-action-block'} className={'col-12 has-info'}>
					<Row>
						{/* Desired Arrival ICAO: Start */}
						<div className={'col-4'}>
							<div className={'form-group'}>
								<Field key={'arrival'} id={'arrival'} name={'arrival.airport'} label={'Desired Arrival ICAO'} textField={'history.0.icaoName'} dataItemKey={'history.0.icao'} placeholder={'Select the Desired Arrival ICAO...'} component={FormComboBox} data={arrivalAirports} validator={requiredValidator} loading={arrivalAirportsLoading} allowCustom={true} filterable={true} onFilterChange={filterArrivalAirportChange} />
							</div>
						</div>
						{/* Desired Arrival ICAO: End */}

						{/* Alternate Arrival ICAO: Start * /}
                        <div className={"col-6"}>
                            <div className={"form-group"}>
                                <Field
                                    key={"altArrivalIcao"}
                                    id={"altArrivalIcao"}
                                    name={"altArrivalIcao"}
                                    label={"Alternate Arrival ICAO"}
                                    placeholder={""}
                                    component={FormInput}
                                    />
                            </div>
                        </div>
                        {/ * Alternate Arrival ICAO: End */}

						{/* Earliest Arrival Date/Time: Start */}
						<div className={'col-4'}>
							<div className={'form-group'}>
								<Field key={'earliestArrivalDateTime'} id={'earliestArrivalDateTime'} name={'arrival.earliest'} label={'Earliest Arrival Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} onChange={handleDateChange} validator={requiredValidator} hint={timeWindowWarningArr ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ''} />
							</div>
						</div>
						{/* Earliest Arrival Date/Time: End */}

						{/* Latest Arrival Date/Time: Start */}
						<div className={'col-4'}>
							<div className={'form-group'}>
								<Field key={'latestArrivalDateTime'} id={'latestArrivalDateTime'} name={'arrival.latest'} label={'Latest Arrival Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} onChange={handleDateChange} validator={requiredValidator} hint={timeWindowWarningArr ? 'Warning: Providing a wider date/time range will increase the likelihood of your request being satisfied.' : ''} />
								<Button
									variant={'secondary'}
									className={'btn-form-info'}
									onClick={() => {
										// Info: Modal Title
										handleModalTitle('Latest Arrival Information');

										// Info: Modal Content
										handleModalContent(`
                                        <p>Choose the largest time window possible.</p>
                                        <p>Requests with larger time windows are more likely to get scheduled. </p>
                                    `);

										// Show Modal
										handleModalToggle();
									}}>
									<i className={'fa-solid fa-info modal-trigger'}></i>
									<span className={'sr-only'}>Click to view information about XXXXX</span>
								</Button>
							</div>
						</div>
						{/* Latest Arrival Date/Time: End */}

						{/* Arrival Alternative Airports: Start */}
						<div className={'col-12'}>
							<label>List of Alternative Arrival Airports</label>
							<FieldArray name="arrivalAlternativeAirports" component={AltArrivalAirportsGrid} />
						</div>
						{/* Arrival Alternative Airports: End */}
					</Row>
				</div>
				{/* Itinerary Arrival Information Block: End */}
			</Row>
			{/* Itinerary Arrival Information: End */}

			<LiftRequestInfoModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalContent={modalContent} />
		</React.Fragment>
	);
};
