/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import makeAnimated from 'react-select/animated';

import data from '../../../Assets/json/temp-missions.json';

const AnalysisFlight = () => {
	// Action Buttons
	const actionButtons = (id) => {
		return (
			<div className={`actions`}>
				<ul className={`actions-list`}>
					{/*
                    <li className={`action-item view`}>
                        <NavLink to={`/liftrequest/${id}`} title={`Click to View ${id}`}>
                            <i className="fa-solid fa-eye"></i>
                            <span className={`sr-only`}>View</span>
                        </NavLink>
                    </li>
                    */}
					<li className={`action-item edit`}>
						<NavLink to={`/mission/${id}`} title={`Click to Edit ${id}`}>
							<i className="fa-solid fa-pen-to-square"></i>
							<span className={`sr-only`}>Edit Mission</span>
						</NavLink>
					</li>
					{/*
                    <li className={`action-item export`}>
                        <NavLink to={`/liftrequest/${id}`} title={`Click to Export ${id}`}>
                            <i className="fa-solid fa-file-export"></i>
                            <span className={`sr-only`}>Export</span>
                        </NavLink>
                    </li>
                    */}
				</ul>
			</div>
		);
	};

	// Columns
	const columns = [
		{
			name: 'Mission #',
			cell: (data) => (
				<NavLink to={`/mission/${data.missionid}`} title={`Click to View ${data.missionid}`}>
					<span className={`ellipsis`}>{data.missionid}</span>
				</NavLink>
			),
			sortable: false
		},
		{
			name: 'Call Sign',
			selector: (row) => row.callsign,
			sortable: true
		},
		{
			name: 'Flying Unit',
			selector: (row) => row.flyingunit,
			sortable: true,
			hide: 'md'
		},
		{
			name: 'A/C Type',
			selector: (row) => row.actype,
			sortable: true
		},
		{
			name: 'Dep ICAO',
			selector: (row) => row.depicao,
			sortable: true,
			hide: 'md'
		},
		{
			name: 'Dep Time/Date (Z)',
			selector: (row) => row.deptimedatez,
			sortable: true,
			hide: 'md'
		},
		{
			name: 'Arr ICAO',
			selector: (row) => row.arricao,
			sortable: true
		},
		{
			name: 'Arr Time/Date(Z)',
			selector: (row) => row.arrtimedatez,
			sortable: true
		},
		{
			name: 'Scheduled Cargo',
			selector: (row) => row.scheduledcaro,
			sortable: true
		},
		{
			name: 'Available Cargo',
			selector: (row) => row.availablecargo,
			sortable: true
		},
		{
			name: 'Actions',
			cell: (data) => actionButtons(data.missionid),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true
		}
	];

	// Table Data Config
	const tableData = {
		columns,
		data,
		filter: false,
		filterPlaceholder: 'Search...',
		fileName: 'liftrecords',
		exportHeader: false,
		export: false, // Throws error due to React 18+
		print: false // Throws error due to React 18+
	};

	// Select: Make Animated
	const animatedComponents = makeAnimated();

	// Select Options: Call Sign
	const optsCallSign = [
		{ value: 'cnv1234', label: 'CNV 1234' },
		{ value: 'cnv1303', label: 'CNV 1303' },
		{ value: 'cnv2345', label: 'CNV 2345' },
		{ value: 'cnv2541', label: 'CNV 2541' },
		{ value: 'cnv4457', label: 'CNV 4457' },
		{ value: 'cnv4567', label: 'CNV 4567' },
		{ value: 'cnv6897', label: 'CNV 6897' }
	];

	// Select Options: Flying Unit
	const optsFlyingUnit = [
		{ value: 'vr-51', label: 'VR-51' },
		{ value: 'vr-56', label: 'VR-56' },
		{ value: 'vr-57', label: 'VR-57' },
		{ value: 'vr-58', label: 'VR-58' },
		{ value: 'vr-59', label: 'VR-59' },
		{ value: 'vr-62', label: 'VR-62' },
		{ value: 'vr-64', label: 'VR-64' }
	];

	// Select Options: Air Craft Type
	const optsAirCraftType = [
		{ value: 'c40', label: 'C40' },
		{ value: 'c130t', label: 'C130T' }
	];

	const customFilters = () => {
		return (
			<div className={`datalist-filters`}>
				<div className={`row`}>
					<div className={`col-3`}>
						<div className={`form-group`}>
							<label htmlFor={`search`}>Search</label>
							<input type={`text`} id={`search`} placeholder={`Search by...`} />
						</div>
					</div>
					<div className={`col-3`}>
						<div className={`form-group`}>
							<label htmlFor={`callsign`}>Call Sign</label>
							<Select id={`callsign`} className={`custom-select`} options={optsCallSign} closeMenuOnSelect={false} components={animatedComponents} isMulti />
						</div>
					</div>
					<div className={`col-3`}>
						<div className={`form-group`}>
							<label htmlFor={`flyingunit`}>Flying Unit</label>
							<Select id={`flyingunit`} className={`custom-select`} options={optsFlyingUnit} closeMenuOnSelect={false} components={animatedComponents} isMulti />
						</div>
					</div>
					<div className={`col-3`}>
						<div className={`form-group`}>
							<label htmlFor={`actype`}>Air Craft Type</label>
							<Select id={`actype`} className={`custom-select`} options={optsAirCraftType} closeMenuOnSelect={false} components={animatedComponents} isMulti />
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			{customFilters()}

			<DataTableExtensions {...tableData}>
				<DataTable columns={columns} data={data} className={`datatable`} highlightOnHover responsive striped pagination />
			</DataTableExtensions>
		</div>
	);
};

export default AnalysisFlight;
