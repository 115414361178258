/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import { NavLink } from 'react-router-dom';

import Layout from '../../Core/Layout';

const Unauthorized = (props) => {
	return (
		<Layout apptheme={'theme-unauthorized'} userrole={'no-role'} className={'unauthorized'}>
			<div className={'unauthorized-wrapper'}>
				<div className={'unauthorized-wrapper-inner'}>
					<p>You are not authorized to view this page.</p>
					<NavLink to="/dashboard" title={'Click to View the Dashboard'}>
						Go To Dashboard
					</NavLink>
				</div>
			</div>
		</Layout>
	);
};

export default Unauthorized;
