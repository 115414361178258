/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect, useState } from 'react';

// eslint-disable-next-line
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

// eslint-disable-next-line
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

// eslint-disable-next-line
import { ApiDisruptionsList, ApiUpdateDisruptionById, ApiLegsById } from '../../Helpers/Apis';

import { CapitalizeString } from '../../Helpers/ContentFormatting';
import AirportMapModal from '../../App/Modals/AirportMapModal';
import { stripTimeZone } from '../../Helpers/DateTimeConversions';
import { toast } from 'react-toastify';

const DisruptionInformation = (props) => {
	// Used to Refresh on State Change
	const { state } = useLocation();

	// Used to Refresh on State Change
	// eslint-disable-next-line
	const [rerender, setRerender] = useState(false);

	const params = useParams();
	const navigate = useNavigate();

	const dmId = params.disruptionId;

	const [disruptionData, setDisruptionData] = React.useState('');
	const [affectedMissionId, setAffectedMissionId] = React.useState('');

	// Map Modal
	const [toggleModal, setToggleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalContent, setModalContent] = useState('');

	const [legData, setLegData] = React.useState([]);

	const handleModalToggle = () => setToggleModal(!toggleModal);

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	const handleCompleteState = (id) => {
		let submissionData = disruptionData.history[0];

		// Set State to COMPLETED
		submissionData.state = 'COMPLETED';

		ApiUpdateDisruptionById(id, submissionData).then((res) => {
			toast.success('Disruption Marked Completed Successfully!');
		});

		setRerender(!rerender);
	};

	const legsDataFetch = () => {
		if (affectedMissionId) {
			ApiLegsById(affectedMissionId).then((res) => {
				const data = JSON.stringify(res);
				const obj = JSON.parse(data);

				const jsonArray = [];
				var i = 0;
				while (i < res.length) {
					var from = obj[i].history[0].departure.airport.icao;
					var to = obj[i].history[0].arrival.airport.icao;
					var titleString = from + ' to ' + to;
					var jsonObject = {
						id: obj[i].id,
						start: stripTimeZone(obj[i].history[0].departure.planned),
						end: stripTimeZone(obj[i].history[0].arrival.planned),
						arrival_airport: obj[i].history[0].arrival.airport.icao,
						arrival_airport_name: obj[i].history[0].arrival.airport.name,
						arrival_airport_lat: obj[i].history[0].arrival.airport.latitude,
						arrival_airport_long: obj[i].history[0].arrival.airport.longitude,
						departure_airport: obj[i].history[0].departure.airport.icao,
						departure_airport_name: obj[i].history[0].departure.airport.name,
						departure_airport_lat: obj[i].history[0].departure.airport.latitude,
						departure_airport_long: obj[i].history[0].departure.airport.longitude,
						satisfies_lift_requests: obj[i].history[0].satisfiesLiftRequests,
						title: titleString
					};
					jsonArray.push(jsonObject);
					i++;
				}

				setLegData(jsonArray);
			});
		}
	};

	const legsList = () => {
		return (
			<PanelBar>
				{legData.map((rec, i) => {
					const id = rec.id;

					const locationFrom = rec.departure_airport;
					const locationTo = rec.arrival_airport;

					const airportNameDeparture = rec.departure_airport_name;
					const latitudeDeparture = rec.departure_airport_lat;
					const LongitudeDeparture = rec.departure_airport_long;

					const airportNameArrival = rec.arrival_airport_name;
					const latitudeArrival = rec.arrival_airport_lat;
					const LongitudeArrival = rec.arrival_airport_long;

					const startTime = rec.start;

					const endTime = rec.end;

					const lrArray = rec.satisfies_lift_requests;

					const legTitle = <span>{`Leg ${i + 1}: ${locationFrom} to ${locationTo}`}</span>;

					return (
						<PanelBarItem key={id} title={legTitle}>
							<div className={'schedule-leg-details-content'}>
								<ul className={'schedule-leg-details-list'}>
									<li className={'schedule-leg-details-item airports'}>
										<strong>Airports</strong>
										<span className={'sr-only'}>Destination </span>
										<button
											onClick={() => {
												// Info: Modal Title
												handleModalTitle(`${airportNameDeparture} Location`);

												// Info: Modal Content
												handleModalContent([
													{
														name: airportNameDeparture,
														lat: latitudeDeparture,
														lng: LongitudeDeparture
													}
												]);

												// Show Modal
												handleModalToggle();
											}}>
											{locationFrom}
										</button>{' '}
										<i className="fa-solid fa-plane"></i> <span className={'sr-only'}>Arrival </span>
										<button
											onClick={() => {
												// Info: Modal Title
												handleModalTitle(`${airportNameArrival} Location`);

												// Info: Modal Content
												handleModalContent([
													{
														name: airportNameArrival,
														lat: latitudeArrival,
														lng: LongitudeArrival
													}
												]);

												// Show Modal
												handleModalToggle();
											}}>
											{locationTo}
										</button>
									</li>
									<li className={'schedule-leg-details-item coordinates-departure'}>
										<strong>Coordinates (Departure)</strong>
										<i className="fa-solid fa-globe"></i> {latitudeDeparture} (<em>lat</em>), {LongitudeDeparture} (<em>long</em>)
										<button
											onClick={() => {
												// Info: Modal Title
												handleModalTitle(`${airportNameDeparture} Location`);

												// Info: Modal Content
												handleModalContent([
													{
														name: airportNameDeparture,
														lat: latitudeDeparture,
														lng: LongitudeDeparture
													}
												]);

												// Show Modal
												handleModalToggle();
											}}>
											<i className="fa-solid fa-up-right-from-square"></i>
										</button>
									</li>
									<li className={'schedule-leg-details-item coordinates-arrival'}>
										<strong>Coordinates (Arrival)</strong>
										<i className="fa-solid fa-globe"></i> {latitudeArrival} (<em>lat</em>), {LongitudeArrival} (<em>long</em>)
										<button
											onClick={() => {
												// Info: Modal Title
												handleModalTitle(`${airportNameArrival} Location`);

												// Info: Modal Content
												handleModalContent([
													{
														name: airportNameArrival,
														lat: latitudeArrival,
														lng: LongitudeArrival
													}
												]);

												// Show Modal
												handleModalToggle();
											}}>
											<i className="fa-solid fa-up-right-from-square"></i>
										</button>
									</li>
									<li className={'schedule-leg-details-item coordinates-time'}>
										<strong>Time</strong>
										<i className="fa-solid fa-clock"></i> {startTime} <i className="fa-solid fa-arrow-right-long"></i> {endTime}
									</li>

									{lrArray.length !== '' && (
										<li className={'schedule-leg-details-item satisfied-lift-requests'}>
											<strong>Lift Request(s) Satisfied</strong>
											{lrArray.map((rec, i) => {
												if (i === 0) {
													return (
														<NavLink key={i} className={'nav-link'} role={'button'} to={`/liftrequest/view/${rec}`} title={`Click to View Lift Request ${rec}`} target={'_blank'}>
															{rec}
														</NavLink>
													);
												} else if (i > 0) {
													return (
														<>
															,&nbsp;
															<NavLink key={i} className={'nav-link'} role={'button'} to={`/liftrequest/view/${rec}`} title={`Click to View Lift Request ${rec}`} target={'_blank'}>
																{rec}
															</NavLink>
														</>
													);
												}
												return null;
											})}
											&nbsp;
										</li>
									)}
								</ul>
							</div>
						</PanelBarItem>
					);
				})}
			</PanelBar>
		);
	};

	const formDependencyData = () => {
		var dependencyArray = [];
		var i = 0;
		while (i < legData.length) {
			if (legData[i] && legData[i + 1]) {
				var newDependencyData = {
					id: i + 1,
					fromId: legData[i].id,
					toId: legData[i + 1].id,
					type: 1
				};
				dependencyArray.push(newDependencyData);
			}
			i++;
		}
	};

	const dataFetch = () => {
		ApiDisruptionsList('/' + params.disruptionId).then((res) => {
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log(res);
			}

			setDisruptionData(res);
			setAffectedMissionId(res.history[0].missionId[0]);
		});
	};

	const disruptionHeader = () => {
		if (disruptionData.history !== null && disruptionData.history !== undefined) {
			return (
				<table className={'table table-disruption-top-description'}>
					<tbody>
						<tr>
							<th style={{ width: '200px' }}>Disruption Type</th>
							<td className={'capitalize'}>{CapitalizeString(disruptionData.history[0].type.toLowerCase()).replace(/_/g, ' ')}</td>
						</tr>
						<tr>
							<th>Disruption ID</th>
							<td>{disruptionData.id}</td>
						</tr>
						<tr>
							<th>Date of Disruption</th>
							<td>{disruptionData.history[0].createdAt}</td>
						</tr>
						<tr>
							<th>Status</th>
							<td>
								<span className={`pill dm-status-${disruptionData.history[0].state.replace(/\s/g, '').replace(/_/g, '').toLowerCase()}`}>{disruptionData.history[0].state.replace(/_/g, ' ')}</span>
							</td>
						</tr>
						<tr>
							<th>Mission Affected</th>
							<td>
								{disruptionData.history[0] !== null && disruptionData.history[0] !== undefined && disruptionData.history[0].missionId[0] !== null && disruptionData.history[0].missionId[0] !== undefined ? (
									<NavLink to={`/missionstatus/${disruptionData.history[0].missionId[0]}`} title={'Click to View Mission Details'} target={'_blank'}>
										<i className="fa-solid fa-arrow-up-right-from-square"></i> {disruptionData.history[0].missionId[0]}
									</NavLink>
								) : (
									'-'
								)}
							</td>
						</tr>
					</tbody>
				</table>
			);
		}
	};

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<NavLink className={'nav-link'} to={'/disruptions'} onClick={() => {}}>
							<i className={'fa-solid fa-arrow-left-long'}></i>
							Return to Disruption Management
						</NavLink>
						{disruptionData.history !== null && disruptionData.history !== undefined && disruptionData.history[0].state === 'PROCESSED' && (
							<button
								className="nav-link"
								title={'Click to Mark as Completed'}
								onClick={() => {
									handleCompleteState(dmId);
								}}>
								<i className={'fa-regular fa-file-check padding-right-10'}></i>
								Mark Completed
							</button>
						)}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	useEffect(() => {
		legsDataFetch();
	}, [navigate, affectedMissionId]);

	useEffect(() => {
		formDependencyData();
	}, [legData]);

	useEffect(() => {
		dataFetch();
	}, [props]);

	// rerender Used to Refresh on Delete
	useEffect(() => {
		dataFetch();
	}, [rerender]);

	// Used to Refresh on Delete
	useEffect(() => {
		dataFetch();
	}, [state]);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2>Disruption ID: {dmId}</h2>

							{PageTitleControls()}
						</div>

						<Row className={'disruption-notice-information margin-top-10'}>
							<div className={'col-12'}>
								{disruptionData.history !== null && disruptionData.history !== undefined && disruptionData.history[0].state === 'CREATED' && (
									<div className={'alert alert-danger'} role={'alert'}>
										Please Return to the Disruption Management Dashboard and Click "Submit for Processing"
									</div>
								)}

								{disruptionData.history !== null && disruptionData.history !== undefined && disruptionData.history[0].state === 'PROCESSED' && (
									<div className={'alert alert-warning'} role={'alert'}>
										Please mark as completed.
									</div>
								)}

								{disruptionData.history !== null && disruptionData.history !== undefined && disruptionData.history[0].state === 'COMPLETED' && (
									<div className={'alert alert-success'} role={'alert'}>
										Disruption has been marked complete.
									</div>
								)}
							</div>
						</Row>
					</Panel>

					<Panel>
						<Row className={'disruption-top-description margin-top-5'}>
							<div className={'col-12 form-section'}>
								<div className={'form-section-title'}>
									<h3>General Details</h3>
								</div>
							</div>
							<div className={'col-12'}>{disruptionHeader()}</div>
						</Row>
					</Panel>

					<Panel>
						<Row className={'disruption-old-mission'}>
							<div className={'col-12 form-section'}>
								<div className={'form-section-title'}>
									<h3>Mission Details</h3>
								</div>
							</div>

							<div id={'schedule-legs-view'} className={'col-12 mission-schedule-output legs-view mission-selected padding-top-0'}>
								<div className={'schedule-list-inner'}>{legsList()}</div>
							</div>
						</Row>
					</Panel>

					<AirportMapModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalContent={modalContent} />

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

export default DisruptionInformation;
