/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom';

import { Col, Container, Row, Button } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { ApiMissionListByState, ApiMissionByIdHelper, ApiLegsById } from '../../Helpers/Apis';

import AirportMapModal from '../../App/Modals/AirportMapModal';
import { stripTimeZone } from '../../Helpers/DateTimeConversions';

const ScheduleLegs = (props) => {
	const params = useParams();
	const [missionsList, setMissionsList] = React.useState([]);

	const missionId = params.missionId ? params.missionId : '';
	const [missionName, setMissionName] = React.useState('');

	const [windowView, setWindowView] = React.useState('minimize'); // maximize, minimize toggle
	const [missionList, setMissionList] = React.useState(true);

	// Map Modal
	const [toggleModal, setToggleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalContent, setModalContent] = useState('');

	// Gantt Chart: Start
	const [legData, setLegData] = React.useState([]);

	const handleModalToggle = () => setToggleModal(!toggleModal);

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	const navigate = useNavigate();

	const missionDataFetch = () => {
		ApiMissionByIdHelper(missionId).then((res) => {
			setMissionName(res.missionName);
		});
	};

	const legsDataFetch = () => {
		if (missionId) {
			ApiLegsById(missionId).then((res) => {
				const data = JSON.stringify(res);
				const obj = JSON.parse(data);

				const jsonArray = [];
				var i = 0;
				while (i < res.length) {
					var from = obj[i].history[0].departure.airport.icao;
					var to = obj[i].history[0].arrival.airport.icao;
					var titleString = from + ' to ' + to;
					var jsonObject = {
						id: obj[i].id,
						start: stripTimeZone(obj[i].history[0].departure.planned),
						end: stripTimeZone(obj[i].history[0].arrival.planned),
						arrival_airport: obj[i].history[0].arrival.airport.icao,
						arrival_airport_name: obj[i].history[0].arrival.airport.name,
						arrival_airport_lat: obj[i].history[0].arrival.airport.latitude,
						arrival_airport_long: obj[i].history[0].arrival.airport.longitude,
						departure_airport: obj[i].history[0].departure.airport.icao,
						departure_airport_name: obj[i].history[0].departure.airport.name,
						departure_airport_lat: obj[i].history[0].departure.airport.latitude,
						departure_airport_long: obj[i].history[0].departure.airport.longitude,
						satisfies_lift_requests: obj[i].history[0].satisfiesLiftRequests,
						title: titleString
					};
					jsonArray.push(jsonObject);
					i++;
				}

				setLegData(jsonArray);
			});
		}
	};

	const formDependencyData = () => {
		var dependencyArray = [];
		var i = 0;
		while (i < legData.length) {
			if (legData[i] && legData[i + 1]) {
				var newDependencyData = {
					id: i + 1,
					fromId: legData[i].id,
					toId: legData[i + 1].id,
					type: 1
				};
				dependencyArray.push(newDependencyData);
			}
			i++;
		}
	};

	const missionsListDataFetch = () => {
		const queryparams = '?state=GENERATED&state=DEPARTED';
		ApiMissionListByState(queryparams).then((res) => {
			setMissionsList(res);
		});
	};

	useEffect(() => {
		missionDataFetch();
		missionsListDataFetch();
		missionsNavigationFetch();
	}, []);

	useEffect(() => {
		legsDataFetch();
		missionDataFetch();
	}, [navigate]);

	useEffect(() => {
		formDependencyData();
	}, [legData]);

	const missionsNavigationFetch = () => {
		missionsList.map((rec, i) => {
			const mId = rec.id;
			ApiLegsById(mId).then((res) => {
				const jsonArray = [];
				var i = 0;
				while (i < res.length) {
					var from = res[i].history[0].departure && res[i].history[0].departure.airport && res[i].history[0].departure.airport.icao ? res[i].history[0].departure.airport.icao : '';
					var to = res[i].history[0].arrival && res[i].history[0].arrival.airport && res[i].history[0].arrival.airport.icao ? res[i].history[0].arrival.airport.icao : '';
					var titleString = from + ' to ' + to;
					var jsonObject = {
						id: res[i].id,
						start: res[i].history[0].departure && res[i].history[0].departure.planned ? stripTimeZone(res[i].history[0].departure.planned) : '',
						end: res[i].history[0].arrival && res[i].history[0].arrival.planned ? stripTimeZone(res[i].history[0].arrival.planned) : '',
						arrival_airport: res[i].history[0].arrival && res[i].history[0].arrival.airport && res[i].history[0].arrival.airport.icao ? res[i].history[0].arrival.airport.icao : '',
						arrival_airport_lat: res[i].history[0].arrival && res[i].history[0].arrival.airport && res[i].history[0].arrival.airport.latitude ? res[i].history[0].arrival.airport.latitude : '',
						arrival_airport_long: res[i].history[0].arrival && res[i].history[0].arrival.airport && res[i].history[0].arrival.airport.longitude ? res[i].history[0].arrival.airport.longitude : '',
						departure_airport: res[i].history[0].departure && res[i].history[0].departure.airport && res[i].history[0].departure.airport.icao ? res[i].history[0].departure.airport.icao : '',
						departure_airport_lat: res[i].history[0].departure && res[i].history[0].departure.airport && res[i].history[0].arrival.airport.latitude ? res[i].history[0].departure.airport.latitude : '',
						departure_airport_long: res[i].history[0].departure && res[i].history[0].departure.airport && res[i].history[0].departure.airport.longitude ? res[i].history[0].departure.airport.longitude : '',
						title: titleString
					};
					jsonArray.push(jsonObject);
					i++;
				}
			});
			return null;
		});
	};

	// Mission Sidebar Selection List
	const missionsNavigation = () => {
		return (
			<div className={'mission-schedules-list'}>
				<div className={'mission-schedules-toggle'}>
					<Navbar bg="light" expand="lg">
						<Button
							className={`mission-schedules-list-button btn-list-missions-view ${missionList ? 'show-list' : 'hide-list'}`}
							title={`Click to ${missionList ? 'Hide' : 'Show'} Mission List`}
							onClick={() => {
								setMissionList(!missionList);
							}}>
							<i className={'fa fa-bars'}></i>
							<span>{`${missionList ? 'Hide' : 'Show'}`} Mission List</span>
						</Button>
					</Navbar>
				</div>

				<div className={'mission-schedules-list-inner'}>
					<ul>
						{missionsList.map((rec, i) => {
							const missionName = rec.missionName === null ? rec.id : rec.missionName;
							const mId = rec.id;
							const createdAt = stripTimeZone(rec.history[0].createdAt);
							const timeframe = {
								__html: '<strong>' + missionName + '</strong><span class="daterange">Created on ' + createdAt + '</span>'
							};

							return (
								<li key={i}>
									<NavLink to={`/schedule/legs/${mId}`} title={`View Mission Leg Data for ${mId}`}>
										<span dangerouslySetInnerHTML={timeframe} />
									</NavLink>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};

	// Header Filters
	const headerFilterList = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						{/* Print and export button placeholders to be implemented in ALO-549 */}
						{/* <div className={"nav-link-group"}>
                            <Nav.Link href="#print" className={"icon-only"}>
                                <i className={"fa fa-print"}></i>
                                <span className={"Print"}></span>
                            </Nav.Link>
                            <Nav.Link href="#export" className={"icon-only"}>
                                <i className={"fa fa-file-export"}></i>
                                <span className={"Export"}></span>
                            </Nav.Link>
                        </div> */}

						<div className={'nav-link-group'}>
							<NavLink to={`${missionId ? '/schedule/map/' + missionId : '/schedule/map'}`} className={'nav-link btn-map-view icon-only'} title={'View Map of Selected Mission'}>
								<i className={'fa fa-globe'}></i>
								<span className={'Click for Map View'}></span>
							</NavLink>
							<NavLink to={`${missionId ? '/missionstatus/' + missionId : '/missionstatus'}`} className={'nav-link btn-missionstatus-view icon-only'} title={'View Mission Status for Selected Mission'}>
								<i className={'fa fa-plane-departure'}></i>
								<span className={'Click for Mission Status'}></span>
							</NavLink>
							<NavLink to={`${missionId ? '/schedule/legs/' + missionId : '/schedule/legs'}`} className={'nav-link btn-legs-view icon-only active'} title={'View Legs of Selected Mission'}>
								<i className={'fa fa-table-rows'}></i>
								<span className={'Click for Mission Legs View'}></span>
							</NavLink>
							<NavLink to={`${missionId ? '/radarbox/map/' + missionId : '/radarbox/map'}`} className={'nav-link btn-map-view icon-only'} title={'View RadarBox AirNav Map'}>
								<i className={'fa-solid fa-radar'}></i>
								<span className={'sr-only'}>Click for RadarBox AirNav Map</span>
							</NavLink>
						</div>

						<div className={'nav-link-group'}>
							{windowView === 'minimize' ? (
								<Nav.Link
									href="#maximize"
									className={'btn-maximize-view icon-only'}
									onClick={() => {
										setWindowView('maximize');
									}}>
									<i className={'fa fa-arrows-maximize'}></i>
									<span className={'Full Screen'}></span>
								</Nav.Link>
							) : (
								<Nav.Link
									href="#minimize"
									className={'btn-minimize-view icon-only'}
									onClick={() => {
										setWindowView('minimize');
									}}>
									<i className={'fa fa-arrows-minimize'}></i>
									<span className={'Exit Full Screen'}></span>
								</Nav.Link>
							)}
						</div>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	const legsList = () => {
		return (
			<PanelBar>
				{legData.map((rec, i) => {
					const id = rec.id;

					const locationFrom = rec.departure_airport;
					const locationTo = rec.arrival_airport;

					const airportNameDeparture = rec.departure_airport_name;
					const latitudeDeparture = rec.departure_airport_lat;
					const LongitudeDeparture = rec.departure_airport_long;

					const airportNameArrival = rec.arrival_airport_name;
					const latitudeArrival = rec.arrival_airport_lat;
					const LongitudeArrival = rec.arrival_airport_long;

					const startTime = rec.start;
					const endTime = rec.end;

					const lrArray = rec.satisfies_lift_requests;

					const legTitle = <span>{`Leg ${i + 1}: ${locationFrom} to ${locationTo}`}</span>;

					return (
						<PanelBarItem key={id} title={legTitle}>
							<div className={'schedule-leg-details-content'}>
								<ul className={'schedule-leg-details-list'}>
									<li className={'schedule-leg-details-item airports'}>
										<strong>Airports</strong>
										<span className={'sr-only'}>Destination </span>
										<button
											onClick={() => {
												// Info: Modal Title
												handleModalTitle(`${airportNameDeparture} Location`);

												// Info: Modal Content
												handleModalContent([
													{
														name: airportNameDeparture,
														lat: latitudeDeparture,
														lng: LongitudeDeparture
													}
												]);

												// Show Modal
												handleModalToggle();
											}}>
											{locationFrom}
										</button>{' '}
										<i className="fa-solid fa-plane"></i> <span className={'sr-only'}>Arrival </span>
										<button
											onClick={() => {
												// Info: Modal Title
												handleModalTitle(`${airportNameArrival} Location`);

												// Info: Modal Content
												handleModalContent([
													{
														name: airportNameArrival,
														lat: latitudeArrival,
														lng: LongitudeArrival
													}
												]);

												// Show Modal
												handleModalToggle();
											}}>
											{locationTo}
										</button>
									</li>
									<li className={'schedule-leg-details-item coordinates-departure'}>
										<strong>Coordinates (Departure)</strong>
										<i className="fa-solid fa-globe"></i> {latitudeDeparture} (<em>lat</em>), {LongitudeDeparture} (<em>long</em>)
										<button
											onClick={() => {
												// Info: Modal Title
												handleModalTitle(`${airportNameDeparture} Location`);

												// Info: Modal Content
												handleModalContent([
													{
														name: airportNameDeparture,
														lat: latitudeDeparture,
														lng: LongitudeDeparture
													}
												]);

												// Show Modal
												handleModalToggle();
											}}>
											<i className="fa-solid fa-up-right-from-square"></i>
										</button>
									</li>
									<li className={'schedule-leg-details-item coordinates-arrival'}>
										<strong>Coordinates (Arrival)</strong>
										<i className="fa-solid fa-globe"></i> {latitudeArrival} (<em>lat</em>), {LongitudeArrival} (<em>long</em>)
										<button
											onClick={() => {
												// Info: Modal Title
												handleModalTitle(`${airportNameArrival} Location`);

												// Info: Modal Content
												handleModalContent([
													{
														name: airportNameArrival,
														lat: latitudeArrival,
														lng: LongitudeArrival
													}
												]);

												// Show Modal
												handleModalToggle();
											}}>
											<i className="fa-solid fa-up-right-from-square"></i>
										</button>
									</li>
									<li className={'schedule-leg-details-item coordinates-time'}>
										<strong>Time</strong>
										<i className="fa-solid fa-clock"></i> {startTime} <i className="fa-solid fa-arrow-right-long"></i> {endTime}
									</li>

									{lrArray.length !== '' && (
										<li className={'schedule-leg-details-item satisfied-lift-requests'}>
											<strong>Lift Request(s) Satisfied</strong>
											{lrArray.map((rec, i) => {
												if (i === 0) {
													return (
														<NavLink key={i} className={'nav-link'} role={'button'} to={`/liftrequest/view/${rec}`} title={`Click to View Lift Request ${rec}`} target={'_blank'}>
															{rec}
														</NavLink>
													);
												} else if (i > 0) {
													return (
														<>
															,&nbsp;
															<NavLink key={i} className={'nav-link'} role={'button'} to={`/liftrequest/view/${rec}`} title={`Click to View Lift Request ${rec}`} target={'_blank'}>
																{rec}
															</NavLink>
														</>
													);
												}
												return null;
											})}
											&nbsp;
										</li>
									)}
								</ul>
							</div>
						</PanelBarItem>
					);
				})}
			</PanelBar>
		);
	};

	return (
		<Container fluid className={`app-content ${windowView}`}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2>{missionId.length ? `Mission Schedule for ${missionName}` : 'Mission Schedules'}</h2>

							{headerFilterList()}
						</div>

						<div className={`mission-schedules ${missionList ? 'sidebar-expanded' : 'sidebar-collapsed'}`}>
							<div className={'mission-schedules-inner'}>
								{missionsNavigation()}

								{missionId.length ? (
									<React.Fragment>
										<div id={'schedule-legs-view'} className={'mission-schedule-output legs-view mission-selected'}>
											<div className={'mission-schedule-inner'}>
												<div className={'schedule-section-header'}>
													<h4 className={'block-section-header'}>Mission Legs Data</h4>
												</div>
												<div className={'schedule-list schedule-legs'}>
													<div className={'schedule-list-inner'}>{legsList()}</div>
												</div>
											</div>
										</div>
									</React.Fragment>
								) : (
									<div id={'schedule-no-view'} className={'mission-schedule-output no-mission no-mission-selected'}>
										<div className={'mission-schedule-inner'}>
											<div className={'schedule-section-header'}>
												<h4 className={'block-section-header'}>Mission Legs Data</h4>
											</div>
											<div className={'schedule-list schedule-legs'}>
												<div className={'schedule-list-inner'}>
													<h3>Please select a mission</h3>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</Panel>

					<AirportMapModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalContent={modalContent} />

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};
export default ScheduleLegs;
