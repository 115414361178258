/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */
import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';

import { Grid, GridColumn as Column, GridNoRecords, getSelectedState } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AssetsColumns } from '../../Helpers/DataGrids';

import { ApiAssetsList } from '../../Helpers/Apis';

import AirportMapModal from '../Modals/AirportMapModal';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const initialDataState = {
	skip: 0,
	take: 10
};

const SELECTED_FIELD = 'selected';

const AssetAvailabilityList = (props) => {
	const [page, setPage] = React.useState(initialDataState);
	const [assets, setAssets] = React.useState([]);
	const [toggleModal, setToggleModal] = useState(false);
	const [modalTitle] = useState('');
	const [modalContent] = useState('');
	const [initialSelectedAssets] = React.useState(props.assetIdArray);

	const [sandboxRemovalDialogVisible, setSandboxRemovalDialogVisible] = React.useState(false);
	const [sandboxRemovalDialogId, setSandboxRemovalDialogId] = React.useState('');

	const [selectedState, setSelectedState] = React.useState({});

	const environment = props.environment;
	const paramPage = props.page;
	const sandboxData = props.data;

	const pageChange = (event) => {
		setPage(event.page);
	};

	const handleModalToggle = () => setToggleModal(!toggleModal);

	const dataFetch = () => {
		ApiAssetsList().then((res) => {
			if (environment !== 'selection') {
				setAssets(res);
			} else {
				res.map((dataItem) =>
					Object.assign(
						{
							selected: false
						},
						dataItem
					)
				);
				setAssets(res);
			}
		});
	};

	const SandboxRemovalDialogWindow = (id) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleSandboxRemovalDialog}>
				<div data-id={id}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to remove this asset from the sandbox?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Remove Asset from Sandbox'} onClick={toggleSandboxRemovalDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							props.handleRemoveAssets(id);
							toggleSandboxRemovalDialog();
							setSandboxRemovalDialogId('');
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const toggleSandboxRemovalDialog = () => {
		setSandboxRemovalDialogVisible(!sandboxRemovalDialogVisible);
	};

	const openRemoveSandboxDialog = (id) => {
		toggleSandboxRemovalDialog();
		setSandboxRemovalDialogId(id);
	};

	useEffect(() => {
		if (environment === 'sandbox') {
			setAssets(sandboxData);
			initialDataState.take = 6;
		} else if (paramPage === 'sandboxEdit') {
			initialDataState.take = 6;
		} else if (environment === 'selection') {
			dataFetch();

			let selectedStateObj = {};

			// eslint-disable-next-line
			initialSelectedAssets.map((assetId) => {
				selectedStateObj[assetId] = true;
			});
			props.setPotentialAssetIdArray(initialSelectedAssets);
			setSelectedState(selectedStateObj);
		} else {
			dataFetch();
		}

		// set number of assets per page based on environment parameters
		if (environment === 'selection') {
			initialDataState.take = 12;
		} else if (paramPage === 'sandboxEdit') {
			initialDataState.take = 6;
		} else {
			initialDataState.take = 10;
		}
	}, []);

	const onSelectionChange = React.useCallback(
		(event) => {
			const newSelectedState = getSelectedState({
				event,
				selectedState: selectedState,
				dataItemKey: 'id'
			});

			setSelectedState(newSelectedState);
			let selectedIdsArray = [];
			// eslint-disable-next-line
			Object.entries(newSelectedState).map(([key, value]) => {
				if (value === true) {
					selectedIdsArray.push(key);
				}
			});
			props.setPotentialAssetIdArray(selectedIdsArray);
		},
		[selectedState]
	);

	const ActionsCell = (p) => {
		const id = p.dataItem.id;
		const sandboxId = p.dataItem.sandboxId;

		return (
			<td className={'table-actions'}>
				<div className={'actions'}>
					<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'left'}>
						<ul className={'actions-list'}>
							{paramPage !== 'sandboxEdit' && (
								<li className={'action-item edit'}>
									<NavLink className={'nav-link'} role={'button'} to={`/asset/edit/${id}`} title={`Click to Edit Asset`}>
										<i className="fa-solid fa-edit"></i>
										<span className={'sr-only'}>Edit Asset</span>
									</NavLink>
								</li>
							)}

							{/* Access: Requester (excluding nalo with base requester role) - Button to edit Lift Requests */}
							{paramPage === 'sandboxEdit' && sandboxId !== null && (
								<li className={'action-item edit'}>
									<NavLink className={'nav-link'} role={'button'} to={`/asset/edit/${id}`} title={`Click to Edit Asset`} target={'_blank'}>
										<i className="fa-solid fa-edit"></i>
										<span className={'sr-only'}>Edit Asset</span>
									</NavLink>
								</li>
							)}

							{paramPage === 'sandboxEdit' && (
								<li className={'action-item regret'}>
									<NavLink
										className={'nav-link'}
										role={'button'}
										to={`#`}
										title={'Remove Asset from Sandbox'}
										onClick={() => {
											openRemoveSandboxDialog(id);
										}}>
										<i className="fa-solid fa-trash"></i>
										<span className={'sr-only'}>Click to Remove Asset from This Sandbox</span>
									</NavLink>

									{sandboxRemovalDialogVisible && sandboxRemovalDialogId === id && SandboxRemovalDialogWindow(id)}
								</li>
							)}
						</ul>
					</Tooltip>
				</div>
			</td>
		);
	};

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<NavLink className={'nav-link'} role={'button'} to={`/asset/new`} title={`Click to Create a New Asset`}>
							<i className="fa-solid fa-circle-plus"></i>
							New Asset
						</NavLink>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	return (
		<React.Fragment>
			{environment !== 'sandbox' && environment !== 'selection' && (
				<div className={'page-title padding-bottom-10'}>
					<h2>Assets List</h2>

					{PageTitleControls()}
				</div>
			)}
			<Grid
				data={assets.slice(page.skip, page.take + page.skip).map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[item.id] }))}
				skip={page.skip}
				take={page.take}
				total={assets.length}
				pageable={{ buttonCount: 5, pageSizes: [10, 25, 50, 150] }}
				filterable={false}
				onPageChange={pageChange}
				selectedField={SELECTED_FIELD}
				onSelectionChange={onSelectionChange}
				selectable={{
					enabled: true,
					drag: false,
					cell: false,
					mode: 'multiple'
				}}>
				<GridNoRecords>No Records Available</GridNoRecords>

				{environment === 'selection' && <Column field={SELECTED_FIELD} headerSelectionValue={assets.findIndex((item) => !selectedState[item.id]) === -1} />}

				{environment === 'sandbox' || environment === 'selection' ? <Column field="id" filter="text" title="Asset Tail Number" className="sandboxList" cell={AssetsColumns.AssetTailNumberCell} /> : <Column field="taiNumber" filter="text" title="Tail Number" cell={AssetsColumns.AssetTailNumberCell} />}

				<Column field="assetType" filter="text" title="Type" cell={AssetsColumns.AssetTypeCell} />
				<Column field="homeLocation" filter="text" title="Home ICAO" cell={AssetsColumns.AssetHomeLocationCell} />
				{environment === 'sandbox' && <Column field="assignedSquadron" filter="text" title="Assigned Squadron" className="sandboxList" cell={AssetsColumns.AssetAssignedSquadron} />}
				{environment !== 'selection' && environment !== 'sandbox' && <Column field="assignedSquadron" filter="text" title="Assigned Squadron" cell={AssetsColumns.AssetAssignedSquadron} />}
				{environment !== 'selection' && paramPage !== 'summary' && <Column field="id" title=" " sortable={false} filterable={false} cell={ActionsCell} />}
			</Grid>

			<AirportMapModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalContent={modalContent} />
		</React.Fragment>
	);
};

export default AssetAvailabilityList;
