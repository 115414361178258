/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Grid, GridColumn } from '@progress/kendo-react-grid';

import data from '../../../Assets/json/temp-missions.json';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';

const initialDataState = {
	skip: 0,
	take: 10
};

const AnalysisData = () => {
	const [page, setPage] = React.useState(initialDataState);
	const pageChange = (event) => {
		setPage(event.page);
	};

	// Select: Make Animated
	const animatedComponents = makeAnimated();

	// Select Options: Call Sign
	const optsCallSign = [
		{ value: 'cnv1234', label: 'CNV 1234' },
		{ value: 'cnv1303', label: 'CNV 1303' },
		{ value: 'cnv2345', label: 'CNV 2345' },
		{ value: 'cnv2541', label: 'CNV 2541' },
		{ value: 'cnv4457', label: 'CNV 4457' },
		{ value: 'cnv4567', label: 'CNV 4567' },
		{ value: 'cnv6897', label: 'CNV 6897' }
	];

	// Select Options: Flying Unit
	const optsFlyingUnit = [
		{ value: 'vr-51', label: 'VR-51' },
		{ value: 'vr-56', label: 'VR-56' },
		{ value: 'vr-57', label: 'VR-57' },
		{ value: 'vr-58', label: 'VR-58' },
		{ value: 'vr-59', label: 'VR-59' },
		{ value: 'vr-62', label: 'VR-62' },
		{ value: 'vr-64', label: 'VR-64' }
	];

	// Select Options: Air Craft Type
	const optsAirCraftType = [
		{ value: 'c40', label: 'C40' },
		{ value: 'c130t', label: 'C130T' }
	];

	const customFilters = () => {
		return (
			<div className={`datalist-filters`}>
				<div className={`row`}>
					<div className={`col-3`}>
						<div className={`form-group`}>
							<label htmlFor={`search`}>Search</label>
							<input type={`text`} id={`search`} placeholder={`Search by...`} />
						</div>
					</div>
					<div className={`col-3`}>
						<div className={`form-group`}>
							<label htmlFor={`callsign`}>Call Sign</label>
							<Select id={`callsign`} className={`custom-select`} options={optsCallSign} closeMenuOnSelect={false} components={animatedComponents} isMulti />
						</div>
					</div>
					<div className={`col-3`}>
						<div className={`form-group`}>
							<label htmlFor={`flyingunit`}>Flying Unit</label>
							<Select id={`flyingunit`} className={`custom-select`} options={optsFlyingUnit} closeMenuOnSelect={false} components={animatedComponents} isMulti />
						</div>
					</div>
					<div className={`col-3`}>
						<div className={`form-group`}>
							<label htmlFor={`actype`}>Air Craft Type</label>
							<Select id={`actype`} className={`custom-select`} options={optsAirCraftType} closeMenuOnSelect={false} components={animatedComponents} isMulti />
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			{customFilters()}

			<Grid style={{}} data={data.slice(page.skip, page.take + page.skip)} skip={page.skip} take={page.take} total={data.length} pageable={true} onPageChange={pageChange}>
				<GridColumn field="missionid" title="Mission ID" />
				<Column
					field="status"
					title="Status"
					cell={(props) => (
						<td className={'mission-cell-status'}>
							<span className={`pill ${props.dataItem[props.field].toLowerCase()}`}>XXXXXXXXX</span>
						</td>
					)}
				/>
				<GridColumn field="callsign" title="Call Sign" />
				<GridColumn field="flyingunit" title="Flying Unit" />
				<GridColumn field="actype" title="A/C Type" />
				<GridColumn field="depicao" title="Dep ICAO" />
				<GridColumn field="deptimedatez" title="Departure Time/Date (Z)" />
				<GridColumn field="arricao" title="Arr ICAO" />
				<GridColumn field="arrtimedatez" title="Arrival Time/Date (Z)" />
				<GridColumn field="scheduledcaro" title="Scheduled Cargo" />
				<GridColumn field="availablecargo" title="Available Cargo" />
			</Grid>
		</div>
	);
};

export default AnalysisData;
