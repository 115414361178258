/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';

import Layout from '../Core/Layout';

const AuthLoader = (props) => {
	return (
		<Layout apptheme={'theme-authloader'} userrole={'no-role'} className={'authloader'}>
			<div className={'authloader-wrapper'}>
				<div className={'authloader-wrapper-inner'}>
					<p>Loading</p>
				</div>
			</div>
		</Layout>
	);
};

export default AuthLoader;
