/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';

/**
 * Accordion
 * Example Usage:
 * <Accordion>
 *    <AccordionHeading>
 *         <div className="heading-box">
 *             <h4 className="heading">Q?</h4>
 *         </div>
 *    </AccordionHeading>
 *    <AccordionContent>
 *        ...
 *    </AccordionContent>
 * </Accordion>
 */
class Accordion extends React.Component {
	state = {
		active: -1,
		heading: -1
	};

	onShow = (i) => {
		this.setState({
			active: i,
			heading: i
		});

		if (this.state.heading === i) {
			this.setState({
				active: -1,
				heading: -1
			});
		}
	};

	kickOff = () => {
		this.setState({
			active: -1
		});
	};

	render() {
		const children = React.Children.map(this.props.children, (child, i) => {
			return React.cloneElement(child, {
				heading: this.state.active === i,
				text: this.state.active + 1 === i,
				onShow: () => {
					this.onShow(i);
				}
			});
		});

		return children;
	}
}

class AccordionHeading extends React.Component {
	render() {
		const { heading, onShow, children, enter } = this.props;

		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					...this.props.style
				}}
				onClick={onShow}
				className={enter ? (heading ? enter.active : enter.normal) : `accordion-heading ${heading ? 'open' : ''}`}>
				{children}

				{this.props.render && this.props.render(heading)}
			</div>
		);
	}
}

class AccordionContent extends React.Component {
	render() {
		const { enter, text, style, children, render } = this.props;

		return (
			<div style={style} className={enter ? (text ? enter.active : enter.normal) : `accordion-content ${text ? 'open' : ''}`}>
				{text ? children : ''}
				{text ? render && render(this.props.text) : ''}
			</div>
		);
	}
}

export { Accordion, AccordionHeading, AccordionContent };
