/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

// global google

import React, { useEffect } from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import { Col, Container, Row, Button } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import { GoogleMap, OverlayView, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { ApiMissionListByState, ApiMissionByIdHelper, ApiLegsById } from '../../Helpers/Apis';
import Marker from '../../Maps/Marker';

//******************* Gantt Chart: Start
// const ganttStyle = {
//     width: "100%",
//     minHeight: "500px",
//     height: "100%" // 850px
// };

// const taskModelFields = {
//     id: "id",
//     arrival_airport: "arrival_airport",
//     departure_airport: "departure_airport",
//     start: "start",
//     end: "end",
//     title: "title",
// };

//Start to Start/ Finish to Finish stuff. Will leave for now
// const dependencyModelFields = {
//     id: "id",
//     fromId: "fromId",
//     toId: "toId",
//     type: "type",
// };

// const getTaskId = getter(taskModelFields.id);

// const columns = [
//     {
//         field: taskModelFields.start,
//         title: "Start",
//         width: 180,
//         format: "{0:MM/dd/yyyy}",
//         columnMenu: ColumnMenuDateColumn
//     },
//     {
//         field: taskModelFields.end,
//         title: "End",
//         width: 180,
//         format: "{0:MM/dd/yyyy}",
//         columnMenu: ColumnMenuDateColumn
//     },
// ];
//******************* Gantt Chart: End

const ScheduleMap = (props) => {
	const params = useParams();
	const [missionsList, setMissionsList] = React.useState([]);

	const [missionName, setMissionName] = React.useState('');
	const missionId = params.missionId ? params.missionId : '';

	const [windowView, setWindowView] = React.useState('minimize'); // maximize, minimize toggle
	const [missionList, setMissionList] = React.useState(true);

	// Map
	const [latitudeAvg, setLatitudeAvg] = React.useState(32.8481);
	const [longitudeAvg, setLongitudeAvg] = React.useState(-96.8512);
	const [polylineData, setPolylineData] = React.useState([]); // Polyline for Each Mission
	const [markerData, setMarkerData] = React.useState([]); // Marker Data for Each Mission

	const [libraries] = React.useState(['geometry', 'drawing']);

	// eslint-disable-next-line
	const { isGoogleLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
		libraries
	});

	// Gantt Chart: Start
	const [legData, setLegData] = React.useState([]);

	//******************* Map: Start
	// Map Options
	const mapOptions = {
		// Map Styling
		style: {
			width: '100%',
			height: '100%'
		},

		// New Zealand/Australia: Default Map Center Point on Load
		center: {
			lat: latitudeAvg,
			lng: longitudeAvg
		},

		// Default Zoom Level
		zoom: 3,

		options: {
			maxZoom: 18,
			minZoom: 3
		}
	};

	// Options: Polyline
	const polylineOptions = {
		geodesic: true,
		strokeColor: '#206a2e',
		strokeOpacity: 0.7,
		strokeWeight: 3,
		fillColor: '#206a2e',
		fillOpacity: 0.35,
		clickable: true,
		draggable: false,
		editable: false,
		visible: true,
		radius: 30000,
		paths: [],
		zIndex: 3
	};

	// Define refs for Polygon instance and listeners
	// const polygonRef = useRef(null);
	// const listenersRef = useRef([]);

	const handlePolyline = () => {
		const p = [];

		legData.map((rec, i) => {
			p.push({
				lat: rec.departure_airport_lat,
				lng: rec.departure_airport_long
			});
			return p.push({
				lat: rec.arrival_airport_lat,
				lng: rec.arrival_airport_long
			});
		});

		//console.log(p);

		setPolylineData(p);
	};

	const handleMarker = () => {
		const m = [];
		let lats = [];
		let lons = [];

		legData.map((rec, i) => {
			lats.push(rec.departure_airport_lat);
			lats.push(rec.arrival_airport_lat);
			lons.push(rec.departure_airport_long);
			lons.push(rec.arrival_airport_long);

			m.push({
				name: rec.departure_airport + ' to ' + rec.arrival_airport,
				lat: rec.departure_airport_lat,
				lng: rec.departure_airport_long
			});
			return m.push({
				name: rec.departure_airport + ' to ' + rec.arrival_airport,
				lat: rec.arrival_airport_lat,
				lng: rec.arrival_airport_long
			});
		});

		const getAvgLats = lats.length > 0 ? lats.reduce((a, b) => a + b) / lats.length : 32.8481;
		const getAvgLons = lons.length > 0 ? lons.reduce((a, b) => a + b) / lons.length : -96.8512;

		setLatitudeAvg(getAvgLats);
		setLongitudeAvg(getAvgLons);

		setMarkerData(m);
	};

	//******************* Map: End

	const navigate = useNavigate();

	const missionDataFetch = () => {
		ApiMissionByIdHelper(missionId).then((res) => {
			setMissionName(res.missionName);
		});
	};

	const legsDataFetch = () => {
		if (missionId) {
			ApiLegsById(missionId).then((res) => {
				const data = JSON.stringify(res);
				const obj = JSON.parse(data);

				const jsonArray = [];
				var i = 0;
				while (i < res.length && obj[i].history[0].departure !== null && obj[i].history[0].arrival !== null) {
					var from = obj[i].history[0].departure.airport.icao;
					var to = obj[i].history[0].arrival.airport.icao;
					var titleString = from + ' to ' + to;
					var jsonObject = {
						id: obj[i].id,
						start: new Date(obj[i].history[0].departure.planned),
						end: new Date(obj[i].history[0].arrival.planned),
						arrival_airport: obj[i].history[0].arrival.airport.icao,
						arrival_airport_name: obj[i].history[0].arrival.airport.name,
						arrival_airport_lat: obj[i].history[0].arrival.airport.latitude,
						arrival_airport_long: obj[i].history[0].arrival.airport.longitude,
						departure_airport: obj[i].history[0].departure.airport.icao,
						departure_airport_name: obj[i].history[0].departure.airport.name,
						departure_airport_lat: obj[i].history[0].departure.airport.latitude,
						departure_airport_long: obj[i].history[0].departure.airport.longitude,
						satisfies_lift_requests: obj[i].history[0].satisfiesLiftRequests,
						title: titleString
					};
					jsonArray.push(jsonObject);
					i++;
				}

				setLegData(jsonArray);
			});
		}
	};

	const formDependencyData = () => {
		var dependencyArray = [];
		var i = 0;
		while (i < legData.length) {
			if (legData[i] && legData[i + 1]) {
				var newDependencyData = {
					id: i + 1,
					fromId: legData[i].id,
					toId: legData[i + 1].id,
					type: 1
				};
				dependencyArray.push(newDependencyData);
			}
			i++;
		}
	};

	const missionsListDataFetch = () => {
		const queryparams = '?state=GENERATED&state=DEPARTED';
		ApiMissionListByState(queryparams).then((res) => {
			setMissionsList(res);
		});
	};

	useEffect(() => {
		missionsListDataFetch();
		missionsNavigationFetch();
		missionDataFetch();
	}, []);

	useEffect(() => {
		legsDataFetch();
		missionDataFetch();
	}, [navigate]);

	useEffect(() => {
		formDependencyData();
		handlePolyline();
		handleMarker();
	}, [legData]);

	const missionsNavigationFetch = () => {
		missionsList.map((rec, i) => {
			const mId = rec.id;

			ApiLegsById(mId).then((res) => {
				const jsonArray = [];
				var i = 0;
				while (i < res.length && res[i].history[0].departure !== null && res[i].history[0].arrival !== null) {
					var from = res[i].history[0].departure.airport.icao;
					var to = res[i].history[0].arrival.airport.icao;
					var titleString = from + ' to ' + to;
					var jsonObject = {
						id: res[i].id,
						start: new Date(res[i].history[0].departure.planned),
						end: new Date(res[i].history[0].arrival.planned),
						arrival_airport: res[i].history[0].arrival.airport.icao,
						arrival_airport_lat: res[i].history[0].arrival.airport.latitude,
						arrival_airport_long: res[i].history[0].arrival.airport.longitude,
						departure_airport: res[i].history[0].departure.airport.icao,
						departure_airport_lat: res[i].history[0].departure.airport.latitude,
						departure_airport_long: res[i].history[0].departure.airport.longitude,
						title: titleString
					};
					jsonArray.push(jsonObject);
					i++;
				}
			});
			return null;
		});
	};

	// Mission Sidebar Selection List
	const missionsNavigation = () => {
		return (
			<div className={'mission-schedules-list'}>
				<div className={'mission-schedules-toggle'}>
					<Navbar bg="light" expand="lg">
						<Button
							className={`mission-schedules-list-button btn-list-missions-view ${missionList ? 'show-list' : 'hide-list'}`}
							title={`Click to ${missionList ? 'Hide' : 'Show'} Mission List`}
							onClick={() => {
								setMissionList(!missionList);
							}}>
							<i className={'fa fa-bars'}></i>
							<span>{`${missionList ? 'Hide' : 'Show'}`} Mission List</span>
						</Button>
					</Navbar>
				</div>

				<div className={'mission-schedules-list-inner'}>
					<ul>
						{missionsList.map((rec, i) => {
							const missionName = rec.missionName === null ? rec.id : rec.missionName;
							const mId = rec.id;
							const createdAt = moment(rec.history[0].createdAt).tz('America/New_York').format('YYYY-MM-DD');
							const timeframe = {
								__html: '<strong>' + missionName + '</strong><span class="daterange">Created on ' + createdAt + '</span>'
							};

							return (
								<li key={i}>
									<NavLink to={`/schedule/map/${mId}`} title={`View Mission Map for ${mId}`}>
										<span dangerouslySetInnerHTML={timeframe} />
									</NavLink>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};

	// Header Filters
	const headerFilterList = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						{/* Print and export button placeholders to be implemented in ALO-549 */}
						{/* <div className={"nav-link-group"}>
                            <Nav.Link href="#print" className={"icon-only"}>
                                <i className={"fa fa-print"}></i>
                                <span className={"Print"}></span>
                            </Nav.Link>
                            <Nav.Link href="#export" className={"icon-only"}>
                                <i className={"fa fa-file-export"}></i>
                                <span className={"Export"}></span>
                            </Nav.Link>
                        </div> */}

						<div className={'nav-link-group'}>
							<NavLink to={`${missionId ? '/schedule/map/' + missionId : '/schedule/map'}`} className={'nav-link btn-map-view icon-only active'} title={'View Map of Selected Mission'}>
								<i className={'fa fa-globe'}></i>
								<span className={'Click for Map View'}></span>
							</NavLink>
							<NavLink to={`${missionId ? '/missionstatus/' + missionId : '/missionstatus'}`} className={'nav-link btn-missionstatus-view icon-only'} title={'View Mission Status for Selected Mission'}>
								<i className={'fa fa-plane-departure'}></i>
								<span className={'Click for Mission Status'}></span>
							</NavLink>
							<NavLink to={`${missionId ? '/schedule/legs/' + missionId : '/schedule/legs'}`} className={'nav-link btn-legs-view icon-only'} title={'View Legs of Selected Mission'}>
								<i className={'fa fa-table-rows'}></i>
								<span className={'Click for Mission Legs View'}></span>
							</NavLink>
							<NavLink to={`${missionId ? '/radarbox/map/' + missionId : '/radarbox/map'}`} className={'nav-link btn-map-view icon-only'} title={'View RadarBox AirNav Map'}>
								<i className={'fa-solid fa-radar'}></i>
								<span className={'sr-only'}>Click for RadarBox AirNav Map</span>
							</NavLink>
						</div>

						<div className={'nav-link-group'}>
							{windowView === 'minimize' ? (
								<Nav.Link
									href="#maximize"
									className={'btn-maximize-view icon-only'}
									onClick={() => {
										setWindowView('maximize');
									}}>
									<i className={'fa fa-arrows-maximize'}></i>
									<span className={'Full Screen'}></span>
								</Nav.Link>
							) : (
								<Nav.Link
									href="#minimize"
									className={'btn-minimize-view icon-only'}
									onClick={() => {
										setWindowView('minimize');
									}}>
									<i className={'fa fa-arrows-minimize'}></i>
									<span className={'Exit Full Screen'}></span>
								</Nav.Link>
							)}
						</div>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	return (
		<Container fluid className={`app-content ${windowView}`}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2>{missionId.length ? `Mission Schedule for ${missionName}` : 'Mission Schedules'}</h2>

							{headerFilterList()}
						</div>

						<div className={`mission-schedules ${missionList ? 'sidebar-expanded' : 'sidebar-collapsed'}`}>
							<div className={'mission-schedules-inner'}>
								{missionsNavigation()}

								{missionId.length ? (
									<React.Fragment>
										<div id={'schedule-map-view'} className={'mission-schedule-output map-view mission-selected'}>
											<div className={'mission-schedule-inner'}>
												<div className={'schedule-section-header'}>
													<h4 className={'block-section-header'}>Mission Map View</h4>
												</div>
												<div className={'schedule-list schedule-map'}>
													<div className={'schedule-list-inner'}>
														<div className={'schedule-map-visual'}>
															<GoogleMap mapContainerStyle={mapOptions.style} center={mapOptions.center} zoom={mapOptions.zoom} options={mapOptions.options}>
																{markerData.map((rec, i) => (
																	<OverlayView key={i} position={{ lat: rec.lat, lng: rec.lng }} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
																		<Marker
																			position={{
																				lat: rec.lat,
																				lng: rec.lng
																			}}
																			type={'dot'}
																			name={rec.name}
																			lat={rec.lat}
																			lng={rec.lng}
																		/>
																	</OverlayView>
																))}

																<Polyline path={polylineData} options={polylineOptions} />
															</GoogleMap>
														</div>
													</div>
												</div>
											</div>
										</div>
									</React.Fragment>
								) : (
									<div id={'schedule-no-view'} className={'mission-schedule-output map-view no-mission no-mission-selected'}>
										<div className={'mission-schedule-inner'}>
											<div className={'schedule-section-header'}>
												<h4 className={'block-section-header'}>Mission Map View</h4>
											</div>
											<div className={'schedule-list schedule-map'}>
												<div className={'schedule-list-inner'}>
													<h3>Please select a mission</h3>

													<div className={'schedule-map-visual'}>
														<GoogleMap mapContainerStyle={mapOptions.style} center={mapOptions.center} zoom={mapOptions.zoom} options={mapOptions.options}></GoogleMap>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};
export default ScheduleMap;
