/*
 * Copyright 2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */
import React, { useEffect } from 'react';
import Footer from '../../../Core/Footer';
import Panel from '../../../Core/Panel';

import { Col, Container, Row } from 'react-bootstrap';

import Loader from '../../../Core/Loader';

import { NavLink, useParams } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { toast } from 'react-toastify';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { CapitalizeString } from '../../../Helpers/ContentFormatting';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import { useNavigate } from 'react-router-dom';

import { SubmitDialog } from '../../../Helpers/FormHelpers/dialogHelpers/solverSubmissionDialog';
import MultiMissionGanttChart from '../../../App/MultiMissionView/MultiMissionGanttChart';
import LiftRequestListGrid from '../../../App/LiftRequestListGrid/LiftRequestListGrid';

import { ApiChangeSandboxState, ApiSandboxById, ApiLiftRequestList, ApiMissionGenRequest, ApiMissionListByState, ApiLegsById, ApiSimulationByMissionId } from '../../../Helpers/Apis';

const dateNow = new Date(Date.now());
//set the latest solver date to the current date + 2 weeks
const dateLater = new Date(Date.now() + 12096e5);

const SandboxResults = (props) => {
	const [visible, setVisible] = React.useState(false);
	const [loaded, setLoaded] = React.useState(false);
	const [dialogState, setDialogState] = React.useState('');
	const [submitVisible, setSubmitVisible] = React.useState(false);
	const [sandboxDetails, setSandboxDetails] = React.useState([]);
	const [solverStartDateValue, setSolverStartDateValue] = React.useState(dateNow);
	const [solverEndDateValue, setSolverEndDateValue] = React.useState(dateLater);
	const [solverSpeed, setSolverSpeed] = React.useState('Fast');
	const [solverTime, setSolverTime] = React.useState(250);
	const [visibleMissionReqId, setVisibleMissionReqId] = React.useState('');
	const [arrayOfMissionReqId, setArrayofMissionReqId] = React.useState([]);
	const [selectedVersion, setSelectedVersion] = React.useState(1);
	const [totalSandboxLiftRequests, setTotalSandboxLiftRequests] = React.useState([]);
	const [versionArray, setVersionArray] = React.useState([]);
	const [displayedMissions, setDisplayedMissions] = React.useState([]);
	const [formKey, setFormKey] = React.useState(new Date());

	const params = useParams();

	const navigate = useNavigate();

	const solverScheduleObj = {
		optimization_timestamp: {
			start_time: solverStartDateValue,
			end_time: solverEndDateValue
		},
		solver_configuration: {
			solver_speed: solverSpeed.toUpperCase(),
			time_limit: solverTime
		},
		createdBy: {
			id: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.id ? props.keycloak.profile.id : null,
			firstName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.firstName ? props.keycloak.profile.firstName : null,
			lastName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.lastName ? props.keycloak.profile.lastName : null,
			email: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.email ? props.keycloak.profile.email : null,
			phoneNumber: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.phoneNumber ? props.keycloak.profile.phoneNumber : null,
			rank: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.rank ? props.keycloak.profile.rank : null,
			dutyTitle: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.dutyTitle ? props.keycloak.profile.dutyTitle : null,
			unit: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.unit ? props.keycloak.profile.unit : null,
			branchOfService: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.branchOfService ? props.keycloak.profile.branchOfService : null
		}
	};

	const data = {
		id: sandboxDetails.id ? sandboxDetails.id : '',
		latestVersion: sandboxDetails.latestVersion ? sandboxDetails.latestVersion : '1',
		latestOwner: {
			id: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.id : null,
			firstName: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.firstName : null,
			lastName: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.lastName : null,
			email: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.email : null,
			phoneNumber: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.phoneNumber : null,
			rank: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.rank : null,
			dutyTitle: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.dutyTitle : null,
			unit: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.unit : null
		},
		history: sandboxDetails.history ? sandboxDetails.history : []
	};

	const sandboxTitle = data.history[0] ? data.history[0].name : params.sandboxId;

	const handleSolverDateChange = (event) => {
		setSolverStartDateValue(event.value.start);
		setSolverEndDateValue(event.value.end);
	};

	const handleSolverSpeedChange = (event) => {
		setSolverSpeed(event.value);
	};

	const handleSolverTimeChange = (event) => {
		setSolverTime(event.value);
	};

	const submitForScheduling = () => {
		toggleSubmitDialog();
		let sandboxIdParamString = `?sandboxId=${params.sandboxId}`;
		ApiMissionGenRequest(solverScheduleObj, sandboxIdParamString);
		toast.success('Successfully Submitted Sandbox Details to the Air Logistics Solver for Scheduling');
		navigate('/sandbox/dashboard');
	};

	const toggleSubmitDialog = () => {
		setSubmitVisible(!submitVisible);
	};

	const openSubmitDialog = () => {
		toggleSubmitDialog();
	};

	const DialogWindow = (id, state) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleDialog}>
				<div data-id={id} data-state-to={state}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to delete this sandbox?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							stateUpdate(id, state);
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const stateUpdate = (id, state) => {
		ApiChangeSandboxState(id, 'CANCELLED').then((res) => {
			toast.success('Successfully Changed the Sandbox Status to ' + CapitalizeString(state.toLowerCase()));
			navigate('/sandbox/dashboard');
		});

		setDialogState('');
		dataFetch();
	};

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<NavLink
							className={'nav-link'}
							role={'button'}
							to={`#`}
							title={'Click to Reprocess Sandbox'}
							onClick={() => {
								openSubmitDialog();
							}}>
							<i className="fa-solid fa-bars-progress"></i>
							Reprocess Sandbox
						</NavLink>

						{submitVisible && <SubmitDialog environment={'sandbox'} visible={submitVisible} toggleDialog={toggleSubmitDialog} handleSolverDateChange={handleSolverDateChange} handleSolverTimeChange={handleSolverTimeChange} handleSolverSpeedChange={handleSolverSpeedChange} submitForScheduling={submitForScheduling} />}

						<NavLink
							className={'nav-link'}
							role={'button'}
							to={`#`}
							title={`Click to Delete Sandbox`}
							onClick={() => {
								openDialog(params.sandboxId, 'CANCELLED', props.token);
							}}>
							<i className="fa-solid fa-trash-can"></i>
							Delete Sandbox
							{visible && dialogState === 'CANCELLED' && DialogWindow(params.sandboxId, 'CANCELLED')}
						</NavLink>

						<NavLink className={'nav-link'} role={'button'} to={`/sandbox/view/${params.sandboxId}`} title={'View Sandbox'}>
							<i className="fa-solid fa-eye"></i>
							View Sandbox
						</NavLink>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const dataFetch = () => {
		ApiSandboxById(params.sandboxId).then((res) => {
			setSandboxDetails(res);
		});

		let sandboxIdParamString = `?sandboxId=${params.sandboxId}`;
		let initialMissionReq = '';
		let satisfiedLiftRequestsArr = [];
		let arrayOfMissions = [];
		let tempVersionArray = [];
		let tempMissionReqIdArray = [];
		setDisplayedMissions([]);

		ApiLiftRequestList(sandboxIdParamString).then((res) => {
			setTotalSandboxLiftRequests([...res]);

			ApiMissionListByState(sandboxIdParamString).then((solverResponse) => {
				if (solverResponse.length > 0) {
					//if length is greater than 1, then order array by history[0].createdAt
					if (solverResponse.length > 1) {
						const orderedMissionsMostRecentFirst = []
							.concat(solverResponse)
							//sort by most recent mission
							.sort((a, b) => (a.history[0].createdAt < b.history[0].createdAt ? 1 : -1));

						//  eslint-disable-next-line
						orderedMissionsMostRecentFirst.map((item, i) => {
							//set default visible missionResponse to most recent
							if (i === 0) {
								setVisibleMissionReqId(item.missionsRequestId);
								initialMissionReq = item.missionsRequestId;
							}
							if (!tempMissionReqIdArray.includes(item.missionsRequestId)) {
								tempMissionReqIdArray.push(item.missionsRequestId);
								tempVersionArray.push(tempVersionArray.length + 1);
							}
						});
					} else {
						initialMissionReq = solverResponse[0].missionsRequestId;
						setVersionArray([1]);
					}

					if (initialMissionReq !== '') {
						let queryParam = `?missionsRequestId=${initialMissionReq}`;

						ApiMissionListByState(queryParam).then((solverResponse) => {
							//map through missions returned by mission by request id and build array of all lift requests satisfied in these missions
							//  eslint-disable-next-line
							solverResponse.map((mission) => {
								ApiSimulationByMissionId(mission.id).then((res) => {
									mission.simulationData = res;
								});

								arrayOfMissions.push(mission);

								ApiLegsById(mission.id).then((legResponse) => {
									//  eslint-disable-next-line
									legResponse.map((leg, i) => {
										if (leg.history[0].satisfiesLiftRequests.length > 0) {
											//  eslint-disable-next-line
											leg.history[0].satisfiesLiftRequests.map((liftRequestId) => {
												satisfiedLiftRequestsArr.push(liftRequestId);
											});
										}
									});

									//  eslint-disable-next-line
									res.map((sandboxLiftRequest) => {
										if (satisfiedLiftRequestsArr.includes(sandboxLiftRequest.id)) {
											sandboxLiftRequest.satisfied = true;
											setTotalSandboxLiftRequests([...res]);
										} else {
											sandboxLiftRequest.satisfied = false;
											setTotalSandboxLiftRequests([...res]);
										}
									});
								});
							});
						});
					}
				}
			});
		});

		setArrayofMissionReqId(tempMissionReqIdArray);
		setDisplayedMissions(arrayOfMissions);
		setVersionArray(tempVersionArray);
		setFormKey(new Date());
	};

	const openDialog = (id, state) => {
		toggleDialog();
		setDialogState(state);
	};

	useEffect(() => {
		dataFetch();
	}, [props]);

	const onMissionReqChange = () => {
		if (visibleMissionReqId !== '') {
			setLoaded(false);
			setDisplayedMissions([]);

			let satisfiedLiftRequestsArr = [];
			let arrayOfMissions = [];
			let totalInitialLiftRequests = [...totalSandboxLiftRequests];
			let queryParam = `?missionsRequestId=${visibleMissionReqId}`;
			let emptyArray = [];

			setDisplayedMissions([...emptyArray]);
			setTotalSandboxLiftRequests([...emptyArray]);

			if (versionArray.length > 0) {
				ApiMissionListByState(queryParam).then((solverResponse) => {
					//map through missions returned by mission by request id and build array of all lift requests satisfied in these missions
					//  eslint-disable-next-line
					solverResponse.map((mission) => {
						ApiSimulationByMissionId(mission.id).then((res) => {
							mission.simulationData = res;
						});

						arrayOfMissions.push(mission);

						ApiLegsById(mission.id).then((legResponse) => {
							//  eslint-disable-next-line
							legResponse.map((leg, i) => {
								if (leg.history[0].satisfiesLiftRequests.length > 0) {
									//  eslint-disable-next-line
									leg.history[0].satisfiesLiftRequests.map((liftRequestId) => {
										satisfiedLiftRequestsArr.push(liftRequestId);
									});

									//  eslint-disable-next-line
									totalInitialLiftRequests.map((sandboxLiftRequest) => {
										if (satisfiedLiftRequestsArr.includes(sandboxLiftRequest.id)) {
											sandboxLiftRequest.satisfied = true;
										} else {
											sandboxLiftRequest.satisfied = false;
										}
									});
								}
							});
						});
					});
				});
			}

			setDisplayedMissions(arrayOfMissions);
			setTotalSandboxLiftRequests(totalInitialLiftRequests);
			setFormKey(new Date());
		}
	};

	useEffect(() => {
		setLoaded(false);
		let sandboxIdParamString = `?sandboxId=${params.sandboxId}`;

		ApiLiftRequestList(sandboxIdParamString).then((res) => {
			if (res.length === totalSandboxLiftRequests.length) {
				setTimeout(() => {
					setLoaded(true);
					setFormKey(new Date());
				}, 300 * res.length);
			}
			// eslint-disable-next-line
			totalSandboxLiftRequests.map((sandboxLr) => {
				if (sandboxLr.satisfied !== true && sandboxLr.satisfied !== false) {
					setLoaded(false);
				}
			});
		});
	}, [totalSandboxLiftRequests]);

	return (
		<Container key={formKey} fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2 className={'padding-top-0 padding-bottom-0'}>{`Results for Sandbox "${sandboxTitle}"`}</h2>
							{PageTitleControls()}
						</div>

						{loaded ? (
							<React.Fragment>
								<div className={'lift-request-meta-data'}>
									<ul>
										<li>Number of Versions: {String(versionArray.length)}</li>
									</ul>
								</div>

								<Row id={'sandbox-name-information'} className={'form-section row'}>
									<div className={'col-3 padding-top-10'}>
										<Label>Select Results Version for this Sandbox</Label>
										<DropDownList
											style={{
												width: '300px'
											}}
											data={versionArray}
											defaultValue={versionArray.length > 0 ? selectedVersion : '0'}
											onChange={(e) => {
												setSelectedVersion(e.value);
												setVisibleMissionReqId(arrayOfMissionReqId[e.value - 1]);
												onMissionReqChange();
											}}
										/>
									</div>
								</Row>

								<Row id={'sandbox-details-list'} className={'form-section row'}>
									<div className={'col-4'}>
										<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
											<strong>Selected Lift Requests</strong>
										</h4>
									</div>
								</Row>

								<div className={'padding-bottom-40'}>
									<LiftRequestListGrid environment={'sandbox'} page={'results'} data={totalSandboxLiftRequests} />
								</div>

								<Row id={'sandbox-details-list'} className={'form-section row'}>
									<div className={'col-4'}>
										<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
											<strong>Multi-mission View</strong>
										</h4>
									</div>
								</Row>

								<MultiMissionGanttChart queryparams={'?state=GENERATED'} environment={'sandbox'} data={displayedMissions} listTitle={'Missions'} />
							</React.Fragment>
						) : (
							<Loader />
						)}
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

export default SandboxResults;
