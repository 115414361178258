/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Col, Row } from 'react-bootstrap';
import Panel from '../../../Core/Panel';

// import LiftRequestListGrid from '../../../App/LiftRequestListGrid/LiftRequestListGrid';
import LiftRequestListTabs from '../../../App/LiftRequestListGrid/LiftRequestListTabs';
import AirportsListGrid from '../../../App/AirportsListGrid/AirportsListGrid';
import AssetAvailabilityList from '../../../App/AssetAvailability/AssetAvailabilityList';

export const SandboxSelectionDialog = (props) => {
	const { cancelEdit, onSubmit } = props;
	const buttonString = props.buttonString;

	if (process.env.REACT_APP_DEBUG === 'true') {
		console.log(props.lrIdArray);
		console.log(props.setPotentialLrIdArray);
	}

	return (
		<React.Fragment>
			<div className={'dialog-form'}>
				<Dialog title={`Select ${buttonString}`} onClose={cancelEdit} className={'dialog-sandbox-itinerary-form'} height={860}>
					<Row>
						<Col>
							<Panel>
								{buttonString === 'Lift Requests' && <LiftRequestListTabs environment={'selection'} lrIdArray={props.lrIdArray} setPotentialLrIdArray={props.setPotentialLrIdArray} />}

								{buttonString === 'Assets' && <AssetAvailabilityList environment={'selection'} assetIdArray={props.assetIdArray} setPotentialAssetIdArray={props.setPotentialAssetIdArray} />}

								{buttonString === 'Airports' && <AirportsListGrid environment={'selection'} airportIdArray={props.airportIdArray} setPotentialAirportIdArray={props.setPotentialAirportIdArray} />}
							</Panel>
						</Col>
					</Row>

					<DialogActionsBar layout="end">
						<div className={'padding-top-10'}>
							<Button title={'Click to Cancel Item Selection Changes'} className={'btn btn-primary btn-size-medium btn-color-red btn-icon-remove'} onClick={() => cancelEdit(buttonString)}>
								Cancel
							</Button>
							<Button type={'submit'} title={'Click to Confirm Item Selection Changes'} className={'btn btn-size-medium btn-primary btn-color-green btn-icon-submit'} onClick={onSubmit}>
								{`Add Selected ${buttonString}`}
							</Button>
						</div>
					</DialogActionsBar>
				</Dialog>
			</div>
		</React.Fragment>
	);
};
