/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { NavLink, useParams } from 'react-router-dom';
import { PDFExport } from '@progress/kendo-react-pdf';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { CapitalizeString } from '../../../Helpers/ContentFormatting';
import { toast } from 'react-toastify';

import Footer from '../../../Core/Footer';
import Panel from '../../../Core/Panel';
import Loader from '../../../Core/Loader';

import LiftRequestListGrid from '../../../App/LiftRequestListGrid/LiftRequestListGrid';
import AirportsListGrid from '../../../App/AirportsListGrid/AirportsListGrid';
import AssetAvailabilityList from '../../../App/AssetAvailability/AssetAvailabilityList';

import { ApiChangeSandboxState, ApiSandboxById, ApiLiftRequestList, ApiAssetsList, ApiAirportsList } from '../../../Helpers/Apis';

const SandboxView = (props) => {
	const [sandboxDetails, setSandboxDetails] = React.useState([]);
	const [loaded, setLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [dialogState, setDialogState] = React.useState('');
	const [lifts, setLifts] = React.useState([]);
	const [assets, setAssets] = React.useState([]);
	const [airports, setAirports] = React.useState([]);
	const params = useParams();
	const pdfExportComponent = React.useRef(null);

	const data = {
		id: sandboxDetails.id ? sandboxDetails.id : '',
		latestVersion: sandboxDetails.latestVersion ? sandboxDetails.latestVersion : '1',
		latestOwner: {
			id: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.id : null,
			firstName: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.firstName : null,
			lastName: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.lastName : null,
			email: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.email : null,
			phoneNumber: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.phoneNumber : null,
			rank: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.rank : null,
			dutyTitle: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.dutyTitle : null,
			unit: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.unit : null
		},
		history: sandboxDetails.history ? sandboxDetails.history : []
	};

	const sandboxTitle = data.history[0] ? data.history[0].name : params.sandboxId;

	const dataFetch = () => {
		ApiSandboxById(params.sandboxId).then((res) => {
			setSandboxDetails(res);
		});

		let sandboxIdParamString = `?sandboxId=${params.sandboxId}`;

		ApiLiftRequestList(sandboxIdParamString).then((res) => {
			setLifts(res);
		});

		ApiAssetsList(sandboxIdParamString).then((res) => {
			setAssets(res);
		});

		ApiAirportsList(sandboxIdParamString).then((res) => {
			setAirports(res);
		});
	};

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<NavLink
							className={'nav-link'}
							role={'button'}
							to={`#`}
							title={`Click to Delete Sandbox`}
							onClick={() => {
								openDialog(params.sandboxId, 'CANCELLED', props.token);
							}}>
							<i className="fa-solid fa-trash-can"></i>
							Delete Sandbox
							{visible && dialogState === 'CANCELLED' && DialogWindow(params.sandboxId, 'CANCELLED')}
						</NavLink>

						<NavLink className={'nav-link'} role={'button'} to={`/sandbox/edit/${params.sandboxId}`} title={'Edit Sandbox'}>
							<i className="fa fa-edit"></i>
							Edit Sandbox
						</NavLink>

						<Nav.Link
							to={'#export'}
							onClick={() => {
								if (pdfExportComponent.current) {
									pdfExportComponent.current.save();
								}
							}}>
							<i className={'fa-solid fa-file-pdf'}></i>
							Export
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	const stateUpdate = (id, state) => {
		ApiChangeSandboxState(id, 'CANCELLED').then((res) => {
			toast.success('Successfully Changed the Sandbox Status to ' + CapitalizeString(state.toLowerCase()));
		});

		setDialogState('');

		setTimeout(() => {
			dataFetch();
		}, 300);
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const openDialog = (id, state) => {
		toggleDialog();
		setDialogState(state);
	};

	const DialogWindow = (id, state) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleDialog}>
				<div data-id={id} data-state-to={state}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to delete this sandbox?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							stateUpdate(id, state);
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	useEffect(() => {
		dataFetch();
		setTimeout(() => {
			setLoaded(true);
		}, 500);
	}, []);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						{loaded ? (
							<PDFExport paperSize="A4" margin="1cm" ref={pdfExportComponent}>
								<div className={'page-title'}>
									<h2>Sandbox: {sandboxTitle}</h2>
									{PageTitleControls()}
								</div>

								<div className={'lift-request-meta-data'}>
									<ul>
										<li>
											Status:&nbsp;
											{data.history[0] && data.history[0].state ? <span className={`pill ${data.history[0].state.toLowerCase()} lift-status-${data.history[0].state.toLowerCase()}`}>{data.history[0].state}</span> : <span className={`pill`}>Not Available</span>}
										</li>
										<li>Version of Sandbox: {String(data.history.length)}</li>
										<li>Read Only</li>
									</ul>
								</div>

								<Row id={'sandbox-name-information'} className={'form-section row'}>
									<div className={'col-3'}>
										<label>Sandbox Name</label>
										<div className={'static-field-read-only static-text'}>{data && data.history && data.history[0] && data.history[0].name ? data.history[0].name : ''}</div>
									</div>
								</Row>

								<Row id={'sandbox-details-list'} className={'form-section row'}>
									<div className={'col-4'}>
										<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
											<strong>Selected Lift Requests</strong>
										</h4>
									</div>
								</Row>

								<div className={'padding-bottom-40'}>
									<LiftRequestListGrid environment={'sandbox'} page={'view'} data={lifts} />
								</div>

								<Row id={'sandbox-details-list'} className={'form-section row'}>
									<div className={'col-4'}>
										<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
											<strong>Selected Assets</strong>
										</h4>
									</div>
								</Row>

								<div className={'padding-bottom-40'}>
									<AssetAvailabilityList environment={'sandbox'} data={assets} />
								</div>

								<Row id={'sandbox-details-list'} className={'form-section row'}>
									<div className={'col-4'}>
										<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
											<strong>Selected Airports</strong>
										</h4>
									</div>
								</Row>

								<div className={'padding-bottom-40'}>
									<AirportsListGrid environment={'sandbox'} page={'view'} data={airports} />
								</div>
							</PDFExport>
						) : (
							<Loader />
						)}
					</Panel>
					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

export default SandboxView;
