/*
 * Copyright 2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

/**
 * Global: Asset Status Code Colors for C-130 and C-40
 * @type {{spo: string, api: string, mx: string, apui: string, hri: string, wri: string}}
 */
export const optionsAssetStatusCodeColors = {
	available: '#f8f0e5',

	api: '#ec8f04', // C1305680d5
	apui: '#ec8f04', // C40
	hri: '#ec8f04', // C40/C130
	mx: '#b80000', // C40/C130
	spo: '#ec8f04', // C40
	wri: '#ec8f04' // C130
};
