/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { PDFExport } from '@progress/kendo-react-pdf';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Col, Container, Row } from 'react-bootstrap';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';
import Loader from '../../Core/Loader';

import { ApiChangeLiftRequestState, ApiLiftRequestById } from '../../Helpers/Apis';
import { ViewPalletForm } from '../../Helpers/FormHelpers/ViewPalletForm';

import Moment from 'react-moment';

import { accessValidator } from '../../Helpers/RolesPermissions';
import { CapitalizeString, htmlRender, numberWithCommas } from '../../Helpers/ContentFormatting';
import { toast } from 'react-toastify';
import { priorityOfTravel, naloPriorityOfTravel, urgencyOfTravel, naloUrgencyOfTravel } from '../../../Assets/json/optionsNewLiftRequest';
import { stripTimeZone } from '../../Helpers/DateTimeConversions';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const HazmatCell = ({ dataItem, ...props }) => {
	if (!dataItem || !dataItem.hazmat || !dataItem.hazmat.containsHazmat) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					No
				</span>
			</td>
		);
	}

	if (dataItem.hazmat.containsHazmat) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					Yes
				</span>
			</td>
		);
	}
};

const RollingStockCell = ({ dataItem, ...props }) => {
	if (!dataItem || !dataItem.rollingStock) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					No
				</span>
			</td>
		);
	}

	if (dataItem.rollingStock) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					Yes
				</span>
			</td>
		);
	}
};

const OversizeCell = ({ dataItem, ...props }) => {
	if (!dataItem || !dataItem.oversize) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					No
				</span>
			</td>
		);
	}

	if (dataItem.oversize) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					Yes
				</span>
			</td>
		);
	}
};

const PalletPositionCell = ({ dataItem, ...props }) => {
	if (dataItem.count) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					{dataItem.count}
				</span>
			</td>
		);
	}
};

const PalletWeightCell = ({ dataItem, ...props }) => {
	if (dataItem.weight) {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					{`${dataItem.weight} lbs `}
				</span>
			</td>
		);
	} else {
		return (
			<td {...props.tdProps}>
				<span
					style={{
						display: 'inlineBlock',
						paddingLeft: '10px',
						verticalAlign: 'middle',
						lineHeight: '32px'
					}}
					className="hazmat-cell">
					{`0 lbs `}
				</span>
			</td>
		);
	}
};

const PalletFormContainerCommandCell = (props) => {
	return (
		<td>
			<button className="btn btn-primary btn-color-green" onClick={() => props.enterEditForm(props.dataItem)}>
				Cargo Form
			</button>
		</td>
	);
};

const LiftRequestView = (props) => {
	const [loaded, setLoaded] = React.useState(false);
	const [liftDetails, setLiftDetails] = React.useState([]);
	const [formKey, setFormKey] = React.useState(new Date());
	const [visible, setVisible] = React.useState(false);
	const [stateVisible, setStateVisible] = React.useState(false);
	const [dialogState, setDialogState] = React.useState('');
	const [hasPallet, setHasPallet] = React.useState(false);
	const [hasPassenger, sethasPassenger] = React.useState(false);
	const [cargoString, setCargoString] = React.useState(false);
	const [gridData, setGridData] = React.useState([]);
	const [openForm, setOpenForm] = React.useState(false);
	const [editItem, setEditItem] = React.useState({
		PalletID: 1
	});
	const [selectedState, setSelectedState] = React.useState('');

	const Capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	};

	const params = useParams();
	const pdfExportComponent = React.useRef(null);

	const accessAuthorizationButton = ['approver'];
	const accessClaimButton = ['nalo'];
	const accessValidateButton = ['nalo'];
	const accessRegrettedButton = ['nalo'];

	const LiftRequestStateList = ['Submitted', 'Claimed', 'Authorized', 'Validated', 'Regretted'];

	const PalletFormCommandCell = (props) => <PalletFormContainerCommandCell {...props} enterEditForm={enterEditForm} />;

	const enterEditForm = (item) => {
		setOpenForm(true);
		setEditItem(item);
	};

	const handleCancelEdit = () => {
		setOpenForm(false);
	};

	const data = {
		id: liftDetails.id ? liftDetails.id : '',
		latestVersion: liftDetails.latestVersion ? liftDetails.latestVersion : '1',
		latestOwner: {
			id: liftDetails.latestOwner ? liftDetails.latestOwner.id : null,
			firstName: liftDetails.latestOwner ? liftDetails.latestOwner.firstName : null,
			lastName: liftDetails.latestOwner ? liftDetails.latestOwner.lastName : null,
			email: liftDetails.latestOwner ? liftDetails.latestOwner.email : null,
			phoneNumber: liftDetails.latestOwner ? liftDetails.latestOwner.phoneNumber : null,
			rank: liftDetails.latestOwner ? liftDetails.latestOwner.rank : null,
			dutyTitle: liftDetails.latestOwner ? liftDetails.latestOwner.dutyTitle : null,
			unit: liftDetails.latestOwner ? liftDetails.latestOwner.unit : null
		},
		history: liftDetails.history ? liftDetails.history : []
	};

	const setPaxStatus = (data) => {
		if (data.history[0] !== undefined && data.history[0].cargoes !== undefined) {
			data.history[0].cargoes.map((cargo) => {
				if (cargo.type === 'PASSENGERS' && hasPallet) {
					sethasPassenger(true);
					setCargoString('Passengers & Cargo');
				}
				if (cargo.type === 'PASSENGERS' && !hasPallet) {
					sethasPassenger(true);
					setCargoString('Passengers');
				}
				if (cargo.type === 'PALLETS' && hasPassenger) {
					setHasPallet(true);
					setCargoString('Passengers & Cargo');
				}
				if (cargo.type === 'PALLETS' && !hasPassenger) {
					setHasPallet(true);
					setCargoString('Cargo');
				}
				return null;
			});
		}
		if (data.history[0] !== undefined && data.history[0].cargoes.length === 0) {
			setCargoString('No Cargo');
		}
	};

	const lrTitle = data.history[0] ? data.history[0].requestName : params.liftId;

	const dataFetch = () => {
		ApiLiftRequestById(params.liftId).then((res) => {
			setSelectedState(res.history[0].state);
			setLiftDetails(res);
			setLoaded(true);
			setPaxStatus(res);

			let palletArray = [];

			if (res.history && res.history[0].cargoes && res.history[0].cargoes !== null && res.history[0].cargoes !== undefined) {
				// eslint-disable-next-line
				res.history[0].cargoes.map((cargo, i) => {
					if (cargo.type === 'PALLETS') {
						palletArray.push(cargo);
					}
				});
			}

			let palletGridArray = [];

			// eslint-disable-next-line
			palletArray.map((pallet, i) => {
				pallet['EntryID'] = i + 1;
				pallet['PalletName'] = pallet.cargoName ? pallet.cargoName : i + 1;
				if (pallet.hazmat && pallet.hazmat.containsHazmat && pallet.hazmat.hazmatCargoes) {
					let hazmatArray = [];
					// eslint-disable-next-line
					pallet.hazmat.hazmatCargoes.map((hazmat, n) => {
						hazmat['EntryID'] = n + 1;
						hazmat['PalletName'] = hazmat.hazmatName ? hazmat.hazmatName : n + 1;
						hazmatArray.push(hazmat);
					});
					pallet.hazmatCargoes = hazmatArray;
				}
				palletGridArray.push(pallet);
			});
			setGridData(palletArray);
		});
	};

	const stateUpdate = (id, state) => {
		ApiChangeLiftRequestState(id, state, props.token);
		toast.success('Successfully Changed Lift Request Status to ' + CapitalizeString(state.toLowerCase()));

		setDialogState('');

		setTimeout(() => {
			dataFetch();
			setFormKey(new Date());
		}, 300);
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const toggleStateDialog = () => {
		setStateVisible(!stateVisible);
	};

	const openDialog = (id, state) => {
		toggleDialog();
		setDialogState(state);
	};

	const openStateDialog = (id) => {
		toggleStateDialog();
	};

	const DialogWindow = (id, state) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleDialog}>
				<div data-id={id} data-state-to={state}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to change the lift request status to {CapitalizeString(state.toLowerCase())}?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							stateUpdate(id, state);
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const DialogStateWindow = (id, state) => {
		return (
			<Dialog title={'Manual Lift Request State Change'} onClose={toggleStateDialog}>
				<div data-id={id} data-state-to={state}>
					<p style={{ textAlign: 'center', paddingBottom: '15px' }}>Change Lift Request State to: </p>
				</div>

				<DropDownList
					data={LiftRequestStateList}
					defaultValue={Capitalize(selectedState)}
					onChange={(event) => {
						setSelectedState(event.value.toUpperCase());
					}}
				/>

				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleStateDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						disabled={selectedState.toLowerCase() === data.history[0].state.toLowerCase()}
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							stateUpdate(id, selectedState);
							toggleStateDialog();
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						{accessValidator(props.roleaccess, ['nalo']) && (
							<React.Fragment>
								<NavLink
									className={'nav-link'}
									role={'button'}
									to={`#`}
									title={`Click to Authorize Lift Request`}
									onClick={() => {
										openStateDialog(params.liftId);
									}}>
									<i className={'fa-solid fa-code-compare'}></i>
									Change Lift Request State
								</NavLink>

								{stateVisible && DialogStateWindow(params.liftId)}
							</React.Fragment>
						)}

						{/* Access: Requester (excluding nalo with base requester role) - Button to edit Lift Requests */}
						{accessValidator(props.roleaccess, accessAuthorizationButton) && data.history[0].state === 'SUBMITTED' && (
							<React.Fragment>
								<NavLink
									className={'nav-link'}
									role={'button'}
									to={`#`}
									title={`Click to Authorize Lift Request`}
									onClick={() => {
										openDialog(params.liftId, 'AUTHORIZED', props.token);
									}}>
									<i className={'fa fa-file-exclamation'}></i>
									Authorize Request
								</NavLink>

								{visible && dialogState === 'AUTHORIZED' && DialogWindow(params.liftId, 'AUTHORIZED')}
							</React.Fragment>
						)}

						{/* Access: Nalo - Button to Claim Authorized Lift Requests */}
						{accessValidator(props.roleaccess, accessClaimButton) && data.history[0].state === 'AUTHORIZED' && (
							<React.Fragment>
								<NavLink
									className={'nav-link'}
									role={'button'}
									to={`#`}
									title={`Click to Claim Lift Request`}
									onClick={() => {
										openDialog(params.liftId, 'CLAIMED', props.token);
									}}>
									<i className="fa-solid fa-file-check"></i>
									Claim Lift Request
								</NavLink>

								{visible && dialogState === 'CLAIMED' && DialogWindow(params.liftId, 'CLAIMED')}
							</React.Fragment>
						)}

						{/* Access: Nalo - Button to Validate Lift Requests */}
						{accessValidator(props.roleaccess, accessValidateButton) && data.history[0].state === 'CLAIMED' && (
							<NavLink className={'nav-link'} role={'button'} to={`/validation/${params.liftId}`} title={'Validate Lift Request'}>
								<i className="fa-regular fa-ballot-check"></i>
								<span className={'sr-only'}>Click to Validate ${params.liftId}</span>
								Validate Lift Request
							</NavLink>
						)}

						{/* Access: Nalo - Button to Regret Lift Requests */}
						{accessValidator(props.roleaccess, accessRegrettedButton) && data.history[0].state !== 'IN_TRANSIT' && data.history[0].state !== 'SATISFIED' && data.history[0].state !== 'REGRETTED' && data.history[0].state !== 'CANCELLED' && (
							<NavLink
								className={'nav-link'}
								role={'button'}
								to={`#`}
								title={`Click to Regret Lift Request`}
								onClick={() => {
									openDialog(params.liftId, 'REGRETTED', props.token);
								}}>
								<i className="fa-solid fa-ban"></i>
								Regret Lift Request
								{visible && dialogState === 'REGRETTED' && DialogWindow(params.liftId, 'REGRETTED')}
							</NavLink>
						)}

						{/* Access: Requester (excluding nalo with base requester role) - Button to edit Lift Requests */}
						{accessValidator(props.roleaccess, ['requester']) && !accessValidator(props.roleaccess, ['nalo', 'approver']) && data.history[0].state !== 'IN_TRANSIT' && data.history[0].state !== 'SATISFIED' && data.history[0].state !== 'REGRETTED' && data.history[0].state !== 'CANCELLED' && (
							<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/edit/${params.liftId}`} title={'Edit Lift Request'}>
								<i className="fa fa-edit"></i>
								Edit Request
							</NavLink>
						)}

						{!accessValidator(props.roleaccess, ['requester', 'approver', 'squadron']) && data.history[0].state !== 'SUBMITTED' && data.history[0].state !== 'AUTHORIZED' && data.history[0].state !== 'IN_TRANSIT' && data.history[0].state !== 'SATISFIED' && data.history[0].state !== 'REGRETTED' && data.history[0].state !== 'CANCELLED' && (
							<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/edit/${params.liftId}`}>
								<i className={'fa fa-edit'}></i>
								Edit Request
							</NavLink>
						)}

						<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/history/${params.liftId}`}>
							<i className={'fa fa-rectangle-history'}></i>
							View Request History
						</NavLink>

						{/* Print button placeholder to be implemented in ALO-549 */}
						{/* <Nav.Link to={"#print"}>
                            <i className={"fa fa-print"}></i>
                            Print
                        </Nav.Link> */}
						<Nav.Link
							to={'#export'}
							onClick={() => {
								if (pdfExportComponent.current) {
									pdfExportComponent.current.save();
								}
							}}>
							<i className={'fa-solid fa-file-pdf'}></i>
							Export
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	useEffect(() => {
		dataFetch();
	}, [formKey]);

	useEffect(() => {
		if (liftDetails.history !== undefined) {
			setPaxStatus(liftDetails);
		}
	}, [liftDetails]);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						{loaded ? (
							<PDFExport paperSize="A4" margin="1cm" ref={pdfExportComponent}>
								<div className={'page-title'}>
									<h2>Lift Request: {lrTitle}</h2>
									{PageTitleControls()}
								</div>

								<div className={'lift-request-meta-data'}>
									<ul>
										<li>
											Status:&nbsp;
											{data.history[0] && data.history[0].state ? <span className={`pill lift-status-${data.history[0].state.toLowerCase()}`}>{data.history[0].state}</span> : <span className={`pill`}>Not Available</span>}
										</li>

										<li>{data.history[0] && data.history[0].priority ? 'Priority: ' + data.history[0].priority : 'Priority: Not Available'}</li>
										<li>Version of Request: {String(data.history.length)}</li>
										<li>Read Only</li>
									</ul>
								</div>

								<Row id={'requester-information'} className={'form-section row'}>
									<div className={'col-12 form-section-title'}>
										<h3 className={'padding-bottom-0'}>Requester Information</h3>
									</div>

									<div className={'col-3'}>
										<label>First Name</label>
										<div className={'static-field-read-only static-text'}>{data.latestOwner && data.latestOwner.firstName ? data.latestOwner.firstName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Last Name</label>
										<div className={'static-field-read-only static-text'}>{data.latestOwner && data.latestOwner.lastName ? data.latestOwner.lastName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Email</label>
										<div className={'static-field-read-only static-text'}>{data.latestOwner && data.latestOwner.email ? htmlRender(`<a href="mailto:${data.latestOwner.email}">${data.latestOwner.email}</a>`) : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Phone Number</label>
										<div className={'static-field-read-only static-text'}>{data.latestOwner && data.latestOwner.phoneNumber ? data.latestOwner.phoneNumber : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Department or Agency</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].requester && data.history[0].requester.branchOfService ? data.history[0].requester.branchOfService : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Grade</label>
										<div className={'static-field-read-only static-text'}>{data.latestOwner && data.latestOwner.rank && data.latestOwner.rank.gradeTitle ? data.latestOwner.rank.gradeTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Duty Title</label>
										<div className={'static-field-read-only static-text'}>{data.latestOwner && data.latestOwner.dutyTitle ? data.latestOwner.dutyTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Unit</label>
										<div className={'static-field-read-only static-text'}>{data.latestOwner && data.latestOwner.unit && data.latestOwner.unit.uic ? data.latestOwner.unit.uic : ''}</div>
									</div>

									<div className={'col-12 form-section-title'}>
										<h3 className={'padding-bottom-0'}>Requesting Unit</h3>
									</div>

									<div className={'col-12'}>
										<p className={'padding-bottom-0 padding-top-0'}>If requesting unit is different from the requester's, then please enter requesting unit and PLADS information below.</p>
									</div>

									<div className={'col-3'}>
										<label>Unit</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].requestingUnit && data.history[0].requestingUnit.unit && data.history[0].requestingUnit.unit.uic ? data.history[0].requestingUnit.unit.uic : ''}</div>
									</div>

									<div className={'col-9'}>
										<label>PLADS</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].requestingUnit && data.history[0].requestingUnit.plads ? data.history[0].requestingUnit.plads : ''}</div>
									</div>
								</Row>

								<Row id={'travel-priority-and-urgency'} className={'form-section row'}>
									<div className={'col-12 form-section-title priority-and-urgency'}>
										<h3 className={'padding-bottom-0'}>Priority and Urgency of Lift</h3>
									</div>

									<div className={'col-6'}>
										<label>Lift Priority</label>
										{props.roleaccess.includes('nalo') ? <div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].priority ? naloPriorityOfTravel.map((rec, i) => (rec.value === data.history[0].priority ? rec.label : '')) : ''}</div> : <div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].priority ? priorityOfTravel.map((rec, i) => (rec.value === data.history[0].priority ? rec.label : '')) : ''}</div>}
									</div>

									<div className={'col-6'}>
										<label>Lift Urgency</label>
										{props.roleaccess.includes('nalo') ? <div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].urgency ? naloUrgencyOfTravel.map((rec, i) => (rec.value === data.history[0].urgency ? rec.label : '')) : ''}</div> : <div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].urgency ? urgencyOfTravel.map((rec, i) => (rec.value === data.history[0].urgency ? rec.label : '')) : ''}</div>}
									</div>
								</Row>

								<Row id={'point-of-contact-departure'} className={'form-section row'}>
									<div className="col-12 form-section-title point-of-contact">
										<h3>
											Point of Contact (Departure)
											<span className="fontsize-14px" style={{ float: 'right' }}>
												(Cannot be Arrival POC or Travel Authorizing Official)
											</span>
										</h3>
									</div>

									<div className="col-12">
										<h5 className="form-subsection">Primary Contact</h5>
									</div>

									<div className={'col-3'}>
										<label>First Name</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.primary && data.history[0].departure.poc.primary.firstName ? data.history[0].departure.poc.primary.firstName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Last Name</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.primary && data.history[0].departure.poc.primary.lastName ? data.history[0].departure.poc.primary.lastName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Email</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.primary && data.history[0].departure.poc.primary.email ? data.history[0].departure.poc.primary.email : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Mobile Phone</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.primary && data.history[0].departure.poc.primary.phoneNumber ? data.history[0].departure.poc.primary.phoneNumber : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Department or Agency</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.primary && data.history[0].departure.poc.primary.branchOfService ? data.history[0].departure.poc.primary.branchOfService : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Grade</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.primary && data.history[0].departure.poc.primary.rank && data.history[0].departure.poc.primary.rank.gradeTitle ? data.history[0].departure.poc.primary.rank.gradeTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Duty Title</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.primary && data.history[0].departure.poc.primary.dutyTitle ? data.history[0].departure.poc.primary.dutyTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Unit</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.primary && data.history[0].departure.poc.primary.unit && data.history[0].departure.poc.primary.unit.uic ? data.history[0].departure.poc.primary.unit.uic : ''}</div>
									</div>

									<div className="col-12">
										<h5 className="form-subsection">Alternate Contact</h5>
									</div>

									<div className={'col-3'}>
										<label>First Name</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.alternate && data.history[0].departure.poc.alternate.firstName ? data.history[0].departure.poc.alternate.firstName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Last Name</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.alternate && data.history[0].departure.poc.alternate.lastName ? data.history[0].departure.poc.alternate.lastName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Email</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.alternate && data.history[0].departure.poc.alternate.email ? data.history[0].departure.poc.alternate.email : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Mobile Phone</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.alternate && data.history[0].departure.poc.alternate.phoneNumber ? data.history[0].departure.poc.alternate.phoneNumber : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Department or Agency</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.alternate && data.history[0].departure.poc.alternate.branchOfService ? data.history[0].departure.poc.alternate.branchOfService : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Grade</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.alternate && data.history[0].departure.poc.alternate.rank && data.history[0].departure.poc.alternate.rank.gradeTitle ? data.history[0].departure.poc.alternate.rank.gradeTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Duty Title</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.alternate && data.history[0].departure.poc.alternate.dutyTitle ? data.history[0].departure.poc.alternate.dutyTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Unit</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.poc && data.history[0].departure.poc.alternate && data.history[0].departure.poc.alternate.unit && data.history[0].departure.poc.alternate.unit.uic ? data.history[0].departure.poc.alternate.unit.uic : ''}</div>
									</div>
								</Row>

								<Row id={'point-of-contact-arrival'} className={'form-section row'}>
									<div className="col-12 form-section-title point-of-contact">
										<h3>
											Point of Contact (Arrival)
											<span className="fontsize-14px" style={{ float: 'right' }}>
												(Cannot be Departure Coordinator)
											</span>
										</h3>
									</div>

									<div className="col-12">
										<h5 className="form-subsection">Primary Contact</h5>
									</div>

									<div className={'col-3'}>
										<label>First Name</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.primary && data.history[0].arrival.poc.primary.firstName ? data.history[0].arrival.poc.primary.firstName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Last Name</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.primary && data.history[0].arrival.poc.primary.lastName ? data.history[0].arrival.poc.primary.lastName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Email</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.primary && data.history[0].arrival.poc.primary.email ? data.history[0].arrival.poc.primary.email : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Mobile Phone</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.primary && data.history[0].arrival.poc.primary.phoneNumber ? data.history[0].arrival.poc.primary.phoneNumber : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Department or Agency</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.primary && data.history[0].arrival.poc.primary.branchOfService ? data.history[0].arrival.poc.primary.branchOfService : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Grade</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.primary && data.history[0].arrival.poc.primary.rank && data.history[0].arrival.poc.primary.rank.gradeTitle ? data.history[0].arrival.poc.primary.rank.gradeTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Duty Title</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.primary && data.history[0].arrival.poc.primary.dutyTitle ? data.history[0].arrival.poc.primary.dutyTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Unit</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.primary && data.history[0].arrival.poc.primary.unit && data.history[0].arrival.poc.primary.unit.uic ? data.history[0].arrival.poc.primary.unit.uic : ''}</div>
									</div>

									<div className="col-12">
										<h5 className="form-subsection">Alternate Contact</h5>
									</div>

									<div className={'col-3'}>
										<label>First Name</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.alternate && data.history[0].arrival.poc.alternate.firstName ? data.history[0].arrival.poc.alternate.firstName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Last Name</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.alternate && data.history[0].arrival.poc.alternate.lastName ? data.history[0].arrival.poc.alternate.lastName : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Email</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.alternate && data.history[0].arrival.poc.alternate.email ? data.history[0].arrival.poc.alternate.email : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Mobile Phone</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.alternate && data.history[0].arrival.poc.alternate.phoneNumber ? data.history[0].arrival.poc.alternate.phoneNumber : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Department or Agency</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.alternate && data.history[0].arrival.poc.alternate.branchOfService ? data.history[0].arrival.poc.alternate.branchOfService : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Grade</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.alternate && data.history[0].arrival.poc.alternate.rank && data.history[0].arrival.poc.alternate.rank.gradeTitle ? data.history[0].arrival.poc.alternate.rank.gradeTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Duty Title</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.alternate && data.history[0].arrival.poc.alternate.dutyTitle ? data.history[0].arrival.poc.alternate.dutyTitle : ''}</div>
									</div>

									<div className={'col-3'}>
										<label>Unit</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.poc && data.history[0].arrival.poc.alternate && data.history[0].arrival.poc.alternate.unit && data.history[0].arrival.poc.alternate.unit.uic ? data.history[0].arrival.poc.alternate.unit.uic : ''}</div>
									</div>
								</Row>

								<Row id={'purpose-of-travel'} className={'form-section row'}>
									<div className="col-12 form-section-title purpose-of-travel">
										<h3>Purpose of Travel</h3>
									</div>

									<div className={'col-12'}>
										<label>Complete Mission Description</label>
										<div className={'static-field-read-only static-textarea'}>{data.history[0] && data.history[0].missionDescription ? data.history[0].missionDescription : ''}</div>
									</div>

									<div className={'col-12'}>
										<label>Reason for Military Airlift or Reason Commercial Travel Unacceptable</label>
										<div className={'static-field-read-only static-textarea'}>{data.history[0] && data.history[0].justification ? data.history[0].justification : ''}</div>
									</div>
								</Row>

								<Row id={'itinerary-departure-information'} className={'form-section row'}>
									<div className="col-12 form-section-title itinerary-departure-information">
										<h3>
											Itinerary Departure Information
											<span className="fontsize-14px" style={{ float: 'right' }}>
												(Earliest and latest dates must be separated by a minimum of 72 hours)
											</span>
										</h3>
									</div>

									<div className={'col-4'}>
										<label>Desired Departure ICAO</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].departure && data.history[0].departure.airport ? data.history[0].departure.airport.icao + ': ' + data.history[0].departure.airport.name : ''}</div>
									</div>

									<div className={'col-4'}>
										<label>Earliest Departure Date/Time (UTC/Zulu)</label>
										<div className={'static-field-read-only static-text'}>
											<Moment format="YYYY-MM-DD HH:mm">{data.history[0] && data.history[0].departure && data.history[0].departure.earliest ? stripTimeZone(data.history[0].departure.earliest) : ''}</Moment>
										</div>
									</div>

									<div className={'col-4'}>
										<label>Latest Departure Date/Time (UTC/Zulu)</label>
										<div className={'static-field-read-only static-text'}>
											<Moment format="YYYY-MM-DD HH:mm">{data.history[0] && data.history[0].departure && data.history[0].departure.latest ? stripTimeZone(data.history[0].departure.latest) : ''}</Moment>
										</div>
									</div>
								</Row>

								<div className={'col-12-alternate-departure-icao'}>
									<label>{data.history[0] && data.history[0].departure.alternateAirports.length > 0 ? 'Alternate Airports' : 'No Alternate Airports'}</label>
									{data.history[0].departure.alternateAirports.map((altAirport, i) => {
										return (
											<div className={'col-12 padding-bottom-15'}>
												<div className={'static-field-read-only static-textarea'}>{altAirport != null ? altAirport.icaoName : ''}</div>
											</div>
										);
									})}
								</div>

								<Row id={'itinerary-arrival-information'} className={'form-section row'}>
									<div className="col-12 form-section-title itinerary-arrival-information">
										<h3>Itinerary Arrival Information</h3>
									</div>

									<div className={'col-4'}>
										<label>Desired Arrival ICAO</label>
										<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].arrival && data.history[0].arrival.airport ? data.history[0].arrival.airport.icao + ': ' + data.history[0].arrival.airport.name : ''}</div>
									</div>

									<div className={'col-4'}>
										<label>Earliest Arrival Date/Time (UTC/Zulu)</label>
										<div className={'static-field-read-only static-text'}>
											<Moment format="YYYY-MM-DD HH:mm">{data.history[0] && data.history[0].arrival && data.history[0].arrival.earliest ? stripTimeZone(data.history[0].arrival.earliest) : ''}</Moment>
										</div>
									</div>

									<div className={'col-4'}>
										<label>Latest Arrival Date/Time (UTC/Zulu)</label>
										<div className={'static-field-read-only static-text'}>
											<Moment format="YYYY-MM-DD HH:mm">{data.history[0] && data.history[0].arrival && data.history[0].arrival.latest ? stripTimeZone(data.history[0].arrival.latest) : ''}</Moment>
										</div>
									</div>
								</Row>

								<div className={'col-12-alternate-arrival-icao'}>
									<label>{data.history[0] && data.history[0].arrival.alternateAirports.length > 0 ? 'Alternate Airports' : 'No Alternate Airports'}</label>
									{data.history[0].arrival.alternateAirports.map((altAirport, i) => {
										return (
											<div className={'col-12 padding-bottom-15'}>
												<div className={'static-field-read-only static-textarea'}>{altAirport != null ? altAirport.icaoName : ''}</div>
											</div>
										);
									})}
								</div>

								<Row id={'form-passenger-cargo-option'} className={'form-section row'}>
									<div className="col-12 form-section-title passengers-or-cargo">
										<h3>
											Is your request for Passengers or Cargo?
											<span className="fontsize-14px" style={{ float: 'right' }}>
												(For each passenger, we will account for 40lbs of bags)
											</span>
										</h3>
									</div>

									<div className={'col-4'}>
										<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
											<strong>Request is for</strong>: {cargoString}
										</h4>
									</div>
								</Row>

								{data.history[0] &&
									data.history[0].cargoes &&
									// eslint-disable-next-line
									data.history[0].cargoes.map((cargo, i) => {
										if (cargo.type === 'PASSENGERS' && hasPassenger) {
											return (
												<div id={'form-cargo'}>
													<React.Fragment>
														<div>
															<Row id={'total-pax'} className={'form-section row'}>
																<div className="col-12 form-section-title pax-count">
																	<h3>PAX Count</h3>
																</div>

																<div className={'col-4'}>
																	<label>Total Number of PAX</label>
																	<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].cargoes.length && data.history[0].cargoes[i].count ? data.history[0].cargoes[i].count : ''}</div>
																</div>
															</Row>

															<Row id={'commercial-cost'} className={'form-section row'}>
																<div className="col-12 form-section-title determine-commercial-cost">
																	<h3>
																		Determine Commercial Cost
																		<span className="fontsize-14px" style={{ float: 'right' }}>
																			(Calculate cost using the{' '}
																			<a href="https://www.gsa.gov/travel/plan-book/transportation-airfare-pov-etc/city-pair-program-cpp" title="Click to Use CPP Search Tool" target="_blank" rel="noreferrer">
																				CPP Search Tool
																			</a>
																			)
																		</span>
																	</h3>
																</div>

																<div className={'col-4'}>
																	<label>Total Commercial Cost for PAX</label>
																	<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].commercialCost ? '$' + numberWithCommas(data.history[0].commercialCost) : ''}</div>
																</div>
															</Row>

															<Row id={'senior-traveler'} className={'form-section row'}>
																<div className="col-12 form-section-title senior-traveler">
																	<h3>
																		Senior Traveler
																		<span className="fontsize-14px" style={{ float: 'right' }}>
																			(Must be 1 for every 121 PAX &amp; can't be the same as the Travel Authorizing Official)
																		</span>
																	</h3>
																</div>

																<div className={'col-3'}>
																	<label>First Name</label>
																	<div className={'static-field-read-only static-text'}>{data.history && data.history[0].cargoes && data.history[0].cargoes[i].type === 'PASSENGERS' && data.history[0].cargoes[i].seniorTravelers !== null && data.history[0].cargoes[i].seniorTravelers !== undefined && data.history[0].cargoes[i].seniorTravelers.length && data.history[0].cargoes[i].seniorTravelers[0].firstName ? data.history[0].cargoes[i].seniorTravelers[0].firstName : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Last Name</label>
																	<div className={'static-field-read-only static-text'}>{data.history && data.history[0].cargoes && data.history[0].cargoes[i].type === 'PASSENGERS' && data.history[0].cargoes[i].seniorTravelers !== null && data.history[0].cargoes[i].seniorTravelers !== undefined && data.history[0].cargoes[i].seniorTravelers.length && data.history[0].cargoes[i].seniorTravelers[0].lastName ? data.history[0].cargoes[i].seniorTravelers[0].lastName : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Email Address</label>
																	<div className={'static-field-read-only static-text'}>{data.history && data.history[0].cargoes && data.history[0].cargoes[i].type === 'PASSENGERS' && data.history[0].cargoes[i].seniorTravelers !== null && data.history[0].cargoes[i].seniorTravelers !== undefined && data.history[0].cargoes[i].seniorTravelers.length && data.history[0].cargoes[i].seniorTravelers[0].email ? data.history[0].cargoes[i].seniorTravelers[0].email : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Mobile Phone</label>
																	<div className={'static-field-read-only static-text'}>{data.history && data.history[0].cargoes && data.history[0].cargoes[i].type === 'PASSENGERS' && data.history[0].cargoes[i].seniorTravelers !== null && data.history[0].cargoes[i].seniorTravelers !== undefined && data.history[0].cargoes[i].seniorTravelers.length && data.history[0].cargoes[i].seniorTravelers[0].phoneNumber ? data.history[0].cargoes[i].seniorTravelers[0].phoneNumber : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Department or Agency</label>
																	<div className={'static-field-read-only static-text'}>{data.history && data.history[0].cargoes && data.history[0].cargoes[i].type === 'PASSENGERS' && data.history[0].cargoes[i].seniorTravelers !== null && data.history[0].cargoes[i].seniorTravelers !== undefined && data.history[0].cargoes[i].seniorTravelers.length && data.history[0].cargoes[i].seniorTravelers[0].branchOfService ? data.history[0].cargoes[i].seniorTravelers[0].branchOfService : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Grade</label>
																	<div className={'static-field-read-only static-text'}>{data.history && data.history[0].cargoes && data.history[0].cargoes[i].type === 'PASSENGERS' && data.history[0].cargoes[i].seniorTravelers !== null && data.history[0].cargoes[i].seniorTravelers !== undefined && data.history[0].cargoes[i].seniorTravelers.length && data.history[0].cargoes[i].seniorTravelers[0].rank && data.history[0].cargoes[i].seniorTravelers[0].rank.gradeTitle ? data.history[0].cargoes[i].seniorTravelers[0].rank.gradeTitle : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Duty Title</label>
																	<div className={'static-field-read-only static-text'}>{data.history && data.history[0].cargoes && data.history[0].cargoes[i].type === 'PASSENGERS' && data.history[0].cargoes[i].seniorTravelers !== null && data.history[0].cargoes[i].seniorTravelers !== undefined && data.history[0].cargoes[i].seniorTravelers.length && data.history[0].cargoes[i].seniorTravelers[0].dutyTitle ? data.history[0].cargoes[i].seniorTravelers[0].dutyTitle : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Unit</label>
																	<div className={'static-field-read-only static-text'}>{data.history && data.history[0].cargoes && data.history[0].cargoes[i].type === 'PASSENGERS' && data.history[0].cargoes[i].seniorTravelers !== null && data.history[0].cargoes[i].seniorTravelers !== undefined && data.history[0].cargoes[i].seniorTravelers.length && data.history[0].cargoes[i].seniorTravelers[0].unit && data.history[0].cargoes[i].seniorTravelers[0].unit.uic ? data.history[0].cargoes[i].seniorTravelers[0].unit.uic : ''}</div>
																</div>
															</Row>

															<Row id={'travel-authorizing-official'} className={'form-section row'}>
																<div className="col-12 form-section-title travel-authorizing-official">
																	<h3>
																		Travel Authorizing Official
																		<span className="fontsize-14px" style={{ float: 'right' }}>
																			(As appointed by Service)
																		</span>
																	</h3>
																</div>

																<div className={'col-3'}>
																	<label>First Name</label>
																	<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].commandingOfficer && data.history[0].commandingOfficer.firstName ? data.history[0].commandingOfficer.firstName : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Last Name</label>
																	<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].commandingOfficer && data.history[0].commandingOfficer.lastName ? data.history[0].commandingOfficer.lastName : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Email Address</label>
																	<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].commandingOfficer && data.history[0].commandingOfficer.email ? data.history[0].commandingOfficer.email : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Mobile Phone</label>
																	<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].commandingOfficer && data.history[0].commandingOfficer.phoneNumber ? data.history[0].commandingOfficer.phoneNumber : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Department or Agency</label>
																	<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].commandingOfficer && data.history[0].commandingOfficer.branchOfService ? data.history[0].commandingOfficer.branchOfService : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Grade</label>
																	<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].commandingOfficer && data.history[0].commandingOfficer.rank && data.history[0].commandingOfficer.rank.gradeTitle ? data.history[0].commandingOfficer.rank.gradeTitle : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Duty Title</label>
																	<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].commandingOfficer && data.history[0].commandingOfficer.dutyTitle ? data.history[0].commandingOfficer.dutyTitle : ''}</div>
																</div>

																<div className={'col-3'}>
																	<label>Unit</label>
																	<div className={'static-field-read-only static-text'}>{data.history[0] && data.history[0].commandingOfficer && data.history[0].commandingOfficer.unit ? data.history[0].commandingOfficer.unit.uic : ''}</div>
																</div>
															</Row>
														</div>
													</React.Fragment>
												</div>
											);
										}
									})}

								{hasPallet && (
									<div id={'form-cargo'}>
										{/* Pallets Positions: Start */}
										<React.Fragment>
											<Row id={'cargo-pallets'} className={'form-section'}>
												{/* Section Title - Cargo Pallets: Start */}
												<div className={'col-12 form-section-title cargo-pallets'}>
													<h3>Cargo</h3>
												</div>
												{/* Section Title - Cargo Pallets: End */}
											</Row>

											{/* Pallet Table: Start */}
											<Grid
												name={'test'}
												style={{
													height: '420px',
													width: '100%'
												}}
												data={gridData}>
												<Column field="PalletName" title="Cargo Title" />

												<Column
													title="Total Weight"
													cells={{
														data: PalletWeightCell
													}}
												/>
												<Column
													title="Cargo Positions"
													cells={{
														data: PalletPositionCell
													}}
												/>
												<Column
													cells={{
														data: HazmatCell
													}}
													title="Contains Hazmat"
													editable={false}
												/>
												<Column
													cells={{
														data: RollingStockCell
													}}
													title="Rolling Stock"
													editable={false}
												/>
												<Column
													cells={{
														data: OversizeCell
													}}
													title="Oversize"
													editable={false}
												/>
												{/* <Column field="oversize" title="Oversize" /> */}
												{/* <Column field="rollingStock" title="Rolling Stock" /> */}
												<Column cell={PalletFormCommandCell} />
											</Grid>

											{openForm && <ViewPalletForm item={editItem} cancelEdit={handleCancelEdit} props={props} />}
											{/* Pallet Table: End */}
										</React.Fragment>
										{/* Pallets Positions: End */}
									</div>
								)}

								<Row id={'share-lift-request'} className={'form-section row'}>
									<div className="col-12 form-section-title share-request">
										<h3>Share Lift Request with Other Personnel</h3>
									</div>
									<div className={'col-12'}>
										<label>Emails</label>
										<div className={'static-field-read-only static-textarea'}>
											{data.history[0] &&
												data.history[0].shareEmails &&
												data.history[0].shareEmails.map((rec, i) => {
													const email = `<a href="mailto:${rec}">${rec}</a>`;
													return <span key={i}>{i === 0 ? htmlRender(email) : htmlRender(email)}</span>;
												})}
										</div>
									</div>
								</Row>

								<Row id={'remarks-additional-comments'} className={'form-section row'}>
									<div className="col-12 form-section-title remarks-additional-comments">
										<h3>Remarks/Additional Comments</h3>
									</div>

									<div className={'col-12'}>
										<div className={'col-12 remarks-additional-comments has-info'}>
											<label>{data.history[0] && data.history[0].comments.length > 0 ? 'Previous Comments' : 'No Previous Comments'}</label>
											{data.history[0] &&
												data.history[0].comments.length > 0 &&
												data.history[0].comments.map((comment, i) => {
													return (
														<div className={'col-12'}>
															{comment.commenter && comment.commenter.firstName ? comment.commenter.firstName + ' ' + comment.commenter.lastName : 'Unknown User'}

															<div className={'static-field-read-only static-textarea'}>{comment.text != null ? comment.text : ''}</div>
															<div className={'author-text'}>
																{comment.createdOn != null
																	? new Intl.DateTimeFormat('en-US', {
																			year: 'numeric',
																			month: '2-digit',
																			day: '2-digit',
																			hour: '2-digit',
																			minute: '2-digit',
																			second: '2-digit'
																	  }).format(new Date(stripTimeZone(comment.createdOn)))
																	: ''}
															</div>
														</div>
													);
												})}
										</div>
									</div>
								</Row>
							</PDFExport>
						) : (
							<Loader />
						)}
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LiftRequestView);
