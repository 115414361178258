/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import * as Actions from '../../../store/actions';

import Logout from '../../Auth/Logout';

const HeaderProfile = (props) => {
	const appName = props.appname;
	const appVersion = props.appversion;

	const [profileMenu, setProfileMenu] = useState(false);

	const handleProfileMenu = () => setProfileMenu(!profileMenu);

	return (
		<ul className={`app-header-nav app-header-nav-profile-wrapper ${profileMenu ? 'expanded' : 'collapsed'}`}>
			<li className={'app-header-nav-dropdown'}>
				<button
					className={'app-header-nav-profile'}
					onClick={() => {
						handleProfileMenu();
					}}>
					<i className="fa-solid fa-user"></i>
					<span className={'sr-only'}>Click to View Profile</span>
				</button>
				<div className={'app-header-nav-dropdown-outer'}>
					<div className={'app-header-nav-dropdown-inner'}>
						<div className={'app-header-info'}>
							<div className={'app-header-info-name'}>Name: {`${props.firstname} ${props.lastname}`}</div>
							{/* <div className={"app-header-info-email"}>Email: {user.email}</div> */}
							{/* <div className={"app-header-info-role"}>Role: {userRole}</div> */}
							<div className={'app-header-info-appinfo'}>
								{appName.toUpperCase()} Version: {appVersion}
							</div>
						</div>

						<div className={'button-wrapper'}>
							<NavLink
								className={'btn btn-profile'}
								title={'Click to View Profile'}
								to={`/profile/${props.userid}`}
								onClick={() => {
									handleProfileMenu();
								}}>
								View Profile
							</NavLink>

							<Logout keycloak={props.keycloak} />
						</div>
					</div>
				</div>
			</li>
		</ul>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname,
		theme: state.theme
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderProfile);
