/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import ProfileOverview from './ProfileOverview';
import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

const ProfileTabs = (props) => {
	const [selected, setSelected] = React.useState(0);

	const handleSelect = (e) => {
		setSelected(e.selected);
	};

	return (
		<TabStrip selected={selected} onSelect={handleSelect} id="profile-tabs" className="profile-tabs">
			<TabStripTab eventKey="overview" title="Overview">
				<ProfileOverview />
			</TabStripTab>
		</TabStrip>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname,
		theme: state.theme
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTabs);
