/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../../store/actions';

import {
	Gantt,
	GanttWeekView,
	GanttMonthView,
	GanttDayView,
	GanttYearView,
	filterBy,
	orderBy,
	mapTree,
	extendDataItem
	//GanttRemoveDialog,
} from '@progress/kendo-react-gantt';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { clone, getter } from '@progress/kendo-react-common';
import Loader from '../../Core/Loader';
import { ViewMissionDetail } from '../../Helpers/GanttChartHelpers/ViewMissionDetail';

import { ApiMissionListByState, ApiLegsById } from '../../Helpers/Apis';

import { scheduleDependencyData } from '../../../Assets/json/temp-gantt-multimission';

import { Checkbox } from '@progress/kendo-react-inputs';

const ganttStyle = {
	width: '100%',
	minHeight: '0',
	height: 700
};

const htmlReturn = (text) => {
	return (
		<React.Fragment>
			<i className="fa-solid fa-lock padding-right-3"></i>
			<span>{text}</span>
		</React.Fragment>
	);
};

const titleCell = (props) => {
	// eslint-disable-next-line
	const navigationAttributes = useTableKeyboardNavigation(props.id);
	const onClick = (ev) => {
		props.onExpandChange(ev, props.dataItem, props.level);
	};
	let className = props.dataItem.children && props.dataItem.children.length > 0 ? (props.expanded ? 'k-icon k-font-icon k-i-caret-alt-down' : 'k-icon k-font-icon k-i-caret-alt-right') : 'k-icon';

	return (
		<td className={props.className} aria-colindex={props.ariaColumnIndex} role={'gridcell'} style={props.style} {...navigationAttributes}>
			<span className="expand-cell-wrapping-container">
				{[...Array(props.level.length - 1)].map((e, i) => (
					<span key={i} className="k-treelist-toggle k-icon k-svg-icon" role="presentation"></span>
				))}
				<span onClick={onClick} data-prevent-selection="true" className={className} role="presentation"></span>

				<span> {props.dataItem.title}</span>
			</span>
		</td>
	);
};

const taskModelFields = {
	id: 'id',
	start: 'start',
	end: 'end',
	title: 'title',
	squadron: 'squadron',
	tailnumber: 'tailnumber',
	plausibility: 'plausibility',
	percentComplete: 'percentComplete',
	isRollup: 'isRollup',
	isExpanded: 'isExpanded',
	isInEdit: 'isInEdit',
	children: 'children'
};

const dependencyModelFields = {
	id: 'id',
	fromId: 'fromId',
	toId: 'toId',
	type: 'type'
};

const getTaskId = getter(taskModelFields.id);

let columns = [
	{
		field: taskModelFields.title,
		title: 'Missions',
		cell: titleCell,
		width: 170,
		expandable: true,
		show: true
	},
	{
		field: taskModelFields.start,
		title: 'Start',
		width: 140,
		format: '{0:MM/dd/yyyy HH:MM}',
		show: true
	},
	{
		field: taskModelFields.end,
		title: 'End',
		width: 140,
		format: '{0:MM/dd/yyyy HH:MM }',
		show: true
	}
];

const MultiMissionGanttChart = (props) => {
	const [taskData, setTaskData] = React.useState([]);
	const [dependencyData] = React.useState(scheduleDependencyData);
	const [expandedState, setExpandedState] = React.useState([]);
	const [, setSelectedIdState] = React.useState(null);
	const [missionLoaded, setMissionLoaded] = React.useState(false);
	const [editItem, setEditItem] = React.useState(null);
	const [refVisible, setRefVisible] = React.useState(false);
	const [ganttKey, setGanttKey] = React.useState(0);

	const ganttRef = React.useRef();

	if (props.environment === 'sandbox') {
		columns = [
			{
				field: taskModelFields.title,
				title: 'Missions',
				cell: titleCell,
				width: 170,
				expandable: true,
				show: true
			},
			{
				field: taskModelFields.start,
				title: 'Start',
				width: 140,
				format: '{0:MM/dd/yyyy HH:MM}',
				show: true
			},
			{
				field: taskModelFields.end,
				title: 'End',
				width: 140,
				format: '{0:MM/dd/yyyy HH:MM }',
				show: true
			},
			{
				field: taskModelFields.plausibility,
				title: 'Plausible',
				width: 75,
				show: true
			}
		];
	} else {
		columns = [
			{
				field: taskModelFields.title,
				title: 'Missions',
				cell: titleCell,
				width: 170,
				expandable: true,
				show: true
			},
			{
				field: taskModelFields.start,
				title: 'Start',
				width: 140,
				format: '{0:MM/dd/yyyy HH:MM}',
				show: true
			},
			{
				field: taskModelFields.end,
				title: 'End',
				width: 140,
				format: '{0:MM/dd/yyyy HH:MM }',
				show: true
			}
		];
	}

	const queryparams = props.queryparams;
	const [colState, setColState] = React.useState(columns);

	const toggleColumn = (id) => {
		setColState(
			colState.map((column, idx) => {
				if (idx === id) {
					column.show = !column.show;
				}
				return column;
			})
		);

		// Update the Gantt key to force a re-render
		setGanttKey(ganttKey + 1);
	};

	/*
    const [columnsState, setColumnsState] = React.useState(columns);
    const onColumnResize = React.useCallback(
        (event) => event.end && setColumnsState(event.columns),
        [setColumnsState]
    );

    const onColumnReorder = React.useCallback(
        (event) => setColumnsState(event.columns),
        [setColumnsState]
    );
    */

	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: 'orderId',
				dir: 'asc'
			}
		],
		filter: []
	});

	const onDataStateChange = React.useCallback(
		(event) =>
			setDataState({
				sort: event.dataState.sort,
				filter: event.dataState.filter
			}),
		[setDataState]
	);

	const onExpandChange = React.useCallback(
		(event) => {
			const id = getTaskId(event.dataItem);
			const newExpandedState = event.value ? expandedState.filter((currentId) => currentId !== id) : [...expandedState, id];
			setExpandedState(newExpandedState);
		},
		[expandedState, setExpandedState]
	);

	const onSelect = React.useCallback((event) => setSelectedIdState(getTaskId(event.dataItem)), [setSelectedIdState]);

	const onEdit = React.useCallback((event) => setEditItem(clone(event.dataItem)), [setEditItem]);

	// keeping this since customer requested edit capibilty in the future
	// const onFormSubmit = React.useCallback(
	// 	(event) => {
	// 		const newData = updateTask({
	// 			updatedDataItem: event.dataItem,
	// 			taskModelFields: taskModelFields,
	// 			dataTree: taskData
	// 		});
	// 		setEditItem(null);
	// 		setTaskData(newData);
	// 	},
	// 	[taskData, setTaskData, setEditItem]
	// );

	const onFormCancel = React.useCallback(() => setEditItem(null), [setEditItem]);

	const processedData = React.useMemo(() => {
		const filteredData = filterBy(taskData, dataState.filter, taskModelFields.children);
		const sortedData = orderBy(filteredData, dataState.sort, taskModelFields.children);

		return mapTree(sortedData, taskModelFields.children, (task) =>
			extendDataItem(task, taskModelFields.children, {
				[taskModelFields.isExpanded]: expandedState.includes(getTaskId(task))
			})
		);
	}, [taskData, dataState, expandedState]);

	useEffect(() => {
		ApiMissionListByState(queryparams).then((res) => {
			if (props.environment === 'sandbox') {
				res = props.data;
			}
			var newRawMissionList = res;

			const jsonArray = [];
			var missionOrderValue = 0;

			// eslint-disable-next-line
			newRawMissionList.map((rawMission, i) => {
				var mission = rawMission.history[0];
				if (mission.legs.length > 0) {
					const mId = rawMission.id;
					const missionName = rawMission.missionName;
					const tailNumber = mission.asset && mission.asset.tailNumber ? mission.asset.tailNumber : '';

					let propabilityPercentage = '';
					let probabilityPlausibility = '';
					let stringPlausibility = '';
					let schaplyPngString = '';

					if (props.environment === 'sandbox') {
						propabilityPercentage = rawMission.simulationData && rawMission.simulationData[0] && rawMission.simulationData[0].probability ? rawMission.simulationData[0].probability : '';
						probabilityPlausibility = rawMission.simulationData && rawMission.simulationData[0] && rawMission.simulationData[0].prediction ? rawMission.simulationData[0].prediction : '';
						schaplyPngString = rawMission.schaplyData ? rawMission.schaplyData : '';
					}

					if (probabilityPlausibility === 'Will fly') {
						stringPlausibility = 'YES';
					} else {
						stringPlausibility = 'NO';
					}

					var start = null;
					var end = null;
					var legArray = [];
					var cargoPalletTotal = 0;
					var cargoPaxTotal = 0;
					var numLegsLocked = 0;
					var totalNumLegs = 0;

					var badRecord = false;
					var missionTitle = '';

					if (rawMission.missionName === null) {
						missionTitle = mId;
					} else {
						missionTitle = rawMission.missionName;
					}

					var missionObj = {
						id: mId,
						missionName: missionName,
						missionId: mId,
						title: missionTitle,
						squadron: null,
						tailnumber: tailNumber,
						plausibility: stringPlausibility,
						isExpanded: false
					};

					ApiLegsById(mId).then((legs) => {
						if (legs.length > 0) {
							var orderValue = 1;

							// eslint-disable-next-line
							legs.map((rawLeg, n) => {
								var palletInfo = [];
								var paxInfo = [];
								var cargoesArray = [];

								var legCargoPalletTotal = 0;
								var legCargoPaxTotal = 0;

								var leg = rawLeg.history[0];
								var from = leg.departure !== undefined && leg.departure !== null && leg.departure.airport !== undefined && leg.departure.airport !== null && leg.departure.airport.icao !== undefined ? leg.departure.airport.icao : '';
								var to = leg.arrival !== undefined && leg.arrival !== null && leg.arrival.airport !== undefined && leg.arrival.airport !== null && leg.arrival.airport.icao !== undefined ? leg.arrival.airport.icao : '';
								var legStart = leg.departure !== undefined && leg.departure !== null ? new Date(leg.departure.planned) : '';
								var legEnd = leg.arrival !== undefined && leg.arrival !== null ? new Date(leg.arrival.planned) : '';
								var legStartLat = leg.departure !== undefined && leg.departure !== null && leg.departure.airport !== undefined && leg.departure.airport !== null ? leg.departure.airport.latitude : '';
								var legStartLon = leg.departure !== undefined && leg.departure !== null && leg.departure.airport !== undefined && leg.departure.airport !== null ? leg.departure.airport.longitude : '';
								var legEndLat = leg.arrival !== undefined && leg.arrival !== null && leg.arrival.airport !== undefined && leg.arrival.airport !== null ? leg.arrival.airport.latitude : '';
								var legEndLon = leg.arrival !== undefined && leg.arrival !== null && leg.arrival.airport !== undefined && leg.arrival.airport !== null ? leg.arrival.airport.longitude : '';
								var legAssetId = leg.assetId !== undefined && leg.assetId !== null ? leg.assetId : '';
								var legSatisfiesLiftRequests = leg.satisfiesLiftRequests !== undefined && leg.satisfiesLiftRequests !== null ? leg.satisfiesLiftRequests : '';
								var titleString = from + ' to ' + to;
								var legState = leg.state !== null ? leg.state : null;

								if (legState === 'LOCKED') {
									numLegsLocked += 1;
								}

								totalNumLegs += 1;

								if (leg.cargoes !== null && leg.cargoes !== undefined && leg.cargoes.length > 0) {
									// eslint-disable-next-line
									leg.cargoes.map((cargo) => {
										if (cargo.type === 'PASSENGERS') {
											cargoPaxTotal += cargo.count;
											legCargoPaxTotal += cargo.count;
											paxInfo.push(cargo);
											cargoesArray.push(cargo);
										}
										if (cargo.type === 'PALLETS') {
											cargoPalletTotal += cargo.count;
											legCargoPalletTotal += cargo.count;
											palletInfo.push(cargo);
											cargoesArray.push(cargo);
										}
									});
								}

								let palletGridArray = [];
								// eslint-disable-next-line
								palletInfo.map((pallet, i) => {
									pallet['EntryID'] = i + 1;
									pallet['PalletName'] = pallet.cargoName ? pallet.cargoName : i + 1;
									if (pallet.hazmat && pallet.hazmat.containsHazmat && pallet.hazmat.hazmatCargoes) {
										let hazmatArray = [];
										// eslint-disable-next-line
										pallet.hazmat.hazmatCargoes.map((hazmat, n) => {
											hazmat['EntryID'] = n + 1;
											hazmat['PalletName'] = hazmat.hazmatName ? hazmat.hazmatName : n + 1;
											hazmatArray.push(hazmat);
										});
										pallet.hazmatCargoes = hazmatArray;
									}
									if (pallet.hazmat === null) palletGridArray.push(pallet);
								});

								if (from === '' || to === '' || legStart === '' || legEnd === '' || legStartLat === '' || legStartLon === '' || legEndLat === '' || legEndLon === '') {
									badRecord = true;
								}

								const legObj = {
									id: leg.id,
									missionId: mId,
									missionName: missionName,
									title: legState === 'LOCKED' ? htmlReturn(titleString) : titleString,
									orderId: orderValue,
									squadron: null,
									tailNumber: tailNumber,
									start: legStart,
									end: legEnd,
									percentComplate: 0.0,
									isExpanded: false,
									isLocked: true,
									locationFrom: from,
									locationTo: to,
									fromLat: legStartLat,
									fromLon: legStartLon,
									toLat: legEndLat,
									toLon: legEndLon,
									cargoPalletInfo: palletGridArray,
									cargoPaxInfo: paxInfo,
									assetId: legAssetId,
									satisfiesLiftRequests: legSatisfiesLiftRequests,
									cargoPalletTotal: legCargoPalletTotal,
									cargoPaxTotal: legCargoPaxTotal,
									cargoes: cargoesArray,
									state: legState
								};

								if (!badRecord) {
									legArray.push(legObj);
								}

								// if FIRST leg, set departure to START date
								if (n === 0) {
									start = leg.departure !== undefined && leg.departure !== null ? new Date(leg.departure.planned) : '';
								}

								// if LAST leg, set arrival to END date
								if (n === legs.length - 1) {
									end = leg.arrival !== undefined && leg.arrival !== null ? new Date(leg.arrival.planned) : '';
								}

								//if NOT last leg in list, then create a STOP obj
								if (n !== legs.length - 1) {
									orderValue += 1;
									var stopEnd = legs[n + 1].history[0].departure !== undefined && legs[n + 1].history[0].departure !== null && legs[n + 1].history[0].departure.planned !== undefined && legs[n + 1].history[0].departure.planned !== null ? new Date(legs[n + 1].history[0].departure.planned) : '';

									const stopObj = {
										id: Math.floor(1000 + Math.random() * 900000),
										title: 'STOP',
										orderId: orderValue,
										start: legEnd,
										end: stopEnd,
										percentComplete: 1.0,
										isExpanded: false,
										isLocked: true,
										stopLocation: to,
										stopLat: legEndLat,
										stopLon: legEndLon,
										details: 'Stopped.'
									};
									if (!badRecord) {
										legArray.push(stopObj);
									}
								}
								orderValue += 1;
							});

							missionObj.children = legArray;
							missionObj.percentComplete = numLegsLocked / totalNumLegs;
							missionObj.start = start;
							missionObj.end = end;
							missionObj.cargoPalletTotal = cargoPalletTotal;
							missionObj.cargoPaxTotal = cargoPaxTotal;
							missionObj.propabilityPercentage = propabilityPercentage;
							missionObj.probabilityPlausibility = probabilityPlausibility;
							missionObj.schaplyPngString = schaplyPngString;

							if (!badRecord) {
								missionObj.orderId = missionOrderValue;
								missionOrderValue += 1;

								setTaskData([...jsonArray, missionObj]);

								jsonArray.push(missionObj);
							}
						}
					});
				}
			});
			setMissionLoaded(true);
		});
	}, []);

	useEffect(() => {
		if (!refVisible) {
			return;
		}
		// detected rendering

		let todayCell = ganttRef.current.element.getElementsByClassName('today_cell')[0];
		let scrollElement = ganttRef.current.element.getElementsByClassName('k-treelist-scrollable')[0];
		let columnsElement = ganttRef.current.element.getElementsByClassName('k-gantt-columns')[0];

		if (todayCell && scrollElement && columnsElement) {
			scrollElement.scroll({
				left: todayCell.getBoundingClientRect().left - scrollElement.getBoundingClientRect().left - columnsElement.getBoundingClientRect().left + 20
			});
		}
	}, [refVisible, taskData]);

	const scrollToDateWeek = () => {
		if (!refVisible) {
			return;
		}
		let todayCell = ganttRef.current.element.getElementsByClassName('today_cell')[0];
		let scrollElement = ganttRef.current.element.getElementsByClassName('k-treelist-scrollable')[0];
		let columnsElement = ganttRef.current.element.getElementsByClassName('k-gantt-columns')[0];

		if (todayCell && scrollElement && columnsElement) {
			scrollElement.scroll({
				left: todayCell.getBoundingClientRect().left - scrollElement.getBoundingClientRect().left - columnsElement.getBoundingClientRect().left + 20
			});
		}
	};

	const weekHeaderCell = (props) => {
		if (ganttRef.current !== undefined) {
			let range = props.range;
			let today = new Date();
			if (props.type === 'day' && range.start <= today && range.end > today) {
				return (
					<p className={'today_cell fontsize-14px'} style={{ background: 'orange', padding: '0' }}>
						*{props.text}
					</p>
				);
			} else {
				return (
					<p className={'fontsize-14px'} style={{ padding: '0' }}>
						{props.text}
					</p>
				);
			}
		}
	};

	const DayHeaderCell = (props) => {
		if (ganttRef.current !== undefined) {
			let range = props.range;
			let today = new Date();
			if (props.type === 'time' && range.start <= today && range.end > today) {
				return (
					<p className={'today_cell fontsize-14px'} style={{ background: 'orange', padding: '0' }}>
						*{props.text}
					</p>
				);
			} else {
				return (
					<p className={'fontsize-14px'} style={{ padding: '0' }}>
						{props.text}
					</p>
				);
			}
		}
	};

	const MonthHeaderCell = (props) => {
		if (ganttRef.current !== undefined) {
			let range = props.range;
			let today = new Date();
			if (props.type === 'week' && range.start <= today && range.end > today) {
				return (
					<p className={'today_cell fontsize-14px'} style={{ background: 'orange', padding: '0' }}>
						*{props.text}
					</p>
				);
			} else {
				return (
					<p className={'fontsize-14px'} style={{ padding: '0' }}>
						{props.text}
					</p>
				);
			}
		}
	};

	const YearHeaderCell = (props) => {
		if (ganttRef.current !== undefined) {
			let range = props.range;
			let today = new Date();
			if (props.type === 'year' && range.start <= today && range.end > today) {
				return (
					<p className={'today_cell fontsize-14px'} style={{ background: 'orange', padding: '0' }}>
						*{props.text}
					</p>
				);
			} else {
				return (
					<p className={'fontsize-14px'} style={{ padding: '0' }}>
						{props.text}
					</p>
				);
			}
		}
	};

	const multimissiongantt = () => {
		return (
			<React.Fragment>
				<div className="multi-mission-columns" style={{ position: 'absolute', top: '11px', left: '10px', zIndex: 9 }}>
					{columns.map((column, idx) => (
						<div key={idx} style={{ marginRight: '10px', display: 'block', float: 'left' }}>
							<Checkbox
								id={idx.toString()}
								className="checkbox"
								defaultChecked={colState[idx].show}
								disabled={(() => {
									let visibleColumns = 0;
									for (let i = 0; i < colState.length; i++) {
										if (colState[i].show === true) {
											visibleColumns++;
										}
									}
									if (visibleColumns === 1 && colState[idx].show === true) {
										return true;
									}
									return false;
								})()}
								type="checkbox"
								onClick={() => {
									toggleColumn(idx);
								}}
							/>
							<label htmlFor={idx} className="k-checkbox-label">
								Show {column.title}
							</label>
						</div>
					))}
				</div>

				<Gantt
					className={'multi-mission-gantt-chart'}
					ref={(el) => {
						ganttRef.current = el;
						setRefVisible(!!el);
					}}
					key={ganttKey}
					style={ganttStyle}
					taskData={processedData}
					taskModelFields={taskModelFields}
					dependencyData={dependencyData}
					dependencyModelFields={dependencyModelFields}
					columns={colState.filter((col) => {
						return col.show;
					})}
					reorderable={true}
					sortable={true}
					sort={dataState.sort}
					filter={dataState.filter}
					navigatable={true}
					onExpandChange={onExpandChange}
					onDataStateChange={onDataStateChange}
					toolbar={{
						addTaskButton: false
					}}
					onTaskClick={onSelect}
					onRowClick={onSelect}
					onTaskDoubleClick={onEdit}
					onRowDoubleClick={onEdit}>
					<GanttWeekView onClick={scrollToDateWeek()} timelineHeaderCell={weekHeaderCell} />
					<GanttDayView timelineHeaderCell={DayHeaderCell} />
					<GanttMonthView timelineHeaderCell={MonthHeaderCell} />
					<GanttYearView timelineHeaderCell={YearHeaderCell} />
				</Gantt>
				{editItem && <ViewMissionDetail item={editItem} onCancel={onFormCancel} environment={props.environment} />}
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			{missionLoaded ? (
				<div id={'schedule-gantt-view'} className={'mission-schedule-output gantt-view mission-selected'}>
					<div className={'multimission-schedule-inner'}>{multimissiongantt()}</div>
				</div>
			) : (
				<Loader />
			)}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiMissionGanttChart);
