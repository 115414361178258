/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

/**
 * Departments and Agencies List
 * Possible Source: https://www.usa.gov/agency-index
 * @type {(string|*)[]}
 */
export const optionsDepartmentOrAgency = ['Navy', 'Marine Corps', 'Coast Guard', 'Air Force', 'Army', 'Space Force', 'Other Dept. of Defense', 'Other US Federal Government', 'Other'];
