/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import axios from 'axios';
import actions from '../../store/actions';
import * as helper from './Apis';

// API Contract
// ASSETS: https://drive.google.com/file/d/1_8C-N8G7GA3NBaUDFkqN8MHDIPHnVGW3/view?usp=share_link

/**
 * Get Lift Request List
 * Method: GET
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/
 * @constructor
 */
export const ApiLiftRequestList = (params) => {
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request' + params;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise
		.then((response) => response.data)
		.catch((error) => {
			console.log(error.message);
		});

	return dataPromise;
};

/**
 * Get Data for an Existing Lift Request by ID
 * Method: GET
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/ + recordId
 * @constructor
 */
export const ApiLiftRequestById = (id) => {
	const recId = id;
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Create New List Request from Wizard/Long form
 * Method: POST
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/
 * @constructor
 */
export const ApiNewLiftRequestForm = (data) => {
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request/';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Create New List Request from Wizard/Long form (IMPORT CSV DATA FOR BULK UPLOAD)
 *  ******************************************************************************************
 * Method: POST
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/importCsv
 * @constructor
 */
export const ApiUploadLiftRequestData = (data) => {
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request/importCsv';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data'
		}
	};

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Create New Lift Request
 * Method: POST
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/
 * @constructor
 */
export const ApiNewLiftRequest = () => {
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request/';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const data = JSON.stringify({
		priority: '1',
		requestName: "Dylan's Sample Test",
		state: 'SUBMITTED',
		cargoes: [
			{
				type: 'PASSENGERS',
				count: 20,
				weight: null,
				description: null,
				specialHandlingRequirements: null
			}
		]
	});

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Update an Existing Lift Request by ID with data from form
 * Method: PUT
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/ + recordId
 * @constructor
 */
export const ApiUpdateLiftRequestByIdForm = (id, data) => {
	const recId = id;
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};
	console.log('in api');
	console.log(data);
	console.log(id);
	console.log(path);

	const promise = axios.put(path, data, config);
	const dataPromise = promise.then((response) => response.data);
	console.log(dataPromise);

	return dataPromise;
};

/**
 * Update a lift request's validation history by ID with data from form
 * Method: PUT
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/ + recordId + /validationHistory
 * @constructor
 */
export const ApiUpdateLiftRequestValidationHistoryByIdForm = (id, data) => {
	const recId = id;
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request/' + recId + '/validationHistory';

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};
	const promise = axios.put(apiUrl, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Update an Existing Lift Request by ID
 * Method: PUT
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/ + recordId
 * @constructor
 */
export const ApiUpdateLiftRequestById = (id) => {
	const recId = id;
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const data = {
		state: 'AUTHORIZED',
		requester: {
			id: '97df8219-dee2-4fd7-960c-d182d7fd60c7',
			firstName: 'Navy',
			lastName: 'Requester',
			email: 'navy.requester@airlift.solutions',
			phoneNumber: '571-123-4532',
			rank: 'RJ6',
			dutyTitle: 'Operations',
			uic: 'w465bn7b6e',
			branchOfService: null
		},
		requestName: 'HAZMAT to TX',
		priority: 1,
		missionDescription: 'Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat.',
		justification: 'Nam vehicula quis velit sed fermentum. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat.',
		departure: {
			airport: {
				id: 638,
				icao: 'KNFW',
				iata: 'FWH',
				name: 'NAS Fort Worth JRB / Carswell Field',
				country: { code: 'US' },
				latitude: 32.7692,
				longitude: -97.4415,
				status: null
			},
			earliest: '2023-04-03T12:45:00Z',
			latest: '2023-04-07T00:00:00Z',
			planned: null,
			actual: null,
			alternateAirports: [
				{
					id: 0,
					icao: 'KMSY',
					iata: 'MSY',
					name: 'Louis Armstrong New Orleans International Airport',
					latitude: 29.9852,
					longitude: -90.2573,
					status: 'ACTIVE'
				}
			],
			poc: {
				primary: {
					firstName: 'Jimmy',
					lastName: 'Jackson',
					email: 'jimmy.jackson@navy.mil',
					phoneNumber: '323-123-3213',
					rank: 'V5G',
					dutyTitle: 'Consultant',
					uic: '123456',
					branchOfService: 'Marines'
				},
				alternate: {
					firstName: 'David',
					lastName: 'Carter',
					email: 'david.carter@airforce.mil',
					phoneNumber: '985-796-3820',
					rank: '2P2',
					dutyTitle: 'Specialist',
					uic: '654321',
					branchOfService: 'Air Force'
				}
			}
		},
		arrival: {
			airport: {
				id: 163,
				icao: 'KBLV',
				iata: 'BLV',
				name: 'MidAmerica St. Louis Airport / Scott Air Force Base',
				country: { code: 'US' },
				latitude: 38.5452,
				longitude: -89.8352,
				status: null
			},
			earliest: '2023-04-03T04:00:00Z',
			latest: '2023-04-06T00:00:00Z',
			planned: null,
			actual: null,
			alternateAirports: [
				{
					id: 0,
					icao: 'KLAX',
					iata: 'LAX',
					name: 'Los Angeles International Airport',
					latitude: 29.9852,
					longitude: -90.2573,
					status: 'ACTIVE'
				}
			],
			poc: {
				primary: {
					firstName: 'Roberto',
					lastName: 'Robertson',
					email: 'roberto@robertson.com',
					phoneNumber: '123-435-1928',
					rank: 'V5G',
					dutyTitle: 'Consultant',
					uic: '123456',
					branchOfService: 'Marines'
				},
				alternate: {
					firstName: 'Rebecca',
					lastName: 'Johnson',
					email: 'rebecca@johnson.com',
					phoneNumber: '985-796-3820',
					rank: '2P2',
					dutyTitle: 'Specialist',
					uic: '654321',
					branchOfService: 'Air Force'
				}
			}
		},
		cargoes: [
			{
				cargoId: '017f7698-c5bb-44ce-bc50-e2f0a760dff5',
				type: 'PALLETS',
				count: 4,
				weight: 0,
				description: 'Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. uspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. uspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat.',
				totalCubicFeet: 125,
				largestItem: {
					length: 50,
					width: 75,
					height: 25,
					weight: 750
				},
				heaviestItem: {
					length: 90,
					width: 20,
					height: 145,
					weight: 7250
				},
				oversize: true,
				rollingStock: true,
				hazmat: {
					containsHazmat: true,
					unNumber: 0,
					shippingName: 'Special Excellence HAZMAT Material',
					shippingDescription: 'Nulla id mollis massa. Nulla quis neque ut leo volutpat aliquet. Phasellus sit amet auctor tellus. Donec et ultricies magna. Sed ac turpis suscipit, dapibus velit eget, tincidunt magna. uspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat.',
					hazardClassAndDivision: 350,
					passengerEligibilityCode: null,
					cargo: {
						length: 125,
						width: 375,
						height: 50,
						weight: 12500
					},
					netExplosiveWeight: 725
				},
				specialHandlingRequirements: 'Fusce maximus hendrerit est, eu scelerisque urna tincidunt at. Vestibulum sit amet urna non nunc laoreet dapibus nec eu dolor. Mauris rutrum augue nibh, at imperdiet eros congue sed. uspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. uspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat.',
				seniorTraveler: {
					email: 'rhonda.williams@navy.mil'
				},
				connection_time: null
			}
		],
		commercialCost: 123456789.25,
		shareEmails: ['bob@smith.com', 'richard@marley.net'],
		comments: [
			{
				commentId: 'rtye-7unt8i8nr-cewtsrv',
				commenter: {
					firstName: 'Russell',
					lastName: 'Stafford',
					email: 'russel@stafford.com',
					phoneNumber: '9850796-7339',
					rank: 'PP1',
					dutyTitle: 'GP2',
					uic: '9218734674',
					branchOfService: 'Marines'
				},
				createdOn: '2022-03-10T16:15:50Z',
				text: 'This is an example comment to describe an awesome shipping description that describes the description that is used for testing descriptions. This is being tested to make sure that it is a good test. This is an example description to describe an awesome shipping description that describes the description that is used for testing descriptions. This is being tested to make sure that it is a good test. uspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat. Suspendisse interdum nisi eu ex accumsan, non ultrices dolor volutpat.'
			}
		],
		version: 1,
		createdAt: '2023-03-21T17:29:29.357Z',
		autoValidated: false,
		urgency: 2,
		commandingOfficer: null
	};

	const promise = axios.put(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Update State of Existing Lift Request by ID
 * Method: PUT
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/lift-request/"
 * @constructor
 */
export const ApiChangeLiftRequestState = (id, newstate) => {
	const recId = id;
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request/';
	const path = apiUrl + recId;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${actions.getToken()}`
	};

	return new Promise((resolve, reject) => {
		helper
			.ApiLiftRequestById(id)
			.then((res) => {
				let data = res.history[0];
				data.state = newstate;
				return axios.put(path, data, { headers: headers });
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/**
 * Submit to Generate Mission
 * Method: POST
 * Endpoint URI: https://mission.dev.airlift.solutions/request/
 * @constructor
 */
export const ApiMissionGenRequest = (obj, queryParam) => {
	let apiUrl = '';
	if (queryParam === undefined) {
		apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/request';
	} else {
		apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/request' + queryParam;
	}

	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, obj, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Submit to Create Mission
 * Method: POST
 * Endpoint URI: https://mission.dev.airlift.solutions/mission/
 * @constructor
 */
export const ApiCreateMission = (obj) => {
	let apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/mission/';

	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, obj, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Edit Mission
 * Method: PUT
 * Endpoint URI: https://mission.dev.airlift.solutions/mission/
 * @constructor
 */
export const ApiEditMission = (obj, missionId) => {
	let apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/mission/' + missionId;

	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.put(path, obj, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Mission by ID
 * Method: GET
 * Endpoint URI: https://mission.dev.airlift.solutions/request/ID
 * @constructor
 */
export const ApiGetMissionById = (id) => {
	const recId = id;
	const apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/mission/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Mission Request by ID
 * Method: GET
 * Endpoint URI: https://mission.dev.airlift.solutions/request/ID
 * @constructor
 */
export const ApiMissionById = (id) => {
	const recId = id;
	const apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/request/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Mission Requests
 * Method: GET
 * Endpoint URI: https://mission.dev.airlift.solutions/request
 * @constructor
 */
export const ApiMissionRequestList = () => {
	const apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/request';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Mission Request errors
 * Method: GET
 * Endpoint URI: https://mission.dev.airlift.solutions/error
 * @constructor
 */
export const ApiMissionRequestErrorsList = () => {
	const apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/error';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Mission Request Error by Mission Request ID
 * Method: POST
 * Endpoint URI: https://mission.dev.airlift.solutions/mission/ID
 * @constructor
 */
export const ApiMissionRequestErrorByRequestId = (id) => {
	let queryParam = `?missionsRequestId=${id}`;
	const apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/error/';
	const path = apiUrl + queryParam;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Mission by ID
 * Method: POST
 * Endpoint URI: https://mission.dev.airlift.solutions/mission/ID
 * @constructor
 */
export const ApiMissionByIdHelper = (id) => {
	const recId = id;
	const apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/mission/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Mission List
 * Method: GET
 * Endpoint URI: https://mission.dev.airlift.solutions/mission
 * @constructor
 */
export const ApiMissionList = () => {
	const apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/mission';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Mission List by STATE
 * Method: GET
 * Endpoint URI: https://mission.dev.airlift.solutions/mission
 * @constructor
 */
export const ApiMissionListByState = (params) => {
	const apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/mission' + params;
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Update existing Mission by ID
 * Method: PUT
 * Endpoint URI: https://mission.dev.airlift.solutions/mission/ID"
 * @constructor
 */
export const ApiUpdateMission = (id, data) => {
	const recId = id;
	const apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/mission/';
	const path = apiUrl + recId;
	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};
	const promise = axios.put(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Update State of Existing Mission by ID
 * Method: PUT
 * Endpoint URI: https://mission.dev.airlift.solutions/mission/"
 * @constructor
 */
export const ApiChangeMissionState = (id, newstate) => {
	const recId = id;
	const apiUrl = 'https://mission.' + process.env.REACT_APP_API_BASE_DOMAIN + '/mission/';
	const path = apiUrl + recId;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${actions.getToken()}`
	};

	return new Promise((resolve, reject) => {
		helper
			.ApiMissionByIdHelper(id)
			.then((res) => {
				let data = res.history[0];
				data.state = newstate;
				return axios.put(path, data, { headers: headers });
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/**
 * Get Airport List
 * Method: GET
 * Endpoint URI: https://airport.dev.airlift.solutions/airport
 * @constructor
 */
export const ApiAirportsList = (param) => {
	let apiUrl = '';
	if (param !== undefined) {
		apiUrl = 'https://airport.' + process.env.REACT_APP_API_BASE_DOMAIN + '/airport' + param;
	} else {
		apiUrl = 'https://airport.' + process.env.REACT_APP_API_BASE_DOMAIN + '/airport';
	}

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Create New Airport
 * Method: POST
 * Endpoint URI: https://airport.dev.airlift.solutions/airport/
 * @constructor
 */
export const ApiNewAirportRequest = (obj) => {
	const apiUrl = 'https://airport.' + process.env.REACT_APP_API_BASE_DOMAIN + '/airport';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const data = obj;

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Airport by ID
 * Method: GET
 * Endpoint URI: https://airport.dev.airlift.solutions/airport
 * @constructor
 */
export const ApiAirportById = (id) => {
	const apiUrl = 'https://airport.' + process.env.REACT_APP_API_BASE_DOMAIN + '/airport/' + id;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Edit Existing Airport
 * Method: PUT
 * Endpoint URI: https://airport.dev.airlift.solutions/airport/
 * @constructor
 */
export const ApiEditAirportRequest = (id, data) => {
	const recId = id;
	const apiUrl = 'https://airport.' + process.env.REACT_APP_API_BASE_DOMAIN + '/airport/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.put(path, data, config);
	const dataPromise = promise.then((response) => response.data);
	return dataPromise;
};

/**
 * DELETE Existing Airport
 * Method: DELETE
 * Endpoint URI: https://airport.dev.airlift.solutions/airport/
 * @constructor
 */
export const ApiDeleteAirportRequest = (id) => {
	const recId = id;
	const apiUrl = 'https://airport.' + process.env.REACT_APP_API_BASE_DOMAIN + '/airport';
	const path = apiUrl + '/' + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const data = '';

	const promise = axios.delete(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * GET Airport Type List
 * Method: GET
 * Endpoint URI: https://airport.dev.airlift.solutions/type/
 * @constructor
 */
export const ApiGetAirportTypeRequest = () => {
	const apiUrl = 'https://airport.' + process.env.REACT_APP_API_BASE_DOMAIN + '/type';

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Organization Squadron List
 * Method: GET
 * Endpoint URI: https://organization.dev.airlift.solutions/organization/squadron
 * @constructor
 */
export const ApiOrgSquadronList = () => {
	const apiUrl = 'https://organization.' + process.env.REACT_APP_API_BASE_DOMAIN + '/organization/squadron';

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Organization Unit List
 * Method: GET
 * Endpoint URI: https://organization.dev.airlift.solutions/organization/unit
 * @constructor
 */
export const ApiOrgUnitList = () => {
	const apiUrl = 'https://organization.' + process.env.REACT_APP_API_BASE_DOMAIN + '/organization/unit';

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

//TODO IMPLEMENT UNIT LIST FILTERING
/**
 * Get Organization Unit List
 * Method: GET
 * Endpoint URI: https://organization.dev.airlift.solutions/organization/unit/filtered
 * @constructor
 */
export const ApiOrgUnitListFiltered = (input) => {
	const apiUrl = 'https://organization.' + process.env.REACT_APP_API_BASE_DOMAIN + '/organization/unit/filtered/' + input;
	//const apiUrl = "http://localhost:8383/organization/unit/filtered/123"
	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Paged Organization Unit List
 * Method: GET
 * Endpoint URI: https://organization.dev.airlift.solutions/organization/unit/paged
 * @constructor
 */
export const ApiOrgUnitListPaged = (pageNum, pageSize) => {
	const apiUrl = 'https://organization.' + process.env.REACT_APP_API_BASE_DOMAIN + '/organization/unit?pageNum=' + pageNum + '&pageSize=' + pageSize;
	//const apiUrl = "http://localhost:8383/organization/unit?pageNum=" + pageNum + "&pageSize=" + pageSize;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Organization Unit List Count
 * Method: GET
 * Endpoint URI: https://organization.dev.airlift.solutions/organization/unit/count
 * @constructor
 */
export const ApiOrgUnitListCount = () => {
	const apiUrl = 'https://organization.' + process.env.REACT_APP_API_BASE_DOMAIN + '/organization/unit/count';
	//const apiUrl = "http://localhost:8383/organization/unit/count"

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Assets List
 * Method: GET
 * Endpoint URI: https://asset.dev.airlift.solutions/asset
 * @constructor
 */
export const ApiAssetsList = (param) => {
	// Status codes:
	// MX: maintenance
	// TK: tasked
	// Example: const apiUrl = "https://asset.dev.airlift.solutions/asset?statusCode=MX&ownerOrg=0";
	let apiUrl = '';
	if (param === undefined) {
		apiUrl = 'https://asset.' + process.env.REACT_APP_API_BASE_DOMAIN + '/asset';
	} else {
		apiUrl = 'https://asset.' + process.env.REACT_APP_API_BASE_DOMAIN + '/asset' + param;
	}

	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Priority List
 * Method: GET
 * Endpoint URI: https://priority.dev.airlift.solutions/priority
 * @constructor
 */
export const ApiPriorityList = () => {
	const apiUrl = 'https://priority.' + process.env.REACT_APP_API_BASE_DOMAIN + '/priority';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Asset by ID
 * Method: GET
 * Endpoint URI: https://asset.dev.airlift.solutions/asset
 * @constructor
 */
export const ApiAssetById = (id) => {
	const apiUrl = 'https://asset.' + process.env.REACT_APP_API_BASE_DOMAIN + '/asset/' + id;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Asset(s) by Squadron ID
 * Method: GET
 * Endpoint URI: https://asset.dev.airlift.solutions/asset
 * @constructor
 */
export const ApiAssetBySquadronId = (id) => {
	const apiUrl = 'https://asset.' + process.env.REACT_APP_API_BASE_DOMAIN + '/asset/?squadronId=' + id;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Create New Asset
 * Method: POST
 * Endpoint URI: https://asset.dev.airlift.solutions/asset/
 * @constructor
 */
export const ApiNewAssetRequest = (obj) => {
	const apiUrl = 'https://asset.' + process.env.REACT_APP_API_BASE_DOMAIN + '/asset';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const data = obj;

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Edit Existing Asset
 * Method: PUT
 * Endpoint URI: https://asset.dev.airlift.solutions/asset/ + ID
 * @constructor
 */
export const ApiEditAssetRequest = (obj, id) => {
	const apiUrl = 'https://asset.' + process.env.REACT_APP_API_BASE_DOMAIN + '/asset/' + id;
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const data = obj;

	const promise = axios.put(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * DELETE Existing Asset
 * Method: DELETE
 * Endpoint URI: https://asset.dev.airlift.solutions/asset/
 * @constructor
 */
export const ApiDeleteAssetRequest = (id) => {
	const apiUrl = 'https://asset.' + process.env.REACT_APP_API_BASE_DOMAIN + '/asset/' + id;
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.delete(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Account List
 * Method: GET
 * Endpoint URI: https://account.dev.airlift.solutions/account
 * @constructor
 */
export const ApiAccountList = () => {
	const apiUrl = 'https://account.' + process.env.REACT_APP_API_BASE_DOMAIN + '/account';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Legs by Mission ID
 * Method: GET
 * Endpoint URI: https://leg.dev.airlift.solutions/leg?missionID=<missionId>
 * @constructor
 */
export const ApiLegsById = (id) => {
	const recId = id;
	const apiUrl = 'https://leg.' + process.env.REACT_APP_API_BASE_DOMAIN + '/leg?missionid=';
	const path = apiUrl + recId;
	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);
	return dataPromise;
};

/**
 * Create New Mission Leg
 * Method: POST
 * Endpoint URI: https://leg.dev.airlift.solutions/leg/
 * @constructor
 */
export const ApiNewLeg = (data) => {
	const apiUrl = 'https://leg.' + process.env.REACT_APP_API_BASE_DOMAIN + '/leg/';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Delete Mission Leg
 * Method: DELETE
 * Endpoint URI: https://leg.dev.airlift.solutions/leg/{id}
 * @constructor
 */
export const ApiDeleteLeg = (id) => {
	const apiUrl = 'https://leg.' + process.env.REACT_APP_API_BASE_DOMAIN + '/leg/';
	const path = apiUrl + id;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.delete(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Update a legs history by ID with data from mission status
 * Method: PUT
 * Endpoint URI: https://leg.dev.airlift.solutions/leg/ + legId
 * @constructor
 */
export const ApiUpdateLegHistoryByIdForm = (id, data) => {
	const recId = id;
	const apiUrl = 'https://leg.' + process.env.REACT_APP_API_BASE_DOMAIN + '/leg/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.put(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Update a leg's state
 * Method: PUT
 * Endpoint URI: https://leg.dev.airlift.solutions/leg/ + legId
 * @constructor
 */
export const ApiUpdateLegState = (id, newState) => {
	const recId = id;
	const apiUrl = 'https://leg.' + process.env.REACT_APP_API_BASE_DOMAIN + '/leg/';
	const path = apiUrl + recId;

	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${actions.getToken()}`
	};

	return new Promise((resolve, reject) => {
		helper
			.ApiLegByIdHelper(id)
			.then((res) => {
				let data = res.history[0];
				data.state = newState;
				return axios.put(path, data, { headers: headers });
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/**
 * Get Leg by ID
 * Method: POST
 * Endpoint URI: https://leg.dev.airlift.solutions/leg/ID
 * @constructor
 */
export const ApiLegByIdHelper = (id) => {
	const recId = id;
	const apiUrl = 'https://leg.' + process.env.REACT_APP_API_BASE_DOMAIN + '/leg/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Sandbox List
 * Method: GET
 * Endpoint URI: https://sandbox.dev.airlift.solutions/sandbox/
 * @constructor
 */
export const ApiSandboxList = (params) => {
	const apiUrl = 'https://sandbox.' + process.env.REACT_APP_API_BASE_DOMAIN + '/sandbox' + params;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise
		.then((response) => response.data)
		.catch((error) => {
			console.log(error.message);
		});

	return dataPromise;
};

/**
 * Get an Existing Sandbox by ID
 * Method: GET
 * Endpoint URI: https://sandbox.dev.airlift.solutions/sandbox/ + recordId
 * @constructor
 */
export const ApiSandboxById = (id) => {
	const recId = id;
	const apiUrl = 'https://sandbox.' + process.env.REACT_APP_API_BASE_DOMAIN + '/sandbox/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Create New Sandbox
 * Method: POST
 * Endpoint URI: https://sandbox.dev.airlift.solutions/sandbox/
 * @constructor
 */
export const ApiNewSandbox = (data) => {
	const apiUrl = 'https://sandbox.' + process.env.REACT_APP_API_BASE_DOMAIN + '/sandbox/';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Update State of Existing Sandbox by ID
 * Method: PUT
 * Endpoint URI: https://sandbox.dev.airlift.solutions/sandbox/"
 * @constructor
 */
export const ApiChangeSandboxState = (id, newstate) => {
	const recId = id;
	const apiUrl = 'https://sandbox.' + process.env.REACT_APP_API_BASE_DOMAIN + '/sandbox/';
	const path = apiUrl + recId;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${actions.getToken()}`
	};

	return new Promise((resolve, reject) => {
		helper
			.ApiSandboxById(id)
			.then((res) => {
				let data = res.history[0];
				data.state = newstate;
				return axios.put(path, data, { headers: headers });
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/**
 * Update an Existing Sandbox by ID with data from form
 * Method: PUT
 * Endpoint URI: https://sandbox.dev.airlift.solutions/sandbox/ + recordId
 * @constructor
 */
export const ApiUpdateSandboxById = (id, data) => {
	const recId = id;
	const apiUrl = 'https://sandbox.' + process.env.REACT_APP_API_BASE_DOMAIN + '/sandbox/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.put(path, data, config);
	const dataPromise = promise.then((response) => response.data);
	return dataPromise;
};

/**
 * Copy an Existing Lift Request by ID with an optional Sandbox ID
 * Method: PUT
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/copy/ + recordId
 * @constructor
 */
export const ApiCopyLiftRequestById = (id, queryParams) => {
	let path = '';
	const recId = id;
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request/copy';
	if (queryParams === undefined) {
		path = apiUrl + recId;
	} else {
		path = apiUrl + recId + queryParams;
	}

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.put(path, config);
	const dataPromise = promise.then((response) => response.data);
	return dataPromise;
};

/**
 * Copy Multiple Lift Requests by IDs with an optional Sandbox ID
 * Method: POST
 * Endpoint URI: https://lift-request.dev.airlift.solutions/lift-request/copy
 * @constructor
 */
export const ApiBulkCopyLiftRequests = (data, queryParams) => {
	let path = '';
	const apiUrl = 'https://lift-request.' + process.env.REACT_APP_API_BASE_DOMAIN + '/lift-request/copy';
	if (queryParams === undefined) {
		path = apiUrl;
	} else {
		path = apiUrl + queryParams;
	}

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Copy an Existing Asset by ID with an optional Sandbox ID
 * Method: PUT
 * Endpoint URI: https://asset.dev.airlift.solutions/asset/copy/ + recordId
 * @constructor
 */
export const ApiCopyAssetById = (id, queryParams) => {
	let path = '';
	const recId = id;
	const apiUrl = 'https://asset.' + process.env.REACT_APP_API_BASE_DOMAIN + '/asset/copy';
	if (queryParams === undefined) {
		path = apiUrl + recId;
	} else {
		path = apiUrl + recId + queryParams;
	}

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.put(path, config);
	const dataPromise = promise.then((response) => response.data);
	return dataPromise;
};

/**
 * Copy Multiple Assets by IDs with an optional Sandbox ID
 * Method: POST
 * Endpoint URI: https://asset.dev.airlift.solutions/asset/copy
 * @constructor
 */
export const ApiBulkCopyAssets = (data, queryParams) => {
	let path = '';
	const apiUrl = 'https://asset.' + process.env.REACT_APP_API_BASE_DOMAIN + '/asset/copy';
	if (queryParams === undefined) {
		path = apiUrl;
	} else {
		path = apiUrl + queryParams;
	}

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Copy an Existing Airport by ID with an optional Sandbox ID
 * Method: PUT
 * Endpoint URI: https://airport.dev.airlift.solutions/airport/copy/ + recordId
 * @constructor
 */
export const ApiCopyAirportById = (id, queryParams) => {
	let path = '';
	const recId = id;
	const apiUrl = 'https://airport.' + process.env.REACT_APP_API_BASE_DOMAIN + '/airport/copy';
	if (queryParams === undefined) {
		path = apiUrl + recId;
	} else {
		path = apiUrl + recId + queryParams;
	}

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.put(path, config);
	const dataPromise = promise.then((response) => response.data);
	return dataPromise;
};

/**
 * Copy Multiple Airports by IDs with an optional Sandbox ID
 * Method: POST
 * Endpoint URI: https://airport.dev.airlift.solutions/airport/copy
 * @constructor
 */
export const ApiBulkCopyAirports = (data, queryParams) => {
	let path = '';
	const apiUrl = 'https://airport.' + process.env.REACT_APP_API_BASE_DOMAIN + '/airport/copy';
	if (queryParams === undefined) {
		path = apiUrl;
	} else {
		path = apiUrl + queryParams;
	}

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get all the Lift Requests using graphql
 * Method: GET/Query
 * Endpoint URI: https://lift-request.dev.airlift.solutions/graphql
 * @constructor
 */
export const GraphQlAllLiftRequests = () => {
	const promise = axios.post(
		`https://lift-request.dev.airlift.solutions/graphql`,
		{ query: 'query liftrequests {allLiftRequests {id}}' },
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const dataPromise = promise.then((response) => response.data);
	return dataPromise;
};

/**
 * Get a Lift Request by Id using graphql
 * Method: GET/Query
 * Endpoint URI: https://lift-request.dev.airlift.solutions/graphql
 * Lift Request Id: 64300592051f3f006be8e7ca
 * @constructor
 */
export const GraphQlLiftRequestById = () => {
	const promise = axios.post(
		`https://lift-request.dev.airlift.solutions/graphql`,
		{
			query: `query liftrequestbyid ($id: String) {
                    liftRequestById(id: $id) {
                        id
                        latestVersion
                        history {
                            state
                            version
                            requestName
                            requester {
                                firstName
                                email
                            }
                            departure {
                                airport {
                                    icao
                                }
                                earliest
                                latest
                                alternateAirports {
                                    icao
                                }
                            }
                            cargoes {
                                cargoId
                                description
                                type
                                count
                                hazmat {
                                    cargo {
                                        height
                                        length
                                        weight
                                        width
                                    }
                                    containsHazmat
                                    hazardClassAndDivision
                                    netExplosiveWeight
                                    passengerEligibilityCode
                                    shippingDescription
                                    shippingName
                                    unNumber
                                }
                            }
                            comments {
                                commentId
                                commenter {
                                    firstName
                                }
                                text
                                createdOn
                            }
                            commercialCost
                            justification
                            missionDescription
                            shareEmails
                            createdBy {
                                email
                            }
                        }
                    }
                }`,
			variables: {
				id: '64300592051f3f006be8e7ca'
			}
		},
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const dataPromise = promise.then((response) => response.data);
	return dataPromise;
};

/**
 * Create a Lift Request using graphql
 * Method: Put/Mutation
 * Endpoint URI: https://lift-request.dev.airlift.solutions/graphql
 * @constructor
 */
export const GraphQlCreateLiftRequest = () => {
	const promise = axios.post(
		`https://lift-request.dev.airlift.solutions/graphql`,
		{
			query: `mutation newrequest($liftRequestDetails: LiftRequestDetailsInput) {
                    createLiftRequest(liftRequestDetails: $liftRequestDetails) {
                        id
                        latestVersion
                        history {
                            state
                            version
                            requestName
                            requester {
                                firstName
                                email
                            }
                            departure {
                                airport {
                                    icao
                                }
                                earliest
                                latest
                                alternateAirports {
                                    icao
                                }
                            }
                            cargoes {
                                cargoId
                                description
                                type
                                count
                                hazmat {
                                    cargo {
                                        height
                                        length
                                        weight
                                        width
                                    }
                                    containsHazmat
                                    hazardClassAndDivision
                                    netExplosiveWeight
                                    passengerEligibilityCode
                                    shippingDescription
                                    shippingName
                                    unNumber
                                }
                            }
                            comments {
                                commentId
                                commenter {
                                    firstName
                                }
                                text
                                createdOn
                            }
                            commercialCost
                            justification
                            missionDescription
                            shareEmails
                            createdBy {
                                email
                            }
                        }
                    }
                }`,
			variables: {
				liftRequestDetails: {
					arrival: {
						actual: null,
						earliest: '2023-08-12T14:10:23Z',
						latest: '2023-08-12T18:10:23Z',
						planned: null
					},
					autoValidated: false,
					cargoes: [
						{
							cargoId: 'e41e4add-2754-4b7e-87c1-3539bc81e026',
							connection_time: null,
							count: 2,
							description: 'Testing a graphql mutation for creation',
							liftrequest_id: null,
							liftrequest_status: null,
							oversize: null,
							rollingStock: null,
							specialHandlingRequirements: null,
							totalCubicFeet: null,
							type: 'PALLETS',
							weight: 2881.0088
						}
					],
					commandingOfficer: null,
					comments: [],
					commercialCost: null,
					createdAt: '2023-07-18T12:13:32.799Z',
					createdBy: null,
					departure: {
						actual: null,
						earliest: '2023-09-11T13:10:23Z',
						latest: '2023-09-11T17:10:23Z',
						planned: null
					},
					justification: 'Justification #1',
					missionDescription: 'Mission description #1',
					priority: 1,
					requestName: 'Lift Request GraphQL',
					requester: {
						id: '62ff763e-7525-44c1-853c-cee7f12add3a'
					},
					shareEmails: [],
					state: 'OPENED',
					urgency: 5,
					version: 0
				}
			}
		},
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const dataPromise = promise.then((response) => response.data);
	return dataPromise;
};

/**
 * Update a Lift Request using graphql
 * Method: Put/Mutation
 * Endpoint URI: https://lift-request.dev.airlift.solutions/graphql
 * Lift Request Id: 64b6b440a3059d1c7a52084d
 * @constructor
 */
export const GraphQlUpdateLiftRequest = () => {
	const firstupdate = axios.post(
		`https://lift-request.dev.airlift.solutions/graphql`,
		{
			query: `mutation updaterequest($id: String, $liftRequestDetails: LiftRequestDetailsInput) {
                updateLiftRequest(id: $id, liftRequestDetails: $liftRequestDetails) {
                        id
                        history {
                            missionDescription
                        }
                    }
                }`,
			variables: {
				id: '64b6b440a3059d1c7a52084d',
				liftRequestDetails: {
					missionDescription: 'Testing the update mutation for graphql'
				}
			}
		},
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const firstPromise = firstupdate.then((response) => response.data);
	return firstPromise;
};

/**
 * Get Disruptions List
 * Method: GET
 * Endpoint URI: https://disruption.dev.airlift.solutions/disruption
 * E.g. Params: ?states=CREATED&states=CLAIMED&states=PROCESSING&states=AWAITING_APPROVAL&states=APPROVED
 * @constructor
 */
export const ApiDisruptionsList = (params) => {
	const apiUrl = 'https://disruption.' + process.env.REACT_APP_API_BASE_DOMAIN + '/disruption' + params;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.get(apiUrl, config);
	const dataPromise = promise
		.then((response) => response.data)
		.catch((error) => {
			console.log(error.message);
		});

	return dataPromise;
};

/**
 * Update a Disruption by ID
 * Method: PUT
 * Endpoint URI: https://disruption.dev.airlift.solutions/disruption/ + disruptionId
 * @constructor
 */
export const ApiUpdateDisruptionById = (id, data) => {
	const recId = id;
	const apiUrl = 'https://disruption.' + process.env.REACT_APP_API_BASE_DOMAIN + '/disruption/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.put(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Update Disruption List States
 * Method: POST
 * Endpoint URI: https://disruption.dev.airlift.solutions/disruption/update?state=STATEVALUE
 * State Values: CREATED, PROCESSED
 * @constructor
 */
export const ApiUpdateDisruptionListStates = (data) => {
	const apiUrl = 'https://disruption.' + process.env.REACT_APP_API_BASE_DOMAIN + '/disruption/update?state=PROCESSED';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Create New Disruption from data
 * Method: POST
 * Endpoint URI: https://disruption.dev.airlift.solutions/disruption/
 * @constructor
 */
export const ApiNewDisruption = (data) => {
	const apiUrl = 'https://disruption.' + process.env.REACT_APP_API_BASE_DOMAIN + '/disruption/';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Retrieve Simulation Data for Given Mission Ids
 * Method: POST
 * Endpoint URI: https://simulation.dev.airlift.solutions/simulation/
 * @constructor
 */
export const ApiSimulationByMissionId = (missionId) => {
	let idArray = [];
	idArray.push(missionId);

	const testObj = {
		mission_ids: idArray
	};

	const apiUrl = 'https://simulation.' + process.env.REACT_APP_API_BASE_DOMAIN + '/simulation';
	const path = apiUrl;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`,
			'Content-Type': 'application/json'
		}
	};

	const promise = axios.post(path, testObj, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get Data for a shaply plot by mission ID
 * Method: GET
 * Endpoint URI: https://simulation.dev.airlift.solutions/simulation/shaply/ + missionId
 * @constructor
 */
export const ApiSimulationShapleyById = (missionId) => {
	const recId = missionId;
	const apiUrl = 'https://simulation.' + process.env.REACT_APP_API_BASE_DOMAIN + '/simulation/shaply/';
	const path = apiUrl + recId;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		},
		responseType: 'arraybuffer'
	};

	const promise = axios.get(path, config);
	const dataPromise = promise.then((res) => {
		const base64 = btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
		return base64;
	});
	return dataPromise;
};
/**
 * Update User Theme Attributes in Keycloak
 * Method: PUT
 * @param userid
 * @param themecolor
 * @constructor
 */
export const ApiKeycloakUpdateTheme = (userid, themecolor) => {
	const url = process.env.REACT_APP_URL_UPDATE + '/' + userid;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const data = {
		attributes: {
			theme: themecolor
		}
	};

	const promise = axios.put(url, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Update User Profile Attributes in Keycloak
 * Method: PUT
 * @param userid
 * @param themecolor
 * @constructor
 */
export const ApiKeycloakUpdateProfile = (userid, data) => {
	const url = process.env.REACT_APP_URL_UPDATE + '/' + userid;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};

	const promise = axios.put(url, data, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};

/**
 * Get User Data from Keycloak
 * Method: GET
 * @param userid
 * @constructor
 */
export const ApiKeycloakGetUserData = (userid) => {
	const url = process.env.REACT_APP_URL_UPDATE + '/' + userid;

	const config = {
		headers: {
			Authorization: `Bearer ${actions.getToken()}`
		}
	};
	const promise = axios.get(url, config);
	const dataPromise = promise.then((response) => response.data);

	return dataPromise;
};
