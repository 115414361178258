/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { accessValidator } from '../../Helpers/RolesPermissions';

import Dropdown from 'react-bootstrap/Dropdown';

import LiftRequestImportWindow from '../LiftRequestImportWindow/LiftRequestImportWindow';

const HeaderActions = (props) => {
	const accessMissionCreate = ['nalo'];

	return (
		<nav className={'app-header-actions-wrapper'}>
			{accessValidator(props.roleaccess, ['requester']) && !props.roleaccess.includes('nalo') && !props.roleaccess.includes('approver') && !props.roleaccess.includes('squadron') && (
				<NavLink title={'Click to Create New Air Lift Request'} to="/newairliftwizard" className={'btn button btn-primary btn-new-lift-request'}>
					New Lift Request
					<span className={'btn-icon'}>
						<i className={'fa fa-plus'}></i>
					</span>
				</NavLink>
			)}

			{/*
			{accessValidator(props.roleaccess, ['nalo']) && (
				<NavLink title={'Click to Create New Air Lift Request'} to="/newairlift" className={'btn button btn-primary btn-new-lift-request'}>
					New Lift Request
					<span className={'btn-icon'}>
						<i className={'fa fa-plus'}></i>
					</span>
				</NavLink>
			)}
			*/}

			{accessValidator(props.roleaccess, ['nalo']) && (
				<Dropdown>
					<Dropdown.Toggle variant="link" id="dropdown-basic">
						<i className={'far fa-list-dropdown'}></i>
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item title={'Click to Create New Air Lift Request'} as={Link} to="/newairlift">
							New Lift Request
						</Dropdown.Item>
						<LiftRequestImportWindow showIcon={false} classes={'dropdown-item'} />

						{accessValidator(props.roleaccess, accessMissionCreate) && (
							<Dropdown.Item title={'Click to Create New Mission Manually'} as={Link} to="/mission/new">
								Create Mission Manually
							</Dropdown.Item>
						)}

						<Dropdown.Item title={'Click to Enter Sandbox Environment'} as={Link} to="/sandbox/dashboard" onClick={console.log('clicked')}>
							Enter Sandbox Environment
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)}
		</nav>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderActions);
