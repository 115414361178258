/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import { Col, Container, Row } from 'react-bootstrap';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import LiftRequestListTabs from '../../App/LiftRequestListGrid/LiftRequestListTabs';

const LiftRequestsList = (props) => {
	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						<LiftRequestListTabs />
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

export default LiftRequestsList;
