/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
//import { Tooltip } from "@progress/kendo-react-tooltip";

import DisruptionManagementGrid from './DisruptionManagementGrid';
import { ApiDisruptionsList, ApiMissionGenRequest, ApiUpdateDisruptionListStates } from '../../Helpers/Apis';
import { SubmitDialog } from '../../Helpers/FormHelpers/dialogHelpers/solverSubmissionDialog';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const DisruptionManagementTabs = (props) => {
	// Used to Refresh on State Change
	const { state } = useLocation();

	// Used to Refresh on State Change
	// eslint-disable-next-line
	const [rerender, setRerender] = useState(false);

	const [selected, setSelected] = React.useState(0);
	const [visible, setVisible] = React.useState(false);
	const [solverStartDateValue, setSolverStartDateValue] = React.useState();
	const [solverEndDateValue, setSolverEndDateValue] = React.useState();
	const [solverSpeed, setSolverSpeed] = React.useState('Fast');
	const [disruptionIds, setDisruptionIds] = React.useState([]);

	const handleSelect = (e) => {
		setSelected(e.selected);
	};

	const solverScheduleObj = {
		// Solver Option: Optimization Timestamp
		optimization_timestamp: {
			start_time: solverStartDateValue,
			end_time: solverEndDateValue
		},

		// Solver Option: Configuration
		solver_configuration: {
			solver_speed: solverSpeed.toUpperCase()
		},

		// Solver Option: Submitted/Created By
		createdBy: {
			id: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.id ? props.keycloak.profile.id : null,
			firstName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.firstName ? props.keycloak.profile.firstName : null,
			lastName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.lastName ? props.keycloak.profile.lastName : null,
			email: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.email ? props.keycloak.profile.email : null,
			phoneNumber: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.phoneNumber ? props.keycloak.profile.phoneNumber : null,
			rank: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.rank ? props.keycloak.profile.rank : null,
			dutyTitle: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.dutyTitle ? props.keycloak.profile.dutyTitle : null,
			unit: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.unit ? props.keycloak.profile.unit : null,
			branchOfService: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.branchOfService ? props.keycloak.profile.branchOfService : null
		},

		// Disruption Configuration
		disruption_configuration: {
			disruption_timerange: {
				start_time: new Date(Date.now()),

				// Add 2 Hours for End Date/Time
				// Seconds in Minute (60) * Minutes in Hour (60) * Hours in Day (6) * Milliseconds (1000)
				end_time: new Date(Date.now() + 60 * 60 * 6 * 1000)
			},
			max_disrupted_itineraries: 4,
			disrupted_time_allowance: 4
		}
	};

	const handleSolverDateChange = (event) => {
		// Date Range
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(event.value);
		}

		setSolverStartDateValue(event.value.start);
		setSolverEndDateValue(event.value.end);
	};

	const handleSolverSpeedChange = (event) => {
		setSolverSpeed(event.value);
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	// Fetch all Disruption IDs
	const dataFetch = () => {
		setDisruptionIds([]);

		ApiDisruptionsList('?states=CREATED').then((res) => {
			if (res !== undefined && res !== null) {
				// eslint-disable-next-line
				res.map((r) => {
					setDisruptionIds((disruptionIds) => [...disruptionIds, r.id]);
				});
			}
		});
	};

	const submitForScheduling = () => {
		toggleDialog();

		ApiMissionGenRequest(solverScheduleObj);

		// Update State for Each Disruption
		ApiUpdateDisruptionListStates(disruptionIds);

		toast.success('Successfully submitted disruptions to the Air Logistics Solver for resolution');

		setRerender(!rerender);
	};

	// Disruption IDs, and Rerender
	useEffect(() => {
		dataFetch();
	}, [rerender]);

	// Used to Refresh
	useEffect(() => {
		dataFetch();
	}, [state]);

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link
							to={'#sendforresolution'}
							onClick={() => {
								toggleDialog();
							}}>
							<i className="fa-solid fa-circle-exclamation-check"></i>
							Send Disruptions for Processing
						</Nav.Link>

						{visible && <SubmitDialog text={'Are you sure you want to submit all disruptions for resolution?'} visible={visible} toggleDialog={toggleDialog} handleSolverDateChange={handleSolverDateChange} handleSolverSpeedChange={handleSolverSpeedChange} submitForScheduling={submitForScheduling} />}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	return (
		<React.Fragment>
			<div className={'page-title'}>
				<h2>Disruption Management</h2>

				{PageTitleControls()}
			</div>

			<TabStrip selected={selected} onSelect={handleSelect} id="disruption-management-list-tabs" className="disruption-management-list-tabs">
				{/* All Disruptions */}
				<TabStripTab eventKey="all" title="All Disruptions">
					<DisruptionManagementGrid tablist={'all'} queryparams={'?states=CREATED&states=CLAIMED&states=PROCESSING&states=AWAITING_APPROVAL&states=APPROVED&states=PROCESSED&states=COMPLETED'} />
				</TabStripTab>

				{/* Created */}
				<TabStripTab eventKey="created" title="Created">
					<DisruptionManagementGrid tablist={'created'} queryparams={'?states=CREATED'} />
				</TabStripTab>

				{/* Processing */}
				<TabStripTab eventKey="processed" title="Processed">
					<DisruptionManagementGrid tablist={'processed'} queryparams={'?states=PROCESSED'} />
				</TabStripTab>

				{/* Completed */}
				<TabStripTab eventKey="completed" title="Completed">
					<DisruptionManagementGrid tablist={'completed'} queryparams={'?states=COMPLETED'} />
				</TabStripTab>
			</TabStrip>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DisruptionManagementTabs);
