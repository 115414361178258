/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';

const Loader = () => {
	return (
		<div className={'loading-wrapper'}>
			<div className={'loading-wrapper-inner'}>
				<p className={'sr-only'}>Loading</p>
			</div>
		</div>
	);
};

export default Loader;
