/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import * as Actions from '../../../../store/actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';

import { SandboxIntroDetails } from '../NewSandboxWizardElements/SandboxIntroDetails';
import { SandboxItineraryDetails } from '../NewSandboxWizardElements/SandboxItineraryDetails';
import { SandboxSummaryDetails } from '../NewSandboxWizardElements/SandboxSummaryDetails';
import { SubmitDialog } from '../../../Helpers/FormHelpers/dialogHelpers/solverSubmissionDialog';
import { ApiMissionGenRequest } from '../../../Helpers/Apis';

import { ApiNewSandbox, ApiChangeSandboxState, ApiUpdateSandboxById, ApiBulkCopyLiftRequests, ApiBulkCopyAssets, ApiBulkCopyAirports } from '../../../Helpers/Apis';

import Footer from '../../../Core/Footer';
import Panel from '../../../Core/Panel';
import { toast } from 'react-toastify';

let sandboxId = 0;
let sandboxName = '';

let selectedLiftRequests = [];
let selectedAssets = [];
let selectedAirports = [];
let usingStandardAirports = false;

const pull_lift_request_data = (data) => {
	selectedLiftRequests = data;
};

const pull_asset_data = (data) => {
	selectedAssets = data;
};

const pull_airport_data = (data) => {
	selectedAirports = data;
};

const getStep = (step, props, post, token) => {
	switch (step) {
		case 0:
			selectedLiftRequests = [];
			selectedAssets = [];
			selectedAirports = [];
			return <SandboxIntroDetails {...props} post={post} token={token} />;
		case 1:
			return <SandboxItineraryDetails {...props} post={post} token={token} setSelectedLiftRequests={pull_lift_request_data} selectedLiftRequests={selectedLiftRequests} setSelectedAssets={pull_asset_data} selectedAssets={selectedAssets} setSelectedAirports={pull_airport_data} selectedAirports={selectedAirports} />;
		default:
			return <SandboxSummaryDetails {...props} post={post} token={token} usingStandardAirports={usingStandardAirports} selectedLiftRequests={selectedLiftRequests} selectedAssets={selectedAssets} selectedAirports={selectedAirports} />;
	}
};

const dateNow = new Date(Date.now());
//set the latest solver date to the current date + 2 weeks
const dateLater = new Date(Date.now() + 12096e5);

const NewSandboxWizard = (props) => {
	const [post] = React.useState('loading...');
	const [formKey] = React.useState(new Date());
	const [step, setStep] = React.useState(0);
	const [formState, setFormState] = React.useState({});
	const [submitVisible, setSubmitVisible] = React.useState(false);
	const [solverStartDateValue, setSolverStartDateValue] = React.useState(dateNow);
	const [solverEndDateValue, setSolverEndDateValue] = React.useState(dateLater);
	const [solverSpeed, setSolverSpeed] = React.useState('Fast');
	const [solverTime, setSolverTime] = React.useState(250);

	const [steps, setSteps] = React.useState([
		{
			label: 'Introduction',
			icon: '',
			isValid: undefined
		},
		{
			label: 'Itinerary',
			icon: '',
			isValid: undefined
		},
		{
			label: 'Summary',
			icon: '',
			isValid: undefined
		}
	]);

	const lastStepIndex = steps.length - 1;
	const isLastStep = lastStepIndex === step;
	const isFirstStep = step === 0;

	const navigate = useNavigate();

	const onPrevClick = React.useCallback(
		(event) => {
			event.preventDefault();
			setStep(() => Math.max(step - 1, 0));
		},
		[step, setStep]
	);

	const solverScheduleObj = {
		optimization_timestamp: {
			start_time: solverStartDateValue,
			end_time: solverEndDateValue
		},
		solver_configuration: {
			solver_speed: solverSpeed.toUpperCase(),
			time_limit: solverTime
		},
		createdBy: {
			id: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.id ? props.keycloak.profile.id : null,
			firstName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.firstName ? props.keycloak.profile.firstName : null,
			lastName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.lastName ? props.keycloak.profile.lastName : null,
			email: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.email ? props.keycloak.profile.email : null,
			phoneNumber: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.phoneNumber ? props.keycloak.profile.phoneNumber : null,
			rank: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.rank ? props.keycloak.profile.rank : null,
			dutyTitle: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.dutyTitle ? props.keycloak.profile.dutyTitle : null,
			unit: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.unit ? props.keycloak.profile.unit : null,
			branchOfService: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.branchOfService ? props.keycloak.profile.branchOfService : null
		}
	};

	const handleSolverDateChange = (event) => {
		setSolverStartDateValue(event.value.start);
		setSolverEndDateValue(event.value.end);
	};

	const handleSolverSpeedChange = (event) => {
		setSolverSpeed(event.value);
	};

	const handleSolverTimeChange = (event) => {
		setSolverTime(event.value);
	};

	const submitForScheduling = () => {
		toggleSubmitDialog();
		let sandboxIdParamString = `?sandboxId=${sandboxId}`;
		ApiMissionGenRequest(solverScheduleObj, sandboxIdParamString);
		toast.success('Successfully Submitted Sandbox Details to the Air Logistics Solver for Scheduling');
	};

	const toggleSubmitDialog = () => {
		setSubmitVisible(!submitVisible);
	};

	const openSubmitDialog = () => {
		toggleSubmitDialog();
	};

	const onStepSubmit = React.useCallback(
		(event) => {
			const { isValid, values } = event;
			const currentSteps = steps.map((currentStep, index) => ({
				...currentStep,
				isValid: index === step ? isValid : currentStep.isValid
			}));

			setSteps(currentSteps);

			setFormState(values);

			if (event.event.target.innerText === 'CANCEL') {
				if (!isFirstStep) {
					//if we're past the first step, then we need to delete the existing sandbox by id since it already exists if past the first step
					ApiChangeSandboxState(sandboxId, 'CANCELLED').then((res) => {
						toast.success('Sandbox Creation Cancelled');
						navigate('/sandbox/dashboard');
					});
				} else {
					toast.success('Sandbox Creation Cancelled');
					navigate('/sandbox/dashboard');
				}
			}

			if (event.event.target.innerText === 'NEXT') {
				setStep(() => Math.min(step + 1, lastStepIndex));

				if (isFirstStep) {
					let sandboxCreationObj = {
						name: values.sandboxName,
						state: 'GENERATED',
						createdBy: {
							id: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.id ? props.keycloak.profile.id : null,
							firstName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.firstName ? props.keycloak.profile.firstName : null,
							lastName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.lastName ? props.keycloak.profile.lastName : null,
							email: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.email ? props.keycloak.profile.email : null,
							phoneNumber: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.phoneNumber ? props.keycloak.profile.phoneNumber : null,
							rank: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.rank ? props.keycloak.profile.rank : null,
							dutyTitle: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.dutyTitle ? props.keycloak.profile.dutyTitle : null,
							unit: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.unit ? props.keycloak.profile.unit : null,
							branchOfService: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.branchOfService ? props.keycloak.profile.branchOfService : null
						}
					};

					// create new sandbox with JUST the sandbox name (since that's all we'd have at that point)
					ApiNewSandbox(sandboxCreationObj).then((res) => {
						sandboxId = res.id;
						sandboxName = res.history[0].name;
					});
				} else {
					//if sandbox name is changed after creation, then call api sandbox PUT to edit the name
					if (sandboxName !== values.sandboxName && values.sandboxName !== undefined) {
						let sandboxUpdateObj = {
							name: values.sandboxName,
							state: 'GENERATED',
							createdBy: {
								id: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.id ? props.keycloak.profile.id : null,
								firstName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.firstName ? props.keycloak.profile.firstName : null,
								lastName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.lastName ? props.keycloak.profile.lastName : null,
								email: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.email ? props.keycloak.profile.email : null,
								phoneNumber: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.phoneNumber ? props.keycloak.profile.phoneNumber : null,
								rank: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.rank ? props.keycloak.profile.rank : null,
								dutyTitle: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.dutyTitle ? props.keycloak.profile.dutyTitle : null,
								unit: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.unit ? props.keycloak.profile.unit : null,
								branchOfService: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.branchOfService ? props.keycloak.profile.branchOfService : null
							}
						};

						ApiUpdateSandboxById(sandboxId, sandboxUpdateObj).then((res) => {
							if (process.env.REACT_APP_DEBUG === 'true') {
								console.log(res);
							}
						});
					}
				}
			}

			// Click Event: Submit
			if (event.event.target.innerText === 'CREATE AND PROCESS' || event.event.target.innerText === 'CREATE AND CLOSE') {
				setStep(() => Math.min(step + 1, lastStepIndex));

				let copyLiftRequestParamString = `?sandboxId=${sandboxId}&state=VALIDATED`;

				if (isLastStep) {
					if (values.useStandardAirports === true) {
						selectedAirports = [];
					}

					ApiBulkCopyLiftRequests(selectedLiftRequests, copyLiftRequestParamString).then((res) => {});
					ApiBulkCopyAirports(selectedAirports, copyLiftRequestParamString).then((res) => {});
					ApiBulkCopyAssets(selectedAssets, copyLiftRequestParamString).then((res) => {});

					if (event.event.target.innerText === 'CREATE AND PROCESS') {
						openSubmitDialog();
					} else if (event.event.target.innerText === 'CREATE AND CLOSE') {
						toast.success('Sandbox Created Successfully!');
						navigate(`/sandbox/dashboard`);
					}
				}
			}
		},
		[steps, isLastStep, step, lastStepIndex]
	);

	const initValues = {
		...formState,
		post: post,

		useStandardAirports: true
	};

	return (
		<React.Fragment>
			<Container fluid className={'app-content'}>
				<Row>
					<Col>
						<Panel>
							<div className={'page-title padding-bottom-0'}>
								<h2>Create New Sandbox</h2>
							</div>

							<div className={'lr-step-wizard-wrapper'}>
								<Form
									key={formKey}
									initialValues={initValues}
									onSubmitClick={onStepSubmit}
									render={(formRenderProps) => (
										<div className={'lift-request-form short-form lift-request-form-wizard'}>
											<div className={'lift-request-form-inner'}>
												<FormElement>
													{(usingStandardAirports = formRenderProps.valueGetter('useStandardAirports'))}
													<div className={'form-fields-wrapper'}>{getStep(step, formRenderProps, post, props.token)}</div>

													<div className={'form-wizard-footer'}>
														<span className={'form-wizard-step-counter'}>
															Step {step + 1} of 3{isLastStep && ((formRenderProps.valueGetter('useStandardAirports') === false && selectedAirports.length === 0) || selectedLiftRequests.length === 0 || selectedAssets.length === 0 || (formRenderProps.valueGetter('useStandardAirports') === true && selectedAssets.length === 0 && selectedLiftRequests.length === 0)) && <p style={{ color: 'red' }}>At least one Lift Request, Asset, and Airport (if not using standard airports) must be selected.</p>}
														</span>

														<div className={'form-wizard-step-action-buttons form-submission-buttons'}>
															<ul>
																<li>
																	<Button className={`btn btn-size-medium btn btn-color-red margin-right-o`} themeColor={'primary'} onClick={formRenderProps.onSubmit} title={'Click to Cancel Sandbox Creation'}>
																		Cancel
																		<i className={'fa-regular fa-xmark'}></i>
																	</Button>
																</li>

																{(step !== 0) & (step !== 1) ? (
																	<li>
																		<Button className={'btn btn-size-medium btn-primary btn-previous'} onClick={onPrevClick} title={'Click to View Previous Items'}>
																			<i className="fa-regular fa-chevron-left"></i>
																			Previous
																		</Button>
																	</li>
																) : undefined}
																{/* is first step? then onClick = submit to api to create a new sandbox that lrs, assets, and airports can be applied to */}
																<li>
																	<Button className={`btn btn-size-medium btn-primary margin-right-o ${isLastStep ? 'btn-submit btn-color-green' : 'btn-next'}`} disabled={isLastStep && ((formRenderProps.valueGetter('useStandardAirports') === false && selectedAirports.length === 0) || selectedLiftRequests.length === 0 || selectedAssets.length === 0 || (formRenderProps.valueGetter('useStandardAirports') === true && selectedAssets.length === 0 && selectedLiftRequests.length === 0))} type={'submit'} themeColor={'primary'} onClick={formRenderProps.onSubmit} title={`${isLastStep ? 'Click to Submit' : 'Click to View Next Items'}`}>
																		{isLastStep ? 'Create and Close' : 'Next'}
																		<i className={`fa-regular ${isLastStep ? 'fa-floppy-disk' : 'fa-chevron-right'}`}></i>
																	</Button>
																</li>

																{isLastStep ? (
																	<li>
																		<Button className={'btn btn-size-medium btn-color-orange'} type={'submit'} onClick={formRenderProps.onSubmit} title={'Click to Submit Sandbox and Process'} disabled={isLastStep && ((formRenderProps.valueGetter('useStandardAirports') === false && selectedAirports.length === 0) || selectedLiftRequests.length === 0 || selectedAssets.length === 0 || (formRenderProps.valueGetter('useStandardAirports') === true && selectedAssets.length === 0 && selectedLiftRequests.length === 0))}>
																			Create and Process
																			<i className="fa-solid fa-arrow-right"></i>
																		</Button>
																		{submitVisible && <SubmitDialog environment={'sandbox'} visible={submitVisible} toggleDialog={toggleSubmitDialog} handleSolverDateChange={handleSolverDateChange} handleSolverTimeChange={handleSolverTimeChange} handleSolverSpeedChange={handleSolverSpeedChange} submitForScheduling={submitForScheduling} />}
																	</li>
																) : undefined}
															</ul>
														</div>
													</div>
												</FormElement>
											</div>
										</div>
									)}
								/>
							</div>
						</Panel>

						<Footer />
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSandboxWizard);
