/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

const Panel = (props) => {
	return (
		<section className={`app-panel ${props.className ? props.className : ''}`} style={props.style}>
			{props.children}
		</section>
	);
};

export default Panel;
