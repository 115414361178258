/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

const configuration = {
	url: process.env.REACT_APP_AUTH_URI,
	confidentialPort: 0,
	publicClient: true,
	realm: process.env.REACT_APP_AUTH_REALM,
	clientId: process.env.REACT_APP_AUTH_RESOURCE,
	sslRequired: 'external',
	scope: 'openid address phone country profile'
};

export default configuration;
