/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import { connect } from 'react-redux';
import * as Actions from '../../../store/actions';

const HeaderGreeting = (props) => {
	return (
		<ul className={'app-header-nav app-header-nav-greeting-wrapper'}>
			<li className={'app-header-nav-item'}>Logged in as {props.profile.email}</li>
		</ul>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname,
		theme: state.theme
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderGreeting);
