/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import DisruptionManagementTabs from '../../App/DisruptionManagementListGrid/DisruptionManagementTabs';

const DisruptionManagement = (props) => {
	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						<DisruptionManagementTabs />
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DisruptionManagement);
