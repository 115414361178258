/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { connect } from 'react-redux';
import * as Actions from '../../../store/actions';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import ProfileTabs from '../../App/Profile/ProfileTabs';

const Profile = (props) => {
	return (
		<Container fluid className={'app-content user-profile'}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2>Profile</h2>
						</div>

						<ProfileTabs />
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
