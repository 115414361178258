/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';

import { Form, Field, FormElement } from '@progress/kendo-react-form';

import { FormDropDownList } from '../../App/KendoFormElements/FormComponents';
import { ApiOrgSquadronList, ApiAssetBySquadronId, ApiCreateMission } from '../../Helpers/Apis';
import { requiredValidator } from '../../App/KendoFormElements/Validators';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';
import { FormInput } from '../../App/KendoFormElements/FormComponents';

const MissionNew = (props) => {
	const [squadronList, setSquadronList] = React.useState([]);
	const [tailNumberList, setTailNumberList] = React.useState([]);
	const [assetList, setAssetList] = React.useState([]);

	// eslint-disable-next-line
	const [selectedSquadron, setSelectedSquadron] = React.useState('');

	// Squadron
	const assignedSquadronChangeHandler = (e) => {
		setSelectedSquadron(e.value.id);

		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(e.value.id);
		}
	};

	useEffect(() => {
		if (selectedSquadron) {
			ApiAssetBySquadronId(selectedSquadron).then((res) => {
				setTailNumberList(res.map((element) => element.tailNumber));
				setAssetList(res);
			});
		}
	}, [selectedSquadron]);

	const navigate = useNavigate();

	const handleSubmit = (dataItem) => {
		if (process.env.REACT_APP_DEBUG === 'true') {
			//alert(JSON.stringify(dataItem, null, 2));
			console.log(dataItem);
		}

		let missionObject = {
			createdBy: null,
			createdAt: null,
			state: 'GENERATED',
			version: 11,
			missionName: dataItem.missionName,
			asset: assetList.filter((asset) => asset.tailNumber === dataItem.assignedTailNum)[0],
			legService: null,
			legs: [],
			squadronId: dataItem.assignedSquadron.id,
			schedulerMissionId: null
		};

		ApiCreateMission(missionObject).then((res) => {
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log(res);
				//alert(JSON.stringify(res, null, 2));
			}

			navigate(`/mission/edit/${res.id}`);
		});
	};

	const dataLoad = () => {
		ApiOrgSquadronList().then((res) => {
			setSquadronList(res);
		});
	};

	useEffect(() => {
		dataLoad();
	}, [props]);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2>Create New Mission</h2>
						</div>

						<Form
							onSubmit={handleSubmit}
							render={(formRenderProps) => (
								<React.Fragment>
									<Row className={'mission-ne-header-details margin-top-10'}>
										<div className={'col-4 '}>
											<Field key={'missionName'} id={'missionName'} name={'missionName'} label={'Mission Name'} placeholder={'E.g. V5G2P2'} component={FormInput} validator={requiredValidator} />
										</div>
										<div className={'col-4'}>
											<Field key={'assignedSquadron'} id={'assignedSquadron'} name={'assignedSquadron'} label={'Assigned Squadron'} defaultItem={'Select Squadron...'} component={FormDropDownList} data={squadronList} onChange={assignedSquadronChangeHandler} textField={'squadronDesignation'} dataItemKey={'id'} validator={requiredValidator} />
										</div>
										<div className={'col-4'}>
											<Field key={'assignedTailNum'} id={'assignedTailNum'} name={'assignedTailNum'} label={'Assigned Tail #'} defaultItem={'Select Tail Number...'} component={FormDropDownList} data={tailNumberList} onChange={assignedSquadronChangeHandler} validator={requiredValidator} />
										</div>
									</Row>

									<Row className={'mission-ne-airports-list'}>
										<div className={'col-12'}>
											<FormElement>
												<div className="k-form-buttons margin-top-15">
													<button type={'submit'} disabled={!formRenderProps.allowSubmit} className={'btn btn-color-green btn-submit-mission'} title={'Click to Submit Mission'}>
														<i className={'far fa-check'}></i> Submit
													</button>
												</div>
											</FormElement>
										</div>
									</Row>
								</React.Fragment>
							)}
						/>
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};
export default MissionNew;
