/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import { Row } from 'react-bootstrap';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormInput } from '../../App/KendoFormElements/FormComponents';
import { Button } from '@progress/kendo-react-buttons';

export const EditMissionForm = (props) => {
	const { cancelEdit, item, onSubmit, ...other } = props;

	return (
		<React.Fragment>
			<Form
				initialValues={item}
				onSubmit={onSubmit}
				onSubmitClick={onSubmit}
				className={'dialog-form'}
				render={(renderProps) => (
					<Dialog title={`Edit Mission Name`} onClose={cancelEdit} width={500}>
						<div
							style={{
								height: 'auto',
								maxHeight: '850px',
								overflow: 'auto',
								overflowX: 'hidden'
							}}>
							<FormElement>
								<Row>
									<div className={'col-12'}>
										<p className={'padding-top-5'} style={{ fontWeight: 'normal' }}>
											<strong>Squadron</strong>: {item.history[0] && item.history[0].squadronId ? item.history[0].squadronId : ''}
										</p>
									</div>
									<div className={'col-12'}>
										<p className={'padding-top-7'} style={{ fontWeight: 'normal' }}>
											<strong>Asset Tail #</strong>: {item.history[0] && item.history[0].asset && item.history[0].asset.tailNumber ? item.history[0].asset.tailNumber : ''}
										</p>
									</div>
									<div className={'col-12'}>
										<p className={'padding-top-7'} style={{ fontWeight: 'normal' }}>
											<strong>Asset Type</strong>: {item.history[0] && item.history[0].asset && item.history[0].asset.assetType && item.history[0].asset.assetType.id ? item.history[0].asset.assetType.id : ''}
										</p>
									</div>
								</Row>

								<Row id={'mission-edit-form'} className={'form-section'}>
									<div className={'col-12'}>
										<div className={'form-group'}>
											<Field key={'missionName'} id={'missionName'} name={'missionName'} label={'Mission Name'} placeholder={'mission name'} component={FormInput} />
										</div>
									</div>
								</Row>
							</FormElement>
						</div>
						<DialogActionsBar layout="end">
							<Button className={'btn btn-primary btn-size-medium btn-color-red btn-icon-remove margin-right-10'} onClick={cancelEdit} title={'Click to Cancel'}>
								Cancel
							</Button>
							<Button type={'submit'} className={'btn btn-primary btn-size-medium btn-color-green btn-icon-submit margin-right-0'} disabled={!renderProps.valid} onClick={renderProps.onSubmit} title={'Click to Save'}>
								Save
							</Button>
						</DialogActionsBar>
					</Dialog>
				)}
				{...other}
			/>
		</React.Fragment>
	);
};
