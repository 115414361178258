/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Moment from 'react-moment';
import { CapitalizeString, getLatitudeLongitudeDegreesMinutes } from './ContentFormatting';
import { stripTimeZone } from './DateTimeConversions';

import { ApiOrgSquadronList } from './Apis';

/**
 * Columns for Lift Request Grids
 * @type {{HasHazmatCell: (function(*): *), StateCell: (function(*): *), ArrivalTimeCell: (function(*): *), LiftRequestCheckboxCell: (function(*): *), DepartureTimeCell: (function(*): *), UicUnitNameCell: (function(*): *), ArrivalDateRangeCell: (function(*): *), FullNameCell: ((function(*): *)|*), ArrivalICAOCell: (function(*): *), LeadTimeCell: (function(*): *), LiftRequestIdCell: (function(*): *), RequestNameCell: (function(*): *), PassengersCountCell: (function(*): *), PriorityCell: (function(*): *), CargoTypeCell: (function(*): *), DepartureDateRangeCell: (function(*): *), DepartureICAOCell: (function(*): *), PalletsCountCell: (function(*): *)}}
 */
export const LiftRequestColumns = {
	// Lift Request Checkbox
	/*
  LiftRequestCheckboxCell: function (props) {
    const id = props.dataItem.id;
    const state = props.dataItem.history[0].state;

    return (
      <td className={"table-lr-checkbox-selection"}>
        {state === "VALIDATED" && (
          <div className={"grid-custom-checkbox"}>
            <label
              className={"custom-checkbox"}
              htmlFor={id}
              title={`Check to Select ID ${id}`}
            >
              <input
                type="checkbox"
                id={id}
                className={`lr-${id} lift-request-selection`}
              />
              <span>
                <span className={"sr-only"}>Click to Select Record {id}</span>
              </span>
            </label>
          </div>
        )}
      </td>
    );
  },
  */

	// Lift Request ID
	LiftRequestIdCell: function (props) {
		const id = props.dataItem.id;

		return (
			<td className={'table-lr-request-id'}>
				<NavLink to={`/liftrequest/view/${id}`} title={`Click to View ${id}`}>
					<span className={'ellipsis'}>{props.dataItem.id}</span>
				</NavLink>
			</td>
		);
	},

	// Request Name
	RequestNameCell: function (props) {
		const history = props.dataItem[props.field][0];
		const name = history.requestName;
		const id = props.dataItem.id;

		if (props.className === 'sandboxList ') {
			return (
				<td className={'table-lr-request-name'}>
					<NavLink to={`/liftrequest/view/${id}`} title={`Click to View ${id}`} target={'_blank'}>
						<span className={'ellipsis'}>{name !== null ? name : id}</span>
					</NavLink>
				</td>
			);
		}

		return (
			<td className={'table-lr-request-name'}>
				<NavLink to={`/liftrequest/view/${id}`} title={`Click to View ${id}`}>
					<span className={'ellipsis'}>{name !== null ? name : id}</span>
				</NavLink>
			</td>
		);
	},

	// State
	StateCell: function (props) {
		const history = props.dataItem[props.field][0];
		const state = history.state;

		return <td className={'table-lr-state'}>{history.state !== null ? <span className={`pill lift-status-${state.toLowerCase()}`}>{state}</span> : `-`}</td>;
	},

	SatisfiedCell: function (props) {
		const satisfiedValue = props.dataItem.satisfied;

		if (satisfiedValue === true) {
			return <td className={'table-lr-state'}>{<span className={`pill lift-status-satisfied`}>SATISFIED</span>}</td>;
		} else {
			return <td className={'table-lr-state'}>{<span className={`pill lift-status-scheduled`}>UNSATISFIED</span>}</td>;
		}
	},

	// UIC/Unit Name
	UicUnitNameCell: function (props) {
		const history = props.dataItem[props.field][0];

		return <td className={'table-lr-uic-unit-name'}>{history !== null && history.requester !== null && history.requester.unit && history.requester.unit.uic !== null ? history.requester.unit.uic : '-'}</td>;
	},

	// Lead Time
	LeadTimeCell: function (props) {
		const history = props.dataItem[props.field][0];

		const departureLatest = history !== null && history.departure !== null && history.departure.latest !== null ? history.departure.latest : '';

		const currentDate = new Date();

		return (
			<td
				className={'table-lr-lead-time'}
				style={{
					outline: '1px solid #eee',
					outlineWidth: '0 1px',
					backgroundColor: 'rgba(252,220,175,0.20)'
				}}>
				{history.departure !== null && history.latest !== null ? (
					<Moment diff={departureLatest} unit="days">
						{currentDate}
					</Moment>
				) : (
					'-'
				)}

				{history.departure !== null && history.latest !== null ? ' days' : ''}
			</td>
		);
	},

	// Departure ICAO
	DepartureICAOCell: function (props) {
		const history = props.dataItem[props.field][0];
		const departure = history.departure;

		return (
			<td
				className={'table-lr-dept-icao'}
				style={{
					outline: '1px solid #eee',
					outlineWidth: '0 1px',
					backgroundColor: 'rgba(252,220,175,0.20)'
				}}>
				{departure !== null && departure.airport !== null ? departure.airport.icao : `-`}
			</td>
		);
	},

	// Departure Time
	DepartureTimeCell: function (props) {
		const history = props.dataItem[props.field][0];

		return <td className={'table-lr-dept-time'}>{history.departure !== null ? <Moment format={'YYYY-MM-DD'}>{history.departure.earliest}</Moment> : `-`}</td>;
	},

	// Departure Date Range
	DepartureDateRangeCell: function (props) {
		if (props.dataItem[props.field][0].departure !== null || props.dataItem[props.field][0].departure !== undefined) {
			const history = props.dataItem[props.field][0];

			return (
				<td className={'table-lr-dept-date-range'}>
					{history !== null && history.departure !== null && history.departure.earliest !== null && history.departure.latest !== null ? (
						<>
							<div>
								<Moment format={'YYYY-MM-DD'}>{stripTimeZone(history.departure.earliest)}</Moment> to
							</div>
							<div>
								<Moment format={'YYYY-MM-DD'}>{stripTimeZone(history.departure.latest)}</Moment>
							</div>
						</>
					) : (
						'-'
					)}
				</td>
			);
		}
	},

	// Arrival ICAO
	ArrivalICAOCell: function (props) {
		const history = props.dataItem[props.field][0];

		return <td className={'table-lr-arr-icao'}>{history.arrival !== null && history.arrival.airport !== null ? history.arrival.airport.icao : `-`}</td>;
	},

	// Arrival Time
	ArrivalTimeCell: function (props) {
		const history = props.dataItem[props.field][0];

		return <td className={'table-lr-arr-time'}>{history.arrival !== null ? <Moment format={'YYYY-MM-DD'}>{history.arrival.earliest}</Moment> : `-`}</td>;
	},

	// Arrival Date Range
	ArrivalDateRangeCell: function (props) {
		if (props.dataItem[props.field][0].arrival !== null || props.dataItem[props.field][0].arrival !== undefined) {
			const history = props.dataItem[props.field][0];

			return (
				<td className={'table-lr-arr-date-range'}>
					{history !== null && history.arrival !== null && history.arrival.earliest !== null && history.arrival.latest !== null ? (
						<>
							<div>
								<Moment format={'YYYY-MM-DD'}>{stripTimeZone(history.arrival.earliest)}</Moment> to
							</div>
							<div>
								<Moment format={'YYYY-MM-DD'}>{stripTimeZone(history.arrival.latest)}</Moment>
							</div>
						</>
					) : (
						'-'
					)}
				</td>
			);
		}
	},

	// Full Name
	FullNameCell: function (props) {
		const history = props.dataItem[props.field][0];

		if (history.requester !== null) {
			if (history.requester.firstName !== null && history.requester.lastName !== null) {
				return (
					<td>
						{history.requester.firstName} {history.requester.lastName}
					</td>
				);
			} else if (history.requester.firstName !== null) {
				return <td>{history.requester.firstName}</td>;
			} else if (history.requester.lastName !== null) {
				return <td>{history.requester.lastName}</td>;
			} else {
				return <td>-</td>;
			}
		} else {
			return <td>-</td>;
		}
	},

	// Priority
	PriorityCell: function (props) {
		const history = props.dataItem[props.field][0];

		return <td className={'table-lr-priority'}>{history.priority ? `Priority ${history.priority}` : `-`}</td>;
	},

	// Cargo Type
	CargoTypeCell: function (props) {
		const history = props.dataItem[props.field][0];
		const cargoes = history.cargoes;

		return <td className={'table-lr-cargo-type'}>{cargoes !== null && cargoes[0]?.type !== null && cargoes.length && cargoes !== '' ? CapitalizeString(cargoes[0].type) : `-`}</td>;
	},

	// Passengers Count
	PassengersCountCell: function (props) {
		const history = props.dataItem[props.field][0];

		return (
			<td className={'table-lr-passengers-count align-center'}>
				{/* if either cargo entries is pallets, then display that pallets numbers */}
				{history !== null && history.cargoes !== null && history.cargoes[0] !== undefined
					? history.cargoes.map((cargo, i) => {
							if (cargo.type === 'PASSENGERS') {
								if (history.cargoes[i].count !== null) {
									return history.cargoes[i].count;
								} else {
									return '-';
								}
							}
							return null;
					  })
					: '-'}
			</td>
		);
	},

	// Pallets Count / Pallets Total Weight
	PalletsCountCell: function (props) {
		const history = props.dataItem[props.field][0];

		var weightSum = 0;
		var countSum = 0;

		if (history !== null && history.cargoes !== null && history.cargoes[0] !== undefined) {
			// eslint-disable-next-line
			history.cargoes.map((cargo, i) => {
				if (cargo.type === 'PALLETS') {
					weightSum += history.cargoes[i].weight;
					countSum += history.cargoes[i].count;
				}
			});
		}
		return (
			<td className={'table-lr-pallets-count-weight align-center'}>
				{/* map through cargoes and get sum of count and weight */}
				{history !== null && history.cargoes !== null && history.cargoes[0] !== undefined ? `${countSum} / ${weightSum} lbs` : '-'}
			</td>
		);
	},

	// Has Hazmat
	HasHazmatCell: function (props) {
		const history = props.dataItem[props.field][0];
		let hazmat = 'No';
		if (history !== null && history.cargoes !== null && history.cargoes !== undefined && history.cargoes.length > 0) {
			history.cargoes.map((cargo) => {
				if (cargo.type === 'PALLETS') {
					if (cargo.hazmat !== null && cargo.hazmat.containsHazmat !== null) {
						if (cargo.hazmat.containsHazmat) {
							hazmat = 'Yes';
						}
					}
				}
				return null;
			});
		}

		return <td className={'table-lr-has-hazmat'}>{hazmat}</td>;
	}
};

/**
 * Columns for Missions List Grid
 * @type {{AcTypeCell: MissionsColumns.AcTypeCell, DepIcaoCell: MissionsColumns.DepIcaoCell, ScheduledCargoCell: MissionsColumns.ScheduledCargoCell, StatusCell: MissionsColumns.StatusCell, DepDateTimeCell: MissionsColumns.DepDateTimeCell, ArrIcaoCell: MissionsColumns.ArrIcaoCell, CallSignCell: MissionsColumns.CallSignCell, MissionNameCell: MissionsColumns.MissionNameCell, FlyingUnitCell: MissionsColumns.FlyingUnitCell, AvailableCargoCell: MissionsColumns.AvailableCargoCell, ArrDateTimeCell: MissionsColumns.ArrDateTimeCell}}
 */
export const MissionsColumns = {
	MissionNameCell: function (props) {
		return (
			<td className={'mission-cell mission-cell-id'}>
				<NavLink to={`/missionstatus/${props.dataItem.id}`} title={'Click to View Mission Details'} target={'_blank'}>
					<span className={'ellipsis'}>{props.dataItem.missionName}</span>
				</NavLink>
			</td>
		);
	},

	StatusCell: function (props) {
		const history = props.dataItem[props.field][0];

		return (
			<td className={'mission-cell-status mission-cell-status'}>
				<span className={`pill mission-status-${history.state.toLowerCase()}`}>{history.state}</span>
			</td>
		);
	},
	LegsCount: function (props) {
		const history = props.dataItem[props.field][0];

		return <td className={'mission-cell mission-cell-legs-count'}>{history.legs.length}</td>;
	},
	CreatedOn: function (props) {
		const history = props.dataItem[props.field][0];

		return (
			<td className={'mission-cell mission-cell-created-on'}>
				<Moment format={'YYYY-MM-DD'}>{history.createdAt}</Moment>
			</td>
		);
	},
	LatestVersion: function (props) {
		const latestVersion = props.dataItem[props.field];

		return <td className={'mission-cell mission-cell-created-on'}>{latestVersion}</td>;
	},

	CallSignCell: function (props) {
		const val = props.dataItem[props.field];

		return <td className={'mission-cell mission-cell-call-sign'}>{val}</td>;
	},
	FlyingUnitCell: function (props) {
		const val = props.dataItem[props.field];

		return <td className={'mission-cell mission-cell-flying-unit'}>{val}</td>;
	},
	AcTypeCell: function (props) {
		const val = props.dataItem[props.field];

		return <td className={'mission-cell mission-cell-ac-type'}>{val}</td>;
	},
	DepIcaoCell: function (props) {
		const val = props.dataItem[props.field];

		return <td className={'mission-cell mission-cell-dep-icao'}>{val}</td>;
	},
	ArrIcaoCell: function (props) {
		const val = props.dataItem[props.field];

		return <td className={'mission-cell mission-cell-arr-icao'}>{val}</td>;
	},
	DepDateTimeCell: function (props) {
		const val = props.dataItem[props.field];

		return <td className={'mission-cell mission-cell-depdatetime'}>{val}</td>;
	},
	ArrDateTimeCell: function (props) {
		const val = props.dataItem[props.field];

		return <td className={'mission-cell mission-cell-arrdatetime'}>{val}</td>;
	},
	ScheduledCargoCell: function (props) {
		const val = props.dataItem[props.field];

		return <td className={'mission-cell mission-cell-scheduled-cargo'}>{val}</td>;
	},
	AvailableCargoCell: function (props) {
		const val = props.dataItem[props.field];

		return <td className={'mission-cell mission-cell-available-cargo'}>{val}</td>;
	}
};

/**
 * Columns for Airports List Grid
 * @type {{LongitudeCell: (function(*): *), StatusCell: (function(*): *), CountryCodeCell: (function(*): *), AirportIdCell: (function(*): *), NameCell: (function(*): *), IcaoCell: (function(*): *), IataCell: (function(*): *), LatitudeCell: (function(*): *)}}
 */
export const AirportColumns = {
	AirportIdCell: function (props) {
		const id = props.dataItem.id;

		if (props.className === 'sandboxList ') {
			return (
				<td className={'airport-cell airport-cell-id'}>
					<NavLink to={`/airport/edit/${id}`} title={`Click to View ${id}`} target={'_blank'}>
						<span className={'ellipsis'}>{id}</span>
					</NavLink>
				</td>
			);
		}

		return (
			<td className={'airport-cell airport-cell-id'}>
				<NavLink to={`/airport/edit/${id}`} title={`Click to View ${id}`}>
					<span className={'ellipsis'}>{id}</span>
				</NavLink>
			</td>
		);
	},
	NameCell: function (props) {
		const id = props.dataItem.id;
		const history = props.dataItem.history[0];
		const name = history.name;

		if (props.className === 'sandboxList ') {
			return (
				<td className={'airport-cell airport-cell-name'}>
					<NavLink to={`/airport/edit/${id}`} title={`Click to View ${name}`} target={'_blank'}>
						{name}
					</NavLink>
				</td>
			);
		}

		return (
			<td className={'airport-cell airport-cell-name'}>
				<NavLink to={`/airport/edit/${id}`} title={`Click to View ${name}`}>
					{name}
				</NavLink>
			</td>
		);
	},
	CountryCodeCell: function (props) {
		const history = props.dataItem.history[0];
		const countryCode = history.country !== null && history.country !== undefined && history.country.code !== null && history.country.code !== undefined ? history.country.code : '';

		return <td className={'airport-cell airport-cell-country-code'}>{countryCode}</td>;
	},
	IcaoCell: function (props) {
		const history = props.dataItem.history[0];
		const icao = history.icao;

		return <td className={'airport-cell airport-cell-icao'}>{icao}</td>;
	},
	IataCell: function (props) {
		const history = props.dataItem.history[0];
		const iata = history.iata;

		return <td className={'airport-cell airport-cell-iata'}>{iata}</td>;
	},
	LatitudeCell: function (props) {
		const latitude = props.dataItem.history[0].latitude !== null ? props.dataItem.history[0].latitude : '';
		const longitude = props.dataItem.history[0].longitude !== null ? props.dataItem.history[0].longitude : '';
		const getLatLongConversion = props.dataItem.history[0].latitude !== null && props.dataItem.history[0].longitude !== null ? getLatitudeLongitudeDegreesMinutes(latitude, longitude, 0) : '-';
		return <td className={'airport-cell airport-cell-latitude'}>{getLatLongConversion}</td>;
	},
	LongitudeCell: function (props) {
		const latitude = props.dataItem.history[0].latitude !== null ? props.dataItem.history[0].latitude : '';
		const longitude = props.dataItem.history[0].longitude !== null ? props.dataItem.history[0].longitude : '';
		const getLatLongConversion = props.dataItem.history[0].latitude !== null && props.dataItem.history[0].longitude !== null ? getLatitudeLongitudeDegreesMinutes(latitude, longitude, 1) : '-';
		return <td className={'airport-cell airport-cell-longitude'}>{getLatLongConversion}</td>;
	},
	StatusCell: function (props) {
		const history = props.dataItem.history[0];
		const status = history.status ? history.status : 'n/a';

		return (
			<td className={'airport-cell-status airport-cell-status'}>
				<span className={`pill airport-status-${status.toLowerCase()}`}>{status}</span>
			</td>
		);
	}
};

export const CargoColumns = {
	WeightCellWizard: function (props) {
		var weight = props.dataItem.cargoTotalWeight;

		if (weight === undefined || weight === null) {
			weight = 0;
		}
		return (
			<td className={'asset-cell asset-cell-id'}>
				<span className={'ellipsis'}>{`${weight} lbs`}</span>
			</td>
		);
	},

	WeightCellLongForm: function (props) {
		var weight = props.dataItem.weight;

		if (weight === undefined || weight === null) {
			weight = 0;
		}
		return (
			<td className={'asset-cell asset-cell-id'}>
				<span className={'ellipsis'}>{`${weight} lbs`}</span>
			</td>
		);
	}
};

export const AssetsColumns = {
	AssetIdCell: function (props) {
		const id = props.dataItem.id;

		if (props.className === 'sandboxList ') {
			return (
				<td className={'asset-cell asset-cell-id'}>
					<NavLink to={`/asset/edit/${id}`} title={`Click to View ${id}`} target={'_blank'}>
						<span className={'ellipsis'}>{id}</span>
					</NavLink>
				</td>
			);
		}

		return (
			<td className={'asset-cell asset-cell-id'}>
				<NavLink to={`/asset/edit/${id}`} title={`Click to View ${id}`}>
					<span className={'ellipsis'}>{id}</span>
				</NavLink>
			</td>
		);
	},

	AssetTailNumberCell: function (props) {
		const tailNumber = props.dataItem.tailNumber;
		const id = props.dataItem.id;

		return (
			<td className={'asset-cell asset-cell-id'}>
				<NavLink to={`/asset/edit/${id}`} title={`Click to View ${tailNumber}`}>
					<span className={'ellipsis'}>{tailNumber}</span>
				</NavLink>
			</td>
		);
	},

	AssetTypeCell: function (props) {
		const assetType = props.dataItem.assetType;

		return <td>{assetType !== null && assetType.id !== null ? assetType.id : '-'}</td>;
	},

	AssetStatusCell: function (props) {
		const status = props.dataItem.status[0];
		const statusCode = status !== null && status.reason !== null && status.reason.code !== null ? status.reason.code : '-';

		return (
			<td>
				<span className={`pill asset-status-${statusCode.toLowerCase()}`}>{statusCode}</span>
			</td>
		);
	},

	AssetAssignedSquadron: function (props) {
		const [squadrons, setSquadrons] = React.useState([]);

		const sId = props.dataItem.squadronId;

		useEffect(() => {
			ApiOrgSquadronList().then((res) => {
				setSquadrons(res);
			});
		}, []);

		return (
			<td>
				{/* eslint-disable-next-line */}
				{squadrons.map((r, i) => {
					if (r.id === sId) {
						if (props.className === 'sandboxList ') {
							return (
								<NavLink key={r.id} to={`/squadron/view/${sId}`} title={`Click to View ${sId}`} target={'_blank'}>
									<span className={'ellipsis'}>{r.squadronDesignation}</span>
								</NavLink>
							);
						} else {
							return (
								<NavLink key={r.id} to={`/squadron/view/${sId}`} title={`Click to View ${sId}`}>
									<span className={'ellipsis'}>{r.squadronDesignation}</span>
								</NavLink>
							);
						}
					}
				})}
			</td>
		);
	},

	AssetHomeLocationCell: function (props) {
		const homeLocation = props.dataItem.homeLocation;

		return <td>{homeLocation !== null && homeLocation.icao !== null ? homeLocation.icao : '-'}</td>;
	}
};

/**
 * Columns for Analysis List Grids
 * @type {{}}
 */
export const AnalysisColumns = {};

/**
 * Columns for Reports List Grids
 * @type {{}}
 */
export const ReportsColumns = {};

/**
 * Columns for Squadrons List Grids
 * @type {{Insignia: (function(*): *), SquadronLineage: (function(*): *), FixedWingSquadron: (function(*): *), Commander: (function(*): *), Aircraft: (function(*): *), Notes: (function(*): *), SquadronDesignation: (function(*): *), Nickname: (function(*): *)}}
 */
export const SquadronsColumns = {
	// Aircraft
	Aircraft: function (props) {
		const aircraft = props.dataItem.aircraft;

		return <td className={'table-sl-aircraft'}>{aircraft !== null ? <span>{aircraft}</span> : `-`}</td>;
	},

	// Fixed Wing Squadron
	FixedWingSquadron: function (props) {
		const fixedWingSquadron = props.dataItem.fixedWingSquadron;

		return <td className={'table-sl-fixedwingsquadron'}>{fixedWingSquadron !== null ? <span>{fixedWingSquadron}</span> : `-`}</td>;
	},

	// Insignia
	Insignia: function (props) {
		const fixedWingSquadron = props.dataItem.fixedWingSquadron;
		const insignia = props.dataItem.insignia;

		return <td className={'table-sl-insignia'}>{insignia !== null ? <img src={`/src${insignia}`} alt={fixedWingSquadron} /> : `-`}</td>;
	},

	// Nickname
	Nickname: function (props) {
		const nickname = props.dataItem.nickname;

		return <td className={'table-sl-nickname'}>{nickname !== null ? <span>{nickname}</span> : `-`}</td>;
	},

	// Notes
	Notes: function (props) {
		const notes = props.dataItem.notes;

		return <td className={'table-sl-notes'}>{notes !== null ? <span>{notes}</span> : `-`}</td>;
	},

	// Operational and Administrative Commander
	Commander: function (props) {
		const operationalAndAdministrativeCommander = props.dataItem.operationalAndAdministrativeCommander;

		return <td className={'table-sl-commander'}>{operationalAndAdministrativeCommander !== null ? <span>{operationalAndAdministrativeCommander}</span> : `-`}</td>;
	},

	// Squadron Designation
	SquadronDesignation: function (props) {
		const id = props.dataItem.id;
		const squadronDesignation = props.dataItem.squadronDesignation;

		return (
			<td className={'table-sl-designation'}>
				<NavLink to={`/squadron/view/${id}`} title={`Click to View Squadron`}>
					{squadronDesignation !== null ? <span>{squadronDesignation}</span> : `-`}
				</NavLink>
			</td>
		);
	},

	// Squadron Lineage
	SquadronLineage: function (props) {
		const squadronLineage = props.dataItem.squadronLineage;

		return <td className={'table-sl-lineage'}>{squadronLineage !== null ? <span>{squadronLineage}</span> : `-`}</td>;
	}
};
