/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import { accessValidator } from '../../Helpers/RolesPermissions';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import LiftRequestListTabs from '../../App/LiftRequestListGrid/LiftRequestListTabs';

import MultiMissionTabs from '../../App/MultiMissionView/MultiMissionTabs';

const Dashboard = (props) => {
	const accessDashboardLiftRequestsList = ['approver', 'nalo', 'requester'];
	const accessDashboardMultiMissionView = ['nalo'];

	// const notice = props.userinfo.role === '' ?
	//     <Alert style={{marginBottom: '0'}}>You do not have permission to access this system. Please contact the system administrator for more information.</Alert>
	//     :
	//     <></>
	// ;

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2 className={'padding-top-0 padding-bottom-0'}>Dashboard</h2>
						</div>
					</Panel>

					{accessValidator(props.roleaccess, accessDashboardMultiMissionView) && (
						<div className={'row'}>
							<div className={'col-12'}>
								<section className={'dashboard-widget-block'}>
									<MultiMissionTabs dashboardWidget={true} />
								</section>
							</div>
						</div>
					)}

					{accessValidator(props.roleaccess, accessDashboardLiftRequestsList) && !accessValidator(props.roleaccess, ['squadron']) && (
						<div className={'row'}>
							<div className={'col-12'}>
								<section className={'dashboard-widget-block'}>
									<LiftRequestListTabs dashboardWidget={true} roleaccess={props.roleaccess} />
								</section>
							</div>
						</div>
					)}

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
