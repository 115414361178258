/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';

import HeaderActions from '../App/Header/HeaderActions';
import HeaderNotifications from '../App/Header/HeaderNotifications';
import HeaderGreeting from '../App/Header/HeaderGreeting';
import HeaderProfile from '../App/Header/HeaderProfile';

//import HeaderCommunications from "../App/Header/HeaderCommunications";
//import HeaderLogs from "../App/Header/HeaderLogs";

const Header = (props) => {
	const appName = props.appname;
	const appVersion = props.appversion;

	return (
		<header id={'app-header'} className={`app-header ${props.className ? props.className : ''}`} style={props.style}>
			<div className={'app-header-inner'}>
				{window.location.href.indexOf('sandbox') === -1 ? <HeaderActions /> : ''}

				<nav className={'app-header-nav-wrapper'}>
					<HeaderGreeting />

					<HeaderNotifications />

					<HeaderProfile appname={appName} appversion={appVersion} />
				</nav>
			</div>
		</header>
	);
};

export default Header;
