/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { descriptionValidator, requiredValidator, fourDigitNumericInputValidator } from '../../App/KendoFormElements/Validators';
import { FormInput, FormNumericTextBox, FormSwitch, FormTextArea } from '../../App/KendoFormElements/FormComponents';
import { Button } from '@progress/kendo-react-buttons';
import LiftRequestTooltipModal from '../../App/Modals/LiftRequestTooltipModal';
import { htmlRender } from '../ContentFormatting';
import { PalletCommandButtonsCell } from '../TableHelpers/CommandCell';
import { insertItem, getItems, updateItem, deleteItem } from '../TableHelpers/TableServices';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { DetailComponent } from './HazmatDetail';

const editField = 'inEdit';

const GridContext = React.createContext();

const HazmatDetailComponent = (props) => {
	const { hazmatCargoes, allowedToSubmit, enterEdit, remove, add, discard, update, cancel, setHazmatCargoes, editDetailItem, editField } = React.useContext(GridContext);
	return <DetailComponent {...props} hazmatCargoes={hazmatCargoes} allowedToSubmit={allowedToSubmit} edit={enterEdit} remove={remove} add={add} discard={discard} update={update} cancel={cancel} setHazmatCargoes={setHazmatCargoes} editDetailItem={editDetailItem} editField={editField} />;
};

export const EditPalletLongForm = (props) => {
	const [cargoDescCount, setCargoDescCount] = React.useState('');
	const [toggleModal, setToggleModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalRef, setModalRef] = useState('');
	const [modalContent, setModalContent] = useState('');
	const [hasHazmat, setHasHazmat] = useState(props.item.hazmat && props.item.hazmat.containsHazmat ? props.item.hazmat.containsHazmat : false);
	const [hazmatCargoes, setHazmatCargoes] = useState(props.item.hazmat && props.item.hazmat.hazmatCargoes ? props.item.hazmat.hazmatCargoes : []);
	const [editing, setEditing] = useState(false);
	const [adding, setAdding] = useState(false);
	const [allowedToSubmit, setAllowedToSubmit] = useState(false);
	const { cancelEdit, discardNew, item, onSubmit, ...other } = props;
	const anchor = React.useRef(null);
	const cargoPositionAnchor = React.useRef(null);
	const cargoDescriptionAnchor = React.useRef(null);
	const editItem = props.item;

	const cargoDescMin = 50;

	const handleModalToggle = () => {
		setToggleModal(!toggleModal);
	};

	const handleModalRef = (val) => {
		setModalRef(val);
	};

	const handleCargoDescCount = (e) => {
		setCargoDescCount(e.value);
	};

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	const CommandCell = (props) => <PalletCommandButtonsCell {...props} allowedToSubmit={allowedToSubmit} edit={enterEdit} remove={remove} add={add} discard={discard} update={update} cancel={cancel} editField={editField} />;

	const hasHazmatToggle = () => {
		setHasHazmat(!hasHazmat);
	};

	const remove = (dataItem) => {
		setEditing(false);
		const newData = [...deleteItem(dataItem, props.item.hazmat.hazmatCargoes)];
		setHazmatCargoes(newData);
	};

	const add = (dataItem) => {
		const newDataItem = {
			inEdit: true,
			expanded: false,
			EntryID: dataItem.EntryID ? dataItem.EntryID : null,
			PalletName: dataItem.PalletName ? dataItem.PalletName : null,
			explosiveCompatibilityGroup: dataItem.explosiveCompatibilityGroup ? dataItem.explosiveCompatibilityGroup : null,
			hazardClassAndDivision: dataItem.hazardClassAndDivision ? dataItem.hazardClassAndDivision : null,
			netExplosiveWeight: dataItem.netExplosiveWeight ? dataItem.netExplosiveWeight : null,
			passengerEligibilityCode: dataItem.passengerEligibilityCode ? dataItem.passengerEligibilityCode : null,
			shippingDescription: dataItem.shippingDescription ? dataItem.shippingDescription : null,
			shippingName: dataItem.shippingName ? dataItem.shippingName : null,
			unNumber: dataItem.unNumber ? dataItem.unNumber : null,
			cargoHeight: dataItem.cargoHeight ? dataItem.cargoHeight : null,
			cargoLength: dataItem.cargoLength ? dataItem.cargoLength : null,
			cargoWidth: dataItem.cargoWidth ? dataItem.cargoWidth : null,
			cargoWeight: dataItem.cargoWeight ? dataItem.cargoWeight : null
		};
		const newData = insertItem(newDataItem, props.item.hazmat.hazmatCargoes);
		setHazmatCargoes(newData);
		setEditing(false);
		setAdding(false);
		props.setPalletHazmatCargoesData(props.item, newData);
	};

	const update = (dataItem) => {
		const newDataItem = {
			inEdit: false,
			expanded: false,
			EntryID: dataItem.EntryID,
			PalletName: dataItem.PalletName,
			explosiveCompatibilityGroup: dataItem.explosiveCompatibilityGroup ? dataItem.explosiveCompatibilityGroup : null,
			hazardClassAndDivision: dataItem.hazardClassAndDivision ? dataItem.hazardClassAndDivision : null,
			netExplosiveWeight: dataItem.netExplosiveWeight ? dataItem.netExplosiveWeight : null,
			passengerEligibilityCode: dataItem.passengerEligibilityCode ? dataItem.passengerEligibilityCode : null,
			shippingDescription: dataItem.shippingDescription ? dataItem.shippingDescription : null,
			shippingName: dataItem.shippingName ? dataItem.shippingName : null,
			unNumber: dataItem.unNumber ? dataItem.unNumber : null,
			cargoHeight: dataItem.cargoHeight ? dataItem.cargoHeight : null,
			cargoLength: dataItem.cargoLength ? dataItem.cargoLength : null,
			cargoWidth: dataItem.cargoWidth ? dataItem.cargoWidth : null,
			cargoWeight: dataItem.cargoWeight ? dataItem.cargoWeight : null
		};
		setEditing(false);
		const newData = updateItem(newDataItem, props.item.hazmat.hazmatCargoes);
		setHazmatCargoes(newData);
		props.setPalletHazmatCargoesData(props.item, newData);
	};

	const discard = () => {
		setEditing(false);
		const newData = [...hazmatCargoes];
		newData.splice(0, 1);
		setHazmatCargoes(newData);
		setAdding(false);
	};

	const cancel = (dataItem) => {
		const originalItem = getItems(props.item.hazmat.hazmatCargoes).find((p) => p.EntryID === dataItem.EntryID);
		const newData = hazmatCargoes.map((item) => (item.EntryID === originalItem.EntryID ? originalItem : item));
		setHazmatCargoes(newData);
		setEditing(false);
	};

	const enterEdit = (dataItem) => {
		const newData = hazmatCargoes.map((item) =>
			item.EntryID === dataItem.EntryID
				? {
						...item,
						inEdit: true,
						expanded: true
				  }
				: item
		);
		setAllowedToSubmit(true);
		setEditing(true);
		setHazmatCargoes(newData);
	};

	// grid utils
	const addNew = () => {
		const newDataItem = {
			inEdit: true,
			expanded: true
		};
		setEditing(true);
		setHazmatCargoes([newDataItem, ...hazmatCargoes]);
		setAllowedToSubmit(false);
		setAdding(true);
	};

	const itemChange = (event) => {
		const newData = hazmatCargoes.map((item) =>
			item.EntryID === event.dataItem.EntryID
				? {
						...item,
						[event.field || '']: event.value
				  }
				: item
		);
		setHazmatCargoes(newData);
	};

	const expandChange = (event) => {
		let newData = hazmatCargoes.map((item) => {
			if (item.EntryID === event.dataItem.EntryID) {
				item.expanded = event.value;
			}
			return item;
		});
		setHazmatCargoes(newData);
	};

	const editDetailItem = (updatedItem, value, field) => {
		// eslint-disable-next-line
		let newData = hazmatCargoes.map((item) => {
			if (updatedItem.EntryID === item.EntryID) {
				item[field] = value;
				setAllowedToSubmit(checkAllowedToSubmit(item));
			}
			return item;
		});
	};

	const checkAllowedToSubmit = (data) => {
		if (data.unNumber !== undefined && data.unNumber !== null && data.shippingName !== undefined && data.shippingName !== null && data.shippingDescription !== undefined && data.shippingDescription !== null && data.hazardClassAndDivision !== undefined && data.hazardClassAndDivision !== null && data.passengerEligibilityCode !== undefined && data.passengerEligibilityCode !== null && data.netExplosiveWeight !== undefined && data.netExplosiveWeight !== null) {
			if (fourDigitNumericInputValidator(data.unNumber) === '' && requiredValidator(data.shippingName) === '' && descriptionValidator(data.shippingDescription) === '' && requiredValidator(data.hazardClassAndDivision) === '' && requiredValidator(data.passengerEligibilityCode) === '' && requiredValidator(data.netExplosiveWeight) === '') {
				return true;
			}
			return false;
		}
		return false;
	};

	return (
		<React.Fragment>
			<Form
				initialValues={item}
				onSubmit={onSubmit}
				onSubmitClick={onSubmit}
				className={'dialog-form'}
				render={(renderProps) => (
					<Dialog title={editItem.newItem !== false ? 'New Cargo ' : `Edit Cargo: ${item.PalletName}`} onClose={cancelEdit} className={'dialog-cargo-long-form'}>
						<div
							style={{
								height: 'auto',
								maxHeight: '850px',
								overflow: 'auto',
								overflowX: 'hidden'
							}}>
							<FormElement>
								<Row id={'cargo-pallets'} className={'form-section'}>
									{/* Number of Pallets: Start */}
									<div className={'col-4 has-info'}>
										<Field key={'cargoPalletName'} id={'cargoPalletName'} name={'PalletName'} label={'Cargo Title'} component={FormInput} />
									</div>
								</Row>
								{/* Pallet Positions: End */}

								{/* Cargo Transportation: Start */}
								<Row id={'cargo-transportation'} className={'form-section'}>
									{/* Section Title - Cargo Transportation: Start */}
									<div className={'col-12 form-section-title cargo-transportation'}>
										<h3>
											Cargo Transportation
											<span className={'fontsize-14px'} style={{ float: 'right' }}>
												(Cargo acceptors and handlers are required at destination airfield)
											</span>
										</h3>
									</div>
									{/* Section Title - Cargo Transportation: End */}

									<Row id={'cargo-transportation'} className={'form-section'}>
										{/* Oversized Cargo: Start */}
										<div className={'col-12 has-oversize-cargo'}>
											<div className={'form-group'}>
												<Field key={'hasOversizedCargo'} id={'hasOversizedCargo'} name={'oversize'} onLabel={'Yes'} offLabel={'No'} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Contains Oversized Cargo?'} component={FormSwitch} hint={'Dimensions greater than either: 88” length, 108” width, or 56” height inclusive of 2.25” pallet height. Oversize Cargo will not fit on a single standard USAF 463L pallet (88" X 108" X 2.25").'} />
											</div>
										</div>
										{/* Oversized Cargo: End */}

										{/* Rolling Stock: Start */}
										<div className={'col-12 has-rolling-stock'}>
											<div className={'form-group'}>
												<Field key={'hasRollingStockCargo'} id={'hasRollingStockCargo'} name={'rollingStock'} onLabel={'Yes'} offLabel={'No'} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Contains Rolling Stock?'} component={FormSwitch} hint={'Any wheeled or tracked equipment that cannot be secured on a standard USAF 463L pallet.'} />
											</div>
										</div>
										{/* Rolling Stock: End */}

										{/* Belly Loadable: Start */}
										<div className={'col-12 has-rolling-stock'}>
											<div className={'form-group'}>
												<Field key={'isBellyLoadable'} id={'isBellyLoadable'} name={'bellyLoadable'} onLabel={'Yes'} offLabel={'No'} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Can Cargo be Belly Loaded?'} component={FormSwitch} hint={'Mark yes if cargo can be broken down and loaded into the aircraft without a pallet. If this is passenger baggage, only include cargo in excess of 40 lbs per person.'} />
											</div>
										</div>
										{/* Belly Loadable: End */}

										{/* Pallet Positions: Start */}
										<div className={'col-12 has-info'}>
											<div className={'form-group'}>
												<Field style={{ maxWidth: 300 }} key={'cargoNumberOfPalletPositions'} id={'cargoNumberOfPalletPositions'} name={'count'} label={'Total Standard Pallets in Size? (minimum 1)'} hint={'Choose the number of standard pallets that would be required to fit this cargo. For oversized pallets, choose the number of standard pallet sized positions to contain all oversized cargo. Standard pallet maximum is 88" length, 108" width.'} placeholder={''} format={'n0'} min={1} component={FormNumericTextBox} />

												<Button
													type={'button'}
													variant={'secondary'}
													style={{ left: 310 }}
													className={'btn-form-info'}
													id={'cargoNumberOfPalletPositions'}
													ref={cargoPositionAnchor}
													onClick={() => {
														// ref: modal anchor
														handleModalRef(cargoPositionAnchor);

														// Info: Modal Title
														handleModalTitle('Pallet Positions Information');

														// Info: Modal Content
														handleModalContent(`
															<p>A pallet position is equivalent to 463L (108”W x 88” L).</p>
														`);

														// Show Modal
														handleModalToggle();
													}}>
													<i className={'fa-solid fa-info modal-trigger'}></i>
													<span className={'sr-only'}>Click to view information about XXXXX</span>
												</Button>
											</div>
										</div>
										{/* Number of Pallets: End */}
									</Row>

									{/* Largest Item Dimensions (Length): Start */}
									<div className={'col-3'}>
										<Field key={'cargoLargestItemDimensionsLength'} id={'cargoLargestItemDimensionsLength'} name={'cargoLargestItemDimensionsLength'} label={'Largest Item Dimensions (Length)'} placeholder={''} component={FormInput} hint={'In inches.'} />
									</div>
									<div className={'col-3'}>
										<Field key={'cargoLargestItemDimensionsWidth'} id={'cargoLargestItemDimensionsWidth'} name={'cargoLargestItemDimensionsWidth'} label={'Largest Item Dimensions (Width)'} placeholder={''} component={FormInput} hint={'In inches.'} />
									</div>

									<div className={'col-3'}>
										<Field key={'cargoLargestItemDimensionsHeight'} id={'cargoLargestItemDimensionsHeight'} name={'cargoLargestItemDimensionsHeight'} label={'Largest Item (Height)'} placeholder={''} component={FormInput} hint={'In inches.'} />
									</div>

									{/* Largest Item Dimensions (weight): Start */}
									<div className={'col-3'}>
										<Field key={'cargoLargestItemDimensionsWeight'} id={'cargoLargestItemDimensionsWeight'} name={'cargoLargestItemDimensionsWeight'} label={htmlRender('Largest Item Dimensions (Weight)')} placeholder={''} component={FormInput} hint={'In lbs.'} />
									</div>

									{/* Largest Item Dimensions (Width): End */}

									{/* Heaviest Item (Length): Start */}
									<div className={'col-3'}>
										<Field key={'cargoHeaviestItemLength'} id={'cargoHeaviestItemLength'} name={'cargoHeaviestItemLength'} label={'Heaviest Item (Length)'} placeholder={''} component={FormInput} hint={'In inches.'} />
									</div>
									{/* Heaviest Item (Length): End */}

									{/* Heaviest Item (Width): Start */}
									<div className={'col-3'}>
										<Field key={'cargoHeaviestItemWidth'} id={'cargoHeaviestItemWidth'} name={'cargoHeaviestItemWidth'} label={'Heaviest Item (Width)'} placeholder={''} component={FormInput} hint={'In inches.'} />
									</div>
									{/* Heaviest Item (Width): End */}

									{/* Heaviest Item (Height): Start */}
									<div className={'col-3'}>
										<Field key={'cargoHeaviestItemHeight'} id={'cargoHeaviestItemHeight'} name={'cargoHeaviestItemHeight'} label={'Heaviest Item (Height)'} placeholder={''} component={FormInput} hint={'In inches.'} />
									</div>

									{/* Heaviest Item (Height): End */}

									{/* Heaviest Item (Weight): Start */}
									<div className={'col-3'}>
										<Field key={'cargoHeaviestItemWeight'} id={'cargoHeaviestItemWeight'} name={'cargoHeaviestItemWeight'} label={htmlRender('Heaviest Item (Weight)')} hint={'In lbs.'} placeholder={''} component={FormInput} />
									</div>
									{/* Heaviest Item (Weight): End */}

									{/* Total Weight: Start */}
									<div className={'col-3'}>
										<Field key={'weight'} id={'weight'} name={'weight'} label={htmlRender('Total Weight (<b><em>In Pounds</em></b>)')} placeholder={''} component={FormInput} />
									</div>
									{/* Total Weight: End */}

									{/* Total Cubic Feet: Start */}
									<div className={'col-3'}>
										<Field key={'cargoTotalCubicFeet'} id={'cargoTotalCubicFeet'} name={'cargoTotalCubicFeet'} label={'Total Cubic Feet'} placeholder={''} component={FormInput} />
									</div>
									{/* Total Cubic Feet: End */}

									{/* Cargo Description: Start */}
									<div className={'col-12 has-info'}>
										<div className={'form-group'}>
											<Field key={'cargoDescription'} id={'cargoDescription'} name={'description'} label={htmlRender('Cargo Description (<b><em>All Weights In Pounds</em></b>)')} optional={false} component={FormTextArea} onChange={handleCargoDescCount} hint={`Minimum ${cargoDescMin} characters. current: ${cargoDescCount.length}`} validator={descriptionValidator} />
											{/* Infotip: Start */}
											<Button
												type={'button'}
												variant={'secondary'}
												className={'btn-form-info'}
												id={'cargoDescriptionInfo'}
												ref={cargoDescriptionAnchor}
												onClick={() => {
													// ref: modal anchor
													handleModalRef(cargoDescriptionAnchor);

													// Info: Modal Title
													handleModalTitle('Cargo Description Information');

													// Info: Modal Content
													handleModalContent(`
															<p>Complete cargo for this request. All PAX listed are allotted one 40lb bag. If baggage is higher than 40lbs per PAX,
															calculate difference and specify in this block.</p>

															<p class="padding-bottom-5"><strong>Also specify:</strong></p>

															<ol class="margin-top-0 padding-top-0">
																<li>Cargo details</li>
																<li>Dimensions</li>
																<li>Individual pallet weights</li>
																<li>Total pallet positions</li>
															</ol>
														`);

													// Show Modal
													handleModalToggle();
												}}>
												<i className={'fa-solid fa-info modal-trigger'}></i>
												<span className={'sr-only'}>Click to view information about XXXXX</span>
											</Button>
											{/* Infotip: End */}
										</div>
									</div>
									{/* Cargo Description: End */}

									{/* Special Handling Requirements: Start */}
									<div className={'col-12 has-info'}>
										<div className={'form-group'}>
											<Field key={'specialHandlingRequirements'} id={'specialHandlingRequirements'} name={'specialHandlingRequirements'} label={'Special Handling Requirements'} component={FormTextArea} />

											{/* Infotip: Start */}
											<Button
												type={'button'}
												variant={'secondary'}
												className={'btn-form-info'}
												id={'specialHandlingRequirementsInfo'}
												ref={anchor}
												onClick={() => {
													// ref: modal anchor
													handleModalRef(anchor);

													// Info: Modal Title
													handleModalTitle('Handling Requirements Information');

													// Info: Modal Content
													handleModalContent(`
													<p>The most current versions of special handling codes are available at the United States Transportation Command (USTRANSCOM): <a href="https://www.ustranscom.mil/cmd/associated/dteb/reference-data.cfm" target="_blank" title="Click to view USTRANSCOM reference data" rel="noreferrer">https://www.ustranscom.mil/cmd/associated/dteb/reference-data.cfm</a></p>
														`);

													// Show Modal
													handleModalToggle();
												}}>
												<i className={'fa-solid fa-info modal-trigger'}></i>
												<span className={'sr-only'}>Click to view information about XXXXX</span>
											</Button>
											{/* Infotip: End */}
										</div>
									</div>
									{/* Special Handling Requirements: End */}

									{/* Section Title - Hazmat: Start */}
									<div className={'col-12 form-section-title cargo-has-hazmat'}>
										<h3>Hazmat</h3>
									</div>
									{/* Section Title - Hazmat: End */}

									{/* Has Hazmat: Start */}
									<div className={'col-12'}>
										<div className={'form-group'}>
											<Field key={'cargoHasHazmat'} id={'cargoHasHazmat'} name={'hazmat.containsHazmat'} onLabel={'Yes'} offLabel={'No'} size={'large'} trackRounded={'medium'} thumbRounded={'medium'} label={'Does cargo contain HAZMAT?'} component={FormSwitch} onChange={hasHazmatToggle} />
										</div>
									</div>
									{/* Has Hazmat: End */}

									{hasHazmat && (
										<React.Fragment>
											<GridContext.Provider value={{ hazmatCargoes, allowedToSubmit, enterEdit, remove, add, discard, update, cancel, setHazmatCargoes, editDetailItem, editField }}>
												<Grid
													name={'hazmat.hazmatCargoes'}
													style={{
														height: '1150px',
														width: '96%',
														paddingLeft: 10
													}}
													data={hazmatCargoes}
													onItemChange={itemChange}
													navigatable={true}
													detail={HazmatDetailComponent}
													expandField="expanded"
													onExpandChange={expandChange}
													editField={editField}>
													<GridToolbar>
														<Button type={'button'} title="Add new" disabled={adding} className={'btn btn-primary btn-color-blue'} onClick={addNew}>
															Add New Hazmat Item
														</Button>
													</GridToolbar>
													<Column field="PalletName" title="Hazmat Cargo Name" editable={true} />
													<Column field="hazardClassAndDivision" title="Hazard Type" editable={false} />
													<Column field="netExplosiveWeight" title="Weight" editable={false} />
													<Column cell={CommandCell} width="250px" />
												</Grid>
											</GridContext.Provider>
										</React.Fragment>
									)}

									{/* Cargo Photos: Start- DISABLED UNTIL IMPLEMENTED * /}s
            				        <div className={"col-12"}>
            				            <div className={"form-group"}>
            				                <Field
            				                    id={"cargoPhotos"}
            				                    name={"cargoPhotos"}
            				                    label={"Upload Cargo Photos"}
            				                    hint={"Hint: You can include .jpg/jpeg and png image files (Min: 50KB and Max: 5MB)"}
            				                    component={FormUpload}
            				                    batch={true}
            				                    multiple={true}
            				                    defaultFiles={[]}
            				                    widthCredentials={false}
            				                    restrictions={{
            				                        allowedExtensions: [".jpg", ".jpeg", ".png"],
            				                        minFileSize: 50000,
            				                        maxFileSize: 5000000
            				                    }}
            				                    />
            				            </div>
            				        </div>
            				        {/ * Cargo Photos: End */}
								</Row>
								{/* Cargo Transportation: End */}
							</FormElement>
						</div>
						<DialogActionsBar layout="end">
							<p className={'fontsize-14px'} style={{ color: 'red' }}>
								{!renderProps.valid ? '* Missing Cargo Description' : ''}
							</p>

							{editItem.newItem && (
								<Button className={'btn btn-primary btn-size-medium btn-color-red btn-icon-remove'} onClick={discardNew}>
									Discard
								</Button>
							)}

							{!editItem.newItem && (
								<Button className={'btn btn-primary btn-size-medium btn-color-red btn-icon-remove'} onClick={cancelEdit}>
									Cancel
								</Button>
							)}

							{editItem.newItem && (
								<Button type={'submit'} className={'btn btn-size-medium btn-primary btn-color-green btn-icon-submit'} disabled={editing || !renderProps.valid} onClick={renderProps.onSubmit} hint={!renderProps.valid ? '* Missing Cargo Description' : ''}>
									Save
								</Button>
							)}

							{!editItem.newItem && (
								<Button type={'submit'} className={'btn btn-size-medium btn-primary btn-color-green btn-icon-submit'} disabled={editing || !renderProps.valid} onClick={renderProps.onSubmit} hint={!renderProps.valid ? '* Missing Cargo Description' : ''}>
									Update
								</Button>
							)}
						</DialogActionsBar>
					</Dialog>
				)}
				{...other}
			/>
			<LiftRequestTooltipModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalRef={modalRef} modalContent={modalContent} />
		</React.Fragment>
	);
};
