/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import { getter } from '@progress/kendo-react-common';
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[-. \\/]?)?((?:\(?\d{1,}\)?[-. \\/]?)+)(?:[-. \\/]?(?:#|ext\.?|extension|x)[-. \\/]?(\d+))?$/i);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);

export const termsValidator = (value) => (value ? '' : "It's required to agree with Terms and Conditions.");

export const commercialCostValidator = (value) => (value ? '' : 'Error: Commercial Cost for PAX is required.');

export const descriptionValidator = (value) => (!value ? 'This field is required' : value.length < 50 ? `This field should be at least 50 characters long. Current: ${value.length}` : '');

export const completeMissionDescriptionValidator = (value) => (!value ? 'This field is required' : value.length < 100 ? `This field should be at least 100 characters long. Current: ${value.length}` : '');

export const commercialTravelUnacceptableDescriptionValidator = (value) => (!value ? 'This field is required' : value.length < 100 ? `This field should be at least 100 characters long. Current: ${value.length}` : '');

export const emailValidator = (value) => (!value ? 'Email field is required.' : emailRegex.test(value) ? '' : 'Email is not in a valid format.');

export const nameValidator = (value) => (!value ? 'Name is required' : value.length < 3 ? 'Name should be at least 3 characters long.' : '');

export const passengerCargoToggleValidator = (value) => (value ? '' : 'Error: Please select Passengers or Cargo.');

export const palletsCountValidator = (value) => (!value ? 'Total Number of Cargo is required' : value.length < 1 && value.length > 9 ? 'Total Number of Cargo must be between 1 and 8 (Maximum of 8).' : '');

export const fourDigitNumericInputValidator = (value) => (!value ? 'This field is required' : value.length < 4 || value.length >= 5 ? 'The value must be 4 digits' : '');

export const paxCountValidator = (value) => (!value ? 'Total Number of PAX is required' : value.length < 1 && value.length > 122 ? 'Total Number of PAX must be between 1 and 121.' : '');

export const priorityValidator = (value) => (value ? '' : 'Error: Priority is required.');

export const urgencyValidator = (value) => (value ? '' : 'Error: Urgency is required.');

export const requiredValidator = (value) => (value ? '' : 'Error: This field is required.');

export const userNameValidator = (value) => (!value ? 'User Name is required' : value.length < 5 ? 'User name should be at least 5 characters long.' : '');

/**
 * Phone Validation with RegEx
 *
 * Test: https://regex101.com/r/kFzb1s/42
 *
 * Possible Matches:
 * - (+351) 282 43 50 50
 * - 90191919908
 * - 555-8909
 * - 001 6867684
 * - 001 6867684x1
 * - 1 (234) 567-8901
 * - 1-234-567-8901 x1234
 * - 1-234-567-8901 ext1234
 * - 1-234 567.89/01 ext.1234
 * - 1(234)5678901x1234
 * - (123)8575973
 * - (0055)(123)8575973
 * - +1 282 282 2828
 *
 * @param value
 * @returns {string}
 */
export const phoneValidator = (value) => (!value ? 'Phone number is required.' : phoneRegex.test(value) ? '' : 'Not a valid phone number.');

export const cardValidator = (value) => (!value ? 'Credit card number is required. ' : ccardRegex.test(value) ? '' : 'Not a valid credit card number format.');

export const cvcValidator = (value) => (!value ? 'CVC code is required,' : cvcRegex.test(value) || value.length !== 3 ? '' : 'Not a valid CVC code format.');

export const guestsValidator = (value) => (!value ? 'Number of guests is required' : value < 5 ? '' : 'Maximum 5 guests');

export const nightsValidator = (value) => (value ? '' : 'Number of Nights is required.');

export const arrivalDateValidator = (value) => (value ? '' : 'Arrival Date is required.');

export const colorValidator = (value) => (value ? '' : 'Color is required.');

export const passwordValidator = (value) => (value && value.length > 8 ? '' : 'Password must be at least 8 symbols.');

export const addressValidator = (value) => (value ? '' : 'Address is required.');

const userNameGetter = getter('username');
const emailGetter = getter('email');

export const formValidator = (values) => {
	const userName = userNameGetter(values);
	const emailValue = emailGetter(values);

	if (userName && emailValue && emailRegex.test(emailValue)) {
		return {};
	}
	return {
		VALIDATION_SUMMARY: 'Please fill in the following fields.',
		//eslint-disable-next-line
		['username']: !userName ? 'User Name is required.' : '',
		//eslint-disable-next-line
		['email']: emailValue && emailRegex.test(emailValue) ? '' : 'Email is required and should be in a valid format.'
	};
};
