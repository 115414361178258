/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';

import { connect } from 'react-redux';
import * as Actions from '../../../store/actions';

import { Col, Container, Row } from 'react-bootstrap';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import { ApiLiftRequestList, ApiLiftRequestById, ApiNewLiftRequest, ApiChangeLiftRequestState, ApiUpdateLiftRequestByIdForm, ApiDisruptionsList, ApiMissionGenRequest, ApiMissionById, ApiMissionList, ApiAirportsList, ApiAssetsList, ApiPriorityList, ApiAccountList, ApiKeycloakGetUserData, ApiKeycloakUpdateTheme, ApiUpdateLiftRequestValidationHistoryByIdForm, ApiKeycloakUpdateProfile, GraphQlAllLiftRequests, GraphQlLiftRequestById, GraphQlCreateLiftRequest, GraphQlUpdateLiftRequest, ApiUpdateLegHistoryByIdForm, ApiNewLeg, ApiOrgUnitList, ApiOrgUnitListPaged } from '../../Helpers/Apis';

const Tests = (props) => {
	const queryParams = '?states=SUBMITTED&states=AUTHORIZED&states=CLAIMED&states=VALIDATED&states=UNSATISFIED&states=SCHEDULED&states=IN_TRANSIT&states=SATISFIED&states=REGRETTED&states=CANCELLED';
	const queryParamsDisruptions = '?states=CREATED&states=CLAIMED&states=PROCESSING&states=AWAITING_APPROVAL&states=APPROVED';

	const testApiLiftRequestList = () => {
		ApiLiftRequestList(queryParams).then((res) => {
			alert(JSON.stringify(res));
		});
	};

	//creates new lift request and then gets that lift request
	const testApiLiftRequestById = () => {
		ApiNewLiftRequest().then((res) => {
			ApiLiftRequestById(res.id).then((res) => {
				alert(JSON.stringify(res));
			});
		});
	};

	const testApiNewLiftRequest = () => {
		ApiNewLiftRequest().then((res) => {
			alert(JSON.stringify(res));
		});
	};

	//creates a new lift request and then updates the lift request to include data in json file
	const testApiUpdateLiftRequestByIdForm = () => {
		const data = require('../../Helpers/updateLiftRequestByIdFormTest.json');

		ApiNewLiftRequest().then((res) => {
			ApiUpdateLiftRequestByIdForm(res.id, data).then((res) => {
				alert(JSON.stringify(res));
			});
		});
	};

	const testApiDisruptionsList = () => {
		ApiDisruptionsList(queryParamsDisruptions).then((res) => {
			alert(JSON.stringify(res));
		});
	};

	//creates a new mission, and leg
	const testApiNewLeg = () => {
		const data = require('../../Helpers/updateLegHistoryByIdFormTest.json');

		ApiMissionGenRequest().then((res) => {
			data.missionId = res.id;
			ApiNewLeg(data).then((res) => {
				alert(JSON.stringify(res));
			});
		});
	};

	//creates a new mission, and leg, and then update the leg to include data in json file
	const testApiUpdateLegHistoryByIdForm = () => {
		const data = require('../../Helpers/updateLegHistoryByIdFormTest.json');

		ApiMissionGenRequest().then((res) => {
			data.missionId = res.id;
			ApiNewLeg(data).then((res) => {
				data.version = 1;
				var legId = res.id;
				ApiUpdateLegHistoryByIdForm(legId, data).then((res) => {
					alert(JSON.stringify(res));
				});
			});
		});
	};

	//creates a new lift request and changes the state to claimed
	const testApiChangeLiftRequestState = () => {
		ApiNewLiftRequest().then((res) => {
			ApiChangeLiftRequestState(res.id, 'CLAIMED').then((res) => {
				alert(JSON.stringify(res));
			});
		});
	};

	//Mission api is WIP
	const testApiNewMissionGenRequest = () => {
		ApiMissionGenRequest().then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiMissionById = () => {
		ApiMissionById('64300592051f3f006be8e7ca').then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiMissionList = () => {
		ApiMissionList().then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiAirportsList = () => {
		ApiAirportsList().then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiAssetsList = () => {
		ApiAssetsList().then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiPriorityList = () => {
		ApiPriorityList().then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiAccountList = () => {
		ApiAccountList().then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiKeycloakTheme = () => {
		ApiKeycloakUpdateTheme(props.userid, '').then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiKeycloakThemeDefault = () => {
		ApiKeycloakUpdateTheme(props.userid, 'theme-default').then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiKeycloakThemeBlack = () => {
		ApiKeycloakUpdateTheme(props.userid, 'theme-black').then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiKeycloakThemeLightGray = () => {
		ApiKeycloakUpdateTheme(props.userid, 'theme-light-gray').then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiKeycloakThemeDarkViolet = () => {
		ApiKeycloakUpdateTheme(props.userid, 'theme-dark-violet').then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiKeycloakThemeViolet = () => {
		ApiKeycloakUpdateTheme(props.userid, 'theme-violet').then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiKeycloakUserData = () => {
		ApiKeycloakGetUserData(props.userid).then((res) => {
			console.log(res);
		});
	};

	const testApiKeycloakUpdateUserData = () => {
		ApiKeycloakUpdateProfile(props.userid).then((res) => {
			alert(JSON.stringify(res));
		});
	};

	//calls ApiNewLiftRequest and passes the first id and a test validation history entry from json file
	//into ApiUpdateLiftRequestValidationHistoryByIdForm
	const testApiUpdateLiftRequestValidationHistoryByIdForm = () => {
		const data = require('../../Helpers/updateValidationHistoryTest.json');

		ApiNewLiftRequest().then((res) => {
			ApiUpdateLiftRequestValidationHistoryByIdForm(res.id, data).then((res) => {
				alert(JSON.stringify(res));
			});
		});
	};

	const testGraphQlAllLiftRequests = () => {
		GraphQlAllLiftRequests(props.userid).then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testGraphQlLiftRequestById = () => {
		GraphQlLiftRequestById(props.userid).then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testGraphQlCreateLiftRequest = () => {
		GraphQlCreateLiftRequest(props.userid).then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testGraphQlUpdateLiftRequest = () => {
		GraphQlUpdateLiftRequest(props.userid).then((res) => {
			alert(JSON.stringify(res));
		});
	};
	/* WIP
    const testApiOrgUnitListFiltered = () => {
        ApiOrgUnitListFiltered("123").then((res) => {
            alert(JSON.stringify(res))
        });
    }
    */

	const testApiOrgUnitList = () => {
		ApiOrgUnitList().then((res) => {
			alert(JSON.stringify(res));
		});
	};

	const testApiOrgUnitListPaged = () => {
		ApiOrgUnitListPaged(0, 10).then((res) => {
			alert(JSON.stringify(res));
		});
	};

	return (
		<Container fluid className={'app-content user-profile'}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2>API Tests</h2>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Lift Requests</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiLiftRequestList}>
								Lift Request List (GET)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiLiftRequestById}>
								Lift Request by ID (GET)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiNewLiftRequest}>
								New Lift Request (POST)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiUpdateLiftRequestByIdForm}>
								Updating Existing Lift Request (PUT)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiChangeLiftRequestState}>
								Change State of Existing Lift Request
								<br />
								SUBMITTED -> CLAIMED
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Disruptions</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiDisruptionsList}>
								Disruptions List (GET)
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Mission</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiNewMissionGenRequest}>
								Submit to Generate Mission (POST)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiMissionById}>
								Get Mission by ID (GET)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiMissionList}>
								Get Mission List (GET)
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Airports</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiAirportsList}>
								Airports List (GET)
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Assets</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiAssetsList}>
								Assets List (GET)
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Priority</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiPriorityList}>
								Priority List (GET)
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Accounts</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiAccountList}>
								Account List (GET)
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>GraphQL</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testGraphQlAllLiftRequests}>
								Lift Request List (GET)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testGraphQlLiftRequestById}>
								Lift Request By ID (GET)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testGraphQlCreateLiftRequest}>
								Create a Lift Request (PUT)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testGraphQlUpdateLiftRequest}>
								Update a Lift Request (PUT)
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Keycloak Theme</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiKeycloakTheme}>
								Set Default Theme Color - "" (PUT)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiKeycloakThemeDefault}>
								Updating Theme Color - "theme-default" (PUT)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiKeycloakThemeBlack}>
								Updating Theme Color - "theme-black" (PUT)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiKeycloakThemeLightGray}>
								Updating Theme Color - "theme-light-gray" (PUT)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiKeycloakThemeDarkViolet}>
								Updating Theme Color - "theme-dark-violet" (PUT)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiKeycloakThemeViolet}>
								Updating Theme Color - "theme-violet" (PUT)
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Keycloak User Info</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiKeycloakUserData}>
								Fetch User's Information - (GET)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiKeycloakUpdateUserData}>
								Update User's Information - (PUT)
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Validation</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiUpdateLiftRequestValidationHistoryByIdForm}>
								Validate Update Validation History By Id - (PUT)
							</button>
						</div>

						<div className={'col-12 margin-bottom-30'}>
							<h3>Mission Status</h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiNewLeg}>
								Create New Leg - (POST)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiUpdateLegHistoryByIdForm}>
								Update Leg History by Leg Id - (PUT)
							</button>
						</div>

						<div className={'col-12'}>
							<h3>Api Org Unit </h3>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiOrgUnitList}>
								Unit List - (GET)
							</button>

							<button className={'btn btn-primary margin-bottom-5'} onClick={testApiOrgUnitListPaged}>
								Paged Unit List - (GET)
							</button>
						</div>
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname,
		theme: state.theme
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tests);
