/*
 * Copyright 2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */
import React, { useEffect } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import { NavLink, useParams } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { CapitalizeString } from '../../../Helpers/ContentFormatting';
import { toast } from 'react-toastify';
import { Form, FormElement } from '@progress/kendo-react-form';
import Button from 'react-bootstrap/Button';

import { Field } from '@progress/kendo-react-form';
import { FormInput } from '../../../App/KendoFormElements/FormComponents';
import { useNavigate } from 'react-router-dom';

import Footer from '../../../Core/Footer';
import Panel from '../../../Core/Panel';
import Loader from '../../../Core/Loader';

import LiftRequestListGrid from '../../../App/LiftRequestListGrid/LiftRequestListGrid';
import AirportsListGrid from '../../../App/AirportsListGrid/AirportsListGrid';
import AssetAvailabilityList from '../../../App/AssetAvailability/AssetAvailabilityList';

import { SandboxSelectionDialog } from '../SandboxSelectionDialog/SandboxSelectionDialog';

import { ApiChangeSandboxState, ApiSandboxById, ApiLiftRequestList, ApiAssetsList, ApiAirportsList, ApiUpdateSandboxById, ApiChangeLiftRequestState, ApiDeleteAirportRequest, ApiDeleteAssetRequest, ApiLiftRequestById, ApiBulkCopyLiftRequests, ApiBulkCopyAssets, ApiBulkCopyAirports, ApiAirportById, ApiAssetById } from '../../../Helpers/Apis';

const SandboxEdit = (props) => {
	const [loaded, setLoaded] = React.useState(false);
	const [sandboxDetails, setSandboxDetails] = React.useState([]);
	const [formKey, setFormKey] = React.useState(new Date());
	const [visible, setVisible] = React.useState(false);
	const [dialogState, setDialogState] = React.useState('');

	const [openLiftRequestForm, setOpenLiftRequestForm] = React.useState(false);
	const [openAssetForm, setOpenAssetForm] = React.useState(false);
	const [openAirportForm, setOpenAirportForm] = React.useState(false);

	const [lrIdArray, setLrIdArray] = React.useState([]);
	const [assetIdArray, setAssetIdArray] = React.useState([]);
	const [airportIdArray, setAirportIdArray] = React.useState([]);

	const [removedLrIdArray, setRemovedLrIdArray] = React.useState([]);
	const [removedAssetIdArray, setRemovedAssetIdArray] = React.useState([]);
	const [removedAirportIdArray, setRemovedAirportIdArray] = React.useState([]);

	const [addedLrIdArray, setAddedLrIdArray] = React.useState([]);
	const [addedAssetIdArray, setAddedAssetIdArray] = React.useState([]);
	const [addedAirportIdArray, setAddedAirportIdArray] = React.useState([]);

	const [initialLrIdArray, setInitialLrIdArray] = React.useState([]);
	const [initialAssetIdArray, setInitialAssetIdArray] = React.useState([]);
	const [initialAirportIdArray, setInitialAirportIdArray] = React.useState([]);

	const [lifts, setLifts] = React.useState([]);
	const [assets, setAssets] = React.useState([]);
	const [airports, setAirports] = React.useState([]);

	const [potentialLrIdArray, setPotentialLrIdArray] = React.useState([]);
	const [potentialAssetIdArray, setPotentialAssetIdArray] = React.useState([]);
	const [potentialAirportIdArray, setPotentialAirportIdArray] = React.useState([]);

	const accessStates = '?states=SUBMITTED&states=AUTHORIZED&states=CLAIMED&states=VALIDATED&states=UNSATISFIED&states=SCHEDULED&states=IN_TRANSIT&states=SATISFIED&states=REGRETTED&states=CANCELLED';

	const navigate = useNavigate();

	const handleCancelEdit = () => {
		setOpenLiftRequestForm(false);
		setOpenAssetForm(false);
		setOpenAirportForm(false);
	};

	const cancelSandboxEdit = () => {
		toast.success('Sandbox Edit Cancelled!');
		navigate('/sandbox/dashboard');
	};

	const handleRemoveLiftRequest = (id) => {
		// eslint-disable-next-line
		lrIdArray.map((item, index) => {
			if (item === id) {
				setRemovedLrIdArray([...removedLrIdArray, id]);
				setLrIdArray((oldValues) => {
					return oldValues.filter((_, i) => i !== index);
				});
			}
		});

		// eslint-disable-next-line
		lifts.map((item, index) => {
			if (item.id === id) {
				setLifts((oldValues) => {
					return oldValues.filter((_, i) => i !== index);
				});
			}
		});

		setFormKey(id);
	};

	const handleRemoveAssets = (id) => {
		// eslint-disable-next-line
		assetIdArray.map((item, index) => {
			if (item === id) {
				setRemovedAssetIdArray([...removedAssetIdArray, id]);
				setAssetIdArray((oldValues) => {
					return oldValues.filter((_, i) => i !== index);
				});
			}
		});

		// eslint-disable-next-line
		assets.map((item, index) => {
			if (item.id === id) {
				setAssets((oldValues) => {
					return oldValues.filter((_, i) => i !== index);
				});
			}
		});

		setFormKey(id);
	};

	const handleRemoveAirports = (id) => {
		// eslint-disable-next-line
		airportIdArray.map((item, index) => {
			if (item === id) {
				setRemovedAirportIdArray([...removedAirportIdArray, id]);
				setAirportIdArray((oldValues) => {
					return oldValues.filter((_, i) => i !== index);
				});
			}
		});

		// eslint-disable-next-line
		airports.map((item, index) => {
			if (item.id === id) {
				setAirports((oldValues) => {
					return oldValues.filter((_, i) => i !== index);
				});
			}
		});

		setFormKey(id);
	};

	const enterEditForm = (param) => {
		if (param === 'liftRequest') {
			setOpenLiftRequestForm(true);
		}
		if (param === 'asset') {
			setOpenAssetForm(true);
		}
		if (param === 'airport') {
			setOpenAirportForm(true);
		}
	};

	const handleSubmit = (event) => {
		setOpenLiftRequestForm(false);
		setOpenAssetForm(false);
		setOpenAirportForm(false);

		if (event.target.textContent === 'Add Selected Lift Requests') {
			setLrIdArray(potentialLrIdArray);
		}

		if (event.target.textContent === 'Add Selected Assets') {
			setAssetIdArray(potentialAssetIdArray);
		}

		if (event.target.textContent === 'Add Selected Airports') {
			setAirportIdArray(potentialAirportIdArray);
		}
	};

	const params = useParams();

	const data = {
		id: sandboxDetails.id ? sandboxDetails.id : '',
		latestVersion: sandboxDetails.latestVersion ? sandboxDetails.latestVersion : '1',
		latestOwner: {
			id: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.id : null,
			firstName: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.firstName : null,
			lastName: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.lastName : null,
			email: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.email : null,
			phoneNumber: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.phoneNumber : null,
			rank: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.rank : null,
			dutyTitle: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.dutyTitle : null,
			unit: sandboxDetails.latestOwner ? sandboxDetails.latestOwner.unit : null
		},
		history: sandboxDetails.history ? sandboxDetails.history : []
	};

	const sandboxTitle = data.history[0] ? data.history[0].name : params.sandboxId;

	const stateUpdate = (id, state) => {
		ApiChangeSandboxState(id, 'CANCELLED').then((res) => {
			toast.success('Successfully Changed the Sandbox Status to ' + CapitalizeString(state.toLowerCase()));
		});

		setDialogState('');

		setTimeout(() => {
			setFormKey(new Date());
		}, 300);
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const openDialog = (id, state) => {
		toggleDialog();
		setDialogState(state);
	};

	const DialogWindow = (id, state) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleDialog}>
				<div data-id={id} data-state-to={state}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to delete this sandbox?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							stateUpdate(id, state);
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<NavLink
							className={'nav-link'}
							role={'button'}
							to={`#`}
							title={`Click to Delete Sandbox`}
							onClick={() => {
								openDialog(params.sandboxId, 'CANCELLED', props.token);
							}}>
							<i className="fa-solid fa-trash-can"></i>
							Delete Sandbox
							{visible && dialogState === 'CANCELLED' && DialogWindow(params.sandboxId, 'CANCELLED')}
						</NavLink>

						<NavLink className={'nav-link'} role={'button'} to={`/sandbox/view/${params.sandboxId}`} title={'View Lift Request'}>
							<i className="fa-solid fa-eye"></i>
							View Sandbox
						</NavLink>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	const onFormSubmit = React.useCallback((event) => {
		const { values } = event;

		let copyLiftRequestParamString = `?sandboxId=${params.sandboxId}&state=VALIDATED`;

		// If item in removedArray and also in initial array. only then set state to REGRETTED
		// Lift Request Removed Items
		// eslint-disable-next-line
		removedLrIdArray.map((lrId) => {
			if (initialLrIdArray.includes(lrId)) {
				ApiChangeLiftRequestState(lrId, 'REGRETTED').then((res) => {
					if (process.env.REACT_APP_DEBUG === 'true') {
						console.log(res);
					}
				});
			}
		});

		// Airport Removed Items
		// eslint-disable-next-line
		removedAirportIdArray.map((airportId) => {
			if (initialAirportIdArray.includes(airportId)) {
				ApiDeleteAirportRequest(airportId).then((res) => {
					if (process.env.REACT_APP_DEBUG === 'true') {
						console.log(res);
					}
				});
			}
		});

		// Asset Removed Items
		// eslint-disable-next-line
		removedAssetIdArray.map((assetId) => {
			if (initialAssetIdArray.includes(assetId)) {
				ApiDeleteAssetRequest(assetId).then((res) => {
					if (process.env.REACT_APP_DEBUG === 'true') {
						console.log(res);
					}
				});
			}
		});

		// If item in addedLrIdArray, then add to bulk copy list
		// Lift Request Added Items
		if (addedLrIdArray.length > 0) {
			ApiBulkCopyLiftRequests(addedLrIdArray, copyLiftRequestParamString).then((res) => {
				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log(res);
				}
			});
		}

		if (addedAssetIdArray.length > 0) {
			ApiBulkCopyAssets(addedAssetIdArray, copyLiftRequestParamString).then((res) => {
				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log(res);
				}
			});
		}

		if (addedAirportIdArray.length > 0) {
			ApiBulkCopyAirports(addedAirportIdArray, copyLiftRequestParamString).then((res) => {
				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log(res);
				}
			});
		}

		// only need to call edit sandbox endpoint if the name has been modified
		if (values.sandboxName !== data.history[0].name) {
			let sandboxUpdateObj = {
				name: values.sandboxName,
				state: 'GENERATED',
				createdBy: {
					id: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.id ? props.keycloak.profile.id : null,
					firstName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.firstName ? props.keycloak.profile.firstName : null,
					lastName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.lastName ? props.keycloak.profile.lastName : null,
					email: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.email ? props.keycloak.profile.email : null,
					phoneNumber: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.phoneNumber ? props.keycloak.profile.phoneNumber : null,
					rank: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.rank ? props.keycloak.profile.rank : null,
					dutyTitle: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.dutyTitle ? props.keycloak.profile.dutyTitle : null,
					unit: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.unit ? props.keycloak.profile.unit : null,
					branchOfService: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.branchOfService ? props.keycloak.profile.branchOfService : null
				}
			};

			ApiUpdateSandboxById(params.sandboxId, sandboxUpdateObj).then((res) => {
				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log(res);
				}
			});
		}

		toast.success('Sandbox Edited Successfully!');
		navigate('/sandbox/dashboard');
	});

	const initialDataFetch = () => {
		let lrIdList = [];
		let assetIdList = [];
		let airportIdList = [];

		ApiSandboxById(params.sandboxId).then((res) => {
			setSandboxDetails(res);
		});

		let sandboxIdLiftRequestParamString = `?sandboxId=${params.sandboxId}&states=SUBMITTED&states=AUTHORIZED&states=CLAIMED&states=VALIDATED&states=UNSATISFIED&states=SCHEDULED&states=IN_TRANSIT&states=SATISFIED`;

		let sandboxIdParamString = `?sandboxId=${params.sandboxId}`;

		ApiLiftRequestList(sandboxIdLiftRequestParamString).then((res) => {
			setLifts(res);
			// eslint-disable-next-line
			res.map((liftRequest) => {
				lrIdList.push(liftRequest.id);
			});
		});

		ApiAssetsList(sandboxIdParamString).then((res) => {
			setAssets(res);
			// eslint-disable-next-line
			res.map((asset) => {
				assetIdList.push(asset.id);
			});
		});

		ApiAirportsList(sandboxIdParamString).then((res) => {
			setAirports(res);
			// eslint-disable-next-line
			res.map((airport) => {
				airportIdList.push(airport.id);
			});
		});

		setLrIdArray(lrIdList);
		setAssetIdArray(assetIdList);
		setAirportIdArray(airportIdList);

		setInitialLrIdArray(lrIdList);
		setInitialAssetIdArray(assetIdList);
		setInitialAirportIdArray(airportIdList);
	};

	useEffect(() => {
		setTimeout(() => {
			setLoaded(true);
		}, 500);
	}, [props]);

	useEffect(() => {
		initialDataFetch();
		setTimeout(() => {
			setLoaded(true);
		}, 500);
	}, []);

	useEffect(() => {
		var tempAddedIdArray = [];

		// eslint-disable-next-line
		lrIdArray.map((lrId) => {
			if (!initialLrIdArray.includes(lrId)) {
				//if intialLrIdArray DOESNT include id, then it was added, so add it to addedLrIdArray
				tempAddedIdArray.push(lrId);
			}
		});

		setAddedLrIdArray([...tempAddedIdArray]);
	}, [lrIdArray]);

	useEffect(() => {
		var tempLiftsArray = [...lifts];

		// map through added lift requests and if id isn't found in lrIdArray, then call lift request by that id and add that res to setLifts array. (in other useEffect)
		// eslint-disable-next-line
		addedLrIdArray.map((newId) => {
			let match = false;
			// eslint-disable-next-line
			lifts.map((lift) => {
				if (lift.id === newId) {
					match = true;
				}
			});
			if (!match) {
				// need to call lift request by id with added ids, then add those items mapped to the lifts array
				ApiLiftRequestById(newId).then((res) => {
					tempLiftsArray.push(res);
					setLifts([...tempLiftsArray]);
					setFormKey(tempLiftsArray);
				});
			}
			setLifts([...tempLiftsArray]);
		});
	}, [addedLrIdArray]);

	useEffect(() => {
		var tempAddedIdArray = [];

		// eslint-disable-next-line
		airportIdArray.map((airportId) => {
			if (!initialAirportIdArray.includes(airportId)) {
				tempAddedIdArray.push(airportId);
			}
		});

		setAddedAirportIdArray([...tempAddedIdArray]);
	}, [airportIdArray]);

	useEffect(() => {
		var tempAirportsArray = [...airports];

		// eslint-disable-next-line
		addedAirportIdArray.map((newId) => {
			let match = false;
			// eslint-disable-next-line
			airports.map((airport) => {
				if (airport.id === newId) {
					match = true;
				}
			});
			if (!match) {
				ApiAirportById(newId).then((res) => {
					tempAirportsArray.push(res);
					setAirports([...tempAirportsArray]);
					setFormKey(tempAirportsArray);
				});
			}
			setAirports([...tempAirportsArray]);
		});
	}, [addedAirportIdArray]);

	useEffect(() => {
		var tempAddedIdArray = [];

		// eslint-disable-next-line
		assetIdArray.map((assetId) => {
			if (!initialAssetIdArray.includes(assetId)) {
				tempAddedIdArray.push(assetId);
			}
		});

		setAddedAssetIdArray([...tempAddedIdArray]);
	}, [assetIdArray]);

	useEffect(() => {
		var tempAssetsArray = [...assets];

		// eslint-disable-next-line
		addedAssetIdArray.map((newId) => {
			let match = false;
			// eslint-disable-next-line
			assets.map((asset) => {
				if (asset.id === newId) {
					match = true;
				}
			});
			if (!match) {
				ApiAssetById(newId).then((res) => {
					tempAssetsArray.push(res);
					setAssets([...tempAssetsArray]);
					setFormKey(tempAssetsArray);
				});
			}
			setAssets([...tempAssetsArray]);
		});
	}, [addedAssetIdArray]);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						{loaded ? (
							<React.Fragment>
								<div className={'page-title'}>
									<h2>Sandbox: {sandboxTitle}</h2>

									{PageTitleControls()}
								</div>

								<div className={'lift-request-meta-data'}>
									<ul>
										<li>Status: {data.history[0] && <span className={`pill ${data.history[0].state.toLowerCase()} lift-status-${data.history[0].state.toLowerCase()}`}>{data.history[0].state}</span>}</li>
										<li>Version of Sandbox: {data.history && String(data.history.length)}</li>
									</ul>
								</div>

								<Form
									onSubmitClick={onFormSubmit}
									key={formKey}
									initialValues={{
										sandboxName: data.history && data.history[0] && data.history[0].name ? data.history[0].name : ''
									}}
									render={(formRenderProps) => (
										<div>
											<FormElement>
												<Row id={'sandbox-name-information'} className={'form-section row'}>
													<div className={'col-3'}>
														<div className={'form-group'}>
															<Field name={'sandboxName'} key={'sandboxName'} value={data.history && data.history[0] && data.history[0].name ? data.history[0].name : ''} id={'sandboxName'} label={'Sandbox Name'} placeholder={'Enter a name for your environment...'} component={FormInput} />
														</div>
													</div>
												</Row>

												<Row id={'sandbox-infotip'} className={'form-section row padding-top-0'}>
													<p>* Items added to this sandbox will need to be finalized by saving before they can be edited</p>
												</Row>

												<Row id={'sandbox-details-list'} className={'form-section row'}>
													<div className={'col-4'}>
														<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
															<strong>Selected Lift Requests</strong>
														</h4>
													</div>

													<div className={'col-8'}>
														<Button style={{ float: 'right' }} className={`btn btn-primary margin-right-o 'btn-next'}`} type={'button'} themecolor={'primary'} onClick={() => enterEditForm('liftRequest')} title={`Click to View Lift Requests`}>
															View Lift Requests
														</Button>
													</div>
												</Row>

												{openLiftRequestForm && <SandboxSelectionDialog environment={'selection'} queryParams={accessStates} cancelEdit={handleCancelEdit} onSubmit={handleSubmit} lrIdArray={lrIdArray} setPotentialLrIdArray={setPotentialLrIdArray} buttonString={'Lift Requests'} props={props} />}

												<div className={'padding-bottom-40'}>
													<LiftRequestListGrid environment={'sandbox'} page={'sandboxEdit'} data={lifts} handleRemoveLiftRequest={handleRemoveLiftRequest} />
												</div>

												<Row id={'sandbox-details-list'} className={'form-section row'}>
													<div className={'col-4'}>
														<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
															<strong>Selected Assets</strong>
														</h4>
													</div>

													<div className={'col-8'}>
														<Button style={{ float: 'right' }} className={`btn btn-primary margin-right-o 'btn-next'}`} type={'button'} themecolor={'primary'} onClick={() => enterEditForm('asset')} title={`Click to View Assets`}>
															View Assets
														</Button>
													</div>
												</Row>

												{openAssetForm && <SandboxSelectionDialog environment={'selection'} cancelEdit={handleCancelEdit} onSubmit={handleSubmit} assetIdArray={assetIdArray} setPotentialAssetIdArray={setPotentialAssetIdArray} buttonString={'Assets'} props={props} />}

												<div className={'padding-bottom-40'}>
													<AssetAvailabilityList environment={'sandbox'} page={'sandboxEdit'} data={assets} handleRemoveAssets={handleRemoveAssets} />
												</div>

												<Row id={'sandbox-details-list'} className={'form-section row'}>
													<div className={'col-4'}>
														<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
															<strong>Selected Airports</strong>
														</h4>
													</div>

													<div className={'col-8'}>
														<Button style={{ float: 'right' }} className={`btn btn-primary margin-right-o 'btn-next'}`} type={'button'} themecolor={'primary'} onClick={() => enterEditForm('airport')} title={`Click to View Airports`}>
															View Airports
														</Button>
													</div>
												</Row>

												{openAirportForm && <SandboxSelectionDialog cancelEdit={handleCancelEdit} queryParams={accessStates} onSubmit={handleSubmit} airportIdArray={airportIdArray} setPotentialAirportIdArray={setPotentialAirportIdArray} buttonString={'Airports'} props={props} />}

												<div className={'padding-bottom-40'}>
													<AirportsListGrid environment={'sandbox'} page={'sandboxEdit'} data={airports} handleRemoveAirports={handleRemoveAirports} />
												</div>

												<div>
													<Button style={{ float: 'right' }} title={'Click to Save Sandbox'} className={'btn btn-size-medium btn-primary btn-color-green margin-right-0 btn-submit'} onClick={formRenderProps.onSubmit}>
														Save Sandbox
														<i className={'far fa-arrow-right padding-left-10'}></i>
													</Button>

													<Button style={{ float: 'right' }} title={'Click to Cancel Sandbox Edit'} className={'btn btn-primary btn-size-medium btn-color-red btn-icon-remove'} onClick={cancelSandboxEdit}>
														Cancel
													</Button>
												</div>
											</FormElement>
										</div>
									)}
								/>
							</React.Fragment>
						) : (
							<Loader />
						)}
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

export default SandboxEdit;
