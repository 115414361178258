/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import { Col, Container, Row } from 'react-bootstrap';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import MissionsListTabs from '../../App/MissionsListGrid/MissionsListTabs';

const MissionsList = (props) => {
	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						<MissionsListTabs />
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};
export default MissionsList;
