/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Col, Container, Row } from 'react-bootstrap';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';
import Loader from '../../Core/Loader';

import { ApiOrgSquadronList } from '../../Helpers/Apis';

const SquadronView = (props) => {
	const [loaded, setLoaded] = React.useState(false);
	const [squadronDetails, setSquadronDetails] = React.useState([]);

	const params = useParams();

	const dataFetch = () => {
		ApiOrgSquadronList(params.squadronId).then((res) => {
			setSquadronDetails(res);
			setLoaded(true);
		});
	};

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto"></Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	useEffect(() => {
		dataFetch();
	}, []);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						{loaded ? (
							<React.Fragment>
								{/* eslint-disable-next-line */}
								{squadronDetails.map((r, i) => {
									if (parseInt(r.id) === parseInt(params.squadronId)) {
										return (
											<React.Fragment key={i}>
												<div className={'page-title'}>
													<h2>Squadron {r.squadronDesignation}</h2>
													{PageTitleControls()}
												</div>

												<Row id={'requester-information'} className={'form-section row'}>
													<div className={'col-12 form-section-title'}>
														<h3 className={'padding-bottom-0'}>Squadron Details</h3>
													</div>

													<div style={{ maxWidth: '750px' }}>
														<div className={'col-12'}>
															<label>Fixed Wing Squadron</label>
															<div className={'static-field-read-only static-text'}>{r.fixedWingSquadron}</div>
														</div>

														<div className={'col-12'}>
															<label>Squadron Designation</label>
															<div className={'static-field-read-only static-text'}>{r.squadronDesignation}</div>
														</div>

														<div className={'col-12'}>
															<label>Nickname</label>
															<div className={'static-field-read-only static-text'}>{r.nickname}</div>
														</div>

														<div className={'col-12'}>
															<label>Aircraft</label>
															<div className={'static-field-read-only static-text'}>{r.aircraft}</div>
														</div>

														<div className={'col-12'}>
															<label>Operational and Administrative Commander</label>
															<div className={'static-field-read-only static-text'}>{r.operationalAndAdministrativeCommander}</div>
														</div>

														<div className={'col-12'}>
															<label>Squadron Lineage</label>
															<div className={'static-field-read-only static-text'}>{r.squadronLineage}</div>
														</div>

														<div className={'col-12'}>
															<label>Notes</label>
															<div className={'static-field-read-only static-text'}>{r.notes}</div>
														</div>
													</div>
												</Row>
											</React.Fragment>
										);
									}
								})}
							</React.Fragment>
						) : (
							<Loader />
						)}
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SquadronView);
