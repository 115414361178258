/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import { convert } from 'geo-coordinates-parser';

/**
 * Convert String to Uppercase First Letter
 * @param str
 * @returns {string}
 * @constructor
 */
export const CapitalizeString = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * Strip HTML Tags from Content
 * @param html
 * @returns {string|string}
 */
export const stripHtml = (html) => {
	const doc = new DOMParser().parseFromString(html, 'text/html');

	return doc.body.textContent || '';
};

/**
 * Render HTML Content
 * @param data
 * @returns {JSX.Element}
 */
export const htmlRender = (data) => {
	const str = { __html: data };

	return <div dangerouslySetInnerHTML={str} />;
};

/**
 * String to Object (used with Keycloak Profile Attributes)
 * @param str
 * @returns {*}
 */
export const str2Obj = (str) => {
	return str
		.split(',')
		.map((keyVal) => {
			return keyVal.split(':').map((_) => _.trim());
		})
		.reduce((accumulator, currentValue) => {
			accumulator[currentValue[0]] = currentValue[1];

			return accumulator;
		}, {});
};

/**
 * Add Commas to Number
 * @param val
 * @returns {string}
 */
export const numberWithCommas = (val) => {
	if (val !== null) {
		return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
};

/**
 * Compare Two Strings for Match
 * @param s1
 * @param s2
 * @returns {boolean}
 */
export const diff = (s1, s2) => {
	if (s1 === s2) {
		return true;
	} else {
		return false;
	}
};

/**
 * Verify that a latitude and longitude pair are valid
 * @param latitude
 * @param longitude
 * @returns {boolean}
 */
export const verifyLatitudeLongitude = (latitude, longitude) => {
	if (latitude !== null && longitude !== null) {
		try {
			convert(latitude.toFixed(8).toString() + ',' + longitude.toFixed(8).toString(), 8);
			return true;
		} catch {
			return false;
		}
	}
	return false;
};

/**
 * Convert Latitude Longitude Pair to Degrees Minutes
 * @param latitude
 * @param longitude
 * @param type flag to determine to return latitude (0) or longitude (1)to return
 * @returns {string}
 */
export const getLatitudeLongitudeDegreesMinutes = (latitude, longitude, type) => {
	let isInt = false;
	if (Number.isInteger(latitude) || Number.isInteger(longitude)) {
		isInt = true;
	}

	if (latitude !== undefined && longitude !== undefined && !isInt) {
		let latLongPieces;
		let latLongConverted;
		if (latitude !== null && longitude !== null) {
			if (typeof latitude === 'number') {
				latitude = latitude.toFixed(8);
			}

			if (typeof longitude === 'number') {
				longitude = longitude.toFixed(8);
			}

			latLongConverted = convert(latitude + ',' + longitude, 8);
			latLongPieces = latLongConverted.toCoordinateFormat(convert.to.DM).split(', ');
		}
		return latLongPieces[type];
	}
};

/**
 * Convert Latitude Longitude Pair to Decimal Degrees
 * @param latitude
 * @param longitude
 * @param type flag to determine to return latitude (0) or longitude (1)to return
 * @returns {string}
 */
export const getLatitudeLongitudeDecimalDegrees = (latitude, longitude, type) => {
	let isInt = false;
	if (Number.isInteger(latitude) || Number.isInteger(longitude)) {
		isInt = true;
	}
	if (latitude !== undefined && longitude !== undefined && !isInt) {
		let latLongPieces;
		let latLongConverted;
		if (latitude !== null && longitude !== null) {
			if (typeof latitude === 'number') {
				latitude = latitude.toFixed(8);
			}

			if (typeof longitude === 'number') {
				longitude = longitude.toFixed(8);
			}

			latLongConverted = convert(latitude + ',' + longitude, 8);
			latLongPieces = latLongConverted.toCoordinateFormat(convert.to.DD).split(',');
		}
		return latLongPieces[type];
	}
};
