/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import * as Actions from '../../../store/actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Stepper } from '@progress/kendo-react-layout';

import { IntroDetails } from '../../App/NewLiftRequestWizardElements/IntroDetails';
import { PriorityDetails } from '../../App/NewLiftRequestWizardElements/PriorityDetails';
import { ItineraryDetails } from '../../App/NewLiftRequestWizardElements/ItineraryDetails';
import { PassengersCargoDetails } from '../../App/NewLiftRequestWizardElements/PassengersCargoDetails';
import { SummaryDetails } from '../../App/NewLiftRequestWizardElements/SummaryDetails';
import { ApiNewLiftRequestForm } from '../../Helpers/Apis';
import { forceDateStringToUTCISOFormat } from '../../Helpers/DateTimeConversions';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';
import { toast } from 'react-toastify';

export const FormContext = React.createContext({});
var cargoData = [];

const pull_data = (data) => {
	cargoData = data;
};

const getStep = (step, props, post, token) => {
	switch (step) {
		case 0:
			return <IntroDetails {...props} post={post} token={token} />;
		case 1:
			return <PriorityDetails {...props} post={post} token={token} />;
		case 2:
			return <ItineraryDetails {...props} post={post} token={token} />;
		case 3:
			return <PassengersCargoDetails {...props} setCargoData={pull_data} cargoData={cargoData} post={post} token={token} />;
		default:
			return <SummaryDetails {...props} cargoData={cargoData} post={post} token={token} />;
	}
};

const NewLiftRequestWizard = (props) => {
	const [post, setPost] = React.useState('loading...');
	const [step, setStep] = React.useState(0);
	const [formState, setFormState] = React.useState({});
	const [steps, setSteps] = React.useState([
		{
			label: 'Introduction',
			icon: '',
			isValid: undefined
		},
		{
			label: 'Priority',
			icon: '',
			isValid: undefined
		},
		{
			label: 'Itinerary',
			icon: '',
			isValid: undefined
		},
		{
			label: 'Passengers/Cargo',
			icon: '',
			isValid: undefined
		},
		{
			label: 'Summary',
			icon: '',
			isValid: undefined
		}
	]);

	const lastStepIndex = steps.length - 1;
	const isLastStep = lastStepIndex === step;
	const isPreviousStepsValid = steps.slice(0, step).findIndex((currentStep) => currentStep.isValid === false) === -1;
	const navigate = useNavigate();

	const onStepSubmit = React.useCallback(
		(event) => {
			const { isValid, values } = event;
			const currentSteps = steps.map((currentStep, index) => ({
				...currentStep,
				isValid: index === step ? isValid : currentStep.isValid
			}));

			setSteps(currentSteps);

			setStep(() => Math.min(step + 1, lastStepIndex));

			setFormState(values);

			const data = JSON.stringify(values);
			const obj = JSON.parse(data);

			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log(obj);
			}

			let cargoPassengers = {};
			let submitCargoArray = [];

			if (obj.arrival) {
				delete ['obj.arrival.airport'];
			}

			if (obj.departure) {
				delete ['obj.departure.airport'];
			}

			if (obj.departureAlternativeAirports) {
				delete obj['departureAlternativeAirports'];
			}

			if (obj.arrivalAlternativeAirports) {
				delete obj['arrivalAlternativeAirports'];
			}

			obj.departure = {};
			obj.departure.airport = {};
			obj.departure.airport.earliest = {};
			obj.departure.airport.latest = {};
			obj.departure.airport = values.departure !== undefined ? values.departure.airport.history[0] : null;
			obj.departure.earliest = values.departure !== undefined ? forceDateStringToUTCISOFormat(values.departure.earliest) : null;
			obj.departure.latest = values.departure !== undefined ? forceDateStringToUTCISOFormat(values.departure.latest) : null;

			obj.arrival = {};
			obj.arrival.airport = {};
			obj.arrival.airport.earliest = {};
			obj.arrival.airport.latest = {};
			obj.arrival.airport = values.arrival !== undefined ? values.arrival.airport.history[0] : null;
			obj.arrival.earliest = values.arrival !== undefined ? forceDateStringToUTCISOFormat(values.arrival.earliest) : null;
			obj.arrival.latest = values.arrival !== undefined ? forceDateStringToUTCISOFormat(values.arrival.latest) : null;

			// If cargo Object Exists
			if (obj.cargo) {
				// PASSENGERS Check
				if (values.cargo.hasPassengers) {
					// PASSENGERS Object: Pushed to Form Cargo Array Before Submission
					cargoPassengers = {
						type: 'PASSENGERS',
						count: values.cargo.paxCount ? values.cargo.paxCount : null
					};

					submitCargoArray.push(cargoPassengers);
				}

				if (values.cargo.hasPallets) {
					// eslint-disable-next-line
					cargoData.map((pallet) => {
						let palletObj = {};
						palletObj = {
							type: 'PALLETS',
							cargoName: pallet.PalletName ? pallet.PalletName : null,
							count: pallet.count ? pallet.count : null,
							weight: pallet.cargoTotalWeight ? pallet.cargoTotalWeight : null,
							totalCubicFeet: pallet.cargoTotalCubicFeet ? pallet.cargoTotalCubicFeet : null,
							description: pallet.description ? pallet.description : null,
							oversize: pallet.oversize ? pallet.oversize : false,
							rollingStock: pallet.rollingStock ? pallet.rollingStock : false,
							bellyLoadable: pallet.bellyLoadable ? pallet.bellyLoadable : false,
							largestItem: {
								length: pallet.cargoLargestItemDimensionsLength ? pallet.cargoLargestItemDimensionsLength : null,
								width: pallet.cargoLargestItemDimensionsWidth ? pallet.cargoLargestItemDimensionsWidth : null,
								height: pallet.cargoLargestItemDimensionsHeight ? pallet.cargoLargestItemDimensionsHeight : null,
								weight: pallet.cargoLargestItemDimensionsWeight ? pallet.cargoLargestItemDimensionsWeight : null
							},
							heaviestItem: {
								length: pallet.cargoHeaviestItemLength ? pallet.cargoHeaviestItemLength : null,
								width: pallet.cargoHeaviestItemWidth ? pallet.cargoHeaviestItemWidth : null,
								height: pallet.cargoHeaviestItemHeight ? pallet.cargoHeaviestItemHeight : null,
								weight: pallet.cargoHeaviestItemWeight ? pallet.cargoHeaviestItemWeight : null
							},
							hazmat: {
								containsHazmat: pallet.hazmat && pallet.hazmat.containsHazmat ? pallet.hazmat.containsHazmat : false,
								hazmatCargoes: []
							},
							specialHandlingRequirements: pallet.specialHandlingRequirements ? pallet.specialHandlingRequirements : null
						};

						if (pallet.hazmat.containsHazmat && pallet.hazmat.hazmatCargoes.length > 0) {
							// create hazmatArray and push it to hazmatCargoes
							// eslint-disable-next-line
							pallet.hazmat.hazmatCargoes.map((hazmat) => {
								let hazmatObj = {};
								hazmatObj = {
									hazmatName: hazmat.PalletName ? hazmat.PalletName : null,
									unNumber: hazmat.unNumber ? hazmat.unNumber : null,
									shippingName: hazmat.shippingName ? hazmat.shippingName : null,
									shippingDescription: hazmat.shippingDescription ? hazmat.shippingDescription : null,
									hazardClassAndDivision: hazmat.hazardClassAndDivision ? hazmat.hazardClassAndDivision : null,
									passengerEligibilityCode: hazmat.passengerEligibilityCode ? hazmat.passengerEligibilityCode : null,
									explosiveCompatibilityGroup: hazmat.explosiveCompatibilityGroup ? hazmat.explosiveCompatibilityGroup : null,
									cargo: {
										length: hazmat.cargoLength ? hazmat.cargoLength : null,
										width: hazmat.cargoWidth ? hazmat.cargoWidth : null,
										height: hazmat.cargoHeight ? hazmat.cargoHeight : null,
										weight: hazmat.cargoWeight ? hazmat.cargoWeight : null
									},
									netExplosiveWeight: hazmat.netExplosiveWeight ? hazmat.netExplosiveWeight : null
								};
								palletObj.hazmat.hazmatCargoes.push(hazmatObj);
							});
						}
						submitCargoArray.push(palletObj);
					});
				}
				delete obj['cargo'];
				obj.cargoes = submitCargoArray;
			}

			if (obj.comments) {
				delete obj['comments'];
				const commentsArray = [];
				let commentObj = {};
				commentObj.commenter = {};
				commentObj.text = values.comments.text;
				commentObj.commenter.firstName = props.keycloak.profile.firstName;
				commentObj.commenter.lastName = props.keycloak.profile.lastName;
				commentsArray.push(commentObj);
				obj.comments = commentsArray;
			}

			if (obj.shareEmails) {
				delete obj.shareEmails;
				const shareEmailsArray = [];
				values.shareEmails.map((element) => {
					return shareEmailsArray.push(element.shareEmail);
				});
				obj.shareEmails = shareEmailsArray;
			}

			if (obj.requester !== undefined && obj.requester.unit !== undefined) {
				delete obj['requester.unit'];
				obj.requester.unit = {
					id: values.requester.unit.id,
					nra: values.requester.unit.nra,
					uic: values.requester.unit.uic
				};
			}

			obj.departure.alternateAirports = values.departureAlternativeAirports;
			obj.arrival.alternateAirports = values.arrivalAlternativeAirports;

			if (obj.requester == null) {
				obj.requester = {};
			}

			if (obj.requester !== undefined && obj.requester.rank !== undefined) {
				delete obj.requester.rank;
				obj.requester.rank = {};
				obj.requester.rank.grade = values.requester.rank.grade;
				obj.requester.rank.insignia = values.requester.rank.insignia;
				obj.requester.rank.title = values.requester.rank.title;
				obj.requester.rank.abbreviation = values.requester.rank.abbr;
				obj.requester.rank.rank = values.requester.rank.class;
				obj.requester.rank.gradeTitle = values.requester.rank.gradetitle;
			}

			if (obj.requesterFirstName) {
				delete obj['requesterFirstName'];
			}

			if (obj.requesterLastName) {
				delete obj['requesterLastName'];
			}

			if (obj.requesterEmail) {
				delete obj['requesterEmail'];
			}

			if (obj.priority) {
				delete obj['priority'];
			}

			if (obj.urgency) {
				delete obj['urgency'];
			}

			/**
			 * Set Requester ID
			 * Temporary Solution: If "Requester" and not "NALO, Approver, or Squadron"
			 * Future solution is to use original requester's ID, as well as allow
			 * other requester's (shared LR) to update and save information. Additionally,
			 * NALO will eventually be able to select who is the owner of the LR.
			 * Currently, no solution to allow for multiple requester ID's for ownership. Only
			 * one owner available at this time.
			 */
			if (props.roleaccess.includes('requester') && !props.roleaccess.includes('approver') && !props.roleaccess.includes('nalo')) {
				obj.requester.id = props.userid;
			}

			obj.urgency = values.urgency !== undefined && values.urgency.value !== undefined ? values.urgency.value : null;
			obj.priority = values.priority !== undefined && values.priority.value !== undefined ? values.priority.value : null;
			obj.requester.firstName = values.requesterFirstName;
			obj.requester.lastName = values.requesterLastName;
			obj.requester.email = values.requesterEmail;

			// Click Event: Save Draft
			if (event.event.target.innerText === 'SAVE DRAFT') {
				const openedObject = obj;
				openedObject.state = 'OPENED';
				const openData = JSON.stringify(openedObject);

				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log('SAVE DRAFT API DATA: ', openData);
				}

				// To API (State = OPENED)
				ApiNewLiftRequestForm(openData).then((res) => {
					toast.success('Lift Request Draft Saved Successfully!');
					navigate('/dashboard');
				});
			}

			// Click Event: Submit
			if (event.event.target.innerText === 'SUBMIT') {
				if (isLastStep && isPreviousStepsValid && isValid) {
					const submittedObject = obj;
					submittedObject.state = 'SUBMITTED';
					const submitData = JSON.stringify(submittedObject);

					if (process.env.REACT_APP_DEBUG === 'true') {
						console.log('SUBMIT API DATA: , ', submittedObject);
					}

					// To API (State = SUBMITTED)
					ApiNewLiftRequestForm(submitData).then((res) => {
						toast.success('Lift Request Submitted Successfully!');
						navigate('/dashboard');
					});
				}
			}
		},
		[steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
	);

	const onPrevClick = React.useCallback(
		(event) => {
			event.preventDefault();
			setStep(() => Math.max(step - 1, 0));
		},
		[step, setStep]
	);

	const [formKey, setFormKey] = React.useState(new Date());

	React.useEffect(() => {
		cargoData = [];
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		};

		fetch('https://jsonplaceholder.typicode.com/posts/1', requestOptions)
			.then((response) => response.json())
			.then((response) => {
				setPost(response.body);
				setFormKey(new Date());
			});
	}, []);

	const initValues = {
		...formState,
		post: post,

		requesterFirstName: props.firstname,
		requesterLastName: props.lastname,
		requesterEmail: props.profile.email
	};

	return (
		<React.Fragment>
			<Container fluid className={'app-content'}>
				<Row>
					<Col>
						<Panel>
							<div className={'page-title padding-bottom-0'}>
								<h2>New Military Air Passenger/Cargo Request</h2>
							</div>

							<div className={'lr-step-wizard-wrapper'}>
								<Stepper value={step} items={steps} />

								<Form
									key={formKey}
									initialValues={initValues}
									onSubmitClick={onStepSubmit}
									render={(formRenderProps) => (
										<div className={'lift-request-form short-form lift-request-form-wizard'}>
											<div className={'lift-request-form-inner'}>
												<FormElement>
													<div className={'form-wizard-header'}>
														<span className={'form-wizard-step-counter'}>Step {step + 1} of 5</span>

														<div className={'form-wizard-step-action-buttons form-submission-buttons'}>
															<ul>
																{step !== 0 ? (
																	<li>
																		<Button className={'btn btn-size-medium btn-primary btn-previous'} onClick={onPrevClick} title={'Click to View Previous Items'}>
																			<i className="fa-regular fa-chevron-left"></i>
																			Previous
																		</Button>
																	</li>
																) : undefined}

																<li>
																	<Button className={`btn btn-size-medium btn-primary margin-right-o ${isLastStep ? 'btn-submit btn-color-green' : 'btn-next'}`} themeColor={'primary'} disabled={isLastStep ? !isPreviousStepsValid : false} onClick={formRenderProps.onSubmit} title={`${isLastStep ? 'Click to Submit' : 'Click to View Next Items'}`}>
																		{isLastStep ? 'Submit' : 'Next'}
																		<i className={`fa-regular ${isLastStep ? 'fa-check' : 'fa-chevron-right'}`}></i>
																	</Button>
																</li>
																{step === 4 ? (
																	<li>
																		<Button title={'Click to Save Draft'} className={'btn btn-size-medium btn-primary btn-color-blue btn-save-draft'} type={'submit'} onClick={formRenderProps.onSubmit}>
																			Save Draft
																			<i className={'fa-regular fa-floppy-disk'}></i>
																		</Button>
																	</li>
																) : undefined}
															</ul>
														</div>
													</div>

													<div className={'form-fields-wrapper'}>{getStep(step, formRenderProps, post, props.token)}</div>

													<div className={'form-wizard-footer'}>
														<span className={'form-wizard-step-counter'}>Step {step + 1} of 5</span>

														<div className={'form-wizard-step-action-buttons form-submission-buttons'}>
															<ul>
																{step !== 0 ? (
																	<li>
																		<Button className={'btn btn-size-medium btn-primary btn-previous'} onClick={onPrevClick} title={'Click to View Previous Items'}>
																			<i className="fa-regular fa-chevron-left"></i>
																			Previous
																		</Button>
																	</li>
																) : undefined}

																<li>
																	<Button className={`btn btn-size-medium btn-primary margin-right-o ${isLastStep ? 'btn-submit btn-color-green' : 'btn-next'}`} themeColor={'primary'} disabled={isLastStep ? !isPreviousStepsValid : false} onClick={formRenderProps.onSubmit} title={`${isLastStep ? 'Click to Submit' : 'Click to View Next Items'}`}>
																		{isLastStep ? 'Submit' : 'Next'}
																		<i className={`fa-regular ${isLastStep ? 'fa-check' : 'fa-chevron-right'}`}></i>
																	</Button>
																</li>
																{step === 4 ? (
																	<li>
																		<Button title={'Click to Save Draft'} className={'btn btn-size-medium btn-primary btn-color-blue btn-save-draft'} type={'submit'} onClick={formRenderProps.onSubmit}>
																			Save Draft
																			<i className={'fa-regular fa-floppy-disk'}></i>
																		</Button>
																	</li>
																) : undefined}
															</ul>
														</div>
													</div>
												</FormElement>
											</div>
										</div>
									)}
								/>
							</div>
						</Panel>

						<Footer />
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLiftRequestWizard);
