/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';

import { Form, Field } from '@progress/kendo-react-form';
import { FormUpload } from '../../App/KendoFormElements/FormComponents';
import { ApiUploadLiftRequestData } from '../../Helpers/Apis';
import { toast } from 'react-toastify';

const LiftRequestImportWindow = (props) => {
	const [visible, setVisible] = React.useState(false);
	const [formState, setFormState] = React.useState({});
	const [serviceResponse, setServiceResponse] = React.useState({});
	const [uploaded, setUploaded] = React.useState(false);

	const toggleDialog = () => {
		setFormState({});
		setServiceResponse({});
		setVisible(!visible);
		setUploaded(false);
	};

	const showFailedImports = () => {
		let invCsv = [];
		let invDb = [];
		if (serviceResponse && serviceResponse.inValidCsvRecord !== null) {
			invCsv = serviceResponse.inValidCsvRecord.map((invalidRecord) => {
				return 'Request Name: ' + invalidRecord.requestName + '   Error: ' + invalidRecord.errorMessage[0] + '\n';
			});
		}

		if (serviceResponse && serviceResponse.dbFailedImport !== null) {
			invDb = serviceResponse.dbFailedImport.map((dbFailedObj) => {
				return 'Request Name: ' + dbFailedObj.requestName + '   Error: Database Error\n';
			});
		}
		invCsv.concat(invDb);
		alert(invCsv.join(''));
	};

	// Form Initial Values
	const initValues = {
		...formState
	};

	const onFormSubmit = React.useCallback((event) => {
		const { values } = event;

		setFormState(values);

		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(values);
		}

		const formData = new FormData();
		formData.append('file', values.fileUpload[0].getRawFile());

		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(formData);
		}

		ApiUploadLiftRequestData(formData).then((res) => {
			setServiceResponse(res);

			// Notifications
			if (res.success === 0 && res.failure > 0) {
				toast.error('Submission Failed.');
			} else if (res.success > 0 && res.failure > 0) {
				toast.warning('Successfully Uploaded with Errors.');
			} else if (res.success > 0 && res.failure === 0) {
				toast.success('Successfully Uploaded!');
				setUploaded(true);
			}

			setTimeout(() => {
				//setFormState({});
				//setServiceResponse({});
				//setVisible(false);
			}, 6000);
		});
	}, []);

	return (
		<React.Fragment>
			<button className={`${props.classes}`} onClick={toggleDialog} title={'Click to Upload Lift Requests from CSV'}>
				{props.showIcon && <i className="far fa-upload"></i>}
				Upload Lift Requests
			</button>
			{visible && (
				<Dialog title={'Lift Request Bulk Upload'} onClose={toggleDialog}>
					<Form
						className="k-form"
						onSubmitClick={onFormSubmit}
						key={1}
						initialValues={initValues}
						render={(formRenderProps) => (
							<React.Fragment>
								{Object.keys(serviceResponse).length === 0 ? (
									<React.Fragment>
										<fieldset className={'padding-bottom-20'}>
											<p className={'padding-bottom-10'}>To begin uploading your lift requests, please add your CSV file and click the Submit button.</p>

											<Field
												key={'fileUpload'}
												id={'fileUpload'}
												name={'fileUpload'}
												label={'File Upload'}
												optional={false}
												batch={false}
												autoUpload={false}
												multiple={false}
												restrictions={{
													allowedExtensions: ['.csv']
												}}
												component={FormUpload}
											/>
										</fieldset>

										<div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched">
											<button type="button" className="btn btn-color-blue btn-cancel-csv-import" onClick={toggleDialog}>
												<i className="fa-regular fa-xmark padding-right-10"></i>
												Cancel
											</button>
											<button type="button" className="btn btn-color-blue btn-submit-csv-import" disabled={uploaded} onClick={formRenderProps.onSubmit}>
												<i className="fa-regular fa-check padding-right-10"></i>
												Submit
											</button>
										</div>
									</React.Fragment>
								) : (
									<React.Fragment>
										<h3>Submission Information</h3>

										<p>
											<strong className="weight-600">Successful Imports:</strong> {serviceResponse.success}
											<br />
											<strong className="weight-600">Failed Imports:</strong> {serviceResponse.dbFailureRecords + serviceResponse.invalidCsvRecords}
											<br />
										</p>

										{/*
										{serviceResponse.insertedIds !== null && serviceResponse.insertedIds !== undefined &&
											<React.Fragment>
												<h3 className="margin-top-15">IDs of Newly Created Records:</h3>
												<ol className="bulk-upload-lr-list">
													{serviceResponse.insertedIds.map((r,i) => {
														return(
															<li key={i}>
																<NavLink to={`/liftrequest/view/${r}`} title={'Click to View Entry'} target={'_blank'}>
																	<i className="fa-solid fa-arrow-up-right-from-square"></i> {r}
																</NavLink>
															</li>
														)
													})}
												</ol>
											</React.Fragment>
										}
										*/}

										{serviceResponse.dbFailureRecords + serviceResponse.invalidCsvRecords > 0 ? (
											<div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched margin-right-0 margin-left-0 padding-right-0 padding-bottom-0 padding-left-0" style={{ display: 'block' }}>
												<button type="button" className="btn btn-color-blue btn-cancel-csv-import margin-top-15" style={{ display: 'block', width: '100%', gap: 0 }} onClick={() => showFailedImports()}>
													<i className="fa-regular fa-xmark padding-right-10"></i>
													View Invalid Records
												</button>
											</div>
										) : (
											''
										)}

										<div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched margin-right-0 margin-left-0 padding-right-0 padding-bottom-0 padding-left-0" style={{ display: 'block' }}>
											<button type="button" className="btn btn-color-blue btn-cancel-csv-import margin-top-15" style={{ display: 'block', width: '100%', gap: 0 }} onClick={() => toggleDialog(false)}>
												<i className="fa-regular fa-xmark padding-right-10"></i>
												Close
											</button>
										</div>
									</React.Fragment>
								)}
							</React.Fragment>
						)}
					/>
				</Dialog>
			)}
		</React.Fragment>
	);
};

export default LiftRequestImportWindow;
