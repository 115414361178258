/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as ActionTypes from './actions';

const initObj = {
	profile: {
		attributes: {
			userThemeValue: ['theme-default']
		}
	}
};

const initialState = {
	keycloak: initObj,

	authenticated: false,
	token: null,
	expireTime: 0,

	roleaccess: [],

	profile: null,
	userid: null,
	username: null,
	firstname: null,
	lastname: null
};

const reducer = (state = initialState, action) => {
	if (action) {
		if (action.type === ActionTypes.LOGIN) {
			return {
				...state,
				keycloak: action.keycloak,

				authenticated: action.authenticated,
				token: action.keycloak.token,
				expireTime: action.expireTime,

				roleaccess: action.keycloak.realmAccess.roles,

				profile: action.keycloak.profile,
				userid: action.keycloak.profile.id,
				username: action.result.username,
				firstname: action.result.firstName,
				lastname: action.result.lastName
			};
		}
		if (action.type === 'UPDATE_THEME') {
			return {
				...state,
				keycloak: action.value.keycloak,
				profile: action.value.keycloak.profile
			};
		}
		if (action.type === 'UPDATE_PROFILE') {
			return {
				...state,
				profile: action.value
			};
		}
	}

	return state;
};

export default reducer;
