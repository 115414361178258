/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

const Footer = (props) => {
	const today = new Date();

	return (
		<footer id={'app-footer'} className={`app-footer ${props.className ? props.className : ''}`} style={props.style}>
			<div className={'app-footer-inner'}>Copyright &copy; {today.getFullYear()}. All Rights Reserved.</div>
		</footer>
	);
};

export default Footer;
