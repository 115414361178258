/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';

import * as Actions from '../../../store/actions';
import { connect, useDispatch } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormInput, FormComboBox } from '../KendoFormElements/FormComponents';

import { optionsCountries } from '../../../Assets/json/countries';
import { optionsStates } from '../../../Assets/json/states';
import { optionsGradesListNavy } from '../../../Assets/json/grades';
import { ApiKeycloakGetUserData, ApiKeycloakUpdateProfile, ApiOrgUnitList, ApiOrgUnitListPaged, ApiOrgUnitListCount } from '../../Helpers/Apis';
import { filterChange } from '../../Helpers/FilterData';

import Loader from '../../Core/Loader';

const ProfileOverview = (props) => {
	const params = useParams();
	const userId = params.userId;

	const [, setFormKey] = React.useState(new Date());
	const [post] = React.useState('loading...');
	const [formState] = React.useState({});
	const [profile, setProfile] = React.useState('');

	const [unitCount, setUnitCount] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [gradesData, setGradesData] = React.useState(optionsGradesListNavy);
	const [stateData, setStateData] = React.useState(optionsStates);
	const [countryData, setCountryData] = React.useState(optionsCountries);
	const [units, setUnits] = React.useState([]);
	const [fullUnits, setFullUnits] = React.useState(false);
	const [gradesLoading, setGradesLoading] = React.useState(false);
	const [unitsLoading, setUnitsLoading] = React.useState(false);
	const [stateLoading, setStateLoading] = React.useState(false);
	const [countryLoading, setCountryLoading] = React.useState(false);
	const uicPageSize = 10;
	const dispatch = useDispatch();
	const timeout = React.useRef();

	const handleSubmit = async (dataItem) => {};

	const filterGradesChange = (event) => {
		filterChange(event, setGradesData, setGradesLoading, optionsGradesListNavy, timeout);
	};

	const filterStateChange = (event) => {
		filterChange(event, setStateData, setStateLoading, optionsStates, timeout);
	};

	const filterCountryChange = (event) => {
		filterChange(event, setCountryData, setCountryLoading, optionsCountries, timeout);
	};

	const filterUnitsChange = (event) => {
		filterChange(event, setUnits, setUnitsLoading, fullUnits, timeout);
	};

	const pageChange = (event) => {
		const currPage = event.page.skip;

		//stop before entries in uic/page length
		if (currPage < unitCount / event.page.take) {
			ApiOrgUnitListPaged(currPage, event.page.take).then((res) => {
				setUnits(res);
			});
			setPage(currPage);
		}
	};

	// Submission
	const onOverviewSubmit = React.useCallback((event) => {
		const { values } = event;

		profile.attributes.userPhone = values.profilePhone ? values.profilePhone : null;

		profile.attributes.userGradeValue = values.profileGrade && values.profileGrade.grade ? values.profileGrade.grade : null;
		profile.attributes.userGradeTitle = values.profileGrade && values.profileGrade.title ? values.profileGrade.title : null;
		profile.attributes.userGradeAbbr = values.profileGrade && values.profileGrade.abbr ? values.profileGrade.abbr : null;
		profile.attributes.userGradeClass = values.profileGrade && values.profileGrade.class ? values.profileGrade.class : null;
		profile.attributes.userGradeGradeTitle = values.profileGrade && values.profileGrade.gradetitle ? values.profileGrade.gradetitle : null;
		profile.attributes.userGradeInsignia = values.profileGrade && values.profileGrade.insignia ? values.profileGrade.insignia : null;

		profile.attributes.userUnitid = values.profileUnit && values.profileUnit.id ? values.profileUnit.id : null;
		profile.attributes.userUnitnra = values.profileUnit && values.profileUnit.nra ? values.profileUnit.nra : null;
		profile.attributes.userUnituic = values.profileUnit && values.profileUnit.uic ? values.profileUnit.uic : null;
		profile.attributes.userUnituicnra = values.profileUnit && values.profileUnit.uicnra ? values.profileUnit.uicnra : null;

		profile.attributes.userDutyTitle = values.profileDutyTitle ? values.profileDutyTitle : null;
		profile.attributes.userAddress1 = values.profileAddress1 ? values.profileAddress1 : null;
		profile.attributes.userAddress2 = values.profileAddress2 ? values.profileAddress2 : null;
		profile.attributes.userCity = values.profileCity ? values.profileCity : null;
		profile.attributes.userStateValue = values.profileState && values.profileState.value ? values.profileState.value : null;
		profile.attributes.userStateLabel = values.profileState && values.profileState.label ? values.profileState.label : null;
		profile.attributes.userZip = values.profileZip ? values.profileZip : null;

		profile.attributes.userCountryIsoCodeAlpha2 = values.profileCountry && values.profileCountry.value ? values.profileCountry.value : null;
		profile.attributes.userCountryLabel = values.profileCountry && values.profileCountry.label ? values.profileCountry.label : null;

		ApiKeycloakUpdateProfile(userId, profile).then((res) => {
			dataLoad();
			setFormKey(profile);
			Actions.updateAttributes(profile);
			dispatch({ type: 'UPDATE_PROFILE', value: profile });
			toast.success('Updated Successfully!');
		});
	});

	// Form Initial Values
	const initValues = {
		...formState,
		post: post,

		profileFirstName: props.firstname,
		profileLastName: props.lastname,
		profileUsername: props.username,
		profileEmail: props.profile.email,

		// Phone
		profilePhone: profile.attributes && profile.attributes.userPhone ? profile.attributes.userPhone[0] : '',

		// Grade
		profileGrade:
			profile.attributes && profile.attributes.userGradeValue && profile.attributes.userGradeTitle && profile.attributes.userGradeAbbr && profile.attributes.userGradeClass && profile.attributes.userGradeGradeTitle && profile.attributes.userGradeInsignia
				? {
						grade: profile.attributes.userGradeValue[0],
						title: profile.attributes.userGradeTitle[0],
						abbr: profile.attributes.userGradeAbbr[0],
						class: profile.attributes.userGradeClass[0],
						gradetitle: profile.attributes.userGradeGradeTitle[0],
						insignia: profile.attributes.userGradeInsignia[0]
				  }
				: '',

		// Duty Title
		profileDutyTitle: profile.attributes && profile.attributes.userDutyTitle ? profile.attributes.userDutyTitle[0] : '',

		// Unit
		profileUnit:
			profile.attributes && profile.attributes.userUnitid && profile.attributes.userUnitnra && profile.attributes.userUnituic && profile.attributes.userUnituicnra
				? {
						id: profile.attributes.userUnitid[0],
						nra: profile.attributes.userUnitnra[0],
						uic: profile.attributes.userUnituic[0],
						uicnra: profile.attributes.userUnituicnra[0]
				  }
				: '',

		// Address 1
		profileAddress1: profile.attributes && profile.attributes.userAddress1 ? profile.attributes.userAddress1[0] : '',

		// Address 2
		profileAddress2: profile.attributes && profile.attributes.userAddress2 ? profile.attributes.userAddress2[0] : '',

		// City
		profileCity: profile.attributes && profile.attributes.userCity ? profile.attributes.userCity[0] : '',

		// State
		profileState:
			profile.attributes && profile.attributes.userStateLabel && profile.attributes.userStateValue
				? {
						value: profile.attributes.userStateValue[0],
						label: profile.attributes.userStateLabel[0]
				  }
				: '',

		// Zip
		profileZip: profile.attributes && profile.attributes.userZip ? profile.attributes.userZip[0] : '',

		// Country
		profileCountry:
			profile.attributes && profile.attributes.userCountryLabel && profile.attributes.userCountryIsoCodeAlpha2
				? {
						value: profile.attributes.userCountryIsoCodeAlpha2[0],
						label: profile.attributes.userCountryLabel[0]
				  }
				: ''
	};

	const dataLoad = () => {
		ApiKeycloakGetUserData(userId).then((res) => {
			setProfile(res);
		});
		ApiOrgUnitList().then((res) => {
			setFullUnits(res);
		});
		ApiOrgUnitListPaged(0, uicPageSize).then((res) => {
			setUnits(res);
		});
		ApiOrgUnitListCount().then((res) => {
			setUnitCount(res);
		});
	};

	React.useEffect(() => {
		dataLoad();

		setFormKey(profile);
	}, [props]);

	if (profile) {
		return (
			<React.Fragment>
				<ToastContainer />
				<Form
					onSubmit={handleSubmit}
					key={profile}
					initialValues={initValues}
					onSubmitClick={onOverviewSubmit}
					render={(formRenderProps) => (
						<FormElement>
							<Row style={{ maxWidth: '1000px' }}>
								<div className={'col-6'}>
									<div className={'form-group'}>
										<Field key={'profileFirstName'} id={'profileFirstName'} name={'profileFirstName'} label={'First Name'} placeholder={'Your first name...'} component={FormInput} hint={'Read Only'} readOnly={'readonly'} />
									</div>
								</div>
								<div className={'col-6'}>
									<div className={'form-group'}>
										<Field key={'profileLastName'} id={'profileLastName'} name={'profileLastName'} label={'Last Name'} placeholder={'Your last name...'} component={FormInput} hint={'Read Only'} readOnly={'readonly'} />
									</div>
								</div>
								<div className={'col-6'}>
									<div className={'form-group'}>
										<Field key={'profileUsername'} id={'profileUsername'} name={'profileUsername'} label={'Your Username'} placeholder={'Your desired username...'} component={FormInput} hint={'Read Only'} readOnly={'readonly'} />
									</div>
								</div>
								<div className={'col-6'}>
									<div className={'form-group'}>
										<Field key={'profileEmail'} id={'profileEmail'} name={'profileEmail'} label={'Your Email'} placeholder={'Your email address...'} component={FormInput} hint={'Read Only'} readOnly={'readonly'} />
									</div>
								</div>
								<div className={'col-6'}>
									<div className={'form-group'}>
										<Field key={'profilePhone'} id={'profilePhone'} name={'profilePhone'} label={'Phone Number'} placeholder={'Please include country code.'} component={FormInput} />
									</div>
								</div>
								<div className={'col-6'}>
									<div className={'form-group'}>
										<Field key={'profileGrade'} id={'profileGrade'} name={'profileGrade'} label={'Grade'} textField={'gradetitle'} dataItemKey={'grade'} placeholder={'Grade...'} component={FormComboBox} allowCustom={true} data={gradesData} loading={gradesLoading} filterable={true} onFilterChange={filterGradesChange} />
									</div>
								</div>
								<div className={'col-6'}>
									<div className={'form-group'}>
										<Field key={'profileDutyTitle'} id={'profileDutyTitle'} name={'profileDutyTitle'} label={'Duty Title'} placeholder={''} component={FormInput} />
									</div>
								</div>
								<div className={'col-6'}>
									<div className={'form-group'}>
										<Field
											key={'profileUnit'}
											id={'profileUnit'}
											name={'profileUnit'}
											dataItemKey={'uic'}
											textField={'uicnra'}
											label={'Unit'}
											placeholder={'Unit...'}
											data={units}
											value={units}
											virtual={{
												total: unitCount,
												pageSize: uicPageSize,
												skip: page
											}}
											component={FormComboBox}
											allowCustom={true}
											onPageChange={pageChange}
											loading={unitsLoading}
											filterable={true}
											onFilterChange={filterUnitsChange}
										/>
									</div>
								</div>
								<div className={'col-12'}>
									<div className={'form-group'}>
										<Field key={'profileAddress1'} id={'profileAddress1'} name={'profileAddress1'} label={'Address'} placeholder={''} component={FormInput} />
									</div>
								</div>
								<div className={'col-12'}>
									<div className={'form-group'}>
										<Field key={'profileAddress2'} id={'profileAddress2'} name={'profileAddress2'} label={'Address (additional)'} placeholder={''} component={FormInput} />
									</div>
								</div>
								<div className={'col-3'}>
									<div className={'form-group'}>
										<Field key={'profileCity'} id={'profileCity'} name={'profileCity'} label={'City'} placeholder={''} component={FormInput} />
									</div>
								</div>
								<div className={'col-3'}>
									<div className={'form-group'}>
										<Field key={'profileState'} id={'profileState'} name={'profileState'} label={'State'} textField={'label'} dataItemKey={'value'} placeholder={'State...'} component={FormComboBox} allowCustom={true} data={stateData} loading={stateLoading} filterable={true} onFilterChange={filterStateChange} />
									</div>
								</div>
								<div className={'col-3'}>
									<div className={'form-group'}>
										<Field key={'profileZip'} id={'profileZip'} name={'profileZip'} label={'Zip'} placeholder={''} component={FormInput} />
									</div>
								</div>
								<div className={'col-3'}>
									<div className={'form-group'}>
										<Field key={'profileCountry'} id={'profileCountry'} name={'profileCountry'} label={'Country'} textField={'label'} dataItemKey={'value'} placeholder={'Country...'} component={FormComboBox} allowCustom={true} data={countryData} loading={countryLoading} filterable={true} onFilterChange={filterCountryChange} />
									</div>
								</div>
							</Row>

							<div className="k-form-buttons">
								<button type={'submit'} className="btn btn-size-medium btn-primary btn-color-orange margin-right-0 btn-submit btn-icon-submit" disabled={!formRenderProps.allowSubmit}>
									Update
								</button>
							</div>
						</FormElement>
					)}
				/>
			</React.Fragment>
		);
	} else {
		return <Loader />;
	}
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOverview);
