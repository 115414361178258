/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

const Marker = (props) => {
	const { type, name, color, lat, lng } = props;

	return <div className={`marker marker-${type}`} style={{ borderColor: color, backgroundColor: color }} title={`Name: ${name}\nLatitude: ${lat}\nLongitude: ${lng}`} />;
};

export default Marker;
