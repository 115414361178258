/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import AssetSchedule from './AssetSchedule';
import AssetAvailabilityList from './AssetAvailabilityList';

const AssetAvailabilityTabs = () => {
	const [selected, setSelected] = React.useState(0);
	const handleSelect = (e) => {
		setSelected(e.selected);
	};
	return (
		<React.Fragment>
			<div className={'page-title padding-bottom-10'}>
				<h2>Assets</h2>
			</div>
			<TabStrip selected={selected} onSelect={handleSelect} id="asset-availability-tabs" className="asset-availability-tabs padding-top-0">
				<TabStripTab eventKey="list" title="Assets List">
					<AssetAvailabilityList />
				</TabStripTab>

				<TabStripTab eventKey="schedule" title="Asset Schedule">
					<AssetSchedule />
				</TabStripTab>
			</TabStrip>
		</React.Fragment>
	);
};

export default AssetAvailabilityTabs;
