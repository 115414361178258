/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

const Main = (props) => {
	return (
		<main id={'app-main'} className={`app-main ${props.className ? props.className : ''}`} style={props.style}>
			<div className={'app-main-outer'}>
				<div className={'app-main-inner'}>{props.children}</div>
			</div>
		</main>
	);
};

export default Main;
