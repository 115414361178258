/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import { Col, Container, Row } from 'react-bootstrap';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';

import AnalysisTabs from '../../App/Analysis/AnalysisTabs';

const Analysis = (props) => {
	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						<div className={'page-title'}>
							<h2>Analysis</h2>
						</div>

						<AnalysisTabs />
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};
export default Analysis;
