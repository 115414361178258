/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';
import { Row } from 'react-bootstrap';
import { Field } from '@progress/kendo-react-form';

import { FormComboBox } from '../KendoFormElements/FormComponents';
import { priorityOfTravel, urgencyOfTravel } from '../../../Assets/json/optionsNewLiftRequest';
import { priorityValidator, urgencyValidator } from '../KendoFormElements/Validators';

import Button from 'react-bootstrap/Button';
import LiftRequestInfoModal from '../Modals/LiftRequestInfoModal';

export const PriorityDetails = (props) => {
	// Here we are using the formRenderProps.valueGetter to get values from the Form

	const [toggleModal, setToggleModal] = React.useState(false);
	const [modalTitle, setModalTitle] = React.useState('');
	const [modalContent, setModalContent] = React.useState('');

	const handleModalToggle = () => setToggleModal(!toggleModal);

	const handleModalTitle = (val) => setModalTitle(val);

	const handleModalContent = (val) => setModalContent(val);

	return (
		<React.Fragment>
			<Row id={'lift-request-priority'} className={'form-section'}>
				{/* Section Title - Priority of Travel: Start */}
				<div className={'col-12 form-section-title lift-request-priority'}>
					<h3>Priority of Travel</h3>
				</div>
				{/* Section Title - Priority of Travel: End */}

				<div className={'col-6 has-info'}>
					<div className={'form-group'}>
						<Field key={'priority'} id={'priority'} name={'priority'} label={'Lift Priority'} textField={'label'} dataItemKey={'value'} placeholder={'Select the priority of your request...'} component={FormComboBox} data={priorityOfTravel} validator={priorityValidator} />
						<Button
							variant={'secondary'}
							className={'btn-form-info'}
							onClick={() => {
								// Info: Modal Title
								handleModalTitle('Travel Priority Information');

								// Info: Modal Content
								handleModalContent(`
                                <p><span class="fontsize-18px">Priority 1:</span><br/><b>Direct support of operational forces</b> engaged in
                                combat <b><u>or</u></b> contingency peace-keeping operations directed NCA, <b><u>or</u></b> for emergency
                                lifesaving purposes.</p>
                                <p><span class="fontsize-18px">Priority 2:</span><br/>"<b>Required use</b>" travel <b><u>or</u></b> compelling
                                operational considerations making commercial transportation unacceptable (within 24 hours). Mission cannot be
                                satisfied by any other mode of travel. Requester should provide a 2-hour window for departure and arrival
                                times to allow consolidation of missions per DoD Directive 4500.43.</p>
                                <p><span class="fontsize-18px">Priority 3:</span><br/><b>Official business travel</b> which when consolidated
                                by JOSAC with other travelers, is more cost effective than commercial air travel or official business travel
                                on previously scheduled missions. Requester <b>must</b> provide at least a 2-hour window for departure and
                                arrival times to allow consolidation of missions per DoD Directive 4500.43.</p>
                            `);

								// Show Modal
								handleModalToggle();
							}}>
							<i className={'fa-solid fa-info modal-trigger'}></i>
							<span className={'sr-only'}>Click to view information about Travel Priority Information</span>
						</Button>
					</div>
				</div>

				{/* Section Title - Urgency of Travel: Start */}
				<div className={'col-12 form-section-title lift-request-priority'}>
					<h3>Urgency of Travel</h3>
				</div>
				{/* Section Title - Urgency of Travel: End */}

				<div className={'col-6 has-info'}>
					<div className={'form-group'}>
						<Field key={'urgency'} id={'urgency'} name={'urgency'} label={'Lift Urgency'} textField={'label'} dataItemKey={'value'} placeholder={'Select the urgency of your request...'} component={FormComboBox} data={urgencyOfTravel} validator={urgencyValidator} />
						<Button
							variant={'secondary'}
							className={'btn-form-info'}
							onClick={() => {
								// Info: Modal Title
								handleModalTitle('PUJC Code Information');

								// Info: Modal Content
								handleModalContent(`
                                <p><b>Priority/Urgency/Justification/Category (PUJC) Codes.</b><br/>Requesters and authorizing officials shall assign a PUJC
								code to each lift when requesting airlift support. The PUJC codes shall be validated by the validating and scheduling
								activity (NALO, CTF-53, CTF-63, CFWP).</p>
                            `);

								// Show Modal
								handleModalToggle();
							}}>
							<i className={'fa-solid fa-info modal-trigger'}></i>
							<span className={'sr-only'}>Click to view information about PUJC Code</span>
						</Button>
					</div>
				</div>
			</Row>

			<LiftRequestInfoModal modalShow={toggleModal} setModalShow={handleModalToggle} modalTitle={modalTitle} modalContent={modalContent} />
		</React.Fragment>
	);
};
