/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';

import { Tooltip } from '@progress/kendo-react-tooltip';

import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import Moment from 'react-moment';
import { CapitalizeString } from '../../../Helpers/ContentFormatting';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { toast } from 'react-toastify';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { ApiMissionGenRequest } from '../../../Helpers/Apis';

import * as Actions from '../../../../store/actions';
import { connect } from 'react-redux';

import { ApiChangeSandboxState, ApiMissionListByState } from '../../../Helpers/Apis';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const initialDataState = {
	skip: 0,
	take: 25
};

let start = new Date();
let end = new Date(Date.now() + 14 * (60 * 60 * 24 * 1000));
let speed = 'Fast';

const dateNow = new Date(Date.now());
//set the latest solver date to the current date + 2 weeks
const dateLater = new Date(Date.now() + 12096e5);

const SandboxListGrid = (props) => {
	const [page, setPage] = React.useState(initialDataState);
	const [formKey, setFormKey] = React.useState(new Date());
	const [visible, setVisible] = React.useState(false);
	const [submitVisible, setSubmitVisible] = React.useState(false);
	const [dialogState, setDialogState] = React.useState('');
	const [dialogId, setDialogId] = React.useState('');
	const [sandboxes, setSandboxes] = React.useState([]);
	const [solverStartDateValue] = React.useState(dateNow);
	const [solverEndDateValue] = React.useState(dateLater);
	const [solverSpeed] = React.useState('Fast');

	const solverSpeedOption = ['Fast', 'Standard', 'Best'];
	const solverDateRangeDefaults = {
		start: dateNow,
		end: dateLater
	};

	const pageChange = (event) => {
		setPage(event.page);
	};

	const solverScheduleObj = {
		optimization_timestamp: {
			start_time: solverStartDateValue,
			end_time: solverEndDateValue
		},
		solver_configuration: {
			solver_speed: solverSpeed.toUpperCase()
		},
		createdBy: {
			id: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.id ? props.keycloak.profile.id : null,
			firstName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.firstName ? props.keycloak.profile.firstName : null,
			lastName: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.lastName ? props.keycloak.profile.lastName : null,
			email: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.email ? props.keycloak.profile.email : null,
			phoneNumber: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.phoneNumber ? props.keycloak.profile.phoneNumber : null,
			rank: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.rank ? props.keycloak.profile.rank : null,
			dutyTitle: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.dutyTitle ? props.keycloak.profile.dutyTitle : null,
			unit: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.unit ? props.keycloak.profile.unit : null,
			branchOfService: props && props.keycloak && props.keycloak.profile && props.keycloak.profile.branchOfService ? props.keycloak.profile.branchOfService : null
		}
	};

	// Sandbox Name
	const SandboxNameCell = (props) => {
		const history = props.dataItem[props.field][0];
		const name = history.name;
		const id = props.dataItem.id;

		return (
			<td className={'table-lr-request-name'}>
				<NavLink to={`/sandbox/view/${id}`} title={`Click to View ${id}`}>
					<span className={'ellipsis'}>{name !== null ? name : id}</span>
				</NavLink>
			</td>
		);
	};

	const FullNameCell = (props) => {
		const history = props.dataItem[props.field][0];

		if (history.createdBy !== null) {
			if (history.createdBy.firstName !== null && history.createdBy.lastName !== null) {
				return (
					<td>
						{history.createdBy.firstName} {history.createdBy.lastName}
					</td>
				);
			} else if (history.createdBy.firstName !== null) {
				return <td>{history.createdBy.firstName}</td>;
			} else if (history.createdBy.lastName !== null) {
				return <td>{history.createdBy.lastName}</td>;
			} else {
				return <td>-</td>;
			}
		} else {
			return <td>-</td>;
		}
	};

	const CreatedOn = (props) => {
		const history = props.dataItem[props.field][0];

		return (
			<td className={'mission-cell mission-cell-created-on'}>
				<Moment format={'YYYY-MM-DD'}>{history.createdAt}</Moment>
			</td>
		);
	};

	const handleSolverDateChange = (event) => {
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log(event.value);
		}

		start = event.value.start;
		end = event.value.end;
	};

	const handleSolverSpeedChange = (event) => {
		speed = event.value;
	};

	const Version = (props) => {
		return <td className={'mission-cell mission-cell-created-on'}>{props.dataItem.latestVersion + 1}</td>;
	};

	const submitForScheduling = () => {
		toggleSubmitDialog();
		solverScheduleObj.optimization_timestamp.start_time = start;
		solverScheduleObj.optimization_timestamp.end_time = end;
		solverScheduleObj.solver_configuration.solver_speed = speed.toUpperCase();

		let sandboxIdParamString = `?sandboxId=${dialogId}`;
		ApiMissionGenRequest(solverScheduleObj, sandboxIdParamString);
		toast.success('Successfully Submitted Sandbox Details to the Air Logistics Solver for Scheduling');
	};

	const SubmissionDialog = () => {
		return (
			<Dialog id={'schedule-submit-dialog'} title={'Please Confirm'} onClose={toggleSubmitDialog}>
				<p style={{ textAlign: 'center' }}>Are you sure you want to submit this Sandbox's selected Lift Requests, Assets, and Airports to the Air Logistics Solver for scheduling?</p>
				<div>
					<p className={'padding-top-25 padding-bottom-5 fontsize-14px'}>
						<strong>Enter the Date Range for the Solver</strong>:
					</p>
					<DateRangePicker defaultValue={solverDateRangeDefaults} onChange={handleSolverDateChange} />
				</div>

				<div className={'padding-bottom-15'}>
					<p className={'padding-top-25 padding-bottom-5 fontsize-14px'}>
						<strong>Enter Solver Configuration</strong>:
					</p>
					<DropDownList data={solverSpeedOption} defaultValue={'Fast'} onChange={handleSolverSpeedChange} />
				</div>

				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleSubmitDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button className="btn btn-color-blue" title={'Click to Confirm'} onClick={submitForScheduling}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	// Actions Cell
	const ActionsCell = (p) => {
		const id = p.dataItem.id;
		const hasSolverResponse = p.dataItem && p.dataItem.hasSolverResponse ? p.dataItem.hasSolverResponse : false;

		return (
			<td className={'table-actions'}>
				<div className={'actions'}>
					<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'left'}>
						<ul className={'actions-list'}>
							{hasSolverResponse && (
								<li className={'action-item view'}>
									<NavLink className={'nav-link'} role={'button'} to={`/sandbox/results/${id}`} title={`Click to View ${id}'s Solver Results`}>
										<i className="fa-solid fa-square-poll-vertical"></i>
										<span className={'sr-only'}>Click to View {id}'s Solver Results</span>
									</NavLink>
								</li>
							)}

							<li className={'action-item view'}>
								<NavLink className={'nav-link'} role={'button'} to={`/sandbox/view/${id}`} title={`Click to View ${id} `}>
									<i className="fa-solid fa-eye"></i>
									<span className={'sr-only'}>Click to View {id}</span>
								</NavLink>
							</li>

							<li className={'action-item process'}>
								<NavLink
									className={'nav-link'}
									role={'button'}
									to={`#`}
									title={'Click to Process Sandbox'}
									onClick={() => {
										openSubmitDialog(id);
									}}>
									<i className={'fa fa-send-back'}></i>
									{/* <i className="fa-solid fa-arrow-progress"></i> */}
									<span className={'sr-only'}>Click to Process ${id}</span>
								</NavLink>
							</li>

							{submitVisible && id === dialogId && <SubmissionDialog />}

							<li className={'action-item edit'}>
								<NavLink className={'nav-link'} role={'button'} to={`/sandbox/edit/${id}`} title={'Edit Sandbox'}>
									<i className="fa-solid fa-pen-to-square"></i>
									<span className={'sr-only'}>Click to Edit ${id}</span>
								</NavLink>
							</li>

							<li className={'action-item regret'}>
								<NavLink
									className={'nav-link'}
									role={'button'}
									to={`#`}
									title={'Click to Delete Sandbox'}
									onClick={() => {
										openDialog(id, 'CANCELLED');
									}}>
									<i className="fa-solid fa-trash-can"></i>
									<span className={'sr-only'}>Click to Delete Sandbox</span>
								</NavLink>
								{visible && dialogId === id && dialogState === 'CANCELLED' && DialogWindow(id, 'CANCELLED')}
							</li>
						</ul>
					</Tooltip>
				</div>
			</td>
		);
	};

	const stateUpdate = (id, state) => {
		ApiChangeSandboxState(id, state);
		toast.success('Successfully Changed Sandbox Status to ' + CapitalizeString(state.toLowerCase()));

		// eslint-disable-next-line
		sandboxes.map((item, index) => {
			if (item.id === id) {
				setSandboxes((oldValues) => {
					return oldValues.filter((_, i) => i !== index);
				});
			}
		});

		setFormKey(id);
		setDialogId('');
		setDialogState('');
	};

	const DialogWindow = (id, state) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleDialog}>
				<div data-id={id} data-state-to={state}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to delete this sandbox?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							stateUpdate(id, state);
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const openDialog = (id, state) => {
		toggleDialog();
		setDialogId(id);
		setDialogState(state);
	};

	const toggleSubmitDialog = () => {
		if (!submitVisible) {
			start = dateNow;
			end = dateLater;
			speed = 'Fast';
		}
		setSubmitVisible(!submitVisible);
	};

	const openSubmitDialog = (id) => {
		toggleSubmitDialog();
		setDialogId(id);
	};

	useEffect(() => {
		const tempSandboxes = [...props.sandboxData];
		// eslint-disable-next-line
		tempSandboxes.map((sandbox) => {
			let queryParam = `?sandboxId=${sandbox.id}`;
			// check if we get a mission response with the requestID. if we do, allow access to results page
			ApiMissionListByState(queryParam).then((solverResponse) => {
				if (solverResponse.length > 0) {
					sandbox.hasSolverResponse = true;
					setFormKey(new Date());
				} else {
					sandbox.hasSolverResponse = false;
					setFormKey(new Date());
				}
			});
		});
		setSandboxes([...tempSandboxes]);
		setFormKey(new Date());
	}, []);

	return (
		<form>
			<Grid key={formKey} data={sandboxes.slice(page.skip, page.take + page.skip)} skip={page.skip} take={page.take} total={sandboxes.length} pageable={{ buttonCount: 5, pageSizes: [10, 25, 50, 150] }} filterable={false} onPageChange={pageChange}>
				<GridNoRecords>No Records Available</GridNoRecords>

				<Column field="history" filter="text" title="Sandbox Name" cell={SandboxNameCell} />
				<Column field="history" filter="text" title="Created By" cell={FullNameCell} />

				<Column field="history" filter="text" title="Created On" cell={CreatedOn} />

				<Column field="history" filter="text" title="Version" cell={Version} />
				<Column field="state" title=" " sortable={false} filterable={false} width="200px" cell={ActionsCell} />
			</Grid>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SandboxListGrid);
