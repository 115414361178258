/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Tooltip } from '@progress/kendo-react-tooltip';

import { CapitalizeString } from '../../Helpers/ContentFormatting';
import { ApiDisruptionsList, ApiUpdateDisruptionById } from '../../Helpers/Apis';
import { toast } from 'react-toastify';

const DisruptionManagementGrid = (props) => {
	// Used to Refresh on State Change
	const { state } = useLocation();

	const navigate = useNavigate();

	// Used to Refresh on State Change
	// eslint-disable-next-line
	const [rerender, setRerender] = useState(false);

	const [disruptionData, setDisruptionData] = React.useState([]);

	// eslint-disable-next-line
	const [formKey, setFormKey] = React.useState(new Date());

	// eslint-disable-next-line
	const [formState] = React.useState({});

	const handleCompleteState = (id) => {
		let submissionData = {};
		// eslint-disable-next-line
		disruptionData.map((rec, i) => {
			if (rec.id === id) {
				submissionData = rec.history[0];

				// Set State to COMPLETED
				submissionData.state = 'COMPLETED';

				ApiUpdateDisruptionById(id, submissionData).then((res) => {
					toast.success('Disruption Marked Completed Successfully!');
					navigate('/disruptions?status=updated');
				});

				setRerender(!rerender);
			}
		});
	};

	const dataFetch = () => {
		ApiDisruptionsList(props.queryparams).then((res) => {
			setDisruptionData(res);

			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log(res);
			}
		});
	};

	const DisruptionGridList = () => {
		return (
			<React.Fragment>
				{disruptionData.map((rec, i) => {
					return (
						<React.Fragment key={i}>
							{rec.history[0].state.replace(/\s/g, '').replace(/_/g, '').toLowerCase() === props.tablist || props.tablist === 'all' ? (
								<tr id={`row-${rec.id}`} className={`form-section form-data-row ${i % 2 !== 0 ? 'even' : 'odd'}`} key={i}>
									<td className={'form-cell dm-type capitalize'}>
										<NavLink to={`/disruption/${rec.id}`} title={'Click to View Disruption Details'}>
											{CapitalizeString(rec.history[0].type.toLowerCase()).replace(/_/g, ' ')}
										</NavLink>
									</td>
									<td className={'form-cell dm-date-of-disruption'}>{rec.history[0].createdAt}</td>
									<td className={'form-cell dm-state'}>
										<span className={`pill dm-status-${rec.history[0].state.replace(/\s/g, '').replace(/_/g, '').toLowerCase()}`}>{rec.history[0].state.replace(/_/g, ' ')}</span>
									</td>
									<td className={'form-cell dm-actions'}>
										{rec.history[0].state === 'CREATED' && (
											<span>
												<i className={'fa-solid fa-triangle-exclamation padding-right-8'}></i>
												Please Submit for Processing
											</span>
										)}

										{rec.history[0].state === 'PROCESSED' && (
											<span>
												<i className={'fas fa-circle-check padding-right-8'}></i>
												Awaiting Final Review
											</span>
										)}
									</td>
									<td>
										<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'left'}>
											<ul className={'dm-actions-list'} style={{ width: '50px' }}>
												{rec.history[0].state === 'PROCESSED' && (
													<li className={'dm-action-btn approve-disruption'}>
														<Button
															className={'btn-primary'}
															onClick={() => {
																handleCompleteState(rec.id);
															}}
															title={'Click to Complete'}>
															<i className={'fa-solid fa-check'}></i>
															<span className={'sr-only'}>Complete Disruption Resolution Solution</span>
														</Button>
													</li>
												)}

												<li className={'dm-action-btn view-info-page'}>
													<NavLink className={'btn-primary'} to={`/disruption/${rec.id}`} title={'Click to View All Disruption Details'}>
														<i className={'fas fa-eye'}></i>
														<span className={'sr-only'}>View All Disruption Details</span>
													</NavLink>
												</li>
											</ul>
										</Tooltip>
									</td>
								</tr>
							) : (
								<></>
							)}
						</React.Fragment>
					);
				})}
			</React.Fragment>
		);
	};

	useEffect(() => {
		dataFetch();
		setFormKey(new Date());
	}, [props]);

	// rerender Used to Refresh on Delete
	useEffect(() => {
		dataFetch();
	}, [rerender]);

	// Used to Refresh on Delete
	useEffect(() => {
		dataFetch();
	}, [state]);

	return (
		<div className={'dm-form'}>
			<div className={'dm-form-inner'}>
				{disruptionData !== null && disruptionData !== undefined && disruptionData.length ? (
					<div className={'table-responsive'}>
						<table className={'table table-disruptionmanagement table-striped'}>
							<thead>
								<tr className={'form-section'}>
									<th className={'header-cell dm-type'}>Disruption Type</th>
									<th className={'header-cell dm-date-of-disruption'}>Date of Disruption</th>
									<th className={'header-cell dm-status'}>Status</th>
									<th className={'header-cell dm-actions'}>Resolution Type?</th>
									<th> </th>
								</tr>
							</thead>
							<tbody>{DisruptionGridList()}</tbody>
						</table>
					</div>
				) : (
					<p className={'align-center'}>No records available to display</p>
				)}
			</div>
		</div>
	);
};

export default DisruptionManagementGrid;
