/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import Keycloak from 'keycloak-js';

import configuration from '../Components/Auth/Configuration';

export const LOGIN = 'LOGIN';

const keycloak = new Keycloak(configuration);

export const login = () => {
	const date = Math.round(new Date().getTime() / 1000);

	return function (dispatch, getState) {
		if (getState().authenticated !== true || date > getState().expireTime) {
			console.log('Required authentication');

			keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {
				keycloak.loadUserProfile().then((result) => {
					dispatch(syncLogin(result, authenticated, keycloak));
				});
			});
		} else {
			console.log('Authentication not required');
		}
	};
};

export const updateTheme = (data) => {
	return function (dispatch) {
		dispatch({ type: 'UPDATE_THEME', value: data });
	};
};

export const updateProfile = (data) => {
	return function (dispatch) {
		dispatch({ type: 'UPDATE_PROFILE', value: data });
	};
};

export const updateAttributes = (data) => {
	keycloak.profile = data;
};

const getToken = () => keycloak.token;

const doLogin = keycloak.login;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback) => {
	keycloak.updateToken(80).then(successCallback).catch(doLogin);
};

const syncLogin = (result, authenticated, keycloak) => {
	return {
		type: LOGIN,
		result: result,

		keycloak: keycloak,

		authenticated: authenticated,
		token: keycloak.token,
		expireTime: keycloak.tokenParsed.exp,

		roleaccess: keycloak.realmAccess.roles,

		profile: keycloak.profile,
		userid: keycloak.profile.id,
		username: keycloak.profile.username,
		firstname: keycloak.profile.firstName,
		lastname: keycloak.profile.lastName
	};
};

const actions = {
	updateToken,
	doLogin,
	getToken,
	isLoggedIn
};

export default actions;
