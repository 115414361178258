/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */
import * as React from 'react';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import MultiMissionGanttChart from './MultiMissionGanttChart';

const MultiMissionTabs = (props) => {
	const [selected, setSelected] = React.useState(0);
	const [isWidget] = React.useState(props.dashboardWidget ? true : false);

	const handleSelect = (e) => {
		setSelected(e.selected);
	};

	return (
		<React.Fragment>
			{isWidget ? (
				<div className={'dashboard-widget-block-inner'}>
					<h3>Schedule</h3>
				</div>
			) : (
				<div className={'page-title'}>
					<h2>Schedule</h2>
				</div>
			)}

			<TabStrip selected={selected} onSelect={handleSelect} id="multimission-list-tabs" className={isWidget ? 'multimission-list-tabs' : 'multimission-non-widget-list-tabs'}>
				<TabStripTab eventKey="generated" title="Generated">
					<MultiMissionGanttChart queryparams={'?state=GENERATED'} listTitle={'Missions'} />
				</TabStripTab>
				<TabStripTab eventKey="departed" title="Departed">
					<MultiMissionGanttChart queryparams={'?state=DEPARTED'} listTitle={'Missions'} />
				</TabStripTab>
			</TabStrip>
		</React.Fragment>
	);
};

export default MultiMissionTabs;
