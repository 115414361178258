/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { SquadronsColumns } from '../../Helpers/DataGrids';

import { ApiOrgSquadronList } from '../../Helpers/Apis';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const initialDataState = {
	skip: 0,
	take: 10
};

const SquadronsListGrid = (props) => {
	const [page, setPage] = React.useState(initialDataState);
	const [squadrons, setSquadrons] = React.useState([]);

	const pageChange = (event) => {
		setPage(event.page);
	};

	const dataFetch = () => {
		ApiOrgSquadronList().then((res) => {
			setSquadrons(res);
		});
	};

	useEffect(() => {
		dataFetch();
	}, []);

	const PageTitleControls = () => {
		return (
			<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'bottom'}>
				<Navbar expand="lg" style={{ display: 'none' }}>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							<NavLink className={'nav-link'} role={'button'} to={`/airport/new`} title={`Click to Create a New Squadron`}>
								<i className="fa-solid fa-circle-plus"></i>
								New Squadron
							</NavLink>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</Tooltip>
		);
	};

	const ActionsCell = (p) => {
		const id = p.dataItem.id;

		return (
			<td className={'table-actions'}>
				<div className={'actions'}>
					<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'left'}>
						<ul className={'actions-list'}>
							<li className={'action-item view'}>
								<NavLink className={'nav-link'} role={'button'} to={`/squadron/view/${id}`} title={`Click to View Squadron Details`}>
									<i className="fa-solid fa-eye"></i>
									<span className={'sr-only'}>View Squadron Details</span>
								</NavLink>
							</li>
						</ul>
					</Tooltip>
				</div>
			</td>
		);
	};

	return (
		<React.Fragment>
			<div className={'page-title padding-bottom-10'}>
				<h2>Squadrons List</h2>

				{PageTitleControls()}
			</div>
			<Grid data={squadrons.slice(page.skip, page.take + page.skip)} skip={page.skip} take={page.take} total={squadrons.length} pageable={{ buttonCount: 5, pageSizes: [10, 25, 50, 150] }} filterable={false} onPageChange={pageChange}>
				<GridNoRecords>No Records Available</GridNoRecords>

				<Column field="name" filter="text" title="Designation" cell={SquadronsColumns.SquadronDesignation} />
				<Column field="name" filter="text" title="Nickname" cell={SquadronsColumns.Nickname} />
				<Column field="name" filter="text" title="Command" cell={SquadronsColumns.Commander} />
				<Column field="name" filter="text" title="Squadron Notes" cell={SquadronsColumns.Notes} />
				<Column field="id" title=" " sortable={false} filterable={false} cell={ActionsCell} />
			</Grid>
		</React.Fragment>
	);
};

export default SquadronsListGrid;
