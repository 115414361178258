/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

const Layout = (props) => {
	return (
		<div id={'app-wrapper'} className={`app-wrapper ${props.apptheme} ${props.userrole} ${props.className ? props.className : ''}`} style={props.style}>
			{props.children}
		</div>
	);
};

export default Layout;
