/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { PDFExport } from '@progress/kendo-react-pdf';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Col, Container, Row } from 'react-bootstrap';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';
import Loader from '../../Core/Loader';

import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import { Accordion, AccordionHeading, AccordionContent } from '../../Helpers/Accordion';

import { ApiChangeLiftRequestState, ApiLiftRequestById } from '../../Helpers/Apis';
import { accessValidator } from '../../Helpers/RolesPermissions';
import { toast } from 'react-toastify';
import { CapitalizeString, numberWithCommas, diff } from '../../Helpers/ContentFormatting';

const LiftRequestHistory = (props) => {
	const [loaded, setLoaded] = React.useState(false);
	const [liftDetails, setLiftDetails] = React.useState([]);

	// eslint-disable-next-line
	const [formKey, setFormKey] = React.useState(new Date());
	const [visible, setVisible] = React.useState(false);

	// eslint-disable-next-line
	const [dialogState, setDialogState] = React.useState('');

	const params = useParams();
	const pdfExportComponent = React.useRef(null);

	const accessAuthorizationButton = ['approver'];
	const accessClaimButton = ['nalo'];
	const accessValidateButton = ['nalo'];
	const accessRegrettedButton = ['nalo'];

	const data = {
		id: liftDetails.id ? liftDetails.id : '',
		latestVersion: liftDetails.latestVersion ? liftDetails.latestVersion : '',
		latestOwner: {
			id: liftDetails.latestOwner ? liftDetails.latestOwner.id : null,
			firstName: liftDetails.latestOwner ? liftDetails.latestOwner.firstName : null,
			lastName: liftDetails.latestOwner ? liftDetails.latestOwner.lastName : null,
			email: liftDetails.latestOwner ? liftDetails.latestOwner.email : null,
			phoneNumber: liftDetails.latestOwner ? liftDetails.latestOwner.phoneNumber : null,
			rank: liftDetails.latestOwner ? liftDetails.latestOwner.rank : null,
			dutyTitle: liftDetails.latestOwner ? liftDetails.latestOwner.dutyTitle : null,
			unit: liftDetails.latestOwner ? liftDetails.latestOwner.unit : null
		},
		history: liftDetails.history ? liftDetails.history : []
	};

	const lrTitle = data.history[0] ? data.history[0].requestName : params.liftId;

	const dataFetch = () => {
		ApiLiftRequestById(params.liftId).then((res) => {
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log(res);
			}

			setLiftDetails(res);
			setLoaded(true);
		});
	};

	const stateUpdate = (id, state) => {
		ApiChangeLiftRequestState(id, state, props.token);
		toast.success('Successfully Changed Lift Request Status to ' + CapitalizeString(state.toLowerCase()));

		setDialogState('');

		setTimeout(() => {
			dataFetch();
			setFormKey(new Date());
		}, 300);
	};

	const toggleDialog = () => {
		setVisible(!visible);
	};

	// eslint-disable-next-line
	const openDialog = (id, state) => {
		toggleDialog();
		setDialogState(state);
	};

	// eslint-disable-next-line
	const DialogWindow = (id, state) => {
		return (
			<Dialog title={'Please Confirm'} onClose={toggleDialog}>
				<div data-id={id} data-state-to={state}>
					<p style={{ textAlign: 'center' }}>Are you sure you want to change the lift request status to {CapitalizeString(state.toLowerCase())}?</p>
				</div>
				<DialogActionsBar>
					<button className="btn btn-color-blue" title={'Click to Cancel'} onClick={toggleDialog}>
						<i className={'fa-regular fa-xmark padding-right-10'}></i>
						No
					</button>
					<button
						className="btn btn-color-blue"
						title={'Click to Confirm'}
						onClick={() => {
							stateUpdate(id, state);
						}}>
						<i className={'fa-regular fa-check padding-right-10'}></i>
						Yes
					</button>
				</DialogActionsBar>
			</Dialog>
		);
	};

	const PageTitleControls = () => {
		return (
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						{accessValidator(props.roleaccess, accessAuthorizationButton) && data.history.length && data.history[0] !== undefined && data.history[0].state !== undefined && data.history[0].state === 'SUBMITTED' && (
							<NavLink
								className={'nav-link'}
								role={'button'}
								to={`#`}
								title={`Click to Authorize Lift Request`}
								onClick={() => {
									ApiChangeLiftRequestState(params.liftId, 'AUTHORIZED', props.token);
								}}>
								<i className={'fa fa-file-exclamation'}></i>
								Authorize Request
							</NavLink>
						)}

						{/* Access: Nalo - Button to Claim Authorized Lift Requests */}
						{accessValidator(props.roleaccess, accessClaimButton) && data.history.length && data.history[0] !== undefined && data.history[0].state !== undefined && data.history[0].state === 'AUTHORIZED' && (
							<NavLink
								className={'nav-link'}
								role={'button'}
								to={`#`}
								title={`Click to Claim Lift Request`}
								onClick={() => {
									ApiChangeLiftRequestState(params.liftId, 'CLAIMED', props.token);
								}}>
								<i className="fa-solid fa-file-check"></i>
								Claim Lift Request
							</NavLink>
						)}

						{/* Access: Nalo - Button to Regret Lift Requests */}
						{accessValidator(props.roleaccess, accessRegrettedButton) && data.history.length && data.history[0] !== undefined && data.history[0].state !== undefined && data.history[0].state !== 'IN_TRANSIT' && data.history[0].state !== 'SATISFIED' && data.history[0].state !== 'REGRETTED' && data.history[0].state !== 'CANCELLED' && (
							<NavLink
								className={'nav-link'}
								role={'button'}
								to={`#`}
								title={`Click to Regret Lift Request`}
								onClick={() => {
									ApiChangeLiftRequestState(params.liftId, 'REGRETTED', props.token);
								}}>
								<i className="fa-solid fa-ban"></i>
								Regret Lift Request
							</NavLink>
						)}

						{/* Access: Nalo - Button to Validate Lift Requests */}
						{accessValidator(props.roleaccess, accessValidateButton) && data.history.length && data.history[0] !== undefined && data.history[0].state !== undefined && data.history[0].state === 'CLAIMED' && (
							<NavLink className={'nav-link'} role={'button'} to={`/validation/${params.liftId}`} title={'Validate Lift Request'}>
								<i className="fa-regular fa-ballot-check"></i>
								<span className={'sr-only'}>Click to Validate ${params.liftId}</span>
								Validate Lift Request
							</NavLink>
						)}

						<NavLink className={'nav-link'} role={'button'} to={`/liftrequest/view/${params.liftId}`}>
							<i className={'fa fa-eye'}></i>
							Read Only View
						</NavLink>

						<Nav.Link to={'#export'}>
							<i className={'fa fa-file-export'}></i>
							Export
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	};

	const historyList = () => {
		return (
			<PanelBar className={'margin-top-10'}>
				{liftDetails.history.map((rec, i, arr1) => {
					const nextValue = arr1[i + 1] !== undefined ? arr1[i + 1] : arr1[i];

					if (process.env.REACT_APP_DEBUG === 'true') {
						console.log('i: ' + (i + 1) + ' liftDetails.history.length: ' + liftDetails.history.length);
					}

					const versionTitle = (
						<span key={i} className={'lr-history-header leg-info-header'} id={`version-id-${i}`}>
							<span className={'lr-history-header-item lr-history-version-counter'}>
								Lift Request Version {liftDetails.history.length - i} {i === 0 ? '(Current Version)' : i + 1 === liftDetails.history.length ? '(Oldest Version)' : ''}
							</span>
							<span className={'lr-history-header-item lr-history-created-date'}>{rec.createdAt}</span>
						</span>
					);

					return (
						<PanelBarItem key={i} title={versionTitle}>
							<div className={'lr-history-details padding-all-15 missionstatus-details'}>
								{/* Primary Details */}
								<Accordion>
									<AccordionHeading>Primary Details</AccordionHeading>
									<AccordionContent>
										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Request Name</div>
												<div className={'lr-history-value'}>{rec.requestName !== null ? rec.requestName : params.liftId}</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Priority</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.priority}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.priority, nextValue.priority) === false ? 'different' : 'same'}`}>{nextValue.priority}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Urgency</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.urgency}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.urgency, nextValue.urgency) === false ? 'different' : 'same'}`}>{nextValue.urgency}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>State</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>
														<span className={`pill lift-status-${rec.state.toLowerCase()}`}>{rec.state}</span>
													</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{nextValue.state !== null && (
																<span className={`next ${diff(rec.state, nextValue.state) === false ? 'different' : 'same'}`}>
																	<span className={`pill lift-status-${nextValue.state.toLowerCase()}`}>{nextValue.state}</span>
																</span>
															)}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Mission Description</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.missionDescription !== null ? rec.missionDescription : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.missionDescription, nextValue.missionDescription) === false ? 'different' : 'same'}`}>{nextValue.missionDescription !== null ? nextValue.missionDescription : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Justification</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.justification !== null ? rec.justification : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.justification, nextValue.justification) === false ? 'different' : 'same'}`}>{nextValue.justification !== null ? nextValue.justification : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Commercial Cost</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.commercialCost !== null ? '$' + numberWithCommas(rec.commercialCost) : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.commercialCost, nextValue.commercialCost) === false ? 'different' : 'same'}`}>{nextValue.commercialCost !== null ? '$' + numberWithCommas(nextValue.commercialCost) : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>
									</AccordionContent>
								</Accordion>

								{/* Requester Information */}
								<Accordion>
									<AccordionHeading>Requester Information</AccordionHeading>
									<AccordionContent>
										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>First Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.requester !== null && rec.requester.firstName !== null ? rec.requester.firstName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.requester.firstName, nextValue.requester.firstName) === false ? 'different' : 'same'}`}>{nextValue.requester !== null && nextValue.requester.firstName !== null ? nextValue.requester.firstName : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Last Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.requester !== null && rec.requester.lastName !== null ? rec.requester.lastName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.requester.lastName, nextValue.requester.lastName) === false ? 'different' : 'same'}`}>{nextValue.requester !== null && nextValue.requester.lastName !== null ? nextValue.requester.lastName : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Phone Number</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.requester !== null && rec.requester.phoneNumber !== null ? rec.requester.phoneNumber : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.requester.phoneNumber, nextValue.requester.phoneNumber) === false ? 'different' : 'same'}`}>{nextValue.requester !== null && nextValue.requester.phoneNumber !== null ? nextValue.requester.phoneNumber : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Email</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.requester !== null && rec.requester.email !== null ? rec.requester.email : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.requester.email, nextValue.requester.email) === false ? 'different' : 'same'}`}>{nextValue.requester !== null && nextValue.requester.email !== null ? nextValue.requester.email : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Rank</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.requester !== null && rec.requester.rank !== null && rec.requester.rank.gradeTitle !== null ? rec.requester.rank.gradeTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.requester !== null && rec.requester.rank !== null && rec.requester.rank.gradeTitle !== null ? rec.requester.rank.gradeTitle : 'N/A', nextValue.requester !== null && nextValue.requester.rank !== null && nextValue.requester.rank.gradeTitle !== null) === false ? 'different' : 'same'}`}>{nextValue.requester !== null && nextValue.requester.rank !== null && nextValue.requester.rank.gradeTitle !== null ? nextValue.requester.rank.gradeTitle : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Duty Title</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.requester !== null && rec.requester.dutyTitle !== null ? rec.requester.dutyTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.requester.dutyTitle, nextValue.requester.dutyTitle) === false ? 'different' : 'same'}`}>{nextValue.requester !== null && nextValue.requester.dutyTitle !== null ? nextValue.requester.dutyTitle : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Branch of Service</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.requester !== null && rec.requester.branchOfService !== null ? rec.requester.branchOfService : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.requester.branchOfService, nextValue.requester.branchOfService) === false ? 'different' : 'same'}`}>{nextValue.requester !== null && nextValue.requester.branchOfService !== null ? nextValue.requester.branchOfService : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>
									</AccordionContent>
								</Accordion>

								{/* Requesting Unit Information */}
								<Accordion>
									<AccordionHeading>Requesting Unit Information</AccordionHeading>
									<AccordionContent>
										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Unit</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.requestingUnit !== null && rec.requestingUnit.unit !== null && rec.requestingUnit.unit.uic !== null ? rec.requestingUnit.unit.uic : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.requestingUnit !== null && rec.requestingUnit.unit !== null && rec.requestingUnit.unit.uic !== null ? rec.requestingUnit.unit.uic : 'N/A', nextValue.requestingUnit !== null && nextValue.requestingUnit.unit !== null && nextValue.requestingUnit.unit.uic !== null) === false ? 'different' : 'same'}`}>{nextValue.requestingUnit !== null && nextValue.requestingUnit.unit !== null && nextValue.requestingUnit.unit.uic !== null ? nextValue.requestingUnit.unit.uic : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>PLADS</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.requestingUnit !== null && rec.requestingUnit.plads !== null ? rec.requestingUnit.plads : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.requestingUnit !== null && rec.requestingUnit.plads !== null ? rec.requestingUnit.plads : 'N/A', nextValue.requestingUnit !== null && nextValue.requestingUnit.plads !== null ? nextValue.requestingUnit.plads : 'N/A') === false ? 'different' : 'same'}`}>{nextValue.requestingUnit !== null && nextValue.requestingUnit.plads !== null ? nextValue.requestingUnit.plads : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>
									</AccordionContent>
								</Accordion>

								{/* Departure Airport Information */}
								<Accordion>
									<AccordionHeading>Departure Airport Information</AccordionHeading>
									<AccordionContent>
										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Airport Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.airport !== null && rec.departure.airport.name !== null && rec.departure.airport.name !== '' ? rec.departure.airport.name : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.departure.airport.name, nextValue.departure.airport.name) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.airport !== null && nextValue.departure.airport.name !== null && nextValue.departure.airport.name !== '' ? nextValue.departure.airport.name : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>ICAO</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.airport !== null && rec.departure.airport.icao !== null && rec.departure.airport.icao !== '' ? rec.departure.airport.icao : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.departure.airport.icao, nextValue.departure.airport.icao) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.airport !== null && nextValue.departure.airport.icao !== null && nextValue.departure.airport.icao !== '' ? nextValue.departure.airport.icao : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>IATA</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.airport !== null && rec.departure.airport.iata !== null && rec.departure.airport.iata !== '' ? rec.departure.airport.iata : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.departure.airport.iata, nextValue.departure.airport.iata) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.airport !== null && nextValue.departure.airport.iata !== null && nextValue.departure.airport.iata !== '' ? nextValue.departure.airport.iata : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Latitude</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.airport !== null && rec.departure.airport.latitude !== null && rec.departure.airport.latitude !== '' ? rec.departure.airport.latitude : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.departure.airport.latitude, nextValue.departure.airport.latitude) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.airport !== null && nextValue.departure.airport.latitude !== null && nextValue.departure.airport.latitude !== '' ? nextValue.departure.airport.latitude : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Longitude</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.airport !== null && rec.departure.airport.longitude !== null && rec.departure.airport.longitude !== '' ? rec.departure.airport.longitude : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.departure.airport.longitude, nextValue.departure.airport.longitude) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.airport !== null && nextValue.departure.airport.longitude !== null && nextValue.departure.airport.longitude !== '' ? nextValue.departure.airport.longitude : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>
									</AccordionContent>
								</Accordion>

								{/* Departure Times */}
								<Accordion>
									<AccordionHeading>Departure Times</AccordionHeading>
									<AccordionContent>
										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Earliest</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.earliest !== null && rec.departure.earliest !== '' ? rec.departure.earliest : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.departure.earliest, nextValue.departure.earliest) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.earliest !== null ? nextValue.departure.earliest : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Latest</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.latest !== null ? rec.departure.latest : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.departure.latest, nextValue.departure.latest) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.latest !== null ? nextValue.departure.latest : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Planned</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.planned !== null ? rec.departure.planned : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.departure.planned, nextValue.departure.planned) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.planned !== null ? nextValue.departure.planned : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Actual</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.actual !== null ? rec.departure.actual : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.departure.actual, nextValue.departure.actual) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.actual !== null ? nextValue.departure.actual : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>
									</AccordionContent>
								</Accordion>

								{/* Departure POC */}
								<Accordion>
									<AccordionHeading>Departure Points of Contact</AccordionHeading>
									<AccordionContent>
										{/* Primary Point of Contact */}
										<Row>
											<div className={'col-12'}>
												<h5 className={'margin-all-0 padding-top-0 padding-bottom-10'}>Primary Point of Contact</h5>
											</div>
										</Row>

										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>First Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.firstName !== null ? rec.departure.poc.primary.firstName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.primary && rec.departure.poc.primary.firstName && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.firstName !== null && nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.firstName !== null && <span className={`next ${diff(rec.departure.poc.primary.firstName, nextValue.departure.poc.primary.firstName) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.firstName !== null ? nextValue.departure.poc.primary.firstName : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Last Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.lastName !== null ? rec.departure.poc.primary.lastName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.primary && rec.departure.poc.primary.lastName && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.lastName !== null && nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.lastName !== null && <span className={`next ${diff(rec.departure.poc.primary.lastName, nextValue.departure.poc.primary.lastName) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.lastName !== null ? nextValue.departure.poc.primary.lastName : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Phone Number</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.phoneNumber !== null ? rec.departure.poc.primary.phoneNumber : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.primary && rec.departure.poc.primary.phoneNumber && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.phoneNumber !== null && nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.phoneNumber !== null && <span className={`next ${diff(rec.departure.poc.primary.phoneNumber, nextValue.departure.poc.primary.phoneNumber) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.phoneNumber !== null ? nextValue.departure.poc.primary.phoneNumber : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Email</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.email !== null ? rec.departure.poc.primary.email : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.primary && rec.departure.poc.primary.email && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.email !== null && nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.email !== null && <span className={`next ${diff(rec.departure.poc.primary.email, nextValue.departure.poc.primary.email) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.email !== null ? nextValue.departure.poc.primary.email : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Rank</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.rank.gradeTitle !== null ? rec.departure.poc.primary.rank.gradeTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.primary && rec.departure.poc.primary.rank && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure.poc.primary.rank !== null || (nextValue.departure.poc.primary.rank.gradeTitle && <span className={`next ${diff(rec.departure.poc.primary.rank.gradeTitle, nextValue.departure.poc.primary.rank.gradeTitle) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.rank.gradeTitle !== null ? nextValue.departure.poc.primary.rank.gradeTitle : 'N/A'}</span>)}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Duty Title</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.dutyTitle !== null ? rec.departure.poc.primary.dutyTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.dutyTitle !== null && nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.dutyTitle !== null && <span className={`next ${diff(rec.departure.poc.primary.dutyTitle, nextValue.departure.poc.primary.dutyTitle) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.dutyTitle !== null ? nextValue.departure.poc.primary.dutyTitle : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Branch of Service</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.branchOfService !== null ? rec.departure.poc.primary.branchOfService : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.primary && rec.departure.poc.primary.branchOfService && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.primary !== null && rec.departure.poc.primary.branchOfService !== null && nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.branchOfService !== null && <span className={`next ${diff(rec.departure.poc.primary.branchOfService, nextValue.departure.poc.primary.branchOfService) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.primary !== null && nextValue.departure.poc.primary.branchOfService !== null ? nextValue.departure.poc.primary.branchOfService : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>

										{/* Alternate Point of Contact */}
										<Row>
											<div className={'col-12'}>
												<h5 className={'margin-all-0 padding-top-20 padding-bottom-10'}>Alternate Point of Contact</h5>
											</div>
										</Row>

										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>First Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.alternate !== null && rec.departure.poc.alternate.firstName !== null ? rec.departure.poc.alternate.firstName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.alternate && rec.departure.poc.alternate.firstName && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure.poc.alternate.firstName && <span className={`next ${diff(rec.departure.poc.alternate.firstName, nextValue.departure.poc.alternate.firstName) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.alternate !== null && nextValue.departure.poc.alternate.firstName !== null ? nextValue.departure.poc.alternate.firstName : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Last Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.alternate !== null && rec.departure.poc.alternate.lastName !== null ? rec.departure.poc.alternate.lastName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.alternate && rec.departure.poc.alternate.lastName && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure.poc.alternate.lastName && <span className={`next ${diff(rec.departure.poc.alternate.lastName, nextValue.departure.poc.alternate.lastName) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.alternate !== null && nextValue.departure.poc.alternate.lastName !== null ? nextValue.departure.poc.alternate.lastName : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Phone Number</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.alternate !== null && rec.departure.poc.alternate.phoneNumber !== null ? rec.departure.poc.alternate.phoneNumber : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.alternate && rec.departure.poc.alternate.phoneNumber && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure.poc.alternate.phoneNumber && <span className={`next ${diff(rec.departure.poc.alternate.phoneNumber, nextValue.departure.poc.alternate.phoneNumber) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.alternate !== null && nextValue.departure.poc.alternate.phoneNumber !== null ? nextValue.departure.poc.alternate.phoneNumber : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Email</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.alternate !== null && rec.departure.poc.alternate.email !== null ? rec.departure.poc.alternate.email : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.alternate && rec.departure.poc.alternate.email && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure.poc.alternate.email && <span className={`next ${diff(rec.departure.poc.alternate.email, nextValue.departure.poc.alternate.email) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.alternate !== null && nextValue.departure.poc.alternate.email !== null ? nextValue.departure.poc.alternate.email : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Rank</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.alternate !== null && rec.departure.poc.alternate.rank.gradeTitle !== null ? rec.departure.poc.alternate.rank.gradeTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.alternate && rec.departure.poc.alternate.rank && rec.departure.poc.alternate.rank.gradeTitle && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure.poc.alternate.rank.gradeTitle || (nextValue.departure.poc.alternate.rank.gradeTitle && <span className={`next ${diff(rec.departure.poc.alternate.rank.gradeTitle, nextValue.departure.poc.alternate.rank.gradeTitle) === false ? 'different' : 'same'}`}>{rec.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.alternate !== null && nextValue.departure.poc.alternate.rank.gradeTitle !== null ? nextValue.departure.poc.alternate.rank.gradeTitle : 'N/A'}</span>)}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Duty Title</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.alternate !== null && rec.departure.poc.alternate.dutyTitle !== null ? rec.departure.poc.alternate.dutyTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.alternate !== null && rec.departure.poc.alternate.dutyTitle !== null && <span className={`next ${diff(rec.departure.poc.alternate.dutyTitle, nextValue.departure.poc.alternate.dutyTitle) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.alternate !== null && nextValue.departure.poc.alternate.dutyTitle !== null ? nextValue.departure.poc.alternate.dutyTitle : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Branch of Service</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.departure !== null && rec.departure.poc !== null && rec.departure.poc.alternate !== null && rec.departure.poc.alternate.branchOfService !== null ? rec.departure.poc.alternate.branchOfService : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.departure && rec.departure.poc && rec.departure.poc.alternate && rec.departure.poc.alternate.branchOfService && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.departure.poc.alternate.branchOfService && <span className={`next ${diff(rec.departure.poc.alternate.branchOfService, nextValue.departure.poc.alternate.branchOfService) === false ? 'different' : 'same'}`}>{nextValue.departure !== null && nextValue.departure.poc !== null && nextValue.departure.poc.alternate !== null && nextValue.departure.poc.alternate.branchOfService !== null ? nextValue.departure.poc.alternate.branchOfService : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>
									</AccordionContent>
								</Accordion>

								{/* Arrival Information */}
								<Accordion>
									<AccordionHeading>Arrival Airport Information</AccordionHeading>
									<AccordionContent>
										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Airport Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.airport !== null && rec.arrival.airport.name !== null ? rec.arrival.airport.name : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.arrival.airport.name, nextValue.arrival.airport.name) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.airport !== null && nextValue.arrival.airport.name !== null ? nextValue.arrival.airport.name : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>ICAO</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.airport !== null && rec.arrival.airport.icao !== null ? rec.arrival.airport.icao : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.arrival.airport.icao, nextValue.arrival.airport.icao) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.airport !== null && nextValue.arrival.airport.icao !== null ? nextValue.arrival.airport.icao : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>IATA</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.airport !== null && rec.arrival.airport.iata !== null ? rec.arrival.airport.iata : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.arrival.airport.iata, nextValue.arrival.airport.iata) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.airport !== null && nextValue.arrival.airport.iata !== null ? nextValue.arrival.airport.iata : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Latitude</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.airport !== null && rec.arrival.airport.latitude !== null ? rec.arrival.airport.latitude : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.arrival.airport.latitude, nextValue.arrival.airport.latitude) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.airport !== null && nextValue.arrival.airport.latitude !== null ? nextValue.arrival.airport.latitude : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Longitude</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.airport !== null && rec.arrival.airport.longitude !== null ? rec.arrival.airport.longitude : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.arrival.airport.longitude, nextValue.arrival.airport.longitude) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.airport !== null && nextValue.arrival.airport.longitude !== null ? nextValue.arrival.airport.longitude : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>
									</AccordionContent>
								</Accordion>

								{/* Arrival Times */}
								<Accordion>
									<AccordionHeading>Arrival Times</AccordionHeading>
									<AccordionContent>
										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Earliest</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.earliest !== null ? rec.arrival.earliest : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.arrival.earliest, nextValue.arrival.earliest) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.earliest !== null ? nextValue.arrival.earliest : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Latest</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.latest !== null ? rec.arrival.latest : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.arrival.latest, nextValue.arrival.latest) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.latest !== null ? nextValue.arrival.latest : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Planned</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.planned !== null ? rec.arrival.planned : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.arrival.planned, nextValue.arrival.planned) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.planned !== null ? nextValue.arrival.planned : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Actual</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.actual !== null ? rec.arrival.actual : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															<span className={`next ${diff(rec.arrival.actual, nextValue.arrival.actual) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.actual !== null ? nextValue.arrival.actual : 'N/A'}</span>
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>
									</AccordionContent>
								</Accordion>

								{/* Arrival POC */}
								<Accordion>
									<AccordionHeading>Arrival Points of Contact</AccordionHeading>

									<AccordionContent>
										{/* Primary Point of Contact */}
										<Row>
											<div className={'col-12'}>
												<h5 className={'margin-all-0 padding-top-0 padding-bottom-10'}>Primary Point of Contact</h5>
											</div>
										</Row>

										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>First Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.primary !== null && rec.arrival.poc.primary.firstName !== null ? rec.arrival.poc.primary.firstName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.primary && rec.arrival.poc.primary.firstName && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival.poc.primary.firstName && <span className={`next ${diff(rec.arrival.poc.primary.firstName, nextValue.arrival.poc.primary.firstName) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.primary !== null && nextValue.arrival.poc.primary.firstName !== null ? nextValue.arrival.poc.primary.firstName : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Last Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.primary !== null && rec.arrival.poc.primary.lastName !== null ? rec.arrival.poc.primary.lastName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.primary && rec.arrival.poc.primary.lastName && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival.poc.primary.lastName && <span className={`next ${diff(rec.arrival.poc.primary.lastName, nextValue.arrival.poc.primary.lastName) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.primary !== null && nextValue.arrival.poc.primary.lastName !== null ? nextValue.arrival.poc.primary.lastName : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Phone Number</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.primary !== null && rec.arrival.poc.primary.phoneNumber !== null ? rec.arrival.poc.primary.phoneNumber : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.primary && rec.arrival.poc.primary.phoneNumber && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival.poc.primary.phoneNumber && <span className={`next ${diff(rec.arrival.poc.primary.phoneNumber, nextValue.arrival.poc.primary.phoneNumber) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.primary !== null && nextValue.arrival.poc.primary.phoneNumber !== null ? nextValue.arrival.poc.primary.phoneNumber : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Email</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.primary !== null && rec.arrival.poc.primary.email !== null ? rec.arrival.poc.primary.email : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.primary && rec.arrival.poc.primary.email && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival.poc.primary.email && <span className={`next ${diff(rec.arrival.poc.primary.email, nextValue.arrival.poc.primary.email) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.primary !== null && nextValue.arrival.poc.primary.email !== null ? nextValue.arrival.poc.primary.email : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Rank</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.primary !== null && rec.arrival.poc.primary.rank !== null && rec.arrival.poc.primary.rank.gradeTitle !== null ? rec.arrival.poc.primary.rank.gradeTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.primary && rec.arrival.poc.primary.rank && rec.arrival.poc.primary.rank.gradeTitle && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival.poc.primary.rank.gradeTitle !== null || (nextValue.arrival.poc.primary.rank.gradeTitle !== null && <span className={`next ${diff(rec.arrival.poc.primary.rank.gradeTitle, nextValue.arrival.poc.primary.rank).gradeTitle === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.primary !== null && nextValue.arrival.poc.primary.rank.gradeTitle !== null ? nextValue.arrival.poc.primary.rank.gradeTitle : 'N/A'}</span>)}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Duty Title</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.primary !== null && rec.arrival.poc.primary.dutyTitle !== null ? rec.arrival.poc.primary.dutyTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.primary && rec.arrival.poc.primary.dutyTitle && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival.poc.primary.dutyTitle && <span className={`next ${diff(rec.arrival.poc.primary.dutyTitle, nextValue.arrival.poc.primary.dutyTitle) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.primary !== null && nextValue.arrival.poc.primary.dutyTitle !== null ? nextValue.arrival.poc.primary.dutyTitle : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Branch of Service</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.primary !== null && rec.arrival.poc.primary.branchOfService !== null ? rec.arrival.poc.primary.branchOfService : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.primary && rec.arrival.poc.primary.branchOfService && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival.poc.primary.branchOfService && <span className={`next ${diff(rec.arrival.poc.primary.branchOfService, nextValue.arrival.poc.primary.branchOfService) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.primiary !== null && nextValue.arrival.poc.primary.branchOfService !== null ? nextValue.arrival.poc.primary.branchOfService : 'N/A'}</span>}
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>

										{/* Alternate Point of Contact */}
										<Row>
											<div className={'col-12'}>
												<h5 className={'margin-all-0 padding-top-20 padding-bottom-10'}>Alternate Point of Contact</h5>
											</div>
										</Row>

										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>First Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.firstName !== null ? rec.arrival.poc.alternate.firstName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.alternate && rec.arrival.poc.alternate.firstName && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.firstName !== null && rec.arrival.poc.alternate.rank.gradeTitle !== null && nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.rank !== null && nextValue.arrival.poc.alternate.rank.firstName !== null ? (
																<span className={`next ${diff(rec.arrival.poc.alternate.firstName, nextValue.arrival.poc.alternate.firstName) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.firstName !== null ? nextValue.arrival.poc.alternate.firstName : 'N/A'}</span>
															) : (
																'N/A'
															)}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Last Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.lastName !== null ? rec.arrival.poc.alternate.lastName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.alternate && rec.arrival.poc.alternate.lastName && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.lastName !== null && rec.arrival.poc.alternate.rank.gradeTitle !== null && nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.rank !== null && nextValue.arrival.poc.alternate.rank.lastName !== null ? (
																<span className={`next ${diff(rec.arrival.poc.alternate.lastName, nextValue.arrival.poc.alternate.lastName) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.lastName !== null ? nextValue.arrival.poc.alternate.lastName : 'N/A'}</span>
															) : (
																'N/A'
															)}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Phone Number</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.phoneNumber !== null ? rec.arrival.poc.alternate.phoneNumber : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.alternate && rec.arrival.poc.alternate.phoneNumber && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.phoneNumber !== null && rec.arrival.poc.alternate.rank.gradeTitle !== null && nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.rank !== null && nextValue.arrival.poc.alternate.rank.phoneNumber !== null ? (
																<span className={`next ${diff(rec.arrival.poc.alternate.phoneNumber, nextValue.arrival.poc.alternate.phoneNumber) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.phoneNumber !== null ? nextValue.arrival.poc.alternate.phoneNumber : 'N/A'}</span>
															) : (
																'N/A'
															)}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Email</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.email !== null ? rec.arrival.poc.alternate.email : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.alternate && rec.arrival.poc.alternate.email && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.email !== null && rec.arrival.poc.alternate.rank.gradeTitle !== null && nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.rank !== null && nextValue.arrival.poc.alternate.rank.email !== null ? (
																<span className={`next ${diff(rec.arrival.poc.alternate.email, nextValue.arrival.poc.alternate.email) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.email !== null ? nextValue.arrival.poc.alternate.email : 'N/A'}</span>
															) : (
																'N/A'
															)}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Rank</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.rank.gradeTitle !== null ? rec.arrival.poc.alternate.rank.gradeTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.alternate && rec.arrival.poc.alternate.rank && rec.arrival.poc.alternate.rank.gradeTitle && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.rank !== null && rec.arrival.poc.alternate.rank.gradeTitle !== null && nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.rank !== null && nextValue.arrival.poc.alternate.rank.gradeTitle !== null ? (
																<span className={`next ${diff(rec.arrival.poc.alternate.rank.gradeTitle, nextValue.arrival.poc.alternate.rank.gradeTitle) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.rank.gradeTitle !== null ? nextValue.arrival.poc.alternate.rank.gradeTitle : 'N/A'}</span>
															) : (
																'N/A'
															)}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Duty Title</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.dutyTitle !== null ? rec.arrival.poc.alternate.dutyTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.alternate && rec.arrival.poc.alternate.rank && rec.arrival.poc.alternate.rank.dutyTitle && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.dutyTitle !== null && rec.arrival.poc.alternate.rank.gradeTitle !== null && nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.rank !== null && nextValue.arrival.poc.alternate.rank.dutyTitle !== null ? (
																<span className={`next ${diff(rec.arrival.poc.alternate.dutyTitle, nextValue.arrival.poc.alternate.dutyTitle) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.dutyTitle !== null ? nextValue.arrival.poc.alternate.dutyTitle : 'N/A'}</span>
															) : (
																'N/A'
															)}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Branch of Service</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.branchOfService !== null ? rec.arrival.poc.alternate.branchOfService : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.arrival && rec.arrival.poc && rec.arrival.poc.alternate && rec.arrival.poc.alternate.branchOfService && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.arrival !== null && rec.arrival.poc !== null && rec.arrival.poc.alternate !== null && rec.arrival.poc.alternate.branchOfService !== null && rec.arrival.poc.alternate.rank.gradeTitle !== null && nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.rank !== null && nextValue.arrival.poc.alternate.rank.branchOfService !== null ? (
																<span className={`next ${diff(rec.arrival.poc.alternate.branchOfService, nextValue.arrival.poc.alternate.branchOfService) === false ? 'different' : 'same'}`}>{nextValue.arrival !== null && nextValue.arrival.poc !== null && nextValue.arrival.poc.alternate !== null && nextValue.arrival.poc.alternate.branchOfService !== null ? nextValue.arrival.poc.alternate.branchOfService : 'N/A'}</span>
															) : (
																'N/A'
															)}
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>
									</AccordionContent>
								</Accordion>

								{/* Travel Authorizing Official */}
								<Accordion>
									<AccordionHeading>Travel Authorizing Official</AccordionHeading>
									<AccordionContent>
										<Row>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>First Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.commandingOfficer !== null && rec.commandingOfficer.firstName ? rec.commandingOfficer.firstName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.commandingOfficer && rec.commandingOfficer.firstName && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.commandingOfficer !== null && rec.commandingOfficer.firstName !== null && nextValue.commandingOfficer !== null && nextValue.commandingOfficer.firstName !== null ? <span className={`next ${diff(rec.commandingOfficer.firstName, nextValue.commandingOfficer.firstName) === false ? 'different' : 'same'}`}>{nextValue.commandingOfficer !== null && nextValue.commandingOfficer.firstName ? nextValue.commandingOfficer.firstName : 'N/A'}</span> : 'N/A'}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Last Name</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.commandingOfficer !== null && rec.commandingOfficer.lastName ? rec.commandingOfficer.lastName : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.commandingOfficer && rec.commandingOfficer.lastName && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.commandingOfficer !== null && rec.commandingOfficer.lastName !== null && nextValue.commandingOfficer !== null && nextValue.commandingOfficer.lastName !== null ? <span className={`next ${diff(rec.commandingOfficer.lastName, nextValue.commandingOfficer.lastName) === false ? 'different' : 'same'}`}>{nextValue.commandingOfficer !== null && nextValue.commandingOfficer.lastName ? nextValue.commandingOfficer.lastName : 'N/A'}</span> : 'N/A'}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Phone Number</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.commandingOfficer !== null && rec.commandingOfficer.phoneNumber ? rec.commandingOfficer.phoneNumber : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.commandingOfficer && rec.commandingOfficer.phoneNumber && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.commandingOfficer !== null && rec.commandingOfficer.phoneNumber !== null && nextValue.commandingOfficer !== null && nextValue.commandingOfficer.phoneNumber !== null ? <span className={`next ${diff(rec.commandingOfficer.phoneNumber, nextValue.commandingOfficer.phoneNumber) === false ? 'different' : 'same'}`}>{nextValue.commandingOfficer !== null && nextValue.commandingOfficer.phoneNumber ? nextValue.commandingOfficer.phoneNumber : 'N/A'}</span> : 'N/A'}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Email</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.commandingOfficer !== null && rec.commandingOfficer.email ? rec.commandingOfficer.email : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.commandingOfficer && rec.commandingOfficer.email && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.commandingOfficer !== null && rec.commandingOfficer.email !== null && nextValue.commandingOfficer !== null && nextValue.commandingOfficer.email !== null ? <span className={`next ${diff(rec.commandingOfficer.email, nextValue.commandingOfficer.email) === false ? 'different' : 'same'}`}>{nextValue.commandingOfficer !== null && nextValue.commandingOfficer.email ? nextValue.commandingOfficer.email : 'N/A'}</span> : 'N/A'}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Rank</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.commandingOfficer !== null && rec.commandingOfficer.rank !== null && rec.commandingOfficer.rank.gradeTitle ? rec.commandingOfficer.rank.gradeTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.commandingOfficer && rec.commandingOfficer.rank && rec.commandingOfficer.rank.gradeTitle && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.commandingOfficer !== null && rec.commandingOfficer.rank !== null && nextValue.commandingOfficer !== null && nextValue.commandingOfficer.rank !== null ? <span className={`next ${diff(rec.commandingOfficer.rank.gradeTitle, nextValue.commandingOfficer.rank.gradeTitle) === false ? 'different' : 'same'}`}>{nextValue.commandingOfficer !== null && nextValue.commandingOfficer.rank.gradeTitle ? nextValue.commandingOfficer.rank.gradeTitle : 'N/A'}</span> : 'N/A'}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Duty Title</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.commandingOfficer !== null && rec.commandingOfficer.dutyTitle ? rec.commandingOfficer.dutyTitle : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.commandingOfficer && rec.commandingOfficer.dutyTitle && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.commandingOfficer !== null && rec.commandingOfficer.dutyTitle !== null && nextValue.commandingOfficer !== null && nextValue.commandingOfficer.dutyTitle !== null ? <span className={`next ${diff(rec.commandingOfficer.dutyTitle, nextValue.commandingOfficer.dutyTitle) === false ? 'different' : 'same'}`}>{nextValue.commandingOfficer !== null && nextValue.commandingOfficer.dutyTitle ? nextValue.commandingOfficer.dutyTitle : 'N/A'}</span> : 'N/A'}
														</React.Fragment>
													)}
												</div>
											</div>
											<div className={'lr-history-row-item col-12'}>
												<div className={'lr-history-label'}>Branch of Service</div>
												<div className={'lr-history-value'}>
													<span className={'prev'}>{rec.commandingOfficer !== null && rec.commandingOfficer.branchOfService ? rec.commandingOfficer.branchOfService : 'N/A'}</span>
													{i + 1 !== liftDetails.history.length && rec.commandingOfficer && rec.commandingOfficer.branchOfService && (
														<React.Fragment>
															<span className={'compare'}>
																<i className={'far fa-arrow-left'}></i>
															</span>
															{rec.commandingOfficer !== null && rec.commandingOfficer.branchOfService !== null && nextValue.commandingOfficer !== null && nextValue.commandingOfficer.branchOfService !== null ? <span className={`next ${diff(rec.commandingOfficer.branchOfService, nextValue.commandingOfficer.branchOfService) === false ? 'different' : 'same'}`}>{nextValue.commandingOfficer !== null && nextValue.commandingOfficer.branchOfService ? nextValue.commandingOfficer.branchOfService : 'N/A'}</span> : 'N/A'}
														</React.Fragment>
													)}
												</div>
											</div>
										</Row>
									</AccordionContent>
								</Accordion>

								{/* Cargo Details */}
								<Accordion>
									<AccordionHeading>Cargo Details</AccordionHeading>
									<AccordionContent>
										{/* Cargo: Passengers */}
										{rec.cargoes !== null && rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].type !== null && rec.cargoes[0].type === 'PASSENGERS' && (
											<React.Fragment>
												<Row>
													<div className={'col-12'}>
														<h5 className={'margin-all-0 padding-top-0 padding-bottom-10'}>Passengers</h5>
													</div>
												</Row>

												<Row>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>PAX Count</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes !== null && rec.cargoes[0].count !== null ? rec.cargoes[0].count : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	<span className={'next'}>{rec.cargoes !== null && rec.cargoes[0].count !== null ? numberWithCommas(rec.cargoes[0].count) : 'N/A'}</span>
																</React.Fragment>
															)}
														</div>
													</div>
												</Row>

												{rec.cargoes !== null &&
													rec.cargoes[0].seniorTravelers !== null &&
													rec.cargoes[0].seniorTravelers.map((r, j, a) => {
														return (
															<React.Fragment>
																<Row className={'margin-top-20'}>
																	<div className={'col-12'}>
																		<h5 className={'margin-all-0 padding-top-0 padding-bottom-10'}>Senior Travelers</h5>
																	</div>
																</Row>

																<Row>
																	<div className={'lr-history-row-item col-12'}>
																		<div className={'lr-history-label'}>
																			<strong>Senior Traveler {j + 1}</strong>
																		</div>
																	</div>
																	<div className={'lr-history-row-item col-12'}>
																		<div className={'lr-history-label'}>First Name</div>
																		<div className={'lr-history-value'}>
																			<span className={'prev'}>{r.firstName !== null ? r.firstName : 'N/A'}</span>
																			{i + 1 !== liftDetails.history.length && (
																				<React.Fragment>
																					<span className={'compare'}>
																						<i className={'far fa-arrow-left'}></i>
																					</span>
																					<span className={'next'}>{r.firstName !== null ? r.firstName : 'N/A'}</span>
																				</React.Fragment>
																			)}
																		</div>
																	</div>
																	<div className={'lr-history-row-item col-12'}>
																		<div className={'lr-history-label'}>Last Name</div>
																		<div className={'lr-history-value'}>
																			<span className={'prev'}>{r.lastName !== null ? r.lastName : 'N/A'}</span>
																			{i + 1 !== liftDetails.history.length && (
																				<React.Fragment>
																					<span className={'compare'}>
																						<i className={'far fa-arrow-left'}></i>
																					</span>
																					<span className={'next'}>{r.lastName !== null ? r.lastName : 'N/A'}</span>
																				</React.Fragment>
																			)}
																		</div>
																	</div>
																	<div className={'lr-history-row-item col-12'}>
																		<div className={'lr-history-label'}>Email</div>
																		<div className={'lr-history-value'}>
																			<span className={'prev'}>{r.email !== null ? r.email : 'N/A'}</span>
																			{i + 1 !== liftDetails.history.length && (
																				<React.Fragment>
																					<span className={'compare'}>
																						<i className={'far fa-arrow-left'}></i>
																					</span>
																					<span className={'next'}>{r.email !== null ? r.email : 'N/A'}</span>
																				</React.Fragment>
																			)}
																		</div>
																	</div>
																	<div className={'lr-history-row-item col-12'}>
																		<div className={'lr-history-label'}>Phone Number</div>
																		<div className={'lr-history-value'}>
																			<span className={'prev'}>{r.phoneNumber !== null ? r.phoneNumber : 'N/A'}</span>
																			{i + 1 !== liftDetails.history.length && (
																				<React.Fragment>
																					<span className={'compare'}>
																						<i className={'far fa-arrow-left'}></i>
																					</span>
																					<span className={'next'}>{r.phoneNumber !== null ? r.phoneNumber : 'N/A'}</span>
																				</React.Fragment>
																			)}
																		</div>
																	</div>
																	<div className={'lr-history-row-item col-12'}>
																		<div className={'lr-history-label'}>Rank</div>
																		<div className={'lr-history-value'}>
																			<span className={'prev'}>{r.rank.gradeTitle !== null ? r.rank.gradeTitle : 'N/A'}</span>
																			{i + 1 !== liftDetails.history.length && (
																				<React.Fragment>
																					<span className={'compare'}>
																						<i className={'far fa-arrow-left'}></i>
																					</span>
																					<span className={'next'}>{r.rank.gradeTitle !== null ? r.rank.gradeTitle : 'N/A'}</span>
																				</React.Fragment>
																			)}
																		</div>
																	</div>
																	<div className={'lr-history-row-item col-12'}>
																		<div className={'lr-history-label'}>Duty Title</div>
																		<div className={'lr-history-value'}>
																			<span className={'prev'}>{r.dutyTitle !== null ? r.dutyTitle : 'N/A'}</span>
																			{i + 1 !== liftDetails.history.length && (
																				<React.Fragment>
																					<span className={'compare'}>
																						<i className={'far fa-arrow-left'}></i>
																					</span>
																					<span className={'next'}>{r.dutyTitle !== null ? r.dutyTitle : 'N/A'}</span>
																				</React.Fragment>
																			)}
																		</div>
																	</div>
																	<div className={'lr-history-row-item col-12'}>
																		<div className={'lr-history-label'}>UIC</div>
																		<div className={'lr-history-value'}>
																			<span className={'prev'}>{r.unit !== null && r.unit.uic !== null ? r.unit.uic : 'N/A'}</span>
																			{i + 1 !== liftDetails.history.length && (
																				<React.Fragment>
																					<span className={'compare'}>
																						<i className={'far fa-arrow-left'}></i>
																					</span>
																					<span className={'next'}>{r.unit !== null && r.unit.uic !== null ? r.unit.uic : 'N/A'}</span>
																				</React.Fragment>
																			)}
																		</div>
																	</div>
																	<div className={'lr-history-row-item col-12'}>
																		<div className={'lr-history-label'}>Branch of Service</div>
																		<div className={'lr-history-value'}>
																			<span className={'prev'}>{r.branchOfService !== null ? r.branchOfService : 'N/A'}</span>
																			{i + 1 !== liftDetails.history.length && (
																				<React.Fragment>
																					<span className={'compare'}>
																						<i className={'far fa-arrow-left'}></i>
																					</span>
																					<span className={'next'}>{r.branchOfService !== null ? r.branchOfService : 'N/A'}</span>
																				</React.Fragment>
																			)}
																		</div>
																	</div>
																</Row>
															</React.Fragment>
														);
													})}
											</React.Fragment>
										)}

										{/* Pallets Section Title Header at cargoes[0] or cargoes[1] */}
										{(rec.cargoes !== null && rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].type !== null && rec.cargoes[0].type === 'PALLETS') ||
											(rec.cargoes !== null && rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].type !== null && rec.cargoes[1].type === 'PALLETS' && (
												<React.Fragment>
													<Row className={'padding-top-20'}>
														<div className={'col-12'}>
															<h5 className={'margin-all-0 padding-top-0 padding-bottom-10'}>Pallets</h5>
														</div>
													</Row>
												</React.Fragment>
											))}

										{/* Pallets at cargoes[0] */}
										{rec.cargoes !== null && rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].type !== null && rec.cargoes[0].type === 'PALLETS' && (
											<React.Fragment>
												<Row>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Count</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes !== null && rec.cargoes[1] !== null ? rec.cargoes[0].count : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].count !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].count !== null ? <span className={`next ${diff(rec.cargoes[0].count, nextValue.cargoes[0].count) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].count !== null ? numberWithCommas(nextValue.cargoes[0].count) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Total Weight</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].weight !== null ? numberWithCommas(rec.cargoes[0].weight) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].weight !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].weight !== null ? <span className={`next ${diff(rec.cargoes[0].weight, nextValue.cargoes[0].weight) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].weight !== null ? numberWithCommas(nextValue.cargoes[0].weight) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Total Cubic Feet</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].totalCubicFeet !== null ? numberWithCommas(rec.cargoes[0].totalCubicFeet) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].totalCubicFeet !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].totalCubicFeet !== null ? <span className={`next ${diff(rec.cargoes[0].totalCubicFeet, nextValue.cargoes[0].totalCubicFeet) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].totalCubicFeet !== null ? numberWithCommas(nextValue.cargoes[0].totalCubicFeet) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Largest Item Dimensions (Length)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.length !== null ? numberWithCommas(rec.cargoes[0].largestItem.length) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.length !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].largestItem !== null && nextValue.cargoes[0].largestItem.length !== null ? <span className={`next ${diff(rec.cargoes[0].largestItem.length, nextValue.cargoes[0].largestItem.length) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].largestItem !== null && nextValue.cargoes[0].largestItem.length !== null ? numberWithCommas(nextValue.cargoes[0].largestItem.length) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Largest Item Dimensions (Width)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.width !== null ? numberWithCommas(rec.cargoes[0].largestItem.width) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.width !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].largestItem !== null && nextValue.cargoes[0].largestItem.width !== null ? <span className={`next ${diff(rec.cargoes[0].largestItem.width, nextValue.cargoes[0].largestItem.width) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].largestItem !== null && nextValue.cargoes[0].largestItem.width !== null ? numberWithCommas(nextValue.cargoes[0].largestItem.width) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Largest Item Dimensions (Height)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.height !== null ? numberWithCommas(rec.cargoes[0].largestItem.height) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.height !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].largestItem !== null && nextValue.cargoes[0].largestItem.height !== null ? <span className={`next ${diff(rec.cargoes[0].largestItem.height, nextValue.cargoes[0].largestItem.height) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].largestItem !== null && nextValue.cargoes[0].largestItem.height !== null ? numberWithCommas(nextValue.cargoes[0].largestItem.height) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Largest Item Dimensions (Weight)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.weight !== null ? numberWithCommas(rec.cargoes[0].largestItem.weight) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.weight !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].largestItem !== null && nextValue.cargoes[0].largestItem.weight !== null ? <span className={`next ${diff(rec.cargoes[0].largestItem.weight, nextValue.cargoes[0].largestItem.weight) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].largestItem !== null && nextValue.cargoes[0].largestItem.weight !== null ? numberWithCommas(nextValue.cargoes[0].largestItem.weight) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Heaviest Item Dimensions (Length)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.length !== null ? numberWithCommas(rec.cargoes[0].heaviestItem.length) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.length !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].heaviestItem !== null && nextValue.cargoes[0].heaviestItem.length !== null ? <span className={`next ${diff(rec.cargoes[0].heaviestItem.length, nextValue.cargoes[0].heaviestItem.length) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].largestItem !== null && nextValue.cargoes[0].largestItem.length !== null ? numberWithCommas(nextValue.cargoes[0].largestItem.length) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Heaviest Item Dimensions (Width)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.width !== null ? numberWithCommas(rec.cargoes[0].heaviestItem.width) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.width !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].heaviestItem !== null && nextValue.cargoes[0].heaviestItem.width !== null ? <span className={`next ${diff(rec.cargoes[0].heaviestItem.width, nextValue.cargoes[0].heaviestItem.width) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].heaviestItem !== null && nextValue.cargoes[0].heaviestItem.width !== null ? numberWithCommas(nextValue.cargoes[0].heaviestItem.width) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Heaviest Item Dimensions (Height)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.height !== null ? numberWithCommas(rec.cargoes[0].heaviestItem.height) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.height !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].heaviestItem !== null && nextValue.cargoes[0].heaviestItem.height !== null ? <span className={`next ${diff(rec.cargoes[0].heaviestItem.height, nextValue.cargoes[0].heaviestItem.height) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].heaviestItem !== null && nextValue.cargoes[0].heaviestItem.height !== null ? numberWithCommas(nextValue.cargoes[0].heaviestItem.height) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Heaviest Item Dimensions (Weight)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.weight !== null ? numberWithCommas(rec.cargoes[0].heaviestItem.weight) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.weight !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].heaviestItem !== null && nextValue.cargoes[0].heaviestItem.weight !== null ? <span className={`next ${diff(rec.cargoes[0].heaviestItem.weight, nextValue.cargoes[0].heaviestItem.weight) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].heaviestItem !== null && nextValue.cargoes[0].heaviestItem.weight !== null ? numberWithCommas(nextValue.cargoes[0].heaviestItem.weight) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Cargo Description</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].description !== null ? rec.cargoes[0].description : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].description !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].description !== null ? <span className={`next ${diff(rec.cargoes[0].description, nextValue.cargoes[0].description) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].description !== null ? nextValue.cargoes[0].description : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Special Handling Requirements</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].specialHandlingRequirements !== null ? rec.cargoes[0].specialHandlingRequirements : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].specialHandlingRequirements !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].specialHandlingRequirements !== null ? <span className={`next ${diff(rec.cargoes[0].specialHandlingRequirements, nextValue.cargoes[0].specialHandlingRequirements) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].specialHandlingRequirements !== null ? nextValue.cargoes[0].specialHandlingRequirements : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Contains Oversize Cargo?</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].oversize !== null ? CapitalizeString(rec.cargoes[0].oversize.toString()) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].oversize !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].oversize !== null ? <span className={`next ${diff(rec.cargoes[0].oversize, nextValue.cargoes[0].oversize) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].oversize !== null ? CapitalizeString(nextValue.cargoes[0].oversize.toString()) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Contains Rolling Stock?</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].rollingStock !== null ? CapitalizeString(rec.cargoes[0].rollingStock.toString()) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].rollingStock !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].rollingStock !== null ? <span className={`next ${diff(rec.cargoes[0].rollingStock, nextValue.cargoes[0].rollingStock) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].rollingStock !== null ? CapitalizeString(nextValue.cargoes[0].rollingStock.toString()) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Contains Hazmat?</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.containsHazmat !== null ? CapitalizeString(rec.cargoes[0].hazmat.containsHazmat.toString()) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.containsHazmat !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.containsHazmat !== null ? <span className={`next ${diff(rec.cargoes[0].hazmat.containsHazmat, nextValue.cargoes[0].hazmat.containsHazmat) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.containsHazmat !== null ? CapitalizeString(nextValue.cargoes[0].hazmat.containsHazmat.toString()) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat UN Number</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].unNumber !== null ? rec.cargoes[0].hazmat.hazmatCargoes[0].unNumber : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].unNumber !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes.length > 0 && nextValue.cargoes[0].hazmat.hazmatCargoes[0].unNumber !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazmatCargoes[0].unNumber, nextValue.cargoes[0].hazmat.hazmatCargoes[0].unNumber) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].unNumber !== null ? nextValue.cargoes[0].hazmat.hazmatCargoes[0].unNumber : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat Shipping Name</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].shippingName !== null ? rec.cargoes[0].hazmat.hazmatCargoes[0].shippingName : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].shippingName !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingName !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazmatCargoes[0].shippingName, nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingName) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingName !== null ? nextValue.cargoes[0].hazmat.shippingName : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat Hazard Class and Division Code</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazardClassAndDivision !== null ? rec.cargoes[0].hazmat.hazardClassAndDivision : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazardClassAndDivision !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazardClassAndDivision !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazardClassAndDivision, nextValue.cargoes[0].hazmat.hazmatCargoes[0].hazardClassAndDivision) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].hazardClassAndDivision !== null ? nextValue.cargoes[0].hazmat.hazmatCargoes[0].hazardClassAndDivision : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat Passenger Eligibility Code</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode !== null ? rec.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode, nextValue.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode !== null ? nextValue.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat Net Explosive Weight</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight !== null ? numberWithCommas(rec.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight, nextValue.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight !== null ? numberWithCommas(nextValue.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight) : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat Shipping Description</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription !== null ? numberWithCommas(rec.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription, nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription !== null ? nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
												</Row>
											</React.Fragment>
										)}

										{/* Pallets at cargoes[1] */}
										{rec.cargoes !== null && rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].type !== null && rec.cargoes[1].type === 'PALLETS' && (
											<React.Fragment>
												<Row>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Count</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes !== null && rec.cargoes[1] !== null && rec.cargoes[1].count !== null ? rec.cargoes[1].count : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].count && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].count !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].count !== null ? <span className={`next ${diff(rec.cargoes[1].count, nextValue.cargoes[1].count) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].count !== null ? numberWithCommas(nextValue.cargoes[1].count) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Total Weight</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].weight !== null ? numberWithCommas(rec.cargoes[0].weight) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].weight !== null ? numberWithCommas(rec.cargoes[1].weight) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].weight && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].weight !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].weight !== null ? <span className={`next ${diff(rec.cargoes[1].weight, nextValue.cargoes[1].weight) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].weight !== null ? numberWithCommas(nextValue.cargoes[1].weight) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Total Cubic Feet</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].totalCubicFeet !== null ? numberWithCommas(rec.cargoes[0].totalCubicFeet) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].totalCubicFeet !== null ? numberWithCommas(rec.cargoes[1].totalCubicFeet) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1] && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].totalCubicFeet !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].totalCubicFeet !== null ? <span className={`next ${diff(rec.cargoes[1].totalCubicFeet, nextValue.cargoes[1].totalCubicFeet) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].totalCubicFeet !== null ? numberWithCommas(nextValue.cargoes[1].totalCubicFeet) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Largest Item Dimensions (Length)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.length !== null ? numberWithCommas(rec.cargoes[0].largestItem.length) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].largestItem !== null && rec.cargoes[1].largestItem.length !== null ? numberWithCommas(rec.cargoes[1].largestItem.length) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].largestItem && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].largestItem !== null && rec.cargoes[1].largestItem.length !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].largestItem !== null && nextValue.cargoes[1].largestItem.length !== null ? <span className={`next ${diff(rec.cargoes[1].largestItem.length, nextValue.cargoes[1].largestItem.length) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].largestItem !== null && nextValue.cargoes[1].largestItem.length !== null ? numberWithCommas(nextValue.cargoes[1].largestItem.length) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Largest Item Dimensions (Width)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.width !== null ? numberWithCommas(rec.cargoes[0].largestItem.width) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].largestItem !== null && rec.cargoes[1].largestItem.width !== null ? numberWithCommas(rec.cargoes[1].largestItem.width) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].largestItem && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].largestItem !== null && rec.cargoes[1].largestItem.width !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].largestItem !== null && nextValue.cargoes[1].largestItem.width !== null ? <span className={`next ${diff(rec.cargoes[1].largestItem.width, nextValue.cargoes[1].largestItem.width) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].largestItem !== null && nextValue.cargoes[1].largestItem.width !== null ? numberWithCommas(nextValue.cargoes[1].largestItem.width) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Largest Item Dimensions (Height)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.height !== null ? numberWithCommas(rec.cargoes[0].largestItem.height) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].largestItem !== null && rec.cargoes[1].largestItem.height !== null ? numberWithCommas(rec.cargoes[1].largestItem.height) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].largestItem && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].largestItem !== null && rec.cargoes[1].largestItem.height !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].largestItem !== null && nextValue.cargoes[1].largestItem.height !== null ? <span className={`next ${diff(rec.cargoes[1].largestItem.height, nextValue.cargoes[1].largestItem.height) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].largestItem !== null && nextValue.cargoes[1].largestItem.height !== null ? numberWithCommas(nextValue.cargoes[1].largestItem.height) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Largest Item Dimensions (Weight)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].largestItem !== null && rec.cargoes[0].largestItem.weight !== null ? numberWithCommas(rec.cargoes[0].largestItem.weight) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].largestItem !== null && rec.cargoes[1].largestItem.weight !== null ? numberWithCommas(rec.cargoes[1].largestItem.weight) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].largestItem && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].largestItem !== null && rec.cargoes[1].largestItem.weight !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].largestItem !== null && nextValue.cargoes[1].largestItem.weight !== null ? <span className={`next ${diff(rec.cargoes[1].largestItem.weight, nextValue.cargoes[1].largestItem.weight) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].largestItem !== null && nextValue.cargoes[1].largestItem.weight !== null ? numberWithCommas(nextValue.cargoes[1].largestItem.weight) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Heaviest Item Dimensions (Length)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.length !== null ? numberWithCommas(rec.cargoes[0].heaviestItem.length) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].heaviestItem !== null && rec.cargoes[1].heaviestItem.length !== null ? numberWithCommas(rec.cargoes[1].heaviestItem.length) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].heaviestItem && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].heaviestItem !== null && rec.cargoes[1].heaviestItem.length !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].heaviestItem !== null && nextValue.cargoes[1].heaviestItem.length !== null ? <span className={`next ${diff(rec.cargoes[1].heaviestItem.length, nextValue.cargoes[1].heaviestItem.length) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].largestItem !== null && nextValue.cargoes[1].largestItem.length !== null ? numberWithCommas(nextValue.cargoes[1].largestItem.length) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Heaviest Item Dimensions (Width)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.width !== null ? numberWithCommas(rec.cargoes[0].heaviestItem.width) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].heaviestItem !== null && rec.cargoes[1].heaviestItem.width !== null ? numberWithCommas(rec.cargoes[1].heaviestItem.width) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].heaviestItem && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].heaviestItem !== null && rec.cargoes[1].heaviestItem.width !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].heaviestItem !== null && nextValue.cargoes[1].heaviestItem.width !== null ? <span className={`next ${diff(rec.cargoes[1].heaviestItem.width, nextValue.cargoes[1].heaviestItem.width) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].heaviestItem !== null && nextValue.cargoes[1].heaviestItem.width !== null ? numberWithCommas(nextValue.cargoes[1].heaviestItem.width) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Heaviest Item Dimensions (Height)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.height !== null ? numberWithCommas(rec.cargoes[0].heaviestItem.height) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].heaviestItem !== null && rec.cargoes[1].heaviestItem.height !== null ? numberWithCommas(rec.cargoes[1].heaviestItem.height) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].heaviestItem && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].heaviestItem !== null && rec.cargoes[1].heaviestItem.height !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].heaviestItem !== null && nextValue.cargoes[1].heaviestItem.height !== null ? <span className={`next ${diff(rec.cargoes[1].heaviestItem.height, nextValue.cargoes[1].heaviestItem.height) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].heaviestItem !== null && nextValue.cargoes[1].heaviestItem.height !== null ? numberWithCommas(nextValue.cargoes[1].heaviestItem.height) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Heaviest Item Dimensions (Weight)</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].heaviestItem !== null && rec.cargoes[0].heaviestItem.weight !== null ? numberWithCommas(rec.cargoes[0].heaviestItem.weight) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].heaviestItem !== null && rec.cargoes[1].heaviestItem.weight !== null ? numberWithCommas(rec.cargoes[1].heaviestItem.weight) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].heaviestItem && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].heaviestItem !== null && rec.cargoes[1].heaviestItem.weight !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].heaviestItem !== null && nextValue.cargoes[1].heaviestItem.weight !== null ? <span className={`next ${diff(rec.cargoes[1].heaviestItem.weight, nextValue.cargoes[1].heaviestItem.weight) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].heaviestItem !== null && nextValue.cargoes[1].heaviestItem.weight !== null ? numberWithCommas(nextValue.cargoes[1].heaviestItem.weight) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Cargo Description</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].description !== null ? rec.cargoes[0].description : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].description !== null ? rec.cargoes[1].description : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].description && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].description !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].description !== null ? <span className={`next ${diff(rec.cargoes[1].description, nextValue.cargoes[1].description) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].description !== null ? nextValue.cargoes[1].description : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Special Handling Requirements</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].specialHandlingRequirements !== null ? rec.cargoes[0].specialHandlingRequirements : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].specialHandlingRequirements !== null ? rec.cargoes[1].specialHandlingRequirements : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].specialHandlingRequirements && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].specialHandlingRequirements !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].specialHandlingRequirements !== null ? <span className={`next ${diff(rec.cargoes[1].specialHandlingRequirements, nextValue.cargoes[1].specialHandlingRequirements) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].specialHandlingRequirements !== null ? nextValue.cargoes[1].specialHandlingRequirements : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Contains Oversize Cargo?</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].oversize !== null ? CapitalizeString(rec.cargoes[0].oversize.toString()) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].oversize !== null ? CapitalizeString(rec.cargoes[1].oversize.toString()) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].oversize && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].oversize !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].oversize !== null ? <span className={`next ${diff(rec.cargoes[1].oversize, nextValue.cargoes[1].oversize) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].oversize !== null ? CapitalizeString(nextValue.cargoes[1].oversize.toString()) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Contains Rolling Stock?</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].rollingStock !== null ? CapitalizeString(rec.cargoes[0].rollingStock.toString()) : rec.cargoes[1] !== null && rec.cargoes[1] !== undefined && rec.cargoes[1].count !== null && rec.cargoes[1].type === 'PALLETS' && rec.cargoes[1].rollingStock !== null ? CapitalizeString(rec.cargoes[1].rollingStock.toString()) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && rec.cargoes[1].rollingStock && nextValue.cargoes[1] && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[1] !== null && rec.cargoes[1].rollingstock !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].rollingstock !== null ? <span className={`next ${diff(rec.cargoes[1].rollingStock, nextValue.cargoes[1].rollingStock) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[1] !== null && nextValue.cargoes[1].rollingStock !== null ? CapitalizeString(nextValue.cargoes[1].rollingStock.toString()) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Contains Hazmat?</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.containsHazmat !== null ? CapitalizeString(rec.cargoes[0].hazmat.containsHazmat.toString()) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.containsHazmat !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.containsHazmat !== null ? <span className={`next ${diff(rec.cargoes[0].hazmat.containsHazmat, nextValue.cargoes[0].hazmat.containsHazmat) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.containsHazmat !== null ? CapitalizeString(nextValue.cargoes[0].hazmat.containsHazmat.toString()) : 'N/A'}</span> : 'N/A'}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat UN Number</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].unNumber !== null ? rec.cargoes[0].hazmat.hazmatCargoes[0].unNumber : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].unNumber !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes.length > 0 && nextValue.cargoes[0].hazmat.hazmatCargoes[0].unNumber !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazmatCargoes[0].unNumber, nextValue.cargoes[0].hazmat.hazmatCargoes[0].unNumber) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].unNumber !== null ? nextValue.cargoes[0].hazmat.hazmatCargoes[0].unNumber : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat Shipping Name</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].shippingName !== null ? rec.cargoes[0].hazmat.hazmatCargoes[0].shippingName : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].shippingName !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingName !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazmatCargoes[0].shippingName, nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingName) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingName !== null ? nextValue.cargoes[0].hazmat.shippingName : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat Hazard Class and Division Code</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazardClassAndDivision !== null ? rec.cargoes[0].hazmat.hazardClassAndDivision : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazardClassAndDivision !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazardClassAndDivision !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazardClassAndDivision, nextValue.cargoes[0].hazmat.hazmatCargoes[0].hazardClassAndDivision) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].hazardClassAndDivision !== null ? nextValue.cargoes[0].hazmat.hazmatCargoes[0].hazardClassAndDivision : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat Passenger Eligibility Code</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode !== null ? rec.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode, nextValue.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode !== null ? nextValue.cargoes[0].hazmat.hazmatCargoes[0].passengerEligibilityCode : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat Net Explosive Weight</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight !== null ? numberWithCommas(rec.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight, nextValue.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight !== null ? numberWithCommas(nextValue.cargoes[0].hazmat.hazmatCargoes[0].netExplosiveWeight) : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
													<div className={'lr-history-row-item col-12'}>
														<div className={'lr-history-label'}>Hazmat Shipping Description</div>
														<div className={'lr-history-value'}>
															<span className={'prev'}>{rec.cargoes[0] !== null && rec.cargoes[0] !== undefined && rec.cargoes[0].count !== null && rec.cargoes[0].type === 'PALLETS' && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription !== null ? numberWithCommas(rec.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription) : 'N/A'}</span>
															{i + 1 !== liftDetails.history.length && (
																<React.Fragment>
																	<span className={'compare'}>
																		<i className={'far fa-arrow-left'}></i>
																	</span>
																	{rec.cargoes[0] !== null && rec.cargoes[0].hazmat !== null && rec.cargoes[0].hazmat.hazmatCargoes !== null && rec.cargoes[0].hazmat.hazmatCargoes.length > 0 && rec.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription !== null ? (
																		<span className={`next ${diff(rec.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription, nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription) === false ? 'different' : 'same'}`}>{nextValue.cargoes !== null && nextValue.cargoes[0] !== null && nextValue.cargoes[0].hazmat !== null && nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription !== null ? nextValue.cargoes[0].hazmat.hazmatCargoes[0].shippingDescription : 'N/A'}</span>
																	) : (
																		'N/A'
																	)}
																</React.Fragment>
															)}
														</div>
													</div>
												</Row>
											</React.Fragment>
										)}
									</AccordionContent>
								</Accordion>
							</div>
						</PanelBarItem>
					);
				})}
			</PanelBar>
		);
	};

	useEffect(() => {
		dataFetch();
	}, []);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						{loaded ? (
							<PDFExport paperSize="A4" margin="1cm" ref={pdfExportComponent}>
								<div className={'page-title'}>
									<h2>Lift Request History: {lrTitle}</h2>

									{PageTitleControls()}
								</div>

								<div className={'lift-request-meta-data'}>
									<ul>
										<li>
											Status:&nbsp;
											{data.history[0] && data.history[0].state ? <span className={`pill lift-status-${data.history[0].state.toLowerCase()}`}>{data.history[0].state}</span> : <span className={`pill`}>Not Available</span>}
										</li>
										<li>{data.history[0] && data.history[0].priority ? 'Priority: ' + data.history[0].priority : 'Priority: Not Available'}</li>
										<li>Total # Versions: {String(data.history.length) ? String(data.history.length) : 'Not Available'}</li>
									</ul>
								</div>

								{historyList()}
							</PDFExport>
						) : (
							<Loader />
						)}
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

export default LiftRequestHistory;
