/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';

import { Form, Field, FormElement } from '@progress/kendo-react-form';

import { FormDateTimePicker, FormComboBox } from '../../App/KendoFormElements/FormComponents';
import { reorderIcon } from '@progress/kendo-svg-icons';
import { DragAndDrop, SvgIcon, useDraggable, useDroppable } from '@progress/kendo-react-common';
import { ExpansionPanel, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';
import Loader from '../../Core/Loader';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line
import { FormDropDownList } from '../../App/KendoFormElements/FormComponents';
import { ApiAirportsList, ApiNewLeg, ApiLegsById, ApiDeleteLeg, ApiOrgSquadronList, ApiAssetBySquadronId, ApiGetMissionById, ApiEditMission } from '../../Helpers/Apis';
import { stripTimeZone, forceDateStringToUTCISOFormat } from '../../Helpers/DateTimeConversions';

// eslint-disable-next-line
import { orderBy } from '@progress/kendo-data-query';

// eslint-disable-next-line
import { Label } from '@progress/kendo-react-labels';

import Footer from '../../Core/Footer';
import Panel from '../../Core/Panel';
import { FormInput } from '../../App/KendoFormElements/FormComponents';

const MissionEdit = (props) => {
	// eslint-disable-next-line
	const [squadronLoaded, setSquadronLoaded] = React.useState(false);
	const [missionLoaded, setMissionLoaded] = React.useState(false);

	const [airports, setAirports] = React.useState([]);
	const [squadronList, setSquadronList] = React.useState([]);
	const [tailNumberList, setTailNumberList] = React.useState([]);
	const [assetList, setAssetList] = React.useState([]);
	const [missionObj, setMissionObj] = React.useState({});
	const [existingLegs, setExistingLegs] = React.useState([]);
	const [generatedMission, setGeneratedMission] = React.useState(false);

	const [formData, setFormData] = React.useState([]);

	// eslint-disable-next-line
	const [selectedSquadron, setSelectedSquadron] = React.useState('');

	const params = useParams();
	const missionId = params.missionId;

	// Squadron
	const assignedSquadronChangeHandler = (e) => {
		setSelectedSquadron(e.value.id);
	};

	useEffect(() => {
		if (selectedSquadron) {
			ApiAssetBySquadronId(selectedSquadron).then((res) => {
				setTailNumberList(res.map((element) => element.tailNumber));
				setAssetList(res);
			});
		}
	}, [selectedSquadron]);

	//Prepopulate airports
	useEffect(() => {
		if (existingLegs) {
			for (let i = 0; i < existingLegs.length; i++) {
				let dataObj1 = {};
				let dataObj2 = {};
				//Only one leg
				if (existingLegs.length === 1) {
					dataObj1 = {
						itemTypeID: i + 1,
						itemAirportName: existingLegs[i].history[0].departure.airport,
						itemDepartureDate: new Date(stripTimeZone(existingLegs[i].history[0].departure.planned)),
						itemArrivalDate: new Date(stripTimeZone(existingLegs[i].history[0].departure.planned)) //first airport has no arrival date, set default to dep
					};
					dataObj2 = {
						itemTypeID: i + 2,
						itemAirportName: existingLegs[i].history[0].arrival.airport,
						itemDepartureDate: new Date(stripTimeZone(existingLegs[i].history[0].arrival.planned)), //last airport has no departure date, set default to arr
						itemArrivalDate: new Date(stripTimeZone(existingLegs[i].history[0].arrival.planned))
					};
					setFormData((formData) => [...formData, dataObj1, dataObj2]);
				}
				//normal loops if there is more than one leg
				else {
					//check first
					if (i === 0) {
						dataObj1 = {
							itemTypeID: i + 1,
							itemAirportName: existingLegs[i].history[0].departure.airport,
							itemDepartureDate: new Date(stripTimeZone(existingLegs[i].history[0].departure.planned)),
							itemArrivalDate: new Date(stripTimeZone(existingLegs[i].history[0].departure.planned)) //first airport has no arrival date, set default to dep
						};
						setFormData((formData) => [...formData, dataObj1]);
					}
					//check last
					else if (i === existingLegs.length - 1) {
						dataObj1 = {
							itemTypeID: i + 1,
							itemAirportName: existingLegs[i].history[0].departure.airport,
							itemDepartureDate: new Date(stripTimeZone(existingLegs[i].history[0].departure.planned)),
							itemArrivalDate: new Date(stripTimeZone(existingLegs[i - 1].history[0].arrival.planned))
						};
						dataObj2 = {
							itemTypeID: i + 2,
							itemAirportName: existingLegs[i].history[0].arrival.airport,
							itemDepartureDate: new Date(stripTimeZone(existingLegs[i].history[0].arrival.planned)), //last airport has no departure date, set default to arr
							itemArrivalDate: new Date(stripTimeZone(existingLegs[i].history[0].arrival.planned))
						};
						setFormData((formData) => [...formData, dataObj1, dataObj2]);
					}
					//middle
					else {
						dataObj1 = {
							itemTypeID: i + 1,
							itemAirportName: existingLegs[i].history[0].departure.airport,
							itemDepartureDate: new Date(stripTimeZone(existingLegs[i].history[0].departure.planned)),
							itemArrivalDate: new Date(stripTimeZone(existingLegs[i - 1].history[0].arrival.planned))
						};
						setFormData((formData) => [...formData, dataObj1]);
					}
				}
			}
		}
	}, [existingLegs]);

	const navigate = useNavigate();

	async function sequentialCall(objs) {
		let legIds = [];
		for (let obj of objs) {
			await ApiNewLeg(obj).then((res) => {
				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log(res);
				}
				legIds.push(res.id);
			});
		}
		return legIds;
	}

	const handleSubmit = React.useCallback((event) => {
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log('In SUBMIT');
			console.log(event.selectedTypes);
		}

		//delete all existing legs by mission id
		ApiLegsById(missionObj.id).then((res) => {
			res.forEach((leg) => {
				ApiDeleteLeg(leg.id);
			});
		});

		let newLegObjs = [];
		let selectedAsset = assetList.filter((asset) => asset.tailNumber === event.assignedTailNum)[0];

		for (let i = 0; i < event.selectedTypes.length - 1; i++) {
			//dep is current, arr is next
			let dataObjForLeg = {
				createdBy: null,
				createdAt: null,
				departure: {
					airport: event.selectedTypes[i].itemAirportName,
					planned: forceDateStringToUTCISOFormat(event.selectedTypes[i].itemDepartureDate)
				},
				arrival: {
					airport: event.selectedTypes[i + 1].itemAirportName,
					planned: forceDateStringToUTCISOFormat(event.selectedTypes[i + 1].itemArrivalDate)
				},
				state: 'SPAWNED',
				assetId: selectedAsset.id,
				aircraftConfigurationId: 'A',
				missionId: missionObj.id
			};
			newLegObjs.push(dataObjForLeg);
		}

		sequentialCall(newLegObjs)
			.then((results) => {
				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log('leg ids in legs arr');
					console.log(results);
				}

				let missionObject = {
					createdBy: null,
					createdAt: null,
					state: missionObj.history[0].state !== null && missionObj.history[0].state !== undefined && missionObj.history[0].state !== '' ? missionObj.history[0].state : 'GENERATED',
					version: 11,
					missionName: event.missionName,
					asset: selectedAsset,
					legService: null,
					legs: results,
					squadronId: event.assignedSquadron.id,
					schedulerMissionId: null,
					missionId: missionId
				};

				if (process.env.REACT_APP_DEBUG === 'true') {
					console.log('Mission obj to PUT:');
					console.log(missionObject);
				}

				ApiEditMission(missionObject, missionObject.missionId).then((res) => {
					navigate(`/schedule`);
				});
			})
			.catch((error) => {
				if (process.env.REACT_APP_DEBUG === 'true') {
					console.error('Error:', error);
				}
			});
	});

	const handleCancel = () => {
		navigate(`/missionslist`);
	};

	function getMaxValue(arr, property) {
		if (arr.length === 0) {
			return 0;
		}

		let maxValue = arr[0][property];

		for (let i = 1; i < arr.length; i++) {
			if (arr[i][property] > maxValue) {
				maxValue = arr[i][property];
			}
		}

		return maxValue;
	}

	const ItemTemplate = (props) => {
		const { dataItem, expanded, deleteItem, dragStart, reorder, setExpanded, value, onChange } = props;

		const onAirportNameChange = (ev) => {
			let itemTypeID = ev.target.props.id;
			const newValue = value.map((item) => {
				// eslint-disable-next-line
				if (item.itemTypeID == itemTypeID) {
					return { ...item, itemAirportName: ev.target.value };
				}

				return item;
			});

			onChange({
				value: newValue
			});
		};

		const onDepartureDateChange = (ev) => {
			let itemTypeID = ev.target.props.id;
			const newValue = value.map((item) => {
				// eslint-disable-next-line
				if (item.itemTypeID == itemTypeID) {
					return { ...item, itemDepartureDate: ev.target.value };
				}

				return item;
			});
			onChange({
				value: newValue
			});
		};

		const onArrivalDateChange = (ev) => {
			let itemTypeID = ev.target.props.id;
			const newValue = value.map((item) => {
				// eslint-disable-next-line
				if (item.itemTypeID == itemTypeID) {
					return { ...item, itemArrivalDate: ev.target.value };
				}

				return item;
			});

			onChange({
				value: newValue
			});
		};

		const [dropped, setDropped] = React.useState(false);
		const [dragged, setDragged] = React.useState(false);
		const [direction, setDirection] = React.useState(null);
		const [initial, setInitial] = React.useState({
			x: 0,
			y: 0
		});

		const element = React.useRef(null);

		const handlePress = (event) => {
			setInitial({
				x: event.clientX - event.offsetX,
				y: event.clientY - event.offsetY
			});
		};

		const handleDragStart = (event) => {
			//debugger;
			if (!event.originalEvent.target || !event.originalEvent.target.dataset.dragHandle) {
				return;
			}

			setDragged(true);
			dragStart(props.dataItem);
		};

		const handleDrag = (event) => {
			if (!element.current || !dragged) {
				return;
			}

			element.current.style.transform = `translateY(${event.clientY - initial.y + event.scrollY}px)`;
		};

		const handleDragEnd = () => {
			setDragged(false);
			setDropped(false);
			setInitial({
				x: 0,
				y: 0
			});
		};

		const handleRelease = () => {
			if (!element.current) {
				return;
			}

			element.current.style.transform = null;
		};

		const handleDragEnter = () => {
			setDropped(true);
			setDirection(null);
		};

		const handleDragOver = (event) => {
			if (!element.current) {
				return;
			}

			const rect = element.current.getBoundingClientRect();
			setDirection(rect.top + rect.height / 2 <= event.pageY ? 'after' : 'before');
		};

		const handleDragLeave = () => {
			setDropped(false);
			setDirection(null);
		};

		const handleDrop = () => {
			reorder(props.dataItem, direction);
			setDropped(false);
			setDirection(null);
		};

		useDraggable(
			element,
			{
				onPress: handlePress,
				onDragStart: handleDragStart,
				onDrag: handleDrag,
				onDragEnd: handleDragEnd,
				onRelease: handleRelease
			},
			{
				autoScroll: dragged
			}
		);

		useDroppable(element, {
			onDragEnter: handleDragEnter,
			onDragOver: handleDragOver,
			onDragLeave: handleDragLeave,
			onDrop: handleDrop
		});

		/* Leg Header Title */
		const legTitle = (
			<span className={'leg-info-header'}>
				<span className={'airport-icon'}>
					<i className={'fa-regular fa-tower-control'}></i>
				</span>

				<span className={'leg-header-item leg-airport-range'}>
					<span>{dataItem.itemAirportName && dataItem.itemAirportName.icao ? dataItem.itemAirportName.icao : 'Airport Not Selected'}</span>
				</span>

				<span className={'leg-header-item leg-date-range'}>
					{dataItem.itemTypeID !== value[0].itemTypeID ? forceDateStringToUTCISOFormat(dataItem.itemArrivalDate) : ''}
					<i className={'far fa-arrow-right'}></i>
					{dataItem.itemTypeID !== value[value.length - 1].itemTypeID ? forceDateStringToUTCISOFormat(dataItem.itemDepartureDate) : ''}
				</span>
			</span>
		);

		return (
			<div className={'mission-ne-airport-block'}>
				{dropped && direction === 'before' && <div className={'mission-ne-airport-block-dropped-and-direction'} />}
				<div ref={element}>
					<div>
						<div className={'mission-ne-airport-drag-handle'} data-drag-handle="true">
							<SvgIcon style={{ pointerEvents: 'none' }} icon={reorderIcon} />
						</div>
						<ExpansionPanel
							title={legTitle}
							expanded={expanded === dataItem.itemTypeID}
							tabIndex={0}
							key={dataItem.itemTypeID}
							onAction={(event) => {
								setExpanded(event.expanded ? '' : dataItem.itemTypeID);
							}}>
							<Reveal>
								{expanded === dataItem.itemTypeID && (
									<ExpansionPanelContent>
										<Row editorid={`${dataItem.itemTypeID}`} key={dataItem.itemTypeID}>
											<div className={'col-12'}>
												<Row>
													<div className="col-12 form-section-title requester-information">
														<h3 className={'section-title'}>
															Requester Information
															<button type="button" className={'btn btn-delete-mission btn-color-red btn-medium margin-right-0'} style={{ marginTop: '-9px', float: 'right' }} onClick={deleteItem} id={dataItem.itemTypeID} title={'Click to Delete Airport Record'}>
																<span className={'padding-right-8'}>Delete Airport Record</span>
																<i className="fa-regular fa-trash-can"></i>
															</button>
														</h3>
													</div>
													<div className={'col-4'} editorid={`${dataItem.itemTypeID}`}>
														<Field id={dataItem.itemTypeID.toString()} defaultValue={dataItem.itemAirportName} onChange={onAirportNameChange} label={'Airport ICAO'} component={FormComboBox} data={airports} textField={'icaoName'} />
													</div>
													<div className={'col-4'} editorid={`${dataItem.itemTypeID}`}>
														<Field id={dataItem.itemTypeID.toString()} defaultValue={dataItem.itemArrivalDate} disabled={dataItem.itemTypeID === value[0].itemTypeID} onChange={onArrivalDateChange} label={'Arrival Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} textField={'arrivalDateTime'} />
													</div>
													<div className={'col-4'} editorid={`${dataItem.itemTypeID}`}>
														<Field id={dataItem.itemTypeID.toString()} defaultValue={dataItem.itemDepartureDate} disabled={dataItem.itemTypeID === value[value.length - 1].itemTypeID} onChange={onDepartureDateChange} label={'Departure Date/Time (UTC/Zulu)'} placeholder={'(Z)/MO/YR(+/- 2 hrs)'} format={'yyyy/MM/dd HH:mm'} component={FormDateTimePicker} textField={'departureDateTime'} />
													</div>
												</Row>
											</div>
										</Row>
									</ExpansionPanelContent>
								)}
							</Reveal>
						</ExpansionPanel>
					</div>
				</div>
				{dropped && direction === 'after' && <div className={'mission-ne-airport-block-dropped-and-direction'} />}
			</div>
		);
	};

	const MissionInput = (fieldRenderProps) => {
		// eslint-disable-next-line
		const { label, value, onChange, itemTypes } = fieldRenderProps;

		const [activeItem, setActiveItem] = React.useState(null);

		const reorder = (dataItem, direction) => {
			if (activeItem === dataItem) {
				return;
			}

			let reorderedData = value.slice();
			let prevIndex = reorderedData.findIndex((p) => p === activeItem);
			let nextIndex = reorderedData.findIndex((p) => p === dataItem) + (direction === 'before' ? -1 : 0);

			if (prevIndex > nextIndex) {
				nextIndex++;
			}

			reorderedData.splice(prevIndex, 1);
			reorderedData.splice(nextIndex, 0, activeItem || reorderedData[0]);
			onChange({ value: reorderedData });
		};

		const dragStart = (dataItem) => {
			if (process.env.REACT_APP_SHOW_IN_DEV === 'true') {
				console.log('Dragstart');
			}

			setActiveItem(dataItem);
		};

		const [expanded, setExpanded] = React.useState('Type1');

		const addItem = (ev) => {
			onChange({
				value: [...value, { itemTypeID: getMaxValue(value, 'itemTypeID') + 1, itemAirportName: '', itemDepartureDate: '', itemArrivalDate: '' }] //you can use the orderBy method over the array here as well, if you want the new item to be sorted as well
			});
		};

		const deleteItem = (ev) => {
			let itemTypeID = ev.target.id;

			if (process.env.REACT_APP_SHOW_IN_DEV === 'true') {
				console.log(itemTypeID);
			}

			onChange({
				// eslint-disable-next-line
				value: value.filter((item) => item.itemTypeID != itemTypeID)
			});
		};

		return (
			<div className="wrapper">
				<button onClick={addItem} type="button" className={'btn btn-color-orange btn-add-airport-to-mission margin-bottom-10'} title={'Click to Add Airport'}>
					<i className={'far fa-plus'}></i> Add Airport to Mission
				</button>

				{value.map((dataItem, i) => (
					<ItemTemplate key={`a + ${i}`} dataItem={dataItem} deleteItem={deleteItem} expanded={expanded} dragStart={dragStart} reorder={reorder} setExpanded={setExpanded} value={value} onChange={onChange} />
				))}
			</div>
		);
	};

	const dataLoad = () => {
		ApiGetMissionById(missionId).then((resMission) => {
			setMissionObj(resMission);
			ApiOrgSquadronList().then((res) => {
				setSquadronList(res);
				if (resMission.history[0].squadronId !== null) {
					setSelectedSquadron(res.filter((squadronObj) => squadronObj.id === resMission.history[0].squadronId)[0].id);
					setSquadronLoaded(true);
				} else {
					setSquadronLoaded(true);
				}
			});
		});

		ApiLegsById(missionId).then((res) => {
			setExistingLegs(res);
			//check if it is a solver generated mission (assuming at least one leg in a solver generated mission has cargoes)
			res.forEach((legObj) => {
				if (legObj.history[0].cargoes) {
					setGeneratedMission(true);
				}
			});
			setMissionLoaded(true);
		});

		ApiAirportsList().then((res) => {
			setAirports(res.map((element) => element.history[0]));
		});
	};

	useEffect(() => {
		dataLoad();
	}, [props]);

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					<Panel>
						{squadronLoaded && missionLoaded ? (
							<React.Fragment>
								<div className={'page-title'}>
									<h2>Edit Mission Details for {missionObj.missionName ? missionObj.missionName : missionObj.id}</h2>
									<span className={'page-title-status'}>
										Mission Status: <span className={`status pill mission-status-${missionObj.history[0].state.toLowerCase()}`}>{missionObj.history[0].state}</span>
									</span>
								</div>
								<div className={'page-title '} style={{ color: 'red' }}>
									<p>{generatedMission ? '*All Solver created Missions edited will lose their Lift Request assignments, and Lift Request statuses will need to be managed separately.' : ''}</p>
								</div>
								<Form
									onSubmit={handleSubmit}
									initialValues={{
										selectedTypes: formData,
										missionName: missionObj.missionName,
										assignedSquadron: squadronList.filter((squadronObj) => squadronObj.id === missionObj.history[0].squadronId)[0],
										assignedTailNum: missionObj.history[0].squadronId !== null ? missionObj.history[0].asset.tailNumber : ''
									}}
									render={(formRenderProps) => (
										<React.Fragment>
											<Row className={'mission-ne-header-details margin-top-10'}>
												<div className={'col-4'}>
													<Field key={'missionName'} id={'missionName'} name={'missionName'} label={'Mission Name'} placeholder={'E.g. V5G2P2'} component={FormInput} />
												</div>
												<div className={'col-4'}>
													<Field key={'assignedSquadron'} id={'assignedSquadron'} name={'assignedSquadron'} label={'Assigned Squadron'} defaultItem={'Select Squadron...'} component={FormDropDownList} data={squadronList} onChange={assignedSquadronChangeHandler} textField={'squadronDesignation'} dataItemKey={'id'} />
												</div>
												<div className={'col-4'}>
													<Field key={'assignedTailNum'} id={'assignedTailNum'} name={'assignedTailNum'} label={'Assigned Tail #'} defaultItem={'Select Tail Number...'} component={FormDropDownList} data={tailNumberList} onChange={assignedSquadronChangeHandler} />
												</div>
											</Row>

											<Row className={'mission-ne-airports-list'}>
												<div className={'col-12'}>
													<FormElement>
														<DragAndDrop>
															<Field name={'selectedTypes'} label={'selectedTypes'} component={MissionInput} itemTypes={formData} />
														</DragAndDrop>
														<div className="k-form-buttons margin-top-15">
															<button type={'submit'} disabled={!formRenderProps.allowSubmit} className={'btn btn-color-green btn-submit-mission margin-right-0'} title={'Click to Submit Mission'}>
																<i className={'far fa-check'}></i> Submit
															</button>
															<button
																type={'button'}
																className={'btn btn-color-red btn-cancel-mission-edit'}
																title={'Click to Cancel Mission Edit'}
																onClick={() => {
																	handleCancel();
																}}>
																<i className={'far fa-times'}></i> Cancel
															</button>
														</div>
													</FormElement>
												</div>
											</Row>
										</React.Fragment>
									)}
								/>
							</React.Fragment>
						) : (
							<Loader />
						)}
					</Panel>

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};
export default MissionEdit;
