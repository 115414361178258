/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const LiftRequestInfoModal = (props) => {
	//console.log(props);

	// https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
	function modalContent() {
		return { __html: props.modalContent };
	}

	function modalContentOutput() {
		return <div dangerouslySetInnerHTML={modalContent()} />;
	}

	return (
		<Modal show={props.modalShow} onHide={props.setModalShow} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>{props.modalTitle}</Modal.Title>
				{/*<Button className="col-md-2" onClick={props.setModalShow}>CLose</Button>*/}
			</Modal.Header>
			<Modal.Body>{modalContentOutput()}</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={props.setModalShow}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default LiftRequestInfoModal;
