/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { PDFExport } from '@progress/kendo-react-pdf';

import LiftRequestListGrid from '../../../App/LiftRequestListGrid/LiftRequestListGrid';
import AirportsListGrid from '../../../App/AirportsListGrid/AirportsListGrid';
import AssetAvailabilityList from '../../../App/AssetAvailability/AssetAvailabilityList';

import { ApiLiftRequestById, ApiAssetById, ApiAirportById } from '../../../Helpers/Apis';
import Loader from '../../../Core/Loader';

export const SandboxSummaryDetails = (props) => {
	const pdfExportComponent = React.useRef(null);

	const [loaded, setLoaded] = React.useState(false);

	const [selectedLiftRequests] = React.useState(props.selectedLiftRequests);
	const [selectedAssets] = React.useState(props.selectedAssets);
	const [selectedAirports] = React.useState(props.selectedAirports);

	const [liftRequestLoadedArray, setLiftRequestLoadedArray] = React.useState([]);
	const [assetLoadedArray, setAssetLoadedArray] = React.useState([]);
	const [airportLoadedArray, setAirportLoadedArray] = React.useState([]);

	let liftRequestArray = [];
	let assetArray = [];
	let airportArray = [];

	useEffect(() => {
		// eslint-disable-next-line
		selectedLiftRequests.map((liftRequestId) => {
			ApiLiftRequestById(liftRequestId).then((res) => {
				liftRequestArray.push(res);
			});
		});
		// eslint-disable-next-line
		selectedAssets.map((assetId) => {
			ApiAssetById(assetId).then((res) => {
				assetArray.push(res);
			});
		});
		if (props.usingStandardAirports) {
			airportArray = [];
		} else {
			// eslint-disable-next-line
			selectedAirports.map((airportId) => {
				ApiAirportById(airportId).then((res) => {
					airportArray.push(res);
				});
			});
		}

		setLoaded(true);
	}, []);

	useEffect(() => {
		setLiftRequestLoadedArray(liftRequestArray);
	}, [liftRequestArray]);

	useEffect(() => {
		setAssetLoadedArray(assetArray);
	}, [assetArray]);

	useEffect(() => {
		setAirportLoadedArray(airportArray);
	}, [airportArray]);

	const summaryData = () => {
		const sandboxName = props.valueGetter('sandboxName');

		return (
			<div className={'form-summary'}>
				<div className={'col-3'}>
					<label>Sandbox Name (Read Only) </label>
					<div className={'static-field-read-only static-text'}>{sandboxName ? sandboxName : ''}</div>
				</div>

				<Row id={'sandbox-details-list'} className={'form-section row'}>
					<div className={'col-4'}>
						<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
							<strong>Selected Lift Requests</strong>
						</h4>
					</div>
				</Row>

				<div className={'padding-bottom-40'}>
					<LiftRequestListGrid environment={'sandbox'} page={'summary'} data={liftRequestLoadedArray} />
				</div>

				<Row id={'sandbox-details-list'} className={'form-section row'}>
					<div className={'col-4'}>
						<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
							<strong>Selected Assets</strong>
						</h4>
					</div>
				</Row>

				<div className={'padding-bottom-40'}>
					<AssetAvailabilityList environment={'sandbox'} page={'summary'} data={assetLoadedArray} />
				</div>

				<Row id={'sandbox-details-list'} className={'form-section row'}>
					<div className={'col-4'}>
						<h4 className={'padding-top-0'} style={{ fontWeight: 'normal' }}>
							<strong>Selected Airports</strong>
						</h4>
					</div>
				</Row>

				<div className={'padding-bottom-40'}>
					<AirportsListGrid environment={'sandbox'} page={'summary'} data={airportLoadedArray} />
				</div>
			</div>
		);
	};

	return (
		<React.Fragment>
			{/* Summary Results: Start */}
			<Row id={'form-summary'} className={'form-section'}>
				{/* Section Title - Summary: Start */}
				<div className={'col-12 form-section-title lift-request-summary-header'}>
					{props.valueGetter('sandboxName') !== undefined ? <h3>{`Sandbox summary for ${props.valueGetter('sandboxName')}`}</h3> : <h3>{`Sandbox summary`}</h3>}

					<div className="summary-controls">
						<button
							className="btn button btn-summary-pdf"
							onClick={() => {
								if (pdfExportComponent.current) {
									pdfExportComponent.current.save();
								}
							}}>
							Export Summary
							<i className={'fa-solid fa-file-pdf'}></i>
						</button>
					</div>
				</div>

				<div className={'col-12'}>
					<PDFExport paperSize="A4" margin="1cm" ref={pdfExportComponent}>
						{loaded ? summaryData() : <Loader />}
					</PDFExport>
				</div>
				{/* Section Title - Summary: End */}
			</Row>
		</React.Fragment>
	);
};
