/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';
//import Tab from 'react-bootstrap/Tab';
//import Tabs from 'react-bootstrap/Tabs';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import AnalysisCost from './AnalysisCost';
import AnalysisData from './AnalysisData';
import AnalysisFlight from './AnalysisFlight';
import AnalysisLift from './AnalysisLift';

const AnalysisTabs = () => {
	const [selected, setSelected] = React.useState(0);
	const handleSelect = (e) => {
		setSelected(e.selected);
	};
	return (
		<TabStrip selected={selected} onSelect={handleSelect} id="analysis-tabs" className="analysis-tabs">
			<TabStripTab eventKey="data" title="All Data">
				<AnalysisData />
			</TabStripTab>
			<TabStripTab eventKey="cost" title="Cost">
				<AnalysisCost />
			</TabStripTab>
			<TabStripTab eventKey="flight" title="Flight Information">
				<AnalysisFlight />
			</TabStripTab>
			<TabStripTab eventKey="lift" title="Lift Information">
				<AnalysisLift />
			</TabStripTab>
		</TabStrip>
	);
};

export default AnalysisTabs;
