/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import { React, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import * as Actions from '../../../store/actions';

import { accessValidator } from '../../Helpers/RolesPermissions';

import Logo from '../../Core/Logo';

const Sidebar = (props) => {
	const [actionDesktopToggle, setActionDesktopToggle] = useState(true);
	const [actionMobileToggle, setActionMobileToggle] = useState(true);

	const handleDesktopToggle = () => setActionDesktopToggle(!actionDesktopToggle);
	const handleMobileToggle = () => setActionMobileToggle(!actionMobileToggle);
	const handleMobileToggleReset = () => setActionMobileToggle(true);

	const accessAirportsList = ['nalo'];
	// const accessAnalysis = ['nalo'];
	const accessAssetManagement = ['nalo', 'squadron'];
	const accessDisruptionManagement = ['nalo'];
	const accessLiftRequestsList = ['approver', 'nalo', 'requester'];
	const accessMissionsList = ['nalo'];
	const accessMissionStatus = ['nalo', 'squadron'];
	// const accessReports = ['nalo'];
	const accessSchedule = ['nalo'];
	const accessSquadronsList = ['nalo'];
	const accessSandbox = ['nalo'];

	const accessDeveloperTests = ['approver', 'nalo', 'requester', 'squadron'];

	return (
		<aside id={'app-sidebar'} className={`app-sidebar ${props.className ? props.className : ''} ${actionDesktopToggle ? 'expanded' : 'collapsed'} ${actionMobileToggle ? 'collapsed-mobile' : 'expanded-mobile'}`} style={props.style}>
			<Logo className={'large'} />

			<nav className={'sidebar-toggle-wrapper'} aria-hidden={'true'}>
				<div className={`sidebar-toggle ${actionDesktopToggle ? 'expanded' : 'collapsed'} ${actionMobileToggle ? 'collapsed-mobile' : 'expanded-mobile'}`}>
					<button
						className={'sidebar-toggle-btn desktop'}
						onClick={() => {
							handleDesktopToggle();
						}}
						title={'Click to Toggle Sidebar desktop'}>
						<i className={'fa fa-bars'}></i>
						<span className={'sr-only'}>Toggle Sidebar</span>
					</button>
					<button
						className={'sidebar-toggle-btn mobile'}
						onClick={() => {
							handleMobileToggle();
						}}
						title={'Click to Toggle Sidebar'}>
						<i className={`fa fa-${actionMobileToggle ? 'bars' : 'xmark'}`}></i>
						<span className={'sr-only'}>Toggle Sidebar</span>
					</button>
				</div>
			</nav>

			{window.location.href.indexOf('sandbox') !== -1 ? (
				// Sandbox Environment Sidebar
				<div className={'app-sidebar-outer'}>
					<div className={'app-sidebar-inner'}>
						<section className={'sidebar-nav-block dashboard'}>
							<NavLink
								to="/dashboard"
								title={'Click to Exit the Sandbox Environment and View the GRACE Dashboard'}
								onClick={(e) => {
									handleMobileToggleReset();
								}}>
								<i className={'far fa-arrow-left'}></i>
								<span>Exit Sandbox Environment</span>
							</NavLink>
						</section>

						<section className={'sidebar-nav-block'}>
							<h5>Actions</h5>
							<ul>
								{accessValidator(props.roleaccess, accessSandbox) && (
									<li className={'sidebar-link sandboxDashboard'}>
										<NavLink
											to="/sandbox/dashboard"
											title={'Click to View the Sandbox Dashboard Page'}
											onClick={(e) => {
												handleMobileToggleReset();
											}}>
											{/* <i className={'fa-solid fa-road-bridge'}></i> */}
											<i className={'fa-regular fa-chart-user'}></i>
											<span>Sandbox Dashboard</span>
										</NavLink>
									</li>
								)}
							</ul>
						</section>
					</div>
				</div>
			) : (
				// Not Sandbox Environment Sidebar
				<div className={'app-sidebar-outer'}>
					<div className={'app-sidebar-inner'}>
						<section className={'sidebar-nav-block dashboard'}>
							<NavLink
								to="/dashboard"
								title={'Click to View the Dashboard'}
								onClick={(e) => {
									handleMobileToggleReset();
								}}>
								<i className={'fa-solid fa-house'}></i>
								<span>Dashboard</span>
							</NavLink>
						</section>

						<section className={'sidebar-nav-block'}>
							<h5>Actions</h5>
							<ul>
								{accessValidator(props.roleaccess, accessAirportsList) && (
									<li className={'sidebar-link airportslist'}>
										<NavLink
											to="/airportslist"
											title={'Click to View the Airports List Page'}
											onClick={(e) => {
												handleMobileToggleReset();
											}}>
											<i className={'fa-solid fa-road-bridge'}></i>
											<span>Airports List</span>
										</NavLink>
									</li>
								)}

								{accessValidator(props.roleaccess, accessAssetManagement) && (
									<li className={'sidebar-link assetavailability'}>
										<NavLink
											to="/assetavailability"
											title={'Click to View Asset Availability'}
											onClick={(e) => {
												handleMobileToggleReset();
											}}>
											<i className={'fa-solid fa-cart-flatbed-suitcase'}></i>
											<span>Asset Availability</span>
										</NavLink>
									</li>
								)}

								{accessValidator(props.roleaccess, accessLiftRequestsList) && !accessValidator(props.roleaccess, ['squadron']) && (
									<li className={'sidebar-link liftrequestslist'}>
										<NavLink
											to="liftrequestslist"
											title={'Click to View the Lift Request List'}
											onClick={(e) => {
												handleMobileToggleReset();
											}}>
											<i className={'fa-solid fa-table-list'}></i>
											<span>Lift Requests List</span>
										</NavLink>
									</li>
								)}

								{accessValidator(props.roleaccess, accessMissionsList) && (
									<li className={'sidebar-link missionslist'}>
										<NavLink
											to="/missionslist"
											title={'Click to View the Missions List'}
											onClick={(e) => {
												handleMobileToggleReset();
											}}>
											<i className={'fa-solid fa-plane-departure'}></i>
											<span>Missions List</span>
										</NavLink>
									</li>
								)}

								{accessValidator(props.roleaccess, accessMissionStatus) && (
									<li className={'sidebar-link missionstatus'}>
										<NavLink
											to="/missionstatus"
											title={'Click to View Mission Status'}
											onClick={(e) => {
												handleMobileToggleReset();
											}}>
											<i className={'fa-regular fa-radar'}></i>
											<span>Mission Status</span>
										</NavLink>
									</li>
								)}

								{accessValidator(props.roleaccess, accessSchedule) && (
									<li className={'sidebar-link schedules'}>
										<NavLink
											to="/schedule"
											title={'Click to View Mission Schedules'}
											onClick={(e) => {
												handleMobileToggleReset();
											}}>
											<i className={'fa-solid fa-calendar-days'}></i>
											<span>Schedule</span>
										</NavLink>
									</li>
								)}

								{process.env.REACT_APP_SHOW_IN_DEV === 'true' && accessValidator(props.roleaccess, accessSquadronsList) && (
									<li className={'sidebar-link squadrons-list'}>
										<NavLink
											to="/squadronslist"
											title={'Click to View Squadrons List'}
											onClick={(e) => {
												handleMobileToggleReset();
											}}>
											<i className={'fa-solid fa-user-pilot'}></i>
											<span>Squadrons List</span>
										</NavLink>
									</li>
								)}

								<li className={'sidebar-link disruptionmanagement'}>
									<NavLink
										to="/disruptions"
										title={'Click to View Disruption Management'}
										onClick={(e) => {
											handleMobileToggleReset();
										}}>
										<i className={'fa-solid fa-toolbox'}></i>
										<span>Disruption Management</span>
									</NavLink>
								</li>
							</ul>
						</section>

						{accessValidator(props.roleaccess, accessDisruptionManagement) && (
							<section className={'sidebar-nav-block'}>
								<h5>Management</h5>
								<ul>
									{accessValidator(props.roleaccess, accessDisruptionManagement) && (
										<li className={'sidebar-link solverstatus'}>
											<NavLink
												to="/solverstatus"
												title={'Click to View Solver Status'}
												onClick={(e) => {
													handleMobileToggleReset();
												}}>
												<i className="fa-solid fa-flag"></i>
												<span>Solver Status</span>
											</NavLink>
										</li>
									)}
								</ul>
							</section>
						)}
					</div>
				</div>
			)}

			{accessValidator(props.roleaccess, accessDeveloperTests) && process.env.REACT_APP_DEBUG === 'true' && (
				<div className={'sidebar-tests'}>
					<NavLink
						to={`/tests/${props.userid}`}
						title={'Click to View the Developer Tests Page'}
						onClick={(e) => {
							handleMobileToggleReset();
						}}>
						<i className={'fa fa-bars-progress'}></i>
						<span>Developer Tests</span>
					</NavLink>
				</div>
			)}
		</aside>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname,
		theme: state.theme
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
