/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import * as React from 'react';
import Button from 'react-bootstrap/Button';

export const PalletCommandButtonsCell = (props) => {
	const { dataItem } = props;
	const inEdit = dataItem[props.editField];
	const isNewItem = dataItem.EntryID === undefined;

	return inEdit ? (
		<td className="k-command-cell">
			<Button
				type={'button'}
				className="btn btn-primary btn-color-blue"
				disabled={props.allowedToSubmit !== undefined ? !props.allowedToSubmit : false}
				onClick={() => {
					isNewItem ? props.add(dataItem) : props.update(dataItem);
				}}
				title={`${isNewItem} ? 'Click to Add' : 'Click to Update'`}>
				{isNewItem ? 'Add' : 'Update'} <i className={`${isNewItem} ? 'far fa-plus' : 'fa-solid fa-rotate-right'`}></i>
			</Button>
			<Button type={'button'} className="btn btn-primary btn-color-red" onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))} title={`${isNewItem} ? 'Click to Discard' : 'Click to Cancel'`}>
				{isNewItem ? 'Discard' : 'Cancel'} <i className={`${isNewItem} ? 'far fa-trash' : 'fa-solid fa-ban'`}></i>
			</Button>
		</td>
	) : (
		<td className="k-command-cell">
			<Button type={'button'} className="btn btn-primary btn-color-red" onClick={() => props.remove(dataItem)} title={'Click to Remove'}>
				Remove <i className="far fa-times"></i>
			</Button>
		</td>
	);
};
