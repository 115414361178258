/*
 * Copyright 2023-2024. Next Tier Concepts, Inc.
 * All rights reserved.
 */

import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import * as Actions from '../../../../store/actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Tooltip } from '@progress/kendo-react-tooltip';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import SandboxListGrid from '../SandboxListGrid/SandboxListGrid';

import Footer from '../../../Core/Footer';
import Panel from '../../../Core/Panel';
import Loader from '../../../Core/Loader';

import { ApiSandboxList } from '../../../Helpers/Apis';

const Dashboard = (props) => {
	const [sandboxList, setSandboxList] = React.useState([]);
	const [loaded, setLoaded] = React.useState(false);

	//don't show cancelled sandboxes
	const queryparams = '?state=GENERATED&state=INPROGRESS&state=COMPLETED';

	useEffect(() => {
		ApiSandboxList(queryparams).then((res) => {
			setSandboxList(res);
			setLoaded(true);
		});
	}, []);

	const PageTitleControls = () => {
		return (
			<Tooltip openDelay={450} anchorElement="target" parentTitle={true} position={'bottom'}>
				<Navbar expand="lg">
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							<NavLink className={'nav-link'} role={'button'} to={`/sandbox/newsandbox`} title={`Click to Create a New Sandbox`}>
								<i className={'fa-solid fa-cards-blank'}></i>
								New Sandbox
							</NavLink>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</Tooltip>
		);
	};

	return (
		<Container fluid className={'app-content'}>
			<Row>
				<Col>
					{loaded ? (
						<Panel
							style={{
								height: `${sandboxList.length ? 'auto' : '600px'}`
							}}>
							<div className={'page-title'}>
								<h2 className={'padding-top-0 padding-bottom-0'}>Sandbox Dashboard</h2>
								{PageTitleControls()}
							</div>

							<div className={'sandbox-dashboard'}>
								<div className={'sandbox-container'}>
									{sandboxList.length > 0 ? (
										<SandboxListGrid sandboxData={sandboxList} />
									) : (
										<div className={'no-sandbox-notif'}>
											<i className={'fa-regular fa-square-info'}></i>
											<p>No sandboxes have been created. To get started, click the "New Sandbox" button.</p>
										</div>
									)}
								</div>
							</div>
						</Panel>
					) : (
						<Loader />
					)}

					<Footer />
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		keycloak: state.keycloak,
		authenticated: state.authenticated,
		token: state.token,
		roleaccess: state.roleaccess,
		profile: state.profile,
		userid: state.userid,
		username: state.username,
		firstname: state.firstname,
		lastname: state.lastname
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStart: () => dispatch(Actions.login())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
